import moment from "moment";
import React from "react";
import { IoMdContact } from "react-icons/io";
import { RiCalendarTodoFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocal } from "../../App";
import noImage from "../../assets/noImage.jpg";
import { formatDate, useCustomMediaQuery } from "../../utils";
import LockIcon from "../LockIcon/LockIcon";
import PlayerIcon from "../PlayerIcon";
const CarouselArticle = ({
  details,
  sidebars,
  slidesToShow,
  type,
  relatedPosts,
  noLink,
}) => {
  const {
    post_thumbnail,
    published_at,
    title,
    post_type,
    id,
    post_media_resolutions,
    post_thumbnail_resolutions,
    post_media_url,
    post_thumbnail_url,
    is_bought,
    is_registered,
  } = details;
  const { resolution } = useCustomMediaQuery();
  const local = useLocal();
  const setPostMediaSource = () => {
    if (relatedPosts) {
      const tempResolutions =
        details?.post_type === "image"
          ? post_media_resolutions
          : post_thumbnail_resolutions;
      if (tempResolutions && tempResolutions.length > 0) {
        const source = tempResolutions.filter(
          (element) => element?.resolution === resolution
        );
        return source[0].file_url;
      }
      if (post_type === "image") {
        return post_media_url ? post_media_url : noImage;
      }
      if (post_type === "video" || post_type === "audio") {
        return post_thumbnail_url ? post_thumbnail_url : noImage;
      }
      return post_thumbnail ? post_thumbnail : noImage;
    }
    if (type === "event-list" || type === "event-carousel") {
      if (
        details?.event_image_resolutions &&
        details?.event_image_resolutions.length > 0
      ) {
        const source = details?.event_image_resolutions.filter(
          (element) => element?.resolution === resolution
        );
        return source[0].file_url;
      }
      return details?.event_image_url ? details?.event_image_url : noImage;
    } else {
      const imgResolutions =
        post_type === "image"
          ? post_media_resolutions
          : post_thumbnail_resolutions;
      if (imgResolutions && imgResolutions.length > 0) {
        const source = imgResolutions.filter(
          (element) => element?.resolution === resolution
        );
        return source[0].file_url;
      }
      if (post_type === "image") {
        return post_media_url ? post_media_url : noImage;
      }
      if (post_type === "video" || post_type === "audio") {
        return post_thumbnail_url ? post_thumbnail_url : noImage;
      }
      return post_thumbnail ? post_thumbnail : noImage;
    }
  };

  const eventHostMedia = () => {
    if (
      details.contact_person_image_resolutions &&
      details.contact_person_image_resolutions.length > 0
    ) {
      const source = details.contact_person_image_resolutions.filter(
        (element) => {
          return element?.resolution === "256x144";
        }
      );
      return source[0]?.file_url;
    }
    return details.contact_person_image_url
      ? details.contact_person_image_url
      : noImage;
  };
  const posts_details_date_time_format = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.posts_general_settings
        ?.posts_details_date_time_format
  );

  const showLockIcon = useSelector(
    (state) =>
      state.generalDetails.generalDetails.general_settings
        .display_lock_icon_to_content
  );

  return (
    <div
      className="article__entry position-relative totalClick"
      style={{
        width: `${
          sidebars && Object.entries(sidebars).length === 0
            ? slidesToShow === 1
              ? "75%"
              : "100%"
            : "100%"
        }`,
        margin: `${slidesToShow === 1 ? "auto" : ""}`,
      }}
    >
      <Link
        to={
          type === "event-list" || type === "event-carousel"
            ? details?.seo_permalink
              ? `../event/${id}/${details?.seo_permalink}`
              : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
            : details?.seo_permalink
            ? `../post/${id}/${details?.seo_permalink}`
            : `../post/${id}/${details?.title?.replace(/\s+/g, "-")}`
        }
        state={
          type === "event-list" || type === "event-carousel"
            ? { eventId: id }
            : { postId: id }
        }
      >
        <div className="article__image rounded-1">
          {post_type === "video" || post_type === "audio" ? (
            <PlayerIcon type={post_type} />
          ) : null}
          {type === "event-list" ||
          type === "event-carousel" ? null : showLockIcon === "yes" &&
            (!!is_bought || !!is_registered) ? null : (
            <LockIcon
              size={slidesToShow === 1 ? "large" : "small"}
              sidebars={sidebars && Object.entries(sidebars).length !== 0}
            />
          )}

          {noLink ? (
            <img
              src={setPostMediaSource()}
              alt="post_image"
              className="img-fluid"
              style={{
                opacity:
                  type === "event-list" || type === "event-carousel"
                    ? ""
                    : showLockIcon === "yes" && (!!is_bought || !!is_registered)
                    ? ""
                    : "50%",
              }}
            />
          ) : (
            <Link
              to={
                type === "event-list" || type === "event-carousel"
                  ? details?.seo_permalink
                    ? `../event/${id}/${details?.seo_permalink}`
                    : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
                  : details?.seo_permalink
                  ? `../post/${id}/${details?.seo_permalink}`
                  : `../post/${id}/${details?.title?.replace(/\s+/g, "-")}`
              }
              state={
                type === "event-list" || type === "event-carousel"
                  ? { eventId: id }
                  : { postId: id }
              }
            >
              <img
                src={setPostMediaSource()}
                alt="post_image"
                className="img-fluid"
                style={{
                  opacity:
                    type === "event-list" || type === "event-carousel"
                      ? ""
                      : showLockIcon === "yes" &&
                        (!!is_bought || !!is_registered)
                      ? ""
                      : "50%",
                }}
              />
            </Link>
          )}
        </div>
        {type === "event-list" || type === "event-carousel" ? (
          <div className="article__content">
            <h5 className="article__introducation">
              {noLink ? (
                title
              ) : (
                <Link
                  to={
                    details?.seo_permalink
                      ? `../event/${id}/${details?.seo_permalink}`
                      : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
                  }
                  state={{ eventId: details?.id }}
                >
                  {title}
                </Link>
              )}
            </h5>
            <div className="d-flex align-items-center">
              {details.start_date ? (
                <ul className="list-inline">
                  {/* <li className="list-inline-item">
                        <span>
                            by david hall
                        </span>,
                    </li> */}

                  <li className="list-inline-item">
                    <span
                      className="text-dark text-capitalize d-flex justify-content-center align-items-center"
                      style={{ marginRight: "1rem", height: "30px" }}
                    >
                      {/* <AiOutlineCalendar className="icon-calendar"/> */}
                      {/* <img src={calendarSvg} /> */}
                      <RiCalendarTodoFill className="icon-calendar" />
                      {details.start_date
                        ? local === "en"
                          ? formatDate(details.start_date)
                          : moment(details.start_date).format("DD.MM.yyyy")
                        : ""}
                    </span>
                  </li>
                </ul>
              ) : null}
              {details?.contact_person_image_url ? (
                <div className="d-flex justify-content-center align-items-center">
                  <IoMdContact
                    className="event-icons no-background"
                    style={{ marginRight: "0" }}
                  />
                  <img
                    className=" mx-1"
                    style={{
                      height: "22px",
                      width: "auto",
                      objectFit: "contain",
                    }}
                    src={details?.contact_person_image_url}
                    alt="host-profile"
                  />
                </div>
              ) : details?.contact_person_name ? (
                <div className="d-flex align-items-center gap-1">
                  <div className="host_profile position-relative">
                    <span
                      className="position-absolute"
                      style={{ marginLeft: "7px" }}
                    >
                      {details?.contact_person_name.charAt(0)}
                    </span>
                  </div>
                  <span className="fw-lighter text-muted">
                    {details?.contact_person_name}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="article__content">
            {published_at && posts_details_date_time_format !== "none" ? (
              <ul className="list-inline">
                {/* <li className="list-inline-item">
                        <span>
                            by david hall
                        </span>,
                    </li> */}

                <li className="list-inline-item">
                  <span
                    className="text-dark text-capitalize d-flex justify-content-center align-items-center"
                    style={{ marginRight: "1rem", height: "30px" }}
                  >
                    {/* <AiOutlineCalendar className="icon-calendar"/> */}
                    {/* <img src={calendarSvg} /> */}
                    <RiCalendarTodoFill className="icon-calendar" />
                    {local && local === "en"
                      ? posts_details_date_time_format === "date"
                        ? formatDate(published_at)
                        : formatDate(published_at, true)
                      : moment(published_at).format(
                          posts_details_date_time_format === "date"
                            ? "DD.MM.yyyy"
                            : "DD.MM.yyyy HH:mm"
                        )}
                  </span>
                </li>
              </ul>
            ) : null}
            <h5 className="article__introducation">
              <Link
                to={
                  details?.seo_permalink
                    ? `../post/${id}/${details?.seo_permalink}`
                    : `../post/${id}/${details?.title?.replace(/\s+/g, "-")}`
                }
                state={{ postId: details?.id }}
              >
                {title}
              </Link>
            </h5>
          </div>
        )}
      </Link>
    </div>
  );
};

export default CarouselArticle;
