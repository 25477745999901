import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useCustomMediaQuery } from "../../utils";
import { CustomDropDown } from "../Dropdown/CustomDropDown";
import InputLabel from "../InputLabel/InputLabel";
import TextInput from "../TextInput/TextInput";
import AdditionalFields from "./AdditionalFields";
import LegalTextModal from "./LegalTextModal";
const EventRegisterModal = ({
  eventId,
  contactId,
  show,
  legalTexts,
  productId,
  setshowErrorModal,
  closeModal,
  eventRegistrationAPI,
  flow,
}) => {
  const intl = useIntl();
  // const dispatch = useDispatch();
  // const [islegalText, setislegalText] = useState(false);
  const [showLegalTextModal, setshow] = useState(
    legalTexts ? new Array(legalTexts?.length).fill(false) : [false]
  );
  // const [legalTextData, setlegalTextData] = useState({});
  // const locale = localStorage.getItem("language");
  const [Error, setError] = useState("");
  const [selectedSalutation, setSelectedSalutation] = useState();
  const [legalString, setLegalString] = useState(
    legalTexts && legalTexts.length > 0
      ? legalTexts.map((ele) => ele?.legal_text_element)
      : []
  );
  const { isXsScreen } = useCustomMediaQuery();
  const [Loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth?.user);
  const [additionalFields, setadditionalFields] = useState({});
  const [userType, setUserType] = useState("private");
  const [selectedCountryCompany, setSelectedCountryCompany] = useState();
  const [selectedCountryPrivate, setSelectedCountryPrivate] = useState();
  const [showFillBoxCompany, setShowFillBoxCompany] = useState(true);
  const [showFillBoxPrivate, setShowFillBoxPrivate] = useState(true);
  const SALUATION_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_SALUATION_OPTIONS" })
  );
  const sysAdmin = useSelector(
    (state) => state.auth?.user?.personalInformation?.sys_admin
  );
  const isAuthorized = useSelector((state) => state?.auth?.isAuthorized);
  const countries = useSelector((state) => state.masterData.countriesList);
  const checked = {};

  const handleCloseModal = (index) =>
    setshow((prev) => {
      prev[index] = false;
      return [...prev];
    });
  const handleShowModal = (index) =>
    setshow((prev) => {
      prev[index] = true;
      return [...prev];
    });
  // useEffect(() => {
  //   if (legalTexts && legalTexts.length > 0) {
  //     setislegalText(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (legalTexts && legalTexts.length > 0) {
  //     let newObj = {};
  //     legalTexts.forEach((ele, index) => {
  //       setlegalTextData((prev) => {
  //         if (checked[`checked_${index}`]) {
  //           newObj[`legal_texts[${index}][id]`] = ele?.legal_text_id;
  //           newObj[`legal_texts[${index}][version_id]`] =
  //             ele?.legal_text_version_id;
  //         }
  //         return newObj;
  //       });
  //     });
  //   }
  // }, [legalTexts, checked]);
  useEffect(() => {
    setLegalString(
      legalTexts &&
        legalTexts.length > 0 &&
        legalTexts.map((ele) => ele?.legal_text_element)
    );
  }, [legalTexts]);

  useEffect(() => {
    if (legalTexts && legalTexts.length > 0) {
      legalTexts.forEach((ele, index) => {
        if (ele?.legal_text_element) {
          const placeholder = ele?.legal_text_element?.match(/\{{%(.*?)\%}}/g);
          if (placeholder && placeholder.length > 0) {
            setLegalString((prev) => {
              if (placeholder && placeholder.length > 0) {
                prev[index] = Array.isArray(prev[index])
                  ? prev[index]
                  : prev[index]?.split(placeholder[0]);
              } else {
                prev[index] = prev[index].concat(
                  ` <a class='read-more-btn border-0 bg-white text-decoration-underline'>Read more</a>`
                );
              }
              return [...prev];
            });
          }
        }
      });
    }
  }, []);

  if (legalTexts && legalTexts.length > 0) {
    legalTexts.forEach((ele, index) => {
      checked[`checked_${index}`] = false;
    });
  }

  const handleChecked = (event, index) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
    // setchecked((prev) => {
    //   prev[`checked_${index}`] = checked;
    //   return { ...prev };
    // });
  };

  const formik = useFormik({
    initialValues: {
      salutation: user?.personalInformation?.salutation_id || undefined,
      email: user?.personalInformation?.email || "",
      firstName: user?.personalInformation?.firstname || "",
      lastName: user?.personalInformation?.lastname || "",
      personalAddress: "",
      personal_zip_code: undefined,
      personalCity: "",
      personal_country_id: undefined,
      company: "",
      address: "",
      zip_code: undefined,
      city: "",
      country_id: undefined,
      ...checked,
    },
    validate: (values) => {
      let errors = {};
      if (
        !values?.personalAddress &&
        userType === "private" &&
        flow === "offers"
      ) {
        errors.personalAddress = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (
        !values?.personal_zip_code &&
        userType === "private" &&
        flow === "offers"
      ) {
        errors.personal_zip_code = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (
        !values?.personalCity &&
        userType === "private" &&
        flow === "offers"
      ) {
        errors.personalCity = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (
        !values?.personal_country_id &&
        userType === "private" &&
        flow === "offers"
      ) {
        errors.personal_country_id = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (!values?.company && userType === "company" && flow === "offers") {
        errors.company = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (!values?.address && userType === "company" && flow === "offers") {
        errors.address = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (!values?.zip_code && userType === "company" && flow === "offers") {
        errors.zip_code = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (!values?.city && userType === "company" && flow === "offers") {
        errors.city = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (!values?.country_id && userType === "company" && flow === "offers") {
        errors.country_id = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (!values?.salutation) {
        errors.salutation = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (!values?.email) {
        errors.email = intl.formatMessage({
          id: "USERS_EMAIL_REQUIRED",
        });
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.email)
      ) {
        errors.email = intl.formatMessage({
          id: "VALIDATE_INVALID_EMAIL_ADDRESS_ERROR_MESSAGE",
        });
      }
      if (!values?.firstName) {
        errors.firstName = intl.formatMessage({
          id: "VALIDATE_FIRST_NAME_REQUIRED_ERROR_MESSAGE",
        });
      } else if (values?.firstName && values?.firstName.length < 2) {
        errors.firstName = intl.formatMessage({
          id: "VALIDATE_TOO_SHORT_ERROR_MESSAGE",
        });
      } else if (values?.firstName && values?.firstName.length > 40) {
        errors.firstName = intl.formatMessage({
          id: "VALIDATE_TOO_LONG_ERROR_MESSAGE",
        });
      }
      if (!values?.lastName) {
        errors.lastName = intl.formatMessage({
          id: "VALIDATE_LAST_NAME_REQUIRED_ERROR_MESSAGE",
        });
      } else if (values?.lastName && values?.lastName.length < 2) {
        errors.lastName = intl.formatMessage({
          id: "VALIDATE_TOO_SHORT_ERROR_MESSAGE",
        });
      } else if (values?.lastName && values?.lastName.length > 40) {
        errors.lastName = intl.formatMessage({
          id: "VALIDATE_TOO_LONG_ERROR_MESSAGE",
        });
      }
      if (legalTexts && legalTexts.length > 0) {
        legalTexts.forEach((ele, index) => {
          if (!values[`checked_${index}`] && ele.required === 1) {
            errors[`checked_${index}`] = intl.formatMessage({
              id: "VALIDATE_TERMS_AND_CONDITIONS_MESSAGE",
            });
          }
        });
      }
      return errors;
    },
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      setLoading(true);

      const legal_texts = [];

      if (legalTexts && legalTexts.length > 0) {
        legalTexts.forEach((ele, index) => {
          if (values[`checked_${index}`] && ele) {
            legal_texts.push({
              id: ele.legal_text_id,
              version_id: ele.legal_text_version_id,
            });
          }
        });
      }

      const paramsObject =
        flow === "offers"
          ? userType === "private"
            ? {
                salutation_id: values.salutation,
                firstname: values.firstName,
                lastname: values.lastName,
                email: values.email,
                product_id: productId ? productId : null,
                address: values.personalAddress,
                zip_code: values.personal_zip_code,
                city: values.personalCity,
                country_id: values.personal_country_id,
                offer_type: userType,
                contact_id: contactId,
                legal_texts,
                ...additionalFields,
                //post_id: postId,
              }
            : {
                contact_id: contactId,
                salutation_id: values.salutation,
                firstname: values.firstName,
                lastname: values.lastName,
                email: values.email,
                product_id: productId ? productId : null,
                company_name: values.company,
                address: values.address,
                zip_code: values.zip_code,
                city: values.city,
                country_id: values.country_id,
                offer_type: userType,
                legal_texts,
                ...additionalFields,
                //post_id: postId,
              }
          : {
              contact_id: contactId,
              salutation_id: values.salutation,
              firstname: values.firstName,
              lastname: values.lastName,
              email: values.email,
              product_id: productId ? productId : null,
              legal_texts,
              ...additionalFields,
              //post_id: postId,
            };
      try {
        eventRegistrationAPI(paramsObject)
          .then((response) => {
            setLoading(false);
            closeModal();
            setError("");
            // setSelectedSalutation();
            // formik.resetForm();
            actions.setSubmitting(false);
          })
          .catch((error) => {
            actions.setSubmitting(false);
            setLoading(false);
            setError(error);
            // setSelectedSalutation();
            // formik.resetForm();
            // setchecked((prev) => {
            //   legalTexts.forEach((ele, index) => {
            //     formik.setFieldValue(`checked_${index}`, false);
            //     prev[`checked_${index}`] = false;
            //   });
            //   return { ...prev };
            // });
          });
      } catch (error) {
        setLoading(false);
        actions.setSubmitting(false);
        // formik.resetForm();
        throw error;
      }
    },
  });
  const handleClose = () => {
    setError("");
    closeModal();
    setadditionalFields({});
    //formik.setFieldValue('salutation','')
    // formik.values = Object.assign({}, formik.initialValues);
    // formik.errors = {};
    // formik.resetForm();
  };

  const autoFillPrivate = async () => {
    await formik.setFieldValue(
      "personalAddress",
      user?.personalAddress?.address_1
    );
    await formik.setFieldValue("personalCity", user?.personalAddress?.city);
    await formik.setFieldValue(
      "personal_zip_code",
      user?.personalAddress?.zip_code,
      false
    );
    countries.map(async (country) => {
      if (
        country.name.toLocaleLowerCase() ===
        user?.personalAddress?.country.toLocaleLowerCase()
      ) {
        await formik.setFieldValue("personal_country_id", country.id);
        setSelectedCountryPrivate(country);
      }
    });
  };

  const autoFillCompany = async () => {
    await formik.setFieldValue("company", user?.companyAddress?.name);
    await formik.setFieldValue("address", user?.companyAddress?.address_1);
    await formik.setFieldValue("city", user?.companyAddress?.city);
    await formik.setFieldValue(
      "zip_code",
      user?.companyAddress?.zip_code,
      false
    );
    countries.map(async (country) => {
      if (
        country.name.toLocaleLowerCase() ===
        user?.companyAddress?.country.toLocaleLowerCase()
      ) {
        await formik.setFieldValue("country_id", country.id);
        setSelectedCountryCompany(country);
      }
    });
  };

  // const handleTouched = (fieldName) => {
  //   formik.setTouched({ ...formik.touched, [fieldName]: true }, true);
  // };

  useEffect(() => {
    formik.setFieldValue(
      "salutation",
      user?.personalInformation?.salutation_id
    );
    if (user?.personalInformation?.salutation_id) {
      setSelectedSalutation(
        SALUATION_OPTIONS.find(
          (item) => item.id === user?.personalInformation?.salutation_id
        )
      );
    }
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size={
          showLegalTextModal?.length > 0 && showLegalTextModal.includes(true)
            ? "xl"
            : "lg"
        }
        dialogClassName={"buynow-modal"}
        centered
        backdrop="static"
        keyboard={false}
        contentClassName={`${
          showLegalTextModal?.length > 0 && showLegalTextModal.includes(true)
            ? "d-none"
            : ""
        }`}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({ id: "SHARED_COMPONENTS_EVENT_REGISTRATION" })}
          </Modal.Title>
        </Modal.Header>
        {!!Error ? (
          <div className="alert alert-danger rounded-0">{Error}</div>
        ) : null}
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            {/* <div className="container"> */}
            <div className="row my-2 mx-2">
              {/* {contactId ? null : ( */}
              <>
                <div className="px-2 mb-3">
                  <InputLabel
                    required
                    text={intl.formatMessage({
                      id: "TITLE_SALUTATION",
                    })}
                  />
                  <CustomDropDown
                    id="salutationDropDown"
                    disabled={!!isAuthorized}
                    hideSearch
                    // size="small"
                    items={SALUATION_OPTIONS}
                    selectedItem={selectedSalutation}
                    // @ts-ignore
                    onSelectItem={(item) => {
                      formik.setFieldValue("salutation", item.id);
                      setSelectedSalutation(item);
                    }}
                    displayValue={selectedSalutation?.name || ""}
                    // @ts-ignore
                    displayFunc={(item) => {
                      return item.name;
                    }}
                    isError={
                      formik.touched.salutation && formik.errors.salutation
                    }
                  />
                  {formik.touched.salutation && formik.errors.salutation && (
                    <div
                      style={{
                        fontSize: "13px",
                        color: "var(--error-alert)",
                      }}
                    >
                      <span role="alert">{formik.errors.salutation}</span>
                    </div>
                  )}
                </div>
                <div className="d-md-flex px-0">
                  <div className="px-2 mb-3 flex-fill">
                    <InputLabel
                      required
                      text={intl.formatMessage({ id: "LABEL_FIRST_NAME" })}
                    />

                    <TextInput
                      value={formik.values.firstName}
                      disabled={!!isAuthorized}
                      onChange={(value) => {
                        formik.setFieldValue("firstName", value);
                      }}
                      isError={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div
                        style={{
                          fontSize: "13px",
                          color: "var(--error-alert)",
                        }}
                      >
                        <span role="alert">{formik.errors.firstName}</span>
                      </div>
                    )}
                  </div>
                  <div className="px-2 mb-3 flex-fill">
                    <InputLabel
                      required
                      text={intl.formatMessage({ id: "LABEL_LAST_NAME" })}
                    />

                    <TextInput
                      value={formik.values.lastName}
                      disabled={!!isAuthorized}
                      onChange={(value) => {
                        formik.setFieldValue("lastName", value);
                      }}
                      isError={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div
                        style={{
                          fontSize: "13px",
                          color: "var(--error-alert)",
                        }}
                      >
                        <span role="alert">{formik.errors.lastName}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="px-2 mb-3">
                  <InputLabel
                    required
                    text={intl.formatMessage({ id: "LABEL_EMAIL" })}
                  />

                  <TextInput
                    value={formik.values.email}
                    disabled={!!isAuthorized}
                    onChange={(value) => {
                      formik.setFieldValue("email", value);
                    }}
                    isError={formik.touched.email && formik.errors.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div
                      style={{
                        fontSize: "13px",
                        color: "var(--error-alert)",
                      }}
                    >
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  )}
                </div>
              </>
              {/* )} */}

              {flow === "offers" ? (
                <>
                  <div className="d-md-flex px-0">
                    <div className="px-2 mb-3 flex-fill d-flex flex-row">
                      <div
                        className="d-flex flex-row cursor-pointer"
                        onClick={() => {
                          setUserType("private");
                        }}
                      >
                        <input
                          //className='form-check-input me-3'
                          //name='user_role'
                          type="radio"
                          checked={userType === "private"}
                          onChange={() => {}}
                          className="mb-1"
                          style={{
                            minWidth: "20px",
                            marginRight: "12px",
                            minHeight: "20px",
                            cursor: "inherit",
                          }}
                        />
                        <InputLabel text="Private" />
                      </div>
                    </div>
                    <div className="px-2 mb-3 flex-fill d-flex flex-row">
                      <div
                        onClick={() => {
                          setUserType("company");
                          // setShowFillBox(true);
                        }}
                        className="d-flex flex-row cursor-pointer"
                      >
                        <input
                          //className='form-check-input me-3'
                          //name='user_role'
                          type="radio"
                          checked={userType === "company"}
                          onChange={() => {}}
                          className="mb-1"
                          style={{
                            minWidth: "20px",
                            marginRight: "12px",
                            minHeight: "20px",
                            cursor: "inherit",
                          }}
                        />
                        <InputLabel text="Company" />
                      </div>
                    </div>
                  </div>
                  {userType === "company" ? (
                    <>
                      {showFillBoxCompany && isAuthorized && !sysAdmin ? (
                        <div className=" px-2 mb-3">
                          <div
                            className=" d-flex flex-row justify-content-between p-2"
                            style={{ border: "1px dashed var(--accent-color)" }}
                          >
                            <div className="">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Consequuntur, ad!
                            </div>
                            <div className="">
                              <span
                                className="mx-2 cursor-pointer"
                                style={{ color: "var(--accent-color)" }}
                                onClick={() => {
                                  if (user?.companyAddress) {
                                    autoFillCompany();
                                  }
                                  setShowFillBoxCompany(false);
                                }}
                              >
                                yes
                              </span>
                              <span
                                className="mx-2 cursor-pointer"
                                style={{ color: "var(--accent-color)" }}
                                onClick={() => {
                                  setShowFillBoxCompany(false);
                                }}
                              >
                                no
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="px-2 mb-3">
                        <InputLabel required text="Company Name" />

                        <TextInput
                          value={formik.values.company}
                          onChange={(value) => {
                            formik.setFieldValue("company", value);
                          }}
                          isError={
                            formik.touched.company && formik.errors.company
                          }
                        />
                        {formik.touched.company && formik.errors.company && (
                          <div
                            style={{
                              fontSize: "14px",
                              color: "var(--error-alert)",
                            }}
                          >
                            <span role="alert">{formik.errors.company}</span>
                          </div>
                        )}
                      </div>

                      <div className="px-2 mb-3">
                        <InputLabel required text="Address" />

                        <TextInput
                          value={formik.values.address}
                          onChange={(value) => {
                            formik.setFieldValue("address", value);
                          }}
                          isError={
                            formik.touched.address && formik.errors.address
                          }
                        />
                        {formik.touched.address && formik.errors.address && (
                          <div
                            style={{
                              fontSize: "14px",
                              color: "var(--error-alert)",
                            }}
                          >
                            <span role="alert">{formik.errors.address}</span>
                          </div>
                        )}
                      </div>

                      <div className="d-md-flex px-0">
                        <div className="px-2 mb-3 flex-fill">
                          <InputLabel required text="City" />

                          <TextInput
                            value={formik.values.city}
                            onChange={(value) => {
                              formik.setFieldValue("city", value);
                            }}
                            isError={formik.touched.city && formik.errors.city}
                          />
                          {formik.touched.city && formik.errors.city && (
                            <div
                              style={{
                                fontSize: "14px",
                                color: "var(--error-alert)",
                              }}
                            >
                              <span role="alert">{formik.errors.city}</span>
                            </div>
                          )}
                        </div>
                        <div className="px-2 mb-3 flex-fill">
                          <InputLabel required text="Zip Code" />

                          <TextInput
                            value={formik.values.zip_code}
                            onChange={(value) => {
                              formik.setFieldValue("zip_code", value);
                            }}
                            isError={
                              formik.touched.zip_code && formik.errors.zip_code
                            }
                          />
                          {formik.touched.zip_code &&
                            formik.errors.zip_code && (
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "var(--error-alert)",
                                }}
                              >
                                <span role="alert">
                                  {formik.errors.zip_code}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="px-2 mb-3">
                        <InputLabel
                          required
                          text={intl.formatMessage({
                            id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_COUNTRY_LABEL",
                          })}
                        />
                        <CustomDropDown
                          id="countriesDropDown"
                          //   hideSearch
                          items={countries}
                          selectedItem={selectedCountryCompany}
                          // size="small"
                          // @ts-ignore
                          onSelectItem={(item) => {
                            formik.setFieldValue("country_id", item.id);
                            setSelectedCountryCompany(item);
                          }}
                          displayValue={selectedCountryCompany?.name || ""}
                          // @ts-ignore
                          displayFunc={(item) => {
                            return item.emoji + " " + item.name;
                          }}
                          isError={
                            formik.touched.country_id &&
                            formik.errors.country_id
                          }
                        />

                        {formik.touched.country_id &&
                          formik.errors.country_id && (
                            <div className="text-danger">
                              <span role="alert">
                                {formik.errors.country_id}
                              </span>
                            </div>
                          )}
                      </div>
                    </>
                  ) : (
                    <>
                      {showFillBoxPrivate && isAuthorized ? (
                        <div className=" px-2 mb-3">
                          <div
                            className=" d-flex flex-row justify-content-between p-2"
                            style={{ border: "1px dashed var(--accent-color)" }}
                          >
                            <div className="">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Consequuntur, ad!
                            </div>
                            <div className="">
                              <span
                                className="mx-2 cursor-pointer"
                                style={{ color: "var(--accent-color)" }}
                                onClick={() => {
                                  if (user?.personalAddress) {
                                    autoFillPrivate();
                                  }
                                  setShowFillBoxPrivate(false);
                                }}
                              >
                                yes
                              </span>
                              <span
                                className="mx-2 cursor-pointer"
                                style={{ color: "var(--accent-color)" }}
                                onClick={() => {
                                  setShowFillBoxPrivate(false);
                                }}
                              >
                                no
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="px-2 mb-3">
                        <InputLabel required text="Personal Address" />

                        <TextInput
                          value={formik.values.personalAddress}
                          onChange={(value) => {
                            formik.setFieldValue("personalAddress", value);
                          }}
                          isError={
                            formik.touched.personalAddress &&
                            formik.errors.personalAddress
                          }
                        />
                        {formik.touched.personalAddress &&
                          formik.errors.personalAddress && (
                            <div
                              style={{
                                fontSize: "14px",
                                color: "var(--error-alert)",
                              }}
                            >
                              <span role="alert">
                                {formik.errors.personalAddress}
                              </span>
                            </div>
                          )}
                      </div>

                      <div className="d-md-flex px-0">
                        <div className="px-2 mb-3 flex-fill">
                          <InputLabel required text="City" />

                          <TextInput
                            value={formik.values.personalCity}
                            onChange={(value) => {
                              formik.setFieldValue("personalCity", value);
                            }}
                            isError={
                              formik.touched.personalCity &&
                              formik.errors.personalCity
                            }
                          />
                          {formik.touched.personalCity &&
                            formik.errors.personalCity && (
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "var(--error-alert)",
                                }}
                              >
                                <span role="alert">
                                  {formik.errors.personalCity}
                                </span>
                              </div>
                            )}
                        </div>
                        <div className="px-2 mb-3 flex-fill">
                          <InputLabel required text="Zip Code" />

                          <TextInput
                            value={formik.values.personal_zip_code}
                            onChange={(value) => {
                              formik.setFieldValue("personal_zip_code", value);
                            }}
                            isError={
                              formik.touched.personal_zip_code &&
                              formik.errors.personal_zip_code
                            }
                          />
                          {formik.touched.personal_zip_code &&
                            formik.errors.personal_zip_code && (
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "var(--error-alert)",
                                }}
                              >
                                <span role="alert">
                                  {formik.errors.personal_zip_code}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="px-2 mb-3">
                        <InputLabel
                          required
                          text={intl.formatMessage({
                            id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_COUNTRY_LABEL",
                          })}
                        />
                        <CustomDropDown
                          id="countriesDropDown"
                          //   hideSearch
                          items={countries}
                          selectedItem={selectedCountryPrivate}
                          // size="small"
                          // @ts-ignore
                          onSelectItem={(item) => {
                            formik.setFieldValue(
                              "personal_country_id",
                              item.id
                            );
                            setSelectedCountryPrivate(item);
                          }}
                          displayValue={selectedCountryPrivate?.name || ""}
                          // @ts-ignore
                          displayFunc={(item) => {
                            return item.emoji + " " + item.name;
                          }}
                          isError={
                            formik.touched.personal_country_id &&
                            formik.errors.personal_country_id
                          }
                        />

                        {formik.touched.personal_country_id &&
                          formik.errors.personal_country_id && (
                            <div className="text-danger">
                              <span role="alert">
                                {formik.errors.personal_country_id}
                              </span>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                </>
              ) : null}

              {legalTexts &&
                legalTexts.length > 0 &&
                legalTexts.map((ele, index) => {
                  return (
                    <>
                      <div className={`d-flex ${index !== 0 ? "mt-3" : ""}`}>
                        <input
                          type="checkbox"
                          name={`checked_${index}`}
                          value={formik.values[`checked_${index}`]}
                          label={legalString?.[index]}
                          // required={!!ele?.required}
                          onBlur={formik.handleBlur}
                          onChange={(event) => handleChecked(event, index)}
                          key={index}
                        />
                        <div className="d-inline">
                          {/* <span
                          style={{ verticalAlign: "super" }}
                        ></span> */}
                          {legalString?.[index] &&
                          Array.isArray(legalString?.[index]) ? (
                            <>
                              <span style={{ verticalAlign: "super" }}>
                                <span>
                                  {" "}
                                  {legalString?.[index][0].split("<p>")[1]}
                                </span>
                                <button
                                  className="read-more-btn border-0 bg-white text-decoration-underline "
                                  onClick={() => handleShowModal(index)}
                                  style={{ padding: "0px 5px" }}
                                  type="button"
                                >
                                  {legalTexts?.[index]?.legal_text_name}
                                </button>
                                <span>
                                  {" "}
                                  {legalString?.[index][1].split("</p>")[0]}
                                </span>
                              </span>
                            </>
                          ) : (
                            <>
                              <span>
                                {legalTexts?.[
                                  index
                                ]?.legal_text_element?.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                )}
                              </span>
                              <button
                                className="read-more-btn border-0 bg-white text-decoration-underline "
                                onClick={() => handleShowModal(index)}
                                style={{ padding: "0px 10px" }}
                                type="button"
                              >
                                {intl.formatMessage({ id: "BUTTON_READ_MORE" })}
                              </button>
                            </>
                          )}
                          {formik.touched[`checked_${index}`] &&
                            formik.errors[`checked_${index}`] && (
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "var(--font-Family)",
                                  color: "var(--error-alert)",
                                  // position: "relative",
                                  // top: "-10px",
                                }}
                              >
                                {formik.errors[`checked_${index}`]}
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
              {flow === "products" || flow === "subscription" ? (
                <AdditionalFields setadditionalFields={setadditionalFields} />
              ) : null}
            </div>
          </Modal.Body>
          {/* </div> */}
          <Modal.Footer
            className="d-flex align-items-center justify-content-center"
            style={{ columnGap: "12px" }}
          >
            {/* <div className="d-flex justify-content-center align-items-center mt-3"> */}
            <button
              id="button-custom-two"
              style={{
                width: isXsScreen ? "100%" : "fit-content",
                height: "fit-content",
                background: "rgb(0,0,0,0.4)",
                border: "1px solid grey",
                marginRight: "0px",
              }}
              type="button"
              className=" my-2"
              onClick={() => handleClose()}
            >
              {intl.formatMessage({ id: "BUTTON_CANCEL" })}{" "}
            </button>
            <button
              id="button-custom-two"
              style={{
                width: isXsScreen ? "100%" : "fit-content",
                height: "fit-content",
                display: "flex",
                alignItems: "center",
                marginLeft: "0px",
              }}
              disabled={formik.isSubmitting}
              type="submit"
              className="my-2 justify-content-center"
            >
              <div>{intl.formatMessage({ id: "BUTTON_SUBMIT" })} </div>
              {Loading ? (
                <Oval
                  height={15}
                  width={15}
                  color="white"
                  wrapperStyle={{
                    display: "inline",
                    marginLeft: "1rem",
                    verticalAlign: "middle",
                    padding: "0px",
                  }}
                  wrapperClass="py-0"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="white"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              ) : null}
            </button>
            {/* </div> */}
          </Modal.Footer>
        </form>
      </Modal>
      {legalTexts &&
        legalTexts.length > 0 &&
        legalTexts.map((ele, index) => (
          <LegalTextModal
            handleClose={handleCloseModal}
            show={showLegalTextModal[index]}
            versionId={ele?.legal_text_version_id}
            index={index}
            key={index}
          />
        ))}
    </>
  );
};
export default EventRegisterModal;
