import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchPaymentStatus } from "../../API/Api";
import CashGif from "../../assets/cash.gif";
import CheckMark from "../CheckMark";
const PaymentSuccess = () => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const [response, setResponse] = useState({});
  const id = searchParams.get("id");
  const orderNumber = searchParams.get("orderNumber");
  const defaultPage = useSelector(
    (state) => state?.navigationMenu?.defaultPage
  );
  // console.log(defaultPage)
  const navigate = useNavigate();
  useEffect(() => {
    if (defaultPage) {
      if (id && orderNumber) {
        const paramsObject = {
          order_number: orderNumber,
          id,
          status: "success",
        };
        fetchPaymentStatus(paramsObject).then((response) => {
          setTimeout(() => {
            setResponse(response);
            setTimeout(() => navigate(`/`), 5000);
          }, 2000);
        });
      }
    }
  }, [defaultPage]);
  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ height: "50vh" }}
    >
      <div className="d-flex justify-content-center align-items-center fw-bold flex-column">
        {response && Object.entries(response).length > 0 ? (
          <div>
            <CheckMark />
            {/* <Player
              autoplay
              loop={false}
              src="https://assets6.lottiefiles.com/packages/lf20_d6mfskxi.json"
              style={{ height: "300px", width: "300px" }}
              keepLastFrame={true}
            ></Player> */}
            {response?.accountType === "existing" ? (
              <p style={{ fontSize: "20px" }}>
                {intl.formatMessage({ id: "PAYMENT_SUCCESS_MESSAGE" })}
              </p>
            ) : (
              <p style={{ fontSize: "20px" }}>
                {intl.formatMessage({ id: "PAYMENT_SUCCESS_MESSAGE_NEW_USER" })}
              </p>
            )}
          </div>
        ) : (
          <>
            <img src={CashGif} style={{ width: "100px", height: "100px" }} alt=''/>
            <div>
              {intl.formatMessage({ id: "PAYMENT_INPROGRESS_MESSAGE" })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
