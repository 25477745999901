import { convert } from "html-to-text";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { useLocal } from "../../App";

const GeneralSearchList = ({ SearchData, type, currentPage }) => {
  const locale = useLocal();
  return SearchData && SearchData.length > 0 ? (
    <>
      {SearchData.map((data, index) => {
        return (
          <div
            key={data.id}
            className="d-flex "
            style={{
              paddingBottom: "40px",
              paddingLeft: "55px",
              fontSize: "13px",
              clear: "both",
            }}
          >
            <span
              style={{
                marginLeft: "-44px",
                fontSize: "18px",
                height: "44px",
                lineHeight: "24px",
                textAlign: "center",
                fontWeight: "bold",
                padding: "0 15px",
              }}
            >
              {(currentPage - 1) * 10 + index + 1}
            </span>
            <div>
              <header
                style={{
                  margin: 0,
                  padding: 0,
                  border: 0,
                  verticalAlign: "baseline",
                  display: "flex",
                }}
              >
                <Link
                  to={
                    type === "posts"
                      ? data?.seo_permalink
                        ? `../post/${data.id}/${data?.seo_permalink}`
                        : `../post/${data.id}/${data?.title?.replace(
                            /\s+/g,
                            "-"
                          )}`
                      : type === "event-list"
                      ? data?.seo_permalink
                        ? `../event/${data.id}/${data?.seo_permalink}`
                        : `../event/${data.id}/${data?.title?.replace(
                            /\s+/g,
                            "-"
                          )}`
                      : `../event/${data.id}/${data?.seo_permalink}`
                  }
                  state={
                    type === "event-list"
                      ? { eventId: data.id }
                      : { postId: data.id }
                  }
                >
                  <h2
                    style={{
                      textTransform: "uppercase",
                      padding: "0px 0 15px",
                      lineHeight: "1.3em",
                      //   marginBottom: "10px",
                      color: "var(--accent-color)",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    {data.title}
                  </h2>
                </Link>
              </header>
              <span
                style={{
                  marginTop: "-20px",
                  textAlign: "left",
                  border: "none",
                  padding: "10px 0",
                  fontSize: "14px",
                }}
              >
                {data?.published_at
                  ? locale === "en"
                    ? moment(data?.published_at).format("DD/MM/yyyy")
                    : moment(data?.published_at).format("DD.MM.yyyy")
                  : data?.start_date
                  ? locale === "en"
                    ? moment(data?.start_date).format("DD/MM/yyyy")
                    : moment(data?.start_date).format("DD.MM.yyyy")
                  : locale === "en"
                  ? moment(data?.published_at).format("DD/MM/yyyy")
                  : moment(data?.published_at).format("DD.MM.yyyy")}
              </span>
              <p
                style={{
                  fontSize: "16px",
                  marginTop: "1rem",
                }}
              >
                {convert(data.introduction)}
              </p>
            </div>
          </div>
        );
      })}
    </>
  ) : null;
};

export default GeneralSearchList;
