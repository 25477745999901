export const GET_GENERAL_DETAILS = "GET_GENERAL_DETAILS";
export const SET_GENERAL_DETAILS = "SET_GENERAL_DETAILS";
export const GET_LEGAL_TEXT = "GET_LEGAL_TEXT";
export const SET_LEGAL_TEXT = "SET_LEGAL_TEXT";
export const GET_SIDEBAR_POSTS = "GET_SIDEBAR_POSTS";
export const SET_SIDEBAR_POSTS_SUCCESS = "SET_SIDEBAR_POSTS_SUCCESS";
export const SET_SIDEBAR_POSTS_FAIL = "SET_SIDEBAR_POSTS_FAIL";

const initialState = {
  generalDetails: {},
  legalText: {},
  isLoading: false,
};

export const getGeneralDetails = () => {
  return { type: GET_GENERAL_DETAILS };
};

export const setGeneralDetails = (details) => {
  return { type: SET_GENERAL_DETAILS, payload: details };
};

export const getLegalTextDetails = (id) => {
  return { type: GET_LEGAL_TEXT, payload: id };
};
export const setLegalTextDetails = (data) => {
  return { type: SET_LEGAL_TEXT, payload: data };
};

export const getSidebarPosts = ({
  paramsObject,
  id,
  type,
  sectionId,
  pageType,
  title,
}) => {
  return {
    type: GET_SIDEBAR_POSTS,
    payload: { paramsObject, id, type, sectionId, pageType, title },
  };
};

export const setSidebarPostsSuccess = (data) => {
  return { type: SET_SIDEBAR_POSTS_SUCCESS, payload: data };
};
export const setSidebarPostsFail = (error) => {
  return { type: SET_SIDEBAR_POSTS_FAIL, payload: error };
};

const generalDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERAL_DETAILS: {
      return { ...state, isLoading: true };
    }
    case SET_GENERAL_DETAILS: {
      return { ...state, isLoading: false, generalDetails: action.payload };
    }
    case GET_LEGAL_TEXT: {
      return { ...state, isLoading: true };
    }
    case SET_LEGAL_TEXT: {
      const id = action.payload?.version_id;
      return {
        ...state,
        isLoading: false,
        legalText: { ...state.legalText, [id]: action.payload },
      };
    }
    case GET_SIDEBAR_POSTS: {
      return { ...state, sidebar: { isLoading: true } };
    }
    case SET_SIDEBAR_POSTS_SUCCESS: {
      const { eventDetails, id, sectionId, type, postDetails, pageType } =
        action.payload;
      const title = action?.payload?.title ? action?.payload?.title : "Title";
      const uniqueId = `${sectionId}_${id}`;
      let postListData =
        postDetails &&
        postDetails.reduce((acc, ele) => {
          acc[ele.id] = ele;
          return acc;
        }, {});
      let eventListData =
        eventDetails &&
        eventDetails.reduce((acc, ele) => {
          acc[ele.id] = ele;
          return acc;
        }, {});
      return type.startsWith("post")
        ? {
            ...state,
            sidebar: {
              isLoading: false,
              ...state?.sidebar,
              [pageType]: {
                ...state?.sidebar?.[pageType],
                [uniqueId]: postListData,
              },
            },
          }
        : {
            ...state,
            sidebar: {
              isLoading: false,
              [pageType]: {
                ...state?.sidebar?.[pageType],
                [uniqueId]: eventListData,
              },
            },
          };
    }
    case SET_SIDEBAR_POSTS_FAIL: {
      return {
        ...state,
        error: action?.payload,
        sidebar: { ...state?.sidebar, isLoading: false },
      };
    }
    default:
      return { ...state };
  }
};

export default generalDetailsReducer;
