import React from "react";
import { Link } from "react-router-dom";
import noImage from "../../assets/noImage.jpg";
import { formatDate, useCustomMediaQuery } from "../../utils";
import "./style.scss";
const CardArticle = ({ details, className }) => {
  const { resolution } = useCustomMediaQuery();
  const { event_image_url, event_image_resolutions, id } = details;
  const setPostMediaSource = () => {
    if (event_image_resolutions && event_image_resolutions.length > 0) {
      const source = event_image_resolutions.filter(
        (element) => element?.resolution === resolution
      );
      return source[0].file_url;
    }
    return event_image_url ? event_image_url : noImage;
  };

  return (
    <div className={`${className}`}>
      <div className="card event-card mb-2">
        <div className="card-image event-image rounded-top">
          {/* <div className="event-tag">{details?.event_mode_name}</div> */}
          <Link
            to={
              details?.seo_permalink
                ? `../event/${id}/${details?.seo_permalink}`
                : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
            }
            state={{ eventId: id }}
          >
            <img src={setPostMediaSource()} alt="card" />
          </Link>
        </div>
        <div className="card-body d-flex  align-items-center justify-content-start">
          <div className="event-date">
            <h5 className="mb-0">
              {/* <AiOutlineCalendar style={{ verticalAlign: "text-top" }} /> */}
              {formatDate(details?.start_date).split(" ")[0].substring(0, 3)}
            </h5>
            <h5 className="mb-0 text-center">
              {parseInt(formatDate(details?.start_date).split(" ")[1])}
            </h5>
          </div>
          <div>
            <div className="event-title">
              <Link
                to={
                  details?.seo_permalink
                    ? `../event/${id}/${details?.seo_permalink}`
                    : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
                }
                state={{ eventId: id }}
              >
                <h6>{details?.title}</h6>
              </Link>
            </div>
            {/* {!!details?.sub_title && <p className="mt-2 event-subtitle">{details?.sub_title}</p> }  */}
          </div>
          {/* <span className="mb-1">
            <SlLocationPin style={{ marginRight: "10px" }} />
            <p className="d-inline">Portugal ,Spain</p>
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default CardArticle;
