import { Player } from "@lottiefiles/react-lottie-player";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import CustomFooter from "../../../sharedComponents/Footer/CustomFooter";
import InputLabel from "../../../sharedComponents/InputLabel/InputLabel";
import TextInput from "../../../sharedComponents/TextInput/TextInput";
import { useCustomMediaQuery } from "../../../utils";
const ForgotPasswordForm = () => {
  const intl = useIntl();
  // const generalDetails = useSelector(
  //   (state) => state.generalDetails.generalDetails
  // );
  const user_sign_In = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings?.user_sign_In
  );
  // const navigate = useNavigate();
  // const locale = localStorage.getItem("language");
  const {
    isXsScreen,
    // isSmScreen,
    // isMdScreen,
    isLgScreen,
    isXLgScreen,
    isXxLgScreen,
  } = useCustomMediaQuery();
  const initialValues = {
    email: "",
  };
  const [resStatus, setresStatus] = useState({});
  // const [error, seterror] = useState(false);
  const [Loading, setLoading] = useState(false);
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "USERS_WRONG_EMAIL_FORMAT" }))
      .required(intl.formatMessage({ id: "USERS_WRONG_EMAIL_FORMAT" })),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      actions.setSubmitting(true);
      try {
        axios
          .post("/forgotPassword/email", {
            email: values.email,
            technology: "app",
          })
          .then((response) => {
            if (response.status === 200) {
              setLoading(false);
              setresStatus(response.data);
              actions.setSubmitting(false);
              setLoading(false);
            }
            if (response.status === 210) {
              setLoading(false);
              // seterror(true);
              setresStatus(response.data);
              actions.setSubmitting(false);
              setLoading(false);
            }
          });
      } catch (error) {
        setresStatus(error.response.data);
        actions.setSubmitting(false);
        setLoading(false);
        throw error;
      }
      actions.resetForm();
    },
  });

  return (
    <div className="">
      <div
        className="container"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="row d-flex align-items-center justify-content-center mt-4 mx-auto"
          style={{
            width: isXsScreen ? "100%" : "90%",
            boxShadow:
              "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          {isLgScreen || isXLgScreen || isXxLgScreen ? (
            <div Name="col-lg-6 col-xl-6 py-5">
              <Player
                autoplay
                loop={true}
                src="https://assets1.lottiefiles.com/packages/lf20_iwyr6aqu.json"
                style={{ height: "400px", width: "400px" }}
                // keepLastFrame={true}
              ></Player>
            </div>
          ) : null}
          <div className="col-md-12 col-lg-5 col-xl-5">
            <div className="p-4">
              <div className="text-center mb-5">
                {/* <img
                src={generalDetails?.logo_url}
                className="img-fluid logo"
                style={{ maxHeight: "64px" }}
              /> */}
                <h5 className="">
                  {intl.formatMessage({ id: "FORGOT_PASSWORD" })} ?
                </h5>
                <p className="mt-2" style={{ color: "rgb(0,0,0,0.5)" }}>
                  {intl.formatMessage({ id: "USER_PASSWORD_RESET_MESSAGE" })}
                </p>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <div className="mb-4 position-relative">
                  {/* <input
                id="form-input"
                name="email"
                label="Email"
                type="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="example@email.com"
              />
              <label placeholder="Email" alt="Email" id="form-label"></label>
              {formik.touched.email && formik.errors && formik.errors.email && (
                <div
                role="alert "
                className="position-absolute"
                style={{
                  top: "55px",
                  color: "var(--error-alert)",
                  fontSize: "12px",
                }}
                >
                  {formik.errors?.email}
                </div>
              )} */}
                  <InputLabel
                    required
                    text={intl.formatMessage({ id: "LABEL_EMAIL" })}
                  />

                  <TextInput
                    value={formik.values.email}
                    onChange={(value) => {
                      formik.setFieldValue("email", value);
                    }}
                    isError={formik.touched.email && formik.errors.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  )}
                </div>
                <div className="pb-lg-0 d-flex justify-content-between align-items-center flex-column">
                  <button
                    type="submit"
                    id="button-custom-two"
                    // id='kt_password_reset_submit'
                    disabled={formik.isSubmitting}
                    className="mx-0"
                    style={{ width: "100%", borderRadius: "3px" }}
                  >
                    {intl.formatMessage({ id: "BUTTON_CONTINUE" })}{" "}
                    {Loading ? (
                      <Oval
                        height={20}
                        width={15}
                        color="white"
                        wrapperStyle={{
                          display: "inline-block",
                          marginLeft: "2rem",
                          verticalAlign: "middle",
                          padding: "0px",
                        }}
                        wrapperClass="py-0"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="white"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                      />
                    ) : null}
                    {/* {!loading && intl.formatMessage({id:'SAVE_BUTTON'})}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id:'WORDPRESS_LOADING_MESSAGE'})}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                  )} */}
                  </button>
                  {user_sign_In && user_sign_In === "no" ? (
                    <Link
                      to="/"
                      className="mt-4"
                      style={{ color: "var(--accent-color)" }}
                    >
                      {intl.formatMessage({ id: "BUTTON_BACK_TO_HOME" })}
                    </Link>
                  ) : (
                    <Link
                      to="/login"
                      className="mt-4"
                      style={{ color: "var(--accent-color)" }}
                    >
                      {intl.formatMessage({ id: "MESSAGE_BACK_TO_LOGIN" })}
                    </Link>
                  )}

                  {resStatus && Object.values(resStatus).length > 0 ? (
                    <div
                      className={`mb-10 mt-3 ${
                        resStatus.status === "Success"
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }`}
                      style={{
                        padding: "10px",
                        borderRadius: "3px",
                        textAlign: "start",
                        width: "100%",
                      }}
                    >
                      <div className="alert-text font-weight-bold text-center">
                        {resStatus.status === "Success"
                          ? intl.formatMessage({
                              id: "USER_AUTHENTICATION_CODE_SUCCESS",
                            })
                          : intl.formatMessage({
                              id: "MESSAGE_CONTACT_SYS_ADMIN",
                            })}
                      </div>
                    </div>
                  ) : null}
                  {/* <button
              type="button"
              // id="button-custom"
              //   id='kt_login_password_reset_form_cancel_button'
              className="fw-bolder mx-0 "
              // onClick={() => navigate("../login")}
            >
              {/* {intl.formatMessage({id:'AUTH.CODE_VERIFICATION.CANCEL'})} */}

                  {/* </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
        <CustomFooter />
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
