import React from "react";
import PersonalInformation from "./PersonalInformation";

function AccountInformation({ userDetails, getCustomerDetailsAPI }) {
  return (
    <>
      <PersonalInformation
        userDetails={userDetails}
        getCustomerDetailsAPI={getCustomerDetailsAPI}
      />
    </>
  );
}

export default AccountInformation;
