import React, { useState } from "react";
import PreviewModal from "./PreviewModal";

const ImageCard = ({ img, details, sideBar }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const setPostMediaSource = () => {
    if (details?.resolutions && details?.resolutions.length > 0) {
      const source = details?.resolutions.filter(
        (element) => element?.resolution === "512x288"
      );
      return source[0].file_url;
    }
    return details?.file_path;
  };
  return (
    <>
      {showPreviewModal && (
        <PreviewModal
          show={showPreviewModal}
          closeModal={() => {
            setShowPreviewModal(false);
          }}
          image={setPostMediaSource()}
          id={details?.id}
          name={details?.name}
        />
      )}
      <div
        className="img-container rounded-1 position-relative img-hover-preview cursorPointer"
        onClick={() => {
          setShowPreviewModal(true);
        }}
      >
        {/* <LazyLoadImage src={img} effect='blur' placeholderSrc={placeHolderComponent} wrapperClassName='img img-fluid'/> */}
        <img
          className="img img-fluid"
          src={setPostMediaSource()}
          alt="card_image"
        />
        {/* <FaRegEye
          onClick={() => {
            setShowPreviewModal(true);
          }}
          className="preview-icon position-absolute"
        /> */}
      </div>
    </>
  );
};

export default ImageCard;
