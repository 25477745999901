import moment from "moment";
import React from "react";
import { BsChat, BsThreeDots } from "react-icons/bs";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

// import { useParams } from "react-router";
import { deleteComment } from "../Redux/CommunitiesAPI";

const CommentCard = ({
  rating,
  details,
  getPostsAPI,
  shouldShowHideReplies,
  depth,
  handleReplyItem,
  hideReplies,
  getShowModal,
  setInputAndType,
  commentRef,
  communities = false,
}) => {
  const todayDate = moment().toDate();

  const intl = useIntl();

  // const { id } = useParams();

  const userInfo = useSelector(
    (state) => state.auth?.user?.personalInformation
  );

  const display_posts_rating_in_web_and_app = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.posts_general_settings
        ?.display_posts_rating_in_web_and_app
  );

  const handleDeleteComment = () => {
    deleteComment(details.communities_id, rating.id).finally(() => {
      getPostsAPI();
    });
  };

  const getTotalCommentsRecursive = (items, lengthObject) => {
    items.forEach((data) => {
      lengthObject.length = lengthObject.length + data.replies.length;
      if (data.replies.length > 0) {
        getTotalCommentsRecursive(data.replies, lengthObject);
      }
    });
  };

  const getTotalComments = (items) => {
    let lengthObject = {
      length: 0,
    };
    getTotalCommentsRecursive(items, lengthObject);

    return lengthObject.length;
  };

  const repliesLength =
    depth === 3 ? getTotalComments([rating]) : rating.replies.length;

  return (
    <>
      {(display_posts_rating_in_web_and_app === "yes" || communities) && (
        <div
          className="mt-4"
          style={{ marginLeft: depth >= 2 ? "20px" : "0px" }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-row">
              <div style={{ marginLeft: "-2px" }}>
                {rating?.users_profile_photo_url ? (
                  <div className="ratingAddProfile">
                    <img
                      src={rating.users_profile_photo_url}
                      height={50}
                      width={50}
                      style={{ borderRadius: "50px" }}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="ratingAddProfile">
                    {`${rating?.users_username.toUpperCase()[0]}`}
                  </div>
                )}
              </div>
              <div style={{ marginLeft: "5px" }}>
                <h5 style={{ marginBottom: "1px" }}>{rating.users_username}</h5>
                <span>
                  {moment(todayDate).diff(rating.created_at, "days") >= 1
                    ? moment(todayDate).diff(rating.created_at, "days") +
                      "days ago"
                    : "Today"}
                </span>
              </div>
            </div>
            {userInfo &&
              (userInfo.id === rating.users_id || userInfo.sys_admin === 1) && (
                <div
                  className="commentMenu"
                  id="commentDropDown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BsThreeDots
                    style={{
                      fontSize: "25px",
                      marginTop: "12px",
                    }}
                    type="button"
                  />
                </div>
              )}
            <div
              className="dropdown-menu  menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-2"
              aria-labelledby="commentDropDown"
            >
              <div
                className="comment-menu-item px-3"
                onClick={() => {
                  // getShowModal(true);
                  // setUpdateComment(true);
                  setInputAndType("update", rating.body, rating.id, null);
                  commentRef?.current?.focus();
                }}
              >
                <span className="comment-menu-link px-3">
                  {intl.formatMessage({ id: "POST_RATING_UPDATE_TITLE" })}
                </span>
              </div>
              <div
                className="comment-menu-item px-3"
                onClick={() => {
                  handleDeleteComment();
                }}
              >
                <span className="comment-menu-link px-3">
                  {intl.formatMessage({ id: "POST_RATING_DELETE_TITLE" })}
                </span>
              </div>
            </div>
          </div>
          {/* {depth === 1 ? (
            <div className="commentRatingSection">
              {[1, 2, 3, 4, 5].map((data, index) => {
                return (
                  <BsFillStarFill
                    style={{
                      fontSize: "20px",
                      color: selectedStar > index ? "#ffd814" : "#D3D3D3",
                    }}
                    type="button"
                  />
                );
              })}
            </div>
          ) : null} */}
          <div className="commentBody">
            <span>{rating.body}</span>
          </div>

          <div className="commentFooter d-flex flex-row justify-content-between">
            {depth !== 4 && (
              <div
                className="d-flex  justify-content-center"
                onClick={() => {
                  if (rating?.replies?.length > 0) {
                    handleReplyItem(rating);
                  }
                }}
              >
                <BsChat
                  style={{
                    fontSize: "25px",
                  }}
                  type="button"
                />
                {shouldShowHideReplies(rating, depth) ? (
                  <div
                    className="mx-2 mt-1 d-flex align-items-center justify-content-center cursorPointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      hideReplies(depth);
                    }}
                  >
                    {intl.formatMessage({
                      id: "POST_RATING_HIDE_COMMENTS_TITLE",
                    })}
                  </div>
                ) : (
                  <div className="mx-2 mt-1 d-flex align-items-center justify-content-center cursorPointer">
                    {repliesLength > 1
                      ? repliesLength +
                        " " +
                        intl.formatMessage({
                          id: "POST_RATING_REPLIES_MESSAGE",
                        })
                      : repliesLength === 1
                      ? repliesLength +
                        " " +
                        intl.formatMessage({ id: "POST_RATING_REPLY_MESSAGE" })
                      : 0 +
                        " " +
                        intl.formatMessage({
                          id: "POST_RATING_REPLIES_MESSAGE",
                        })}
                  </div>
                )}
              </div>
            )}
            <div
              className="cursorPointer"
              onClick={() => {
                // setReplyId(rating);
                // setReplyComment(true);
                // getShowModal(true);
                setInputAndType("reply", "", rating.id, rating.users_username);
                commentRef?.current?.focus();
              }}
            >
              {intl.formatMessage({ id: "POST_RATING_REPLY_MESSAGE" })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommentCard;
