import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CarouselArticle from "../../sharedComponents/Article/CarouselArticle";
import Loader from "../../sharedComponents/Loader";
import Title from "../../sharedComponents/Title";

import { useIntl } from "react-intl";
const AllFavouritePosts = () => {
  const intl = useIntl();
  const [favPosts, setfavPosts] = useState([]);
  const [Loading, setLoading] = useState(true);
  // const [Error, setError] = useState("");
  const [current, setCurrent] = useState(6);
  const contactId = useSelector(
    (state) => state?.auth?.user?.personalInformation?.id
  );
  const handleLoadMore = () => {
    setCurrent((prev) => prev + 3);
  };
  useEffect(() => {
    try {
      axios
        .get(`websiteApp/posts/contacts/favourites/${contactId}`)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setfavPosts(response.data.postDetails);
          }
        });
    } catch (error) {
      // setError(
      //   intl.formatMessage({ id: "PAGES_ALL_FAVOURITE_POSTS_ERROR_MESSAGE" })
      // );
      throw error;
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      {Loading ? (
        <Loader />
      ) : (
        <>
          <div className="container mt-4">
            <Title
              title={intl.formatMessage({
                id: "PAGES_ALL_FAVOURITE_POSTS_TITLE",
              })}
            />

            <div className="row mt-3" style={{ rowGap: "1rem" }}>
              {favPosts &&
                favPosts.length > 0 &&
                favPosts.slice(0, current).map((ele, index) => {
                  return (
                    <div className="col-lg-4">
                      <div className="animate__animated animate__fadeInUp">
                        <CarouselArticle
                          details={ele}
                          key={index}
                          sidebars={{ a: true }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            {current < favPosts.length && (
              <div className="d-flex align-items-center justify-content-center my-3">
                <button
                  onClick={() => handleLoadMore()}
                  id="button-custom"
                  className="mx-auto "
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  {intl.formatMessage({
                    id: "PAGES_LOAD_MORE",
                  })}
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AllFavouritePosts;
