import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { getPageDetails } from "../../Redux/PageDetailsReducer";
import {
  getPostsDetails,
  setMainIsLoading,
} from "../../Redux/PostsDetailsReducer";
import Banner from "../../sharedComponents/Banner/Banner";
import Newsletter from "../../sharedComponents/NewsLetter/Newsletter";
import Title, { TitleHeader } from "../../sharedComponents/Title";
import CarouselListSection from "../Sections/CarouselListSection";
import EventList from "./EventList";
import PostList from "./PostList";
// import {intl.formatMessage({id:'Welcome'})} from "../../i18n/translate";
import { useIntl } from "react-intl";
// import { useMediaQuery } from "react-responsive";
import DeadlinesScroller from "../../sharedComponents/DeadlinesScroller/DeadlinesScroller";
import { MainLoader } from "../../sharedComponents/Loader/Loader";
import Scroller from "../../sharedComponents/Scroller/Scroller";
import DeadlinesListSection from "../Sections/DeadlinesListSection";
import { NavigationSection } from "../Sections/NavigationSection";
const PageDetails = ({ id, pageName, pageSections = [] }) => {
  // var $animation_elements = $(".animation-element");
  // var $window = $(window);

  // function check_if_in_view() {
  //   var window_height = $window.height();
  //   var window_top_position = $window.scrollTop();
  //   var window_bottom_position = window_top_position + window_height;

  //   $.each($animation_elements, function () {
  //     var $element = $(this);
  //     var element_height = $element.outerHeight();
  //     var element_top_position = $element.offset().top;
  //     var element_bottom_position = element_top_position + element_height;

  //     //check to see if this current container is within viewport
  //     if (
  //       element_bottom_position >= window_top_position &&
  //       element_top_position <= window_bottom_position
  //     ) {
  //       $element.addClass("in-view");
  //       $element.addClass("fadeInUpCard");
  //     } else {
  //       $element.removeClass("in-view");
  //       $element.removeClass("fadeInUpCard");
  //     }
  //   });
  // }

  // const firstRender = useRef(true);

  const intl = useIntl();
  const dispatch = useDispatch();
  // const largeScreen = useMediaQuery({ minWidth: 768 });
  const data = useSelector((state) => state.pageDetails.pages);
  // const generalDetails = useSelector(
  //   (state) => state.generalDetails.generalDetails
  // );
  // const isLoading = useSelector((state) => state.postsDetails.isLoading);
  const isMainLoading = useSelector(
    (state) => state.postsDetails.isMainLoading
  );
  const postsData = useSelector((state) => state.postsDetails);
  const campaigns = useSelector((state) => state.auth.campaignDetails);
  const [sections, setSections] = useState([]);
  const [sidebars, setsidebars] = useState([]);
  useEffect(() => {
    if (!Object.keys(data).includes(pageName)) dispatch(getPageDetails(id));
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setsidebars(data?.[pageName]?.sidebars);
    //eslint-disable-next-line
  }, [data?.[pageName]?.sidebars, pageName]);
  useEffect(() => {
    setSections(data?.[pageName]?.sections);
    //eslint-disable-next-line
  }, [data?.[pageName]?.sections, pageName]);

  let lastSectionId = -1;

  //add type for every new component to find last section id for main loading

  const getLastSectionID = (sections = []) => {
    let id = -1;
    pageSections.forEach((section) => {
      if (
        section.type === "event-list" ||
        section.type === "event-carousel" ||
        section.type === "post-list" ||
        section.type === "post-carousel" ||
        section.type === "post-scroller" ||
        section.type === "event-scroller"
      ) {
        id = section.id;
      }
    });

    return id;
  };

  lastSectionId = getLastSectionID();

  useEffect(() => {
    if (
      data?.[pageName]?.sections &&
      data?.[pageName]?.sections?.length > 0 &&
      getLastSectionID(data?.[pageName]?.sections) !== -1
    ) {
      dispatch(setMainIsLoading(true));

      data?.[pageName]?.sections.forEach((ele) => {
        if (ele.type === "post-carousel" || ele.type === "event-carousel") {
          const paramsObject = {
            offset: ele.offset,
            option_for_featured: ele.option_for_featured,
            total_items: ele.total_items,
            sorting: parseInt(ele.sorting),
            groups: ele.groups,
            groups_condition: ele.groups_condition,
            categories: ele.categories,
            categories_condition: ele.categories_condition,
            tags: ele.tags,
            tags_condition: ele.tags_condition,
            rule_type: ele.rule_type,
            type: ele.type,
          };
          dispatch(
            getPostsDetails({
              paramsObject,
              id: ele.id,
              pageName,
              type: ele.type,
              title: ele.title,
              sectionId: ele.section_id,
              isLast: ele.id === lastSectionId,
            })
          );
        } else if (
          ele.type === "post-scroller" ||
          ele.type === "event-scroller"
        ) {
          const paramsObject = {
            offset: ele.offset,
            option_for_featured: ele.option_for_featured,
            total_items: ele.maximum_items,
            sorting: parseInt(ele.sorting),
            groups: ele.groups,
            groups_condition: ele.groups_condition,
            categories: ele.categories,
            categories_condition: ele.categories_condition,
            tags: ele.tags,
            tags_condition: ele.tags_condition,
            rule_type: ele.rule_type,
            type:
              ele.type === "post-scroller" ? "post-carousel" : "event-carousel",
          };
          dispatch(
            getPostsDetails({
              paramsObject,
              id: ele.id,
              pageName,
              type: ele.type,
              title: ele.title,
              sectionId: ele.section_id,
              isLast: ele.id === lastSectionId,
            })
          );
        } else {
          const paramsObject = {
            offset: ele.offset,
            option_for_featured: ele.option_for_featured,
            pagination: ele.pagination,
            items_per_page: ele.items_per_page,
            groups: ele.groups,
            groups_condition: ele.groups_condition,
            categories: ele.categories,
            categories_condition: ele.categories_condition,
            tags: ele.tags,
            tags_condition: ele.tags_condition,
            sorting: ele.sorting,
            rule_type: ele.rule_type,
            type: ele.type,
          };
          if (ele.type === "post-list" || ele.type === "event-list") {
            dispatch(
              getPostsDetails({
                paramsObject,
                pageName,
                id: ele.id,
                type: ele.type,
                defaultView: ele.default_view,
                title: ele.title,
                sectionId: ele.section_id,
                isLast: ele.id === lastSectionId,
              })
            );
          }
        }

        // if (ele.id === lastSectionId) {
        //   const data = {
        //     id: ele.id,
        //     pageName: pageName,
        //     type: ele.type,
        //     title: ele.title,
        //     sectionId: ele.section_id,
        //   };
        //   dispatch(setIsLoadingFalse(data));
        // }
      });
    }
    //eslint-disable-next-line
  }, [data?.[pageName]?.sections, pageName]);

  // useEffect(() => {
  //   if (data?.[pageName]?.sections && !postsData?.[pageName]) {
  //     data?.[pageName]?.sections.forEach((ele) => {
  //       if (ele.id === lastSectionId) {
  //         const data = {
  //           id: ele.id,
  //           pageName: pageName,
  //           type: ele.type,
  //           title: ele.title,
  //           sectionId: ele.section_id,
  //         };
  //         dispatch(setIsLoadingFalse(data));
  //       }
  //     });
  //   }
  // }, []);

  // console.log("isMainLoading", isMainLoading);

  return (
    <>
      <Helmet>
        <title>{data?.[pageName]?.seo_tag}</title>
        <meta name="description" content={data?.[pageName]?.seo_description} />
        <meta
          property="title"
          name="title"
          content={data?.[pageName]?.seo_permalink}
        />
        <meta property="tag" name="tag" content={data?.[pageName]?.seo_tag} />
      </Helmet>

      {isMainLoading === true ? (
        <MainLoader />
      ) : sections && sections.length > 0 ? (
        sections.map((ele, index) => {
          switch (ele.type) {
            case "event-carousel":
            case "post-carousel": {
              const uniqueId = `${ele.section_id}_${ele.id}`;
              return (
                postsData?.[pageName]?.[uniqueId]?.posts?.[1] &&
                Object.entries(postsData?.[pageName]?.[uniqueId]?.posts?.[1])
                  ?.length > 0 && (
                  <CarouselListSection
                    key={index}
                    details={ele}
                    pageName={pageName}
                    title={ele.title}
                    uniqueId={uniqueId}
                    sidebars={sidebars}
                    type={ele?.type}
                    lastSection={lastSectionId}
                  />
                )
              );
            }
            case "event-scroller":
            case "post-scroller": {
              const uniqueId = `${ele.section_id}_${ele.id}`;
              return (
                <Scroller
                  id={ele.id}
                  key={index}
                  title={ele.title}
                  type={ele.type}
                  sectionId={ele.section_id}
                  uniqueId={uniqueId}
                  pageName={pageName}
                  sidebars={sidebars || []}
                  is_bought={ele.is_bought}
                  is_registered={ele.is_registered}
                />
              );
            }
            case "deadlines-scroller": {
              const uniqueId = `${ele.section_id}_${ele.id}`;
              return (
                <DeadlinesScroller
                  id={ele.id}
                  key={index}
                  title={ele.title}
                  type={ele.type}
                  sectionId={ele.section_id}
                  uniqueId={uniqueId}
                  pageName={pageName}
                  details={ele}
                  sidebars={sidebars || []}
                />
              );
            }
            case "deadlines-list": {
              return (
                ele?.deadlines?.length > 0 && (
                  <DeadlinesListSection
                    id={ele.id}
                    key={index}
                    title={ele.title}
                    pageName={pageName}
                    details={ele}
                    sidebars={sidebars || []}
                  />
                )
              );
            }
            case "Navigations": {
              return (
                <div
                  className={` mb-4 ${
                    sidebars && Object.entries(sidebars).length === 0
                      ? "container"
                      : ""
                  }`}
                >
                  {ele?.navigations && ele?.navigations.length > 0 && (
                    <>
                      {!!ele?.display_header ? (
                        sidebars && Object.entries(sidebars).length === 0 ? (
                          <Title title={ele.title} />
                        ) : (
                          <TitleHeader title={ele.title} noMarginTop />
                        )
                      ) : null}
                      <NavigationSection
                        navigations={ele?.navigations}
                        sidebar={
                          !(sidebars && Object.entries(sidebars).length === 0)
                        }
                        index={index}
                      />
                    </>
                  )}
                </div>
              );
            }
            case "Banner": {
              return (
                <Banner
                  key={index}
                  type={ele.type}
                  details={ele}
                  pageName={pageName}
                  sidebars={sidebars}
                />
              );
            }
            case "NewsLetter": {
              //return <Newsletter details={ele} pageName={pageName}/>
              return sidebars && Object.entries(sidebars).length > 0 ? (
                <Newsletter
                  pageName={pageName}
                  key={index}
                  details={ele}
                  sidebars={true}
                  pageDetails={data}
                />
              ) : (
                <Newsletter
                  pageName={pageName}
                  key={index}
                  details={ele}
                  sidebars={false}
                  pageDetails={data}
                />
              );

              // return sidebars && Object.entries(sidebars).length > 0 ? (
              //   <>
              //     {!!ele.display_header ? <TitleHeader title={ele.title} /> : null}
              //     {largeScreen ? (
              //       <NewsLetterTwo key={index} details={ele} sidebars={true} />
              //     ) : (
              //       <NewsLetterThree
              //         key={index}
              //         details={ele}
              //         sidebars={true}
              //       />
              //     )}
              //   </>
              // ) : largeScreen ? (
              //   <NewsLetterTwo key={index} details={ele} sidebars={false} />
              // ) : (
              //   <NewsLetterThree key={index} details={ele} sidebars={false} />
              // );
            }
            case "event-list": {
              const uniqueId = `${ele.section_id}_${ele.id}`;
              if (!postsData?.[pageName]?.[uniqueId]) {
                // const paramsObject = {
                //   offset: ele?.offset,
                //   option_for_featured: ele?.option_for_featured,
                //   pagination: ele?.pagination,
                //   items_per_page: ele?.items_per_page,
                //   groups: ele?.groups,
                //   groups_condition: ele?.groups_condition,
                //   categories: ele?.categories,
                //   categories_condition: ele?.categories_condition,
                //   tags: ele?.tags,
                //   tags_condition: ele?.tags_condition,
                //   sorting: ele?.sorting,
                //   rule_type: ele?.rule_type,
                //   type: ele?.type,
                // };
                // dispatch(
                //   getPostsDetails({
                //     paramsObject,
                //     pageName,
                //     id: ele?.id,
                //     type: ele?.type,
                //     defaultView: ele?.default_view,
                //     title: ele?.title,
                //     sectionId: ele?.section_id,
                //     isLast: ele?.id === lastSectionId,
                //   })
                // );
              }
              return (
                postsData?.[pageName]?.[uniqueId]?.posts?.[1] &&
                Object.entries(postsData?.[pageName]?.[uniqueId]?.posts?.[1])
                  .length > 0 && (
                  <EventList
                    key={index}
                    details={ele}
                    title={ele.title}
                    pageName={pageName}
                    uniqueId={uniqueId}
                    sidebars={sidebars}
                    type="event-list"
                  />
                )
              );
            }
            case "post-list": {
              const uniqueId = `${ele.section_id}_${ele.id}`;
              if (!postsData?.[pageName]?.[uniqueId]) {
                // const paramsObject = {
                //   offset: ele?.offset,
                //   option_for_featured: ele?.option_for_featured,
                //   pagination: ele?.pagination,
                //   items_per_page: ele?.items_per_page,
                //   groups: ele?.groups,
                //   groups_condition: ele?.groups_condition,
                //   categories: ele?.categories,
                //   categories_condition: ele?.categories_condition,
                //   tags: ele?.tags,
                //   tags_condition: ele?.tags_condition,
                //   sorting: ele?.sorting,
                //   rule_type: ele?.rule_type,
                //   type: ele?.type,
                // };
                // console.log("lastSectionId", lastSectionId);
                // dispatch(
                //   getPostsDetails({
                //     paramsObject,
                //     pageName,
                //     id: ele?.id,
                //     type: ele?.type,
                //     defaultView: ele?.default_view,
                //     title: ele?.title,
                //     sectionId: ele?.section_id,
                //     isLast: ele?.id === lastSectionId,
                //   })
                // );
              }
              return (
                postsData?.[pageName]?.[uniqueId]?.posts?.[1] &&
                Object.entries(postsData?.[pageName]?.[uniqueId]?.posts?.[1])
                  .length > 0 && (
                  <PostList
                    key={index}
                    details={ele}
                    title={ele.title}
                    pageName={pageName}
                    uniqueId={uniqueId}
                    sidebars={sidebars}
                    type="post-list"
                  />
                )
              );
            }
          }
          if (ele?.section_id === 10) {
            return (
              <div
                className={`mb-4 ${
                  sidebars && Object.entries(sidebars).length === 0
                    ? "container"
                    : ""
                }`}
                style={{ cursor: "pointer" }}
                title={campaigns?.contentBannerCampaign?.name}
                onClick={() => {
                  if (campaigns?.contentBannerCampaign?.site_url)
                    window.open(
                      campaigns?.contentBannerCampaign?.site_url,
                      "_blank",
                      "noreferrer"
                    );
                }}
              >
                {campaigns?.contentBannerCampaign?.image_url && (
                  <img
                    src={campaigns?.contentBannerCampaign?.image_url}
                    className="rounded web-kit-width"
                    alt=""
                  />
                )}
              </div>
            );
          }
        })
      ) : (
        ""
      )}
    </>
  );
};
export default PageDetails;
