import React, { useState } from "react";
import { MdAddLocationAlt } from "react-icons/md";
import { useIntl } from "react-intl";
import CompanyAddressModal from "./CompanyAddressModal";

const NewCompanyAddress = ({ userDetails, getCustomerDetailsAPI }) => {
  const [step, setStep] = useState(1);
  const [chooseAddressTypeModal, setChooseAddressTypeModal] = useState(false);
  const intl = useIntl();

  const closeModal = () => {
    setChooseAddressTypeModal(false);
  };

  return (
    <>
      {chooseAddressTypeModal && (
        <CompanyAddressModal
          show={chooseAddressTypeModal}
          closeModal={closeModal}
          userDetails={userDetails}
          getCustomerDetailsAPI={getCustomerDetailsAPI}
        />
      )}

      {step === 1 ? (
        <div className="notice d-flex bg-light-primary rounded double-FA-box p-3 mx-2 mb-3">
          <div className="me-3">
            <MdAddLocationAlt
              className="shield-icon"
              style={{ top: "5px", width: "22px", position: "relative" }}
            />
          </div>
          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap justify-content-between">
            <div className="mb-3 mb-md-0">
              <h4 className=" fw-bold" style={{ fontSize: "16px" }}>
                {intl.formatMessage({ id: "ADD_COMPANY_ADDRESS_TITLE" })}
              </h4>
              <div className=" text-gray-600 pe-7" style={{ fontSize: "14px" }}>
                {intl.formatMessage({ id: "ADD_COMPANY_ADDRESS_WRAPPER_INFO" })}
              </div>
            </div>
            <span
              role={"button"}
              className="btn btn-custom mx-md-2 mx-0 align-self-center text-nowrap"
              onClick={() => {
                setChooseAddressTypeModal(true);
              }}
            >
              Add Address
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NewCompanyAddress;
