import clsx from "clsx";
import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { useIntl } from "react-intl";
import { useCustomMediaQuery } from "../../utils";

const SelectFlowModal = ({
  show,
  flowModal,
  setflow,
  //setshowProductModal,
  setEventRegsiterModal,
  closeModal,
  setBuyPostModal,
  // showProductModal,
  showEventRegisterModal,
  showBuyPostModal,
}) => {
  const { isXsScreen } = useCustomMediaQuery();
  // const [loading, setLoading] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState("products");
  const intl = useIntl();

  const handleClick = (type) => {
    setBuyPostModal?.(true);
    setflow(type);
    setEventRegsiterModal?.(true);
  };
  return (
    <Modal
      centered
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={closeModal}
      contentClassName={
        showBuyPostModal || showEventRegisterModal ? "d-none" : ""
      }
      size="lg"
    >
      <ModalHeader closeButton>
        <ModalTitle>
          {intl.formatMessage({ id: "SHARED_COMPONENTS_FLOW_MODAL_TITLE" })}
        </ModalTitle>{" "}
      </ModalHeader>
      <ModalBody>
        <span className="fs-6 text-gray-700">
          {intl.formatMessage({ id: "SHARED_COMPONENTS_FLOW_MODAL_SELECT" })}
        </span>

        <div
          className={`d-flex align-items-stretch my-4 ${
            isXsScreen ? "flex-column" : ""
          }`}
          style={{ borderColor: "#e4e6ef", columnGap: "1.5rem" }}
        >
          {/* offers */}
          <div
            className={clsx(
              " p-4 d-flex align-items-center rounded border border-rounded flex-even mt-3",
              selectedTemplateType === "products" ? "selected-offer" : ""
            )}
            role={"button"}
            onClick={() => {
              setSelectedTemplateType("products");
            }}
          >
            <input
              //className='form-check-input me-3'
              //name='user_role'
              type="radio"
              checked={selectedTemplateType === "products"}
              onChange={() => {}}
              style={{
                minWidth: "20px",
                marginRight: "12px",
                minHeight: "20px",
              }}
            />
            <label className="radio-abel">
              <div className="fs-4 fw-bolder">
                <span className="me-1">
                  {intl.formatMessage({
                    id: "SHARED_COMPONENTS_FLOW_MODAL_PRODUCT_FLOW",
                  })}
                </span>
              </div>
              <div className="fs-7 text-gray-600" role={"button"}>
                {intl.formatMessage({
                  id: "SHARED_COMPONENTS_FLOW_MODAL_PRODUCT_DESC",
                })}
              </div>
            </label>
          </div>
          {/* <div className='mx-4'></div> */}
          <div
            className={clsx(
              "p-4 d-flex align-items-center rounded border border-rounded flex-even mt-3",
              selectedTemplateType === "offers" ? "selected-offer" : ""
            )}
            role={"button"}
            onClick={() => {
              setSelectedTemplateType("offers");
            }}
          >
            <input
              //className='form-check-input me-3'
              //name='user_role'
              type="radio"
              checked={selectedTemplateType === "offers"}
              onChange={() => {}}
              style={{
                minWidth: "20px",
                marginRight: "12px",
                minHeight: "20px",
              }}
            />
            <label className="form-check-label">
              <div className="fs-4 fw-bolder">
                {intl.formatMessage({
                  id: "SHARED_COMPONENTS_FLOW_MODAL_OFFERS_FLOW",
                })}
              </div>
              <div className="fs-7 text-gray-600" role={"button"}>
                {intl.formatMessage({
                  id: "SHARED_COMPONENTS_FLOW_MODAL_OFFERS_DESC",
                })}
              </div>
            </label>
          </div>
        </div>
        {/* <div
          className={`d-flex justify-content-center align-items-center ${
            isXsScreen ? "flex-column" : ""
          }`}
        >
          <button
            id="button-custom"
            className="mx-2 mt-3"
            type="button"
            onClick={() => handleClick("offers")}
            style={{ width: isXsScreen ? "100%" : "" }}
          >
            Offers Flow
          </button>
          <button
            id="button-custom"
            className="mx-2 mt-3"
            type="button"
            onClick={() => handleClick("products")}
            style={{ width: isXsScreen ? "100%" : "" }}
          >
            Products Flow
          </button>
        </div> */}
      </ModalBody>
      <ModalFooter
        className="justify-content-center"
        style={{ columnGap: "12px" }}
      >
        <button className="close" type="button" onClick={() => closeModal()}>
          {intl.formatMessage({ id: "BUTTON_CLOSE" })}
        </button>
        <button
          id="button-custom"
          className="mx-0"
          type="submit"
          onClick={() => handleClick(selectedTemplateType)}
        >
          {intl.formatMessage({ id: "BUTTON_SUBMIT" })}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default SelectFlowModal;
