import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { BsShieldFillCheck } from "react-icons/bs";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { updateCustomerEmail, updateCustomerPassword } from "../../../API/Api";
import { actions } from "../../../Redux/AuthReducer";
import Alert from "../../../sharedComponents/Alerts/Alert";
import InputLabel from "../../../sharedComponents/InputLabel/InputLabel";
import ConfirmPasswordModal from "../../../sharedComponents/Modal/ConfirmPasswordModal";
import ChooseAuthMethodModal from "../../../sharedComponents/Modal/TwoFactorAuthModal";
import TextInput from "../../../sharedComponents/TextInput/TextInput";
import { TitleHeader } from "../../../sharedComponents/Title";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/Toast/Toasts";
import { disableTwoFactorAuthentication } from "../../auth/Redux/AuthApi";
import "./style.scss";

function SignInSettings({ userDetails, getCustomerDetailsAPI }) {
  const intl = useIntl();
  // const countries = useSelector((state) => state.masterData.countriesList);

  // const navigate = useNavigate();
  const dispatch = useDispatch();

  // state
  // const [showEmailForm, setShowEmailForm] = useState(false);
  // const [showPasswordForm, setPasswordForm] = useState(false);
  const [user, setUser] = useState(userDetails);
  const [showChooseAuthModal, setShowChooseAuthModal] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isDisableLoading, setIsDisableLoading] = useState(false);
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] =
    useState(false);
  // const [selectedCountry, setSelectedCountry] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [emailUpdate, setEmailUpdate] = useState(true);

  const messages = {
    emailSuccess: intl.formatMessage({
      id: "ACCOUNT_SETTINGS_SIGN_IN_EMAIL_UPDATE_SUCCESS_MESSAGE",
    }),
    emailFailure: intl.formatMessage({
      id: "ACCOUNT_SETTINGS_SIGN_IN_EMAIL_UPDATE_FAILURE_MESSAGE",
    }),
    passwordSuccess: intl.formatMessage({
      id: "ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_UPDATE_SUCCESS_MESSAGE",
    }),
    passwordFailure: intl.formatMessage({
      id: "ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_UPDATE_ERROR_MESSAGE",
    }),
  };

  useEffect(() => {
    setUser(userDetails);
  }, [userDetails]);

  const emailFormInitialValues = {
    newEmail: "",
    confirmPassword: "",
  };

  const emailFormValidationSchema = Yup.object().shape({
    newEmail: Yup.string()
      .email(
        intl.formatMessage({ id: "ACCOUNT_SETTINGS_SIGN_IN_EMAIL_MESSAGE" })
      )
      .max(
        60,
        intl.formatMessage({ id: "ACCOUNT_SETTINGS_SIGN_IN_EMAIL_MAX_MESSAGE" })
      )
      .required(
        intl.formatMessage({
          id: "ACCOUNT_SETTINGS_SIGN_IN_EMAIL_REQUIRED_MESSAGE",
        })
      ),
    confirmPassword: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_REQUIRED_MESSAGE",
      })
    ),
  });

  const passwordFormValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_REQUIRED_MESSAGE",
      })
    ),
    newPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_REQUIRED_MESSAGE",
        })
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        intl.formatMessage({
          id: "ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_MUST_MATCH",
        })
      ),
    passwordConfirmation: Yup.string()
      .required(
        intl.formatMessage({
          id: "ACCOUNT_SETTINGS_SIGN_IN_CONFIRM_PASSWORD_REQUIRED",
        })
      )
      .oneOf(
        [Yup.ref("newPassword"), null],
        intl.formatMessage({
          id: "ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_MUST_MATCH",
        })
      ),
  });

  const logout = () => {
    // navigate("/");
    dispatch(actions.logout());
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };

  const formik1 = useFormik({
    initialValues: emailFormInitialValues,
    validationSchema: emailFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading1(true);
      setEmailUpdate(true);
      updateCustomerEmail(
        user?.personalInformation?.id,
        values.newEmail,
        values.confirmPassword
      )
        .then(() => {
          // SuccessAlert(intl.formatMessage({id: 'USERS_EMAIL_CHNAGE_SUCCESS_MESSAGE'}))
          setUser({
            ...user,
            email: values.newEmail,
          });
          setShowSuccess(true);
          logout();
        })
        .catch(() => {
          // errorAlert(intl.formatMessage({id: 'USERS_EMAIL_CHANGE_FAILURE_MESSAGE'}))
          setShowFailure(true);
        })
        .finally(() => {
          setLoading1(false);
          getCustomerDetailsAPI();
          formik1.resetForm();
        });
    },
  });

  const passwordFormInitialValues = {
    currentPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  };

  const formik2 = useFormik({
    initialValues: passwordFormInitialValues,
    validationSchema: passwordFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading2(true);
      setEmailUpdate(false);
      updateCustomerPassword(
        user?.personalInformation?.id,
        values.currentPassword,
        values.newPassword,
        values.passwordConfirmation
      )
        .then(() => {
          // SuccessAlert(intl.formatMessage({id: 'USERS_PASSWORD_CHANGE_SUCCESS_MESSAGE'}))
          setShowSuccess(true);
          logout();
        })
        .catch(() => {
          // errorAlert(intl.formatMessage({id: 'USERS_PASSWORD_CHANGE_FAILURE_MESSAGE'}))
          setShowFailure(true);
        })
        .finally(() => {
          setLoading2(false);
          getCustomerDetailsAPI();
          formik2.resetForm();
        });
    },
  });
  const closeConfirmPasswordModal = () => {
    setShowConfirmPasswordModal(false);
  };

  // const onCountrySelect = (item, updateState) => {
  //   setSelectedCountry(item);
  //   setSelectedState(undefined)
  //   setStatesLoading(true)
  //   getStatesList(item.id)
  //     .then(({data: {states = []}}) => {
  //       setStates(states)
  //       if (updateState) {
  //         states.map((state) => {
  //           if (state.id === address?.state_id) {
  //             formik.setFieldValue('state_id', state.id)
  //             setSelectedState(state)
  //           }
  //         })
  //       }
  //     })
  //     .finally(() => {
  //       setStatesLoading(false)
  //     })
  // };

  const disableUserTwoFactorAuthentication = () => {
    //commented
    setIsDisableLoading(true);
    disableTwoFactorAuthentication(userDetails?.personalInformation?.id)
      .then(() => {
        setUser({
          ...user,
          has2FA: false,
        });
        successToast(
          intl.formatMessage({
            id: "USERS_DISABLE_VERIFICATION_SUCCESS_MESSAGE",
          })
        );
        getCustomerDetailsAPI();
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "USERS_DISABLE_VERIFICATION_FAILURE_MESSAGE",
          })
        );
      })
      .finally(() => {
        setIsDisableLoading(false);
      });
  };

  const closeModal = () => {
    setShowChooseAuthModal(false);
  };

  return (
    // <>
    //     <div className='card mb-5 mb-xl-10 ms-0 card-no-border' id='sign-in'>
    //     <div
    //     className='card-header border-0 cursor-pointer card-header-custom'
    //     role='button'
    //     data-bs-toggle='collapse'
    //     data-bs-target='#kt_account_signin_method'
    //   >
    //     <div className='card-title m-0 pt-2'>
    //       <h3 className='fw-bolder m-0' style={{fontSize:'1.5rem'}}>
    //       Sign-in Method
    //       </h3>
    //     </div>
    //   </div>

    //   <div id='kt_account_signin_method' className='collapse show'>
    //     <div className='card-body p-9'>
    //       <div className='d-flex flex-wrap align-items-center'>
    //         <div id='kt_signin_email' className={' ' + (showEmailForm && 'd-none')}>
    //           <div className='fs-6 fw-bolder mb-1'>
    //           Email
    //           </div>
    //           <div className='text-gray-600'>{user?.personalInformation?.email}</div>
    //         </div>

    //         <div
    //           id='kt_signin_email_edit'
    //           className={'flex-row-fluid ' + (!showEmailForm && 'd-none')}
    //           style={{width:'100%'}}
    //         >
    //           <form
    //             onSubmit={formik1.handleSubmit}
    //             id='kt_signin_change_email'
    //             // className={clsx('form', loading1 ? 'pe-none' : '')}
    //             noValidate
    //           >
    //             <div className='row mb-6'>
    //               <div className='col-lg-6 mb-4 mb-lg-0'>
    //                 <div className='fv-row mb-0'>
    //                   <label htmlFor='emailaddress' className='form-label fs-6 fw-bolder mb-3'>
    //                   Enter New Email Address
    //                   </label>
    //                   <input
    //                     type='email'
    //                     className=' form-common-input'
    //                     id='emailaddress'
    //                     {...formik1.getFieldProps('newEmail')}
    //                   />
    //                   {formik1.touched.newEmail && formik1.errors.newEmail && (
    //                     <div className='text-danger'>
    //                       <span role='alert'>{formik1.errors.newEmail}</span>
    //                     </div>
    //                   )}
    //                 </div>
    //               </div>
    //               <div className='col-lg-6'>
    //                 <div className='fv-row mb-0'>
    //                   <label
    //                     htmlFor='confirmemailpassword'
    //                     className='form-label fs-6 fw-bolder mb-3'
    //                   >
    //                     Confirm Password
    //                   </label>
    //                   <input
    //                     type='password'
    //                     className=' form-common-input'
    //                     id='confirmemailpassword'
    //                     {...formik1.getFieldProps('confirmPassword')}
    //                   />
    //                   {formik1.touched.confirmPassword && formik1.errors.confirmPassword && (
    //                     <div className='text-danger'>
    //                       <span role='alert'>{formik1.errors.confirmPassword}</span>
    //                     </div>
    //                   )}
    //                 </div>
    //               </div>
    //             </div>
    //             <div className='d-flex pt-4'>
    //               <button
    //                 id='kt_signin_submit'
    //                 type='submit'
    //                 className='btn btn-primary  me-2 px-6'
    //               >
    //                 {!loading1 && 'Update Email'}
    //                 {loading1 && (
    //                   <span className='indicator-progress' style={{display: 'block'}}>
    //                     Please wait...{' '}
    //                     <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //                   </span>
    //                 )}
    //               </button>
    //               <button
    //                 id='kt_signin_cancel'
    //                 type='button'
    //                 onClick={() => {
    //                   setShowEmailForm(false)
    //                   formik1.resetForm()
    //                 }}
    //                 className='btn btn-color-gray-400 btn-active-light-primary px-6'
    //               >
    //                 Cancel
    //               </button>
    //             </div>
    //           </form>
    //         </div>

    //         <div id='kt_signin_email_button' className={'ms-auto ' + (showEmailForm && 'd-none')}>
    //           <button
    //             onClick={() => {
    //               setShowEmailForm(true)
    //             }}
    //             className='btn btn-light btn-active-light-primary'
    //           >
    //             Change Email
    //           </button>
    //         </div>
    //       </div>

    //       <div className='separator separator-dashed my-3'></div>

    //       <div className='d-flex flex-wrap align-items-center mb-10'>
    //         <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
    //           <div className='fs-6 fw-bolder mb-1'>
    //           Password
    //           </div>
    //           <div className='fw-bold text-gray-600'>************</div>
    //         </div>

    //         <div
    //           id='kt_signin_password_edit'
    //           className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
    //         >
    //           <form
    //             onSubmit={formik2.handleSubmit}
    //             id='kt_signin_change_password'
    //             // className={clsx('form', loading2 ? 'pe-none' : '')}
    //             noValidate
    //           >
    //             <div className='row mb-1'>
    //               <div className='col-lg-5'>
    //                 <div className='fv-row mb-0'>
    //                   <label htmlFor='currentpassword' className='form-label fs-6 fw-bolder mb-3'>
    //                   Current Password
    //                   </label>
    //                   <input
    //                     type='password'
    //                     className=' form-common-input'
    //                     id='currentpassword'
    //                     {...formik2.getFieldProps('currentPassword')}
    //                   />
    //                   {formik2.touched.currentPassword && formik2.errors.currentPassword && (
    //                     <div className='text-danger'>
    //                       <span role='alert'>{formik2.errors.currentPassword}</span>
    //                     </div>
    //                   )}
    //                 </div>
    //               </div>

    //               <div className='col-lg-5'>
    //                 <div className='fv-row mb-0'>
    //                   <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
    //                   New Password
    //                   </label>
    //                   <input
    //                     type='password'
    //                     className=' form-common-input'
    //                     id='newpassword'
    //                     {...formik2.getFieldProps('newPassword')}
    //                   />
    //                   {formik2.touched.newPassword && formik2.errors.newPassword && (
    //                     <div className='text-danger'>
    //                       <span role='alert'>{formik2.errors.newPassword}</span>
    //                     </div>
    //                   )}
    //                 </div>
    //               </div>

    //               <div className='col-lg-5'>
    //                 <div className='fv-row mb-0'>
    //                   <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
    //                   Confirm New Password
    //                   </label>
    //                   <input
    //                     type='password'
    //                     className=' form-common-input'
    //                     id='confirmpassword'
    //                     {...formik2.getFieldProps('passwordConfirmation')}
    //                   />
    //                   {formik2.touched.passwordConfirmation && formik2.errors.passwordConfirmation && (
    //                     <div className='text-danger'>
    //                       <span role='alert'>{formik2.errors.passwordConfirmation}</span>
    //                     </div>
    //                   )}
    //                 </div>
    //               </div>
    //             </div>

    //             <div className='form-text mb-4'>
    //             Password must be at least 8 character and contain symbols
    //             </div>

    //             <div className='d-flex'>
    //               <button
    //                 id='kt_password_submit'
    //                 type='submit'
    //                 className='btn btn-primary me-2 px-6'
    //               >
    //                 {!loading2 && 'Update Password'}
    //                 {loading2 && (
    //                   <span className='indicator-progress' style={{display: 'block'}}>
    //                     Loading...{' '}
    //                     <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //                   </span>
    //                 )}
    //               </button>
    //               <button
    //                 onClick={() => {
    //                   setPasswordForm(false)
    //                   formik2.resetForm()
    //                 }}
    //                 id='kt_password_cancel'
    //                 type='button'
    //                 className='btn btn-color-gray-400 btn-active-light-primary px-6'
    //               >
    //                 Cancel
    //               </button>
    //             </div>
    //           </form>
    //         </div>

    //         <div
    //           id='kt_signin_password_button'
    //           className={'ms-auto ' + (showPasswordForm && 'd-none')}
    //         >
    //           <button
    //             onClick={() => {
    //               setPasswordForm(true)
    //             }}
    //             className='btn btn-light btn-active-light-primary'
    //           >
    //             Reset Password
    //           </button>
    //         </div>
    //       </div>

    //       {/* {user?.has2FA ? (
    //         <>
    //           <div className='separator separator-dashed my-6'></div>
    //           <div className='d-flex flex-wrap align-items-center'>
    //             <div className='d-flex'>
    //               <div className='fs-6 fw-bolder mb-1 me-2'>
    //                 {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_SIGNIN_TWO_FACTOR'})}
    //               </div>
    //               <div className='badge badge-light-success fw-bolder'>
    //                 {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_SIGNIN_ENABLED'})}
    //               </div>
    //             </div>
    //             <div
    //               id='kt_signin_email_button'
    //               className={'ms-auto ' + (showEmailForm && 'd-none')}
    //             >
    //               <button
    //                 onClick={() => {
    //                   setShowConfirmPasswordModal(true)
    //                 }}
    //                 className='btn btn-danger'
    //               >
    //                 {!isDisableLoading && 'Disable'}
    //                 {isDisableLoading && (
    //                   <span className='indicator-progress' style={{display: 'block'}}>
    //                     {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_SIGNIN_DISABLING'})}{' '}
    //                     <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //                   </span>
    //                 )}
    //               </button>
    //             </div>
    //           </div>
    //         </>
    //       ) : (
    //         <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
    //           <KTSVG
    //             path='/media/icons/duotune/general/gen048.svg'
    //             className='svg-icon-2tx svg-icon-primary me-4'
    //           />
    //           <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
    //             <div className='mb-3 mb-md-0 fw-bold'>
    //               <h4 className='text-gray-800 fw-bolder'>
    //                 {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_SIGNIN_SECURE_ACCOUNT'})}
    //               </h4>
    //               <div className='fs-6 text-gray-600 pe-7'>
    //                 {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_SIGNIN_TWO_FACTOR_MESSAGE'})}
    //               </div>
    //             </div>
    //             <span
    //               role={'button'}
    //               className='btn btn-primary px-6 align-self-center text-nowrap'
    //               onClick={() => {
    //                 setShowChooseAuthModal(true)
    //               }}
    //             >
    //               {intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_LIST_ENABLE'})}
    //             </span>
    //           </div>
    //         </div>
    //       )} */}
    //     </div>
    //   </div>
    //     </div>
    // </>

    <>
      {showChooseAuthModal && (
        <ChooseAuthMethodModal
          show={showChooseAuthModal}
          closeModal={closeModal}
          userDetails={userDetails}
          getCustomerDetailsAPI={getCustomerDetailsAPI}
        />
      )}
      {showConfirmPasswordModal && (
        <ConfirmPasswordModal
          show={showConfirmPasswordModal}
          message={intl.formatMessage({ id: "USERS_CONFIRM_PASSWORD_AUTH" })}
          user_id={userDetails?.personalInformation?.id}
          onPasswordConfirm={disableUserTwoFactorAuthentication}
          closeModal={closeConfirmPasswordModal}
        />
      )}
      <Alert
        show={showSuccess ? showSuccess : showFailure}
        setShow={showSuccess ? setShowSuccess : setShowFailure}
        message={
          emailUpdate
            ? showSuccess
              ? messages.emailSuccess
              : messages.emailFailure
            : showSuccess
            ? messages.passwordSuccess
            : messages.passwordFailure
        }
        type={showSuccess ? "success" : "failure"}
      />
      {user?.personalInformation?.has2FA ? (
        <>
          {/* <div className='separator separator-dashed my-6'></div> */}
          <div
            className="notice d-flex flex-wrap align-items-center bg-light-primary rounded p-3 mx-2 mb-3"
            style={{ backgroundColor: "var(--accent-color-10)" }}
          >
            <div className="d-flex">
              <div className="fs-6 fw-bolder me-2">
                {intl.formatMessage({ id: "USERS_AUTHNETICATION" })}
              </div>
              <div className="badge badge-light-success fw-bolder">
                {intl.formatMessage({ id: "USERS_VERIFICATION_ENABLED_TEXT" })}
              </div>
            </div>
            <div
              id="kt_signin_email_button"
              className={"ms-auto "}
            >
              <button
                onClick={() => {
                  setShowConfirmPasswordModal(true);
                }}
                className="btn btn-danger"
              >
                {!isDisableLoading &&
                  intl.formatMessage({ id: "USERS_DISABLE_BUTTON" })}
                {isDisableLoading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({
                      id: "USERS_DISABLING_LOADING_MESSAGE",
                    })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              {/* <button onClick={disableUserTwoFactorAuthentication} className='btn btn-danger'>
                    {!isDisableLoading && 'Disable'}
                    {isDisableLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Disabling...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button> */}
            </div>
          </div>
        </>
      ) : (
        <div className="notice d-flex bg-light-primary rounded double-FA-box p-3 mx-2 mb-3">
          <div className="me-3">
            <BsShieldFillCheck
              className="shield-icon"
              style={{ top: "5px", width: "22px", position: "relative" }}
            />
          </div>
          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
            <div className="mb-3 mb-md-0">
              <h4 className=" fw-bold" style={{ fontSize: "16px" }}>
                {intl.formatMessage({ id: "USERS_SECURE_ACCOUNT" })}
              </h4>
              <div className=" text-gray-600 pe-7" style={{ fontSize: "14px" }}>
                {intl.formatMessage({ id: "USERS_AUTHENTICATION_INFO" })}
              </div>
            </div>
            <span
              role={"button"}
              className="btn btn-custom mx-md-2 mx-0 align-self-center text-nowrap"
              onClick={() => {
                setShowChooseAuthModal(true);
              }}
            >
              {intl.formatMessage({ id: "ACCOUNT_SETTINGS_SIGN_IN_ENABLE" })}
            </span>
          </div>
        </div>
      )}
      <div className="card mb-4 mb-xl-10 me-0 ms-0 card-no-border">
        <div
          className="card-header border-0 cursor-pointer card-header-custom pt-0"
          role="button"
          //  data-bs-toggle='collapse'
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <TitleHeader
            noMarginTop
            title={intl.formatMessage({
              id: "ACCOUNT_SETTINGS_SIGN_IN_CHANGE_EMAIL_TITLE",
            })}
          />
        </div>

        <div id="kt_account_profile_details" className="collapse show">
          <form onSubmit={formik1.handleSubmit} noValidate className="form">
            <div className="card-body pt-0 p-3">
              {/* New Email */}
              <div className="mb-3">
                {/* <label className=' col-lg-5 col-form-label required form-input-label'>
                 {translate('ACCOUNT_SETTINGS_SIGN_IN_NEW_EMAIL_LABEL')}
                </label>

                <div className='col-lg-7'>
                  <div className='fv-row '>
                    <input
                      type='text'
                      className=' mb-3 mb-lg-0 form-common-input'
                      style={{width:'100%'}}
                      {...formik1.getFieldProps('newEmail')}
                    />
                    {formik1.errors.newEmail && (
                      <div className='text-danger'>
                        <span role='alert'>{formik1.errors.newEmail}</span>
                      </div>
                    )}
                  </div>
                </div> */}

                <div className="mb-2">
                  <InputLabel
                    text={intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_SIGN_IN_CURRENT_EMAIL_LABEL",
                    })}
                  />
                  {`: ${user?.personalInformation?.email}`}
                </div>

                <InputLabel
                  required
                  text={intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_SIGN_IN_NEW_EMAIL_LABEL",
                  })}
                />

                <TextInput
                  value={formik1.values.newEmail}
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    formik1.setFieldValue("newEmail", value);
                  }}
                  isError={formik1.touched.newEmail && formik1.errors.newEmail}
                />
                {formik1.touched.newEmail && formik1.errors.newEmail && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik1.errors.newEmail}</span>
                  </div>
                )}
              </div>

              {/* Confirm Password */}
              <div className="">
                {/* <label className=' col-lg-5 col-form-label required form-input-label'>
                  {translate('ACCOUNT_SETTINGS_SIGN_IN_CURRENT_PASSWORD_LABEL')}
                </label>

                <div className='col-lg-7'>
                  <div className='fv-row'>
                    <input
                      type='password'
                      className=' mb-3 mb-lg-0 form-common-input input-password'
                      style={{width:'100%'}}
                      {...formik1.getFieldProps('confirmPassword')}
                    />
                    {formik1.errors.confirmPassword && (
                      <div className='text-danger'>
                        <span role='alert'>{formik1.errors.confirmPassword}</span>
                      </div>
                    )}
                  </div>
                </div> */}
                <InputLabel
                  required
                  text={intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_SIGN_IN_CURRENT_PASSWORD_LABEL",
                  })}
                />

                <TextInput
                  value={formik1.values.confirmPassword}
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    formik1.setFieldValue("confirmPassword", value);
                  }}
                  isError={
                    formik1.touched.confirmPassword &&
                    formik1.errors.confirmPassword
                  }
                />
                {formik1.touched.confirmPassword &&
                  formik1.errors.confirmPassword && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik1.errors.confirmPassword}</span>
                    </div>
                  )}
              </div>
            </div>

            <div className="card-footer d-flex justify-content-start py-3 px-9 card-footer-custom">
              <button
                type="submit"
                className="btn btn-custom"
                disabled={loading1}
              >
                {!loading1 &&
                  intl.formatMessage({ id: "ACCOUNT_SETTINGS_SIGN_IN_SUBMIT" })}
                {loading1 && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_SIGN_IN_LOADING",
                    })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="card mb-4 mb-xl-10 me-0 ms-0 card-no-border">
        <div
          className="card-header border-0 cursor-pointer card-header-custom pt-0"
          role="button"
          //  data-bs-toggle='collapse'
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <TitleHeader
            noMarginTop
            title={intl.formatMessage({
              id: "ACCOUNT_SETTINGS_SIGN_IN_RESET_PASSWORD_TITLE",
            })}
          />
        </div>

        <div id="kt_account_profile_details" className="collapse show">
          <form onSubmit={formik2.handleSubmit} noValidate className="form">
            <div className="card-body pt-0 p-3">
              {/* Current Password */}
              <div className="mb-3">
                {/* <label className=' col-lg-5 col-form-label required form-input-label'>
                  {translate('ACCOUNT_SETTINGS_SIGN_IN_CURRENT_PASSWORD_LABEL')}
                </label>

                <div className='col-lg-7'>
                  <div className='fv-row '>
                    <input
                      type='password'
                      className=' mb-3 mb-lg-0 form-common-input input-password'
                      style={{width:'100%'}}
                      {...formik2.getFieldProps('currentPassword')}
                    />
                    {formik2.errors.currentPassword && (
                      <div className='text-danger'>
                        <span role='alert'>{formik2.errors.currentPassword}</span>
                      </div>
                    )}
                  </div>
                </div> */}
                <InputLabel
                  required
                  text={intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_SIGN_IN_CURRENT_PASSWORD_LABEL",
                  })}
                />

                <TextInput
                  value={formik2.values.currentPassword}
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    formik2.setFieldValue("currentPassword", value);
                  }}
                  isError={
                    formik2.touched.currentPassword &&
                    formik2.errors.currentPassword
                  }
                />
                {formik2.touched.currentPassword &&
                  formik2.errors.currentPassword && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik2.errors.currentPassword}</span>
                    </div>
                  )}
              </div>

              {/* New Password */}
              <div className="mb-3">
                {/* <label className=' col-lg-5 col-form-label required form-input-label'>
                  {translate('ACCOUNT_SETTINGS_SIGN_IN_NEW_PASSWORD_LABEL')}
                </label>

                <div className='col-lg-7'>
                  <div className='fv-row'>
                    <input
                      type='password'
                      className=' mb-3 mb-lg-0 form-common-input input-password'
                      style={{width:'100%'}}
                      {...formik2.getFieldProps('newPassword')}
                    />
                    {formik2.errors.newPassword && (
                      <div className='text-danger'>
                        <span role='alert'>{formik2.errors.newPassword}</span>
                      </div>
                    )}
                  </div>
                </div> */}
                <InputLabel
                  required
                  text={intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_SIGN_IN_NEW_PASSWORD_LABEL",
                  })}
                />

                <TextInput
                  value={formik2.values.newPassword}
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    formik2.setFieldValue("newPassword", value);
                  }}
                  isError={
                    formik2.touched.newPassword && formik2.errors.newPassword
                  }
                />
                {formik2.touched.newPassword && formik2.errors.newPassword && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik2.errors.newPassword}</span>
                  </div>
                )}
              </div>

              {/* Confirm Password */}
              <div className="">
                {/* <label className=' col-lg-5 col-form-label required form-input-label'>
                  {translate('ACCOUNT_SETTINGS_SIGN_IN_CONFIRM_PASSWORD_LABEL')}
                </label>

                <div className='col-lg-7'>
                  <div className='fv-row'>
                    <input
                      type='password'
                      className=' mb-3 mb-lg-0 form-common-input input-password'
                      style={{width:'100%'}}
                      {...formik2.getFieldProps('passwordConfirmation')}
                    />
                    {formik2.errors.passwordConfirmation && (
                      <div className='text-danger'>
                        <span role='alert'>{formik2.errors.passwordConfirmation}</span>
                      </div>
                    )}
                  </div>
                </div> */}
                <InputLabel
                  required
                  text={intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_SIGN_IN_CONFIRM_PASSWORD_LABEL",
                  })}
                />

                <TextInput
                  value={formik2.values.passwordConfirmation}
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    formik2.setFieldValue("passwordConfirmation", value);
                  }}
                  isError={
                    formik2.touched.passwordConfirmation &&
                    formik2.errors.passwordConfirmation
                  }
                />
                {formik2.touched.passwordConfirmation &&
                  formik2.errors.passwordConfirmation && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">
                        {formik2.errors.passwordConfirmation}
                      </span>
                    </div>
                  )}
              </div>
            </div>

            <div className="card-footer d-flex justify-content-start py-3 px-9 card-footer-custom">
              <button
                type="submit"
                className="btn btn-custom"
                disabled={loading2}
              >
                {!loading2 &&
                  intl.formatMessage({ id: "ACCOUNT_SETTINGS_SIGN_IN_SUBMIT" })}
                {loading2 && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_SIGN_IN_LOADING",
                    })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignInSettings;
