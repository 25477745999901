import React from "react";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import "./style.scss";
export const MainLoader = ({ map }) => {
  const generalDetails = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  return (
    <Oval
      height={80}
      width={80}
      color={generalDetails.accent_color}
      wrapperStyle={{}}
      wrapperClass={map ? "map-loading-wrapper" : "loader-wrapper"}
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={generalDetails.accent_color}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};

const Loader = ({ map }) => {
  const generalDetails = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  return (
    <Oval
      height={80}
      width={80}
      color={generalDetails.accent_color}
      wrapperStyle={{}}
      wrapperClass={map ? "map-loading-wrapper" : "loader-wrapper"}
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={generalDetails.accent_color}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};

export const ComponentLoader = () => {
  const generalDetails = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  return (
    <Oval
      height={80}
      width={80}
      color={generalDetails.accent_color}
      wrapperStyle={{}}
      wrapperClass={"component-loader"}
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={generalDetails.accent_color}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};

export default Loader;
