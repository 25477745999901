import React, { useState } from "react";
import { useIntl } from "react-intl";
import OwlCarousel from "react-owl-carousel";
import CarouselArticle from "../Article/CarouselArticle";
import "./style.scss";
const FollowUpEvents = ({ sidebar, Id, followUpEvents, noLink, event }) => {
  const intl = useIntl();
  // const events = useSelector((state) => state.events.events);
  const [count, setCount] = useState(1);
  const navLeft =
    count === 1
      ? `<span class="arrow arrow-left arrow-disable"></span>`
      : `<span class="arrow arrow-left arrow-enable"></span>`;
  const navRight =
    count === 1
      ? `<span class="arrow arrow-right arrow-disable"></span>`
      : `<span class="arrow arrow-right arrow-enable"></span>`;

  const responsive = {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    991: {
      items: 2,
    },
  };
  const handleInitialize = (event) => {
    setCount(event.item.count);
  };
  return (
    <div
      className={`mb-3 mt-2 px-0 ${
        !sidebar && !event ? `col-lg-10 mx-auto` : ""
      }`}
      style={!sidebar ? { maxWidth: "750px" } : {}}
    >
      <div className="d-flex title-wrapper">
        <div className="flex-grow-1  d-flex border-section">
          <h4 className="mb-0 flex-grow-1 d-inline align-self-end">
            {intl.formatMessage({
              id: "SHARED_COMPONENTS_FOLLOW_UP_EVENTS_TITLE",
            })}
          </h4>
          <div className="custom-nav owl-nav mx-auto d-inline relatedposts-nav"></div>
        </div>
      </div>

      <div className="d-flex justify-content-center my-3">
        <OwlCarousel
          className="owl-theme"
          loop={followUpEvents && followUpEvents.length > 2 ? true : false}
          // loop
          margin={16}
          nav={true}
          lazyLoad={true}
          autoplay
          autoplaySpeed={5000}
          autoplayTimeout={5000}
          autoplayHoverPause={true}
          navText={[
            followUpEvents && followUpEvents.length > 2 ? navLeft : "",
            followUpEvents && followUpEvents.length > 2 ? navRight : "",
          ]}
          dots={false}
          responsive={responsive}
          navContainer=".d-flex .owl-nav.relatedposts-nav"
          onInitialized={handleInitialize}
        >
          {followUpEvents !== undefined &&
            followUpEvents.length > 0 &&
            followUpEvents.map((element, index) => {
              return (
                <CarouselArticle
                  details={element}
                  key={index}
                  sidebars={{ a: true }}
                  noLink={noLink}
                  //   relatedPosts={true}
                  type="event-list"
                />
              );
            })}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default FollowUpEvents;
