import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { getGeneralDetails } from "./Redux/GeneralDetailsSlice";
import MainRoutes from "./Routing/Routes";
import AuthInit from "./components/auth/Redux/AuthInit";
import config from "./config";
import { useLang } from "./i18n/Metronici18n";
import { I18nProvider } from "./i18n/i18nProvider";
import Loader from "./sharedComponents/Loader";
import ScrollToTop from "./sharedComponents/ScrollToTop/ScrollToTop";

export const useLocal = () => {
  let ls = localStorage.getItem("i18nConfig");
  if (ls) {
    try {
      return JSON.parse(ls).selectedLang;
    } catch (error) {
      throw error;
    }
  }
};

function App() {
  // const [locale, setLocale] = useState(LOCALES.GERMAN);
  const dispatch = useDispatch();
  const lang = useLang();
  useEffect(() => {
    dispatch(getGeneralDetails());
    //dispatch(getNavigationMenu())
  }, [dispatch]);

  // useEffect(() => {
  //   localStorage.setItem("language", locale);
  // }, [locale]);

  return (
    <>
      <Helmet>
        <title>{config.APP_NAME}</title>
        {/* <meta http-equiv="content-Language" content={lang} /> */}
        {/* <meta name="copyright" content={config.APP_COMPANY_NAME} /> */}
      </Helmet>
      <I18nProvider
      //locale={locale}
      >
        <BrowserRouter>
          <div className="App">
            <AuthInit>
              <Suspense fallback={<Loader />}>
                <ToastContainer />
                <MainRoutes
                // setLocale={setLocale} locale={locale}
                />
                <ScrollToTop />
              </Suspense>
            </AuthInit>
          </div>
        </BrowserRouter>
      </I18nProvider>
    </>
  );
}

export default App;
