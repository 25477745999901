import moment from "moment";
import React, { useEffect, useState } from "react";
import { RiCalendarTodoFill } from "react-icons/ri";
import { useIntl } from "react-intl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getAuthorDetails } from "../../API/Api";
import { useLocal } from "../../App";
import noImage from "../../assets/noImage.jpg";
import Loader from "../../sharedComponents/Loader";
import PlayerIcon from "../../sharedComponents/PlayerIcon";
import Title from "../../sharedComponents/Title";
import { formatDate, useCustomMediaQuery } from "../../utils";

const AuthorProfile = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [authorDetails, setAuthorDetails] = useState([]);
  // const [err, setErr] = useState(false);
  const { resolution } = useCustomMediaQuery();
  const local = useLocal();
  const intl = useIntl();

  useEffect(() => {
    setLoading(true);
    getAuthorDetails(id)
      .then((res) => {
        setAuthorDetails(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [id]);

  const setAuthorMediaSource = (ele) => {
    if (
      ele?.profile_photo_resolutions &&
      ele?.profile_photo_resolutions.length > 0
    ) {
      const source = ele?.profile_photo_resolutions.filter(
        (element) => element?.resolution === resolution
      );
      return source[0].file_url;
    }
    return ele?.profile_photo_url ? ele?.profile_photo_url : noImage;
  };

  return loading === true ? (
    <Loader />
  ) : authorDetails && Object.entries(authorDetails).length > 0 ? (
    <div className="mt-4">
      <Title title={"Author Profile"} />
      <div className="container px-lg-4 px-xl-5 position-relative">
        <section className="mt-4">
          <div className="row">
            <div className="col-lg-4">
              <div
                className="mb-4 author-profile-card"
                style={{ height: "fit-content" }}
              >
                <div className="text-center">
                  <img
                    className="author_profile_img"
                    src={setAuthorMediaSource(authorDetails)}
                    alt="author-profile"
                  />
                  <h5 className="mb-2 py-3">
                    {authorDetails?.firstname ? authorDetails.firstname : ""}{" "}
                    {authorDetails?.lastname ? authorDetails.lastname : ""}
                  </h5>
                </div>
              </div>
              {authorDetails?.description && (
                <div
                  className="author-profile-description-container"
                  style={{ height: "fit-content" }}
                >
                  <div className="text-left px-4">
                    <h5>
                      {intl.formatMessage({
                        id: "AUTHOR_PROFILE_DESCRIPTION_HEADING",
                      })}
                    </h5>
                    <p className="">
                      {authorDetails?.description ? (
                        <div
                          className="author-profile-description"
                          dangerouslySetInnerHTML={{
                            __html: authorDetails?.description,
                          }}
                        >
                          {/* {formatString(posts?.[eventId]?.description)} */}
                        </div>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <hr className="author-profile-rule" />
            <div className="col-lg-8 ">
              {authorDetails?.posts.slice(0, 29).map((item) => (
                <Link
                  to={
                    item?.seo_permalink
                      ? `../post/${item.id}/${item?.seo_permalink}`
                      : `../post/${item.id}/${item?.title?.replace(
                          /\s+/g,
                          "-"
                        )}`
                  }
                >
                  <div
                    key={item.id}
                    className={"col-lg-12 article-two-container mb-3"}
                    style={{ padding: "0 20px !important" }}
                  >
                    <div className="row">
                      <div className="col-5">
                        <div className="img-container rounded-1 event-image">
                          {item?.post_type === "video" ||
                          item?.post_type === "audio" ? (
                            <PlayerIcon type={item?.post_type} />
                          ) : null}
                          <LazyLoadImage
                            src={
                              item?.post_type === "video" ||
                              item?.post_type === "audio"
                                ? item?.post_thumbnail
                                : item?.post_media
                            }
                            effect="blur"
                            alt="image"
                            wrapperClassName="img img-fluid w-100 image-responsive"
                            placeholder={
                              <span className="img-placeholder w-100" />
                            }
                          />
                        </div>
                      </div>
                      <div className={`col-7 pl-0 d-flex align-items-start`}>
                        <div className="article-two-body ">
                          <div className="article-two-content">
                            <div className="article-two-author-info mb-1">
                              <ul
                                className="list-inline d-flex align-items-center"
                                style={{ marginBottom: "0px" }}
                              >
                                {item?.published_at && (
                                  <li className="list-inline-item">
                                    <span
                                      className="text-dark d-flex justify-content-center align-items-center"
                                      style={{
                                        marginRight: "1rem",
                                        height: "30px",
                                      }}
                                    >
                                      <RiCalendarTodoFill className="icon-calendar" />
                                      {local && local === "en"
                                        ? formatDate(item?.published_at, true)
                                        : moment(item?.published_at).format(
                                            "DD.MM.yyyy HH:mm"
                                          )}
                                    </span>
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div className="article-two-title">
                              <h5>{item.title}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : (
    ""
  );
};

export default AuthorProfile;
