import React from "react";
const TagsList = ({ name, postsCount, handleClick, id }) => {
  return (
    <li className="list-inline-item">
      <div onClick={() => handleClick(id, name)}>
        <span>#{name}</span>
        <span style={{ marginLeft: "12px" }}>{postsCount}</span>
      </div>
    </li>
  );
};

export default TagsList;
