import { Player } from "@lottiefiles/react-lottie-player";
import { useFormik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCustomMediaQuery } from "../../../utils";
import { codeVerification } from "../Redux/AuthApi";
//import{ useTranslate} from "../../../i18n/translate";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";
import CustomFooter from "../../../sharedComponents/Footer/CustomFooter";
import InputLabel from "../../../sharedComponents/InputLabel/InputLabel";
import TextInput from "../../../sharedComponents/TextInput/TextInput";

function LoginCodeVerification({ submitted, setcustomSignupEmail }) {
  const { code } = useParams();
  const intl = useIntl();
  const initialValues = {
    email: code ? code : "",
    code: "",
  };

  const codeSchema = Yup.object().shape({
    email: code
      ? Yup.string()
      : Yup.string()
          .email(intl.formatMessage({ id: "USERS_WRONG_EMAIL_FORMAT" }))
          .required(intl.formatMessage({ id: "USERS_EMAIL_REQUIRED" })),
    code: Yup.string().required(
      intl.formatMessage({ id: "USERS_CODE_REQUIRED" })
    ),
  });

  // const generalDetails = useSelector(
  //   (state) => state.generalDetails.generalDetails
  // );
  // const locale = localStorage.getItem("language");
  const {
    isXsScreen,
    // isSmScreen,
    // isMdScreen,
    isLgScreen,
    isXLgScreen,
    isXxLgScreen,
  } = useCustomMediaQuery();
  const user_sign_In = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings?.user_sign_In
  );

  const formik = useFormik({
    initialValues,
    validationSchema: codeSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //   setLoading(true)
      //   setHasErrors(undefined)
      setSubmitting(true);
      setTimeout(() => {
        codeVerification(values.email, values.code)
          .then((response) => {
            // SuccessAlert('Code is verified')
            if (response.status === 200) {
              submitted();
              setcustomSignupEmail(values.email);
              // setSubmitting(false)
            } else if (response.status === 210) {
              setStatus(intl.formatMessage({ id: "USERS_INVALID_DETAILS" }));
            } else {
              setStatus(
                intl.formatMessage({ id: "MESSAGE_CONTACT_SYS_ADMIN" })
              );
            }
          })
          .catch(() => {
            // setHasErrors(true)
            //setSubmitting(false);
            setStatus(intl.formatMessage({ id: "USERS_INVALID_CODE" }));
          })
          .finally(() => {
            setSubmitting(false);
            // setLoading(false)
          });
      }, 1000);
    },
  });

  return (
    <div className="">
      <div
        className="container"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="row d-flex align-items-center justify-content-center mt-4 mx-auto"
          style={{
            width: isXsScreen ? "100%" : "90%",
            boxShadow:
              "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          {isLgScreen || isXLgScreen || isXxLgScreen ? (
            <div className="col-lg-6 col-xl-6 py-5">
              <Player
                autoplay
                loop={true}
                src="https://assets1.lottiefiles.com/packages/lf20_gysrp57x.json"
                style={{ height: "400px", width: "400px" }}
              ></Player>
            </div>
          ) : null}
          <div className="col-md-12 col-lg-5 col-xl-5">
            <div className="p-4">
              <div className="text-center mb-5">
                {/* <img
                src={generalDetails?.logo_url}
                className="img-fluid logo"
                style={{ maxHeight: "64px" }}
              /> */}
                <h5 className="">
                  {intl.formatMessage({ id: "USER_AUTHENTICATION" })}
                </h5>
                <p className="" style={{ color: "rgb(0,0,0,0.5)" }}>
                  {intl.formatMessage({ id: "USER_AUTHENTICATION_MESSAGE" })}
                </p>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <div className="mb-5"></div>
                {code ? null : (
                  <div className="form-outline mb-2 position-relative">
                    {/* <input
                  {...formik.getFieldProps("email")}
                  id="form-input"
                  type="email"
                  name="email"
                  autoComplete="off"
                  placeholder="example@email.com"
                  value={formik.values.email}
                  className="mb-3"
                />
                <label placeholder="Email" alt="Email" id="form-label"></label>
                {formik.touched.email &&
                  formik.errors &&
                  formik.errors.email && (
                    <span
                      role="alert "
                      className="position-absolute"
                      style={{
                        top: "55px",
                        color: "var(--error-alert)",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.email}
                    </span>
                  )} */}
                    <InputLabel
                      required
                      text={
                        // JSON.stringify(locale).includes("de") ? "Email" : "Email"
                        intl.formatMessage({ id: "LABEL_EMAIL" })
                      }
                    />

                    <TextInput
                      value={formik.values.email}
                      onChange={(value) => {
                        formik.setFieldValue("email", value);
                      }}
                      isError={formik.touched.email && formik.errors.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: "var(--error-alert)",
                        }}
                      >
                        <span role="alert">{formik.errors.email}</span>
                      </div>
                    )}
                  </div>
                )}

                <div className="mb-4">
                  {/* <input
                  name="code"
                  label="code"
                  type="string"
                  id="form-input"
                  value={formik.values.code}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <label placeholder="Code" alt="Code" id="form-label"></label>
                {formik.touched.code && formik.errors && formik.errors.code && (
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: "var(--font-Family)",
                      color: "var(--error-alert)",
                      position: "relative",
                      top: "-20px",
                    }}
                  >
                    {formik.errors?.code}
                  </div>
                )} */}
                  <InputLabel
                    required
                    text={
                      // JSON.stringify(locale).includes("de") ? "Code" : "Code"
                      intl.formatMessage({ id: "LABEL_CODE" })
                    }
                  />

                  <TextInput
                    value={formik.values.code}
                    onChange={(value) => {
                      formik.setFieldValue("code", value);
                    }}
                    isError={formik.touched.code && formik.errors.code}
                  />
                  {formik.touched.code && formik.errors.code && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.code}</span>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-between align-items-center flex-column">
                  <button
                    type="submit"
                    id="button-custom-two"
                    className="mx-0"
                    style={{ width: "100%", borderRadius: "3px" }}
                    disabled={formik.isSubmitting}
                  >
                    {intl.formatMessage({ id: "BUTTON_NEXT" })}
                    {formik.isSubmitting ? (
                      <Oval
                        height={20}
                        width={15}
                        color="white"
                        wrapperStyle={{
                          display: "inline-block",
                          marginLeft: "2rem",
                          verticalAlign: "middle",
                          padding: "0px",
                        }}
                        wrapperClass="py-0"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="white"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                      />
                    ) : null}
                  </button>

                  {user_sign_In && user_sign_In === "no" ? (
                    <Link
                      to="/"
                      className="mt-4"
                      style={{ color: "var(--accent-color)" }}
                    >
                      {intl.formatMessage({ id: "BUTTON_BACK_TO_HOME" })}
                    </Link>
                  ) : (
                    <Link
                      to="/login"
                      className="mt-4"
                      style={{ color: "var(--accent-color)" }}
                    >
                      {intl.formatMessage({ id: "MESSAGE_BACK_TO_LOGIN" })}
                    </Link>
                  )}
                  {formik.status && (
                    <div
                      className={`mb-10 mt-3 ${
                        formik.status?.status === "Success"
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }`}
                      style={{
                        padding: "10px",
                        borderRadius: "3px",
                        width: "100%",
                      }}
                    >
                      <div className="alert-text font-weight-bold text-center">
                        {formik.status}
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <CustomFooter />
      </div>
    </div>
  );
}

export default LoginCodeVerification;
