export const actionTypes = {
  SET_COMMUNITIES: "SET_COMMUNITIES",
  SET_JOINED_COMMUNITIES: "SET_JOINED_COMMUNITIES",
};

const initialState = {
  communities: {},
  joinedCommunities: {},
};

export const actions = {
  setCommunities: (payload) => ({
    type: actionTypes.SET_COMMUNITIES,
    payload: payload,
  }),
  setJoinedCommunities: (payload) => ({
    type: actionTypes.SET_JOINED_COMMUNITIES,
    payload: payload,
  }),
};

export const CommunitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMMUNITIES: {
      const data = action.payload || {};
      return { ...state, communities: data };
    }

    case actionTypes.SET_JOINED_COMMUNITIES: {
      const data = action.payload || {};
      return { ...state, joinedCommunities: data };
    }

    default:
      return state;
  }
};
