import { convert } from "html-to-text";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GridArticle from "../../sharedComponents/Article/GridArticle";
import ListArticle from "../../sharedComponents/Article/ListArticle";
//import PageNumber from "../../sharedComponents/Pagination";
import Pagination from "../../sharedComponents/Pagination/Pagination";
import Title, { TitleHeader } from "../../sharedComponents/Title";
// import {useTranslate} from "../../i18n/translate";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { useIntl } from "react-intl";
const EventList = ({
  details,
  pageName,
  uniqueId,
  sidebars,
  type,
  ...props
}) => {
  // const [pageId, setPageId] = useState(1);
  const intl = useIntl();
  const postsData = useSelector(
    (state) => state.postsDetails?.[pageName]?.[uniqueId]?.posts
  );
  // const isLoading = useSelector(
  //   (state) => state?.postsDetails?.[pageName]?.[uniqueId]?.isLoading
  // );
  const data = useSelector(
    (state) => state.postsDetails?.[pageName]?.[uniqueId]
  );
  const { title, default_view } = details;
  const paginationDetails = data?.paginationDetails;
  const currentPage = paginationDetails?.currentPage
    ? paginationDetails?.currentPage
    : 1;
  const [viewDetails, setViewDetails] = useState(default_view);
  const [searchText, setSearchText] = useState("");
  const [eventDetails, setEventDetails] = useState();

  useEffect(() => {
    setSearchText("");
    if (
      searchText.length === 0 &&
      postsData &&
      Object.values(postsData).length > 0
    ) {
      let data = Object.values(postsData[currentPage]).map((ele) => ele);
      setEventDetails(data);
    }
    //eslint-disable-next-line
  }, [postsData]);

  useEffect(() => {
    if (postsData && Object.values(postsData).length > 0) {
      let updatedEvents = Object.values(postsData[currentPage]).filter(
        (post) => {
          let subTitle = post.sub_title
            ? // ? post.sub_title.replace(/(<([^>]+)>)/gi, "")
              convert(post.sub_title)
            : "";
          return (
            post.title
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase()) ||
            subTitle
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
          );
        }
      );
      setEventDetails(updatedEvents);
    }
    //eslint-disable-next-line
  }, [searchText]);

  const onSearchTextChange = (text) => {
    setSearchText(text || "");
  };
  // const locale = localStorage.getItem("language");
  return (
    <section {...props} className={`mb-4`}>
      {details?.display_header ? (
        sidebars && Object.entries(sidebars).length > 0 ? (
          <TitleHeader title={title} header_navigation_id={details?.header_navigation_id} noMarginTop />
        ) : (
          <Title title={title} header_navigation_id={details?.header_navigation_id} />
        )
      ) : null}
      <div
        className={`${
          sidebars && Object.entries(sidebars).length === 0 ? "container" : ""
        }`}
      >
        <div className="d-flex justify-content-between">
          {details && details.search_bar === 1 ? (
            <div className="d-flex flex-column">
              <div className="align-items-center mb-3">
                <input
                  type="text"
                  className="form-control form-control-solid ps-15"
                  placeholder={intl.formatMessage({
                    id: "LABEL_ENTER_SEARCH_TEXT",
                  })}
                  value={searchText}
                  onChange={(e) => {
                    onSearchTextChange(e.target.value);
                  }}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    width: "295px",
                  }}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {details && details.switch_between_views === 1 ? (
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="px-3">
                <button
                  className="d-flex justify-content-center align-items-center viewButton"
                  onClick={() => {
                    if (viewDetails && viewDetails === "grid") {
                      setViewDetails("list");
                    }
                  }}
                  title={intl.formatMessage({ id: "TITLE_LIST_VIEW" })}
                  disabled={viewDetails === "list" ? true : false}
                  style={{ padding: "8px" }}
                >
                  <AiOutlineUnorderedList className="icon-calendar cursor-pointer" />
                </button>
              </div>
              <div className="">
                <button
                  className="d-flex justify-content-center align-items-center viewButton"
                  onClick={() => {
                    if (viewDetails && viewDetails === "list") {
                      setViewDetails("grid");
                    }
                  }}
                  title={intl.formatMessage({ id: "TITLE_GRID_VIEW" })}
                  disabled={viewDetails === "grid" ? true : false}
                  style={{ padding: "8px" }}
                >
                  <BsFillGrid3X3GapFill className="icon-calendar cursor-pointer" />
                </button>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className="row mb-2 justify-content-between">
          {/* {isLoading ? (
            <div className="position-relative" style={{minHeight:'250px'}}>
            <Loader />
            </div>
          ) :  */}
          {eventDetails && eventDetails.length > 0 ? (
            eventDetails.map((ele) => {
              if (viewDetails && viewDetails === "grid") {
                return (
                  <GridArticle
                    key={ele.id}
                    className="col-lg-6 col-md-6 mb-4"
                    details={ele}
                    type={type}
                  />
                );
              } else {
                return (
                  <ListArticle
                    key={ele.id}
                    className="col-lg-12"
                    details={ele}
                    sidebars={sidebars}
                    type={type}
                  />
                );
              }
            })
          ) : (
            <p className="text-center">
              {intl.formatMessage({ id: "NO_DATA" })}
            </p>
          )}
        </div>

        {paginationDetails && Object.entries(paginationDetails).length > 0 ? (
          // <PageNumber
          //   paginationDetails={paginationDetails}
          //   pageName={pageName}
          //   uniqueId={uniqueId}
          //   pageId={currentPage}
          // />
          <Pagination
            paginationDetails={paginationDetails}
            pageName={pageName}
            uniqueId={uniqueId}
            pageId={currentPage}
          />
        ) : null}
      </div>
    </section>
  );
};

export default EventList;
