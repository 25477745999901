const specials = [
  { ä: "ae" },
  { ü: "ue" },
  { ö: "oe" },
  { Ä: "Ae" },
  { Ü: "Ue" },
  { Ö: "Oe" },
  { ß: "ss" },
  { "´": "" },
];

const CheckSpecialChar = (title) => {
  let Finalresult = "";

  const bool = specials.some((special, index) =>
    title.includes(Object.keys(special)[0])
  );

  if (bool) {
    specials.map((special, index) => {
      if (title.includes(Object.keys(special)[0])) {
        const result = title.replaceAll(
          Object.keys(special)[0],
          special[Object.keys(special)[0]]
        );

        Finalresult = result;
      }
    });
    return Finalresult.toLocaleLowerCase();
  } else {
    return title.toLocaleLowerCase();
  }
};

export default CheckSpecialChar;
