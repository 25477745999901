import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import { actions } from "../../../Redux/AuthReducer";
import { actions } from "../../Redux/AuthReducer";
// import CloseButton from '../../../sharedComponents/closeButton/CloseButton'
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";

// images
// import SettingsIcon from '../../../../_metronic/assets/icons/settings.svg'
// import {SVGICON} from '../../../../_metronic/helpers'

// import {loginAfter2FA, verifyCode} from '../redux/TwoFactorAuthAPI'
// import * as auth from '../../auth/redux/AuthRedux'
import { AiFillSetting } from "react-icons/ai";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { loginAfter2FA, verifyCode } from "../../components/auth/Redux/AuthApi";
import InputLabel from "../InputLabel/InputLabel";
import TextInput from "../TextInput/TextInput";

const CodeValidationSchema = Yup.object().shape({
  verify_code: Yup.string().required("Code is required"),
});

const initialValues = {
  verify_code: "",
};

const LoginTwoFactorAuthModal = ({
  show,
  closeModal,
  userId,
  email,
  password,
  closeLoginModal,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  // state
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedAuthMethod, setSelectedAuthMethod] = useState(1);
  const [error, setError] = useState(false);

  // @ts-ignore
  const clientDetails_id = parseInt(
    sessionStorage.getItem("clientDetails_id") || "0"
  );
  const clientDetails_secret =
    sessionStorage.getItem("clientDetails_secret") || "";

  const ChooseAuthMethod = () => {
    return (
      <div>
        <p
          className="text-muted mb-10"
          style={{ fontSize: "18px", fontWeight: "500" }}
        >
          {intl.formatMessage({ id: "USERS_LOGIN_INFO" })}
        </p>
        <div className="">
          {/* recovery code */}
          <label
            className={clsx(
              "btn bg-light-primary rounded p-3 d-flex align-items-center svg-icon svg-icon-3x mb-4",
              selectedAuthMethod === 1 ? "double-FA-box" : "login-double-FA-box"
            )}
            onClick={() => {
              setSelectedAuthMethod(1);
            }}
            htmlFor="kt_modal_two_factor_authentication_option_1"
          >
            {/* <SVGICON src={SettingsIcon} className={'svg-icon-4x svg-icon-primary me-4'} /> */}
            <AiFillSetting
              className="shield-icon"
              style={{ marginRight: "20px", top: "0px" }}
            />
            <span className="d-block fw-bold text-start">
              <span
                className="text-dark fw-bolder d-block"
                style={{ fontSize: "22px" }}
              >
                {intl.formatMessage({ id: "USERS_LOGIN_RECOVERY_MODE" })}
              </span>
              <span className="text-muted fw-bold" style={{ fontSize: "16px" }}>
                {intl.formatMessage({ id: "USERS_AUTHENTICATOR_APP_INFO" })}
              </span>
            </span>
          </label>

          {/* auth code */}
          <label
            className={clsx(
              "btn bg-light-primary rounded p-3 d-flex align-items-center svg-icon svg-icon-3x",
              selectedAuthMethod === 2 ? "double-FA-box" : "login-double-FA-box"
            )}
            onClick={() => {
              setSelectedAuthMethod(2);
            }}
            htmlFor="kt_modal_two_factor_authentication_option_1"
          >
            {/* <SVGICON src={SettingsIcon} className={'svg-icon-4x svg-icon-primary me-4'} /> */}
            <AiFillSetting
              className="shield-icon"
              style={{ marginRight: "20px", top: "0px" }}
            />
            <span className="d-block fw-bold text-start">
              <span
                className="text-dark fw-bolder d-block"
                style={{ fontSize: "22px" }}
              >
                {intl.formatMessage({ id: "USERS_LOGIN_AUTHENTICATOR_CODE" })}
              </span>
              <span className="text-muted fw-bold" style={{ fontSize: "16px" }}>
                {intl.formatMessage({ id: "USERS_AUTHENTICATOR_APP_INFO" })}
              </span>
            </span>
          </label>
        </div>
      </div>
    );
  };

  let handleCodeSubmit = () => {};
  const EnterCodeForm = () => {
    return (
      <div>
        {/* <p className='text-muted fs-5 fw-bold mb-10'>You’ll have to enter your Code to login</p> */}
        {/* <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>
            {selectedAuthMethod === 1
              ? intl.formatMessage({id: 'USERS_LOGIN_RECOVERY_MODE'})
              : intl.formatMessage({id: 'USERS_LOGIN_AUTHENTICATOR_CODE'})}
          </span>
        </label> */}
        {error && (
          <div className="mb-3 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {intl.formatMessage({ id: "USERS_INCORRECT_AUTH_CODE" })}
            </div>
          </div>
        )}
        <InputLabel
          required
          text={
            selectedAuthMethod === 1
              ? intl.formatMessage({ id: "USERS_LOGIN_RECOVERY_MODE" })
              : intl.formatMessage({ id: "USERS_LOGIN_AUTHENTICATOR_CODE" })
          }
        />
        <Formik
          initialValues={initialValues}
          validationSchema={CodeValidationSchema}
          onSubmit={(values) => {
            // @ts-ignore
            onFormSubmit(values);
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            setStatus,
          }) => {
            handleCodeSubmit = handleSubmit;
            return (
              <>
                {/* <input
                  type='text'
                  placeholder={intl.formatMessage({id: 'USERS_VERIFY_CODE_PLACEHOLDER'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  name='verify_code'
                  autoComplete='off'
                  onChange={handleChange('verify_code')}
                  value={values.verify_code}
                /> */}
                <TextInput
                  value={values.verify_code}
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    setFieldValue("verify_code", value);
                  }}
                  isError={touched.verify_code && errors.verify_code}
                />
                {touched.verify_code && errors.verify_code && (
                  <div className="text-danger">
                    <span role="alert">{errors.verify_code}</span>
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    );
  };

  const saveAccessToken = (accessToken) => {
    sessionStorage.setItem("accessToken", accessToken);
    setTimeout((accessToken) => {
      dispatch(actions.login(accessToken));
    }, 0);
  };

  const saveRefreshToken = (refresh_token) => {
    localStorage.setItem("refreshToken", refresh_token);
  };

  const onFormSubmit = (values) => {
    const code_name =
      selectedAuthMethod === 1 ? "recovery_code" : "verify_code";
    if (values.verify_code && userId) {
      setLoading(true);
      verifyCode(userId, code_name, values.verify_code)
        .then((data) => {
          setLoading(true);
          loginAfter2FA(
            userId,
            email,
            password,
            clientDetails_id,
            clientDetails_secret
          )
            .then(
              ({
                data: {
                  userDetails,
                  tokenDetails: { access_token, refresh_token } = {},
                },
              }) => {
                dispatch(actions.setUser(userDetails));
                setLoading(false);
                access_token && saveAccessToken(access_token);
                refresh_token && saveRefreshToken(refresh_token);
                navigate("/");
                closeLoginModal?.();
              }
            )
            .catch(() => {
              setError(true);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="cam-modal-container">
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        contentClassName={loading ? "pe-none" : ""}
      >
        <Modal.Header closeButton onHide={closeModal}>
          <div className="d-flex flex-row flex-fill align-items-center justify-content-between">
            <Modal.Title style={{ fontSize: "20px", fontWeight: "600" }}>
              {intl.formatMessage({ id: "USERS_AUTH_MODAL_TITLE" })}
            </Modal.Title>
            {/* <CloseButton onClose={closeModal} /> */}
          </div>
        </Modal.Header>
        <Modal.Body className="py-4 px-lg-5">
          {step === 1 ? <ChooseAuthMethod /> : <EnterCodeForm />}
        </Modal.Body>
        <Modal.Footer className="justify-content-center px-lg-5">
          {step === 1 ? (
            <button
              className="btn btn-custom mx-0 my-3 w-100"
              data-kt-element="options-select"
              style={{ height: "42px" }}
              onClick={() => {
                setStep(2);
              }}
            >
              {intl.formatMessage({ id: "CONTINUE_BUTTON" })}
            </button>
          ) : step === 2 ? (
            <>
              <Button
                variant="secondary"
                style={{ padding: "10px 20px" }}
                onClick={closeModal}
              >
                {intl.formatMessage({ id: "CLOSE_BUTTON" })}
              </Button>
              <button
                className="btn btn-custom"
                style={{ padding: "10px 20px" }}
                onClick={() => {
                  handleCodeSubmit();
                }}
              >
                {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </>
          ) : (
            <>
              <Button variant="primary w-100" onClick={closeModal}>
                {intl.formatMessage({ id: "OKAY_BUTTON" })}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LoginTwoFactorAuthModal;
