import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { actions } from "../../../Redux/AuthReducer";
import { login } from "../Redux/AuthApi";
import "../style.scss";
import "./style.scss";
//import {useTranslate} from "../../../i18n/translate";
import axios from "axios";
import { useState } from "react";
import { browserName } from "react-device-detect";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";
import InputLabel from "../../../sharedComponents/InputLabel/InputLabel";
import LoginTwoFactorAuthModal from "../../../sharedComponents/Modal/LoginTwoFactorAuthModal";
import TextInput from "../../../sharedComponents/TextInput/TextInput";

function Login() {
  const intl = useIntl();
  const [showLoginTwoFactorAuthModal, setShowLoginTwoFactorAuthModal] =
    useState(false);
  const [userId, setUserId] = useState();
  const [ip, setIP] = useState("");
  // const generalDetails = useSelector(
  //   (state) => state.generalDetails.generalDetails
  // );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientDetails_id = parseInt(
    sessionStorage.getItem("clientDetails_id") || "0"
  );
  const clientDetails_secret =
    sessionStorage.getItem("clientDetails_secret") || "";

  const saveAccessToken = (accessToken) => {
    sessionStorage.setItem("accessToken", accessToken);
    setTimeout((accessToken) => {
      dispatch(actions.login(accessToken));
    }, 0);
  };

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  const user_registration = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings?.user_registration
  );

  const saveRefreshToken = (refresh_token) => {
    localStorage.setItem("refreshToken", refresh_token);
  };
  const initialValues = {
    email: "",
    password: "",
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "USERS_WRONG_EMAIL_FORMAT" }))
      .required(intl.formatMessage({ id: "USERS_EMAIL_REQUIRED" })),
    password: Yup.string().required(
      intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //   setLoading(true)
      setSubmitting(true);
      login({
        client_id: clientDetails_id,
        client_secret: clientDetails_secret,
        email: values.email,
        password: values.password,
        ip: ip,
        browser_agent: browserName,
      })
        .then(
          ({
            data: {
              userDetails,
              tokenDetails: { access_token, refresh_token } = {},
            },
          }) => {
            const { has2FA, id } = userDetails;
            dispatch(actions.setUser(userDetails));
            if (has2FA) {
              setUserId(id);
              setShowLoginTwoFactorAuthModal(true);
            } else {
              // save access_token in session storage
              access_token && saveAccessToken(access_token);
              refresh_token && saveRefreshToken(refresh_token);
              navigate("/");
              // setLoading(false)
            }
          }
        )
        .catch((err) => {
          err.response?.data?.errors?.content
            ? setStatus(err.response.data?.errors?.content)
            : err.response?.data?.errors?.email
            ? setStatus(err.response.data?.errors?.email)
            : setStatus(
                intl.formatMessage({
                  id: "VALIDATE_LOGIN_DETAILS_ERROR_MESSAGE",
                })
              );
          //   setLoading(false)
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
          //   setLoading(false)
        });
    },
  });

  const closeModal = () => {
    setShowLoginTwoFactorAuthModal(false);
  };
  return (
    <>
      {showLoginTwoFactorAuthModal && (
        <LoginTwoFactorAuthModal
          show={showLoginTwoFactorAuthModal}
          closeModal={closeModal}
          userId={userId}
          email={formik.values.email}
          password={formik.values.password}
        />
      )}
      <form
        className=" p-4 mx-auto"
        // style={{ width: "400px" }}
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* <div className="text-center mb-4">
        <img
          src={generalDetails?.logo_url}
          className="img-fluid logo"
          style={{ maxHeight: "64px" }}
        />
      </div> */}
        <div className=" text-center mb-5">
          <h4 className="mb-2 ">{intl.formatMessage({ id: "LOGIN" })}</h4>
          <p className="mt-2" style={{ color: "rgb(0,0,0,0.5)" }}>
            {intl.formatMessage({ id: "LOGIN_MESSAGE_ONE" })}
          </p>
          {/* <h6 className='mb-2 mt-2 modal-color text-center welcome-text' style={{fontSize:'14px'}}>
              Please enter your details to experience all the features
            </h6> */}
        </div>

        <div className="fv-row mb-2 position-relative w-100">
          <InputLabel required text={intl.formatMessage({ id: "EMAIL" })} />
          <TextInput
            value={formik.values.email}
            onChange={(value) => {
              formik.setFieldValue("email", value);
            }}
            isError={formik.touched.email && formik.errors.email}
          />
          {formik.touched.email && formik.errors.email && (
            <div style={{ color: "var(--error-alert)" }}>
              <span role="alert">{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* <div className="form-outline mt-5 mb-4 position-relative">
        <input
          {...formik.getFieldProps("password")}
          id="form-input"
          type="password"
          name="password"
          value={formik.values.password}
          autoComplete="off"
          placeholder="******"
          className="mb-3"
        />
        <label placeholder="Password" alt="Password" id="form-label"></label>
        {formik.touched.password && formik.errors.password && (
          <span
            role="alert"
            className="position-absolute"
            style={{
              top: "55px",
              color: "var(--error-alert)",
              fontSize: "12px",
            }}
          >
            {formik.errors.password}
          </span>
        )}
      </div> */}
        <div className="fv-row mb-2 position-relative w-100">
          <InputLabel required text={intl.formatMessage({ id: "PASSWORD" })} />
          <TextInput
            value={formik.values.password}
            type="password"
            onChange={(value) => {
              formik.setFieldValue("password", value);
            }}
            isError={formik.touched.password && formik.errors.password}
          />
          {formik.touched.password && formik.errors.password && (
            <div style={{ color: "var(--error-alert)" }}>
              <span role="alert">{formik.errors.password}</span>
            </div>
          )}
        </div>
        <div className="pb-2 mt-3">
          <button
            type="submit"
            id="button-custom-two"
            className="btn-block"
            style={{ width: "100%", borderRadius: "3px" }}
            disabled={formik.isSubmitting}
          >
            {intl.formatMessage({ id: "SIGN_IN" })}
            {formik.isSubmitting ? (
              <Oval
                height={20}
                width={15}
                color="white"
                wrapperStyle={{
                  display: "inline-block",
                  marginLeft: "2rem",
                  verticalAlign: "middle",
                  padding: "0px",
                }}
                wrapperClass="py-0"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="white"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            ) : null}
          </button>
        </div>
        <div className="text-center">
          <Link
            to={"/forgot-password"}
            className="mt-3 "
            style={{ color: "var(--accent-color)" }}
          >
            {intl.formatMessage({ id: "FORGOT_PASSWORD" })}?
          </Link>
        </div>
        <hr
          className="my-3"
          style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}
        ></hr>
        <div className="text-center">
          <div className="mt-3">
            {intl.formatMessage({ id: "LOGIN_MESSAGE_TWO" })}{" "}
            <Link
              to={
                user_registration === "yes" ? "/register" : "/register/verify"
              }
              state={{ customSignup: true }}
              style={{ color: "blue" }}
            >
              {intl.formatMessage({ id: "SIGN_UP" })}
            </Link>{" "}
            {/* {intl.formatMessage({ id: "HERE" })} */}
          </div>
        </div>
        {formik.status && (
          <div
            className="mb-10 mt-3 alert alert-danger"
            style={{ padding: "10px", borderRadius: "3px" }}
          >
            <div className="alert-text font-weight-bold text-center">
              {formik.status}
            </div>
          </div>
        )}
      </form>
    </>
  );
}

export default Login;
