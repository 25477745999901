import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
// import CloseButton from '../../../sharedComponents/closeButton/CloseButton'
import { Formik } from "formik";
import { RiErrorWarningLine } from "react-icons/ri";
import * as Yup from "yup";

// styles
// import '../TwoFactorAuth.scss'

// images
// import SettingsIcon from '../../../../_metronic/assets/icons/settings.svg'
// import {SVGICON} from '../../../../_metronic/helpers'
// import {useStore} from 'react-redux'
// import InformationText from '../../../sharedComponents/informationText/InformationText'

// import {enableTwoFactorAuthentication, getQRCode, getRecoveryCodes} from '../redux/TwoFactorAuthAPI'
// import {UserType} from '../../userManagement/types/getUsersListResponseType'
// import {confirmPassword} from '../../auth/redux/AuthCRUD'
// import {getUserDetails} from '../../userManagement/redux'
// import {errorAlert} from '../../../sharedComponents/Alert'
import { useIntl } from "react-intl";
// import {info} from 'console'
import { AiFillSetting } from "react-icons/ai";
import {
  confirmPassword,
  enableTwoFactorAuthentication,
  getQRCode,
  getRecoveryCodes,
} from "../../components/auth/Redux/AuthApi";
import InputLabel from "../InputLabel/InputLabel";
import TextInput from "../TextInput/TextInput";
import { errorToast } from "../Toast/Toasts";
import "./style.scss";

const PasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
});

const initialValues = {
  password: "",
};

const ChooseAuthMethodModal = ({
  show,
  closeModal,
  userDetails,
  getCustomerDetailsAPI,
}) => {
  const [step, setStep] = useState(1);
  const [confirmPasswordLoading, setConfirmPasswordLoading] = useState(false);
  const [loading, setLoading] = useState("");
  const [passwordFormError, setPasswordFormError] = useState("");
  const [QRCode, setQRCode] = useState("");
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const intl = useIntl();
  // const getQRCodeAPI = () => {
  //   getQRCode(userDetails.id).then(({data: {svg}}) => {
  //     const formattedSVG = svg.replaceAll('\\', '')

  //     const buff = Buffer.from(formattedSVG)
  //     const base64data = buff.toString('base64')

  //     setQRCode(base64data)
  //   })
  // }
  // const getRecoveryCodesAPI = () => {
  //   return getRecoveryCodes(userDetails.id).then(({data: {'Recovery Codes': recoveryCodes}}) => {
  //     setRecoveryCodes(recoveryCodes)
  //   })
  // }

  const enableTwoFactorAuthenticationAPI = () => {
    //commented
    enableTwoFactorAuthentication(userDetails?.personalInformation?.id)
      .then(async () => {
        setLoading(intl.formatMessage({ id: "USERS_AUTH_LOADING_MESSAGE" }));
        try {
          const response = await Promise.all([
            getQRCode(userDetails?.personalInformation?.id),
            getRecoveryCodes(userDetails?.personalInformation?.id),
          ]);
          const {
            data: { svg },
          } = response[0];
          const {
            data: { recoveryCodes },
          } = response[1];
          const formattedSVG = svg.replaceAll("\\", "");
          const Buffer = require("buffer").Buffer;
          const buff = Buffer.from(formattedSVG);
          const base64data = buff.toString("base64");
          setRecoveryCodes(recoveryCodes);
          setQRCode(base64data || "");
          setStep(3);
          setLoading("");
        } catch (e) {
          closeModal();
          errorToast(
            intl.formatMessage({ id: "USERS_QR_CODE_FAILURE_MESSAGE" })
          );
        }
      })
      .catch(() => {
        setLoading("");
      })
      .finally(() => {
        getCustomerDetailsAPI();
      });
  };

  const onFormSubmit = (values, formikActions) => {
    // setStep(3);
    //commented
    if (values.password) {
      setConfirmPasswordLoading(true);
      confirmPassword(userDetails?.personalInformation?.id, values.password)
        .then(() => {
          setLoading(
            intl.formatMessage({ id: "USERS_ENABLING_VERIFICATION_MESSAGE" })
          );
          enableTwoFactorAuthenticationAPI();
        })
        .catch((e) => {
          setPasswordFormError(
            intl.formatMessage({ id: "USERS_INCORRECT_PASSWORD_MESSAGE" })
          );
        })
        .finally(() => {
          setConfirmPasswordLoading(false);
        });
    }
  };

  const ChooseAuthMethod = () => {
    return (
      <div>
        <p
          className="text-muted mb-4"
          style={{ fontSize: "18px", fontWeight: "500" }}
        >
          {intl.formatMessage({ id: "USERS_LOGIN_INFO" })}
        </p>
        <div>
          <label
            className="btn bg-light-primary rounded double-FA-box p-3 d-flex align-items-center svg-icon svg-icon-3x"
            htmlFor="kt_modal_two_factor_authentication_option_1"
          >
            {/* <SVGICON src={SettingsIcon} className={'svg-icon-4x svg-icon-primary me-4'} /> */}
            <AiFillSetting
              className="shield-icon"
              style={{ marginRight: "20px", top: "0px" }}
            />
            <span className="d-block fw-bold text-start">
              <span
                className="text-dark fw-bolder d-block"
                style={{ fontSize: "22px" }}
              >
                {intl.formatMessage({ id: "USERS_AUTHENTICATOR_APP_TITLE" })}
              </span>
              <span className="text-muted fw-bold" style={{ fontSize: "16px" }}>
                {intl.formatMessage({ id: "USERS_AUTHENTICATOR_APP_INFO" })}
              </span>
            </span>
          </label>
        </div>
      </div>
    );
  };

  let passwordHandleSubmit = () => {};
  const PasswordConfirmation = () => {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={PasswordSchema}
          onSubmit={(values, actions) => {
            setPasswordFormError("");
            // @ts-ignore
            onFormSubmit(values);
          }}
        >
          {({
            errors,
            values,
            touched,
            setFieldValue,
            handleSubmit,
          }) => {
            passwordHandleSubmit = handleSubmit;
            return (
              <>
                {passwordFormError && (
                  <div className="mb-10 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      {passwordFormError}
                    </div>
                  </div>
                )}
                <p
                  className="text-muted fw-bold mb-10"
                  style={{ fontSize: "18px" }}
                >
                  {intl.formatMessage({ id: "USERS_PASSWORD_FORM_ERROR" })}
                </p>
                <InputLabel
                  required
                  text={intl.formatMessage({ id: "USERS_PASSWORD_FIELD" })}
                />
                {/* <input
                  type='password'
                  placeholder={intl.formatMessage({id: 'USERS_PASSWORD_PLACEHOLDER'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  name='password'
                  autoComplete='off'
                  onChange={handleChange('password')}
                  value={values.password}
                /> */}
                <TextInput
                  value={values.password}
                  type="password"
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    setFieldValue("password", value);
                  }}
                  isError={touched.password && errors.password}
                />
                {touched.password && errors.password && (
                  <div className="text-danger">
                    <span role="alert">{errors.password}</span>
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    );
  };

  const DisplayQRCode = () => {
    return (
      <div>
        <h3 className="text-dark fw-bolder mb-4" style={{ fontSize: "20px" }}>
          {intl.formatMessage({ id: "USERS_AUTHENTICATOR_APP_TITLE" })}
        </h3>
        <div
          className="text-gray-500 fw-bold mb-4"
          style={{ fontSize: "15px" }}
        >
          {intl.formatMessage({ id: "USERS_AUTHENTICATOR_QR_CODE_TEXT1" })}
          <a
            href="https://support.google.com/accounts/answer/1066447?hl=en"
            target="_blank"
          >
            {intl.formatMessage({ id: "USERS_AUTHRNTICATOR_APP1" })}
          </a>
          ,
          <a
            href="https://www.microsoft.com/en-us/account/authenticator"
            target="_blank"
          >
            {intl.formatMessage({ id: "USERS_AUTHRNTICATOR_APP2" })}
          </a>
          ,
          <a href="https://authy.com/download/" target="_blank">
            {intl.formatMessage({ id: "USERS_AUTHRNTICATOR_APP3" })}
          </a>
          , or
          <a
            href="https://support.1password.com/one-time-passwords/"
            target="_blank"
          >
            {intl.formatMessage({ id: "USERS_AUTHRNTICATOR_APP4" })}
          </a>
          {intl.formatMessage({ id: "USERS_AUTHENTICATOR_QR_CODE_TEXT2" })}
          <div className="mt-4 text-center">
            <img
              src={`data:image/svg+xml;base64,${QRCode}`}
              alt=""
              className="mw-150px"
            />
          </div>
        </div>
        <div
          className="d-flex flow-row  fw-bolder text-gray-800 mb-10"
          style={{ fontSize: "14px" }}
        >
          {(recoveryCodes || []).join(`, `)}
        </div>
        {/* commented */}
        {/* <InformationText
          textComponent={
            <div className='fs-6 text-gray-700'>
              {intl.formatMessage({id: 'USERS_AUTHENTICATOR_QR_CODE_TEXT3'})}
            </div>
          }
        /> */}
        <div className="notice d-flex bg-light-primary rounded information-text-box p-3 my-3">
          <div className="me-3">
            <RiErrorWarningLine
              className="warning-icon"
              style={{ top: "5px", width: "22px", position: "relative" }}
            />
          </div>
          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
            <div className="mb-3 mb-md-0">
              <div className=" text-gray-600 pe-7" style={{ fontSize: "14px" }}>
                {intl.formatMessage({
                  id: "USERS_AUTHENTICATOR_QR_CODE_TEXT3",
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="cam-modal-container">
      <Modal show={show} centered dialogClassName="medium-size-modal">
        <Modal.Header closeButton onHide={closeModal}>
          <div className="d-flex flex-row flex-fill align-items-center justify-content-between">
            <Modal.Title style={{ fontSize: "20px", fontWeight: "600" }}>
              {intl.formatMessage({ id: "USERS_AUTH_MODAL_TITLE" })}
            </Modal.Title>
            {/* <CloseButton onClose={closeModal} /> */}
          </div>
        </Modal.Header>
        <Modal.Body className="py-4 px-lg-5">
          {loading && (
            <div className="d-flex flex-row justify-content-center">
              <h3 className="indicator-progress" style={{ display: "block" }}>
                {`${loading} `}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </h3>
            </div>
          )}
          {!loading && (
            <>
              {step === 1 ? (
                <ChooseAuthMethod />
              ) : step === 2 ? (
                <PasswordConfirmation />
              ) : (
                <DisplayQRCode />
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center px-lg-5 py-3">
          {step === 1 ? (
            <button
              className="btn btn-custom m-0 w-100"
              data-kt-element="options-select"
              style={{ height: "42px" }}
              onClick={() => {
                setStep(2);
              }}
            >
              {intl.formatMessage({ id: "CONTINUE_BUTTON" })}
            </button>
          ) : step === 2 ? (
            <>
              <Button
                variant="secondary"
                onClick={closeModal}
                style={{ padding: "10px 20px" }}
              >
                {intl.formatMessage({ id: "CLOSE_BUTTON" })}
              </Button>
              <button
                // variant='primary'
                className="btn btn-custom"
                style={{ padding: "10px 20px" }}
                onClick={() => {
                  passwordHandleSubmit();
                }}
              >
                {!confirmPasswordLoading &&
                  intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                {confirmPasswordLoading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-custom w-100"
                style={{ height: "42px" }}
                //   variant='primary w-100'
                onClick={closeModal}
              >
                {intl.formatMessage({ id: "OKAY_BUTTON" })}
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChooseAuthMethodModal;
