import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Loader from "../../../sharedComponents/Loader";
import { TitleHeader } from "../../../sharedComponents/Title";
import { getOffersList } from "../../auth/Redux/AuthApi";
import OffersCard from "./OffersCard";

const OffersList = () => {
  const intl = useIntl();
  // state
  const [offersList, setOffersList] = useState([]);
  const [loading, setLoading] = useState(false);

  const offersListAPI = () => {
    setLoading(true);
    getOffersList()
      .then(({ data }) => {
        setOffersList(data.offers);
        // console.log(data.products);
        // dispatch(actions.setUser(data))
        //   dispatch(actions.setUser(data.personalInformation))
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    offersListAPI();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="card mb-4 mb-xl-10 me-0 card-no-border ms-0">
            <div
              className="card-header border-0 cursor-pointer card-header-custom pt-0 px-0"
              role="button"
              //  data-bs-toggle='collapse'
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
            >
              <TitleHeader
                noMarginTop
                title={intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_OFFERS_TITLE",
                })}
              />
            </div>
            <div className="pb-3">
              {offersList && offersList.length > 0 ? (
                offersList.map((ele) => {
                  return <OffersCard element={ele} />;
                })
              ) : (
                <>
                  <div>
                    {intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PRODUCTS_NO_OFFERS_AVAILABLE",
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OffersList;
