import React from "react";
import { Link, useLocation } from "react-router-dom";
import noImage from "../../assets/noImage.jpg";
import "./style.scss";
const AuthorImage = ({
  photoUrl,
  firstname,
  lastname,
  zIndex,
  size,
  displayname,
  authorId,
  ele,
  name = true,
}) => {
  const fullName = `${firstname ? firstname : ""} ${lastname ? lastname : ""}`;
  const location = useLocation();
  // const { resolution } = useCustomMediaQuery();
  // console.log(location);
  // console.log(location.pathname.startsWith("/community"));

  const setAuthorMediaSource = () => {
    if (
      ele?.profile_photo_resolutions &&
      ele?.profile_photo_resolutions.length > 0
    ) {
      const source = ele?.profile_photo_resolutions.filter(
        (element) => element?.resolution === "256x144"
      );
      return source[0].file_url;
    }
    return ele?.profile_photo_url ? ele?.profile_photo_url : noImage;
  };

  return (
    <>
      {location.pathname.startsWith("/community") ? (
        <div
          className={
            name === true ? "author-tooltip" : "author-tooltip-noimage"
          }
          style={{ position: "relative", zIndex: zIndex }}
        >
          <div
            className="position-absolute bg-light px-3 py-2 fs-8 justify-content-center align-items-center"
            style={{
              display: "none",
              backgroundColor: "white",
              top: "-75px",
              borderRadius: "5px",
              fontSize: "12px",
              minHeight: "60px",
              minWidth: "100px",
              fontWeight: "900",
            }}
          >
            {displayname ? displayname : fullName}
          </div>
          {photoUrl && photoUrl !== null ? (
            <div
              className={`${
                size === "large" ? "author_meta-lg" : "author_meta"
              } d-flex flex-nowrap align-items-center`}
              style={{ zIndex: zIndex }}
            >
              <img
                className="img img-fluid"
                src={setAuthorMediaSource()}
                alt="author"
              />
            </div>
          ) : (
            <div
              className={`${
                size === "large" ? "author_meta-lg" : "author_meta"
              } d-flex justify-content-center align-items-center ${
                size === "large" ? "author_firstname-lg" : "author_firstname"
              }`}
            >
              {firstname
                ? firstname?.charAt(0)?.toUpperCase()
                : lastname?.charAt(0)?.toUpperCase()}
            </div>
          )}
        </div>
      ) : (
        <Link to={`/authors/${authorId}`}>
          <div
            className={
              name === true ? "author-tooltip" : "author-tooltip-noimage"
            }
            style={{ position: "relative", zIndex: zIndex }}
          >
            <div
              className="position-absolute bg-light px-3 py-2 fs-8 justify-content-center align-items-center"
              style={{
                display: "none",
                backgroundColor: "white",
                top: "-75px",
                borderRadius: "5px",
                fontSize: "12px",
                minHeight: "60px",
                minWidth: "100px",
                fontWeight: "900",
              }}
            >
              {displayname ? displayname : fullName}
            </div>
            {photoUrl && photoUrl !== null ? (
              <div
                className={`${
                  size === "large" ? "author_meta-lg" : "author_meta"
                } d-flex flex-nowrap align-items-center`}
                style={{ zIndex: zIndex }}
              >
                <img
                  className="img img-fluid"
                  src={setAuthorMediaSource()}
                  alt="author"
                />
              </div>
            ) : (
              <div
                className={`${
                  size === "large" ? "author_meta-lg" : "author_meta"
                } d-flex justify-content-center align-items-center ${
                  size === "large" ? "author_firstname-lg" : "author_firstname"
                }`}
              >
                {firstname
                  ? firstname?.charAt(0)?.toUpperCase()
                  : lastname?.charAt(0)?.toUpperCase()}
              </div>
            )}
          </div>
        </Link>
      )}
    </>
  );
};

export default AuthorImage;
