import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const successToast = (text) => {
  toast.success(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: false,
    theme: "colored",
  });
};

export const errorToast = (text) => {
  toast.error(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: false,
    theme: "colored",
  });
};

export const SuccessAlert = (text, onSuccess, title) => {
  Swal.fire({
    title: title ? title : "Success",
    text: text,
    icon: "success",
    // @ts-ignore
    buttons: {
      confirm: {
        text: "Okay",
        className: "btn btn-custom",
        closeModal: true,
        value: true,
      },
    },
    closeOnClickOutside: false,
  }).then((e) => {
    if (e) {
      onSuccess?.();
    }
  });
};

export const ErrorAlert = (text) => {
  Swal.fire({
    text: text,
    icon: "error",
    // @ts-ignore
    buttons: {
      confirm: {
        text: "Okay",
        className: "btn btn-danger",
        closeModal: true,
        value: "confirm",
      },
    },
    closeOnClickOutside: false,
  });
};
