export const GET_EVENT_DATA_BY_ID = "GET_EVENT_DATA_BY_ID";
export const GET_EVENT_DATA_BY_ID_SUCCESS = "GET_EVENT_DATA_BY_ID_SUCCESS";
export const GET_EVENT_DATA_BY_ID_FAIL = "GET_EVENT_DATA_BY_ID_FAIL";

const initialState = {
  error: {},
  isLoading: true,
  events: {},
};

export const geteventDataById = (id, userId) => {
  return { type: GET_EVENT_DATA_BY_ID, payload: { id, userId } };
};

export const geteventDataByIdSuccess = (data) => {
  return { type: GET_EVENT_DATA_BY_ID_SUCCESS, payload: data };
};

export const geteventDataByIdFail = (id, error) => {
  return { type: GET_EVENT_DATA_BY_ID_FAIL, payload: { id, error } };
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT_DATA_BY_ID: {
      return { ...state, isLoading: true };
    }
    case GET_EVENT_DATA_BY_ID_SUCCESS: {
      return {
        ...state,
        events: { ...state.events, [action.payload.id]: action.payload },
        isLoading: false,
      };
    }
    case GET_EVENT_DATA_BY_ID_FAIL: {
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.id]: action.payload.error.message,
        },
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default eventsReducer;
