import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import "./CustomAlert.scss";

const CustomAlert = ({ show, closeModal, type, message }) => {
  const intl = useIntl();
  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="sm"
      dialogClassName={"activation-modal"}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="border-0" />
      <Modal.Body className="d-flex flex-column mx-4 justify-content-center">
        <div className="d-flex justify-content-center">
          <Player
            autoplay
            loop={true}
            src={
              type === "success"
                ? "https://assets4.lottiefiles.com/packages/lf20_3wo4gag4.json"
                : "https://assets1.lottiefiles.com/packages/lf20_sii02z44.json"
            }
            style={{ height: "90px", width: "90px" }}
          ></Player>
        </div>
        <div className="justify-content-center d-flex">
          <h4 className="">
            {type === "success"
              ? intl.formatMessage({ id: "ALERT_SUCCESS" })
              : intl.formatMessage({ id: "ALERT_ERROR" })}
          </h4>
        </div>
        <div className=" text-center">{message}</div>
        <div className="d-flex justify-content-center mt-3">
          <button
            type="submit"
            className={`btn btn-custom`}
            style={{ minWidth: "70px", fontSize: "20px" }}
            onClick={() => {
              closeModal();
            }}
          >
            {intl.formatMessage({ id: "ALERT_OK" })}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomAlert;
