import React from "react";
// import { useSelector } from "react-redux/es/hooks/useSelector";
import { Route, Routes } from "react-router";
// import { useLocation } from "react-router-dom";
import CodeVerificationWrapper from "../auth/components/CodeVerificationWrapper";
import "./style.scss";

function Registration() {
  // const user_sign_In = useSelector(
  //   (state) =>
  //     state.generalDetails.generalDetails?.general_settings?.user_sign_In
  // );
  // const location = useLocation();
  // const accessToken = sessionStorage.getItem("accessToken");
  // const customSignup = location.state?.customSignup;

  return (
    <>
      <section className="vh-99">
        <Routes>
          <Route path="/:code" element={<CodeVerificationWrapper />} />
          <Route path="/" element={<CodeVerificationWrapper />} />
          {/* <Route index element={<Navigate to={'/registration/:code'} />} /> */}
          {/* <Route path="" element={<Navigate to={"/"} />} /> */}
        </Routes>
      </section>
    </>
  );
}

export default Registration;
