import moment from "moment";
import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { FaRegCommentAlt } from "react-icons/fa";
import { useIntl } from "react-intl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { useLocal } from "../../../App";
import AuthorImage from "../../../sharedComponents/AuthorImage/AuthorImage";
import {
  ErrorAlert,
  SuccessAlert,
} from "../../../sharedComponents/Toast/Toasts";
import { formatDate } from "../../../utils";
import { deleteCommunityPost } from "../Redux/CommunitiesAPI";
import AddPostModal from "./AddPostModal";
import ShowCommentCard from "./ShowCommentCard";
import ShowCommentModal from "./ShowCommentModal";

export const PostComment = ({ comment, className }) => {
  return (
    <div className={className}>
      <div className="d-flex flex-row">
        <div className="mt-1 align-self-start">
          {comment?.users_profile_photo_url ? (
            <div className="ratingAddProfile">
              <img
                src={comment?.users_profile_photo_url}
                height={50}
                width={50}
                style={{ borderRadius: "50px" }}
                alt="user-profile"
              />
            </div>
          ) : (
            <div className="ratingAddProfile">
              {`${comment?.users_username.toUpperCase()[0]}`}
            </div>
          )}
        </div>
        <div
          className="d-flex flex-column ms-1 p-2"
          style={{ borderRadius: "10%", background: "#f1f1f1" }}
        >
          <div className="fw-bold">{comment?.users_username}</div>
          <div>{comment?.body}</div>
        </div>
      </div>
    </div>
  );
};

const PostCard = ({ post, getPostsAPI, type, getShowModal, setInputType }) => {
  const local = useLocal();
  const intl = useIntl();
  const userInfo = useSelector(
    (state) => state.auth?.user?.personalInformation
  );

  const [openAddModal, setOpenAddModal] = useState(false);
  // const [selectedStar, setSelectedStar] = useState(post?.ratings || 0);
  const [openAddCommentModal, setopenAddCommentModal] = useState(false);
  const [openShowCommentModal, setopenShowCommentModal] = useState(false);

  const [modalProps, setModalProps] = useState({
    type: "",
    input: "",
    replyId: "",
    replyToUser: "",
  });
  const [boolModalProps, setBoolModalProps] = useState(false);

  const deletePost = () => {
    deleteCommunityPost(post?.posts_id)
      .then(() => {
        SuccessAlert("post deleted successfully");
      })
      .catch(() => {
        ErrorAlert("post deletion unsuccessful");
      })
      .finally(() => {
        getPostsAPI();
      });
  };

  // const getTotalCommentsRecursive = (items, lengthObject) => {
  //   items.map((data) => {
  //     lengthObject.length = lengthObject.length + data.replies.length;
  //     if (data.replies.length > 0) {
  //       getTotalCommentsRecursive(data.replies, lengthObject);
  //     }
  //   });
  // };

  // const getTotalComments = (items) => {
  //   let lengthObject = {
  //     length: 0,
  //   };
  //   getTotalCommentsRecursive(items, lengthObject);

  //   return lengthObject.length;
  // };

  // const repliesLength = getTotalComments([post]) || 0;

  return (
    <>
      {openAddModal ? (
        <AddPostModal
          show={openAddModal}
          closeModal={() => {
            setOpenAddModal(false);
            getShowModal(false);
          }}
          id={post?.posts_id}
          type="update"
          getPostsAPI={getPostsAPI}
          bodyParam={post.body}
          communityId={post?.communities_id}
        />
      ) : null}
      {openAddCommentModal ? (
        <AddPostModal
          show={openAddCommentModal}
          closeModal={() => {
            setopenAddCommentModal(false);
          }}
          id={post?.posts_id}
          type="comment"
          getPostsAPI={getPostsAPI}
          bodyParam={post.body}
          communityId={post?.communities_id}
        />
      ) : null}

      {openShowCommentModal ? (
        <ShowCommentModal
          show={openShowCommentModal}
          closeModal={() => {
            if (boolModalProps) {
              setBoolModalProps(false);
            }
            setopenShowCommentModal(false);
          }}
          id={post?.posts_id}
          type="showComment"
          getPostsAPI={getPostsAPI}
          bodyParam={post.body}
          communityId={post?.communities_id}
          post={post}
          only3Comments={boolModalProps ? modalProps : false}
        />
      ) : null}

      <div className="card mx-0 mb-2">
        <div className="card-body">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row ms-3 communityProfile">
              <AuthorImage
                photoUrl={post.users_profile_picture_url}
                firstname={post.users_username}
                size={"large"}
              />
              <div className="d-flex flex-column ms-2">
                <div>{post.users_username}</div>
                <div style={{ fontSize: "12px" }}>
                  {post.created_at
                    ? local && local === "en"
                      ? formatDate(post.created_at)
                      : moment(post.created_at).format("DD.MM.yyyy")
                    : ""}
                </div>
              </div>
            </div>
            {userInfo &&
              (userInfo.id === post.users_id || userInfo.sys_admin === 1) && (
                <div>
                  <div
                    className="commentMenu"
                    id="commentDropDown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BsThreeDots
                      style={{
                        fontSize: "25px",
                        // marginTop: "12px",
                      }}
                      type="button"
                    />
                  </div>
                  <div
                    className="dropdown-menu  menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-2"
                    aria-labelledby="commentDropDown"
                  >
                    <div
                      className="comment-menu-item px-3"
                      onClick={() => {
                        // setUpdateComment(true);
                        if (type === "showComment") {
                          setInputType(post.body);
                        } else {
                          setOpenAddModal(true);
                          getShowModal(true);
                        }
                      }}
                    >
                      <span className="comment-menu-link px-3">
                        {intl.formatMessage({ id: "POST_RATING_UPDATE_TITLE" })}
                      </span>
                    </div>
                    <div
                      className="comment-menu-item px-3"
                      onClick={() => {
                        deletePost();
                        // setReplyId(null);
                        // handleDeleteComment();
                      }}
                    >
                      <span className="comment-menu-link px-3">
                        {intl.formatMessage({ id: "POST_RATING_DELETE_TITLE" })}
                      </span>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className="mt-2">{post.body}</div>
          {post?.posts_images_type?.length > 0 ? (
            <div className="mt-3">
              <LazyLoadImage
                src={post?.posts_images_url}
                effect="blur"
                alt="image"
                wrapperClassName="img img-fluid w-100 image-responsive"
                placeholder={<span className="img-placeholder w-100" />}
              />
            </div>
          ) : null}
          <div className="mt-4">
            <div
              className="d-flex justify-content-end text-underline-hover cursor-pointer"
              onClick={() => {
                if (post?.replies?.length > 0 && type !== "showComment") {
                  setopenShowCommentModal(true);
                }
              }}
            >
              <div>{`${post?.replies?.length || 0} ${intl.formatMessage({
                id: "COMMUNITIES_COMMENTS",
              })}`}</div>
            </div>
            {type === "showComment" ? null : (
              <>
                <hr className="my-2" />
                <div className="row">
                  <div className="col d-flex justify-content-center align-items-center">
                    <div className="d-flex cursor-pointer">
                      <FaRegCommentAlt
                        style={{
                          fontSize: "20px",
                          marginTop: "2px",
                        }}
                      />
                      <div
                        className="ms-2"
                        onClick={() => {
                          setopenShowCommentModal(true);
                        }}
                      >
                        {intl.formatMessage({
                          id: "COMMUNITIES_COMMENT",
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="my-2" />
              </>
            )}
          </div>
          <>
            {type === "showComment" ? null : (
              <>
                {/* {post?.replies?.[0] ? (
                  <PostComment comment={post?.replies?.[0]} className="mt-2" />
                ) : null}
                {post?.replies?.[1] ? (
                  <PostComment comment={post?.replies?.[1]} className="mt-3" />
                ) : null} */}
                {post?.replies?.length > 0 ? (
                  <ShowCommentCard
                    details={post}
                    getPostsAPI={getPostsAPI}
                    getShowModal={(value) => {
                      console.log("getShowModal", value);
                    }}
                    setInputAndType={(type, input, replyId, replyToUser) => {
                      setBoolModalProps(true);
                      setopenShowCommentModal(true);
                      // console.log(
                      //   "type is" + type,
                      //   "input is " + input,
                      //   "replyid is" + replyId,
                      //   "replytouser" + replyToUser
                      // );
                      setModalProps({ type, input, replyId, replyToUser });
                    }}
                    commentRef={""}
                    only3Comments={true}
                    communities={true}
                  />
                ) : null}
                {post?.replies.length > 2 ? (
                  <div
                    onClick={() => {
                      setopenShowCommentModal(true);
                    }}
                    className="mt-2 cursor-pointer text-underline-hover"
                  >
                    {intl.formatMessage({
                      id: "COMMUNITIES_SHOW_MORE",
                    })}
                  </div>
                ) : null}
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default PostCard;
