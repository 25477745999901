import React from "react";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { ReactComponent as MailOne } from "../../assets/mailOne.svg";
import { useCustomMediaQuery } from "../../utils";

const Newsletter = ({ details, pageName, sidebars, isSidebar, type, pageDetails, generalDetails }) => {
  const intl = useIntl();
  const smallScreen = useMediaQuery({ maxWidth: 575 });
  const mediumScreen = useMediaQuery({ minWidth: 576, maxWidth: 767 });
  const { isXsScreen, isSmScreen, isMdScreen } = useCustomMediaQuery();
  const navigate = useNavigate();
  const titleStyle = {
    color: details?.title_text_color,
    backgroundColor: details?.title_layer_color,
    opacity: !isSidebar ? `${details?.title_layer_transparency}%` : 1,
    fontSize: smallScreen
      ? `${details?.title_size_small}px`
      : mediumScreen
      ? `${details?.title_size_medium}px`
      : `${details?.title_size_large}px`,
    alignSelf: "center",
    padding: "10px",
    width: "fit-content",
  };
  const setImageMediaResource = () => {
    if (
      details?.background_image_resolutions &&
      details?.background_image_resolutions.length > 0
    ) {
      const source = details?.background_image_resolutions.filter(
        (element) => element?.resolution === "1024x576"
      );
      return source[0].file_url;
    }
    return details?.background_image_url;
  };
  return (
    <div className={isSidebar ? " container p-0" : " container p-0"}>
      <div
        className={isSidebar ? " mt-0 mb-3" : " mb-4"}
        style={{
          boxShadow:
            "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          padding: "0px",
        }}
      >
        <div
          className="custom-form-wrapper "
          style={{
            backgroundColor: !isSidebar ? details.background_color : "white",
            height: !isSidebar ? "" : "200px",
          }}
        >
          {!isSidebar && details?.background_type === "image" ? (
            <img
              className="d-block w-100 "
              src={setImageMediaResource()}
              style={{ objectFit: "contain" }}
            />
          ) : null}
          <div
            className="position-absolute custom-center d-flex justify-content-center align-items-center row"
            style={{ padding: "20px", width: "100%" }}
          >
            <div
              className={`${
                sidebars
                  ? isSidebar
                    ? "col-12 d-flex justify-content-center"
                    : "col-lg-6"
                  : "col-lg-8"
              } col-12 col-md-12`}
            >
              <h6
                className={`card-title text-start ${
                  sidebars ? " mb-2" : " mb-0"
                } `}
                style={titleStyle}
              >
                {details.title}
              </h6>
            </div>
            <MailOne
              className={`col-12  col-md-12 ${isSidebar ? "" : "col-lg-2"}`}
              style={{
                height:
                  isXsScreen || isSmScreen
                    ? "60px"
                    : isMdScreen || sidebars
                    ? "80px"
                    : sidebars
                    ? "70px"
                    : "80px",
                width:
                  isXsScreen || isSmScreen
                    ? "60px"
                    : isMdScreen || sidebars
                    ? "70px"
                    : sidebars
                    ? "70px"
                    : "80px",
                fill: "var(--accent-color)",
              }}
            />

            <div
              className={`col-12 col-md-12  ${
                isSidebar ? "mb-1 pe-0 " : "col-lg-2 m-0"
              }`}
              style={{
                display: isSidebar ? "table" : "flex",
                width: isSidebar ? "100%" : "auto",
              }}
            >
              <button
                id="button-custom"
                style={{
                  width:
                    !isXsScreen && !isSmScreen && !isMdScreen && !isSidebar
                      ? "auto"
                      : "100%",
                }}
                onClick={() =>
                  navigate("../../newsletter", {
                    state: {
                      pageName: pageName,
                      id: details?.id,
                      sectionId: details?.section_id,
                      isSidebar: !!isSidebar,
                      type,
                      pageDetails,
                      generalDetails
                    },
                  })
                }
              >
                {intl.formatMessage({ id: "SHARED_COMPONENTS_SUBSCRIBE" })}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
