import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SuccessBadge from "../../assets/successBadge.png";
import "./style.scss";

const TitleHeader = ({
  title,
  capacity,
  noMarginTop,
  className,
  header_navigation_id,
  activated,
  ...props
}) => {
  const navigationMenu = useSelector(
    (state) => state.navigationMenu.navigationMenu
  );
  const [navigateTo, setNavigateTo] = useState([]);

  useEffect(() => {
    if (
      navigationMenu.length !== 0 &&
      Object.keys(navigationMenu).length !== 0
    ) {
      const removeNested = (nav) => {
        let children = [];
        return nav
          .map((m) => {
            if (m.children && m.children.length) {
              children = [...children, ...m.children];
            }
            return m;
          })
          .concat(children.length ? removeNested(children) : children);
      };

      const filtered = (removeNested(navigationMenu) || []).filter(
        (item) => item.id === header_navigation_id
      );

      setNavigateTo(filtered);
    }
  }, [header_navigation_id]);

  return (
    <div className={`title-wrapper ${className} ${noMarginTop ? "" : "mt-3"}`}>
      {navigateTo[0]?.seo_permalink !== undefined ? (
        <Link to={`/${navigateTo[0]?.seo_permalink}`}>
          <h4
            className={`border-section ${
              capacity || activated
                ? "d-flex justify-content-between align-items-end"
                : ""
            }`}
          >
            {title}
            {capacity && (
              <div
                className="d-flex flex-column align-items-center p-2"
                style={{
                  backgroundColor: "var(--accent-color)",
                  color: "var(--accent-textcolor)",
                }}
              >
                <div style={{ fontSize: "14px" }}>{capacity}</div>
                <div style={{ fontSize: "10px" }}>Capacity</div>
              </div>
            )}
            {activated && (
              <img
                src={SuccessBadge}
                style={{ height: "24px", width: "24px" }}
                alt='success-badge'
              />
            )}
          </h4>
        </Link>
      ) : (
        <h4
          className={`border-section ${
            capacity || activated
              ? "d-flex justify-content-between align-items-end"
              : ""
          }`}
        >
          {title}
          {capacity && (
            <div
              className="d-flex flex-column align-items-center p-2"
              style={{
                backgroundColor: "var(--accent-color)",
                color: "var(--accent-textcolor)",
              }}
            >
              <div style={{ fontSize: "14px" }}>{capacity}</div>
              <div style={{ fontSize: "10px" }}>Capacity</div>
            </div>
          )}
          {activated && (
            <img src={SuccessBadge} style={{ height: "24px", width: "24px" }} alt='success-badge'/>
          )}
        </h4>
      )}
    </div>
  );
};
export default TitleHeader;
