import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
//import { getLegalTextDetails } from "../../Redux/GeneralDetailsSlice";
import { useSelector } from "react-redux";
import "./style.scss";
function LegalTextModal({ handleClose, show, versionId, index }) {
  const intl = useIntl();

  //const [show, setShow] = useState(false);
  const legalText = useSelector((state) => state.generalDetails.legalText);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose(index)}
        size="xl"
        centered
        backdrop="static"
        keyboard={false}
        fullscreen="md-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>{legalText?.[versionId]?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <div
            dangerouslySetInnerHTML={{
              __html: legalText?.[versionId]?.description,
            }}
          ></div>
          {/* {!!legalText?.[versionId]?.description
            ? legalText?.[versionId]?.description.replace(/(<([^>]+)>)/gi, "")
            : ""} */}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="close" onClick={() => handleClose(index)}>
            {intl.formatMessage({ id: "BUTTON_CLOSE" })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LegalTextModal;
