import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";

import { useEffect, useState } from "react";
import { AiFillCloseCircle, AiFillFolder } from "react-icons/ai";
import {
  ErrorAlert,
  SuccessAlert,
} from "../../../sharedComponents/Toast/Toasts";
import {
  addCommunityPostFile,
  addCommunityPostImage,
  addPostComment,
  addPostCommentReply,
  updateCommunityPost,
} from "../Redux/CommunitiesAPI";

const AddPostModal = ({
  show,
  closeModal,
  id,
  getPostsAPI,
  type,
  bodyParam,
  communityId,
  replyId,
  details,
}) => {
  const intl = useIntl();
  const [body, setBody] = useState(type === "update" ? bodyParam : "");
  const [imageURL, setImageURL] = useState("");
  const [imageType, setImageType] = useState("url");
  const [imageUrlError, setImageUrlError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState();

  useEffect(() => {
    if (
      imageURL === "" ||
      imageURL?.startsWith("https") ||
      imageType === "file"
    ) {
      setImageUrlError(false);
    } else {
      setImageUrlError(true);
    }
  }, [imageURL,imageType]);

  const onFileUploadChange = (file) => {
    // const filesArr = _.clone(uploadedFiles);
    // files &&
    //   Object.keys(files).map((key) => {
    //     // @ts-ignore
    //     filesArr.push(files[key]);
    //   });
    setUploadedImage(file[0]);
    setImageType("file");
    setImageURL(file[0].name);
  };

  const clearInput = () => {
    setImageType("url");
    setUploadedImage(null);
    setImageURL("");
  };

  const handleSubmit = () => {
    setLoading(true);
    if (type === "update") {
      if (replyId) {
        addPostCommentReply(
          details.communities_id,
          details.posts_id,
          body,
          replyId.id
        )
          .then(() => {
            //   successToast("post updated successfully");
          })
          .catch(() => {
            //   errorToast("post updation unsuccessful");
          })
          .finally(() => {
            closeModal();
            getPostsAPI();
            setLoading(false);
          });
      }
      updateCommunityPost(id, body)
        .then(() => {
          //   successToast("post updated successfully");
        })
        .catch(() => {
          //   errorToast("post updation unsuccessful");
        })
        .finally(() => {
          getPostsAPI();
          setLoading(false);
          closeModal();
        });
    } else if (type === "comment") {
      addPostComment(communityId, id, body)
        .then(() => {
          //   successToast("post updated successfully");
        })
        .catch(() => {
          //   errorToast("post updation unsuccessful");
        })
        .finally(() => {
          closeModal();
          getPostsAPI();
          setLoading(false);
        });
    } else {
      if (!imageUrlError && imageType === "url") {
        addCommunityPostImage(id, body, imageURL, imageType)
          .then(() => {
            SuccessAlert(
              intl.formatMessage({
                id: "COMMUNITIES_ADD_POST_SUCCESS",
              })
            );
          })
          .catch((res) => {
            if (res.response.data.status_code === "099") {
              ErrorAlert(
                intl.formatMessage({
                  id: "COMMUNITIES_ADD_POST_FAILURE_NOT_JOINED",
                })
              );
            } else {
              ErrorAlert(
                intl.formatMessage({
                  id: "COMMUNITIES_ADD_POST_FAILURE",
                })
              );
            }
          })
          .finally(() => {
            closeModal();
            getPostsAPI();
            setLoading(false);
          });
      } else if (!imageUrlError && imageType === "file") {
        const formData = new FormData();
        formData.append("body", body);
        formData.append("image_type", imageType);
        // console.log("file submit", uploadedImage);
        formData.append("image_url", uploadedImage);
        // console.log("test", formData);
        addCommunityPostFile(id, formData)
          .then(() => {
            SuccessAlert(
              intl.formatMessage({
                id: "COMMUNITIES_ADD_POST_SUCCESS",
              })
            );
          })
          .catch((res) => {
            if (res.response.data.status_code === "099") {
              ErrorAlert(
                intl.formatMessage({
                  id: "COMMUNITIES_ADD_POST_FAILURE_NOT_JOINED",
                })
              );
            } else {
              ErrorAlert(
                intl.formatMessage({
                  id: "COMMUNITIES_ADD_POST_FAILURE",
                })
              );
            }
          })
          .finally(() => {
            closeModal();
            getPostsAPI();
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={closeModal}
        size={"lg"}
        dialogClassName={"buynow-modal"}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {type === "update" && !replyId
              ? intl.formatMessage({
                  id: "COMMUNITIES_UPDATE_POST",
                })
              : type === "comment"
              ? "Add Comment"
              : type === "update" && replyId
              ? "Reply Comment"
              : intl.formatMessage({
                  id: "COMMUNITIES_NEW_POST",
                })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {type !== "update" ? (
              <div className="mt-2 mb-3">
                {/* <InputLabel
                text={
                  // JSON.stringify(locale).includes("de") ? "Email" : "Email"
                  intl.formatMessage({ id: "LABEL_IMAGE_URL" })
                }
              /> */}
                <div
                  id="post-image-url"
                  className="d-flex flex-row justify-content-between"
                  style={{
                    width: "100%",
                    // minHeight: "200px",
                    // border: "none",
                    // padding: "20px",
                    // borderRadius: "20px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      background: "#f0f8ff",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    className="position-relative"
                  >
                    {imageURL !== "" && imageType === "file" && (
                      <AiFillCloseCircle
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "10px",
                          background: "#f0f8ff",
                          top: "15px",
                          right: "15px",
                          color: "#a1a5b7",
                        }}
                        onClick={() => {
                          clearInput();
                        }}
                        className="position-absolute cursor-pointer"
                      />
                    )}
                    <input
                      id="post-image-url-input"
                      className=" border-0 align-self-stretch"
                      value={imageURL || ""}
                      placeholder={intl.formatMessage({
                        id: "LABEL_IMAGE_URL",
                      })}
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "#f0f8ff",
                      }}
                      disabled={imageType === "file"}
                      autoFocus
                      onChange={(e) => {
                        setImageURL(e.target.value);
                      }}
                    />
                  </div>
                  <input
                    type="file"
                    id="actual-btn"
                    hidden
                    // multiple
                    onChange={(e) => {
                      onFileUploadChange(e.target.files);
                    }}
                    accept="image/jpeg, image/jpg, image/png"
                  />
                  <label
                    htmlFor="actual-btn"
                    className="d-flex align-self-end ms-2"
                  >
                    <AiFillFolder
                      style={{
                        padding: "10px",
                        width: "50px",
                        height: "50px",
                        borderRadius: "10px",
                        background: "#f0f8ff",
                        color: "var(--accent-color)",
                      }}
                      className=""
                    />
                  </label>
                </div>
                {imageUrlError && imageType === "url" ? (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    className="ms-3"
                  >
                    <span role="alert">
                      {intl.formatMessage({
                        id: "IMAGE_URL_ERROR",
                      })}
                    </span>
                  </div>
                ) : null}
              </div>
            ) : null}
            <textarea
              id="addTextarea"
              style={{
                width: "100%",
                minHeight: "200px",
                border: type !== "update" ? "" : "none",
                padding: "20px",
                borderRadius: "20px",
              }}
              value={body || ""}
              placeholder={intl.formatMessage({
                id: "POST_RATINGS_ADD_MODAL_TEXTAREA_PLACEHOLDER",
              })}
              autoFocus
              onChange={(e) => {
                setBody(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center w-100">
            <button
              id="button-custom-two"
              onClick={() => {
                handleSubmit();
              }}
              disabled={body && body.length > 0 ? false : true}
              type="button"
            >
              {!loading &&
                intl.formatMessage({
                  id: "POST_RATINGS_ADD_MODAL_POST_BUTTON",
                })}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "POST_RATINGS_ADD_MODAL_POST_BUTTON_LOADING",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPostModal;
