import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router";
import { getNewsletterDeactivationStatus } from "../../API/Api";
import error from "../../assets/error.png";
import newsletter from "../../assets/newsletter.svg";
import success from "../../assets/success-icon.png";

export const NewsLetterDeactivation = () => {
  const intl = useIntl();
  const [response, setResponse] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigateHome = () => {
    navigate("/");
  };

  useEffect(() => {
    if (id) {
      const paramsObject = {
        email: id,
      };
      setLoading(true);
      getNewsletterDeactivationStatus(paramsObject)
        .then((response) => {
          setTimeout(() => {
            setResponse(response);
            setLoading(false);
            // if (response.status === "Success") {
            //   setTimeout(() => navigate("/"), 3000);
            // }
          }, 2000);
        })
        .catch((err) => {
          setErr(true);
          setLoading(false);
        });
    }
  }, []);
  return (
    <div className="container .newsletter-container d-flex justify-content-center align-items-center gap-2 p-4">
      <img
        className="newsletterSvg"
        src={newsletter}
        style={{ width: "30px", flex: "1" }}
      />
      <div
        className="d-flex justify-content-center align-items-center fw-bold flex-column"
        style={{ flex: "1" }}
      >
        {response && Object.entries(response).length > 0 && (
          <div>
            {response?.status === "Success" && (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={success} style={{ width: "40px" }} />
                <p className="newsletterText">
                  {intl.formatMessage({ id: "NEWSLETTER_CONFIRMATION" })}
                </p>
              </div>
            )}
          </div>
        )}
        {loading && (
          <div className="d-flex flex-column align-items-center">
            <p className="newsletterText">
              {intl.formatMessage({ id: "NEWSLETTER_DEACTIVATION_INPROGRESS" })}
            </p>
          </div>
        )}
        {err && (
          <div className="d-flex flex-column align-items-center">
            <img src={error} style={{ width: "40px" }} />
            <p className="newsletterText">
              {intl.formatMessage({ id: "NEWSLETTER_DEACTIVATION_FAILED" })}
            </p>
            <button onClick={navigateHome} id="button-custom">
              Go Home
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
