import axios from "axios";
export async function fetchPageDetails(_id) {
  const response = await axios.get(`/websiteApp/pageSettings/${_id}`);
  return response.data;
}

export async function fetchPostDetails(paramsObject, type, pageId) {
  const response = await axios({
    method: "post",
    url: `/websiteApp/${type.startsWith("post") ? "posts" : "events"}`,
    data: paramsObject,
    params: { page: pageId },
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}

export async function fetchDetailedPostData(id) {
  const response = await axios.get(`/posts/${id}?channel=web`);
  return response.data;
}

export async function fetchDetailedEventData(id, userId) {
  if (userId) {
    const response = await axios.get(
      `/events/${id}?contacts=${userId}&channel=web`
    );
    return response.data;
  } else {
    const response = await axios.get(`/events/${id}?channel=web`);
    return response.data;
  }
}

export async function fetchMasterData(listType) {
  const response = await axios({
    method: "post",
    url: "/websiteApp/masterData",
    data: { type: listType },
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}

export async function fetchMasterDataDetails(masterDataType, id) {
  const response = await axios({
    method: "post",
    url: "/websiteApp/masterData/details",
    data: { master_data: masterDataType, master_data_id: id },
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}

export async function fetchTopTrendingPosts(id) {
  const response = await axios.get(
    `/websiteApp/posts/trending?condition=${id}`
  );
  return response.data;
}

export async function fetchLatandLong(address) {
  const response = await axios.get(
    `https://nominatim.openstreetmap.org/search?q=${address}&format=json`
  );
  return response.data[0];
}

export async function fetchCheckoutUrl(paramsObject, productId) {
  try {
    const response = await axios({
      method: "post",
      url: `/stripes/checkout/sessions/${productId}`,
      data: paramsObject,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function fetchPaymentStatus(paramsObject) {
  const response = await axios({
    method: "post",
    url: `/stripes/payments/status`,
    data: paramsObject,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}

export async function getCountriesList() {
  const response = await axios.get(`/client/countries`);
  return response;
}

// get all conatcttypes list
export function getContactTypesList() {
  return axios.get(`/contactDetails`);
}

// update customer contact
export function updateCustomerContact(id, contact_id, contact_description) {
  return axios.post(`/contact/contactDetails/update/${id}`, {
    id: contact_id,
    description: contact_description,
  });
}

// add customer contact
export function addCustomerContact(id, contact_id, contact_description) {
  return axios.post(`/contact/contactDetails/create/${id}`, {
    id: contact_id,
    description: contact_description,
  });
}

// delete customer contact
export function deleteCustomerContact(id, contact_id) {
  return axios.post(`/contact/contactDetails/delete/${id}/${contact_id}`);
}

export function updateCustomerPersonalAddress(
  id,
  address_1,
  address_2,
  zip_code,
  city,
  country_id,
  state_id
) {
  return axios.post(`/contact/personalAddress/create/${id}`, {
    address_1,
    address_2,
    zip_code,
    city,
    country_id,
    state_id,
  });
}

export function subscribeToNewsletter(
  id,
  email,
  salutations_id,
  firstname,
  lastname
) {
  return axios.post(`/newsletters/subscribe/${id}`, {
    salutations_id,
    firstname,
    lastname,
    email,
  });
}

// update user email
export function updateCustomerEmail(id, email, confirm_password) {
  return axios.post(`/contact/updateEmail/${id}`, {
    email,
    confirm_password,
  });
}

// update user password
export function updateCustomerPassword(
  id,
  current_password,
  password,
  password_confirmation
) {
  return axios.post(`/contact/updatePassword/${id}`, {
    current_password,
    password,
    password_confirmation,
  });
}

export function updateCustomerPersonalDetails(
  id,
  profile_photo_id,
  salutation_id,
  title_id,
  first_name,
  last_name,
  username
) {
  return axios.post(`/contact/personal/${id}`, {
    profile_photo_id: profile_photo_id,
    salutation_id: salutation_id,
    title_id: title_id,
    first_name: first_name,
    last_name: last_name,
    username: username,
  });
}

export async function fetchMicroPageEvent(eventUid) {
  const response = await axios({
    method: "get",
    url: `/events/micropages/${eventUid}`,
    // data:paramsObject,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function checkUserEventRegister(eventId, userId) {
  const response = await axios({
    method: "get",
    url: `/events/${eventId}?contacts=${userId}&channel=web`,
    // data:paramsObject,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function eventRegister(paramsObject, eventId) {
  try {
    const response = await axios({
      method: "post",
      url: `/events/register/${eventId}`,
      data: paramsObject,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function eventJoin(eventId) {
  try {
    const response = await axios({
      method: "post",
      url: `/events/register/join/${eventId}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function eventOfferRegister(paramsObject, eventId) {
  try {
    const response = await axios({
      method: "post",
      url: `/posts/checkout/offers/${eventId}`,
      data: paramsObject,
      // headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getStripeOffers(code) {
  try {
    const response = await axios({
      method: "get",
      url: `/stripes/offers/${code}`,
      // headers: { "Content-Type": "application/json" },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getStripeOffersCheckOutUrl(id, paramsObject) {
  try {
    const response = await axios({
      method: "post",
      url: `/stripes/offers/status/${id}`,
      data: paramsObject,
      // headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
export async function fetchStripeOffersPaymentStatus(paramsObject) {
  try {
    const response = await axios({
      method: "post",
      url: `stripes/payments/status`,
      data: paramsObject,
      // headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateUserRead(id, contactId, val) {
  try {
    const response = await axios({
      method: "post",
      url: `websiteApp/posts/contacts/reads/${id}/${contactId}`,
      data: { read: val },
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getSubscriptioncheckoutUrl(id, paramsObject) {
  try {
    const response = await axios({
      method: "post",
      url: `/subscriptions/checkout/sessions/${id}`,
      data: paramsObject,
      // headers: { "Content-Type": "application/json" },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getNewsletterActivationStatus(paramsObject) {
  const response = await axios({
    method: "post",
    url: "/newsletters/activation",
    data: paramsObject,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}
export async function getNewsletterDeactivationStatus(paramsObject) {
  const response = await axios({
    method: "post",
    url: "/newsletters/deactivation",
    data: paramsObject,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}

export async function getAuthorDetails(id) {
  try {
    const response = await axios({
      method: "get",
      url: `/authors/${id}`,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// activate account
export function activateAccount(id) {
  return axios.post(`/contact/doubleOptIn/email/${id}`);
}
