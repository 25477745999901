import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Carousel from "../../sharedComponents/Carousel";
import Title, { TitleHeader } from "../../sharedComponents/Title";
import "./style.scss";
const CarouselListSection = ({
  type,
  details,
  pageName,
  title,
  uniqueId,
  sidebars,
  lastSection,
  ...props
}) => {
  //const [postsData, setpostsData] = useState({});
  const intl = useIntl();
  // const dispatch = useDispatch();
  const posts = useSelector((state) => state.postsDetails);
  if (!posts?.[pageName]?.[uniqueId]) {
    // const paramsObject = {
    //   offset: details?.offset,
    //   option_for_featured: details?.option_for_featured,
    //   pagination: details?.pagination,
    //   items_per_page: details?.items_per_page,
    //   groups: details?.groups,
    //   groups_condition: details?.groups_condition,
    //   categories: details?.categories,
    //   categories_condition: details?.categories_condition,
    //   tags: details?.tags,
    //   tags_condition: details?.tags_condition,
    //   sorting: details?.sorting,
    //   rule_type: details?.rule_type,
    //   type: details?.type,
    // };

    // dispatch(
    //   getPostsDetails({
    //     paramsObject,
    //     pageName,
    //     id: details?.id,
    //     type: details?.type,
    //     defaultView: details?.default_view,
    //     title: details.title,
    //     sectionId: details?.section_id,
    //     isLast: details?.id === lastSection,
    //   })
    // );
  }
  // useEffect(() => {
  //   setpostsData(posts?.[pageName]?.[uniqueId]?.posts?.[1]);
  // }, [posts?.[pageName]?.[uniqueId]?.posts, pageName, uniqueId]);
  return (
    <div {...props} className="mb-4">
      {/* {sidebars && Object.entries(sidebars).length ? (
        postsData && Object.entries(postsData).length> 0 ?(
         <Carousel
         title={title}
         data={postsData}
         maxItems={details?.maximum_items_per_carousel}
         uniqueId={uniqueId}
         type={type}
         displayHeader={!!details?.display_header}
         sidebars={sidebars}
       />) :(
        <>
        <TitleHeader title={title}/>
        <p className="text-center pb-4">{intl.formatMessage({id:"No Data"})('No Data')}</p>
        </>
       )
      ) : (
        postsData && Object.entries(postsData).length> 0 ? (<div className="container">

          {!!details?.display_header && <Title title={title} />}
          <PostCarouselOne
            maxItems={details?.maximum_items_per_carousel}
            pageName={pageName}
            postsData={postsData}
            type={type}
            sidebars={sidebars}
          />
        </div> ):null
      )} */}

      {posts?.[pageName]?.[uniqueId]?.posts?.[1] &&
      Object.entries(posts?.[pageName]?.[uniqueId]?.posts?.[1]).length > 0 ? (
        <Carousel
          title={title}
          data={posts?.[pageName]?.[uniqueId]?.posts?.[1]}
          maxItems={details?.maximum_items_per_carousel}
          uniqueId={uniqueId}
          type={type}
          displayHeader={!!details?.display_header}
          sidebars={sidebars}
          pageName={pageName}
          details={details}
        />
      ) : // <>
      // <TitleHeader title={title}/>
      // <p className="text-center pb-4">{intl.formatMessage({id:"No Data"})('No Data')}</p>
      // </>

      sidebars && Object.entries(sidebars).length > 0 ? (
        <>
          {!!details?.display_header ? <TitleHeader title={title}  header_navigation_id={details?.header_navigation_id} noMarginTop /> : null}

          <p className="text-center py-4 ">
            {intl.formatMessage({ id: "NO_DATA" })}
          </p>
        </>
      ) : (
        <>
          {!!details?.display_header ? <Title title={title} header_navigation_id={details?.header_navigation_id} /> : null}
          <p className="text-center py-4">
            {intl.formatMessage({ id: "NO_DATA" })}
          </p>
        </>
      )}
    </div>
  );
};

export default CarouselListSection;
