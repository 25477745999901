import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CarouselArticle from "../Article/CarouselArticle";
import Title from "../Title";
import "./style.scss";

const Carousel = ({
  data,
  displayHeader,
  maxItems,
  title,
  type,
  uniqueId,
  sidebars,
  pageName,
  details,
}) => {
  const intl = useIntl();
  const [count, setCount] = useState(1);
  const [navigateTo, setNavigateTo] = useState([]);

  const navigationMenu = useSelector(
    (state) => state.navigationMenu.navigationMenu
  );

  useEffect(() => {
    if (
      navigationMenu.length !== 0 &&
      Object.keys(navigationMenu).length !== 0
    ) {
      const removeNested = (nav) => {
        let children = [];
        return nav
          .map((m) => {
            if (m.children && m.children.length) {
              children = [...children, ...m.children];
            }
            return m;
          })
          .concat(children.length ? removeNested(children) : children);
      };

      const filtered = (removeNested(navigationMenu) || []).filter(
        (item) => item.id === details?.header_navigation_id
      );

      setNavigateTo(filtered);
    }
  }, [details?.header_navigation_id]);

  const navLeft =
    count === 1
      ? `<span class="arrow arrow-left arrow-disable"></span>`
      : `<span class="arrow arrow-left arrow-enable"></span>`;
  const navRight =
    count === 1
      ? `<span class="arrow arrow-right arrow-disable"></span>`
      : `<span class="arrow arrow-right arrow-enable"></span>`;
  // const [slidesToShow, setslidesToShow] = useState(1);
  // useEffect(() => {
  //  if( data && Object.entries(data).length > 1){
  //   setslidesToShow(parseInt(maxItems));
  //  }
  //     //? setslidesToShow(1)
  //     //: setslidesToShow(parseInt(maxItems));
  // }, [data, maxItems, uniqueId,pageName]);
  const responsive = {
    0: {
      items: 1,
    },
    768: {
      items: data && Object.entries(data).length === 1 ? 1 : parseInt(maxItems),
    },
    991: {
      items: data && Object.entries(data).length === 1 ? 1 : parseInt(maxItems),
    },
  };

  const handleInitialize = (event) => {
    setCount(event.item.count);
  };
  return (
    <div
      className={`mt-0 ${
        sidebars && Object.entries(sidebars).length === 0 ? "container" : ""
      }`}
    >
      {}
      {displayHeader ? (
        sidebars && Object.entries(sidebars).length === 0 ? (
          <Title
            title={title}
            header_navigation_id={details?.header_navigation_id}
          />
        ) : navigateTo[0]?.seo_permalink !== undefined ? (
          <div className="d-flex title-wrapper">
            <div
              className="flex-grow-1  d-flex border-section"
              // style={{
              //   paddingBottom: count === 1 ? "20px" : "3px",
              // }}
            >
              <Link
                to={`/${navigateTo[0]?.seo_permalink}`}
                className="flex-grow-1 d-inline align-self-end"
              >
                <h4
                  style={{
                    marginBottom:
                      (parseInt(maxItems) === 1 &&
                        data &&
                        Object.entries(data).length > 1) ||
                      (parseInt(maxItems) === 2 &&
                        data &&
                        Object.entries(data).length > 2)
                        ? "10px"
                        : "0px",
                  }}
                >
                  {title}
                </h4>
              </Link>
              <div
                className={`custom-nav owl-nav mx-auto d-inline ${uniqueId.replace(
                  / +/,
                  "_"
                )}-nav`}
              ></div>
            </div>
          </div>
        ) : (
          <div className="d-flex title-wrapper">
            <div
              className="flex-grow-1  d-flex border-section"
              // style={{
              //   paddingBottom: count === 1 ? "20px" : "3px",
              // }}
            >
              <h4
                className="flex-grow-1 d-inline align-self-end"
                style={{
                  marginBottom:
                    (parseInt(maxItems) === 1 &&
                      data &&
                      Object.entries(data).length > 1) ||
                    (parseInt(maxItems) === 2 &&
                      data &&
                      Object.entries(data).length > 2)
                      ? "10px"
                      : "0px",
                }}
              >
                {title}
              </h4>
              <div
                className={`custom-nav owl-nav mx-auto d-inline ${uniqueId.replace(
                  / +/,
                  "_"
                )}-nav`}
              ></div>
            </div>
          </div>
        )
      ) : null}

      <div className="d-flex justify-content-center">
        <OwlCarousel
          className="owl-theme"
          loop={data && Object.entries(data).length > 2 ? true : false}
          dots={
            sidebars && Object.entries(sidebars).length === 0
              ? true
              : displayHeader
              ? false
              : true
          }
          margin={16}
          nav={true}
          lazyLoad={true}
          navText={[
            (parseInt(maxItems) === 1 &&
              data &&
              Object.entries(data).length > 1) ||
            (parseInt(maxItems) === 2 &&
              data &&
              Object.entries(data).length > 2)
              ? navLeft
              : "",
            (parseInt(maxItems) === 1 &&
              data &&
              Object.entries(data).length > 1) ||
            (parseInt(maxItems) === 2 &&
              data &&
              Object.entries(data).length > 2)
              ? navRight
              : "",
          ]}
          responsive={responsive}
          navContainer={`.d-flex .owl-nav.${uniqueId.replace(/ +/, "_")}-nav`}
          onInitialized={handleInitialize}
        >
          {data && Object.entries(data).length > 0 ? (
            Object.values(data).map((element, index) => {
              return type === "post-carousel" ? (
                index < 10 ? (
                  <CarouselArticle
                    details={element}
                    key={index}
                    slidesToShow={parseInt(maxItems)}
                    sidebars={sidebars}
                  />
                ) : null
              ) : element.is_visible ? (
                // <CardArticle details={element} key={index} />
                index < 10 ? (
                  <CarouselArticle
                    details={element}
                    key={index}
                    slidesToShow={parseInt(maxItems)}
                    sidebars={sidebars}
                    type="event-carousel"
                  />
                ) : null
              ) : null;
            })
          ) : (
            <p className="text-center pb-4 mx-auto">
              {intl.formatMessage({ id: "NO_DATA" })}
            </p>
          )}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Carousel;
