import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useParams } from "react-router";
import { getPostDataById } from "../../Redux/PostsReducer";
import AddRatingModal from "./AddRatingModal";
import Comment from "./Comment";
import "./PostRatingDetails.scss";

const PostRatingDetails = () => {
  const intl = useIntl();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const userInfo = useSelector(
    (state) => state.auth?.user?.personalInformation
  );
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const contactId = useSelector(
    (state) => state?.auth?.user?.personalInformation?.id
  );
  const posts_rating = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.posts_general_settings
        ?.posts_rating
  );

  const display_posts_rating_in_web_and_app = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.posts_general_settings
        ?.display_posts_rating_in_web_and_app
  );

  const [isEnabledAdd, setIsEnabledAdd] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const posts = useSelector((state) => state.posts.posts);
  const postId = location.state?.postId || id;

  const details = posts?.[postId];

  useEffect(() => {
    if (details && details.ratings?.length > 0) {
      const result = details.ratings.filter(
        (data) => data.users_id === userInfo.id
      );
      if (result && result.length > 0) {
        setIsEnabledAdd(false);
      } else {
        setIsEnabledAdd(true);
      }
    } else {
      setIsEnabledAdd(true);
    }
  }, [details]);

  const updateRatingCommentsAll = () => {
    dispatch(
      getPostDataById({
        id: details.id,
        isAuthorized: isAuthorized,
        contactId: contactId,
        hideLoader: true,
      })
    );
  };

  return (
    <>
      {openAddModal ? (
        <AddRatingModal
          show={openAddModal}
          closeModal={() => {
            setOpenAddModal(false);
          }}
          details={details}
          type="new"
          updateRatingCommentsAll={updateRatingCommentsAll}
        />
      ) : null}
      <div className="mb-3 mt-4">
        {/* header of the secttion*/}
        {(posts_rating === "yes" ||
          display_posts_rating_in_web_and_app === "yes") && (
          <div className="d-flex title-wrapper">
            <div className="flex-grow-1  d-flex border-section">
              <h4 className="mb-0 flex-grow-1 d-inline align-self-end">
                {intl.formatMessage({ id: "POST_RATINGS_TITLE" })}
              </h4>
            </div>
          </div>
        )}
        {/* Add button section */}

        {posts_rating === "yes" && (
          <div className="d-flex ratingAddContainer">
            <div className="ratingAddSubContainer justify-content-between align-items-center  d-flex w-100">
              <div className="d-flex">
                {userInfo?.firstname ? (
                  <div className="ratingAddProfile d-flex justify-content-center align-items-center">
                    {`${userInfo?.firstname.toUpperCase()[0]}${
                      userInfo?.lastname.toUpperCase()[0]
                    }`}
                  </div>
                ) : null}
              </div>
              <div
                className="ratingAddInput d-flex justify-content-start align-items-center"
                onClick={() => {
                  if (posts_rating === "yes") {
                    setOpenAddModal(true);
                  }
                }}
              >
                <div className="ratingAddInputText ">
                  {userInfo?.firstname
                    ? `${intl.formatMessage({ id: "POST_RATINGS_QUESTION" })} ${
                        userInfo.firstname
                      } ?`
                    : null}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* // ratings already existing */}
        {/* {details.ratings.length > 0 ? (
          <div className="mt-5">
            {details.ratings.map((rating) => {
              return ( */}
        <div style={{ marginTop: "30px" }}>
          <Comment
            details={details}
            updateRatingCommentsAll={updateRatingCommentsAll}
          />
        </div>
        {/* );
            })}
          </div>
        ) : null} */}
      </div>
    </>
  );
};

export default PostRatingDetails;
