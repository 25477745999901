import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
// import { useLocal } from "../../../App";
import noImage from "../../../assets/noImage.jpg";
import Author from "../../../sharedComponents/AuthorImage";
import Loader from "../../../sharedComponents/Loader";
// import { ComponentLoader } from "../../../sharedComponents/Loader/Loader";
import { useCustomMediaQuery } from "../../../utils";
import { getCommunitiesList, getCommunityPosts } from "../Redux/CommunitiesAPI";
import { actions } from "../Redux/CommunitiesRedux";
import AboutCard from "./AboutCard";
import AddPostModal from "./AddPostModal";
import "./DetailsPage.scss";
import PostCard from "./PostCard";

const DetailsPageWrapper = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const communities = useSelector((state) => state.communities.communities);
  const userInfo = useSelector(
    (state) => state.auth?.user?.personalInformation
  );
  const intl = useIntl();
  // const local = useLocal();
  const [community, setCommunity] = useState({});
  const [communityPosts, setCommunityPosts] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);

  useEffect(() => {
    if (id) {
      // console.log(id);
      setCommunity(
        Array.isArray(communities)
          ? communities?.filter((ele) => {
              return ele.id.toString() === id.toString();
            })[0]
          : {}
      );
      // console.log(community);
    }
    // eslint-disable-next-line
  }, [communities]);

  const [loading, setLoading] = useState(false);
  // const [postLoading, setPostLoading] = useState(false);

  const getCommunitiesAPI = () => {
    setLoading(true);
    getCommunitiesList()
      .then(({ data: { communitiesDetails } }) => {
        dispatch(actions.setCommunities(communitiesDetails));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPostsAPI = () => {
    // setPostLoading(true);
    getCommunityPosts(id)
      .then(({ data: { communityPosts } }) => {
        setCommunityPosts(communityPosts);
      })
      .finally(() => {
        // setPostLoading(false);
      });
  };

  useEffect(() => {
    if (!Object.keys(communities).includes(id)) {
      getCommunitiesAPI();
    }
    getPostsAPI();
    // eslint-disable-next-line
  }, []);

  const { resolution } = useCustomMediaQuery();

  const setPostMediaSource = () => {
    if (
      community?.cover_picture_resolutions &&
      community?.cover_picture_resolutions.length > 0
    ) {
      const source = community?.cover_picture_resolutions?.filter((element) => {
        return element?.resolution === resolution;
      });
      return source[0].file_url;
    }
    return community?.cover_picture_url
      ? community?.cover_picture_url
      : noImage;
  };
  return (
    <>
      {openAddModal ? (
        <AddPostModal
          show={openAddModal}
          closeModal={() => {
            setOpenAddModal(false);
          }}
          id={id}
          type="new"
          getPostsAPI={getPostsAPI}
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container d-flex justify-content-center align-items-center mb-4 banner-two">
            {community?.cover_picture_url !== null ? (
              <img
                className="d-block w-100"
                style={{ objectFit: "fill" }}
                src={setPostMediaSource()}
                alt=''
              />
            ) : null}
          </div>
          <div className="container">
            <h4 className="p-2 fw-bold">{`${community?.name}`}</h4>
            <div className="ms-4 mb-2 communityProfile">
              {community?.contacts?.length > 10 ? (
                <Author
                  authors={community?.contacts?.slice(0, 10)}
                  size={"large"}
                  noNavigation={false}
                />
              ) : (
                <Author
                  authors={community?.contacts}
                  size={"large"}
                  noNavigation={false}
                />
              )}
            </div>
          </div>
          <div className="" style={{ backgroundColor: "#f0f2f5" }}>
            <div className=" container">
              <div className="row">
                <div
                  className="col-12 col-md-8 my-3"
                  // style={postLoading === true ? { minHeight: "100px" } : {}}
                >
                  <div
                    className="d-flex ratingAddContainer mb-3"
                    style={{ background: "#ffffff" }}
                  >
                    <div className="ratingAddSubContainer justify-content-between align-items-center  d-flex w-100">
                      <div className="d-flex">
                        {userInfo?.firstname ? (
                          <div className="ratingAddProfile d-flex justify-content-center align-items-center">
                            {`${userInfo?.firstname.toUpperCase()[0]}${
                              userInfo?.lastname.toUpperCase()[0]
                            }`}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="ratingAddInput d-flex justify-content-start align-items-center"
                        onClick={() => {
                          setOpenAddModal(true);
                        }}
                      >
                        <div className="ratingAddInputText ">
                          {userInfo?.firstname
                            ? `${intl.formatMessage({
                                id: "POST_RATINGS_QUESTION",
                              })} ${userInfo.firstname} ?`
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>

                      {communityPosts.length > 0
                        ? communityPosts.map((ele) => {
                            return (
                              <PostCard
                                post={ele}
                                getPostsAPI={getPostsAPI}
                                getShowModal={() => {}}
                              />
                            );
                          })
                        : null}

                </div>
                <div className="d-none d-md-block col-md-4 my-3">
                  <AboutCard community={community} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DetailsPageWrapper;
