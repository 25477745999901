import React, { useEffect, useRef, useState } from "react";
import { FiPause, FiPlay, FiVolume2, FiVolumeX } from "react-icons/fi";
import { useSelector } from "react-redux";
import noImage from "../../assets/noImage.jpg";
import { convertTime, useCustomMediaQuery } from "../../utils";
import "./style.scss";
const AudioPlayer = ({ id }) => {
  const postData = useSelector((state) => state.posts.posts);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(30);
  const audioPlayer = useRef();
  const progressBar = useRef();
  const animationRef = useRef();
  const volumeRef = useRef();
  const volumeBar = useRef();
  const { resolution } = useCustomMediaQuery();
  const TogglePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    } else {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  };
  useEffect(() => {
    if (currentTime === progressBar.current.max) {
      setIsPlaying(false);
      cancelAnimationFrame(animationRef.current);
    }
  }, [currentTime]);
  useEffect(() => {
    if (audioPlayer) {
      audioPlayer.current.volume = volume / 100;
    }
  }, [volume]);
  const setPostMediaSource = () => {
    if (postData?.[id]?.post_thumbnail_resolutions.length > 0) {
      const source = postData?.[id]?.post_thumbnail_resolutions.filter(
        (element) => element?.resolution === resolution
      );
      return source[0].file_url;
    }
    return postData?.[id]?.post_thumbnail
      ? postData?.[id]?.post_thumbnail
      : noImage;
  };
  // useEffect(()=>{
  //   progressBar.current.style.setProperty('--seek-before-width',`${progressBar.current.value/duration *100}%`)
  // },[progressBar.current.value])
  const changeCurrentTime = () => {
    setCurrentTime(progressBar.current.value);
  };
  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value / duration) * 100}%`
    );
    changeCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };
  const updateRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value / duration) * 100}%`
    );
    changeCurrentTime();
  };
  const ToggleVolumeButton = () => {
    if (volume !== 0) volumeRef.current = volume;
    if (volume > 0) {
      setVolume(0);
      volumeBar.current.style.setProperty("--seek-before-width", `${1}%`);
      volumeBar.current.value = "0";
    } else {
      setVolume(volumeRef.current);
      volumeBar.current.style.setProperty(
        "--seek-before-width",
        `${volumeRef.current}%`
      );
      volumeBar.current.value = volumeRef.current;
    }
  };
  const updateVolume = (e) => {
    volumeBar.current.style.setProperty(
      "--seek-before-width",
      `${volumeBar.current.value}%`
    );
    setVolume(e.target.value);
  };
  useEffect(() => {
    setDuration(audioPlayer?.current?.duration);
    progressBar.current.max = Math.floor(audioPlayer?.current?.duration);
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);
  return (
    <div className="mb-3 audio-wrapper">
      <div className="img-container-two rounded">
        <img className="rounded" src={setPostMediaSource()} alt='' />
      </div>
      <audio ref={audioPlayer} src={postData?.[id]?.post_media}></audio>
      <div className="audio-controls">
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="d-inline w-auto border-0"
            onClick={TogglePlayPause}
          >
            {isPlaying ? <FiPause /> : <FiPlay />}
          </button>
          <span>{convertTime(currentTime)}</span>

          <div className="d-inline mx-3 flex-grow-1">
            <input
              type="range"
              min="0"
              step="1"
              ref={progressBar}
              defaultValue={0}
              onChange={updateRange}
              className="duration-slider"
            />
          </div>
          <span>{isNaN(duration) ? "0:00" : convertTime(duration)}</span>
          <div className="d-inline volume-wrapper">
            <button
              className="d-inline w-auto border-0"
              onClick={ToggleVolumeButton}
            >
              {volumeBar?.current?.value === "0" ? (
                <FiVolumeX />
              ) : (
                <FiVolume2 />
              )}
            </button>
            <input
              className="volume-slider"
              type="range"
              min={0}
              max={100}
              value={volume}
              ref={volumeBar}
              onChange={updateVolume}
              step={1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
