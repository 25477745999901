import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { getLegalTextDetails } from "../../Redux/GeneralDetailsSlice";
// import { useCustomMediaQuery } from "../../utils";
import FooterListItem from "./FooterListItem";
import "./style.scss";
const CustomFooter = () => {
  // const { isXsScreen, isSmScreen, isMdScreen } = useCustomMediaQuery();
  const generalDetails = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  // const [footerHeight, setfooterHeight] = useState(0);
  const legalText = useSelector((state) => state.generalDetails.legalText);
  const user_sign_In = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings?.user_sign_In
  );
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("accessToken");
  useEffect(() => {
    if (
      generalDetails?.impression_id &&
      !legalText?.[generalDetails.impression_id]
    ) {
      dispatch(getLegalTextDetails(generalDetails.impression_id));
    }
    if (
      generalDetails?.data_privacy_id &&
      !legalText?.[generalDetails.data_privacy_id]
    ) {
      dispatch(getLegalTextDetails(generalDetails.data_privacy_id));
    }
    if (
      generalDetails?.terms_of_use_id &&
      !legalText?.[generalDetails.terms_of_use_id]
    ) {
      dispatch(getLegalTextDetails(generalDetails.terms_of_use_id));
    }
  }, [
    generalDetails?.impression_id,
    generalDetails?.data_privacy_id,
    generalDetails?.terms_of_use_id,
  ]);

  return (
    <footer class="mt-3">
      <div
        className="customFooter"
        style={{
          backgroundColor: "white",
          color: "black",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center  flex-row">
            <div
              className={
                !accessToken && user_sign_In === "yes"
                  ? "col-md-12 col-sm-12"
                  : "col-md-6 col-sm-6"
              }
            >
              <ul
                className="list-inline "
                style={
                  !accessToken && user_sign_In === "yes"
                    ? { textAlign: "center" }
                    : { textAlign: "center" }
                }
              >
                {!!generalDetails.data_privacy ? (
                  <FooterListItem
                    key={generalDetails.data_privacy_id}
                    text={generalDetails.data_privacy_text}
                    versionId={generalDetails.data_privacy_id}
                  />
                ) : (
                  ""
                )}
                {!!generalDetails.impression ? (
                  <FooterListItem
                    key={generalDetails.impression_id}
                    text={generalDetails.impression_text}
                    versionId={generalDetails.impression_id}
                  />
                ) : (
                  ""
                )}
                {!!generalDetails.terms_of_use ? (
                  <FooterListItem
                    key={generalDetails.terms_of_use_id}
                    text={generalDetails.terms_of_use_text}
                    versionId={generalDetails.terms_of_use_id}
                  />
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CustomFooter;
