import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";

import { useRef, useState } from "react";
import { BsSendFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import {
  addPostComment,
  addPostCommentReply,
  updateCommentReply,
  updateCommunityPost,
} from "../Redux/CommunitiesAPI";
import PostCard from "./PostCard";
import ShowCommentCard from "./ShowCommentCard";

const ShowCommentModal = ({
  show,
  closeModal,
  id,
  getPostsAPI,
  type,
  bodyParam,
  communityId,
  post,
  only3Comments,
}) => {
  const intl = useIntl();
  // const [body, setBody] = useState(type === "update" ? bodyParam : "");
  const [comment, setComment] = useState(
    !only3Comments ? "" : only3Comments?.input
  );
  const [showModal, setShowModal] = useState(false);
  const [inputType, setInputType] = useState(
    !only3Comments ? "" : only3Comments?.type
  );
  const [replyId, setReplyId] = useState(
    !only3Comments ? "" : only3Comments?.replyId
  );
  const [replyToUser, setReplyToUser] = useState(
    !only3Comments ? "" : only3Comments?.replyToUser
  );

  const userInfo = useSelector(
    (state) => state.auth?.user?.personalInformation
  );

  const commentRef = useRef(null);

  const resetValues = () => {
    setReplyId(null);
    setInputType("");
    getPostsAPI();
    setComment("");
  };

  const addCommentToPost = () => {
    if (comment !== "") {
      //replying to a comment
      if (inputType === "reply") {
        if (replyId) {
          addPostCommentReply(
            post.communities_id,
            post.posts_id,
            comment,
            replyId
          )
            .then(() => {
              //   successToast("post updated successfully");
            })
            .catch(() => {
              //   errorToast("post updation unsuccessful");
            })
            .finally(() => {
              resetValues();
            });
        }
      } else if (inputType === "updatePost") {
        updateCommunityPost(post.posts_id, comment)
          .then(() => {
            //   successToast("post updated successfully");
          })
          .catch(() => {
            //   errorToast("post updation unsuccessful");
          })
          .finally(() => {
            resetValues();
          });
      }
      //updating comment
      else if (inputType === "update") {
        updateCommentReply(post.communities_id, comment, replyId)
          .then(() => {
            //   successToast("post updated successfully");
          })
          .catch(() => {
            //   errorToast("post updation unsuccessful");
          })
          .finally(() => {
            resetValues();
          });
      }
      //add comment
      else {
        addPostComment(communityId, id, comment)
          .then(() => {
            //   successToast("post updated successfully");
          })
          .catch(() => {
            //   errorToast("post updation unsuccessful");
          })
          .finally(() => {
            resetValues();
          });
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={closeModal}
        size={"xlg"}
        dialogClassName={
          showModal ? "showComment-modal d-none" : "showComment-modal"
        }
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {userInfo?.firstname}'{"s post"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "800px", maxHeight: "800px" }}>
          <div>
            <PostCard
              post={post}
              getPostsAPI={getPostsAPI}
              type={"showComment"}
              getShowModal={(value) => {
                setShowModal(value);
              }}
              setInputType={(body) => {
                setInputType("updatePost");
                setComment(body);
              }}
            />
            <div style={{ overflow: "auto" }}>
              <ShowCommentCard
                details={post}
                getPostsAPI={getPostsAPI}
                getShowModal={(value) => {
                  setShowModal(value);
                }}
                setInputAndType={(type, input, replyId, replyToUser) => {
                  // console.log("testing", type, input);
                  setComment(input);
                  setInputType(type);
                  setReplyId(replyId);
                  setReplyToUser(replyToUser);
                }}
                commentRef={commentRef}
                only3Comments={false}
                communities={true}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className=" position-relative">
          {inputType === "update" ||
          inputType === "reply" ||
          inputType === "updatePost" ? (
            <div
              className=" position-absolute"
              style={{
                height: "30px",
                background: "#f1f1f1",
                top: "-36px",
                left: "-4px",
                width: "100%",
              }}
            >
              <div className=" d-flex justify-content-between align-items-center ms-3 mt-1">
                <div>
                  {inputType === "update" ? (
                    <div className="fw-bold">
                      {intl.formatMessage({
                        id: "COMMUNITIES_UPDATING_COMMENT",
                      })}
                    </div>
                  ) : inputType === "updatePost" ? (
                    <div className="fw-bold">
                      {intl.formatMessage({
                        id: "COMMUNITIES_UPDATING_POST",
                      })}
                    </div>
                  ) : (
                    <div>
                      {`${intl.formatMessage({
                        id: "COMMUNITIES_REPLYING_TO",
                      })} `}
                      <span className="fw-bold">{replyToUser}</span>
                    </div>
                  )}
                </div>
                <div
                  className="fw-bold text-underline-hover cursor-pointer me-3"
                  onClick={() => {
                    resetValues();
                  }}
                >
                  {intl.formatMessage({
                    id: "COMMUNITIES_CANCEL",
                  })}
                </div>
              </div>
            </div>
          ) : null}
          <div
            className="d-flex ratingAddContainer mb-3 w-100"
            style={{ background: "#ffffff" }}
          >
            <div className="ratingAddSubContainer justify-content-between align-items-center  d-flex w-100 h-100 py-2">
              <div className="d-flex">
                {userInfo?.firstname ? (
                  <div className="ratingAddProfile d-flex justify-content-center align-items-center">
                    {`${userInfo?.firstname.toUpperCase()[0]}${
                      userInfo?.lastname.toUpperCase()[0]
                    }`}
                  </div>
                ) : null}
              </div>
              <div
                className="ratingAddInput d-flex justify-content-start align-items-center showCommentModal h-100"
                onClick={() => {}}
              >
                <div
                  className="w-100 d-flex justify-content-between align-items-center"
                  style={{ height: "inherit" }}
                >
                  <textarea
                    ref={commentRef}
                    className="ratingAddInputText"
                    id="showTextarea"
                    style={{
                      width: "100%",
                      margin: "0",
                      border: "none",
                      height: "inherit",
                      borderRadius: "20px",
                      backgroundColor: "#f1f1f1",
                      padding: "13px",
                    }}
                    value={comment || ""}
                    placeholder={
                      userInfo?.firstname
                        ? `${intl.formatMessage({
                            id: "POST_RATINGS_QUESTION",
                          })} ${userInfo.firstname} ?`
                        : null
                    }
                    autoFocus
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  ></textarea>
                  <BsSendFill
                    style={{
                      fontSize: "30px",
                      color: comment ? "var(--accent-color)" : "silver",
                      padding: "5px",
                      marginRight: "10px",
                    }}
                    type="button"
                    onClick={() => {
                      addCommentToPost();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShowCommentModal;
