import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Loader from "../../../sharedComponents/Loader";
import { TitleHeader } from "../../../sharedComponents/Title";
import { getOffersList, getProductList } from "../../auth/Redux/AuthApi";
import ProductCard from "./ProductCard";

const ProductList = () => {
  const intl = useIntl();
  // state
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);

  const productListAPI = () => {
    setLoading(true);
    getProductList()
      .then(({ data }) => {
        setProductList(data.products);
        // console.log(data.products);
        // dispatch(actions.setUser(data))
        //   dispatch(actions.setUser(data.personalInformation))
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    productListAPI();
  }, []);

  useEffect(() => {
    getOffersList().then(() => {});
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="card mb-4 mb-xl-10 me-0 card-no-border ms-0">
            <div
              className="card-header border-0 cursor-pointer card-header-custom pt-0 px-0"
              role="button"
              //  data-bs-toggle='collapse'
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
            >
              <TitleHeader
                noMarginTop
                title={intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_PRODUCTS_TITLE",
                })}
              />
            </div>
            <div className="pb-3">
              {productList && productList.length > 0 ? (
                productList.map((ele) => {
                  return <ProductCard element={ele} />;
                })
              ) : (
                <>
                  <div>
                    {intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PRODUCTS_NO_PRODUCTS_PURCHASED",
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductList;
