import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  activateAccount,
  updateCustomerPersonalDetails,
} from "../../../API/Api";
import { actions } from "../../../Redux/AuthReducer";
import Alert from "../../../sharedComponents/Alerts/Alert";
import { CustomDropDown } from "../../../sharedComponents/Dropdown/CustomDropDown";
import InputLabel from "../../../sharedComponents/InputLabel/InputLabel";
import ConfirmPasswordModal from "../../../sharedComponents/Modal/ConfirmPasswordModal";
import TextInput from "../../../sharedComponents/TextInput/TextInput";
import { TitleHeader } from "../../../sharedComponents/Title";
import {
  ErrorAlert,
  SuccessAlert,
  errorToast,
  successToast,
} from "../../../sharedComponents/Toast/Toasts";
import { deactivateContactAccount } from "../../auth/Redux/AuthApi";

export const salutations = [
  { id: 1, name: "Mr." },
  { id: 2, name: "Mrs." },
];

export const titles = [
  { id: null, name: "No title" },
  { id: 1, name: "Dr." },
  { id: 2, name: "Prof." },
  { id: 3, name: "Prof. Dr." },
];

function PersonalInformation({ userDetails, getCustomerDetailsAPI }) {
  const intl = useIntl();
  const [user, setUser] = useState(userDetails);
  const [loading, setLoading] = useState(false);
  const [selectedSalutation, setSelectedSalutation] = useState();
  const [selectedTitle, setSelectedTitle] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] =
    useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [activationLoading, setActivationLoading] = useState(false);

  const info = useSelector((state) => state.auth?.user?.personalInformation);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const handleTouched = (fieldName) => {
  //   formik.setTouched({ ...formik.touched, [fieldName]: true }, true);
  // };

  const SALUATION_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_SALUATION_OPTIONS" })
  );
  const TITLES_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_TITLES_OPTIONS" })
  );

  const logout = () => {
    // navigate("/");
    dispatch(actions.logout());
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };

  const deactivateAccount = () => {
    setDeleteLoading(true);
    deactivateContactAccount()
      .then(() => {
        successToast(
          intl.formatMessage({
            id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_DEACTIVATE_SUCCESS_TOAST",
          })
        );
        logout();
        navigate("/");
      })
      .catch(() => {
        errorToast(
          intl.formatMessage({
            id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_DEACTIVATE_ERROR_TOAST",
          })
        );
      })
      .finally(() => {
        getCustomerDetailsAPI();
        setDeleteLoading(false);
      });
  };

  const closeConfirmPasswordModal = () => {
    setShowConfirmPasswordModal(false);
  };

  useEffect(() => {
    formik.setFieldValue(
      "salutation",
      user?.personalInformation?.salutation_id
    );
    if (user?.personalInformation?.salutation_id) {
      setSelectedSalutation(
        SALUATION_OPTIONS.find(
          (item) => item.id === user?.personalInformation?.salutation_id
        )
      );
    }
    formik.setFieldValue("title", user?.personalInformation?.title_id);
    if (user?.personalInformation?.title_id) {
      setSelectedTitle(
        TITLES_OPTIONS.find(
          (item) => item.id === user?.personalInformation?.title_id
        )
      );
    }
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    salutation: user?.personalInformation?.salutation_id || 1,
    title: user?.personalInformation?.title_id || 0,
    firstname: user?.personalInformation?.firstname || "",
    lastname: user?.personalInformation?.lastname || "",
    username: user?.personalInformation?.username || "",
  };

  const EditCustomerProfileSchema = Yup.object().shape({
    salutation: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_SALUTATION_MESSAGE",
      })
    ),
    firstname: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_FIRST_NAME_MESSAGE",
      })
    ),
    lastname: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_LAST_NAME_MESSAGE",
      })
    ),
    username: Yup.string()
      .matches(
        /^[a-zA-Z0-9]*$/,
        intl.formatMessage({
          id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_USERNAME_INVALID_MESSAGE",
        })
      )
      .required(
        intl.formatMessage({
          id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_USERNAME_MESSAGE",
        })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: EditCustomerProfileSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading(true);
      updateCustomerPersonalDetails(
        user?.personalInformation?.id,
        null,
        values.salutation,
        values.title,
        values.firstname,
        values.lastname,
        values.username
      )
        .then(() => {
          // SuccessAlert(intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_PROFILE_ADD_SUCCESS_MESSAGE'}))
          setShowSuccess(true);
        })
        .catch(() => {
          // errorAlert(intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_PROFILE_ADD_FAILURE_MESSAGE'}))
          setShowFailure(true);
        })
        .finally(() => {
          setLoading(false);
          getCustomerDetailsAPI();
        });
    },
  });
  return (
    <>
      {showConfirmPasswordModal && (
        <ConfirmPasswordModal
          show={showConfirmPasswordModal}
          message={intl.formatMessage({
            id: "USERS_CONFIRM_PASSWORD_DELETE_ACCOUNT_MESSAGE",
          })}
          user_id={userDetails?.personalInformation?.id}
          onPasswordConfirm={deactivateAccount}
          closeModal={closeConfirmPasswordModal}
        />
      )}
      <Alert
        show={showSuccess ? showSuccess : showFailure}
        setShow={showSuccess ? setShowSuccess : setShowFailure}
        message={
          showSuccess
            ? intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_UPDATE_SUCCESS_MESSAGE",
              })
            : intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_UPDATE_FAILURE_MESSAGE",
              })
        }
        type={showSuccess ? "success" : "failure"}
      />
      <div className="card mb-4 mb-xl-10 me-0 card-no-border ms-0">
        <div
          className="card-header border-0 cursor-pointer card-header-custom pt-0"
          role="button"
          //  data-bs-toggle='collapse'
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          {/* <div className="card-title m-0">
            <h3 className=" m-0" style={{ fontSize: "20px" }}>
              {intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_TITLE",
              })}
            </h3>
          </div> */}
          <TitleHeader
            noMarginTop
            title={intl.formatMessage({
              id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_TITLE",
            })}
          />
        </div>

        <div id="kt_account_profile_details" className="collapse show">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <div className="card-body pt-0 p-3">
              {/* salutation */}
              <div className="mb-3">
                <InputLabel
                  required
                  text={intl.formatMessage({
                    id: "TITLE_SALUTATION",
                  })}
                />
                <CustomDropDown
                  id="salutationDropDown"
                  hideSearch
                  size="small"
                  items={SALUATION_OPTIONS}
                  selectedItem={selectedSalutation}
                  // @ts-ignore
                  onSelectItem={(item) => {
                    formik.setFieldValue("salutation", item.id);
                    setSelectedSalutation(item);
                  }}
                  displayValue={selectedSalutation?.name || ""}
                  // @ts-ignore
                  displayFunc={(item) => {
                    return item.name;
                  }}
                  isError={formik.errors.salutation}
                />
                {formik.errors.salutation && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik.errors.salutation}</span>
                  </div>
                )}
              </div>

              {/* titile */}
              <div className="mb-3">
                <InputLabel
                  text={intl.formatMessage({
                    id: "TITLE_TITLE",
                  })}
                />

                <CustomDropDown
                  id="titleDropDown"
                  hideSearch
                  size="small"
                  items={TITLES_OPTIONS}
                  selectedItem={selectedTitle}
                  // @ts-ignore
                  onSelectItem={(item) => {
                    formik.setFieldValue("title", item.id);
                    setSelectedTitle(item);
                  }}
                  displayValue={selectedTitle?.name || ""}
                  // @ts-ignore
                  displayFunc={(item) => {
                    return item.name;
                  }}
                  isError={formik.errors.title}
                />
                {formik.errors.title && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik.errors.title}</span>
                  </div>
                )}
              </div>

              {/* fullname : firstname, lastname */}
              <div className="row mb-md-3 mb-sm-0 form-outline position-relative">
                <div className="col-md-6 position-relative first-name-sm">
                  <InputLabel
                    required
                    text={intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_FIRST_NAME_TITLE",
                    })}
                  />

                  <TextInput
                    value={formik.values.firstname}
                    size="lg"
                    fontSize="16px"
                    onChange={(value) => {
                      formik.setFieldValue("firstname", value);
                    }}
                    isError={
                      formik.touched.firstname && formik.errors.firstname
                    }
                  />
                  {formik.touched.firstname && formik.errors.firstname && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.firstname}</span>
                    </div>
                  )}
                </div>
                <div className="col-md-6 position-relative first-name-sm">
                  <InputLabel
                    required
                    text={intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_LAST_NAME_TITLE",
                    })}
                  />

                  <TextInput
                    value={formik.values.lastname}
                    size="lg"
                    fontSize="16px"
                    onChange={(value) => {
                      formik.setFieldValue("lastname", value);
                    }}
                    isError={formik.touched.lastname && formik.errors.lastname}
                  />
                  {formik.touched.lastname && formik.errors.lastname && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.lastname}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="row mb-3">
                <label className="col-lg-4 col-form-label required form-input-label">
                  {translate(
                    "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_NAME_TITLE"
                  )}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className="mb-3 mb-lg-0 form-common-input"
                        style={{ width: "100%" }}
                        {...formik.getFieldProps("firstname")}
                      />
                      {formik.errors.firstname && (
                        <div className="text-danger">
                          <span role="alert">{formik.errors.firstname}</span>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6 fv-row">
                      <input
                        type="text"
                        className=" form-common-input"
                        style={{ width: "100%" }}
                        {...formik.getFieldProps("lastname")}
                      />
                      {formik.errors.lastname && (
                        <div className="text-danger">
                          <span role="alert">{formik.errors.lastname}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}

              {/* username */}
              <div className="">
                <InputLabel
                  required
                  text={intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_USERNAME_TITLE",
                  })}
                />

                <TextInput
                  value={formik.values.username}
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    formik.setFieldValue("username", value);
                  }}
                  isError={formik.touched.username && formik.errors.username}
                />
                {formik.touched.username && formik.errors.username && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik.errors.username}</span>
                  </div>
                )}
              </div>

              {/* <label className=" col-lg-4 col-form-label required form-input-label">
                {intl.formatMessage({
                  id: '"ACCOUNT_SETTINGS_PERSONAL_INFORMATION_USERNAME_TITLE"',
                })}
              </label>

              <div className="col-lg-8">
                <div className="fv-row">
                  <input
                    type="text"
                    className=" mb-3 mb-lg-0 form-common-input"
                    style={{ width: "100%" }}
                    {...formik.getFieldProps("username")}
                  />
                  {formik.errors.username && (
                    <div className="text-danger">
                      <span role="alert">{formik.errors.username}</span>
                    </div>
                  )}
                </div>
              </div> */}
            </div>

            <div className="card-footer d-flex justify-content-start py-0 px-9 card-footer-custom">
              <button
                type="submit"
                className="btn btn-custom"
                disabled={loading}
              >
                {!loading &&
                  intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_SUBMIT",
                  })}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_LOADING",
                    })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Activate account card */}

      <div className="card mb-4 mb-xl-10 me-0 ms-0 card-no-border">
        <div
          className="card-header border-0 cursor-pointer card-header-custom pt-0"
          role="button"
          //  data-bs-toggle='collapse'
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <TitleHeader
            noMarginTop
            title={intl.formatMessage({ id: "TITLE_ACTIVATE_ACCOUNT" })}
            activated={info?.double_opt_in === 1}
          />
        </div>

        {info?.sys_admin === 0 &&
        info?.sys_customer === 1 &&
        info?.double_opt_in === 0 ? (
          <div id="kt_account_profile_details" className="collapse show">
            {/* <form onSubmit={formik2.handleSubmit} noValidate className="form"> */}
            <div className="card-body pt-0 p-3">
              {intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATE_ACCOUNT_MESSAGE",
              })}
            </div>

            <div className="card-footer d-flex justify-content-start pb-3 px-9 card-footer-custom">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  setActivationLoading(true);
                  activateAccount(info?.id)
                    .then(() => {
                      SuccessAlert(
                        intl.formatMessage({
                          id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATE_ACCOUNT_SUCCESS_MESSAGE",
                        })
                      );
                    })
                    .catch(() => {
                      ErrorAlert(
                        intl.formatMessage({
                          id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATE_ACCOUNT_ERROR_MESSAGE",
                        })
                      );
                    })
                    .finally(() => {
                      setActivationLoading(false);
                    });
                }}
                // disabled={loading2}
              >
                {!activationLoading &&
                  intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATE_BUTTON",
                  })}
                {activationLoading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_LOADING",
                    })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
            {/* </form> */}
          </div>
        ) : (
          <div className="card-body pt-0 p-3">
            {intl.formatMessage({
              id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATED_MESSAGE",
            })}
          </div>
        )}
      </div>

      {/* ------------------------------------------------------------------------------------ */}

      {/* Delete account card */}

      <div className="card mb-5 mb-xl-10 me-0 ms-0 card-no-border">
        <div
          className="card-header border-0 cursor-pointer card-header-custom pt-0"
          role="button"
          //  data-bs-toggle='collapse'
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <TitleHeader
            noMarginTop
            title={intl.formatMessage({ id: "TITLE_DELETE_ACCOUNT" })}
          />
        </div>

        <div id="kt_account_profile_details" className="collapse show">
          {/* <form onSubmit={formik2.handleSubmit} noValidate className="form"> */}
          <div className="card-body pt-0 p-3">
            {intl.formatMessage({
              id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_DEACTIVATE_ACCOUNT_MESSAGE",
            })}
          </div>

          <div className="card-footer d-flex justify-content-start pb-3 px-9 card-footer-custom">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setShowConfirmPasswordModal(true);
              }}
              // disabled={loading2}
            >
              {!deleteLoading &&
                intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_DEACTIVATE_BUTTON",
                })}
              {deleteLoading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_LOADING",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
}

export default PersonalInformation;
