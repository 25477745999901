import React from "react";
import { useIntl } from "react-intl";
import { TitleHeader } from "../../../sharedComponents/Title";
import DevicesCard from "./DevicesCard";

function DevicesList({ userDetails, getCustomerDetailsAPI }) {
  const intl = useIntl();
  return (
    <>
      <div className="card mb-4 mb-xl-10 me-0 card-no-border ms-0">
        <div
          className="card-header border-0 cursor-pointer card-header-custom pt-0 px-0"
          role="button"
          //  data-bs-toggle='collapse'
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <TitleHeader
            noMarginTop
            title={intl.formatMessage({
              id: "ACCOUNT_SETTINGS_DEVICES_TITLE",
            })}
          />
        </div>
        <div className="pb-3">
          {userDetails?.devices && userDetails?.devices.length > 0 ? (
            userDetails?.devices.map((ele) => {
              return (
                <DevicesCard
                  element={ele}
                  getCustomerDetailsAPI={getCustomerDetailsAPI}
                />
              );
            })
          ) : (
            <>
              <div>
                {intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_NO_DEVICES",
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DevicesList;
