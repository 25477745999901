import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AuthorImage from "./AuthorImage";
import "./style.scss";

const AuthorInArticle = ({ authors, view }) => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  return (
    <div className="d-flex justify-content-center align-items-center">
      {authors && authors.length > 0
        ? authors.map((ele, index, authors) => {
            if (
              (view === "list" && index <= 10) ||
              (view === "grid" && index <= 5)
            )
              return isAuthorized ? (
                <Link to={`/authors/${ele.id}`}>
                  <AuthorImage
                    key={index}
                    photoUrl={ele.profile_photo_url}
                    firstname={ele.firstname}
                    lastname={ele.lastname}
                    zIndex={index}
                    displayname={ele.display_name}
                    authorId={ele.id}
                    ele={ele}
                  />
                </Link>
              ) : (
                <AuthorImage
                  key={index}
                  photoUrl={ele.profile_photo_url}
                  firstname={ele.firstname}
                  lastname={ele.lastname}
                  zIndex={index}
                  displayname={ele.display_name}
                  authorId={ele.id}
                  ele={ele}
                />
              );
            return null;
          })
        : null}
      {view === "list" && authors && authors.length - 10 > 0 ? (
        <div
          className="author-tooltip"
          style={{
            position: "relative",
            zIndex: authors.length,
            marginLeft: "-10px",
          }}
        >
          <div
            className="position-absolute bg-light px-3 py-2 fs-8 justify-content-center align-items-center"
            style={{
              display: "none",
              backgroundColor: "white",
              top: "-75px",
              borderRadius: "5px",
              fontSize: "12px",
              minHeight: "60px",
              minWidth: "100px",
            }}
          >
            {" "}
            {authors &&
              authors
                .slice(10, authors.length)
                .map((res, index) =>
                  index === authors.slice(10, authors.length).length - 1
                    ? res.firstname + res.lastname + ""
                    : res.firstname + res.lastname + ", "
                )}
          </div>

          <div className="author_meta d-flex justify-content-center align-items-center author_firstname">
            +{authors && authors.length > 2 && authors.length - 2}
          </div>
        </div>
      ) : view === "grid" && authors && authors.length - 5 > 0 ? (
        <div
          className="author-tooltip"
          style={{
            position: "relative",
            zIndex: authors.length,
            marginLeft: "-10px",
          }}
        >
          <div
            className="position-absolute bg-light px-3 py-2 fs-8 justify-content-center align-items-center"
            style={{
              display: "none",
              backgroundColor: "white",
              top: "-75px",
              borderRadius: "5px",
              fontSize: "12px",
              minHeight: "60px",
              minWidth: "100px",
            }}
          >
            {" "}
            {authors &&
              authors
                .slice(5, authors.length)
                .map((res, index) =>
                  index === authors.slice(5, authors.length).length - 1
                    ? res.firstname + res.lastname + ""
                    : res.firstname + res.lastname + ", "
                )}
          </div>

          <div className="author_meta d-flex justify-content-center align-items-center author_firstname">
            +{authors && authors.length > 2 && authors.length - 2}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AuthorInArticle;
