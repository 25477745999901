import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";
import * as Yup from "yup";
import { subscribeToNewsletter } from "../../API/Api";
import CustomAlert from "../CustomAlerts/CustomAlert";
import { CustomDropDown } from "../Dropdown/CustomDropDown";
import InputLabel from "../InputLabel/InputLabel";
import LegalTextModal from "../Modal/LegalTextModal";
import TextInput from "../TextInput/TextInput";

const NewsLetterForm = () => {
  const intl = useIntl();
  const location = useLocation();
  const [legalString, setLegalString] = useState();
  const SALUATION_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_SALUATION_OPTIONS" })
  );
  const { pageName, type, pageDetails, generalDetails } = location.state;

  const landingPage = pageDetails?.[pageName]

  const staticSidebar = generalDetails?.website_settings
        ?.static_pages_sidebars

  const eventDetailsSidebar = generalDetails?.events_general_settings
        ?.events_details_sidebars

  const postDetailsSidebar = generalDetails?.posts_general_settings
        ?.posts_details_sidebars

  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const legalText = useSelector((state) => state.generalDetails.legalText);
  const smallScreen = useMediaQuery({ maxWidth: 575 });
  const mediumScreen = useMediaQuery({ minWidth: 576, maxWidth: 767 });
  const [show, setShow] = useState(false);
  const [details, setdetails] = useState({});
  const [selectedSalutation, setSelectedSalutation] = useState();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const titleStyle = {
    color: details?.title_text_color,
    backgroundColor: details?.title_layer_color,
    opacity: `${details?.title_layer_transparency}%`,
    fontSize: smallScreen
      ? `${details?.title_size_small}px`
      : mediumScreen
      ? `${details?.title_size_medium}px`
      : `${details?.title_size_large}px`,
    alignSelf: "center",
    width: "fit-content",
  };
  useEffect(() => {
    if (legalText?.[details?.legal_text_id]) {
      const placeholder =
        legalText?.[details?.legal_text_id]?.element.match(/\{{%(.*?)\%}}/g);
      if (placeholder && placeholder.length > 0) {
        setLegalString(
          legalText?.[details?.legal_text_id]?.element.split(placeholder[0])
        );
      }
    }
  }, [details]);
  const handleData = (data) => {
    if (data?.sections && data?.sections.length > 0) {
      const sectionDetails = data.sections.filter((ele) => {
        return ele?.type === "NewsLetter";
        //&& ele?.id===id && ele?.section_id===sectionId
      });
      if (sectionDetails && sectionDetails.length > 0) {
        setdetails(sectionDetails[0]);
      }
    }
  };
  useEffect(() => {
    if (type === "postDetailsPage") {
      handleData(postDetailsSidebar);
    } else if (type === "staticPage") {
      handleData(staticSidebar);
    } else if (type === "eventDetailsPage") {
      handleData(eventDetailsSidebar);
    } else {
      handleData(landingPage);
    }
  }, []);

  const userDetails = useSelector(
    (state) => state.auth?.user?.personalInformation
  );

  useEffect(() => {
    formik.setFieldValue("salutation", userDetails?.salutation_id);
    if (userDetails?.salutation_id) {
      setSelectedSalutation(
        SALUATION_OPTIONS.find((item) => item.id === userDetails.salutation_id)
      );
    }
  }, []);

  const initialvalues = {
    firstName: userDetails?.firstname || "",
    lastName: userDetails?.lastname || "",
    email: userDetails?.email || "",
    salutation: userDetails?.salutation_id || 1,
    checked: false,
  };

  const validationSchemaOne = Yup.object().shape({
    salutation: Yup.string().required("Required"),
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    checked: Yup.bool().oneOf([true], "Please accept terms and conditions"),
  });
  const validationSchemaTwo = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    checked: Yup.bool().oneOf([true], "Please accept terms and conditions"),
  });
  const validationSchemaThree = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });
  const formik = useFormik({
    initialValues: initialvalues,
    validationSchema:
      details?.legal_text_id === null
        ? validationSchemaThree
        : !!details?.extra_enable
        ? validationSchemaOne
        : validationSchemaTwo,
    onSubmit: (values, actions) => {
      if (!!details?.extra_enable) {
        if (
          values.salutation &&
          values.firstName &&
          values.lastName &&
          values.email &&
          values.checked
        ) {
          setLoading(true);
          subscribeToNewsletter(
            details.id,
            values.email,
            values.salutation,
            values.firstName,
            values.lastName
          )
            .then(() => {
              setIsSuccess(true);
              setShowAlert(true);
            })
            .catch(() => {
              setIsSuccess(false);
              setShowAlert(true);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } else {
        if (values.email) {
          setLoading(true);
          subscribeToNewsletter(details.id, values.email)
            .then(() => {
              setIsSuccess(true);
              setShowAlert(true);
            })
            .catch(() => {
              setIsSuccess(false);
              setShowAlert(true);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    },
  });
  return (
    <>
      <CustomAlert
        show={showAlert}
        closeModal={() => {
          setShowAlert(false);
        }}
        type={isSuccess ? "success" : "error"}
        message={
          isSuccess
            ? intl.formatMessage({ id: "NEWSLETTER_FORM_SUBMIT_SUCCESS" })
            : intl.formatMessage({ id: "NEWSLETTER_FORM_SUBMIT_FAILED" })
        }
      />
      <div
        className="container mt-5 mb-3"
        style={{ maxWidth: "750px", margin: "auto" }}
      >
        <h6 className="card-title mb-2 text-start " style={titleStyle}>
          {details?.title}
        </h6>
        <div className="row">
          <form onSubmit={formik.handleSubmit}>
            {/* salutation */}
            {!!details.extra_enable && (
              <>
                <div className="mb-3">
                  <InputLabel
                    required
                    text={intl.formatMessage({
                      id: "TITLE_SALUTATION",
                    })}
                  />
                  <CustomDropDown
                    id="salutationDropDown"
                    hideSearch
                    size="small"
                    items={SALUATION_OPTIONS}
                    selectedItem={selectedSalutation}
                    // @ts-ignore
                    onSelectItem={(item) => {
                      formik.setFieldValue("salutation", item.id);
                      setSelectedSalutation(item);
                    }}
                    displayValue={selectedSalutation?.name || ""}
                    // @ts-ignore
                    displayFunc={(item) => {
                      return item.name;
                    }}
                    isError={
                      formik.errors.salutation && formik.touched.salutation
                    }
                    disabled={isAuthorized}
                  />
                  {formik.errors.salutation && formik.touched.salutation && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.salutation}</span>
                    </div>
                  )}
                </div>
                <div className="row mb-3 form-outline position-relative">
                  <div className="col-md-6 position-relative first-name-sm">
                    <InputLabel
                      required
                      text={intl.formatMessage({
                        id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_FIRST_NAME_TITLE",
                      })}
                    />

                    <TextInput
                      value={formik.values.firstName}
                      size="lg"
                      fontSize="16px"
                      onChange={(value) => {
                        formik.setFieldValue("firstName", value);
                      }}
                      isError={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      disabled={isAuthorized}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: "var(--error-alert)",
                        }}
                      >
                        <span role="alert">{formik.errors.firstName}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 position-relative first-name-sm">
                    <InputLabel
                      required
                      text={intl.formatMessage({
                        id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_LAST_NAME_TITLE",
                      })}
                    />

                    <TextInput
                      value={formik.values.lastName}
                      size="lg"
                      fontSize="16px"
                      onChange={(value) => {
                        formik.setFieldValue("lastName", value);
                      }}
                      isError={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      disabled={isAuthorized}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: "var(--error-alert)",
                        }}
                      >
                        <span role="alert">{formik.errors.lastName}</span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="mb-3">
              <InputLabel
                required
                text={intl.formatMessage({
                  id: "LABEL_EMAIL",
                })}
              />

              <TextInput
                value={formik.values.email}
                size="lg"
                fontSize="16px"
                onChange={(value) => {
                  formik.setFieldValue("email", value);
                }}
                isError={formik.touched.email && formik.errors.email}
                disabled={isAuthorized}
              />
              {formik.touched.email && formik.errors.email && (
                <div style={{ fontSize: "14px", color: "var(--error-alert)" }}>
                  <span role="alert">{formik.errors.email}</span>
                </div>
              )}
            </div>
            <div className="col-12 mt-3">
              {!!details?.legal_text_id ? (
                <>
                  <label>{legalText?.[details?.legal_text_id]?.name} *</label>
                  <div className="mt-2 mb-2 d-flex">
                    <input
                      type="checkbox"
                      name="checked"
                      className=""
                      value={formik.values.checked}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      style={{ minWidth: "20px" }}
                    />
                    {/* <span className="mx-2 w-100"> */}
                    <label>
                      {legalString && legalString.length > 0 ? (
                        <>
                          <span className="mx-2 text-start">
                            <span className="text-start">
                              {" "}
                              {legalString[0].split("<p>")[1]}
                            </span>
                            <span
                              className="read-more-btn border-0 bg-white text-decoration-underline "
                              onClick={() => handleShow()}
                              style={{
                                padding: "0px 5px",
                                color: "var(--accent-color)",
                              }}
                              type="button"
                            >
                              {legalText?.[details?.legal_text_id]?.title}
                            </span>
                            <span className="text-start">
                              {" "}
                              {legalString[1].split("</p>")[0]}
                            </span>
                          </span>
                        </>
                      ) : (
                        <span className="mx-2 text-start">
                          {legalText?.[details?.legal_text_id]?.element.replace(
                            /(<([^>]+)>)/gi,
                            ""
                          )}
                          <span
                            className="read-more-btn border-0 bg-white text-decoration-underline "
                            onClick={() => handleShow()}
                            style={{
                              padding: "0px 10px",
                              color: "var(--accent-color)",
                            }}
                            type="button"
                          >
                            {intl.formatMessage({ id: "BUTTON_READ_MORE" })}
                          </span>
                        </span>
                      )}
                      {formik.touched?.checked &&
                        formik.errors &&
                        formik.errors?.checked && (
                          <div
                            style={{
                              fontSize: "12px",
                              fontFamily: "var(--font-Family)",
                              color: "var(--error-alert)",
                              //position: "relative",
                              //top: "-20px",
                            }}
                          >
                            {formik.errors?.checked}
                          </div>
                        )}
                    </label>
                    {/* </span> */}
                  </div>

                  {/* <ErrorMessage
                  name="checked"
                  component="div"
                  className="alert-message"
                  style={{ textAlign: "start", marginLeft: "1px" }}
                /> */}
                </>
              ) : null}
            </div>
            <button
              type="submit"
              id="button-custom"
              disabled={loading}
              className="mt-3"
              style={{ width: mediumScreen || smallScreen ? "100%" : "" }}
            >
              {intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            </button>
          </form>
        </div>
        <LegalTextModal
          handleClose={handleClose}
          show={show}
          versionId={details?.legal_text_id}
        />
      </div>
    </>
  );
};

export default NewsLetterForm;
