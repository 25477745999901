import { convert } from "html-to-text";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { ImArrowRight2 } from "react-icons/im";
import { useIntl } from "react-intl";
import Title, { TitleHeader } from "../../sharedComponents/Title";
import DeadlinePreviewModal from "./DeadlinePreviewModal";

const DeadlinesListSection = ({
  id,
  pageName,
  sidebars,
  title,
  details,
  isSidebar,
}) => {
  const intl = useIntl();
  const [deadlines, setDeadlines] = useState(details);
  const [deadlinesList, setDeadlinesList] = useState();
  const [previewDeadline, setPreviewDeadline] = useState();
  const [showDeadlineModal, setShowDeadlineModal] = useState(false);
  const [highlightedDates, setHighlightedDates] = useState();
  const [clickedDateData, setClickedDateData] = useState();
  const [showClickedDateDeadline, setShowClickedDateDeadline] = useState(false);
  const [filteredDeadlines, setFilteredDeadlines] = useState();
  const [mousePosition, setMousePosition] = useState({
    x: null,
    y: null,
  });
  const [topOffset, setTopOffset] = useState();

  useEffect(() => {
    // let parent = document?.getElementById("calenderParent");
    let nav = document.querySelector(".rdp-nav");

    const closeDetailModal = () => {
      setShowClickedDateDeadline(false);
    };

    // const updateMousePosition = (ev) => {
    //   let bounds = parent.getBoundingClientRect();
    //   let x = ev.clientX - bounds.left;
    //   let y = ev.clientY - bounds.top;
    //   setMousePosition({ x: x, y: y });
    // };

    nav?.addEventListener("click", closeDetailModal);

    // if (showClickedDateDeadline) {
    //   parent?.removeEventListener("click", updateMousePosition);
    // } else {
    //   parent?.addEventListener("click", updateMousePosition);
    // }
    return () => {
      // parent?.removeEventListener("click", updateMousePosition);
      nav?.removeEventListener("click", closeDetailModal);
    };
  }, [showClickedDateDeadline]);

  useEffect(() => {
    setDeadlines(details);
  }, [details]);

  useEffect(() => {
    const tempObj = { ...deadlines };
    const deadlinesDisplayOption = tempObj?.deadlines
      ?.filter((item) => item.date !== null)
      .filter((item) => {
        if (deadlines?.display_option === "all") {
          return item;
        } else if (deadlines?.display_option === "past") {
          const today = new Date();
          return item.date < today;
        } else if (deadlines?.display_option === "future") {
          const today = new Date();
          return (
            new Date(item.date) > today ||
            item.date === moment(new Date()).format("YYYY-MM-DD")
          );
        }
      });

    const arr = [...deadlinesDisplayOption];
    const filtered = arr?.filter((item) => {
      if (deadlines?.display_variant === "year") {
        return moment(item.date).format("YYYY") === moment().format("YYYY");
      } else if (deadlines?.display_variant === "month") {
        return moment(item.date).format("M") === moment().format("M");
      }
    });

    setFilteredDeadlines(filtered);

    const arr2 = [...filtered];
    const sorted = arr2.sort((a, b) => {
      if (deadlines?.group_by_month === 1) {
        return new Date(a.date) - new Date(b.date);
      } else {
        return;
      }
    });

    const groupBy = new Map();
    const arr3 = [...sorted];
    arr3?.map((item, i) => {
      const month = new Date(item?.date).getMonth();
      if (groupBy.has(month)) {
        groupBy.get(month).push(item);
      } else {
        groupBy.set(month, [item]);
      }
    });

    const arr4 = [...groupBy];
    const final = arr4.map((item) => item[1]);

    setDeadlinesList(final);
  }, [deadlines]);

  useEffect(() => {
    const filteredDates = deadlinesList?.flat().map((item) => item?.date);
    const highlighted = filteredDates?.map((date) => new Date(date));
    setHighlightedDates(highlighted);
  }, [deadlinesList]);

  const closeModal = () => {
    setShowDeadlineModal(false);
  };

  const handleDayClick = (day) => {
    const selectedCells = document.querySelectorAll(".rdp-day_selected");
    const cellsArr = Array.from(selectedCells);
    const clickedCell = cellsArr.filter(
      (item) => item.innerText === moment(day).format("D")
    );

    if (clickedCell && clickedCell.length > 0) {
      const top = clickedCell[0].offsetTop;
      setTopOffset(top);
    }

    const selectedDay = moment(day).format("YYYY-MM-DD");
    const arr = deadlinesList
      ?.flat()
      .filter((item) => item.date === selectedDay);
    setClickedDateData(arr);
    setShowClickedDateDeadline(!showClickedDateDeadline);
  };

  return (
    <>
      {showDeadlineModal && (
        <DeadlinePreviewModal
          show={showDeadlineModal}
          closeModal={closeModal}
          previewDeadline={previewDeadline}
        />
      )}
      {!isSidebar ? (
        <div>
          {deadlines?.display_header ? (
            sidebars && Object.entries(sidebars).length > 0 ? (
              <TitleHeader title={title} noMarginTop />
            ) : (
              <Title title={title} />
            )
          ) : null}
          <div
            className={`${
              sidebars && Object.entries(sidebars).length === 0
                ? "container"
                : "container p-0"
            }`}
          >
            {deadlines?.group_by_month === 0 ? (
              <div className="d-flex flex-wrap pt-3">
                {filteredDeadlines && filteredDeadlines.length > 0 ? (
                  filteredDeadlines.map((deadline, i) => {
                    return (
                      <div
                        className={`cursor-pointer ${
                          sidebars && Object.entries(sidebars).length === 0
                            ? "d-flex col-md-12 mb-4 px-4"
                            : "d-flex col-md-12 mb-4"
                        }`}
                        onClick={() => {
                          setPreviewDeadline(deadline);
                          setShowDeadlineModal(true);
                        }}
                      >
                        <div
                          className=""
                          style={{
                            maxHeight: "70px",
                            maxWidth: "58px",
                          }}
                        >
                          <div
                            className=" text-white px-3 d-flex justify-content-center"
                            style={{
                              backgroundColor: "var(--accent-color)",
                              borderRadius: "4px 4px 0 0",
                            }}
                          >
                            <span className="fw-bold">
                              {deadline?.date
                                ? moment(deadline?.date).format("MMM")
                                : "-"}
                            </span>
                          </div>
                          <div
                            className="d-flex flex-column align-items-center border border-top-0"
                            style={{
                              borderRadius: "0 0 4px 4px",
                              backgroundColor: "#f8f8f8",
                            }}
                          >
                            <span className="fw-bold">
                              {deadline?.date
                                ? moment(deadline?.date).format("DD")
                                : "-"}
                            </span>
                            <span style={{ fontSize: "10px" }}>
                              {deadline?.date
                                ? moment(deadline?.date).format("YYYY")
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="px-4 d-flex flex-column w-100">
                          <h3 className="deadline_title">{deadline?.title}</h3>
                          {deadline?.description ? (
                            <div className="deadline_description">
                              {convert(deadline?.description)}
                            </div>
                          ) : null}

                          {/* <div>
                            <span
                              className="mt-2 cursor-pointer"
                              style={{
                                fontSize: "13px",
                                color: "var(--accent-color)",
                                paddingRight: "2px",
                              }}
                            >
                              {intl.formatMessage({
                                id: "DEADLINE_CONTINUE_READING_DAEDLINE",
                              })}
                            </span>
                            <AiFillEye
                              className="cursor-pointer"
                              style={{
                                fontSize: "12px",
                                color: "var(--accent-color)",
                              }}
                            />
                          </div> */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <p className="text-center py-4">
                      {intl.formatMessage({ id: "NO_DATA" })}
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {deadlinesList && deadlinesList.length > 0 ? (
                  deadlinesList.map((item, i) => {
                    return (
                      <>
                        <div className="d-flex flex-wrap pt-3">
                          {item.map((deadline, i) => {
                            return (
                              <>
                                <div
                                  className={`cursor-pointer ${
                                    sidebars &&
                                    Object.entries(sidebars).length === 0
                                      ? "d-flex col-md-12 mb-4 px-4"
                                      : "d-flex col-md-12 mb-4"
                                  }`}
                                  onClick={() => {
                                    setPreviewDeadline(deadline);
                                    setShowDeadlineModal(true);
                                  }}
                                >
                                  <div
                                    className=""
                                    style={{
                                      maxHeight: "70px",
                                      maxWidth: "58px",
                                    }}
                                  >
                                    <div
                                      className="text-white px-3 d-flex justify-content-center"
                                      style={{
                                        backgroundColor: "var(--accent-color)",
                                        borderRadius: "4px 4px 0 0",
                                      }}
                                    >
                                      <span className="fw-bold">
                                        {deadline?.date
                                          ? moment(deadline?.date).format("MMM")
                                          : "-"}
                                      </span>
                                    </div>
                                    <div
                                      className="d-flex flex-column align-items-center border border-top-0"
                                      style={{
                                        borderRadius: "0 0 4px 4px",
                                        backgroundColor: "#f8f8f8",
                                      }}
                                    >
                                      <span className="fw-bold">
                                        {deadline?.date
                                          ? moment(deadline?.date).format("DD")
                                          : "-"}
                                      </span>
                                      <span style={{ fontSize: "10px" }}>
                                        {deadline?.date
                                          ? moment(deadline?.date).format(
                                              "YYYY"
                                            )
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="px-4 d-flex flex-column w-100">
                                    <h3 className="deadline_title">
                                      {deadline?.title}
                                    </h3>
                                    {deadline?.description ? (
                                      <div className="deadline_description">
                                        {convert(deadline?.description)}
                                      </div>
                                    ) : null}
                                    {/* <div>
                                      <span
                                        className="mt-2 cursor-pointer"
                                        style={{
                                          fontSize: "13px",
                                          color: "var(--accent-color)",
                                          paddingRight: "2px",
                                        }}
                                      >
                                        {intl.formatMessage({
                                          id: "DEADLINE_CONTINUE_READING_DAEDLINE",
                                        })}
                                      </span>
                                      <AiFillEye
                                        className="cursor-pointer"
                                        style={{
                                          fontSize: "12px",
                                          color: "var(--accent-color)",
                                          marginTop: "1px",
                                        }}
                                      />
                                    </div> */}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>

                        {i === deadlinesList?.length - 1 ? null : (
                          <div
                            className="mb-3"
                            style={{
                              height: "2px",
                              backgroundColor: "#eeeeee",
                            }}
                          ></div>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div>
                    <p className="text-center py-4">
                      {intl.formatMessage({ id: "NO_DATA" })}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="deadlines-wrapper  mb-3">
          {Boolean(details?.display_header) ? (
            <h4 className="border-section">{details?.title}</h4>
          ) : null}
          <div className="position-relative" id="calenderParent">
            {showClickedDateDeadline && clickedDateData?.length > 0 && (
              <div
                className="position-absolute shadow-sm pb-2"
                style={{
                  backgroundColor: "white",
                  zIndex: "3",
                  left: 0,
                  right: 0,
                  margin: "0 auto",
                  minWidth: "100px",
                  maxWidth: "300px",
                  top: topOffset + 36 + "px",
                  border: "1px solid var(--accent-color)",
                }}
              >
                {clickedDateData &&
                  clickedDateData.length > 0 &&
                  clickedDateData.map((item, i) => {
                    return (
                      <>
                        <div
                          className="px-2 py-3 d-flex flex-column align-items-center"
                          style={{
                            height: "fit-content",
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between pb-2 px-2 gap-2 w-100">
                            <div className="">
                              <span
                                className={`${
                                  item?.description
                                    ? "deadline_detail_title"
                                    : "deadline_detail_title_without_desc"
                                } fw-bold`}
                              >
                                {/* {intl.formatMessage({
                              id: "DEADLINE_CALENDER_MODAL_DATE_TITLE",
                            })}{" "}
                            {moment(item?.date).format("DD. MMM")} */}
                                {item.title}
                              </span>
                            </div>
                          </div>
                          <div
                            className={`d-flex gap-1 align-items-start justify-content-start w-100 px-2 ${
                              item?.description
                                ? "deadline_detail_description_div cursor-pointer"
                                : ""
                            }`}
                            onClick={() => {
                              if (item?.description) {
                                setPreviewDeadline(item);
                                setShowClickedDateDeadline(false);
                                setShowDeadlineModal(true);
                                setClickedDateData([]);
                              } else {
                                return;
                              }
                            }}
                          >
                            {item?.description ? (
                              <span>
                                <ImArrowRight2
                                  style={{
                                    fontSize: "12px",
                                    color: item?.description ? "" : "gray",
                                  }}
                                />
                              </span>
                            ) : null}
                            <span
                              className={`align-self-center deadline_detail_description ${
                                item?.description
                                  ? "deadline_detail_description_div"
                                  : ""
                              } `}
                              style={{ color: item?.description ? "" : "gray" }}
                            >
                              {item?.description
                                ? convert(item?.description)
                                : intl.formatMessage({
                                    id: "DEADLINE_CALENDER_DETAIL_NO_DESCRIPTION",
                                  })}
                            </span>
                          </div>
                        </div>
                        {clickedDateData.length > 1 &&
                          i !== clickedDateData.length - 1 && (
                            <div
                              className="mx-3"
                              style={{
                                height: "1px",
                                backgroundColor: "#eeeeee",
                              }}
                            ></div>
                          )}
                      </>
                    );
                  })}
              </div>
            )}
            <DayPicker
              selected={highlightedDates?.map((date) => new Date(date))}
              onDayClick={handleDayClick}
              mode="multiple"
              // defaultMonth={
              //   new Date(
              //     moment(highlightedDates?.[0]).format("YYYY"),
              //     moment(highlightedDates?.[0]).format("MM")
              //   )
              // }
              fromMonth={new Date(moment().format("YYYY"), 0)}
              toMonth={new Date(moment().format("YYYY"), 11)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DeadlinesListSection;
