import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { TbChevronDown } from "react-icons/tb";
import { useIntl } from "react-intl";
import Tag from "../Tag/Tag";
import "./CustomDropdown.scss";

export const CustomDropDown = ({
  id,
  displayValue = "",
  items,
  selectedItem,
  onSelectItem,
  displayFunc,
  searchPlaceholder,
  disabled,
  hideSearch,
  selectedItems,
  multiSelect,
  onMultiSelectBlur,
  addEmptySelect,
  defaultIndex,
  searchkey,
  matchKey = "id",
  loading,
  showCloseIcon,
  customDisplay,
  customDisplayFunc,
  size,
  isError,
}) => {
  // ref
  const inputEl = useRef(null);
  const timerRef = useRef(null);
  const firstRender = useRef(true);
  const intl = useIntl();

  // state
  const [displayItems, setDisplayItems] = useState(items);
  const [searchText, setSearchText] = useState("");
  // const [inputElFocus, setInputElFocus] = useState(false);

  useEffect(() => {
    const updatedItems = items.filter((item) => {
      let key = "";
      // @ts-ignore
      if (searchkey && item[searchkey]) {
        key = searchkey;
      } else if (item["name"]) {
        key = "name";
        // @ts-ignore
      } else if (item["title"]) {
        key = "title";
      }
      if (
        // @ts-ignore
        (item[key] || "")
          .toLocaleLowerCase()
          .includes((searchText || "").toLocaleLowerCase())
      )
        return true;
    });
    const finalItems = addEmptySelect
      ? [{ id: 0, name: "Please select" }, ...(updatedItems || [])]
      : updatedItems;
    setDisplayItems(finalItems);
  }, [items, searchText]);

  useEffect(() => {
    typeof defaultIndex === "number" &&
      defaultIndex > -1 &&
      onSelectItem?.(displayItems[defaultIndex]);
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    onMultiSelectBlur?.();
  }, [selectedItems]);

  const onSearchTextChange = (text) => {
    setSearchText((text || "").trim());
  };

  const style1 =
    size === "small" ? { minHeight: "42px" } : { minHeight: "48px" };

  const style2 = isError
    ? { border: "1px solid var(--error-alert)" }
    : { border: "1px solid #919196" };

  const style3 = disabled ? { backgroundColor: "#eff2f5" } : {};
  return (
    <div className="dropdown px-0">
      <div
        id={`${id}-container`}
        className={clsx(
          "form-control-custom form-control  d-flex flex-row justify-content-between align-items-center py-2 ",
          disabled ? "pe-none" : "form-control-solid",
          size === "small" ? "form-control-md" : "form-control-lg"
          // isError ? 'error-input' : '',
        )}
        style={{ ...style1, ...style2, ...style3 }}
        data-bs-toggle="dropdown"
        aria-expanded="false"
        role="button"
        onClick={() => {
          // @ts-ignore
          inputEl?.current?.focus();
        }}
        // style={disabled ? {backgroundColor: '#eff2f5', borderRadius:'0px', height:'42px'} : {borderRadius:'0px', height:'42px'}}
      >
        <div className="d-flex flex-row align-ietms-center flex-wrap">
          {multiSelect ? (
            <>
              {!showCloseIcon &&
                selectedItems?.map((item, i) => {
                  return (
                    <div className="mt-2 " key={i}>
                      <Tag
                        text={item.name}
                        onRemove={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onSelectItem?.(item);
                          // @ts-ignore
                          inputEl?.current?.focus();
                        }}
                      />
                    </div>
                  );
                })}
              <textarea
                id={`${id}-textarea`}
                ref={inputEl}
                className="mt-1"
                aria-label="Search"
                aria-describedby="select2-h5wl-container"
                // @ts-ignore
                // placeholder={selectedItems?.length > 0 ? '' : 'select an option'}
                style={{
                  // @ts-ignore
                  width: selectedItems?.length > 0 ? "1em" : "100%",
                  border: "none",
                  backgroundColor: "transparent",
                  resize: "none",
                  WebkitTapHighlightColor: "transparent",
                  outline: "none",
                }}
                rows={1}
                onFocus={() => {
                  // setInputElFocus(true);
                  // @ts-ignore
                  timerRef.current && clearTimeout(timerRef.current);
                }}
                // onBlur={() => {
                //   setInputElFocus(false)
                //   // @ts-ignore
                //   timerRef.current = timer()
                // }}
                value={""}
                onChange={() => {}}
              ></textarea>
            </>
          ) : (
            <span>{displayValue}</span>
          )}
        </div>
        {loading ? (
          <div
            className="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span className="sr-only">
              {intl.formatMessage({ id: "USERS_DISABLING_LOADING_MESSAGE" })}
            </span>
          </div>
        ) : (
          // <i className='bi bi-chevron-down text-dark'></i>
          <TbChevronDown className="dropdown-icon position-relative ms-1 text-dark" />
        )}
      </div>

      <div
        className={` dropdown-options-custom dropdown-menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7   ${
          size === "small" ? `py-3` : `py-4`
        }`}
        aria-labelledby={id}
        style={{
          paddingBottom: "0px !important",
          width: "100%",
          transform: "translate(0px,44px)",
        }}
        id={id}
      >
        {!hideSearch && (
          <div className="px-2 mb-3">
            <input
              type="search"
              placeholder={searchPlaceholder}
              autoComplete="off"
              className={clsx("form-control")}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </div>
        )}

        {multiSelect ? (
          <div style={{ maxHeight: "200px" }} id="dropdown-custom">
            {displayItems && displayItems.length > 0 ? (
              <>
                {(showCloseIcon ? displayItems.slice(0, 20) : displayItems).map(
                  (item, i) => {
                    const isSelected =
                      selectedItems?.findIndex((a) => a.id === item.id) !== -1;
                    return (
                      <div
                        className={clsx(
                          "px-4 py-3   fw-normal text-gray-700",
                          isSelected && !item.isGroupLabel
                            ? "bg-select-custom"
                            : "bg-hover-light-primary text-hover-primary",
                          item.isGroupLabel ? "pe-none" : ""
                        )}
                        onClick={() => {
                          if (!item.isGroupLabel) {
                            // @ts-ignore
                            inputEl?.current?.focus();
                            onSelectItem?.(item);
                          }
                        }}
                        key={i}
                        role="button"
                      >
                        <div
                          className={clsx(
                            item.isGroupLabel
                              ? "fw-bolder fs-6 mt-2"
                              : hideSearch
                              ? "fs-13px"
                              : "fs-7",
                            isSelected && !item.isGroupLabel ? "text-white" : ""
                          )}
                        >
                          {displayFunc(item)}
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <>
                <div className="px-4 py-2  text-gray-600 fw-normal fs-7 ">
                  <span>{"No results found"}</span>
                </div>
              </>
            )}
          </div>
        ) : (
          <div style={{ maxHeight: "200px" }} id="dropdown-custom">
            {displayItems && displayItems.length > 0 ? (
              <>
                {displayItems.map((item, i) => {
                  return (
                    <div
                      className={clsx(
                        "px-4 py-2  fw-normal text-gray-700",
                        // @ts-ignore
                        selectedItem?.[matchKey] === item[matchKey]
                          ? "bg-select-custom"
                          : "dropdown-item-custom"
                      )}
                      onClick={() => {
                        onSelectItem?.(item);
                      }}
                      key={i}
                      role="button"
                    >
                      {customDisplay ? (
                        customDisplayFunc?.(item)
                      ) : (
                        <span
                          className={clsx(
                            hideSearch ? "fs-13px" : "fs-7",
                            // @ts-ignore
                            selectedItem?.[matchKey] === item[matchKey]
                              ? "text-white"
                              : "",
                            "dropdown-option-cell"
                          )}
                        >
                          {displayFunc(item)}
                        </span>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="px-4 py-2  text-gray-600 fw-normal fs-7 ">
                  <span>
                    {intl.formatMessage({ id: "SHARED_COMPONENTS_NO_RESULTS" })}
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
