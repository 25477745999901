import moment from "moment";
import React from "react";
import { BsStars } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";
import { useIntl } from "react-intl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocal } from "../../../App";
import noImage from "../../../assets/noImage.jpg";
import AuthorImage from "../../../sharedComponents/AuthorImage/AuthorImage";
import {
  ErrorAlert,
  SuccessAlert,
} from "../../../sharedComponents/Toast/Toasts";
import { formatDate, useCustomMediaQuery } from "../../../utils";
import {
  joinPublicCommunity,
  requestPrivateCommunity,
} from "../Redux/CommunitiesAPI";
import "./Communities.scss";

const CommunityCard = ({ details, getCommunitiesAPI }) => {
  const { resolution } = useCustomMediaQuery();

  const local = useLocal();
  const intl = useIntl();

  const setPostMediaSource = () => {
    if (
      details.cover_picture_resolutions &&
      details.cover_picture_resolutions.length > 0
    ) {
      const source = details.cover_picture_resolutions.filter((element) => {
        return element?.resolution === resolution;
      });
      return source[0].file_url;
    }
    return details.cover_picture_url ? details.cover_picture_url : noImage;
  };

  const joinedCommunities = useSelector(
    (state) => state.communities.joinedCommunities
  );
  const userInfo = useSelector(
    (state) => state.auth?.user?.personalInformation
  );

  const isJoinedCommunity = () => {
    const temp = joinedCommunities?.find((ele) => {
      return ele.id === details.id;
    });

    return temp;
  };

  const isRequested = () => {
    const temp = joinedCommunities?.find((ele) => {
      return ele.id === details.id && ele.contact_status === "requested";
    });

    return temp;
  };

  const joinCommunity = () => {
    if (details?.privacy === "private") {
      requestPrivateCommunity(details?.id)
        .then(() => {
          SuccessAlert(
            intl.formatMessage({
              id: "COMMUNITIES_JOIN_REQUEST_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          ErrorAlert(
            intl.formatMessage({
              id: "COMMUNITIES_JOIN_REQUEST_ERROR_MESSAGE",
            })
          );
        })
        .finally(() => {
          getCommunitiesAPI();
        });
    } else {
      joinPublicCommunity(details?.id)
        .then(() => {
          SuccessAlert(
            intl.formatMessage({
              id: "COMMUNITIES_JOIN_SUCCESS_MESSAGE",
            })
          );
        })
        .catch(() => {
          ErrorAlert(
            intl.formatMessage({
              id: "COMMUNITIES_JOIN_ERROR_MESSAGE",
            })
          );
        })
        .finally(() => {
          getCommunitiesAPI();
        });
    }
  };

  return (
    <div className="row">
      <div className="col-lg-3 col-md-3">
        <div className="img-container rounded-1 event-image">
          <LazyLoadImage
            src={setPostMediaSource()}
            effect="blur"
            alt="image"
            wrapperClassName="img img-fluid w-100 image-responsive"
            placeholder={<span className="img-placeholder w-100" />}
          />
        </div>
      </div>
      <div className="col-lg-7 col-md-6">
        <div className="d-flex flex-row mt-2 ms-2">
          <div className="d-flex flex-row me-3">
            <AuthorImage
              photoUrl={null}
              firstname={details?.creator_name}
              zIndex={1}
            />
            <div className="d-flex align-items-center ms-1">
              {details?.creator_name}
            </div>
          </div>
          <div className="d-none d-md-flex">
            <span className="d-flex align-self-start fw-bolder me-3">.</span>
            <div className="d-flex flex-row">
              <div
                className="d-flex align-items-center"
                style={{ fontSize: "16px" }}
              >
                {details.created_at
                  ? local && local === "en"
                    ? formatDate(details.created_at)
                    : moment(details.created_at).format("DD.MM.yyyy")
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="article-two-title">
          <div>
            <h5>
              <Link
                to={`/community/${details?.id}`}
                style={{ fontSize: "22px" }}
                className={`mt-2 fw-bold ${
                  details?.status_id === 2 ? "" : "pointer-events-none"
                }`}
              >
                {details?.name}
              </Link>
            </h5>
          </div>
          <p
            className="d-lg-webkit-box mb-0"
            dangerouslySetInnerHTML={{ __html: details?.description }}
          ></p>
        </div>
        <div className="d-flex flex-row mt-2 ms-1">
          <div className="d-flex flex-row">
            <div className="d-flex flex-row me-3">
              <HiUserGroup className="group-icon" />
              <div
                className="d-flex align-items-center ms-2 mt-1"
                style={{ fontSize: "16px" }}
              >
                {`${details?.contacts?.length} ${intl.formatMessage({
                  id: "COMMUNITIES_MEMBERS",
                })}`}
              </div>
            </div>
            <span className="d-flex align-self-start fw-bolder me-3">.</span>
            <div className="d-flex flex-row align-items-center">
              <BsStars className="star-icon" />
              <div
                className="d-flex align-items-center ms-1"
                style={{ fontSize: "16px" }}
              >
                {details?.privacy === "private"
                  ? intl.formatMessage({
                      id: "COMMUNITIES_PRIVATE",
                    })
                  : intl.formatMessage({
                      id: "COMMUNITIES_PUBLIC",
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-2 col-md-3 d-flex mt-2 mt-md-0 justify-content-md-center justify-content-sm-start align-items-center">
        <button
          className="btn btn-custom"
          style={{ minWidth: "100px" }}
          onClick={() => joinCommunity()}
          disabled={isJoinedCommunity()}
        >
          {isRequested()
            ? intl.formatMessage({
                id: "COMMUNITIES_REQUESTED",
              })
            : isJoinedCommunity()
            ? intl.formatMessage({
                id: "COMMUNITIES_JOINED",
              })
            : intl.formatMessage({
                id: "COMMUNITIES_JOIN",
              })}
        </button>
      </div>
    </div>
  );
};

export default CommunityCard;
