import React from "react";
// import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useCustomMediaQuery } from "../../utils";
import ButtonComponent from "../Button/Button";
import "./style.scss";

const Banner = ({ details, sidebars, pageName }) => {
  //${sidebars && Object.entries(sidebars).length>0 ? 'pb-5' :'pb-3'}
  // const sections = useSelector(
  //   (state) => state?.pageDetails?.pages?.[pageName]?.sections
  // );

  const smallScreen = useMediaQuery({ maxWidth: 575 });
  const mediumScreen = useMediaQuery({ minWidth: 576, maxWidth: 767 });
  const { resolution } = useCustomMediaQuery();
  const titleStyle = {
    color: details.title_text_color,
    backgroundColor: details.title_layer_color,
    opacity: `${details.title_layer_transparency}%`,
    fontSize: smallScreen
      ? `${details.title_size_small}px`
      : mediumScreen
      ? `${details.title_size_medium}px`
      : `${details.title_size_large}px`,
  };

  const subTitleStyle = {
    fontSize: smallScreen
      ? `${details.sub_title_size_small}px`
      : mediumScreen
      ? `${details.sub_title_size_medium}px`
      : `${details.sub_title_size_large}px`,
    color: details.sub_title_text_color,
    backgroundColor: details.sub_title_layer_color,
    opacity: `${details.sub_title_layer_transparency}%`,
    marginTop: "20px",
  };
  const setImageMediaResource = () => {
    if (
      details?.background_image_resolutions &&
      details?.background_image_resolutions.length > 0
    ) {
      const source = details?.background_image_resolutions.filter(
        (element) => element?.resolution === resolution
      );
      return source[0].file_url;
    }
    return details?.background_image_url;
  };

  return (
    <>
      <div
        className={`d-flex justify-content-center align-items-center mb-4${
          !!details.backgroundColor ? "bg-light" : ""
        } ${
          sidebars && Object.entries(sidebars).length > 0
            ? "banner-one"
            : "banner-two"
        } pb-2`}
        style={{ backgroundColor: details.background_color }}
      >
        {details.background_image_url !== null ? (
          <img className="d-block w-100 " src={setImageMediaResource()} alt=''/>
        ) : null}

        <div className="banner-caption text-center">
          <h1 className="text-capitalize" style={titleStyle}>
            {details.title}
          </h1>
          <p className="text-capitalize" style={subTitleStyle}>
            {details.sub_title}
          </p>
          {details.destination_button > 0 ? (
            <ButtonComponent
              className="d-none d-sm-none d-md-block mt-3"
              text={details.destination_button_name}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Banner;
