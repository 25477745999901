import { createSlice } from "@reduxjs/toolkit";

const navigationMenuSlice = createSlice({
  name: "navigationMenu",
  initialState: { navigationMenu: {} },
  reducers: {
    getNavigationMenu() {},
    setNavigationMenu(state, action) {
      const navigationMenu = action.payload;
      return { ...state, navigationMenu };
    },
    setDefaultPage(state, action) {
      return { ...state, defaultPage: action.payload };
    },
  },
});

export const { getNavigationMenu, setNavigationMenu, setDefaultPage } =
  navigationMenuSlice.actions;

export default navigationMenuSlice.reducer;
