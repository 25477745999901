import React from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router";
import "./style.scss";
const ButtonComponent = ({ text, ...props }) => {
  const navigate = useNavigate();
  const leavePage = (page) => navigate(page.toLowerCase());
  return (
    <Button
      id="button-custom"
      {...props}
      onClick={() => (text ? leavePage(`../${text}`) : navigate("/"))}
    >
      {text}
    </Button>
  );
};

export default ButtonComponent;
//()=>navigate(`../${text}`)
