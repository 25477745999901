import React from "react";

const InputLabel = ({ text, required }) => {
  return (
    <label
      className={`form-label ${
        required ? "required" : ""
      } px-0 fw-bolder mb-1 fs-6 text-dark`}
      style={{ cursor: "inherit" }}
    >
      {/* {intl.formatMessage({id: 'SIGN_IN_EMAIL'})} */}
      {text}
    </label>
  );
};

export default InputLabel;
