import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import {
  getMasterDataByType,
  getMasterDataDetails,
} from "../../Redux/MasterDataReducer";
import Loader from "../../sharedComponents/Loader";
import AllPostsList from "./AllPostsList";
import SideBar from "./SideBar";

const AllPosts = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const masterData = useSelector((state) => state.masterData);
  const [sidebarPage, setsidebarPage] = useState("");
  const sideBars = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.website_settings
        ?.static_pages_sidebars
  );
  const [sidebar, setSidebar] = useState(false);
  const [sections, setSections] = useState([]);
  const { pageName, type, id } = location?.state;
  const masterDataDetails =
    masterData?.[pageName || params?.pageName]?.[
      type
        ? type
        : window.location.pathname.contains("groups")
        ? "groups"
        : window.location.pathname.contains("categories")
        ? "categories"
        : "tags"
    ]?.masterDataDetails?.[id || params?.id];

  useEffect(() => {
    if (
      !masterData?.[pageName || params?.pageName]?.[
        type || window.location.pathname.contains("groups")
          ? "groups"
          : window.location.pathname.contains("categories")
          ? "categories"
          : "tags"
      ]?.masterDataDetails?.[id || params?.id]
    ) {
      dispatch(
        getMasterDataDetails({
          masterDataType: window.location.pathname?.includes("categories")
            ? "categories"
            : window.location.pathname?.includes("groups")
            ? "groups"
            : "tags",
          id: params.id,
          pageName: pageName || params?.pageName,
        })
      );
    }
    if (
      !masterData?.[pageName || params?.pageName]?.[
        type || window.location.pathname.includes("groups")
          ? "groups"
          : window.location.pathname.includes("categories")
          ? "categories"
          : "tags"
      ]?.masterData
    ) {
      dispatch(
        getMasterDataByType({
          listType:
            type === "groups" || window.location.pathname.includes("groups")
              ? "group-list"
              : type === "categories" ||
                window.location.pathname.includes("categories")
              ? "category-list"
              : "tag-list",
          pageName,
        })
      );
    }
    //eslint-disable-next-line
  }, []);

  // useEffect(()=>{

  // },[sideBars?.sections?.length])
  return (
    <div className="container">
      {masterData.isLoading === true ? (
        <Loader />
      ) : (
        <div className="row" style={{ marginTop: "14px" }}>
          <div
            className={`${
              sideBars?.sections && sideBars?.sections.length > 0
                ? "col-lg-8"
                : "col-lg-12"
            }`}
            style={{
              paddingRight:
                sideBars?.sections && sideBars?.sections.length > 0
                  ? "20px"
                  : "0px",
            }}
          >
            {masterDataDetails?.postsDetails &&
            masterDataDetails?.postsDetails.length > 0 ? (
              <AllPostsList
                pageName={pageName || params?.pageName}
                type={
                  type ||
                  (window.location.pathname?.includes("groups")
                    ? "groups"
                    : window.location.pathname?.includes("categories")
                    ? "categories"
                    : "tags")
                }
                id={id || params?.id}
                flag="postsDetails"
                sidebar={sidebar}
              />
            ) : null}
            {masterDataDetails?.events_details &&
            masterDataDetails?.events_details.length > 0 ? (
              <AllPostsList
                pageName={pageName || params?.pageName}
                type={
                  type || window.location.pathname?.includes("groups")
                    ? "groups"
                    : window.location.pathname?.includes("categories")
                    ? "categories"
                    : "tags"
                }
                id={id || params?.id}
                flag="events_details"
                sidebar={sidebar}
              />
            ) : null}
          </div>

          {sections.length > 0 ? (
            <div
              className="col-lg-4"
              style={{
                paddingLeft: "20px",
              }}
            >
              <SideBar
                sections={sections}
                pageName={sidebarPage}
                sidebar={sidebar}
                type="staticPage"
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default AllPosts;
