import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import noImage from "../../assets/noLogo.png";
import "./style.scss";

function LoginModal({ children, closeModal, show }) {
  const generalDetails = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  return (
    <Modal
      show={show}
      centered
      keyboard={false}
      // onHide={closeModal}
      //   dialogClassName='w-90'
      dialogClassName="main-modal"
      // size='sm'
      // className=''
      // style={{width:'400px'}}
      // contentClassName={loading ? 'pe-none' : ''}
      contentClassName="main-modal-dialog"
    >
      <Modal.Header
        className="justify-content-center border-bottom-0 pb-0"
        closeButton
        onHide={closeModal}
      ></Modal.Header>
      <Modal.Body className="pt-0">
        <figure className="py-2 text-center my-auto d-lg-block">
          <img
            src={generalDetails?.logo_url ? generalDetails?.logo_url : noImage}
            alt="header-logo"
            className="img-fluid logo"
            style={{
              height:
                generalDetails.logo_size === "large"
                  ? "120px"
                  : generalDetails.logo_size === "short"
                  ? "60px"
                  : "90px",
              width:
                generalDetails.logo_size === "large"
                  ? "213.3px"
                  : generalDetails.logo_size === "short"
                  ? "107px"
                  : "160px",
            }}
          />
        </figure>
        {children}
      </Modal.Body>

      {/* <Modal.Footer className='justify-content-center'>
        <Button variant='secondary' onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default LoginModal;
