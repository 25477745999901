import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlineClose } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import DropDownItem from "./DropDownItem";
import NavBarModal from "./NavBarModal";
const NavBar = ({ generalDetails }) => {
  const navigate = useNavigate();
  const navigationMenu = useSelector(
    (state) => state.navigationMenu.navigationMenu
  );
  const [nav, setnav] = useState("");
  const [sticky, setsticky] = useState("");
  const [fixed, setfixed] = useState(false);
  // const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [generalSearch, setGeneralSearch] = useState(false);
  const [categoryText, setCategoryText] = useState("Posts");
  const [showError, setShowError] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const intl = useIntl();

  // const posts = useSelector((state) => state.posts.posts);
  // const postId = location.state?.postId;
  // const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  // let navbar = document.getElementById("main-nav");
  // let navSticky = navbar ? navbar.offsetTop : 0;
  useEffect(() => {
    setnav(document.getElementById("main-nav"));
    setsticky(nav?.offsetTop);
    window.addEventListener("scroll", handleSticky);
    return () => window.removeEventListener("scroll", handleSticky);
  }, [nav, sticky]);

  // useEffect(() => {
  //   window.onscroll = () => {
  //     stickyNavbar();
  //   };
  //   return () => window.removeEventListener("scroll", stickyNavbar);
  // }, [navSticky]);

  const onSearchTextChange = (text) => {
    setSearchText(text || "");
  };

  useEffect(() => {
    document.querySelectorAll(".dropdown-menu a").forEach(function (element) {
      element.addEventListener("click", handleSubMenu);
    });
    return () =>
      document
        .querySelectorAll(".dropdown-menu a")
        .forEach((ele) => ele.removeEventListener("click", handleSubMenu));
  }, []);

  const handleSubMenu = (e) => {
    let nextEl = this.nextElementSibling;
    if (nextEl && nextEl?.classList?.contains("submenu")) {
      e.preventDefault();
      if (nextEl.style.display == "block") {
        nextEl.style.display = "none";
      } else {
        nextEl.style.display = "block";
      }
    }
  };

  const handleSticky = () => {
    let navbar = document.getElementById("main-nav");
    if (window.pageYOffset > sticky) {
      // setfixed(true);

      navbar.classList.add("fixed-top");
    } else {
      // setfixed(false);
      navbar.classList.remove("fixed-top");
    }
  };
  const leavePage = (page) => {
    navigate(`/${page.toLowerCase()}`);
  };

  const goToHome = () => {
    navigate("/");
  };

  // const stickyNavbar = () => {
  //   if (window.pageYOffset >= navSticky) {
  //     navbar.classList.add("fixed-top");
  //   } else {
  //     navbar.classList.remove("fixed-top");
  //   }
  // };

  const handleGeneralSearch = () => {
    if (searchText && searchText.length >= 3) {
      navigate(`/general-search/${searchText}/${categoryText}/1`);
      setShowError(false);
      setGeneralSearch(false);
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      <div
        id="main-nav"
        className={`navigation-wrap navigation-shadow  ${
          fixed ? "fixed-top" : ""
        }`}
      >
        <nav className="navbar navbar-expand-lg navbar-soft">
          <div className="container-fluid">
            <div className="d-flex logo-spacing">
              <img
                onClick={goToHome}
                src={generalDetails?.logo_url}
                alt="header-logo"
                className="navbar-logo"
                style={{ height: "40px" }}
              />
              <button
                className="navbar-toggler"
                type="button"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={handleShow}
              >
                <span className="navbar-toggler-icon"></span>
                {/* <img className="navbar-toggler-icon" src={generalDetails?.logo_url} /> */}
              </button>
            </div>

            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                {!!navigationMenu.length
                  ? navigationMenu.map((item) => {
                      return !item.children.length > 0 ? (
                        <li key={item?.id} className="nav-item active">
                          <button
                            className="nav-link active"
                            role="button"
                            onClick={() => {
                              if (item.page_type === "link") {
                                if (item.link_url_opening === 0) {
                                  window.open(item.link_url, "_self");
                                } else {
                                  window.open(
                                    item.link_url,
                                    "_blank",
                                    "noreferrer"
                                  );
                                }
                              } else {
                                leavePage(item.seo_permalink);
                              }
                            }}
                          >
                            {item.name}
                          </button>
                        </li>
                      ) : (
                        <li key={item.id} className="nav-item active dropdown">
                          <button
                            className="nav-link active dropdown-toggle"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                              if (item.page_type === "link") {
                                if (item.link_url_opening === 0) {
                                  window.open(item.link_url, "_self");
                                } else {
                                  window.open(
                                    item.link_url,
                                    "_blank",
                                    "noreferrer"
                                  );
                                }
                              } else {
                                leavePage(item.seo_permalink);
                              }
                            }}
                          >
                            {item.name}
                          </button>
                          {item.children.length > 0 ? (
                            <ul
                              className="dropdown-menu main-dropdown animate animatedFadeInUp fadeInUp border-0"
                              aria-labelledby="navbarDropdown"
                              style={{
                                boxShadow:
                                  "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                              }}
                            >
                              {item.children.map((i) => {
                                return (
                                  <DropDownItem
                                    key={i.id}
                                    id={i.id}
                                    name={i.name}
                                    link={i.seo_permalink}
                                    className={
                                      i.children.length > 0
                                        ? "icon-arrow arrow-align"
                                        : "d-block"
                                    }
                                    item={i}
                                  >
                                    {i.children.length > 0 ? (
                                      <ul
                                        className="submenu dropdown-menu  animate animatedFadeInUp fadeInUp border-0"
                                        style={{
                                          boxShadow:
                                            "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                                        }}
                                      >
                                        {i.children.map((entry) => (
                                          <DropDownItem
                                            key={entry.id}
                                            id={entry.id}
                                            name={entry.name}
                                            link={entry.seo_permalink}
                                            item={entry}
                                          ></DropDownItem>
                                        ))}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </DropDownItem>
                                );
                              })}
                            </ul>
                          ) : (
                            ""
                          )}
                        </li>
                      );
                    })
                  : ""}
                <ul
                  className="mb-2 mb-lg-0 d-flex align-items-center generalSearch"
                  style={{ margin: 0 }}
                  onClick={() => {
                    setGeneralSearch(!generalSearch);
                  }}
                >
                  <BiSearchAlt2
                    style={{
                      fontSize: "20px",
                      // marginTop: "12px",
                    }}
                    type="button"
                  />
                </ul>
              </ul>
              {/* {posts &&
                posts?.[postId] &&
                posts?.[postId].products &&
                Object.keys(posts?.[postId].products).length > 0 && (
                  <ul className="mb-2 mb-lg-0" style={{ marginRight: 0 }}>
                    <button className="buyButton-title">Buy</button>
                  </ul>
                )} */}
            </div>
          </div>
        </nav>
        <div className="position-relative">
          {generalSearch ? (
            <div
              className={`navigation-wrap navigation-shadow w-100`}
              style={{
                position: "absolute",
                zIndex: "1030",
              }}
            >
              <div className="position-absolute generalSearchPopup">
                <Card>
                  <Card.Header className="generalSearchCloseIcon">
                    <AiOutlineClose
                      style={{
                        fontSize: "20px",
                        // marginTop: "12px",
                      }}
                      onClick={() => {
                        setGeneralSearch(false);
                        setSearchText("");
                      }}
                      type="button"
                    />
                  </Card.Header>
                  <Card.Body>
                    <div className="d-flex align-items-center ">
                      <input
                        type="text"
                        className="form-control form-control-solid ps-15 mx-2"
                        placeholder={intl.formatMessage({
                          id: "LABEL_ENTER_SEARCH_TEXT",
                        })}
                        value={searchText}
                        onChange={(e) => {
                          if (e.target.value.length >= 3) {
                            setShowError(false);
                          }
                          onSearchTextChange(e.target.value);
                        }}
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          width: "300px",
                        }}
                      />
                      <div className="me-2">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            {categoryText}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setCategoryText("Posts");
                              }}
                            >
                              {intl.formatMessage({
                                id: "GENERAL_SEARCH_DROPDOWN_POSTS_VALUE",
                              })}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setCategoryText("Events");
                              }}
                            >
                              {intl.formatMessage({
                                id: "GENERAL_SEARCH_DROPDOWN_EVENTS_VALUE",
                              })}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setCategoryText("Courses");
                              }}
                            >
                              {intl.formatMessage({
                                id: "GENERAL_SEARCH_DROPDOWN_COURSES_VALUE",
                              })}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <div
                        className="p-2  d-flex  align-items-center popupSearch"
                        onClick={() => {
                          handleGeneralSearch();
                        }}
                        style={{
                          borderRadius: "5px",
                        }}
                      >
                        <BiSearchAlt2
                          style={{
                            fontSize: "21px",
                            color: "#ffff",
                          }}
                          type="button"
                        />
                      </div>
                    </div>
                    {showError && (
                      <div
                        className="d-flex align-items-center text-danger mx-2 "
                        style={{ fontSize: "13px" }}
                      >
                        {intl.formatMessage({
                          id: "GENERAL_SEARCH_ERROR_MESSAGE",
                        })}
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <NavBarModal show={show} handleClose={handleClose} />
      {/* general search section */}
    </>
  );
};

export default NavBar;
