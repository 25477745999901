import { Player } from "@lottiefiles/react-lottie-player";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { activateAccount } from "../../API/Api";
import { useLang } from "../../i18n/Metronici18n";
import "./ActivationReminder.scss";

const ActivationReminderModal = ({
  show,
  closeModal,
  setIsSuccess,
  setShowAlert,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const lang = useLang();
  const info = useSelector((state) => state.auth?.user?.personalInformation);
  const [activating, setActivating] = useState(false);
  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="sm"
      dialogClassName={"activation-modal"}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="border-0" />
      <Modal.Body className="d-flex flex-column mx-4 justify-content-center">
        <div className="d-flex justify-content-center">
          <Player
            autoplay
            loop={true}
            src="https://assets3.lottiefiles.com/packages/lf20_jvki4wd1.json"
            style={{ height: "90px", width: "90px" }}
          ></Player>
        </div>
        <div className="justify-content-center d-flex">
          <h4 className="">{intl.formatMessage({ id: "ACTIVATION_TITLE" })}</h4>
        </div>
        <div className=" text-center">
          {intl.formatMessage({ id: "ACTIVATION_SUB_TITLE" })}
        </div>
        <div className="d-flex justify-content-center align-items-center mt-3 position-relative">
          <div className="">
            <button
              type="submit"
              className="btn btn-success"
              style={{ minWidth: "120px", fontSize: "20px" }}
              onClick={() => {
                setActivating(true);
                activateAccount(info?.id)
                  .then(() => {
                    setIsSuccess(true);
                    setShowAlert(true);
                  })
                  .catch(() => {
                    setIsSuccess(false);
                    setShowAlert(true);
                  })
                  .finally(() => {
                    setActivating(false);
                    closeModal();
                  });
              }}
            >
              {!activating &&
                intl.formatMessage({
                  id: "ACTIVATION_BUTTON",
                })}
              {activating && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({
                    id: "ACTIVATING_BUTTON",
                  })}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <span
              className="ms-2 cursor-pointer position-absolute text-decoration-underline"
              style={{
                top: "10px",
                right: lang === "en" ? "50px" : "0px",
                color: "var(--accent-color)",
              }}
              onClick={() => {
                closeModal();
              }}
            >
              {intl.formatMessage({
                id: "SKIP_BUTTON",
              })}
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ActivationReminderModal;
