import React, { useEffect, useState } from "react";
import { ModalBody } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { BiChevronRight } from "react-icons/bi";
import { RiUserSettingsLine } from "react-icons/ri";
import { TbLogout } from "react-icons/tb";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Login from "../../components/LoginModal/components/login";
import { actions } from "../../Redux/AuthReducer";
import { dateFormat } from "../../utils";
import "./style.scss";
const NavBarModal = ({ show, handleClose }) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigationMenu = useSelector(
    (state) => state.navigationMenu.navigationMenu
  );
  const generalDetails = useSelector(
    (state) => state?.generalDetails?.generalDetails
  );
  const user_sign_In = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings?.user_sign_In
  );

  const userInfo = useSelector(
    (state) => state.auth?.user?.personalInformation
  );

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      if (newWidth > "992") handleClose();
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const logout = () => {
    navigate("/");
    handleClose();
    dispatch(actions.logout());
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };

  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const leavePage = (page) => {
    navigate(`/${page.toLowerCase()}`);
    handleClose();
  };
  const [showLogin, setShowLogin] = useState(false);
  const posts = useSelector((state) => state.posts.posts);
  const location = useLocation();
  const postId = location.state?.postId;
  const closeLoginModal = () => {
    setShowLogin(false);
  };
  const openLoginModal = () => {
    setShowLogin(true);
    handleClose();
  };
  return (
    <div>
      {showLogin && <Login show={showLogin} closeModal={closeLoginModal} />}
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={"navbar-modal"}
        id="navbarModalWidth"
      >
        <Modal.Header closeButton>
          {!accessToken && user_sign_In === "no" ? (
            <img src={generalDetails?.logo_url} style={{ height: "40px" }} />
          ) : null}
          {isAuthorized ? (
            <div className="d-flex align-items-center gap-1 mx-1">
              {userInfo?.username || userInfo?.firstname ? (
                // <div className=" mx-2 d-inline-flex">
                //   <img src={ukIcon} alt="profile-pic"/>
                // </div>
                <div
                  className=" d-inline-flex mx-2 profile-pic"
                  style={{ height: "50px", width: "50px" }}
                >
                  {`${userInfo?.firstname.toUpperCase()[0]}${
                    userInfo?.lastname.toUpperCase()[0]
                  }`}
                </div>
              ) : null}

              <div className="d-flex flex-column">
                <span className="fw-semibold">
                  {userInfo?.username || userInfo?.firstname}
                </span>
                <span
                  className="fw-light"
                  style={{ color: "gray", fontSize: "12px" }}
                >
                  {userInfo?.email}
                </span>
              </div>
              <RiUserSettingsLine
                onClick={() => {
                  navigate(`/account`);
                  handleClose();
                }}
                style={{ width: "25px", height: "25px", marginLeft: "10px" }}
              />
            </div>
          ) : null}
        </Modal.Header>
        <ModalBody style={{ overflowY: "auto" }}>
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            {!!navigationMenu.length
              ? navigationMenu.map((item) => {
                  return !item.children.length > 0 ? (
                    <li
                      key={item?.id}
                      className="nav-item active"
                      id="modal-nav-link"
                    >
                      <button
                        className="nav-link active"
                        role="button"
                        onClick={() => leavePage(item.seo_permalink)}
                      >
                        {item.name}
                      </button>
                    </li>
                  ) : (
                    <li
                      key={item.id}
                      className="nav-item active dropdown"
                      id="modal-nav-link"
                    >
                      <button
                        className="nav-link active"
                        style={{ textAlign: "left" }}
                        role="button"
                        onClick={() => leavePage(item.seo_permalink)}
                      >
                        {item.name}
                        {item.children.length > 0 ? (
                          <BiChevronRight
                            className="mx-1"
                            style={{ verticalAlign: "middle" }}
                          />
                        ) : null}
                      </button>
                      {item.children.length > 0 ? (
                        <ul className="list-unstyled modal-dropdown border-top border-bottom border-light  animate animatedFadeInUp fadeInUp">
                          {item.children.map((i) => {
                            return (
                              <li
                                key={i.id}
                                className="nav-item active mx-3"
                                id="modal-nav-link"
                              >
                                <button
                                  className="nav-link active"
                                  style={{ textAlign: "left" }}
                                  role="button"
                                  onClick={() => leavePage(i.seo_permalink)}
                                >
                                  {i.name}
                                  {i.children.length > 0 ? (
                                    <BiChevronRight
                                      className="mx-1"
                                      style={{ verticalAlign: "middle" }}
                                    />
                                  ) : null}
                                </button>
                                {i.children.length > 0 ? (
                                  <ul className="list-unstyled modal-dropdown-two border-top border-bottom border-light  animate animatedFadeInUp fadeInUp">
                                    {i.children.map((ele) => {
                                      return (
                                        <li
                                          key={ele.id}
                                          className="nav-item active mx-3"
                                          id="modal-nav-link"
                                        >
                                          <button
                                            className="nav-link active"
                                            style={{ textAlign: "left" }}
                                            role="button"
                                            onClick={() =>
                                              leavePage(ele.seo_permalink)
                                            }
                                          >
                                            {ele.name}
                                          </button>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  );
                })
              : ""}
            {isAuthorized ? (
              <>
                <li className="nav-item active" id="modal-nav-link">
                  <button
                    className="nav-link active"
                    role="button"
                    onClick={() => leavePage("favourite-posts")}
                  >
                    {intl.formatMessage({
                      id: "SHARED_COMPONENTS_NAVBAR_MODAL_FAVOURITE_POSTS",
                    })}
                  </button>
                </li>
                <li className="nav-item active" id="modal-nav-link">
                  <button
                    className="nav-link active"
                    role="button"
                    onClick={() => leavePage("posts-read")}
                  >
                    {intl.formatMessage({
                      id: "SHARED_COMPONENTS_NAVBAR_MODAL_POSTS_READ",
                    })}
                  </button>
                </li>
                <li className="nav-item active" id="modal-nav-link">
                  <button
                    className="nav-link active"
                    role="button"
                    onClick={() => leavePage("communities")}
                  >
                    {intl.formatMessage({
                      id: "COMMUNITIES_TITLE",
                    })}
                  </button>
                </li>
              </>
            ) : null}
          </ul>
          {/* {posts &&
            posts?.[postId] &&
            posts?.[postId].products &&
            Object.keys(posts?.[postId].products).length > 0 && (
              <ul
                className="mb-2 mb-lg-0"
                style={{ marginRight: "auto", padding: "0.25rem" }}
              >
                <button className="buyButton-title">Buy</button>
              </ul>
            )} */}
        </ModalBody>
        <Modal.Footer
          className="sidebar-footer"
          style={{
            backgroundColor: generalDetails.accent_color,
            justifyContent: "space-between",
          }}
        >
          {!accessToken && user_sign_In === "no" ? (
            <a onClick={openLoginModal}>
              <div
                style={{
                  color: generalDetails.navigation_top_text,
                  cursor: "pointer",
                }}
              >
                {intl.formatMessage({
                  id: "SHARED_COMPONENTS_NAVBAR_MODAL_LOGIN",
                })}
              </div>
            </a>
          ) : null}
          {isAuthorized && (
            <div
              className="d-flex gap-2 align-items-center"
              onClick={logout}
              style={{
                color: generalDetails.navigation_top_text,
                cursor: "pointer",
              }}
            >
              <span>Logout</span>
              <TbLogout
                style={{
                  width: "20px",
                  height: "20px",
                  stroke: "white",
                }}
              />
            </div>
          )}
          <span style={{ color: generalDetails.navigation_top_text }}>
            {dateFormat("en-us")}
          </span>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NavBarModal;
