import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
const ScrollToTop = () => {
  const [visible, setvisible] = useState(false);
  const ToggleVisible = () => {
    let scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) setvisible(true);
    else setvisible(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", ToggleVisible);
    return () => {
      window.removeEventListener("scroll", ToggleVisible);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <button
      id="return-to-top"
      onClick={() => scrollToTop()}
      style={{ display: visible ? "flex" : "none" }}
    >
      <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
    </button>
  );
};

export default ScrollToTop;
