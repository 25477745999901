import React, { useEffect } from "react";
// import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router";
import AllPosts from "../../components/Pages/AllPosts";
import { getMasterDataByType } from "../../Redux/MasterDataReducer";
import CategoryList from "./CategoryList";
import "./style.scss";
const Category = ({ title, details, pageName }) => {
  // const intl = useIntl();
  const navigate = useNavigate();
  const masterData = useSelector((state) => state?.masterData);
  const dispatch = useDispatch();
  useEffect(() => {
    if (title === "category") {
      if (!masterData?.[pageName]?.categories) {
        dispatch(getMasterDataByType({ listType: "category-list", pageName }));
      }
    } else {
      if (!masterData?.[pageName]?.groups) {
        dispatch(getMasterDataByType({ listType: "group-list", pageName }));
      }
    }
  }, [pageName]);
  const masterDataList =
    title === "category"
      ? masterData?.[pageName]?.categories?.masterData
      : masterData?.[pageName]?.groups?.masterData;
  const handleClick = (title, id, name) => {
    // dispatch(
    //   getMasterDataDetails({
    //     masterDataType: title === "category" ? "categories" : "groups",
    //     id,
    //     pageName,
    //   })
    // );
    navigate(
      `../${
        title === "category" ? "categories" : "groups"
      }/${pageName}/${id}/${name}`,
      {
        state: {
          pageName: pageName,
          type: title === "category" ? "categories" : "groups",
          id: id,
        },
      }
    );
  };
  return (
    <>
      <Routes>
        {masterDataList && masterDataList.length > 0
          ? masterDataList.map((ele, index) => {
              return (
                <Route
                  path={`/${
                    title === "category" ? "categories" : "groups"
                  }/${pageName}/:${ele.id}/:category`}
                  element={<AllPosts />}
                  key={index}
                />
              );
            })
          : null}
      </Routes>
      <div className="categories-wrapper mt-0 mb-3">
        {Boolean(details?.display_header) ? (
          <h4 className="border-section">{details?.title}</h4>
        ) : null}

        <div className="widget-category">
          <ul className="list-unstyled">
            {masterDataList && masterDataList.length > 0
              ? masterDataList
                  .sort((a, b) => b.posts_count - a.posts_count)
                  .map((ele, index) => {
                    if (index < details?.maximum_number_of_data) {
                      return (
                        <CategoryList
                          name={ele.name}
                          key={index}
                          postsCount={
                            details?.posts_count ? ele.posts_count : ""
                          }
                          title={title}
                          handleClick={handleClick}
                          id={ele?.id}
                        />
                      );
                    }
                  })
              : null}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Category;
