import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CheckSpecialChar from "../CheckSpecialChar";
const FooterListItem = ({ text, versionId, ...props }) => {
  const [linkTo, setlinkTo] = useState("/");
  const legalText = useSelector((state) => state?.generalDetails?.legalText);
  useEffect(() => {
    const link =
      legalText &&
      Object.values(legalText).filter((ele) => ele?.version_id === versionId);
    if (link && link.length > 0) {
      const changedLink = CheckSpecialChar(link[0]?.name);
      setlinkTo("/legaltexts/" + changedLink.replace(/ /g, "-"));
    }
  }, [legalText, versionId]);
  return (
    <li className="list-inline-item" {...props}>
      <Link to={linkTo ? linkTo : "/"}>{text}</Link>
    </li>
  );
};

export default FooterListItem;
