import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import LoginImage from "../../assets/login_image.svg";
import CustomFooter from "../../sharedComponents/Footer/CustomFooter";
import { useCustomMediaQuery } from "../../utils";
import ForgotPassword from "./components/ForgotPasswordForm";
import Login from "./components/Login";
function AuthPage() {
  // const legalText = useSelector((state) => state.generalDetails.legalText);
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  // eslint-disable-next-line    
  }, []);
  const {
    isXsScreen,
    // isSmScreen,
    // isMdScreen,
    isLgScreen,
    isXLgScreen,
    isXxLgScreen,
  } = useCustomMediaQuery();
  return (
    <React.Fragment>
      <div className="">
        {/* <Wave className="position-absolute" style={{ zIndex: -100 }} /> */}
        <section>
          <div
            className="container"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <div
              className="row d-flex align-items-center justify-content-center mx-auto"
              style={{
                boxShadow:
                  "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                background: "white",
                width: isXsScreen ? "100%" : "90%",
              }}
            >
              {isLgScreen || isXLgScreen || isXxLgScreen ? (
                <div className="col-lg-6 col-xl-6">
                  <img
                    src={LoginImage}
                    style={{ maxHeight: "350px" }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              ) : null}
              <div className="col-md-12 col-lg-5 col-xl-5">
                {/* <Switch>
                        <Route path='/registration' component={registration} />
                        <Redirect to='/registration' />
                    </Switch> */}
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route
                    exact
                    path="/forgot-password/"
                    element={<ForgotPassword />}
                  />
                  {/* <Route path='code-verification/:code' element={<CodeVerificationWrapper />} /> */}
                  <Route index element={<Navigate to="/login" />} />
                </Routes>
              </div>
            </div>
            <CustomFooter />
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

export default AuthPage;
