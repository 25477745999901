import { convert } from "html-to-text";
import { useMediaQuery } from "react-responsive";
export const formatDate = (date, time) => {
  const _date = new Date(date);
  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // const days = [
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  // ];
  const month = _date.getMonth();
  const day = _date.getDate();
  const year = _date.getFullYear();
  const hours = addZero(_date.getHours());
  const minutes = addZero(_date.getMinutes());
  return `${months[month]} ${day}, ${year} ${
    time ? `${hours}:${minutes}` : ""
  }`;
};

export const formatString = (str) => {
  return !!str ? convert(str) : str;
};

export const convertTime = (seconds) => {
  if (!isNaN(seconds)) {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    if (parseInt(mins) < 10 && secs < 10) {
      return `0${mins}:0${secs}`;
    } else if (parseInt(mins) < 10) {
      return `0${mins}:${secs}`;
    } else if (secs < 10) return `${mins}:0${secs}`;
    else return `${mins}:${secs}`;
  }
};

export const setPostMediaSource = (
  post_type,
  post_media_resolutions,
  post_thumbnail_resolutions,
  post_thumbnail_url,
  noImage
) => {
  const imgResolutions =
    post_type === "image" ? post_media_resolutions : post_thumbnail_resolutions;
  if (imgResolutions.length > 0) {
    const source = imgResolutions.filter(
      (element) => element?.resolution === "1920x1080"
    );
    return source[0].file_url;
  }
  return post_thumbnail_url ? post_thumbnail_url : noImage;
};

export const dateFormat = (locale) => {
  const _date = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const deMonths = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const deDays = [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
  ];
  const month = _date.getMonth();
  const day = _date.getDate();
  const year = _date.getFullYear();
  return locale === "en-us"
    ? `${days[_date.getDay()]}, ${months[month]} ${day}, ${year}`
    : `${deDays[_date.getDay()]}, ${deMonths[month]} ${day}, ${year}`;
};

export const useCustomMediaQuery = () => {
  const isXsScreen = useMediaQuery({ maxWidth: 575 });
  const isSmScreen = useMediaQuery({ minWidth: 576, maxWidth: 767 });
  const isMdScreen = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isLgScreen = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isXLgScreen = useMediaQuery({ minWidth: 1200, maxWidth: 1399 });
  const isXxLgScreen = useMediaQuery({ minWidth: 1400 });
  const resolution = isXsScreen
    ? "256x144"
    : isSmScreen
    ? "512x288"
    : isMdScreen
    ? "1024x576"
    : isLgScreen
    ? "1024x576"
    : "1024x576";
  return {
    isXsScreen,
    isSmScreen,
    isMdScreen,
    isLgScreen,
    isXLgScreen,
    isXxLgScreen,
    resolution,
  };
};

// export const useResolution = () => {
//   const { isLargeScreen, isSmallScreen, isMediumScreen, isvLgScreen } =
//     useCustomMediaQuery();
//   return isSmallScreen
//     ? "256x144"
//     : isMediumScreen
//     ? "512x288"
//     : isLargeScreen
//     ? "1024x576"
//     : isvLgScreen
//     ? "1920x1080"
//     : "2048x1152";
// };

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
