import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./style.scss";

const Title = ({ title, sidebar, header_navigation_id }) => {
  const [navigateTo, setNavigateTo] = useState([]);
  // const [section, setSections] = useState([]);

  const navigationMenu = useSelector(
    (state) => state.navigationMenu.navigationMenu
  );

  useEffect(() => {
    if (
      navigationMenu.length !== 0 &&
      Object.keys(navigationMenu).length !== 0
    ) {
      const removeNested = (nav) => {
        let children = [];
        return nav
          .map((m) => {
            if (m.children && m.children.length) {
              children = [...children, ...m.children];
            }
            return m;
          })
          .concat(children.length ? removeNested(children) : children);
      };

      const filtered = (removeNested(navigationMenu) || []).filter(
        (item) => item.id === header_navigation_id
      );

      setNavigateTo(filtered);
    }
  }, [header_navigation_id]);

  return (
    <div className="title mb-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-12 text-center justify-content-center d-flex">
            <span className="title-before"></span>
            {navigateTo[0]?.seo_permalink !== undefined ? (
              <Link to={`/${navigateTo[0]?.seo_permalink}`}>
                <h3>{title}</h3>
              </Link>
            ) : (
              <h3>{title}</h3>
            )}
            <span className="title-after"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Title;
