export default {
  LOGIN: {
    en: "Login",
    de: "Anmelden",
  },
  SIGN_IN: {
    en: "Sign In",
    de: "Anmeldung",
  },
  REGISTER: {
    en: "Register",
    de: "Benutzerkonto",
  },
  REGISTER_MESSAGE: {
    en: "Please register with us to experience our new features.",
    de: "Hier können Sie Ihr Benutzerkonto erstellen",
  },
  LANGUAGE: {
    en: "Language",
    de: "Sprache",
  },
  TOP: {
    en: "Top-News",
    de: "Top-News",
  },
  CATEGORY: {
    en: "Category",
    de: "Kategorie",
  },
  TAGS: {
    en: "Tags",
    de: "Stichwörter",
  },
  GROUP: {
    en: "Groups",
    de: "Gruppen",
  },

  NEWSLETTER: {
    en: "Newsletter",
    de: "Newsletter",
  },
  NO_DATA: {
    en: "No data to display in this section",
    de: "Keine Daten vorhanden",
  },
  USERS_SALUATION_TITLE: {
    en: "Salutation",
    de: "Anrede",
  },
  USERS_SALUATION_OPTION1: {
    en: "Mr",
    de: "Herr",
  },
  USERS_SALUATION_OPTION2: {
    en: "Mrs",
    de: "Frau",
  },

  USERS_SALUATION_OPTIONS: {
    en: JSON.stringify([
      { id: 1, name: "Mr" },
      { id: 2, name: "Mrs" },
    ]),
    de: JSON.stringify([
      { id: 1, name: "Herr" },
      { id: 2, name: "Frau" },
    ]),
  },
  USERS_TITLES_OPTIONS: {
    en: JSON.stringify([
      { id: 0, name: "No Title" },
      { id: 1, name: "Dr." },
      { id: 2, name: "Prof." },
      { id: 3, name: "Prof. Dr." },
    ]),
    de: JSON.stringify([
      { id: 0, name: "Kein Titel" },
      { id: 1, name: "Dr." },
      { id: 2, name: "Prof." },
      { id: 3, name: "Prof. Dr." },
    ]),
  },
  USERS_TITLE_OPTION1: {
    en: "No Title",
    de: "Kein Titel",
  },
  USERS_TITLE_OPTION2: {
    en: "Dr.",
    de: "Dr.",
  },
  USERS_TITLE_OPTION3: {
    en: "Prof.",
    de: "Prof.",
  },
  USERS_TITLE_OPTION4: {
    en: "Prof. Dr.",
    de: "Prof. Dr.",
  },
  USERS_FIRSNAME_FIELD: {
    en: "First name",
    de: "Vorname",
  },
  USERS_LASTNAME_FIELD: {
    en: "Last name",
    de: "Nachname",
  },
  USERS_EMAIL_FIELD: {
    en: "Email",
    de: "E-Mail",
  },
  WELCOME: {
    en: "Welcome",
    de: "Willkommen",
  },
  LOGIN_MESSAGE_ONE: {
    en: "Please enter your details to experience all the features",
    de: "Hier können Sie sich mit Benutzernamen und Passwort anmelden.",
  },
  LOGIN_MESSAGE_TWO: {
    en: "If you have an authentication code please",
    de: "Sie haben noch kein Benutzerkonto?",
  },
  SIGN_UP: {
    en: "Sign up",
    de: "Benutzerkonto erstellen",
  },
  HERE: {
    en: "here",
    de: "hier",
  },
  FORGOT_PASSWORD: {
    en: "forgot password",
    de: "Passwort vergessen",
  },
  PASSWORD: {
    en: "Password",
    de: "Passwort",
  },
  EMAIL: {
    en: "Email",
    de: "E-Mail",
  },
  USERS_EMAIL_REQUIRED: {
    en: "Email is required!",
    de: "Die E-Mail ist ein Pflichtfeld",
  },
  USERS_INVALID_DETAILS: {
    en: "Given details are incorrect. Please try again",
    de: "Ihre Eingaben sind ungültig. Bitte korrigieren Sie diese",
  },
  USERS_CODE_REQUIRED: {
    en: "Please enter the code",
    de: "Bitte geben Sie den Code ein",
  },
  USERS_INVALID_CODE: {
    en: "The code is incorrect",
    de: "Der eingegebene Code ist falsch",
  },
  USERS_ACTIVATION_CODE_SUCCESSFUL: {
    en: "Account is successfully activated",
    de: "",
  },
  USERS_WRONG_EMAIL_FORMAT: {
    en: "Wrong email format",
    de: "Die E-Mail-Adresse ist ein Pflichtfeld",
  },
  USERS_PASSWORD_REQUIRED: {
    en: "Password is required",
    de: "Das Passwort ist ein Pflichtfeld",
  },
  USERS_PASSWORD_MATCHES_INFO: {
    en: "Password must be atleast 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character!",
    de: "Das Passwort muss mindestens 8 Zeichen lang, mindestens ein Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten!",
  },
  USERS_CONFIRM_PASSWORD_REQUIRED: {
    en: "Password confirmation is required!",
    de: "Bitte bestätigen Sie das Passwort",
  },
  USERS_PASSWORD_MISMATCH: {
    en: "Password and Confirm Password didn't match",
    de: "Die Passwörter stimmen nicht überein",
  },
  USERS_PASSWORD_INFO: {
    en: "Use 8 or more characters with a mix of letters, numbers & symbols.",
    de: "Verwenden Sie 8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Zahlen und Symbolen.",
  },
  VALIDATE_REQUIRED_ERROR_MESSAGE: {
    en: "Required",
    de: "Pflichfeld",
  },
  VALIDATE_INVALID_EMAIL_ADDRESS_ERROR_MESSAGE: {
    en: "Invalid email address",
    de: "Ungültige E-Mail-Adresse",
  },
  VALIDATE_FIRST_NAME_REQUIRED_ERROR_MESSAGE: {
    en: "Firstname is Required",
    de: "Der Vorname ist ein Pflichtfeld",
  },
  VALIDATE_LAST_NAME_REQUIRED_ERROR_MESSAGE: {
    en: "Lastname is Required",
    de: "Der Nachname ist ein Pflichtfeld",
  },
  VALIDATE_TOO_SHORT_ERROR_MESSAGE: {
    en: "Too short!",
    de: "Die Eingabe ist zu kurz!",
  },
  VALIDATE_TOO_LONG_ERROR_MESSAGE: {
    en: "Too Long!",
    de: "Die EIngabe ist zu lang!",
  },
  VALIDATE_TERMS_AND_CONDITIONS_MESSAGE: {
    en: "Please accept terms and conditions",
    de: "Bitte Akzeptieren Sie die Bedingungen",
  },
  VALIDATE_LOGIN_DETAILS_ERROR_MESSAGE: {
    en: "The login details are incorrect",
    de: "Die Anmeldeinformationen konnten nich verifiziert werden!",
  },
  USERS_FULL_NAME: {
    en: "Full Name",
    de: "Name",
  },
  USERS_FULL_NAME_REQUIRED: {
    en: "Please enter Fullname",
    de: "Bitte geben Sie Ihren vollen Namen ein!",
  },
  USERS_PASSWORD_CHANGE_SUCCESS_MESSAGE: {
    en: "User password changed successfully!",
    de: "Das Passwort wurde erfolgreich aktualisiert!",
  },
  USERS_PASSWORD_CHANGE_FAILURE_MESSAGE: {
    en: "User password change failed!",
    de: "Fehler beim aktualisieren des Passwortes",
  },
  USERS_SIGN_IN_CURRENT_PASSWORD: {
    en: "Current Password",
    de: "Aktuelles Passwort",
  },
  USERS_SIGN_IN_NEW_PASSWORD: {
    en: "New Password",
    de: "Neues Passwort",
  },
  USERS_SIGN_IN_CONFIRM_PASSWORD: {
    en: "Confirm Password",
    de: "Passwort bestätigen",
  },
  USER_AUTHENTICATION: {
    en: "User Authentication",
    de: "Benutzerauthentifizierung",
  },
  USER_AUTHENTICATION_MESSAGE: {
    en: "Please enter authentication code and email to authenticate",
    de: "Bitte Authentifizierungscode und E-Mail zur Authentifizierung eingeben",
  },
  USER_AUTHENTICATION_MESSAGE_TWO: {
    en: "Please enter authentication code to reset",
    de: "Bitte geben Sie den Code aus der E-Mail ein",
  },
  USER_ACTIVATION_MESSAGE_TWO: {
    en: "Please enter authentication code to activate account",
    de: "",
  },
  USER_AUTHENTICATION_CODE_SUCCESS: {
    en: "The email is sent to desired email with Code to reset your password",
    de: "Falls Sie ein Benutzerkonto bei uns haben, bekommen Sie eine E-Mail mit weiteren Anweisungen zum zurücksetzen des Passworts. Bitte schauen Sie auch in Ihrem Spam Ordner nach",
  },
  USER_PASSWORD_RESET: {
    en: "Reset Password",
    de: "Passwort zurücksetzen",
  },
  USER_ACCOUNT_ACTIVATE: {
    en: "Activate Account",
    de: "",
  },
  USER_PASSWORD_RESET_MESSAGE: {
    en: "Please enter your email to reset your password.",
    de: "Bitte geben Sie ihre E-Mail-Adresse zum zurücksetzen des Passworts ein",
  },
  USER_CHANGE_PASSWORD: {
    en: "Change Password",
    de: "Passwort ändern",
  },
  USER_CHANGE_PASSWORD_MESSAGE: {
    en: "Please enter new password to reset",
    de: "Bitte geben Sie ein neues Passwort ein, um es zurückzusetzen",
  },
  BUTTON_BACK_TO_HOME: {
    en: "Back to Home",
    de: "Zurück zur Startseite",
  },
  BUTTON_CANCEL: {
    en: "Cancel",
    de: "Abbrechen",
  },
  TEXT_SOLD_OUT: {
    en: "SOLD OUT",
    de: "Ausverkauft",
  },
  TEXT_REGISTERED: {
    en: "REGISTERED",
    de: "Angemeldet",
  },
  BUTTON_REGISTER_NOW: {
    en: "REGISTER NOW",
    de: "Jetzt registrieren",
  },

  BUTTON_JOIN_EVENT: {
    en: "JOIN EVENT",
    de: "An Veranstaltung teilnehmen",
  },
  TEXT_BUY_OPTIONS: {
    en: "Buy Options",
    de: "Kaufoptionen",
  },
  MESSAGE_BACK_TO_LOGIN: {
    en: "Back to Login",
    de: "Zurück zur Anmeldung",
  },
  MESSAGE_CONTACT_SYS_ADMIN: {
    en: "Oops! Please contact system administrator",
    de: "Ein Fehler ist aufgetreten!",
  },
  ERROR_CANNOT_REGISTER: {
    en: "Oops! Cannot register. Please try again later!",
    de: "Fehler beim erstellen des Benutzerkontos, bitte versuchen Sie es später noch mal!",
  },
  ERROR_FAILED_TO_LOAD_DATA: {
    en: "Oops! Cannot load data.Please try again later!",
    de: "Beim ladne der Daten ist ein Fehler aufgetreten, bitte versuchen Sie es noch mal!",
  },
  BUTTON_READ_MORE: {
    en: "Read More",
    de: "Mehr erfahren",
  },
  BUTTON_SELECT: {
    en: "Select",
    de: "Auswählen",
  },
  BUTTON_NEXT: {
    en: "Next",
    de: "Weiter",
  },
  BUTTON_CONTINUE: {
    en: "Continue",
    de: "Weiter",
  },
  BUTTON_BUY_NOW: {
    en: "Buy",
    de: "Kaufen",
  },
  BUTTON_SUBMIT: {
    en: "Continue",
    de: "Weiter",
  },
  BUTTON_CLOSE: {
    en: "Abort",
    de: "Abbrechen",
  },
  ENTER_SEARCH_TEXT: {
    en: "Search",
    de: "Suche",
  },
  ACCEPT_TERMS_AND_CONDITIONS: {
    en: "Please Accept The Terms And Conditions",
    de: "Bitte akzeptieren Sie alle Bedingungen",
  },
  ERROR_TOO_SHORT: {
    en: "Too short",
    de: "Zu Kurze eingabe",
  },
  ERROR_TOO_LONG: {
    en: "Too Long",
    de: "Zu lange Eingabe",
  },
  ERROR_INVALID_EMAIL: {
    en: "Invalid email",
    de: "Falsches E-Mail format",
  },
  //Payments
  PAYMENT_CANCEL_MESSAGE: {
    en: "Your Payment has been cancelled. We regret the inconvenience caused. Please try again after some time.",
    de: "Ihre Zahlung wurde storniert. Wir bedauern die entstandenen Unannehmlichkeiten. Bitte versuchen Sie es später erneut.",
  },
  PAYMENT_SUCCESS_MESSAGE: {
    en: "Your payment is success.",
    de: "Ihre Zahlung war erfolgreich",
  },
  PAYMENT_SUCCESS_MESSAGE_NEW_USER: {
    en: "Your payment is success. Please check your email inbox for login credentials.",
    de: "Ihre Zahlung war erfolgreich. Ihre Benutzerkontoinformationen haben wir per E-Mail an Sie versand.",
  },
  PAYMENT_INPROGRESS_MESSAGE: {
    en: "Your Payment is in Progress.Please dont refresh the page or hit back button",
    de: "Ihre Zahlung ist in Bearbeitung. Bitte aktualisieren Sie die Seite nicht und drücken Sie nicht die Zurück-Taste im Browser.",
  },
  SUBSCRIPTION_PLAN_1_YEAR: {
    en: "Yearly subscription",
    de: "Jahresabonnement",
  },
  SUBSCRIPTION_PLAN_YEARLY: {
    en: "year subscription",
    de: "Jahr/ Jahre",
  },
  SUBSCRIPTION_PLAN_1_MONTH: {
    en: "Monthly subscription",
    de: "Monatsabonnement",
  },
  SUBSCRIPTION_PLAN_MONTHLY: {
    en: "months subscription",
    de: "Monat / Monate",
  },
  SUBSCRIPTION_PLAN_1_WEEK: {
    en: "Weekly subscription",
    de: "Wöchentliches Abonnement ",
  },
  SUBSCRIPTION_PLAN_WEEKLY: {
    en: "weeks subscription",
    de: "Woche / Wochen",
  },
  SUBSCRIPTION_PLAN_1_DAY: {
    en: "Daily subscription",
    de: "Tagesabonnement",
  },
  SUBSCRIPTION_PLAN_DAILY: {
    en: "days subscription",
    de: "Tag / Tage",
  },
  OFFER_INVOICE_DOWNLOAD_MESSAGE: {
    en: "Here you can download your invoice",
    de: "Hier können Sie Ihre Rechnung herunterladen",
  },
  // ACCOUNT SETTINGS

  ACCOUNT_SETTINGS_WRAPPER_TITLE: {
    en: "Account Settings",
    de: "Benutzerkonto",
  },
  ACCOUNT_SETTINGS_WRAPPER_SUB_TITLE: {
    en: "Manage your account.",
    de: "Verwalten Sie hier Ihr Benutzerkonto und alle Einstellungen.",
  },
  ACCOUNT_SETTINGS_ACCOUNT_INFORMATION: {
    en: "Profile",
    de: "Profil",
  },
  ACCOUNT_SETTINGS_SECURITY: {
    en: "Security",
    de: "Sicherheit",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS: {
    en: "Personal Address",
    de: "Adresse",
  },
  ACCOUNT_SETTINGS_COMPANY_ADDRESS: {
    en: "Company Address",
    de: "Unternehmen",
  },
  ACCOUNT_SETTINGS_CONTACT_TYPES: {
    en: "Contact",
    de: "Kontakt",
  },

  // ACCOUNT SETTINGS - CONTACT TYPES

  ACCOUNT_SETTINGS_CONTACTS_ADD_SUCCESS: {
    en: "Contact Added Successfully",
    de: "Die Kontaktart wurde erfolgreich erstellt!",
  },
  ACCOUNT_SETTINGS_CONTACTS_UPDATE_SUCCESS: {
    en: "Contact Updated Successfully",
    de: "Die Kontaktart wurde erfolgreich aktualisiert!",
  },
  ACCOUNT_SETTINGS_CONTACTS_DELETE_SUCCESS: {
    en: "Contact Deleted Successfully",
    de: "Die Kontaktart wurde erfolgreich gelöscht!",
  },
  ACCOUNT_SETTINGS_CONTACTS_ADD_ERROR: {
    en: "Contact Addition Unsuccessful",
    de: "Beim erstellen der Kontaktart ist ein Fehler aufgetreten!",
  },
  ACCOUNT_SETTINGS_CONTACTS_UPDATE_ERROR: {
    en: "Contact Updation Unsuccessful",
    de: "Beim aktualisieren Ihrer Kontaktart ist ein Fehler aufgetreten!",
  },
  ACCOUNT_SETTINGS_CONTACTS_DELETE_ERROR: {
    en: "Contact Deletion Unsuccessful",
    de: "Beim löschen Ihrer Kontaktart ist ein Fehler aufgetreten",
  },
  ACCOUNT_SETTINGS_CONTACTS_CONTACT_LIST_ERROR: {
    en: "ERROR: getContactTypesListAPI",
    de: "API Fehler: Kontaktarten konnten nicht geladen werden!",
  },
  ACCOUNT_SETTINGS_CONTACTS_TITLE: {
    en: "Contact",
    de: "Kontakt",
  },
  ACCOUNT_SETTINGS_CONTACTS_FULL_ALERT: {
    en: "All contacts added already!",
    de: "Alle Kontaktarten sind schon vorhanden!",
  },
  ACCOUNT_SETTINGS_CONTACTS_ADD_CONTACT: {
    en: "Add Contact",
    de: "Kontaktart erstellen",
  },
  ACCOUNT_SETTINGS_CONTACTS_NO_CONTACT_ADDED: {
    en: "No contacts added.",
    de: "Es sind noch keine Kontaktarten vorhanden",
  },
  ACCOUNT_SETTINGS_CONTACTS_EDIT: {
    en: "Edit",
    de: "Bearbeiten",
  },
  ACCOUNT_SETTINGS_CONTACTS_DELETE: {
    en: "Delete",
    de: "Löschen",
  },
  ACCOUNT_SETTINGS_CONTACTS_CONTACT_ID_ERROR_MESSAGE: {
    en: "Please select a contact",
    de: "Bitte wählen Sie eine Kontaktart aus",
  },
  ACCOUNT_SETTINGS_CONTACTS_CONTACT_DESCRIPTION_MAX_MESSAGE: {
    en: "Maximum 60 symbols",
    de: "Maximal 60 Zeichen",
  },
  ACCOUNT_SETTINGS_CONTACTS_CONTACT_DESCRIPTION_REQUIRED_MESSAGE: {
    en: "value is required",
    de: "Dieser Wert ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_CONTACTS_ADD_NEW_CONTACT: {
    en: "Add New Contact",
    de: "Erstellen",
  },
  ACCOUNT_SETTINGS_CONTACTS_EDIT_CONTACT: {
    en: "Edit Contact",
    de: "Kontaktart bearbeiten",
  },
  ACCOUNT_SETTINGS_CONTACTS_CONTACT_TYPE_LABEL: {
    en: "Contact",
    de: "Kontakt",
  },
  ACCOUNT_SETTINGS_CONTACTS_DESCRIPTION_LABEL: {
    en: "Value",
    de: "Wert",
  },
  ACCOUNT_SETTINGS_CONTACTS_CLOSE: {
    en: "Close",
    de: "Schließen",
  },
  ACCOUNT_SETTINGS_CONTACTS_LOADING: {
    en: "Loading...",
    de: "Laden...",
  },
  ACCOUNT_SETTINGS_CONTACTS_SUBMIT: {
    en: "Submit",
    de: "Speichern",
  },

  // ACCOUNT SETTINGS - PERSONAL ADDRESS

  ACCOUNT_SETTINGS_COMPANY_ADDRESS_NAME_MESSAGE: {
    en: "Please enter company name!",
    de: "Bitte geben Sie einen Unternehmensnamen ein!",
  },
  ACCOUNT_SETTINGS_COMPANY_ADDRESS_VERIFICATION_CODE_MESSAGE: {
    en: "Please enter verification code",
    de: "Bitte geben Sie den Verifizierungscode ein!",
  },

  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS1_MESSAGE: {
    en: "Please enter Address Line 1",
    de: "Die Adresse ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ZIP_CODE_MESSAGE: {
    en: "Please enter ZIP Code",
    de: "Die Postleitzahl ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_CITY_MESSAGE: {
    en: "Please enter City",
    de: "Die Stadt ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_COUNTRY_MESSAGE: {
    en: "Please enter Country",
    de: "Das Land ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_SUCCESS_MESSAGE: {
    en: "Address updated successfully",
    de: "Die Adresse wurde erfolgreoch aktualisiert!",
  },
  ACCOUNT_SETTINGS_ADDRESS_REMOVE_SUCCESS_MESSAGE: {
    en: "Address removed successfully",
    de: "Die Adresse wurde erfolgreich gelöscht!",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ERROR_MESSAGE: {
    en: "Address updation unsuccessful",
    de: "Fehler beim aktualisieren der Adresse!",
  },
  ACCOUNT_SETTINGS_ADDRESS_REMOVE_ERROR_MESSAGE: {
    en: "Address removal unsuccessful",
    de: "Fehler beim löschen der Adresse!",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_TITLE: {
    en: "Address",
    de: "Adresse",
  },
  ACCOUNT_SETTINGS_COMPANY_ADDRESS_NAME_LABEL: {
    en: "Company Name",
    de: "Unternehmensname",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS1_LABEL: {
    en: "Street",
    de: "Straße",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS2_LABEL: {
    en: "Address Line 2",
    de: "Zusatz",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_CITY_LABEL: {
    en: "City",
    de: "Stadt",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ZIP_CODE_LABEL: {
    en: "Zip Code",
    de: "PLZ",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_STATE_LABEL: {
    en: "State",
    de: "Bundesland",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_COUNTRY_LABEL: {
    en: "Country",
    de: "Land",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_SUBMIT: {
    en: "Save",
    de: "Speichern",
  },
  ACCOUNT_SETTINGS_PERSONAL_ADDRESS_LOADING: {
    en: "Loading...",
    de: "Bitte warten...",
  },

  //ACCOUNT SETTINGS PRODUCT CARD
  ACCOUNT_SETTINGS_PRODUCT_CARD_PURCHASED_DATE: {
    en: "Purchased date",
    de: "Kaufdatum",
  },
  ACCOUNT_SETTINGS_PRODUCT_CARD_TRANSACTION_ID: {
    en: "Transaction ID",
    de: "Transaktions ID",
  },
  ACCOUNT_SETTINGS_PRODUCT_CARD_DOWNLOAD_INVOICE: {
    en: "Download Invoice",
    de: "Rechnung runterladen",
  },
  ACCOUNT_SETTINGS_SUBSCRIPTIONS_CARD_CANCEL_SUBSCRIPTION: {
    en: "Cancel Subscription",
    de: "Abonnement kündigen",
  },
  ACCOUNT_SETTINGS_SUBSCRIPTIONS_CARD_EXPIRES_ON: {
    en: "Subscription expires on: ",
    de: "Ihr Abonnement läuft aus am: ",
  },
  ACCOUNT_SETTINGS_PRODUCT_CARD_DOWNLOAD_INVOICE_SUCCESS_TOAST: {
    en: "Invoice is downloading...",
    de: "Rechnung wird runtergeladen...",
  },
  ACCOUNT_SETTINGS_PRODUCT_CARD_VIEW_RECIEPT: {
    en: "View Receipt",
    de: "Zahlungsbeleg ansehen",
  },
  ACCOUNT_SETTINGS_PRODUCT_CARD_INCLUSIVE_OF_ALL_TAXES: {
    en: "inclusive of all taxes",
    de: "inkl. MwSt. ",
  },
  ACCOUNT_SETTINGS_PRODUCT_CARD_EXCLUSIVE_OF_ALL_TAXES: {
    en: "exclusive of all taxes",
    de: "exkl. MwSt.",
  },
  MODAL_PLACEHOLDER_NAME: {
    en: "Name",
    de: "Name",
  },
  MODAL_PLACEHOLDER_VALUE: {
    en: "Value",
    de: "Wert",
  },
  ACCOUNT_SETTINGS_PRODUCTS_TITLE: {
    en: "Products",
    de: "Produkte",
  },
  ACCOUNT_SETTINGS_OFFERS_TITLE: {
    en: "Offers",
    de: "Angebote",
  },
  ACCOUNT_SETTINGS_DEVICES_TITLE: {
    en: "Manage Devices",
    de: "Angemeldete Geräte",
  },
  ACCOUNT_SETTINGS_SUBSCRIPTIONS_TITLE: {
    en: "Subscriptions",
    de: "Abonnements",
  },
  ACCOUNT_SETTINGS_SUBSCRIPTIONS_INFO: {
    en: "Here you can manage your subscriptions.",
    de: "Hier können Sie Ihre Abonnements verwalten.",
  },
  ACCOUNT_SETTINGS_PURCHASE_IOS_INFO: {
    en: "If you want to cancel the subscription , please visit App Store to cancel!.",
    de: "",
  },
  ACCOUNT_SETTINGS_PURCHASE_ANDROID_INFO: {
    en: "If you want to cancel the subscription , please visit Play Store to cancel!.",
    de: "",
  },
  ACCOUNT_SETTINGS_SUBSCRIPTIONS_TYPE_1: {
    en: "Active",
    de: "Aktiv",
  },
  ACCOUNT_SETTINGS_SUBSCRIPTIONS_TYPE_2: {
    en: "Canceled",
    de: "Gekündigt",
  },
  ACCOUNT_SETTINGS_SUBSCRIPTIONS_TYPE_3: {
    en: "Expired",
    de: "Abgelaufen",
  },
  ACCOUNT_SETTINGS_PRODUCTS_NO_PRODUCTS_PURCHASED: {
    en: "No product purchased",
    de: "Keine gekaufeten Produkte vorhanden",
  },
  ACCOUNT_SETTINGS_PRODUCTS_NO_OFFERS_AVAILABLE: {
    en: "No Offers Available",
    de: "Keine Angebote vorhanden",
  },
  ACCOUNT_SETTINGS_NO_DEVICES: {
    en: "No Devices",
    de: "Keine Geräte vorhanden",
  },
  ACCOUNT_SETTINGS_PRODUCTS_NO_SUBSCRIPTIONS_PURCHASED: {
    en: "No subscriptions",
    de: "Keine Abonnements vorhanden",
  },

  // ACCOUNT SETTINGS - PERSONAL INFORMATION
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_DEACTIVATE_SUCCESS_TOAST: {
    en: "Account deactivated successfully",
    de: "Das Konto wurde erfolgreich deaktiviert",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_DEACTIVATE_ERROR_TOAST: {
    en: "Disabling account failed, Please try again!",
    de: "Fehler beim deaktivieren des Kontos!",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_DEACTIVATE_BUTTON: {
    en: "Delete Account",
    de: "Benutzerkonto löschen",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATE_BUTTON: {
    en: "Activate Account",
    de: "Benutzerkonto löschen",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_DEACTIVATE_ACCOUNT_MESSAGE: {
    en: "As soon as you click the Delete User Account button, your account will be immediately and permanently deleted along with all associated data.",
    de: "Sobald Sie auf die Schaltfläche Benutzerkonto löschen klicken, wird Ihr Konto mit allen verbundenen Daten sofort und endgültig gelöscht.",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATE_ACCOUNT_MESSAGE: {
    en: "Experience all the features by activating your account. Activate your account by clicking on the button below.",
    de: "Aktivieren Sie Ihr Benutzerkonto, um alle Funktionen nutzen zu können.",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATED_MESSAGE: {
    en: "Your account has been activated successfully!",
    de: "Dein Benutzerkonto wurde erfolgreich aktiviert!",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATE_ACCOUNT_SUCCESS_MESSAGE: {
    en: "Mail has been successfully sent to your E-Mail Adress",
    de: "Eine Aktivierungsmail wurde an dein Konto gesendet. Bitte überprüfe auch den Spam Ordner.",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATE_ACCOUNT_ERROR_MESSAGE: {
    en: "Could not send mail to your email, Please try again!",
    de: "Beim senden der Aktivierungsmail ist ein Fehler aufgetreten!",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_FIRST_NAME_TITLE: {
    en: "First Name",
    de: "Vorname",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_LAST_NAME_TITLE: {
    en: "Last Name",
    de: "Nachname",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_SALUTATION_MESSAGE: {
    en: "Please enter Salutation",
    de: "Die Anrede ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_FIRST_NAME_MESSAGE: {
    en: "First name is required",
    de: "Der Vorname ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_LAST_NAME_MESSAGE: {
    en: "Last name is required",
    de: "Der Nachname ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_USERNAME_INVALID_MESSAGE: {
    en: "The Usrename is already been taken!",
    de: "Der Benutzername ist schon vergeben!",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_USERNAME_MESSAGE: {
    en: "Username is required",
    de: "Der Benutzername ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_UPDATE_SUCCESS_MESSAGE: {
    en: "Profile details updated successfully!",
    de: "Die Profil wurden erfolgreich aktualisert!",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_UPDATE_FAILURE_MESSAGE: {
    en: "Profile Details updation unsuccessful",
    de: "Fehler beim aktualiseren der Profil!",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_TITLE: {
    en: "Profile",
    de: "Profil",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_SALUTATION_LABEL: {
    en: "Salutation",
    de: "Anrede",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_TITLE_LABEL: {
    en: "Title",
    de: "Titel",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_NAME_TITLE: {
    en: "Name",
    de: "Name",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_USERNAME_TITLE: {
    en: "Username",
    de: "Benutzername",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_SUBMIT: {
    en: "Save",
    de: "Speichern",
  },
  ACCOUNT_SETTINGS_PERSONAL_INFORMATION_LOADING: {
    en: "Loading...",
    de: "Lade...",
  },

  // ACCOUNT SETTINGS SIGN-IN SETTINGS

  ACCOUNT_SETTINGS_SIGN_IN_EMAIL_UPDATE_SUCCESS_MESSAGE: {
    en: "Email is updated successfully",
    de: "E-Mail wurde erfolgreich aktualisiert",
  },
  ACCOUNT_SETTINGS_SIGN_IN_EMAIL_UPDATE_FAILURE_MESSAGE: {
    en: "email updation unsuccessful",
    de: "Fehler beim aktualiseren der E-Mail",
  },
  ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_UPDATE_SUCCESS_MESSAGE: {
    en: "password is updated successfully",
    de: "Das Passwort wurde erfolgreich aktualisiert!",
  },
  ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_UPDATE_ERROR_MESSAGE: {
    en: "password updation unsuccessful",
    de: "Fehler beim aktualisieren des Passworts!",
  },
  ACCOUNT_SETTINGS_SIGN_IN_EMAIL_MESSAGE: {
    en: "Wrong email format",
    de: "Falsches E-Mail format!",
  },
  ACCOUNT_SETTINGS_SIGN_IN_EMAIL_MAX_MESSAGE: {
    en: "Maximum 60 symbols",
    de: "Maximal 60 Zeichen",
  },
  ACCOUNT_SETTINGS_SIGN_IN_EMAIL_REQUIRED_MESSAGE: {
    en: "Email is required field",
    de: "Die E-Mail-Adresse ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_REQUIRED_MESSAGE: {
    en: "password required",
    de: "Das Passwort ist ein Pflichtfeld",
  },
  ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_FORMAT_MESSAGE: {
    en: "Password must be atleast 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character!",
    de: "Das Passwort muss mindestens 8 Zeichen lang sein, mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen enthalten!",
  },
  ACCOUNT_SETTINGS_SIGN_IN_CONFIRM_PASSWORD_REQUIRED: {
    en: "Confirm password is required!",
    de: "Bitte wiederholen Sie das Passwort!",
  },
  ACCOUNT_SETTINGS_SIGN_IN_ENABLE: {
    en: "Enable",
    de: "Aktivieren",
  },
  ACCOUNT_SETTINGS_SIGN_IN_PASSWORD_MUST_MATCH: {
    en: "Passwords must match!",
    de: "Die Passwörter stimmen nicht überein!",
  },
  ACCOUNT_SETTINGS_SIGN_IN_CHANGE_EMAIL_TITLE: {
    en: "Change Email",
    de: "E-Mail-Adresse",
  },
  ACCOUNT_SETTINGS_SIGN_IN_CURRENT_EMAIL_LABEL: {
    en: "Current E-mail",
    de: "Aktuelle E-Mail-Adresse",
  },
  ACCOUNT_SETTINGS_SIGN_IN_NEW_EMAIL_LABEL: {
    en: "New Email",
    de: "Neue E-Mail-Adresse",
  },
  ACCOUNT_SETTINGS_SIGN_IN_RESET_PASSWORD_TITLE: {
    en: "Change Password",
    de: "Passwort ändern",
  },
  ACCOUNT_SETTINGS_SIGN_IN_CURRENT_PASSWORD_LABEL: {
    en: "Current Password",
    de: "Aktuelles Passwort",
  },
  ACCOUNT_SETTINGS_SIGN_IN_NEW_PASSWORD_LABEL: {
    en: "New Password",
    de: "Neues Passwort",
  },
  ACCOUNT_SETTINGS_SIGN_IN_CONFIRM_PASSWORD_LABEL: {
    en: "Confirm Password",
    de: "Neues Passwort wiederholen",
  },
  ACCOUNT_SETTINGS_SIGN_IN_SUBMIT: {
    en: "Save",
    de: "Speichern",
  },
  ACCOUNT_SETTINGS_SIGN_IN_LOADING: {
    en: "Loading...",
    de: "Bitte warten...",
  },
  USERS_SECURE_ACCOUNT: {
    en: "Secure Your Account",
    de: "Sichern Sie Ihr Konto",
  },
  USERS_AUTHENTICATION_INFO: {
    en: "Two-factor authentication adds an extra layer of security to your account. To log in, in addition you'll need to provide a 6 digit code",
    de: "Die Zwei-Faktor-Authentifizierung verleiht Ihrem Konto eine zusätzliche Sicherheitsebene. Um sich anzumelden, müssen Sie zusätzlich einen 6-stelligen Code eingeben",
  },
  USERS_AUTHNETICATION: {
    en: "Two-factor Authentication",
    de: "Zwei-Faktor-Authentifizierung",
  },
  USERS_VERIFICATION_ENABLED_TEXT: {
    en: "Enabled",
    de: "Aktiviert",
  },
  USERS_VERIFICATION_DISABLED_TEXT: {
    en: "Disabled",
    de: "Deaktiviert",
  },
  USERS_DISABLE_BUTTON: {
    en: "Disable",
    de: "Deaktivieren",
  },
  USERS_AUTH_MODAL_TITLE: {
    en: "Choose An Authentication Method",
    de: "Wählen Sie eine Authentifizierungsmethode",
  },
  USERS_LOGIN_INFO: {
    en: "In addition to your username and password, you’ll have to enter a code (delivered via app) to log into your account.",
    de: "Zusätzlich zu Ihrem Benutzernamen und Passwort müssen Sie einen Code eingeben (per App), um sich in Ihr Konto einzuloggen.",
  },
  USERS_AUTHENTICATOR_APP_TITLE: {
    en: "Authenticator Apps",
    de: "Authentifizierungs APP",
  },
  USERS_AUTHENTICATOR_APP_INFO: {
    en: "Get codes from an app like Google Authenticator, Microsoft Authenticator, Authy or 1Password.",
    de: "Holen Sie sich Codes von einer App wie Google Authenticator, Microsoft Authenticator, Authy oder 1Password",
  },
  CONTINUE_BUTTON: {
    en: "Continue",
    de: "Weiter",
  },
  CLOSE_BUTTON: {
    en: "Close",
    de: "Schließen",
  },
  USERS_PASSWORD_FORM_ERROR: {
    en: "You’ll have to enter your password , before enabling Two-factor authentication",
    de: "Sie müssen Ihr Passwort eingeben, bevor Sie die Zwei-Faktor-Authentifizierung aktivieren können.",
  },
  USERS_PASSWORD_FIELD: {
    en: "Password",
    de: "Passwort",
  },
  USERS_PASSWORD_PLACEHOLDER: {
    en: "Please enter your password",
    de: "Bitte geben Sie ein Passwort ein",
  },
  SUBMIT_BUTTON: {
    en: "Submit",
    de: "Absenden",
  },
  OKAY_BUTTON: {
    en: "Okay",
    de: "OK",
  },
  USERS_AUTHENTICATOR_QR_CODE_TEXT1: {
    en: "Using an authenticator app like ",
    de: "Mit einer Authentifizierungs-App wie ",
  },
  USERS_AUTHRNTICATOR_APP1: {
    en: "Google Authenticator",
    de: "Google Authenticator",
  },
  USERS_AUTHRNTICATOR_APP2: {
    en: " Microsoft Authenticator",
    de: " Microsoft Authenticator",
  },
  USERS_AUTHRNTICATOR_APP3: {
    en: " Authy",
    de: " Authy",
  },
  USERS_AUTHRNTICATOR_APP4: {
    en: " 1Password",
    de: " 1Password",
  },
  USERS_AUTHENTICATOR_QR_CODE_TEXT2: {
    en: " , scan the QR code. It will generate a 6 digit code for you to enter below.",
    de: " , scannen Sie den QR-Code. Es wird ein 6-stelliger Code generiert, den Sie unten eingeben müssen.",
  },
  USERS_LOGIN_RECOVERY_MODE: {
    en: "Recovery Code",
    de: "Wiederherstellungscode",
  },
  USERS_LOGIN_AUTHENTICATOR_CODE: {
    en: "Authenticator Code",
    de: "Authentifizierungscode",
  },
  USERS_CONFIRM_PASSWORD_AUTH: {
    en: " You’ll have to enter your password , before disabling Two-factor authentication",
    de: "Bitte geben Sie Passwort ein, bevor Sie die 2 Faktor authentifizierung deaktivieren können",
  },
  USERS_CONFIRM_PASSWORD_DELETE_ACCOUNT_MESSAGE: {
    en: "Eiusmod aute nostrud veniam laboris laborum reprehenderit in elit adipisicing id. Nostrud nisi nostrud nostrud officia pariatur est ad occaecat esse proident id quis. Nulla reprehenderit sint mollit pariatur anim magna.",
    de: "",
  },
  USERS_CONFIRM_PASSWORD_MODAL_TITLE: {
    en: "Confirm password",
    de: "Passwort bestätigen",
  },
  USERS_AUTH_LOADING_MESSAGE: {
    en: "Generating QR code...",
    de: "Generiere QR-Code...",
  },
  USERS_QR_CODE_FAILURE_MESSAGE: {
    en: "There was an issue generting codes, please try again!",
    de: "Beim generieren des QR-Codes ist ein Fehler aufgetreten!",
  },
  USERS_ENABLING_VERIFICATION_MESSAGE: {
    en: "Enabling Two-factor authentication...",
    de: "Aktiviere Two-factor Authentifizierung, bitte warten...",
  },
  USERS_INCORRECT_PASSWORD_MESSAGE: {
    en: "Incorrect password, please try again!",
    de: "Das Passwort ist falsch, bitte versuchen Sie es noch mal!",
  },
  USERS_LOADING_MESSAGE: {
    en: "Please wait...",
    de: "Bitte warten...",
  },
  USERS_INCORRECT_PASSOWRD: {
    en: "Incorrect password, please try again!",
    de: "Das Passwort ist falsch!",
  },
  USERS_STATUS_FIELD: {
    en: "status",
    de: "Status",
  },
  USERS_DISABLE_VERIFICATION_SUCCESS_MESSAGE: {
    en: "Two-factor authentication disabled successfully!",
    de: "Two-factor Authentifizierung wurde erfolgreich deaktiviert!",
  },
  USERS_DISABLE_VERIFICATION_FAILURE_MESSAGE: {
    en: "Disabling Two-factor authentication failed, Please try again!",
    de: "Beim deaktivieren der Two-factor Authentifizierung ist ein Fehler aufgetreten, bitte versuchen Sie es noch mal!",
  },
  USERS_DISABLING_LOADING_MESSAGE: {
    en: "Disabling...",
    de: "Deaktiviere...",
  },

  USERS_INCORRECT_AUTH_CODE: {
    en: "Incorrect Authentication Code, please try again!",
    de: "Falscher Authentifizierungscode, bitte versuchen Sie es erneut!",
  },
  USERS_AUTHENTICATOR_QR_CODE_TEXT3: {
    en: "If you having trouble using the QR code, select manual entry on your app, and enter your username and the code",
    de: "Wenn Sie Probleme bei der Verwendung des QR-Codes haben, wählen Sie in Ihrer App die manuelle Eingabe und geben Sie Ihren Benutzernamen und den Code ein",
  },
  PAGES_ALL_FAVOURITE_POSTS_ERROR_MESSAGE: {
    en: "There is some issue.Please try again later",
    de: "Es gibt ein Problem, bitte versuchen Sie es später noch einmal!",
  },
  PAGES_LOAD_MORE: {
    en: "Load More",
    de: "Mehr laden",
  },
  PAGES_ALL_FAVOURITE_POSTS_TITLE: {
    en: "Favourite Posts",
    de: "Favoriten",
  },
  PAGES_404_TITLE: {
    en: "Error 404",
    de: "Fehler 404",
  },
  PAGES_404_MESSAGE_1: {
    en: "Looks like you're lost",
    de: "Sieht aus, als hätten Sie sich verirrt",
  },
  PAGES_404_MESSAGE_2: {
    en: "The page you are looking for is not available!",
    de: "Die von Ihnen gesuchte Seite ist nicht verfügbar!",
  },
  PAGES_404_GO_HOME: {
    en: "Go to Home",
    de: "Zurück zur Startseite",
  },
  SHARED_COMPONENTS_GALLERY_TITLE: {
    en: "Gallery",
    de: "Galerie",
  },
  SHARED_COMPONENTS_DETAILS_PAGE_POST_READ: {
    en: "Post Read",
    de: "Beitrag gelesen",
  },
  SHARED_COMPONENTS_NO_RESULTS: {
    en: "No results found",
    de: "Es wurden keine Ergebnisse gefunden",
  },
  SHARED_COMPONENTS_EVENT_PRODUCT_EUR: {
    en: "EUR",
    de: "EUR",
  },
  SHARED_COMPONENTS_EVENT_PRODUCT_INCLUSIVE_OF_ALL_TAXES: {
    en: "inclusive of all taxes",
    de: "inkl. aller Steuern",
  },
  EVENTS_ADDRESS_ONLINE_DETAILS: {
    en: "Online",
    de: "Online",
  },
  EVENT_DETAILS_FIELDSET_LEGEND_TITLE: {
    en: "Event",
    de: "Veranstaltung",
  },
  EVENT_HOST_DETAILS_FIELDSET_LEGEND_TITLE: {
    en: "Host",
    de: "Veranstalter",
  },
  EVENTS_ADDRESS_LOCATION_DETAILS: {
    en: "Location",
    de: "Lokal",
  },
  SHARED_COMPONENTS_EVENT_PRODUCT_EXCLUSIVE_OF_ALL_TAXES: {
    en: "exclusive of all taxes",
    de: "excl. aller Steuern",
  },
  SHARED_COMPONENTS_EVENT_PRODUCT_NO_PRODUCTS: {
    en: "No product found",
    de: "Es wurde kein Produkt gefunden",
  },
  SHARED_COMPONENTS_EVENTS_CANT_REGISTER: {
    en: "Can not register, please try again later!",
    de: "Die Registrierung ist nicht möglich, bitte versuchen Sie es später noch einmal!",
  },
  SHARED_COMPONENTS_EVENTS_USER_ALREADY_REGISTERED: {
    en: "User is already registered!",
    de: "Der Benutzer ist bereits registriert!",
  },
  SHARED_COMPONENTS_EVENTS_ONLINE_EVENT: {
    en: "Online",
    de: "Online",
  },
  SHARED_COMPONENTS_EVENTS_MAP_ERROR_MESSAGE: {
    en: "Cannot detect precise location",
    de: "Der genaue Standort kann nicht ermittelt werden!",
  },
  SHARED_COMPONENTS_EVENTS_BOOKING_OPENS_IN: {
    en: "Booking opens in ",
    de: "",
  },
  SHARED_COMPONENTS_EVENTS_DAYS: {
    en: " days",
    de: " Tage",
  },
  SHARED_COMPONENTS_EVENTS_HOURS: {
    en: " hours",
    de: " Stunden",
  },
  SHARED_COMPONENTS_EVENTS_SUCCESS_TOAST: {
    en: "Successfully registered!",
    de: "Erfolgreich registriert!",
  },
  SHARED_COMPONENTS_EVENTS_SUCCESS: {
    en: "Success",
    de: "Erfolg",
  },
  SHARED_COMPONENTS_EVENTS_CONTACT: {
    en: "Contact",
    de: "Kontakt",
  },
  SHARED_COMPONENTS_EVENTS_SHOW_MAP: {
    en: "Show map",
    de: "Zeige Karte",
  },
  SHARED_COMPONENTS_EVENTS_HIDE_MAP: {
    en: "Hide map",
    de: "Blende Karte aus",
  },
  SHARED_COMPONENTS_EVENTS_STARTS_FROM: {
    en: "possible from",
    de: "möglich ab",
  },
  SHARED_COMPONENTS_EVENTS_REGISTRATION: {
    en: "Registration ",
    de: "Anmeldung",
  },
  SHARED_COMPONENTS_EVENTS_TILL: {
    en: "till",
    de: "bis",
  },
  SHARED_COMPONENTS_FOLLOW_UP_EVENTS_TITLE: {
    en: "Follow Up Events",
    de: "Folgeveranstaltungen",
  },
  SHARED_COMPONENTS_NAVBAR_MODAL_FAVOURITE_POSTS: {
    en: "Favourite Posts",
    de: "Favoriten",
  },
  SHARED_COMPONENTS_NAVBAR_MODAL_POSTS_READ: {
    en: "Posts Read",
    de: "Gelesene Beiträge",
  },
  SHARED_COMPONENTS_NAVBAR_MODAL_LOGIN: {
    en: "Login/Register",
    de: "Anmeldung / Benutzerkontoregistrierung",
  },
  SHARED_COMPONENTS_TOPBAR_ACCOUNT_SETTINGS: {
    en: "Account Settings",
    de: "Benutzerkonto",
  },
  SHARED_COMPONENTS_TOPBAR_LOGOUT: {
    en: "Sign out",
    de: "Abmelden",
  },
  SHARED_COMPONENTS_OFFERS_TITLE: {
    en: "OFFER FOR",
    de: "",
  },
  SHARED_COMPONENTS_OFFERS_OFFER_NUMBER: {
    en: "OFFER NUMBER",
    de: "",
  },
  SHARED_COMPONENTS_OFFERS_ISSUE_DATE: {
    en: "ISSUE DATE",
    de: "",
  },
  SHARED_COMPONENTS_OFFERS_EXPIRATION_DATE: {
    en: "EXPIRATION DATE",
    de: "",
  },
  SHARED_COMPONENTS_OFFERS_DESCRIPTION: {
    en: "DESCRIPTION",
    de: "",
  },
  SHARED_COMPONENTS_OFFERS_TAX_CODE: {
    en: "Tax Code",
    de: "",
  },
  SHARED_COMPONENTS_OFFERS_AMOUNT: {
    en: "AMOUNT",
    de: "",
  },
  SHARED_COMPONENTS_OFFERS_SUB_TOTAL: {
    en: "Sub Total",
    de: "",
  },
  SHARED_COMPONENTS_OFFERS_TAX: {
    en: "Tax",
    de: "",
  },
  SHARED_COMPONENTS_OFFERS_0_VALUE: {
    en: "0.00",
    de: "",
  },
  SHARED_COMPONENTS_OFFERS_TOTAL: {
    en: "Total",
    de: "",
  },
  SHARED_COMPONENTS_MODAL_ADDITIONAL_FIELDS: {
    en: "Add additional Fields",
    de: "Optionale Angaben wie eine Bestellnummer für Ihre Rechnung",
  },
  SHARED_COMPONENTS_MODAL_ADD: {
    en: "Add",
    de: "weitere Angabe hinzufügen",
  },
  SHARED_COMPONENTS_REQUIRED: {
    en: "Required",
    de: "Pflichtfeld",
  },
  SHARED_COMPONENTS_REGISTER_FAIL_MESSAGE: {
    en: "Oops!Cannot Register.Please try again later",
    de: "",
  },
  SHARED_COMPONENTS_CONTACT_ADMIN: {
    en: "Ooops... Please contact System Administrator",
    de: "",
  },
  SHARED_COMPONENTS_EVENT_REGISTRATION: {
    en: "Event Registration",
    de: "",
  },
  SHARED_COMPONENTS_FLOW_MODAL_TITLE: {
    en: "Select Flow",
    de: "Buchung",
  },
  SHARED_COMPONENTS_FLOW_MODAL_SELECT: {
    en: "Please select any one below process to buy product.",
    de: "Wie möchten Sie weiter fortfahren?",
  },
  SHARED_COMPONENTS_FLOW_MODAL_PRODUCT_FLOW: {
    en: "Product flow",
    de: "Produkt kaufen",
  },
  SHARED_COMPONENTS_BUY_PRODUCT: {
    en: "Buy Product",
    de: "Produkt kaufen",
  },
  SHARED_COMPONENTS_JOIN_EVENT: {
    en: "Join Event",
    de: "Veranstaltung beitreten",
  },
  SHARED_COMPONENTS_BUY_SUBSCRIPTION: {
    en: "Buy Subscription",
    de: "Abonnements",
  },
  SHARED_COMPONENTS_FLOW_MODAL_PRODUCT_DESC: {
    en: "this is product flow, you can directly purchase.",
    de: "Hier können Sie das Produkt direkt kaufen und nutzen.",
  },
  SHARED_COMPONENTS_FLOW_MODAL_OFFERS_FLOW: {
    en: "Offers flow",
    de: "Angebot anfordern",
  },
  SHARED_COMPONENTS_FLOW_MODAL_OFFERS_DESC: {
    en: "this is offers flow, you can get an offer from this option and purchase .",
    de: "Es besteht die Möglichkeit, ein Angebot im Voraus anzufordern und später beim Eintritt in den Bestellprozess auf das Angebot zurückzugreifen.",
  },
  SHARED_COMPONENTS_FLOW_MODAL_: {
    en: "Gallery",
    de: "Galerie",
  },
  SHARED_COMPONENTS_SUBSCRIBE: {
    en: "Subscribe",
    de: "Abonieren",
  },
  SHARED_COMPONENTS_: {
    en: "Gallery",
    de: "Galerie",
  },
  LABEL_EMAIL: {
    en: "Email",
    de: "E-Mail",
  },
  LABEL_IMAGE_URL: {
    en: "Please enter image url",
    de: "",
  },
  IMAGE_URL_ERROR: {
    en: "Please enter valid image url",
    de: "",
  },
  LABEL_CODE: {
    en: "Code",
    de: "Code",
  },
  LABEL_NEW_PASSWORD: {
    en: "New Password",
    de: "Neues Passwort",
  },
  LABEL_CONFIRM_PASSWORD: {
    en: "Confirm Password",
    de: "Passwort wiederholen",
  },
  LABEL_FIRST_NAME: {
    en: "First Name",
    de: "Vorname",
  },
  LABEL_LAST_NAME: {
    en: "Last Name",
    de: "Nachname",
  },
  LABEL_PASSWORD: {
    en: "Password",
    de: "Passwort",
  },
  LABEL_ENTER_SEARCH_TEXT: {
    en: "Search",
    de: "Suchen",
  },
  LABEL_SELECT: {
    en: "Select",
    de: "Auswählen",
  },
  LABEL_FULL_NAME: {
    en: "Full Name",
    de: "Name",
  },
  TITLE_POST: {
    en: "Posts",
    de: "Beiträge",
  },
  TITLE_EVENT: {
    en: "Events",
    de: "Veranstaltungen",
  },
  TITLE_READ_POSTS: {
    en: "Read Posts",
    de: "Gelesene Beiträge",
  },
  TITLE_CATEGORY: {
    en: "category",
    de: "Kategorie",
  },
  TITLE_LIST_VIEW: {
    en: "list view",
    de: "Listenansicht",
  },
  TITLE_GRID_VIEW: {
    en: "grid view",
    de: "Gitteransicht",
  },
  TITLE_GROUP: {
    en: "Group",
    de: "Gruppen",
  },
  TITLE_ADVERTISE: {
    en: "Advertise",
    de: "Anzeige",
  },
  TITLE_PRODUCTS: {
    en: "Products",
    de: "Produkte",
  },
  TITLE_DEVICES: {
    en: "Manage Devices",
    de: "Geräteverwaltung",
  },
  TITLE_SUBSCRIPTIONS: {
    en: "Subscriptions",
    de: "Abonnements",
  },
  TITLE_OFFERS: {
    en: "Offers",
    de: "Angebote",
  },
  TITLE_DELETE_ACCOUNT: {
    en: "Delete Account",
    de: "Benutzerkonto löschen",
  },
  TITLE_ACTIVATE_ACCOUNT: {
    en: "Activate Account",
    de: "Kontoaktivierung",
  },
  TITLE_ADDITIONAL_DETAILS: {
    en: "Additional Details",
    de: "",
  },
  TITLE_TITLE: {
    en: "Title",
    de: "Titel",
  },
  TITLE_SALUTATION: {
    en: "Salutation",
    de: "Anrede",
  },
  TITLE_OFFERS_MESSAGE: {
    en: "Please enter the code sent to your Email",
    de: "",
  },
  OFFERS_OFFER_NUMBER: {
    en: "Offer Number",
    de: "Angebotsnummer",
  },
  OFFERS_EXPIRES_ON: {
    en: "Offer Ends On",
    de: "Angebot endet am",
  },
  OFFERS_DOWNLOADING: {
    en: "Downloading...",
    de: "Lade herunter...",
  },
  FILE_DOWNLOAD_SUCCESSFUL: {
    en: "File downloaded successfully",
    de: "",
  },
  OFFERS_DOWNLOAD_SUCCESSFUL: {
    en: "Offer downloaded successfully",
    de: "Das Angebot wurde erfolgreich heruntergeladen",
  },
  OFFERS_REMINDER_SUCCESSFUL: {
    en: "Reminder set successfully",
    de: "",
  },
  OFFERS_REMINDER_UNSUCCESSFUL: {
    en: "There is some issue setting the reminder",
    de: "",
  },
  OFFERS_SET_REMINDER: {
    en: "Set Reminder",
    de: "",
  },
  ADD_COMPANY_ADDRESS_INFO: {
    en: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore enim laudantium labore hic corrupti minus iure? Explicabo ut asperiores quis.",
    de: "",
  },
  ADD_COMPANY_ADDRESS_WITH_CODE: {
    en: "Add address with code",
    de: "Mit Anmelde-Code beitreten",
  },
  ADD_NEW_COMPANY_ADDRESS: {
    en: "Add new address",
    de: "Neu erstellen",
  },
  ADD_COMPANY_ADDRESS_TITLE: {
    en: "Add new company address",
    de: "Unternehmen hinzufügen",
  },
  ADD_COMPANY_ADDRESS_WITH_CODE_INFO: {
    en: "Use the code shared with your company to add address on your account.",
    de: "",
  },
  ADD_NEW_COMPANY_ADDRESS_INFO: {
    en: "If you dont have a company address code, create a new address for the company.",
    de: "",
  },
  ADD_COMPANY_ADDRESS_WRAPPER_INFO: {
    en: "Here you can create a new company or join an existing one by entering a code.",
    de: "Hier können Sie ein neues Unternehmen erstellen oder einem bestehendem Unternehmen per Einaldungs-Code beitreten.",
  },
  COMPANY_ADDRESS_WITH_CODE: {
    en: "Company Address Code",
    de: "",
  },
  NEWSLETTER_FORM_SUBMIT_SUCCESS: {
    en: "Newsletter subscription successful. Please check your mail for activate your account. ",
    de: "",
  },
  NEWSLETTER_FORM_SUBMIT_FAILED: {
    en: "Newsletter Subscription Unsuccessful.",
    de: "Newsletter-Anmeldung fehlgeschlagen.",
  },
  NEWSLETTER_ACTIVATION_INPROGRESS: {
    en: "Your Newsletter Activation is in Progress. Please Wait...",
    de: "Ihre Newsletter-Aktivierung ist in Bearbeitung. Warten Sie mal...",
  },
  NEWSLETTER_DEACTIVATION_INPROGRESS: {
    en: "Your Newsletter Deactivation is in Progress. Please Wait...",
    de: "Ihre Newsletter-Deaktivierung ist in Bearbeitung. Warten Sie mal...",
  },
  NEWSLETTER_ACTIVATION_FAILED: {
    en: "Activation Failed. Please try again later.",
    de: "Aktivierung fehlgeschlagen. Bitte versuchen Sie es später erneut.",
  },
  NEWSLETTER_DEACTIVATION_FAILED: {
    en: "Deactivation Failed. Please try again later.",
    de: "Deaktivierung fehlgeschlagen. Bitte versuchen Sie es später erneut.",
  },
  NEWSLETTER_CONFIRMATION: {
    en: "Thanks for your Confirmation",
    de: "Danke für deine Bestätigung",
  },
  SIGNUP: {
    en: "Signup",
    de: "",
  },
  HAVE_VERIFICATION_CODE: {
    en: "have verification code?",
    de: "",
  },
  RELATED_POSTS: {
    en: "Related Posts",
    de: "Verwandte Beiträge",
  },
  POST_RATINGS_TITLE: {
    en: "Ratings",
    de: "Bewertungen",
  },
  POST_RATINGS_ADD_MODAL_TITLE: {
    en: "Add Rating",
    de: "Bewertung erstellen",
  },
  POST_RATINGS_ADD_MODAL_TEXTAREA_PLACEHOLDER: {
    en: "Whats on your mind?",
    de: "Möchten Sie noch einen Kommentar hinterlassen?",
  },
  POST_RATINGS_ADD_MODAL_POST_BUTTON: {
    en: "Post",
    de: "Beitrag",
  },
  POST_RATINGS_ADD_MODAL_POST_BUTTON_LOADING: {
    en: "Loading...",
    de: "Bitte warten...",
  },
  POST_RATINGS_QUESTION: {
    en: "Whats on your mind",
    de: "Was denken Sie?",
  },
  POST_RATING_DELETE_SUCCESS_MESSAGE: {
    en: "Successfully deleted ratings to the post!!",
    de: "Die Bewertung wurde erfolgreich gelöscht",
  },
  POST_RATING_DELETE_FAILURE_MESSAGE: {
    en: "Can not delete ratings to the post!, please try again!!",
    de: "Beim löschen der Bewertung ist ein Fehler aufgetreten!",
  },
  POST_RATING_UPDATE_TITLE: {
    en: "Update",
    de: "Bearbeiten",
  },
  POST_RATING_DELETE_TITLE: {
    en: "Delete",
    de: "Löschen",
  },
  POST_RATING_HIDE_COMMENTS_TITLE: {
    en: "hide comments",
    de: "Kommentare verbergen",
  },
  POST_RATING_REPLIES_MESSAGE: {
    en: "replies",
    de: "Antworten",
  },
  POST_RATING_REPLY_MESSAGE: {
    en: "reply",
    de: "Antworten",
  },
  POST_RATINGS_UPDATE_SUCCESS_MESSAGE: {
    en: "Successfully updated ratings to the post!",
    de: "Die Bewertung wurde erfolgreich aktualisiert",
  },
  POST_RATINGS_UPDATE_FAILURE_MESSAGE: {
    en: "Can not update ratings to the post!, please try again!",
    de: "Bei der Aktualisierung der Bewertung ist ein Fehler aufgetreten.",
  },
  POST_RATINGS_ADD_SUCCESS_MESSAGE: {
    en: "Successfully added ratings to the post!",
    de: "Die Bewertung wurde erfolgreich erstellt!",
  },
  POST_RATINGS_ADD_FAILURE_MESSAGE: {
    en: "Can not add ratings to the post!, please try again!!",
    de: "Bei der Erstellung der Bewertung ist ein Fehler aufgetreten, bitte versuchen Sie es später noch mal!",
  },
  POST_RATINGS_ADD_REPLY_BUTTON: {
    en: "Add Reply",
    de: "Antwort erstellen",
  },
  POST_RATINGS_REPLYING_TO: {
    en: "Replying to",
    de: "",
  },
  COMMUNITIES_TITLE: {
    en: "Communities",
    de: "Communities",
  },
  COMMUNITIES_MEMBERS: {
    en: "members",
    de: "Mitglieder",
  },
  COMMUNITIES_PRIVATE: {
    en: "Private",
    de: "Privat",
  },
  COMMUNITIES_PUBLIC: {
    en: "Public",
    de: "Öffentlich",
  },
  COMMUNITIES_MY: {
    en: "My communities",
    de: "Meine Communities",
  },
  COMMUNITIES_JOINED: {
    en: "Joined",
    de: "Beigetreten",
  },
  COMMUNITIES_REQUESTED: {
    en: "Requested",
    de: "",
  },
  COMMUNITIES_JOIN: {
    en: "Join",
    de: "Beitreten",
  },
  COMMUNITIES_ALL: {
    en: "All",
    de: "Alle",
  },
  COMMUNITIES_JOIN_SUCCESS_MESSAGE: {
    en: "Joined Community successfully",
    de: "",
  },
  COMMUNITIES_JOIN_REQUEST_SUCCESS_MESSAGE: {
    en: "Join request sent successfully",
    de: "",
  },
  COMMUNITIES_JOIN_REQUEST_ERROR_MESSAGE: {
    en: "Could not request to join community, Please try again!",
    de: "",
  },
  COMMUNITIES_JOIN_ERROR_MESSAGE: {
    en: "Could not join community, Please try again!",
    de: "",
  },
  COMMUNITIES_ABOUT: {
    en: "About",
    de: "",
  },
  COMMUNITIES_SHOW_MORE: {
    en: "Show more comments",
    de: "",
  },
  COMMUNITIES_SEE_LESS: {
    en: "See less",
    de: "",
  },
  COMMUNITIES_PRIVATE_MESSAGE: {
    en: "Only members can see who's in the group and what they post.",
    de: "",
  },
  COMMUNITIES_PUBLIC_MESSAGE: {
    en: "Anyone can see who's in the group and what they post.",
    de: "",
  },
  COMMUNITIES_ADD_POST_SUCCESS: {
    en: "added post successfully",
    de: "",
  },
  COMMUNITIES_ADD_POST_FAILURE: {
    en: "add post unsuccessful",
    de: "",
  },
  COMMUNITIES_ADD_POST_FAILURE_NOT_JOINED: {
    en: "You cannot add post as you haven't joined the community",
    de: "",
  },
  COMMUNITIES_NEW_POST: {
    en: "New Post",
    de: "",
  },
  COMMUNITIES_UPDATE_POST: {
    en: "Update Post",
    de: "",
  },
  COMMUNITIES_COMMENT: {
    en: "Comment",
    de: "",
  },
  COMMUNITIES_COMMENTS: {
    en: "comments",
    de: "",
  },
  COMMUNITIES_UPDATING_COMMENT: {
    en: "Updating comment",
    de: "",
  },
  COMMUNITIES_UPDATING_POST: {
    en: "Updating Post",
    de: "",
  },
  COMMUNITIES_REPLYING_TO: {
    en: "Replying to ",
    de: "",
  },
  COMMUNITIES_SEE_MORE: {
    en: "See more",
    de: "Mehr erfahren",
  },
  COMMUNITIES_CANCEL: {
    en: "Cancel",
    de: "Abbrechen",
  },
  AUTHOR_PROFILE_DESCRIPTION_HEADING: {
    en: "Description:",
    de: "Beschreibung",
  },
  ACTIVATION_TITLE: {
    en: "Account not activated",
    de: "Benutzerkonto nicht bestätigt",
  },
  ACTIVATION_SUB_TITLE: {
    en: "Please activate your account to experience all the features.",
    de: "Bitte aktivieren Sie Ihr Benutzerkonto",
  },
  ACTIVATION_BUTTON: {
    en: "Activate",
    de: "Aktivieren",
  },
  ACTIVATING_BUTTON: {
    en: "Activating...",
    de: "Aktiviere...",
  },
  SKIP_BUTTON: {
    en: "Skip",
    de: "Überspringen",
  },
  ALERT_SUCCESS: {
    en: "Success",
    de: "",
  },
  ALERT_ERROR: {
    en: "Error",
    de: "Fehler",
  },
  ALERT_OK: {
    en: "OK",
    de: "OK",
  },
  ONLINE_TITLE: {
    en: "Online",
    de: "Online",
  },
  OFFLINE_TITLE: {
    en: "Location",
    de: "Vor Ort",
  },
  EVENT_CODE_TITLE: {
    en: "Code",
    de: "Code",
  },
  EVENT_DETAILS_COMING_SOON_MESSAGE: {
    en: "Event details will be coming soon.. !!",
    de: "Veranstaltungsdetails folgen später",
  },
  MEDIA_TITLE: {
    en: "Downloads",
    de: "Downloads",
  },
  DEADLINE_CONTINUE_READING_DAEDLINE: {
    en: "Preview",
    de: "mehr erfahren",
  },
  DEADLINE_PREVIEW_MODAL_TITLE: {
    en: "Deadline",
    de: "Frist",
  },
  DEADLINE_CALENDER_MODAL_DATE_TITLE: {
    en: "Deadlines on the",
    de: "",
  },
  DEADLINE_CALENDER_DETAIL_NO_DESCRIPTION: {
    en: "No description",
    de: "",
  },
  GENERAL_SEARCH_DROPDOWN_POSTS_VALUE: {
    en: "Posts",
    de: "",
  },
  GENERAL_SEARCH_DROPDOWN_EVENTS_VALUE: {
    en: "Events",
    de: "",
  },
  GENERAL_SEARCH_DROPDOWN_COURSES_VALUE: {
    en: "Courses",
    de: "",
  },
  GENERAL_SEARCH_NO_DATA_TEXT: {
    en: "No data to display for the search criteria.",
    de: "",
  },
  GENERAL_SEARCH_ERROR_MESSAGE: {
    en: "search text should be minimum 3 chars..",
    de: "",
  },
  General_SEARCH_PAGINATION_SITE_TEXT: {
    en: "Site",
    de: "",
  },
  GENERAL_SEARCH_PAGINATION_OF_TEXT: {
    en: "of",
    de: "",
  },
};
