import moment from "moment";
import React from "react";
import { IoMdContact } from "react-icons/io";
import { RiCalendarTodoFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocal } from "../../App";
import noImage from "../../assets/noImage.jpg";
import { formatDate } from "../../utils";
import LockIcon from "../LockIcon/LockIcon";
import PlayerIcon from "../PlayerIcon";
import "./style.scss";
const SidebarArticle = ({ details, type, relatedPosts }) => {
  const local = useLocal();
  const {
    title,
    post_thumbnail_url,
    post_type,
    id,
    post_media_resolutions,
    post_thumbnail_resolutions,
    post_media_url,
    published_at,
    is_bought,
    is_registered,
  } = details;
  // const { resolution } = useCustomMediaQuery();
  const posts_details_date_time_format = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.posts_general_settings
        ?.posts_details_date_time_format
  );

  const showLockIcon = useSelector(
    (state) =>
      state.generalDetails.generalDetails.general_settings
        .display_lock_icon_to_content
  );
  const setPostMediaSource = () => {
    if (relatedPosts) {
      return details?.post_type === "image"
        ? details?.post_media
        : details?.post_thumbnail;
    }
    if (type === "event-list") {
      if (
        details.event_image_resolutions &&
        details.event_image_resolutions.length > 0
      ) {
        const source = details.event_image_resolutions.filter(
          (element) => element?.resolution === "256x144"
        );
        return source[0].file_url;
      }
      return details.event_image_url ? details.event_image_url : noImage;
    } else {
      const imgResolutions =
        post_type === "image"
          ? post_media_resolutions
          : post_thumbnail_resolutions;
      const file_url =
        post_type === "image" ? post_media_url : post_thumbnail_url;
      if (imgResolutions && imgResolutions.length > 0) {
        const source = imgResolutions.filter(
          (element) => element?.resolution === "256x144"
        );
        return source[0].file_url;
      }
      return file_url ? file_url : noImage;
    }
  };

  const eventHostMedia = () => {
    if (
      details.contact_person_image_resolutions &&
      details.contact_person_image_resolutions.length > 0
    ) {
      const source = details.contact_person_image_resolutions.filter(
        (element) => {
          return element?.resolution === "256x144";
        }
      );
      return source[0]?.file_url;
    }
    return details.contact_person_image_url
      ? details.contact_person_image_url
      : noImage;
  };
  return (
    <Link
      to={
        type === "event-list"
          ? details?.seo_permalink
            ? `../event/${id}/${details?.seo_permalink}`
            : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
          : details?.seo_permalink
          ? `../post/${id}/${details?.seo_permalink}`
          : `../post/${id}/${title?.replace(/\s+/g, "-")}`
      }
      state={type === "event-list" ? { eventId: id } : { postId: id }}
    >
      <div className="d-flex mt-3 position-relative .article-two-container .img-container">
        {post_type === "video" || post_type === "audio" ? (
          <PlayerIcon type={post_type} size={"sidebarlistSize"} />
        ) : null}
        <div className="article-list-image position-relative">
          {type === "event-list" ||
          type === "event-carousel" ? null : showLockIcon === "yes" &&
            (!!is_bought || !!is_registered) ? null : (
            <LockIcon size={"xsmall"} />
          )}
          {}
          <Link
            to={
              type === "event-list"
                ? details?.seo_permalink
                  ? `../event/${id}/${details?.seo_permalink}`
                  : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
                : details?.seo_permalink
                ? `../post/${id}/${details?.seo_permalink}`
                : `../post/${id}/${title?.replace(/\s+/g, "-")}`
            }
            state={type === "event-list" ? { eventId: id } : { postId: id }}
          >
            <img
              src={setPostMediaSource()}
              alt=""
              className=" rounded-1 image-webkit-fill"
              style={{
                // height: "-webkit-fill-available",
                width: "100%",
                objectFit: "cover",
                overflow: "hidden",
                minHeight: "100%",
                maxHeight: "100%",
                opacity:
                  type === "event-list" || type === "event-carousel"
                    ? ""
                    : showLockIcon === "yes" && (!!is_bought || !!is_registered)
                    ? ""
                    : "50%",
              }}
            />
          </Link>
        </div>
        {type === "event-list" ? (
          <div className="article-list-content">
            <h6>
              <Link
                to={
                  details?.seo_permalink
                    ? `../event/${id}/${details?.seo_permalink}`
                    : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
                }
                state={{ eventId: id }}
                className=""
              >
                {title}
              </Link>
            </h6>
            <div className="d-flex align-items-center gap-1">
              {details.start_date ? (
                <ul className="list-inline fs-6">
                  <li className="list-inline-item">
                    <RiCalendarTodoFill style={{ marginRight: "0.5rem" }} />
                    <span className="sidebar-article-date">
                      {local && local === "en"
                        ? formatDate(details.start_date)
                        : moment(details.start_date).format("DD.MM.yyyy")}
                    </span>
                  </li>
                </ul>
              ) : null}
              {details?.contact_person_image_url ? (
                <div className="d-flex justify-content-center align-items-center">
                  <IoMdContact
                    className="event-icons no-background"
                    style={{ marginRight: "0" }}
                  />
                  <img
                    className=" mx-1"
                    style={{
                      height: "16px",
                      width: "auto",
                      objectFit: "contain",
                    }}
                    src={details?.contact_person_image_url}
                    alt="host-profile"
                  />
                </div>
              ) : details?.contact_person_name ? (
                <div className="d-flex align-items-center gap-1 pt-1">
                  <div className="host_profile_sidebar position-relative">
                    <span
                      className="position-absolute"
                      style={{ marginLeft: "4px", fontSize: "10px" }}
                    >
                      {details?.contact_person_name.charAt(0)}
                    </span>
                  </div>
                  <span
                    className="fw-lighter text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    {details?.contact_person_name}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="article-list-content">
            {published_at && posts_details_date_time_format !== "none" ? (
              <ul className="list-inline fs-6">
                <li className="list-inline-item">
                  <RiCalendarTodoFill style={{ marginRight: "0.5rem" }} />
                  <span className="sidebar-article-date">
                    {local && local === "en"
                      ? posts_details_date_time_format === "date"
                        ? formatDate(published_at)
                        : formatDate(published_at, true)
                      : moment(published_at).format(
                          posts_details_date_time_format === "date"
                            ? "DD.MM.yyyy"
                            : "DD.MM.yyyy HH:mm"
                        )}
                  </span>
                </li>
              </ul>
            ) : null}

            <h6>
              <Link
                to={
                  details?.seo_permalink
                    ? `../post/${id}/${details?.seo_permalink}`
                    : `../post/${id}/${details?.title?.replace(/\s+/g, "-")}`
                }
                state={{ postId: id }}
                className=""
              >
                {title}
              </Link>
            </h6>
          </div>
        )}
      </div>
    </Link>
  );
};

export default SidebarArticle;
