import React, { useState } from "react";
import { useIntl } from "react-intl";
import OwlCarousel from "react-owl-carousel";
import noImage from "../../assets/noImage.jpg";
import { useCustomMediaQuery } from "../../utils";
import ImageCard from "./ImageCard";
import "./style.scss";
const GalleryContainer = ({ sideBar, galleries }) => {
  const intl = useIntl();
  const { resolution } = useCustomMediaQuery();

  const [count, setCount] = useState(1);
  const navLeft =
    count === 1
      ? `<span class="arrow arrow-left arrow-disable"></span>`
      : `<span class="arrow arrow-left arrow-enable"></span>`;
  const navRight =
    count === 1
      ? `<span class="arrow arrow-right arrow-disable"></span>`
      : `<span class="arrow arrow-right arrow-enable"></span>`;
  const responsive = {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    991: {
      items: 2,
    },
  };
  const handleInitialize = (event) => {
    setCount(event.item.count);
  };

  const setGalleryMediaSource = (image) => {
    if (image?.resolutions && image?.resolutions.length > 0) {
      const source = image?.resolutions.filter(
        (element) => element?.resolution === resolution
      );
      return source[0].file_url;
    }
    return image?.file_path ? image?.file_path : noImage;
  };

  return (
    <div
      className={`mb-3 mt-4 ${!sideBar ? `col-lg-10 mx-auto` : "mx-auto"}`}
      style={!sideBar ? { maxWidth: "750px" } : {}}
    >
      <div className="d-flex title-wrapper">
        <div className="flex-grow-1  d-flex border-section">
          <h4 className="flex-grow-1 d-inline align-self-end mb-0">
            {intl.formatMessage({ id: "SHARED_COMPONENTS_GALLERY_TITLE" })}
          </h4>
          <div className="custom-nav owl-nav mx-auto d-flex gallery-nav"></div>
        </div>
      </div>

      <div>
        <OwlCarousel
          className="owl-theme"
          loop={galleries && galleries.length > 2 ? true : false}
          // loop
          autoplay
          margin={16}
          nav={true}
          lazyLoad={true}
          navText={[
            galleries && galleries.length > 2 ? navLeft : "",
            galleries && galleries.length > 2 ? navRight : "",
          ]}
          autoplaySpeed={5000}
          autoplayTimeout={5000}
          autoplayHoverPause={true}
          dots={false}
          responsive={responsive}
          navContainer=".d-flex .gallery-nav"
          onInitialized={handleInitialize}
        >
          {galleries &&
            galleries.length > 0 &&
            galleries.map((ele, index) => {
              return (
                <ImageCard
                  key={index}
                  details={ele}
                  img={setGalleryMediaSource(ele)}
                  sideBar={sideBar}
                />
              );
            })}
        </OwlCarousel>

        {/* <Slider ref={sliderRef} {...settings} className='reveal'>
      {galleries && galleries.length>0 && galleries.map((ele,index)=>{
         // return <ImageCard key={index} img={ele.file_path} sideBar={sideBar}/>
         return  <div>{index}</div>
        })}
        
      </Slider> */}
      </div>
    </div>
  );
};

export default GalleryContainer;
