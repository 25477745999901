import { Player } from "@lottiefiles/react-lottie-player";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import CustomFooter from "../../../sharedComponents/Footer/CustomFooter";
import InputLabel from "../../../sharedComponents/InputLabel/InputLabel";
import TextInput from "../../../sharedComponents/TextInput/TextInput";
import { useCustomMediaQuery } from "../../../utils";
import { newPassword } from "../Redux/AuthApi";

function NewPassword({ email, forgotPassword }) {
  const intl = useIntl();
  // const locale = localStorage.getItem("language");
  const navigate = useNavigate();
  // const generalDetails = useSelector(
  //   (state) => state.generalDetails.generalDetails
  // );
  const user_sign_In = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings?.user_sign_In
  );
  // const defaultPage = useSelector(
  //   (state) => state?.navigationMenu?.navigationMenu?.defaultPage
  // );
  const {
    isXsScreen,
    // isSmScreen,
    // isMdScreen,
    isLgScreen,
    isXLgScreen,
    isXxLgScreen,
  } = useCustomMediaQuery();
  const [resStatus, setresStatus] = useState({});
  const initialValues = {
    newPassword: "",
    retypePassword: "",
  };

  const newPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" }))
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        intl.formatMessage({ id: "USERS_PASSWORD_MATCHES_INFO" })
      ),
    retypePassword: Yup.string()
      .required(intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" }))
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          intl.formatMessage({ id: "USERS_PASSWORD_MISMATCH" })
        ),
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: newPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //   setLoading(true)
      setSubmitting(true);
      setTimeout(() => {
        newPassword(
          email,
          values.newPassword,
          values.retypePassword,
          forgotPassword
        )
          .then((response) => {
            if (response.status === 200) {
              setresStatus(response.data);
              setTimeout(() => {
                if (user_sign_In === "yes") {
                  navigate("../../login");
                } else {
                  navigate(`../../`);
                }
              }, 3000);
              //setSubmitting(false)
            } else if (response.status === 422) {
              setresStatus(response.data);
            } else {
              setStatus(response.data);
            }
            // setLoading(false)
            // SuccessAlert('Password is reset successfully')
            // history.push(`/login`)
          })
          .catch(() => {
            // setLoading(false)
            // setSubmitting(false)
            // setStatus('The login detail is incorrect')
          })
          .finally(() => {
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="">
      <div
        className="container"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="row d-flex align-items-center justify-content-center mt-4 mx-auto"
          style={{
            width: isXsScreen ? "100%" : "90%",
            boxShadow:
              "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          {isLgScreen || isXLgScreen || isXxLgScreen ? (
            <div className="col-lg-6 col-xl-6 py-5">
              <Player
                autoplay
                loop={true}
                src="https://assets6.lottiefiles.com/packages/lf20_z3pnisgt.json"
                style={{ height: "400px", width: "400px" }}
              ></Player>
            </div>
          ) : null}
          <div className="col-md-12 col-lg-5 col-xl-5">
            <div className="p-4">
              <div className="text-center mb-3">
                {/* <img
                src={generalDetails?.logo_url}
                className="img-fluid logo"
                style={{ maxHeight: "64px" }}
              /> */}
                <h5 className="mb-4 ">
                  {intl.formatMessage({ id: "USER_CHANGE_PASSWORD" })}
                </h5>

                <div className="text-muted">
                  {`${intl.formatMessage({
                    id: "USERS_PASSWORD_INFO",
                  })} ${intl.formatMessage({
                    id: "USER_CHANGE_PASSWORD_MESSAGE",
                  })}`}
                </div>
              </div>

              <form onSubmit={formik.handleSubmit}>
                {/* New Password input */}
                <div className="form-outline mb-2 position-relative">
                  {/* <input
                  {...formik.getFieldProps("newPassword")}
                  id="form-input"
                  type="password"
                  name="newPassword"
                  autoComplete="off"
                  placeholder="*******"
                  className="mb-3"
                />
                <label
                  placeholder={
                    JSON.stringify(locale).includes("de")
                      ? "Neues Passwort"
                      : "New Password"
                  }
                  alt={
                    JSON.stringify(locale).includes("de")
                      ? "Neues Passwort"
                      : "New Password"
                  }
                  id="form-label"
                ></label>
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <span
                    role="alert "
                    className="position-absolute"
                    style={{
                      top: "55px",
                      color: "var(--error-alert)",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.newPassword}
                  </span>
                )} */}
                  <InputLabel
                    required
                    text={intl.formatMessage({ id: "LABEL_NEW_PASSWORD" })}
                  />

                  <TextInput
                    value={formik.values.newPassword}
                    type="password"
                    onChange={(value) => {
                      formik.setFieldValue("newPassword", value);
                    }}
                    isError={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                  />
                  {formik.touched.newPassword && formik.errors.newPassword && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.newPassword}</span>
                    </div>
                  )}
                </div>
                <div className="form-outline mb-3 position-relative">
                  {/* <input
                  {...formik.getFieldProps("retypePassword")}
                  id="form-input"
                  type="password"
                  name="retypePassword"
                  autoComplete="off"
                  placeholder="*******"
                  className="mb-3"
                />
                <label
                  placeholder={
                    JSON.stringify(locale).includes("de")
                      ? "Kennwort bestätigen"
                      : "Confirm Password"
                  }
                  alt={
                    JSON.stringify(locale).includes("de")
                      ? "Kennwort bestätigen"
                      : "Confirm Password"
                  }
                  id="form-label"
                ></label>
                {formik.touched.retypePassword &&
                  formik.errors.retypePassword && (
                    <span
                      role="alert "
                      className="position-absolute"
                      style={{
                        top: "55px",
                        color: "var(--error-alert)",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.retypePassword}
                    </span>
                  )} */}
                  <InputLabel
                    required
                    text={intl.formatMessage({ id: "LABEL_CONFIRM_PASSWORD" })}
                  />

                  <TextInput
                    value={formik.values.retypePassword}
                    type="password"
                    onChange={(value) => {
                      formik.setFieldValue("retypePassword", value);
                    }}
                    isError={
                      formik.touched.retypePassword &&
                      formik.errors.retypePassword
                    }
                  />
                  {formik.touched.retypePassword &&
                    formik.errors.retypePassword && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: "var(--error-alert)",
                        }}
                      >
                        <span role="alert">{formik.errors.retypePassword}</span>
                      </div>
                    )}
                </div>
                <div className="d-flex justify-content-between align-items-center flex-column">
                  <button
                    type="submit"
                    id="button-custom-two"
                    className="mx-0"
                    style={{ width: "100%", borderRadius: "3px" }}
                    disabled={formik.isSubmitting}
                  >
                    {intl.formatMessage({ id: "BUTTON_SUBMIT" })}
                    {formik.isSubmitting ? (
                      <Oval
                        height={20}
                        width={15}
                        color="white"
                        wrapperStyle={{
                          display: "inline-block",
                          marginLeft: "2rem",
                          verticalAlign: "middle",
                          padding: "0px",
                        }}
                        wrapperClass="py-0"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="white"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                      />
                    ) : null}
                  </button>
                  {user_sign_In && user_sign_In === "no" ? (
                    <Link
                      to="/"
                      className="mt-4"
                      style={{ color: "var(--accent-color)" }}
                    >
                      {intl.formatMessage({ id: "BUTTON_BACK_TO_HOME" })}
                    </Link>
                  ) : (
                    <Link
                      to="/login"
                      className="mt-4"
                      style={{ color: "var(--accent-color)" }}
                    >
                      {intl.formatMessage({ id: "MESSAGE_BACK_TO_LOGIN" })}
                    </Link>
                  )}
                </div>
                {resStatus && Object.values(resStatus).length > 0 ? (
                  <div
                    className={`mb-10 mt-3 ${
                      resStatus.status === "Success"
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }`}
                    style={{
                      padding: "10px",
                      borderRadius: "3px",
                      textAlign: "start",
                    }}
                  >
                    <div className="alert-text font-weight-bold text-center">
                      {resStatus.message}
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
        <CustomFooter />
      </div>
    </div>
  );
}

export default NewPassword;
