import { convert } from "html-to-text";
import moment from "moment";
import React from "react";
import { IoMdContact } from "react-icons/io";
import { RiCalendarTodoFill } from "react-icons/ri";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocal } from "../../App";
import noImage from "../../assets/noImage.jpg";
import { formatDate, useCustomMediaQuery } from "../../utils";
import AuthorInArticle from "../AuthorImage/AuthorInArticle";
import LockIcon from "../LockIcon/LockIcon";
import PlayerIcon from "../PlayerIcon";
const GridArticle = ({ details, className, type, view }) => {
  const {
    title,
    post_thumbnail_url,
    post_media_url,
    description,
    introduction,
    published_at,
    post_type,
    post_thumbnail_resolutions,
    post_media_resolutions,
    id,
    authors,
    is_bought,
    is_registered,
  } = details;

  // const [local, setLocal] = useState("");

  // useEffect(() => {
  //   const res = localStorage.getItem("language");
  //   setLocal(res);
  // }, []);
  const local = useLocal();
  const { resolution } = useCustomMediaQuery();

  const posts_details_date_time_format = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.posts_general_settings
        ?.posts_details_date_time_format
  );

  const showLockIcon = useSelector(
    (state) =>
      state.generalDetails.generalDetails.general_settings
        .display_lock_icon_to_content
  );
  const setPostMediaSource = () => {
    if (type === "event-list") {
      if (
        details.event_image_resolutions &&
        details.event_image_resolutions.length > 0
      ) {
        const source = details.event_image_resolutions.filter(
          (element) => element?.resolution === resolution
        );
        return source[0]?.file_url;
      }
      return details.event_image_url ? details.event_image_url : noImage;
    } else {
      const imgResolutions =
        post_type === "image"
          ? post_media_resolutions
          : post_thumbnail_resolutions;
      const file_url =
        post_type === "image" ? post_media_url : post_thumbnail_url;
      if (imgResolutions && imgResolutions.length > 0) {
        const source = imgResolutions.filter(
          (element) => element?.resolution === resolution
        );
        return source[0]?.file_url;
      }
      return file_url ? file_url : noImage;
    }
  };
  const eventHostMedia = () => {
    if (
      details.contact_person_image_resolutions &&
      details.contact_person_image_resolutions.length > 0
    ) {
      const source = details.contact_person_image_resolutions.filter(
        (element) => {
          return element?.resolution === "256x144";
        }
      );
      return source[0]?.file_url;
    }
    return details.contact_person_image_url
      ? details.contact_person_image_url
      : noImage;
  };
  return (
    <div className={`${className}`}>
      <Link
        to={
          type === "event-list"
            ? details?.seo_permalink
              ? `../event/${id}/${details?.seo_permalink}`
              : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
            : details?.seo_permalink
            ? `../post/${id}/${details?.seo_permalink}`
            : `../post/${id}/${details?.title?.replace(/\s+/g, "-")}`
        }
        state={type === "event-list" ? { eventId: id } : { postId: id }}
      >
        <div className={`article-container`}>
          {/* <div className="article-category">
            life
            </div> */}

          <div className="article-image rounded-1 event-image">
            {/* {type === "event-list" && (
            <div className="event-tag">{details?.event_mode_name}</div>
          )} */}
            {/* <div> */}
            {type === "event-list" ||
            type === "event-carousel" ? null : showLockIcon === "yes" &&
              (!!is_bought || !!is_registered) ? null : (
              <LockIcon size={"small"} />
            )}
            {}
            {post_type === "video" || post_type === "audio" ? (
              <PlayerIcon type={post_type} />
            ) : null}
            {/* </div> */}
            {/* {post_type === "video" || post_type === "audio" ? (
            <VideoPlayButton />
          ) : null} */}
            <Link
              to={
                type === "event-list"
                  ? details?.seo_permalink
                    ? `../event/${id}/${details?.seo_permalink}`
                    : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
                  : details?.seo_permalink
                  ? `../post/${id}/${details?.seo_permalink}`
                  : `../post/${id}/${details?.title?.replace(/\s+/g, "-")}`
              }
              state={type === "event-list" ? { eventId: id } : { postId: id }}
              className="image-link"
            >
              <LazyLoadImage
                src={setPostMediaSource()}
                alt="article_image"
                wrapperClassName="img-fluid"
                style={{
                  opacity:
                    type === "event-list" || type === "event-carousel"
                      ? ""
                      : showLockIcon === "yes" &&
                        (!!is_bought || !!is_registered)
                      ? ""
                      : "50%",
                }}
              />
              {/* <img
              loading="lazy"
              src={post_thumbnail_url ? post_thumbnail_url : noImage}
              alt="article_image"
              className="img-fluid"
            /> */}
            </Link>
          </div>
          <div className="article-content">
            <div>
              <h4>
                <Link
                  className="article-content-title"
                  to={
                    type === "event-list"
                      ? details?.seo_permalink
                        ? `../event/${id}/${details?.seo_permalink}`
                        : `../event/${id}/${details?.title?.replace(
                            /\s+/g,
                            "-"
                          )}`
                      : details?.seo_permalink
                      ? `../post/${id}/${details?.seo_permalink}`
                      : `../post/${id}/${details?.title?.replace(/\s+/g, "-")}`
                  }
                  state={
                    type === "event-list" ? { eventId: id } : { postId: id }
                  }
                >
                  {title}
                </Link>
              </h4>
              <div className="d-flex">
                <ul className="list-inline article__post__author">
                  {/* <li className="list-inline-item article-post-image">
                            <AuthorImage/>
                        </li> */}
                  {type === "event-list" ? (
                    <li className="list-inline-item article-post-date">
                      <span
                        className="text-dark text-capitalize d-flex justify-content-center align-items-center"
                        style={{ marginRight: "1rem", height: "30px" }}
                      >
                        {/* <AiOutlineCalendar className="icon-calendar"/> */}
                        {/* <img src={calendarSvg} /> */}
                        <RiCalendarTodoFill className="icon-calendar" />
                        {details.start_date
                          ? local && local === "en"
                            ? formatDate(details.start_date)
                            : moment(details.start_date).format("DD.MM.yyyy")
                          : ""}
                      </span>
                    </li>
                  ) : published_at &&
                    posts_details_date_time_format !== "none" ? (
                    <li className="list-inline-item article-post-date">
                      <span
                        className="text-dark text-capitalize d-flex justify-content-center align-items-center"
                        style={{ marginRight: "1rem", height: "30px" }}
                      >
                        {/* <AiOutlineCalendar className="icon-calendar"/> */}
                        {/* <img src={calendarSvg} /> */}
                        <RiCalendarTodoFill className="icon-calendar" />
                        {local && local === "en"
                          ? posts_details_date_time_format === "date"
                            ? formatDate(published_at)
                            : formatDate(published_at, true)
                          : moment(published_at).format(
                              posts_details_date_time_format === "date"
                                ? "DD.MM.yyyy"
                                : "DD.MM.yyyy HH:mm"
                            )}
                      </span>
                    </li>
                  ) : null}
                  <li className="list-inline-item article-post-date">
                    <span className="text-dark">
                      {/* <Author authors={authors}/> */}
                      <AuthorInArticle authors={authors} view={view} />
                    </span>
                  </li>
                </ul>
                {details?.contact_person_image_url ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <IoMdContact
                      className="event-icons no-background"
                      style={{ marginRight: "0" }}
                    />
                    <img
                      className=" mx-1"
                      style={{
                        height: "22px",
                        width: "auto",
                        objectFit: "contain",
                      }}
                      src={details?.contact_person_image_url}
                      alt="host-profile"
                    />
                  </div>
                ) : details?.contact_person_name ? (
                  <div className="d-flex align-items-center gap-1">
                    <div className="host_profile position-relative">
                      <span
                        className="position-absolute"
                        style={{ marginLeft: "7px" }}
                      >
                        {details?.contact_person_name.charAt(0)}
                      </span>
                    </div>
                    <span className="fw-lighter text-muted">
                      {details?.contact_person_name}
                    </span>
                  </div>
                ) : null}
              </div>
              {type === "event-list" ? (
                <>
                  {" "}
                  <p className="article-content-description">
                    {/* {details.sub_title
                    ? details.sub_title.replace(/(<([^>]+)>)/gi, "")
                    : ""} */}
                    {details.sub_title
                      ? convert(details.sub_title)
                      : description
                      ? convert(description)
                      : ""}
                  </p>
                </>
              ) : (
                <p className="article-content-description">
                  {/* {description ? description.replace(/(<([^>]+)>)/gi, "") : ""} */}
                  {introduction
                    ? convert(introduction)
                    : description
                    ? convert(description)
                    : ""}
                </p>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default GridArticle;
