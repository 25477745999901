import React, { useState } from "react";
import { BiEuro } from "react-icons/bi";
import { useIntl } from "react-intl";
// import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import BuyNowModalTwo from "../Modal/BuyNowModalTwo";
import ContactAdminModal from "../Modal/ContactAdminModal";
import SelectFlowModal from "../Modal/SelectFlowModal";
import "./style.scss";
const Product = ({ postId, products, sidebar }) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [showFlowModal, setshowFlowModal] = useState(false);
  const [flow, setflow] = useState("products");
  // const [Loading, setLoading] = useState(false);
  // const isAuthorized = useSelector((state) => state?.auth?.isAuthorized);
  // const userInfo = useSelector(
  //   (state) => state.auth?.user?.personalInformation
  // );
  const offerFlow = useSelector(
    (state) =>
      state.generalDetails.generalDetails.general_settings.enable_offer_flow
  );
  const handleShow = () => {
    offerFlow === "yes" ? setshowFlowModal(true) : setShowModal(true);
  };
  // const error = useSelector((state) => state?.payment?.error);
  const handleSubmit = () => {
    // if (isAuthorized && products?.legal_texts.length === 0) {
    //   const paramsObject = {
    //     salutation_id: userInfo?.salutation,
    //     firstname: userInfo?.firstname,
    //     lastname: userInfo?.lastname,
    //     email: userInfo?.email,
    //     product_id: products?.id,
    //   };
    //   try {
    //     setLoading(true);
    //     axios({
    //       method: "post",
    //       url:
    //         process.env.REACT_APP_API_URL +
    //         `/posts/checkout/sessions/${postId}`,
    //       data: paramsObject,
    //       headers: { "Content-Type": "multipart/form-data" },
    //     })
    //       .then((response) => {
    //         setLoading(false);
    //         if (response.status === 200) {
    //           window.location.href = response?.data?.stripeProductDetails?.url;
    //         }
    //       })
    //       .catch((error) => {
    //         setLoading(false);
    //         if (error.response.status === 500) {
    //           setTimeout(() => setshowErrorModal(true), 1000);
    //         }
    //       });
    //   } catch (error) {
    //     setLoading(false);
    //     throw error;
    //   }
    // } else {
    //   handleShow();
    // }
    handleShow();
  };
  return (
    <div
      className={`card mx-auto py-4 px-4 my-3 product-card ${
        !sidebar ? "col-lg-10" : ""
      }`}
      style={{ maxWidth: !sidebar ? "750px" : "" }}
    >
      <h2>{products?.name}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: products?.display_description }}
      ></div>
      <br></br>
      <div className=" d-flex justify-content-between align-items-end">
        <div>
          <h4 className="mb-0">
            <BiEuro style={{ verticalAlign: "middle", paddingBottom: "1px" }} />
            {(parseInt(products?.price) / 100).toFixed(2)}{" "}
            <span style={{ fontSize: "16px" }}>EUR</span>
          </h4>
          <span
            style={{
              color: "var(--accent-color)",
              fontSize: "14px",
              marginLeft: "0.5rem",
            }}
          >
            *
            {`${
              products?.price_tax_behavior === "inclusive"
                ? intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PRODUCT_CARD_INCLUSIVE_OF_ALL_TAXES",
                  })
                : intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PRODUCT_CARD_EXCLUSIVE_OF_ALL_TAXES",
                  })
            }`}
          </span>
        </div>
        <button
          id="button-custom"
          style={{
            width: "fit-content",
            height: "fit-content",
            marginRight: "0px",
          }}
          onClick={() => handleSubmit()}
        >
          {intl.formatMessage({ id: "BUTTON_BUY_NOW" })}
          {/* {Loading ? (
            <Oval
              height={20}
              width={20}
              color="white"
              wrapperStyle={{
                display: "inline-block",
                marginLeft: "1rem",
                padding: "0px",
              }}
              wrapperClass="py-0"
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="white"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          ) : null} */}
          {/* Buy Now <FiChevronRight style={{ verticalAlign: "text-bottom" }} /> */}
        </button>
      </div>
      <SelectFlowModal
        show={showFlowModal}
        setflow={setflow}
        setBuyPostModal={setShowModal}
        closeModal={() => setshowFlowModal(false)}
        showBuyPostModal={showModal}
      />
      {showModal && (
        <BuyNowModalTwo
          show={showModal}
          legalTexts={products?.legal_texts}
          postId={postId}
          productId={products?.id}
          setshowErrorModal={setshowErrorModal}
          setShowModal={setShowModal}
          flow={flow}
          setshowFlowModal={setshowFlowModal}
        />
      )}

      {showErrorModal && (
        <ContactAdminModal
          setshowErrorModal={setshowErrorModal}
          show={showErrorModal}
        />
      )}
    </div>
  );
};

export default Product;
