import { useState } from "react";
import CommentCard from "./CommentCard";

const ShowCommentCard = ({
  details,
  getPostsAPI,
  getShowModal,
  setInputAndType,
  commentRef,
  only3Comments,
  communities = false,
}) => {
  const [showComments, setShowComments] = useState(undefined);
  const [showSubComments, setShowSubComments] = useState(undefined);
  const [showLastLevelComments, setShowLastLevelComments] = useState(undefined);

  const handleReplyItem = (item) => {
    setShowComments(item);
  };

  const handleSubReplyItem = (item) => {
    setShowSubComments(item);
  };

  const handleLastLevelReplyItem = (item) => {
    setShowLastLevelComments(item);
  };

  const getTotalCommentsRecursive = (items, result) => {
    items.forEach((data) => {
      result.push(data);
      if (data.replies.length > 0) {
        getTotalCommentsRecursive(data.replies, result);
      }
    });
  };

  const getLastLevelReplies = (items) => {
    const result = [];
    getTotalCommentsRecursive(items, result);
    return result;
  };

  const totalReplies = only3Comments
    ? details?.replies?.slice(0, 2)
    : details?.replies || [];

  const shouldShowHideReplies = (rating, depth) => {
    if (depth === 1) {
      return rating.id === showComments?.id;
    } else if (depth === 2) {
      return rating.id === showSubComments?.id;
    } else if (depth === 3) {
      return rating.id === showLastLevelComments?.id;
    }
  };

  const hideReplies = (depth) => {
    if (depth === 1) {
      setShowComments(undefined);
      setShowSubComments(undefined);
      setShowLastLevelComments(undefined);
    } else if (depth === 2) {
      setShowSubComments(undefined);
      setShowLastLevelComments(undefined);
    } else if (depth === 3) {
      setShowLastLevelComments(undefined);
    }
  };

  return (
    <div>
      {totalReplies && totalReplies.length > 0
        ? totalReplies.map((rating, index) => {
            const replies = rating.replies || [];
            return (
              <div>
                <CommentCard
                  key={index}
                  rating={rating}
                  details={details}
                  getPostsAPI={getPostsAPI}
                  handleReplyItem={handleReplyItem}
                  depth={1}
                  shouldShowHideReplies={shouldShowHideReplies}
                  hideReplies={hideReplies}
                  getShowModal={getShowModal}
                  setInputAndType={setInputAndType}
                  commentRef={commentRef}
                  communities={communities}
                />

                {showComments?.id === rating.id && (
                  <div
                    style={{
                      borderLeft: "1px solid #ccc",
                      marginTop: "15px",
                      padding: "5px 0 0 0",
                      marginLeft: "10px",
                    }}
                  >
                    {replies &&
                      replies.length > 0 &&
                      replies.map((reply) => {
                        const subReplies = reply.replies;
                        return (
                          <div>
                            <CommentCard
                              rating={reply}
                              details={details}
                              getPostsAPI={getPostsAPI}
                              handleReplyItem={handleSubReplyItem}
                              depth={2}
                              shouldShowHideReplies={shouldShowHideReplies}
                              hideReplies={hideReplies}
                              getShowModal={getShowModal}
                              setInputAndType={setInputAndType}
                              commentRef={commentRef}
                              communities={communities}
                            />
                            {showSubComments?.id === reply.id && (
                              <div
                                style={{
                                  borderLeft: "1px solid #ccc",
                                  marginTop: "15px",
                                  padding: "5px 0 0 0",
                                  marginLeft: "30px",
                                }}
                              >
                                {subReplies &&
                                  subReplies.length > 0 &&
                                  subReplies.map((subReply) => {
                                    const lastLeveleReplies =
                                      getLastLevelReplies(subReply.replies);
                                    return (
                                      <div>
                                        <CommentCard
                                          rating={subReply}
                                          details={details}
                                          getPostsAPI={getPostsAPI}
                                          handleReplyItem={
                                            handleLastLevelReplyItem
                                          }
                                          depth={3}
                                          shouldShowHideReplies={
                                            shouldShowHideReplies
                                          }
                                          hideReplies={hideReplies}
                                          getShowModal={getShowModal}
                                          setInputAndType={setInputAndType}
                                          commentRef={commentRef}
                                          communities={communities}
                                        />
                                        {showLastLevelComments?.id ===
                                          subReply.id && (
                                          <div
                                            style={{
                                              borderLeft: "1px solid #ccc",
                                              marginTop: "15px",
                                              padding: "5px 0 0 0",
                                              marginLeft: "30px",
                                            }}
                                          >
                                            {lastLeveleReplies &&
                                              lastLeveleReplies.length > 0 &&
                                              lastLeveleReplies.map(
                                                (lastItem) => {
                                                  return (
                                                    <div>
                                                      <CommentCard
                                                        rating={lastItem}
                                                        details={details}
                                                        getPostsAPI={
                                                          getPostsAPI
                                                        }
                                                        depth={4}
                                                        getShowModal={
                                                          getShowModal
                                                        }
                                                        setInputAndType={
                                                          setInputAndType
                                                        }
                                                        commentRef={commentRef}
                                                        communities={
                                                          communities
                                                        }
                                                      />
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default ShowCommentCard;
