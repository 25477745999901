import axios from "axios";

export function addRatingsComment(id, ratings, body) {
  return axios.post(`/posts/ratings/${id}`, {
    ratings,
    body,
  });
}

export function updateRatingsComment(id, ratings, body) {
  return axios.post(`/posts/ratings/update/${id}`, {
    ratings,
    body,
  });
}

export function deleteRatingsComment(id, commentId) {
  return axios.post(`/posts/ratings/delete/${id}/${commentId}`);
}

export function addRatingsReply(id, body, comment_id) {
  return axios.post(`/posts/ratings/reply/${id}`, {
    comment_id,
    body,
  });
}
