import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { confirmPassword } from "../../components/auth/Redux/AuthApi";
import InputLabel from "../InputLabel/InputLabel";
import TextInput from "../TextInput/TextInput";

const initialValues = {
  password: "",
};

const ConfirmPasswordModal = ({
  show,
  onPasswordConfirm,
  user_id,
  closeModal,
  message,
}) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const ConfirmPasswordSchema = Yup.object().shape({
    password: Yup.string().required(
      intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" })
    ),
  });
  const onFormSubmit = (values, formikHelpers) => {
    setLoading(true);
    confirmPassword(user_id, values.password)
      .then(() => {
        onPasswordConfirm?.();
        closeModal();
      })
      .catch(() => {
        formikHelpers.setStatus(
          intl.formatMessage({ id: "USERS_INCORRECT_PASSOWRD" })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal show={show} centered dialogClassName={"medium-size-modal"}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>
          {intl.formatMessage({ id: "USERS_CONFIRM_PASSWORD_MODAL_TITLE" })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={ConfirmPasswordSchema}
        onSubmit={(values, formikHelpers) => {
          onFormSubmit(values, formikHelpers);
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          status,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <Modal.Body>
              <div className="p-4">
                {status && (
                  <div className="mb-10 alert alert-danger">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                )}

                <p
                  className=" mb-10"
                  style={{ fontSize: "18px", fontWeight: "500" }}
                >
                  {message}
                </p>

                {/* <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>{intl.formatMessage({id:'USERS_PASSWORD_FIELD'})}</span>
                </label> */}
                <InputLabel
                  required
                  text={intl.formatMessage({ id: "USERS_PASSWORD_FIELD" })}
                />

                {/* <input
                  placeholder={''}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  name='password'
                  autoComplete='off'
                  onChange={handleChange('password')}
                  value={values.password}
                /> */}
                <TextInput
                  value={values.password}
                  type="password"
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    setFieldValue("password", value);
                  }}
                  isError={touched.password && errors.password}
                />
                {touched.password && errors.password && (
                  <div className="text-danger">
                    <span role="alert">{errors.password}</span>
                  </div>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer className="justify-content-center">
              <Button
                variant="secondary"
                onClick={closeModal}
                style={{ padding: "10px 20px" }}
              >
                {intl.formatMessage({ id: "CLOSE_BUTTON" })}
              </Button>
              <button
                // variant='primary'
                className="btn btn-custom"
                style={{ padding: "10px 20px" }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ConfirmPasswordModal;
