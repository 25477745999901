import React, { useRef, useState } from "react";
import { MdShowChart } from "react-icons/md";
import { useIntl } from "react-intl";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./style.scss";
const TrendingPosts = () => {
  const data = useSelector((state) => state?.postsDetails?.trendingPosts);
  const intl = useIntl();
  const [count, setCount] = useState(1);
  const navLeft =
    count === 1
      ? `<span class="arrow arrow-left arrow-disable"></span>`
      : `<span class="arrow arrow-left arrow-enable"></span>`;
  const navRight =
    count === 1
      ? `<span class="arrow arrow-right arrow-disable"></span>`
      : `<span class="arrow arrow-right arrow-enable"></span>`;
  const handleInitialize = (event) => {
    setCount(event.item.count);
  };

  const ref = useRef(null);

  const handlePause = () => {
    ref.current?.stop();
  };

  const handlePlay = () => {
    ref.current?.play();
  };
  const handlePrev = () => {
    ref.current?.prev();
  };
  const handleNext = () => {
    ref.current?.next();
  };

  return data && data.length > 0 ? (
    <div className="py-4 trending-wrapper">
      <div className="container">
        <div className="row">
          <div className="col d-flex w-100">
            <div className="col-1 col-lg-2 trending-title">
              <MdShowChart />
              <span
                className="d-none d-sm-none d-md-none d-lg-inline"
                style={{ marginLeft: "10px" }}
              >
                {intl.formatMessage({ id: "TOP" })}
              </span>
            </div>
            <div className="col-10 col-lg-9 d-flex justify-content-between align-items-center carousel-wrapper">
              <OwlCarousel
                ref={ref}
                items="1"
                loop={true}
                autoplay={true}
                // navText={[
                //   data && data.length > 1 ? navLeft : "",
                //   data && data.length > 1 ? navRight : "",
                // ]}
                dots={false}
                animateIn={true}
                // navContainer={".trending-wrapper .owl-nav.trending-post-nav"}
                onInitialized={handleInitialize}
                autoplayHoverPause={true}
              >
                {data &&
                  data.length > 0 &&
                  data.map((ele, index) => {
                    return (
                      <Link
                        key={index}
                        to={
                          ele.seo_permalink
                            ? `../post/${ele.id}/${ele.seo_permalink}`
                            : `../post/${ele.id}/${ele.title?.replace(
                                /\s+/g,
                                "-"
                              )}`
                        }
                        state={{ postId: ele.id }}
                        className="trending-post"
                      >
                        {ele.title}
                      </Link>
                    );
                  })}
              </OwlCarousel>
            </div>
            {/* <div className="col-1 owl-nav trending-post-nav"></div> */}
            <ActionButtons
              handlePause={handlePause}
              handlePlay={handlePlay}
              handlePrev={handlePrev}
              handleNext={handleNext}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const ActionButtons = ({ handlePause, handlePlay, handlePrev, handleNext }) => {
  const [pause, setPause] = useState(false);

  return (
    <div className="col-1 pe-3 justify-content-end align-items-center d-none d-md-flex">
      <div
        className="d-flex flex-row gap-4 px-1 h-100 align-items-center"
        style={{ background: "#ffffff", zIndex: "10" }}
        onMouseOver={() => {
          handlePause();
        }}
        onMouseLeave={() => {
          if (!pause) {
            handlePlay();
          }
        }}
      >
        {/* <AiOutlineLeft
          style={{
            height: "25px",
            width: "25px",
          }}
          onClick={() => {
            handlePrev();
          }}
        /> */}
        <span
          class="arrow arrow-left arrow-enable"
          style={{
            height: "15px",
            width: "15px",
          }}
          onClick={() => {
            handlePrev();
          }}
        ></span>

        {/* <AiOutlineRight
          style={{
            height: "25px",
            width: "25px",
          }}
          onClick={() => {
            handleNext();
          }}
        /> */}
        <span
          class="arrow arrow-right arrow-enable"
          style={{
            height: "15px",
            width: "15px",
          }}
          onClick={() => {
            handleNext();
          }}
        ></span>
      </div>
    </div>
  );
};

export default TrendingPosts;
