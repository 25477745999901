import React, { useState } from "react";
import { BiEuro } from "react-icons/bi";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useCustomMediaQuery } from "../../utils";

export const FreeEventData = {
  name: "Free Event",
  description: "",
  price: 0,
  price_tax_behavior: "inclusive",
};

export const EventProduct = ({
  products,
  remainingCapacity,
  setProductDetails,
  setShowEventRegisterModal,
  setshowFlowModal,
  handleRegisterClick,
  freeEvent,
  handleRegistationDisable,
  subscription,
  setflow,
}) => {
  const { isXsScreen } = useCustomMediaQuery();
  const [Loading, setLoading] = useState(false);

  const intl = useIntl();
  const offerFlow = useSelector(
    (state) =>
      state.generalDetails.generalDetails.general_settings.enable_offer_flow
  );
  const setSubscriptionIntlId = (interval, number) => {
    switch (interval) {
      case "year":
        if (number === 1) {
          return { id: "SUBSCRIPTION_PLAN_1_YEAR" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_YEARLY" };
        }
      case "month":
        if (number === 1) {
          return { id: "SUBSCRIPTION_PLAN_1_MONTH" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_MONTHLY" };
        }
      case "week":
        if (number === 1) {
          return { id: "SUBSCRIPTION_PLAN_1_WEEK" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_WEEKLY" };
        }
      case "day":
        if (number === 1) {
          return { id: "SUBSCRIPTION_PLAN_1_DAY" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_DAILY" };
        }
    }
  };
  const handleBuyNow = (details) => {
    if (freeEvent && !subscription) {
      handleRegisterClick?.();
    } else if (subscription) {
      setflow("subscription");
      setShowEventRegisterModal?.(true);
      if (!freeEvent) {
        setProductDetails(details);
      }
    } else {
      setProductDetails(details);
      offerFlow === "yes"
        ? setshowFlowModal?.(true)
        : setShowEventRegisterModal?.(true);
    }
  };
  // const handleDisable = (productDetails) => {
  //   if (productDetails?.license_type === "group") {
  //     if (remainingCapacity > productDetails?.quantity) {
  //       return !true;
  //     } else {
  //       return !false;
  //     }
  //   } else {
  //     return !true;
  //   }
  // };
  return (
    <div className="px-0">
      {products && products.length > 0 ? (
        products.map((details, index) => {
          return (
            <div className="card mx-auto py-4 px-4 my-3 product-card">
              <h4>{details?.name}</h4>
              {subscription ? (
                <div
                  style={{
                    color: "var(--accent-color)",
                  }}
                >
                  <p className="d-inline" style={{ marginRight: "6px" }}>
                    {details?.price_interval_count === 1
                      ? null
                      : details?.price_interval_count}
                  </p>
                  <p className="d-inline">
                    {intl.formatMessage(
                      setSubscriptionIntlId(
                        details?.price_interval,
                        details?.price_interval_count
                      )
                    )}
                  </p>
                </div>
              ) : null}
              <div
                dangerouslySetInnerHTML={{ __html: details?.display_description }}
              ></div>
              <br></br>
              <div
                className={`d-flex justify-content-between align-items-start ${
                  isXsScreen ? "flex-column" : ""
                }`}
              >
                <div>
                  <h4 className="mb-0">
                    <BiEuro
                      style={{ verticalAlign: "middle", paddingBottom: "1px" }}
                    />
                    {!freeEvent
                      ? (parseInt(details?.price) / 100).toFixed(2)
                      : "0.00"}{" "}
                    <span style={{ fontSize: "16px" }}>
                      {intl.formatMessage({
                        id: "SHARED_COMPONENTS_EVENT_PRODUCT_EUR",
                      })}
                    </span>
                  </h4>
                  <span
                    style={{
                      color: "var(--accent-color)",
                      fontSize: "14px",
                      marginLeft: "0.5rem",
                    }}
                  >
                    *
                    {details?.price_tax_behavior === "inclusive"
                      ? intl.formatMessage({
                          id: "SHARED_COMPONENTS_EVENT_PRODUCT_INCLUSIVE_OF_ALL_TAXES",
                        })
                      : intl.formatMessage({
                          id: "SHARED_COMPONENTS_EVENT_PRODUCT_EXCLUSIVE_OF_ALL_TAXES",
                        })}
                  </span>
                </div>
                <button
                  id="button-custom"
                  style={{
                    width: isXsScreen ? "100%" : "fit-content",
                    height: "fit-content",
                    marginRight: "0px",
                    marginTop: isXsScreen ? "16px" : "0px",
                  }}
                  onClick={() => handleBuyNow(details)}
                  disabled={handleRegistationDisable()}
                  className={`${
                    handleRegistationDisable() ? "btn-disabled" : ""
                  }`}
                >
                  {intl.formatMessage({ id: "BUTTON_BUY_NOW" })}
                  {Loading ? (
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      wrapperStyle={{
                        display: "inline-block",
                        marginLeft: "1rem",
                        padding: "0px",
                      }}
                      wrapperClass="py-0"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="white"
                      strokeWidth={4}
                      strokeWidthSecondary={4}
                    />
                  ) : null}
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <div>
          {intl.formatMessage({
            id: "SHARED_COMPONENTS_EVENT_PRODUCT_NO_PRODUCTS",
          })}
        </div>
      )}
    </div>
  );
};
