export const GET_PAGE_DETAILS = "GET_PAGE_DETAILS";
export const GET_PAGE_DETAILS_SUCCESS = "GET_PAGE_DETAILS_SUCCESS";
export const GET_PAGE_DETAILS_FAIL = "GET_PAGE_DETAILS_FAIL";

const initialState = {
  pages: {},
  isLoading: true,
  error: {
    message: "",
  },
};

export const getPageDetails = (id) => {
  return { type: GET_PAGE_DETAILS, payload: id };
};

export const getPageDetailsSuccess = (details) => {
  return {
    type: GET_PAGE_DETAILS_SUCCESS,
    payload: details,
  };
};

export const getPageDetailsFail = (error) => {
  return {
    type: GET_PAGE_DETAILS_FAIL,
    payload: error,
  };
};

const PageDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGE_DETAILS: {
      return { ...state, isLoading: true };
    }
    case GET_PAGE_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        pages: { ...state.pages, [action.payload?.name]: action.payload },
      };
    }
    case GET_PAGE_DETAILS_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: { message: action.payload },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default PageDetailsReducer;
