import moment from "moment";
import React from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { useIntl } from "react-intl";
import {
  errorToast,
  successToast,
} from "../../../sharedComponents/Toast/Toasts";
import { downloadOffer, offerReminder } from "../../auth/Redux/AuthApi";

const OffersCard = ({ element }) => {
  // const date = new Date(element?.transaction_date * 1000).toLocaleString(
  //   "default"
  // );

  const intl = useIntl();
  // const date = new Date().getDate()
  return (
    <>
      <div
        className="d-xl-flex p-3 mb-3 justify-content-between position-relative"
        style={{ border: "2px solid #f3f3f3", borderRadius: "10px" }}
      >
        {element?.status === "open" ? (
          <div className=" position-absolute px-3 py-1 mt-2 me-3 product-type-label">
            Open
          </div>
        ) : null}
        <div>
          <div
            className="fw-bolder"
            style={{ fontSize: "20px", maxWidth: "400px" }}
          >
            <div className="mb-2" style={{ wordBreak: "break-all" }}>
              {element?.product_details?.name}
            </div>
            <div
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <span style={{ fontSize: "18px", color: "var(--accent-color)" }}>
                {`${
                  element?.product_details?.price_currency === "eur" ? "€" : ""
                }${(parseInt(element?.product_details?.price) / 100).toFixed(
                  2
                )} / `}
              </span>
              <span style={{ color: "red" }} className="ms-1">
                {` *`}
              </span>
              <span>
                {`${
                  element?.product_details?.price_tax_behavior === "inclusive"
                    ? intl.formatMessage({
                        id: "ACCOUNT_SETTINGS_PRODUCT_CARD_INCLUSIVE_OF_ALL_TAXES",
                      })
                    : intl.formatMessage({
                        id: "ACCOUNT_SETTINGS_PRODUCT_CARD_EXCLUSIVE_OF_ALL_TAXES",
                      })
                }`}
              </span>
            </div>
          </div>
          <div className="mt-1 fw-bold" style={{ fontSize: "14px" }}>
            <div className=" d-inline-block" style={{ width: "100px" }}>
              {intl.formatMessage({
                id: "OFFERS_OFFER_NUMBER",
              })}
            </div>
            :&nbsp;
            <span className=" fw-normal">{` ${element?.offer_number}`}</span>
            <MdOutlineContentCopy
              className="clipboard-icon d-inline-block"
              onClick={() => {
                navigator.clipboard.writeText(element?.transaction_id);
                successToast("copied to clickboard");
              }}
            />
          </div>
          <div className="mt-1 text-danger" style={{ fontSize: "14px" }}>
            {/* {date} */}
            <div className=" d-inline-block">
              {`${intl.formatMessage({
                id: "OFFERS_EXPIRES_ON",
              })} ${moment(element?.expires_at).format("DD.MM.yyyy")}`}
            </div>
          </div>
        </div>
        <div className="justify-content-xl-end mt-xl-0 mt-3 d-flex align-items-center">
          <div
            onClick={() => {
              successToast(
                intl.formatMessage({
                  id: "OFFERS_DOWNLOADING",
                })
              );
              downloadOffer(element?.id)
                .then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute(
                    "download",
                    `${element?.product_details?.name}.pdf`
                  );
                  document.body.appendChild(link);
                  link.click();
                  successToast(
                    intl.formatMessage({
                      id: "OFFERS_DOWNLOAD_SUCCESSFUL",
                    })
                  );
                })
                .catch(() => {
                  errorToast(
                    intl.formatMessage({
                      id: "OFFERS_DOWNLOAD_UNSUCCESSFUL",
                    })
                  );
                });
            }}
            className="btn btn-custom fw-bold cursor-pointer me-1"
          >
            <span>
              {/* <VscCloudDownload className="product-download-icon" /> */}
            </span>
            {intl.formatMessage({
              id: "ACCOUNT_SETTINGS_PRODUCT_CARD_DOWNLOAD_INVOICE",
            })}
          </div>
          <div
            download
            onClick={() => {
              offerReminder(element?.id)
                .then(() => {
                  successToast(
                    intl.formatMessage({
                      id: "OFFERS_REMINDER_SUCCESSFUL",
                    })
                  );
                })
                .catch(() => {
                  errorToast(
                    intl.formatMessage({
                      id: "OFFERS_REMINDER_UNSUCCESSFUL",
                    })
                  );
                });
            }}
            className="btn btn-custom fw-bold cursor-pointer"
          >
            <span>
              {/* <VscCloudDownload className="product-download-icon" /> */}
            </span>
            {intl.formatMessage({
              id: "OFFERS_SET_REMINDER",
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default OffersCard;
