import { Controls, Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchPaymentStatus } from "../../API/Api";
import CustomFooter from "../Footer/CustomFooter";
const MicroEventPaymentFail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const intl = useIntl();
  // const defaultPage = useSelector(
  //   (state) => state?.navigationMenu?.defaultPage
  // );
  const [response, setResponse] = useState({});
  const id = searchParams.get("id");
  const orderNumber = searchParams.get("orderNumber");
  const navigate = useNavigate();
  const eventPathName = sessionStorage.getItem("pathName");
  useEffect(() => {
    if (id && orderNumber) {
      const paramsObject = {
        order_number: orderNumber,
        id,
        status: "cancel",
      };
      fetchPaymentStatus(paramsObject).then((response) => {
        setTimeout(() => {
          setResponse(response);
          //setTimeout(() => navigate(`../../${defaultPage?.name}`), 5000);
        }, 2000);
      });
    }
  }, []);
  const handleBack = () => {
    if (!!eventPathName) {
      navigate(`../../${eventPathName}`);
    } else {
      window.history.go(-3);
    }
  };
  return (
    <>
      <div
        className="container d-flex justify-content-center align-items-center"
        style={{ height: "70vh" }}
      >
        <div className="d-flex justify-content-center align-items-center fw-bold flex-column">
          {/* <img src={FailedGif} style={{ width: "150px", height: "150px" }} /> */}
          <Player
            autoplay
            loop={false}
            src="https://assets1.lottiefiles.com/packages/lf20_tl52xzvn.json"
            style={{ height: "120px", width: "120px" }}
            keepLastFrame={true}
          >
            <Controls visible={false} />
          </Player>
          <p style={{ fontSize: "20px" }}>
            {intl.formatMessage({ id: "PAYMENT_CANCEL_MESSAGE" })}
          </p>
          <div className="text-center mt-3">
            <button id="button-custom" onClick={() => handleBack()}>
              {intl.formatMessage({ id: "BUTTON_BACK_TO_HOME" })}
            </button>
          </div>
        </div>
      </div>
      <CustomFooter />
    </>
  );
};

export default MicroEventPaymentFail;
