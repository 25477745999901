import React, { useState } from "react";
import { BiEuro } from "react-icons/bi";
import { useIntl } from "react-intl";
// import { Oval } from "react-loader-spinner";
// import { useSelector } from "react-redux";
import BuyNowModalTwo from "../Modal/BuyNowModalTwo";
const SubscriptionCard = ({ subscription, sidebar, postId }) => {
  const intl = useIntl();
  // const [Loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const isAuthorized = useSelector((state) => state?.auth?.isAuthorized);
  const handleShow = () => setShowModal(true);
  const setSubscriptionIntlId = (interval, number) => {
    switch (interval) {
      case "year":
        if (number === 1) {
          return { id: "SUBSCRIPTION_PLAN_1_YEAR" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_YEARLY" };
        }
      case "month":
        if (number === 1) {
          return { id: "SUBSCRIPTION_PLAN_1_MONTH" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_MONTHLY" };
        }
      case "week":
        if (number === 1) {
          return { id: "SUBSCRIPTION_PLAN_1_WEEK" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_WEEKLY" };
        }
      case "day":
        if (number === 1) {
          return { id: "SUBSCRIPTION_PLAN_1_DAY" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_DAILY" };
        }
    }
  };
  return (
    <div
      className={`card mx-auto py-4 px-4 my-3 product-card ${
        !sidebar ? "col-lg-10" : ""
      }`}
      style={{ maxWidth: !sidebar ? "750px" : "" }}
    >
      <h2>{subscription?.name}</h2>
      <div
        style={{
          color: "var(--accent-color)",
          fontSize: "22px",
          fontWeight: "bolder",
          marginBottom: "0.5rem",
        }}
      >
        <span style={{ marginRight: "6px" }}>
          {subscription?.price_interval_count === 1
            ? null
            : subscription?.price_interval_count}
        </span>
        <span>
          {intl.formatMessage(
            setSubscriptionIntlId(
              subscription?.price_interval,
              subscription?.price_interval_count
            )
          )}
        </span>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: subscription?.display_description }}
      ></div>
      <br></br>
      <div className=" d-flex justify-content-between align-items-end">
        <div>
          <h4 className="mb-0">
            <BiEuro style={{ verticalAlign: "middle", paddingBottom: "1px" }} />
            {(parseInt(subscription?.price) / 100).toFixed(2)}{" "}
            <span style={{ fontSize: "16px" }}>EUR</span>
          </h4>
          <span
            style={{
              color: "var(--accent-color)",
              fontSize: "14px",
              marginLeft: "0.5rem",
            }}
          >
            *
            {`${
              subscription?.price_tax_behavior === "inclusive"
                ? intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PRODUCT_CARD_INCLUSIVE_OF_ALL_TAXES",
                  })
                : intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PRODUCT_CARD_EXCLUSIVE_OF_ALL_TAXES",
                  })
            }`}
          </span>
        </div>
        <button
          id="button-custom"
          style={{
            width: "fit-content",
            height: "fit-content",
            marginRight: "0px",
          }}
          onClick={() => handleShow()}
        >
          {intl.formatMessage({ id: "BUTTON_BUY_NOW" })}
          {/* {Loading ? (
            <Oval
              height={20}
              width={20}
              color="white"
              wrapperStyle={{
                display: "inline-block",
                marginLeft: "1rem",
                padding: "0px",
              }}
              wrapperClass="py-0"
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="white"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          ) : null} */}
          {/* Buy Now <FiChevronRight style={{ verticalAlign: "text-bottom" }} /> */}
        </button>
      </div>
      {showModal && (
        <BuyNowModalTwo
          show={showModal}
          legalTexts={subscription?.legal_texts}
          postId={postId}
          productId={subscription?.id}
          setShowModal={setShowModal}
          flow="subscription"
          //flow={flow}
          //setshowFlowModal={setshowFlowModal}
        />
      )}
    </div>
  );
};

export default SubscriptionCard;
