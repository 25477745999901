import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AuthorImage from "./AuthorImage";
import "./style.scss";
const Author = ({ authors, view, size, noNavigation, name = true }) => {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  return (
    <div
      className={`d-flex ${
        size === "large" ? "justify-content-left" : "justify-content-center"
      } align-items-center`}
    >
      {authors && authors.length > 0
        ? authors.map((ele, index, authors) =>
            isAuthorized && noNavigation ? (
              <Link to={`/authors/${ele.id}`}>
                <AuthorImage
                  key={index}
                  photoUrl={ele.profile_photo_url}
                  ele={ele}
                  firstname={ele.firstname}
                  lastname={ele.lastname}
                  displayname={ele.display_name}
                  size={size}
                  zIndex={index}
                  authorId={ele.id}
                  name={name}
                />
              </Link>
            ) : (
              <AuthorImage
                key={index}
                photoUrl={ele.profile_photo_url}
                ele={ele}
                firstname={ele.firstname}
                lastname={ele.lastname}
                displayname={ele.display_name}
                size={size}
                zIndex={index}
                authorId={ele.id}
                name={name}
              />
            )
          )
        : null}
      {/* {authors && authors.length >0 ? authors.map((ele,index,authors)=>{
          if(index===2){
            return <span className="author_name" key={index}><span style={{marginLeft:'0.25rem'}}></span> and {authors.length-2} other{authors.length-2>1 ? 's':''}</span>
          }
          if(index<2) return (<span className="author_name" key={index}><span style={{marginLeft:'0.25rem'}}></span>{ele.firstname}</span>)
        }):null} */}
    </div>
  );
};

export default Author;
