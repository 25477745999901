import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
//import Dashboard from "../Pages/Dashboard";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { actions } from "../Redux/AuthReducer";
import {
  getCommunitiesList,
  joinedCommunities,
} from "../components/Communities/Redux/CommunitiesAPI";
import { actions as communityAction } from "../components/Communities/Redux/CommunitiesRedux";
import LegalText from "../components/Pages/LegalText";
import Page404 from "../components/Pages/Page404";
import Registration from "../components/Pages/Registration";
import AuthPage from "../components/auth/AuthPage";
import {
  getContactDetails,
  valuatePurchaseDetails,
} from "../components/auth/Redux/AuthApi";
import ActivationCodeVerification from "../components/auth/components/ActivationCodeVerification";
import ForgotPassword from "../components/auth/components/ForgotPassword";
import RegistrationDefault from "../components/auth/components/Registration";
import CheckSpecialChar from "../sharedComponents/CheckSpecialChar";
import EventsMicroPage from "../sharedComponents/EventsDetailsPage/EventsMicroPage";
import Footer from "../sharedComponents/Footer/Footer";
import HeaderWithNavbar from "../sharedComponents/Header";
import HeaderWithoutNavbar from "../sharedComponents/Header/HeaderWithoutNavbar";
import OffersMicroPage from "../sharedComponents/MicroPage/OffersMicroPage";
import MicroEventPaymentFail from "../sharedComponents/Payments/MicroEventPaymentFail";
import MicroEventPaymentSuccess from "../sharedComponents/Payments/MicroEventPaymentSuccess";
import PrivateRoutes from "./PrivateRoutes";
// const DetailsPage = React.lazy(() => import("../TempFiles/DetailsPage"));
// const Dashboard = React.lazy(() => import("../components/Pages/Dashboard"));

const MainRoutes = ({ setLocale, locale }) => {
  // const navigationMenu = useSelector(
  //   (state) => state.navigationMenu.navigationMenu
  // );
  //const [defaultPage, setdefaultPage] = useState({});
  const legalText = useSelector((state) => state.generalDetails.legalText);
  // const posts = useSelector((state) => state.generalDetails.posts);
  const user_sign_In = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings?.user_sign_In
  );
  const generalDetails = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  const userId = useSelector(
    (state) => state.auth?.user?.personalInformation?.id
  );

  const [headerTwoActive, setHeaderTwoActive] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // console.log("this is test");
  // useEffect(() => {
  //   (window.location.pathname.indexOf("/register/verify") !== -1 &&
  //   window.location.pathname.indexOf("/public/event/overview") !== -1)
  //     ? setHeaderTwoActive(true)
  //     : setHeaderTwoActive(false);
  // }, []);
  useEffect(() => {
    location.pathname === "/register" ||
    location.pathname.includes("/doubleoptin/activation") ||
    location.pathname.includes("/forgot-password") ||
    location.pathname === "/register/verify" ||
    location.pathname === "/public/event/overview" ||
    location.pathname === "/offers" ||
    location.pathname === "/legaltexts/" ||
    location.pathname === "/login" ||
    !!sessionStorage.getItem("EventsMicroPage") ||
    (!!sessionStorage.getItem("OffersMicroPage") &&
      location.pathname === "/payment")
      ? setHeaderTwoActive(true)
      : setHeaderTwoActive(false);
  }, [location]);
  // useEffect(() => {
  //   if (navigationMenu && navigationMenu.length > 0) {
  //     const page = navigationMenu.filter(
  //       (element) => element?.default_page === 1
  //     )[0];
  //     setdefaultPage(page);
  //   }
  // }, [navigationMenu]);
  useEffect(() => {
    // console.log("this is inside routes", user_sign_In);
  }, [navigate]);

  useEffect(() => {
    if (generalDetails) {
      setRootVariables(rootVariables);
    }
  }, [generalDetails]);

  useEffect(() => {
    if (userId) {
      getContactDetails().then(({ data }) => {
        dispatch(actions.setUser(data));
        //   dispatch(actions.setUser(data.personalInformation))
      });
      //to valuate purchase details
      valuatePurchaseDetails();
      getCommunitiesList().then((data) => {
        dispatch(communityAction.setCommunities(data.data.communitiesDetails));
      });
      joinedCommunities().then((data) => {
        dispatch(communityAction.setJoinedCommunities(data.data.communities));
      });
    }
  }, [userId]);

  useEffect(() => {
    if (generalDetails && generalDetails?.favicon_url) {
      document
        .getElementById("favicon-url")
        ?.setAttribute("href", generalDetails?.favicon_url);
    }
  }, [generalDetails?.favicon_url]);

  const rootVariables = {
    "--accent-color": generalDetails.accent_color,
    "--accent-color-10": generalDetails.accent_color + "10",
    "--accent-color-20": generalDetails.accent_color + "20",
    "--accent-color-30": generalDetails.accent_color + "30",
    "--accent-color-80": generalDetails.accent_color + "80",
    "--accent-textcolor": generalDetails.accent_text_color,
    "--navigationtop-bg": generalDetails.navigation_top_background,
    "--navigation-text": generalDetails.navigation_text,
    "--navigationtop-text": generalDetails.navigation_top_text,
    "--navigation-bg": generalDetails.navigation_background,
    "--footer-bg": generalDetails.footer_background,
    "--footer-textcolor": generalDetails.footer_text,
    "--font": generalDetails.font,
    "--success-alert": generalDetails.success_alert,
    "--warning-alert": generalDetails.warning_alert,
    "--error-alert": generalDetails.error_alert,
    "--font-Family": generalDetails.font,
    "--hyperlink-color": generalDetails.hyperlink_color,
  };
  const setRootVariables = (rootVar) => {
    Object.entries(rootVar).forEach((val) => {
      document.documentElement.style.setProperty(val[0], val[1]);
    });
  };

  // const isAuthorized = useSelector<RootState>(({auth}) => auth.isAuthorized) as boolean
  const accessToken = sessionStorage.getItem("accessToken");
  return user_sign_In !== undefined ? (
    <div className="d-flex flex-column h-100">
      <div style={{ flex: 1 }}>
        {navigate.state && navigate.state !== "loading" ? null : (
          <div>
            {(!accessToken && user_sign_In === "yes") || headerTwoActive ? (
              <HeaderWithoutNavbar
                // setLocale={setLocale}
                //locale={locale}
                headerTwoActive={headerTwoActive}
              />
            ) : (
              <HeaderWithNavbar
              //setLocale={setLocale}
              //locale={locale}
              />
            )}
          </div>
        )}
        <Routes>
          {legalText && Object.keys(legalText).length > 0
            ? Object.values(legalText).map((ele) => {
                const result = CheckSpecialChar(ele?.name);
                if (result) {
                  return (
                    <Route
                      exact
                      key={ele.id}
                      path={"/legaltexts/" + result.replace(/ /g, "-")}
                      element={
                        <LegalText details={ele} versionId={ele?.version_id} />
                      }
                    ></Route>
                  );
                }
              })
            : null}
          <Route
            exact
            path="/public/event/overview/:eventUid"
            element={<EventsMicroPage />}
          />
          <Route
            exact
            path="/doubleoptin/activation/:email"
            element={<ActivationCodeVerification />}
          />
          <Route exact path="/offers/:code" element={<OffersMicroPage />} />
          <Route exact path="/register/verify/*" element={<Registration />} />
          <Route exact path="/register" element={<RegistrationDefault />} />
          {/* <Route exact path="/register/verify1/*" element={<ForgotPasswordCodeVerification />} />
        <Route exact path="/register/verify2/*" element={<NewPassword />} />
        <Route exact path="/register/verify3/*" element={<LoginCodeVerification />} /> */}
          <Route exact path="/forgot-password/*" element={<ForgotPassword />} />
          {!!sessionStorage.getItem("EventsMicroPage") ||
          !!sessionStorage.getItem("OffersMicroPage") ? (
            <>
              <Route
                exact
                path="payment/cancel"
                element={<MicroEventPaymentFail />}
              />
              <Route
                exact
                path="payment/success"
                element={<MicroEventPaymentSuccess />}
              />
            </>
          ) : null}

          <Route path="/404" element={<Page404 />} />
          {!accessToken && user_sign_In && user_sign_In === "yes" ? (
            <>
              <Route path="*" element={<AuthPage />} />
            </>
          ) : (
            <>
              <Route path="*" element={<PrivateRoutes />} />
            </>
          )}
        </Routes>
      </div>

      <div>{headerTwoActive ? null : <Footer />}</div>
    </div>
  ) : null;
};

export default MainRoutes;
