import { saveAs } from "file-saver";
import React from "react";
import { Modal } from "react-bootstrap";
import "./style.scss";

const PreviewModal = ({ show, closeModal, image, id, name }) => {
  console.log("image", id);

  const downloadFile = () => {
    const accessToken = sessionStorage.getItem("accessToken");

    fetch(
      `${process.env.REACT_APP_API_URL}/mediaManager/downloadFile/` + id,
      {}
    )
      .then((data) => {
        return data;
      })
      .then((data) => data.blob())
      .then((res) => blobToBase64(res, name));
  };

  const blobToBase64 = (blob, file) => {
    var reader = new FileReader();
    reader.onload = function () {
      const readUrl = reader.result?.toString() || "";
      saveAs(readUrl, file);
    };
    return reader.readAsDataURL(blob);
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName="medium-size-modal"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <h4 className="mb-0">Gallery</h4>
      </Modal.Header>
      <Modal.Body>
        <div className=" position-relative">
          <img
            className="img img-fluid w-100 h-100 preview-image"
            src={image}
            alt="card_image"
          />
          {/* <AiOutlineCloseCircle
            onClick={() => {
              closeModal();
            }}
            className="close-icon position-absolute"
          /> */}

          {/* <a href={image} download> */}
          {/* <HiOutlineDownload
            onClick={() => {
              saveAs(image, "image");
            }}
            className="dowload-img-icon position-absolute"
          /> */}
          {/* </a> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          <a
            // href={image}
            download
            onClick={(e) => downloadFile(e)}
            rel="noreferrer"
            // target="_blank"
          >
            <button
              className="btn btn-custom p-2"
              style={{ minWidth: "100px" }}
            >
              Download
            </button>
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewModal;
