import React from "react";
import { useSelector } from "react-redux";

function DevicesCard({ element, getCustomerDetailsAPI }) {
  const user_disconnect_device = useSelector(
    (state) =>
      state.generalDetails.generalDetails.general_settings
        .user_disconnect_device
  );

  return (
    <>
      <div
        className="d-flex p-3 mb-3 justify-content-between align-items-center position-relative"
        style={{ border: "2px solid #f3f3f3", borderRadius: "10px" }}
      >
        {/* <div className="d-flex justify-content-between"> */}
        <div>
          <div style={{ fontWeight: "bold", fontSize: "18px" }}>
            {element?.device_name}
          </div>
          <div>{element?.device_location}</div>
        </div>
        {/* <div>
          <MdDelete className="clipboard-icon" />
        </div> */}
        {user_disconnect_device === "no" ? null : (
          <div
            onClick={() => {
              // setShowModal(true);
            }}
            className="btn btn-danger fw-bold cursor-pointer ms-2"
          >
            {/* <span>
              <VscCloudDownload className="product-download-icon" />
            </span> */}
            Delete
          </div>
        )}
        {/* </div> */}
      </div>
    </>
  );
}

export default DevicesCard;
