import React from "react";
import { RiLockFill } from "react-icons/ri";
import "./style.scss";

const LockIcon = ({ size, sidebars }) => {
  return (
    <>
      <div
        className={`${
          size === "large" ? "p-3" : "p-2"
        } rounded-circle position-absolute d-flex justify-content-center align-items-center`}
        style={{
          //   background: "var(--accent-color)",
          zIndex: "10",
          height: "100%",
          width: "100%",
          //   opacity: "100%",
        }}
      >
        <RiLockFill
          className={`lock-icon ${
            size && size === "large"
              ? "lock-icon-large"
              : size === "xsmall"
              ? "lock-icon-xsmall"
              : "lock-icon-small"
          }`}
        />
      </div>
    </>
  );
};

export default LockIcon;
