import React from "react";
import { useIntl } from "react-intl";
import SubscriptionCard from "./SubscriptionCard";

function SubscriptionsList({
  subscriptionList,
  cancelButton,
  getSubscriptionsListAPI,
  canceled,
}) {
  const intl = useIntl();
  return (
    <>
      <div className="pb-3 mt-2">
        {subscriptionList && subscriptionList.length > 0 ? (
          subscriptionList.map((ele) => {
            return (
              <SubscriptionCard
                element={ele}
                cancelButton={!ele?.is_cancelled && cancelButton}
                canceled={canceled}
                getSubscriptionsListAPI={getSubscriptionsListAPI}
              />
            );
          })
        ) : (
          <>
            <div className="mt-3">
              {intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PRODUCTS_NO_SUBSCRIPTIONS_PURCHASED",
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default SubscriptionsList;
