import React from "react";
import Title from "../../sharedComponents/Title";
const LegalText = ({ details, versionId }) => {
  return (
    <>
      <div className="container text-center mt-3">
        <Title title={details?.title}></Title>
        <div
          className="mt-3 text-start"
          dangerouslySetInnerHTML={{ __html: details?.description }}
        ></div>
        {/* <p className='mt-3 text-start'>{details?.description.replace(/(<([^>]+)>)/gi, "")}</p> */}
      </div>
    </>
  );
};

export default LegalText;
