import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { actions } from "../../../Redux/AuthReducer";
import "../../../sharedComponents/NewsLetter/style.scss";
import { login } from "../../auth/Redux/AuthApi";
import LoginModal from "../LoginModal";
import "../style.scss";
//import  { intl.formatMessage({id:'USERS_PASSWORD_REQUIRED'}) } from "../../../i18n/translate";
import axios from "axios";
import { browserName } from "react-device-detect";
import { useIntl } from "react-intl";
import InputLabel from "../../../sharedComponents/InputLabel/InputLabel";
import LoginTwoFactorAuthModal from "../../../sharedComponents/Modal/LoginTwoFactorAuthModal";
import TextInput from "../../../sharedComponents/TextInput/TextInput";

function Login({ show, closeModal }) {
  const intl = useIntl();
  // const [show, setShow] = useState(false);

  // const closeModal = () =>{
  //     setShow(false)
  // }
  const initialValues = {
    email: "",
    password: "",
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .required(intl.formatMessage({ id: "USERS_EMAIL_REQUIRED" })),
    password: Yup.string().required(
      intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" })
    ),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientDetails_id = parseInt(
    sessionStorage.getItem("clientDetails_id") || "0"
  );
  const clientDetails_secret =
    sessionStorage.getItem("clientDetails_secret") || "";

  const saveAccessToken = (accessToken) => {
    sessionStorage.setItem("accessToken", accessToken);
    setTimeout((accessToken) => {
      dispatch(actions.login(accessToken));
    }, 0);
  };
  const [showLoginTwoFactorAuthModal, setShowLoginTwoFactorAuthModal] =
    useState(false);
  const [userId, setUserId] = useState();
  const [ip, setIP] = useState("");

  const user_registration = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings?.user_registration
  );

  const saveRefreshToken = (refresh_token) => {
    localStorage.setItem("refreshToken", refresh_token);
  };

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      //   setLoading(true)
      login({
        client_id: clientDetails_id,
        client_secret: clientDetails_secret,
        email: values.email,
        password: values.password,
        ip: ip,
        browser_agent: browserName,
      })
        .then(
          ({
            data: {
              userDetails,
              tokenDetails: { access_token, refresh_token } = {},
            },
          }) => {
            dispatch(actions.setUser(userDetails));
            // save access_token in session storage
            const { has2FA, id } = userDetails;

            if (has2FA) {
              setUserId(id);
              setShowLoginTwoFactorAuthModal(true);
              // closeModal();
            } else {
              access_token && saveAccessToken(access_token);
              refresh_token && saveRefreshToken(refresh_token);
              navigate("/");
              closeModal();
            }
            // setLoading(false)
          }
        )
        .catch((err) => {
          err.response?.data?.errors?.content
            ? setStatus(err.response.data?.errors?.content)
            : err.response?.data?.errors?.email
            ? setStatus(err.response.data?.errors?.email)
            : setStatus(
                intl.formatMessage({
                  id: "VALIDATE_LOGIN_DETAILS_ERROR_MESSAGE",
                })
              );
          //   setLoading(false)
          setSubmitting(false);
        })
        .finally(() => {
          //   setLoading(false)
        });
    },
  });

  const closeAuthModal = () => {
    setShowLoginTwoFactorAuthModal(false);
  };

  return (
    <>
      {showLoginTwoFactorAuthModal && (
        <LoginTwoFactorAuthModal
          show={showLoginTwoFactorAuthModal}
          closeModal={closeAuthModal}
          closeLoginModal={closeModal}
          userId={userId}
          email={formik.values.email}
          password={formik.values.password}
        />
      )}
      <LoginModal show={show} closeModal={closeModal} centered>
        <form
          className="form w-100"
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_login_signin_form"
        >
          <div className=" text-left p-2">
            <h4 className="mb-2 modal-color text-center welcome-text text-start">
              {intl.formatMessage({ id: "WELCOME" })}
            </h4>
            <h6
              className="mb-2 mt-2 modal-color text-center welcome-text"
              style={{ fontSize: "14px" }}
            >
              {intl.formatMessage({ id: "LOGIN_MESSAGE_ONE" })}
            </h6>
          </div>
          {formik.status && (
            <div
              className="mb-10 alert alert-danger"
              style={{ margin: "0px", padding: "10px" }}
            >
              <div className="alert-text font-weight-bold text-center">
                {formik.status}
              </div>
            </div>
          )}
          {/* <div className=' d-flex'>
                    <span className=' me-auto'>Or create account</span>
                    <span className=' ms-auto'>log in help</span>
                </div> */}
          <div className="fv-row mb-2 position-relative p-2 w-100">
            <InputLabel required text={intl.formatMessage({ id: "EMAIL" })} />
            <TextInput
              value={formik.values.email}
              onChange={(value) => {
                formik.setFieldValue("email", value);
              }}
              isError={formik.touched.email && formik.errors.email}
            />
            {formik.touched.email && formik.errors.email && (
              <div style={{ color: "var(--error-alert)" }}>
                <span role="alert">{formik.errors.email}</span>
              </div>
            )}
          </div>
          <div className="fv-row mb-1 p-2">
            <div className="d-flex justify-content-between">
              <InputLabel
                required
                text={intl.formatMessage({ id: "PASSWORD" })}
              />
              <div
                className="d-inline-flex flex-end"
                style={{ position: "relative", top: "2px" }}
              >
                <Link
                  to={"/forgot-password"}
                  onClick={closeModal}
                  style={{ color: `var(--accent-color)` }}
                >
                  {intl.formatMessage({ id: "FORGOT_PASSWORD" })}
                </Link>
              </div>
            </div>
            <TextInput
              value={formik.values.password}
              type="password"
              onChange={(value) => {
                formik.setFieldValue("password", value);
              }}
              isError={formik.touched.password && formik.errors.password}
            />
            {formik.touched.password && formik.errors.password && (
              <div style={{ color: "var(--error-alert)" }}>
                <span role="alert">{formik.errors.password}</span>
              </div>
            )}
          </div>
          {/* <div class="input-icons" style={{marginBottom:'10px'}}>
                    <i className="fa fa-user"
                        style={{minWidth:'50px', padding:'10px', position:'absolute'}}
                        >
                    </i>
                    <input className="input-field" 
                        style={{padding:'10px'}}
                        type="text" 
                        placeholder="Username" />
                </div> */}
          {/* <p className=' input-group with-icon'>
                    <span className=' placeholder'>
                            <div className=' icon icon-email'></div>
                            Email
                        </span>
                    <input
                            // {...formik.getFieldProps('email')}
                            className='form-control form-control-lg form-control-solid'
                            type='email'
                            name='email'
                            autoComplete='off'
                        />
                </p> */}
          <div className="text-center px-2">
            <button
              type="submit"
              id="button-custom"
              color="white"
              className=" w-100 my-3"
            >
              {intl.formatMessage({ id: "LOGIN" })}
            </button>
            <div className="text-center mb-3">
              <div className="">
                {intl.formatMessage({ id: "LOGIN_MESSAGE_TWO" })}{" "}
                <Link
                  to={
                    user_registration === "yes"
                      ? "/register"
                      : "/register/verify"
                  }
                  state={{ customSignup: true }}
                  style={{ color: "var(--accent-color)" }}
                  onClick={closeModal}
                >
                  {intl.formatMessage({ id: "SIGN_UP" })}
                </Link>{" "}
                {intl.formatMessage({ id: "HERE" })}
              </div>
            </div>
          </div>
        </form>
      </LoginModal>
    </>
  );
}

export default Login;
