import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { BiSearchAlt2 } from "react-icons/bi";
import { useIntl } from "react-intl";
import ReactPaginate from "react-paginate";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../../../sharedComponents/Loader";
import Title from "../../../sharedComponents/Title";
import GeneralSearchList from "../../Pages/GeneralSearchList";
import { getGeneralPageSearchDetails } from "../Redux/AuthApi";

const GeneralSearchDetails = () => {
  const { id, category, pageid } = useParams();
  const [loading, setLoading] = useState(false);
  const [paginationDetails, serPaginationDetails] = useState();
  const [searchDetails, setSearchDetails] = useState();
  const [showError, setShowError] = useState(false);
  const [categoryText, setCategoryText] = useState(category);
  const [searchText, setSearchText] = useState(id);

  const intl = useIntl();
  const currentPage = paginationDetails?.currentPage
    ? paginationDetails?.currentPage
    : 1;
  const mediumScreen = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    setLoading(true);
    getAPIDetails();
  }, []);

  const getAPIDetails = () => {
    getGeneralPageSearchDetails(
      searchText,
      categoryText.toLocaleLowerCase(),
      pageid
    )
      .then((res) => {
        console.log(res.data);
        setSearchDetails(res.data.searchDetails);
        serPaginationDetails(res.data.paginationDetails);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSearchTextChange = (text) => {
    setSearchText(text || "");
  };

  const handleGeneralSearch = () => {
    setLoading(true);
    if (searchText && searchText.length >= 3) {
      getAPIDetails();
      setShowError(false);
    } else {
      setLoading(false);
      setShowError(true);
    }
  };
  const navigate = useNavigate();
  const handlePageClick = async (data) => {
    const _pageId = data.selected + 1;
    navigate(`/general-search/${searchText}/${categoryText}/${_pageId}`);
    getGeneralPageSearchDetails(
      searchText,
      categoryText.toLocaleLowerCase(),
      _pageId
    )
      .then((res) => {
        console.log(res.data);
        setSearchDetails(res.data.searchDetails);
        serPaginationDetails(res.data.paginationDetails);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container mt-2">
      <Title title={"General Search"}></Title>
      <div className="row mt-4">
        <div
          className={`w-100 `}
          style={{
            border: "none",
            paddingBottom: "30px",
          }}
        >
          <div className="">
            <Card
              style={{
                border: "none",
              }}
            >
              <Card.Header
                style={{
                  padding: "16px",
                  background: "#ffff",
                  border: "none",
                  fontSize: "30px",
                }}
              >
                <div>
                  {paginationDetails?.actual_total_items} Search results for:{" "}
                  {searchText}
                </div>
              </Card.Header>
              <Card.Body>
                <div className="d-flex align-items-center ">
                  <input
                    type="text"
                    className="form-control form-control-solid ps-15 me-2"
                    placeholder={intl.formatMessage({
                      id: "LABEL_ENTER_SEARCH_TEXT",
                    })}
                    value={searchText}
                    onChange={(e) => {
                      if (e.target.value.length >= 3) {
                        setShowError(false);
                      }
                      onSearchTextChange(e.target.value);
                    }}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      width: "300px",
                    }}
                  />
                  <div className="me-2">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        {categoryText}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setCategoryText("Posts");
                          }}
                        >
                          {intl.formatMessage({
                            id: "GENERAL_SEARCH_DROPDOWN_POSTS_VALUE",
                          })}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setCategoryText("Events");
                          }}
                        >
                          {intl.formatMessage({
                            id: "GENERAL_SEARCH_DROPDOWN_EVENTS_VALUE",
                          })}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setCategoryText("Courses");
                          }}
                        >
                          {intl.formatMessage({
                            id: "GENERAL_SEARCH_DROPDOWN_COURSES_VALUE",
                          })}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Link
                    to={
                      searchText && searchText.length >= 3
                        ? `/general-search/${searchText}/${categoryText}/${1}`
                        : ""
                    }
                    className=""
                    style={{ color: "var(--accent-color)" }}
                  >
                    <div
                      className="p-2  d-flex  align-items-center popupSearch"
                      style={{ borderRadius: "5px" }}
                      onClick={() => {
                        handleGeneralSearch();
                      }}
                    >
                      <BiSearchAlt2
                        style={{
                          fontSize: "21px",
                          color: "#ffff",
                        }}
                        type="button"
                      />
                    </div>
                  </Link>
                </div>
                {showError && (
                  <div
                    className="d-flex align-items-center text-danger mx-2 "
                    style={{ fontSize: "13px" }}
                  >
                    {intl.formatMessage({
                      id: "GENERAL_SEARCH_ERROR_MESSAGE",
                    })}
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="generalSearchPageContainer">
              {
                searchDetails && searchDetails.length > 0 ? (
                  <>
                    <hr></hr>
                    <GeneralSearchList
                      SearchData={searchDetails}
                      type={categoryText.toLocaleLowerCase()}
                      currentPage={paginationDetails?.current_page}
                    />
                  </>
                ) : null
                // (
                //   <div className="d-flex justify-content-center align-items-center">
                //     {intl.formatMessage({ id: "GENERAL_SEARCH_NO_DATA_TEXT" })}
                //   </div>
                // )
              }

              {searchDetails &&
              searchDetails.length > 0 &&
              paginationDetails &&
              Object.entries(paginationDetails).length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    padding: "10px 44px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ReactPaginate
                    previousLabel={""}
                    nextLabel={""}
                    breakLabel={"..."}
                    pageCount={paginationDetails?.number_of_pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={mediumScreen ? 1 : 2}
                    onPageChange={(data) => {
                      handlePageClick(data);
                    }}
                    containerClassName={
                      "pagination-wrapper d-flex justify-content-center align-items-center my-2 mb-3"
                    }
                    pageClassName={"pagination-numbers"}
                    pageLinkClassName={"custom-page-link"}
                    previousLinkClassName={"arrow arrow-left pagination-arrow"}
                    nextLinkClassName={"arrow arrow-right pagination-arrow"}
                    breakClassName={"pagination-numbers"}
                    breakLinkClassName={"custom-page-link"}
                    activeClassName={"active-page"}
                    initialPage={paginationDetails?.current_page - 1}
                  />
                  <span>
                    {intl.formatMessage({
                      id: "General_SEARCH_PAGINATION_SITE_TEXT",
                    })}{" "}
                    {paginationDetails?.current_page}{" "}
                    {intl.formatMessage({
                      id: "GENERAL_SEARCH_PAGINATION_OF_TEXT",
                    })}{" "}
                    {paginationDetails?.number_of_pages}
                  </span>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GeneralSearchDetails;
