import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { getStripeOffers, getStripeOffersCheckOutUrl } from "../../API/Api";
import { formatDate, useCustomMediaQuery } from "../../utils";
import CustomFooter from "../Footer/CustomFooter";
import InputLabel from "../InputLabel/InputLabel";
import Loader from "../Loader";
import AdditionalFields from "../Modal/AdditionalFields";
import LegalTextModal from "../Modal/LegalTextModal";
import TextInput from "../TextInput/TextInput";
import { errorToast } from "../Toast/Toasts";
import "./style.scss";

const OffersMicroPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { isXsScreen } = useCustomMediaQuery();
  const params = useParams();
  const { code } = params;
  const [stripeDetails, setstripeDetails] = useState({});
  // const [Error, setError] = useState("");
  const [additionalFields, setadditionalFields] = useState({});
  const [regStatus, setregStatus] = useState("");
  const [Loading, setLoading] = useState(true);
  const [cancelLoading, setcancelLoading] = useState(false);
  const [registerLoading, setregisterLoading] = useState(false);
  const [legalTexts, setlegalTexts] = useState([]);
  const [showLegalTextModal, setshowLegalTextModal] = useState(
    legalTexts ? new Array(legalTexts?.length).fill(false) : [false]
  );
  const [legalTextData, setlegalTextData] = useState({});
  const [legalString, setLegalString] = useState(
    legalTexts && legalTexts.length > 0
      ? legalTexts.map((ele) => ele?.legal_text_element)
      : []
  );
  sessionStorage.setItem("OffersMicroPage", true);
  sessionStorage.setItem("pathName", window.location.pathname);
  const [checked, setchecked] = useState({});
  const handleCloseModal = (index) =>
    setshowLegalTextModal((prev) => {
      prev[index] = false;
      return [...prev];
    });
  const handleShowModal = (index) =>
    setshowLegalTextModal((prev) => {
      prev[index] = true;
      return [...prev];
    });
  useEffect(() => {
    getStripeOffers(code)
      .then((response) => {
        const {
          data: { status },
        } = response;
        if (response.status === 200 && status === "Success") {
          setLoading(false);
          setstripeDetails(response?.data?.stripeOfferDetails);
          setlegalTexts(response?.data?.stripeOfferDetails?.legal_texts);
        } else {
          sessionStorage.removeItem("OffersMicroPage");
          sessionStorage.removeItem("pathName");
          navigate("../../login");
          // setError(message);
        }
      })
      .catch((error) => {
        throw error;
      });
  }, []);
  useEffect(() => {
    if (legalTexts && legalTexts.length > 0) {
      let newObj = {};
      legalTexts.forEach((ele, index) => {
        setlegalTextData((prev) => {
          if (checked[`checked_${index}`]) {
            newObj[`legal_texts[${index}][id]`] = ele?.legal_text_id;
            newObj[`legal_texts[${index}][version_id]`] =
              ele?.legal_text_version_id;
          }
          return newObj;
        });
      });
    }
  }, [legalTexts, checked]);
  useEffect(() => {
    if (legalTexts && legalTexts.length > 0) {
      legalTexts.forEach((ele, index) => {
        if (ele?.legal_text_element) {
          const placeholder = ele?.legal_text_element?.match(/\{{%(.*?)\%}}/g);
          if (placeholder && placeholder.length > 0) {
            setLegalString((prev) => {
              if (placeholder && placeholder.length > 0) {
                prev[index] = Array.isArray(prev[index])
                  ? prev[index]
                  : prev[index]?.split(placeholder[0]);
              } else {
                prev[index] = prev[index].concat(
                  ` <a class='read-more-btn border-0 bg-white text-decoration-underline'>Read more</a>`
                );
              }
              return [...prev];
            });
          }
        }
      });
    }
  }, [legalTexts]);
  useEffect(() => {
    setLegalString(
      legalTexts &&
        legalTexts.length > 0 &&
        legalTexts.map((ele) => ele?.legal_text_element)
    );
  }, [legalTexts]);

  useEffect(() => {
    if (legalTexts && legalTexts.length > 0) {
      legalTexts.forEach((ele, index) => {
        setchecked((prev) => {
          prev[`checked_${index}`] = false;
          return { ...prev };
        });
      });
    }
  }, [legalTexts]);
  const formik = useFormik({
    initialValues: { code: "", status: "", ...checked },
    validate: (values) => {
      let errors = {};
      if (!values?.code) {
        errors.code = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (legalTexts && legalTexts.length > 0) {
        legalTexts.forEach((ele, index) => {
          if (!values[`checked_${index}`] && ele.required === 1) {
            errors[`checked_${index}`] = intl.formatMessage({
              id: "VALIDATE_TERMS_AND_CONDITIONS_MESSAGE",
            });
          }
        });
      }
      return errors;
    },
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      if (regStatus === "cancel") setcancelLoading(true);
      else if (regStatus === "success") setregisterLoading(true);
      const paramsObject = {
        code: values.code,
        status: regStatus,
        ...legalTextData,
        ...additionalFields,
        //post_id: postId,
      };
      try {
        getStripeOffersCheckOutUrl(stripeDetails?.id, paramsObject).then(
          (response) => {
            actions.setSubmitting(false);
            if (regStatus === "cancel") setcancelLoading(false);
            else if (regStatus === "success") setregisterLoading(false);
            const {
              data: { status },
            } = response;
            if (status === "Success") {
              window.location.href = response?.data?.stripeProductDetails?.url;
            } else if (status === "Error") {
              // setError(response.data.message);
              errorToast(response.data.message);
            }
          }
        );
      } catch (error) {
        if (regStatus === "cancel") setcancelLoading(false);
        else if (regStatus === "success") setregisterLoading(false);
        actions.setSubmitting(false);
        throw error;
      }
    },
  });
  const handleChecked = (event, index) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
    setchecked((prev) => {
      prev[`checked_${index}`] = checked;
      return { ...prev };
    });
  };
  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div className="container mb-5" style={{ maxWidth: "750px" }}>
          <div className="d-flex  flex-column quote">
            {/* <div className="d-flex justify-content-between align-items-start ">
          <div>OFFER</div>
          <div>Valid until {formatDate(stripeDetails?.expiration_date)}</div>
        </div> */}
            <div
              style={{
                marginLeft: "auto",
                marginTop: "1rem",
                fontSize: "30px",
              }}
            >
              {" "}
              &#8364;
              {(parseInt(stripeDetails?.stripe_product?.price) / 100).toFixed(
                2
              )}
            </div>
          </div>

          <div
            className={`d-flex justify-content-between mt-5 ${
              isXsScreen ? "flex-column" : ""
            }`}
          >
            <div className="d-flex flex-column">
              <h5>
                {intl.formatMessage({ id: "SHARED_COMPONENTS_OFFERS_TITLE" })}
              </h5>
              <div>
                {stripeDetails?.customer?.first_name}{" "}
                {stripeDetails?.customer?.last_name}
              </div>
              <div>{stripeDetails?.customer?.email}</div>
            </div>
            <div>
              <table className="custom-table">
                <tbody>
                  <tr>
                    <td>
                      {intl.formatMessage({
                        id: "SHARED_COMPONENTS_OFFERS_OFFER_NUMBER",
                      })}
                    </td>
                    <td>{stripeDetails?.offer_number}</td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      {intl.formatMessage({
                        id: "SHARED_COMPONENTS_OFFERS_ISSUE_DATE",
                      })}
                    </td>
                    <td>{formatDate(stripeDetails?.date_of_issue)}</td>
                  </tr>
                  <tr>
                    <td>
                      {intl.formatMessage({
                        id: "SHARED_COMPONENTS_OFFERS_EXPIRATION_DATE",
                      })}
                    </td>
                    <td>{formatDate(stripeDetails?.expiration_date)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="my-3">
            <table className="product-table">
              <thead>
                <tr>
                  <th>
                    {intl.formatMessage({
                      id: "SHARED_COMPONENTS_OFFERS_DESCRIPTION",
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: "SHARED_COMPONENTS_OFFERS_TAX_CODE",
                    })}
                  </th>
                  <th></th>
                  <th>
                    {intl.formatMessage({
                      id: "SHARED_COMPONENTS_OFFERS_AMOUNT",
                    })}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="py-3">
                  <td>
                    <h6>{stripeDetails?.stripe_product?.name}</h6>
                    <p>{stripeDetails?.stripe_product?.description}</p>
                  </td>
                  <td>{stripeDetails?.stripe_product?.tax_code}</td>
                  <td>
                    {/* &#8364;
                {(parseInt(stripeDetails?.stripe_product?.price) / 100).toFixed(
                  2
                )} */}
                  </td>
                  <td>
                    &#8364;
                    {(
                      parseInt(stripeDetails?.stripe_product?.price) / 100
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    {intl.formatMessage({
                      id: "SHARED_COMPONENTS_OFFERS_SUB_TOTAL",
                    })}
                  </td>
                  <td></td>
                  <td>
                    &#8364;
                    {(parseInt(stripeDetails?.price_subtotal) / 100).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td>
                    {intl.formatMessage({ id: "SHARED_COMPONENTS_OFFERS_TAX" })}
                  </td>
                  <td></td>
                  <td>
                    {intl.formatMessage({
                      id: "SHARED_COMPONENTS_OFFERS_0_VALUE",
                    })}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    {intl.formatMessage({
                      id: "SHARED_COMPONENTS_OFFERS_TOTAL",
                    })}
                    <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                      ({stripeDetails?.stripe_product?.price_tax_behavior})*
                    </span>
                  </td>
                  <td></td>
                  <td>
                    &#8364;
                    {(parseInt(stripeDetails?.price_total) / 100).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card px-4 py-4 product-card mx-0 mt-5">
            <form onSubmit={formik.handleSubmit}>
              <div className=" mt-4" style={{ columnGap: "1rem" }}>
                <InputLabel
                  text={intl.formatMessage({
                    id: "TITLE_OFFERS_MESSAGE",
                  })}
                  required
                />
                <div
                //className={`${!isXsScreen ? "w-50" : "w-100"}`}
                >
                  <TextInput
                    type="text"
                    value={formik.values.code}
                    onChange={(value) => {
                      formik.setFieldValue("code", value);
                    }}
                    isError={formik.touched.code && formik.errors.code}
                  />
                  {formik.touched.code && formik.errors.code && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.code}</span>
                    </div>
                  )}
                </div>
              </div>

              {legalTexts &&
                legalTexts.length > 0 &&
                legalTexts.map((ele, index) => {
                  return (
                    <>
                      <div className={`d-flex mt-3`}>
                        <input
                          type="checkbox"
                          name={`checked_${index}`}
                          value={formik.values[`checked_${index}`]}
                          label={legalString?.[index]}
                          // required={!!ele?.required}
                          onBlur={formik.handleBlur}
                          onChange={(event) => handleChecked(event, index)}
                          key={index}
                        />
                        <div className="d-inline">
                          {/* <span
                          style={{ verticalAlign: "super" }}
                        ></span> */}
                          {legalString?.[index] &&
                          Array.isArray(legalString?.[index]) ? (
                            <>
                              <span style={{ verticalAlign: "super" }}>
                                <span>
                                  {" "}
                                  {legalString?.[index][0].split("<p>")[1]}
                                </span>
                                <button
                                  className="read-more-btn border-0 bg-white text-decoration-underline "
                                  onClick={() => handleShowModal(index)}
                                  style={{ padding: "0px 5px" }}
                                  type="button"
                                >
                                  {legalTexts?.[index]?.legal_text_name}
                                </button>
                                <span>
                                  {" "}
                                  {legalString?.[index][1].split("</p>")[0]}
                                </span>
                              </span>
                            </>
                          ) : (
                            <>
                              <span>
                                {legalTexts?.[
                                  index
                                ]?.legal_text_element.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                )}
                              </span>
                              <button
                                className="read-more-btn border-0 bg-white text-decoration-underline "
                                onClick={() => handleShowModal(index)}
                                style={{ padding: "0px 10px" }}
                                type="button"
                              >
                                {intl.formatMessage({ id: "BUTTON_READ_MORE" })}
                              </button>
                            </>
                          )}
                          {formik.touched[`checked_${index}`] &&
                            formik.errors &&
                            formik.errors[`checked_${index}`] && (
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "var(--font-Family)",
                                  color: "var(--error-alert)",
                                }}
                              >
                                {formik.errors[`checked_${index}`]}
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
              <AdditionalFields setadditionalFields={setadditionalFields} />
              <div
                className="d-flex justify-content-center align-items-center mt-3"
                style={{ columnGap: "12px" }}
              >
                <button
                  type="button"
                  id="button-custom"
                  className="mt-3 "
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    setregStatus("cancel");
                    formik.handleSubmit();
                  }}
                  style={{
                    background: "rgb(0,0,0,0.5)",
                    border: "none",
                    marginRight: "0px",
                  }}
                >
                  {intl.formatMessage({ id: "BUTTON_CANCEL" })}
                  {cancelLoading ? (
                    <Oval
                      height={15}
                      width={15}
                      color="white"
                      wrapperStyle={{
                        display: "inline",
                        marginLeft: "1rem",
                        verticalAlign: "middle",
                        padding: "0px",
                      }}
                      wrapperClass="py-0"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="white"
                      strokeWidth={4}
                      strokeWidthSecondary={4}
                    />
                  ) : null}
                </button>
                <button
                  type="button"
                  id="button-custom"
                  className="mt-3"
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    setregStatus("success");
                    formik.handleSubmit();
                  }}
                  style={{ marginLeft: "0px" }}
                >
                  {intl.formatMessage({ id: "BUTTON_SUBMIT" })}
                  {registerLoading ? (
                    <Oval
                      height={15}
                      width={15}
                      color="white"
                      wrapperStyle={{
                        display: "inline",
                        marginLeft: "1rem",
                        verticalAlign: "middle",
                        padding: "0px",
                      }}
                      wrapperClass="py-0"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="white"
                      strokeWidth={4}
                      strokeWidthSecondary={4}
                    />
                  ) : null}
                </button>
              </div>
            </form>
          </div>
          {/* <div
        className={`d-flex justify-content-between mt-5 ${
          isXsScreen ? "flex-column" : ""
        }`}
        style={{ borderTop: "2px solid rgb(0,0,0,0.5)" }}
      >
        <div className="mt-3">
          Questions? Contact Omnics Manager at sannagiri@rgc-manager.de or call
          at +49 176 75620231.
        </div>
        <div className="mt-3">{stripeDetails?.offer_number}</div>
      </div> */}
          <CustomFooter />
        </div>
      )}
      {legalTexts &&
        legalTexts.length > 0 &&
        legalTexts.map((ele, index) => (
          <LegalTextModal
            handleClose={handleCloseModal}
            show={showLegalTextModal[index]}
            versionId={ele?.legal_text_version_id}
            index={index}
            key={index}
          />
        ))}
    </>
  );
};

export default OffersMicroPage;
