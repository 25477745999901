import { convert } from "html-to-text";
import moment from "moment";
import React from "react";
import { IoMdContact } from "react-icons/io";
import { RiCalendarTodoFill } from "react-icons/ri";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocal } from "../../App";
import noImage from "../../assets/noImage.jpg";
import { formatDate, useCustomMediaQuery } from "../../utils";
import Author from "../AuthorImage";
import AuthorInArticle from "../AuthorImage/AuthorInArticle";
import LockIcon from "../LockIcon/LockIcon";
import PlayerIcon from "../PlayerIcon";
import "./style.scss";
const ListArticle = ({ details, sidebars, className, type, view }) => {
  const {
    title,
    post_thumbnail_url,
    introduction,
    description,
    post_type,
    id,
    authors,
    post_media_resolutions,
    post_thumbnail_resolutions,
    post_media_url,
    is_bought,
    is_registered,
  } = details;
  // const [local, setLocal] = useState(localStorage.getItem("language"));

  // useEffect(() => {
  //   const res = localStorage.getItem("language");
  //   setLocal(res);
  // }, [localStorage]);
  const { resolution } = useCustomMediaQuery();
  const local = useLocal();
  const posts_details_date_time_format = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.posts_general_settings
        ?.posts_details_date_time_format
  );

  const showLockIcon = useSelector(
    (state) =>
      state.generalDetails.generalDetails.general_settings
        .display_lock_icon_to_content
  );

  const setPostMediaSource = () => {
    if (type === "event-list") {
      if (
        details.event_image_resolutions &&
        details.event_image_resolutions.length > 0
      ) {
        const source = details.event_image_resolutions.filter((element) => {
          return element?.resolution === resolution;
        });
        return source[0]?.file_url;
      }
      return details.event_image_url ? details.event_image_url : noImage;
    } else {
      const imgResolutions =
        post_type === "image"
          ? post_media_resolutions
          : post_thumbnail_resolutions;
      const file_url =
        post_type === "image" ? post_media_url : post_thumbnail_url;
      if (imgResolutions && imgResolutions.length > 0) {
        const source = imgResolutions.filter((element) => {
          return element?.resolution === resolution;
        });
        return source[0]?.file_url;
      }
      return file_url ? file_url : noImage;
    }
  };

  const eventHostMedia = () => {
    if (
      details.contact_person_image_resolutions &&
      details.contact_person_image_resolutions.length > 0
    ) {
      const source = details.contact_person_image_resolutions.filter(
        (element) => {
          return element?.resolution === "256x144";
        }
      );
      return source[0]?.file_url;
    }
    return details.contact_person_image_url
      ? details.contact_person_image_url
      : noImage;
  };
  return (
    <div className={` ${className} article-two-container mb-3`}>
      <Link
        to={
          type === "event-list"
            ? details?.seo_permalink
              ? `../event/${id}/${details?.seo_permalink}`
              : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
            : details?.seo_permalink
            ? `../post/${id}/${details?.seo_permalink}`
            : `../post/${id}/${details?.title?.replace(/\s+/g, "-")}`
        }
        state={type === "event-list" ? { eventId: id } : { postId: id }}
      >
        <div className="row">
          <div className="col-5">
            <div className="img-container rounded-1 event-image">
              {/* {type === "event-list" && (
              <div className="event-tag">{details?.event_mode_name}</div>
            )} */}
              {type === "event-list" ||
              type === "event-carousel" ? null : showLockIcon === "yes" &&
                (!!is_bought || !!is_registered) ? null : (
                <LockIcon
                  size={"small"}
                  sidebars={sidebars && Object.entries(sidebars).length !== 0}
                />
              )}
              {}
              {post_type === "video" || post_type === "audio" ? (
                <PlayerIcon type={post_type} />
              ) : null}
              {/* {post_type === "video" || post_type === "audio" ? (
              <VideoPlayButton />
            ) : null} */}
              <Link
                to={
                  type === "event-list"
                    ? details?.seo_permalink
                      ? `../event/${id}/${details?.seo_permalink}`
                      : `../event/${id}/${details?.title?.replace(/\s+/g, "-")}`
                    : details?.seo_permalink
                    ? `../post/${id}/${details?.seo_permalink}`
                    : `../post/${id}/${details?.title?.replace(/\s+/g, "-")}`
                }
                state={type === "event-list" ? { eventId: id } : { postId: id }}
                className="image-link"
              >
                <LazyLoadImage
                  src={setPostMediaSource()}
                  effect="blur"
                  alt="image"
                  wrapperClassName="img img-fluid w-100 image-responsive"
                  placeholder={<span className="img-placeholder w-100" />}
                  style={{
                    opacity:
                      type === "event-list" || type === "event-carousel"
                        ? ""
                        : showLockIcon === "yes" &&
                          (!!is_bought || !!is_registered)
                        ? ""
                        : "50%",
                  }}
                />
              </Link>
            </div>
          </div>
          <div className={`col-7 pl-0 d-flex align-items-center`}>
            <div className="article-two-body ">
              <div className="article-two-content">
                {/* <AuthorImage className="mb-2" /> */}
                {type !== "event-list" && type !== "event-carousel" ? (
                  <>
                    <div className="article-two-author-info mb-1">
                      <ul
                        className="list-inline d-flex align-items-center"
                        style={{ marginBottom: "0px" }}
                      >
                        {details.published_at &&
                        posts_details_date_time_format !== "none" ? (
                          <li className="list-inline-item">
                            <span
                              className="text-dark d-flex justify-content-center align-items-center"
                              style={{ marginRight: "1rem", height: "30px" }}
                            >
                              <RiCalendarTodoFill className="icon-calendar" />
                              {local && local === "en"
                                ? posts_details_date_time_format === "date"
                                  ? formatDate(details.published_at)
                                  : formatDate(details.published_at, true)
                                : moment(details.published_at).format(
                                    posts_details_date_time_format === "date"
                                      ? "DD.MM.yyyy"
                                      : "DD.MM.yyyy HH:mm"
                                  )}
                            </span>
                          </li>
                        ) : null}

                        {sidebars && Object.entries(sidebars).length > 0 ? (
                          <li className=" d-none d-md-block  list-inline-item">
                            <AuthorInArticle authors={authors} view={view} />
                          </li>
                        ) : (
                          <li className=" d-none d-md-block  list-inline-item">
                            <Author authors={authors} view={view} />
                          </li>
                        )}
                      </ul>
                    </div>
                  </>
                ) : null}
                <div className="article-two-title">
                  <h5>
                    <Link
                      to={
                        type === "event-list" || type === "event-carousel"
                          ? details?.seo_permalink
                            ? `../event/${id}/${details?.seo_permalink}`
                            : `../event/${id}/${details?.title?.replace(
                                /\s+/g,
                                "-"
                              )}`
                          : details?.seo_permalink
                          ? `../post/${id}/${details?.seo_permalink}`
                          : `../post/${id}/${details?.title?.replace(
                              /\s+/g,
                              "-"
                            )}`
                      }
                      state={
                        type === "event-list" || type === "event-carousel"
                          ? { eventId: id }
                          : { postId: id }
                      }
                    >
                      {title}
                    </Link>
                  </h5>
                  {type !== "event-list" && type !== "event-carousel" ? (
                    <>
                      {details?.contact_person_image_url ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <IoMdContact
                            className="event-icons no-background"
                            style={{ marginRight: "0" }}
                          />
                          <img
                            className=" mx-1"
                            style={{
                              height: "22px",
                              width: "auto",
                              objectFit: "contain",
                            }}
                            src={details?.contact_person_image_url}
                            alt="host-profile"
                          />
                        </div>
                      ) : details?.contact_person_name ? (
                        <div className="d-flex align-items-center gap-1">
                          <div className="host_profile position-relative">
                            <span
                              className="position-absolute"
                              style={{ marginLeft: "7px" }}
                            >
                              {details?.contact_person_name.charAt(0)}
                            </span>
                          </div>
                          <span className="fw-lighter text-muted">
                            {details?.contact_person_name}
                          </span>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {type === "event-list" || type === "event-carousel" ? (
                    <>
                      {" "}
                      {details.sub_title ? (
                        // ? details.sub_title.replace(/(<([^>]+)>)/gi, "")
                        <p className="article-content-description">
                          {convert(details.sub_title)}
                        </p>
                      ) : description ? (
                        <p className="article-content-description">
                          {convert(details.description)}
                        </p>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <p className="d-lg-webkit-box mb-0">
                      {introduction
                        ? // ? introduction.replace(/(<([^>]+)>)/gi, "")

                          convert(introduction)
                        : description
                        ? convert(description)
                        : ""}
                    </p>
                  )}
                  {type === "event-list" ? (
                    <>
                      <div className="article-two-author-info mb-1">
                        <ul
                          className="list-inline d-flex align-items-center"
                          style={{ marginBottom: "0px" }}
                        >
                          {type === "event-list" ||
                          type === "event-carousel" ? (
                            <>
                              <li className="list-inline-item">
                                <span
                                  className="text-dark d-flex justify-content-center align-items-center"
                                  style={{
                                    marginRight: "1rem",
                                    height: "30px",
                                  }}
                                >
                                  <RiCalendarTodoFill className="icon-calendar" />
                                  {details.start_date
                                    ? local && local === "en"
                                      ? formatDate(details.start_date)
                                      : moment(details.start_date).format(
                                          "DD.MM.yyyy"
                                        )
                                    : ""}
                                </span>
                              </li>
                              {details?.contact_person_image_url ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <IoMdContact
                                    className="event-icons no-background"
                                    style={{ marginRight: "0" }}
                                  />
                                  <img
                                    className=" mx-1"
                                    style={{
                                      height: "22px",
                                      width: "auto",
                                      objectFit: "contain",
                                    }}
                                    src={details?.contact_person_image_url}
                                    alt="host-profile"
                                  />
                                </div>
                              ) : details?.contact_person_name ? (
                                <div className="d-flex align-items-center gap-1">
                                  <div className="host_profile position-relative">
                                    <span
                                      className="position-absolute"
                                      style={{ marginLeft: "7px" }}
                                    >
                                      {details?.contact_person_name.charAt(0)}
                                    </span>
                                  </div>
                                  <span className="fw-lighter text-muted">
                                    {details?.contact_person_name}
                                  </span>
                                </div>
                              ) : null}
                            </>
                          ) : details.published_at &&
                            posts_details_date_time_format !== "none" ? (
                            <li className="list-inline-item">
                              <span
                                className="text-dark d-flex justify-content-center align-items-center"
                                style={{ marginRight: "1rem", height: "30px" }}
                              >
                                <RiCalendarTodoFill className="icon-calendar" />
                                {local && local === "en"
                                  ? posts_details_date_time_format === "date"
                                    ? formatDate(details.published_at)
                                    : formatDate(details.published_at, true)
                                  : moment(details.published_at).format(
                                      posts_details_date_time_format === "date"
                                        ? "DD.MM.yyyy"
                                        : "DD.MM.yyyy HH:mm"
                                    )}
                              </span>
                            </li>
                          ) : null}

                          {sidebars && Object.entries(sidebars).length > 0 ? (
                            <li className=" d-none d-md-block  list-inline-item">
                              <AuthorInArticle authors={authors} view={view} />
                            </li>
                          ) : (
                            <li className=" d-none d-md-block  list-inline-item">
                              <Author authors={authors} view={view} />
                            </li>
                          )}
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ListArticle;
