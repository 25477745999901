import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../sharedComponents/Loader";
import Title from "../../../sharedComponents/Title";
import { getCommunitiesList, joinedCommunities } from "../Redux/CommunitiesAPI";
import { actions } from "../Redux/CommunitiesRedux";
import CommunityCard from "./CommunityCard";

const CommunitiesWrapper = () => {
  const intl = useIntl();
  const [communities, setCommunities] = useState([]);
  const [filterCommunities, setFilterCommunities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterText, setfilterText] = useState("All");
  const dispatch = useDispatch();

  const joinedCommunitiesData = useSelector(
    (state) => state.communities.joinedCommunities
  );

  // const getCommunitiesAPI = () => {
  //   setLoading(true);
  //   getCommunitiesList()
  //     .then(({ data: { communitiesDetails } }) => {
  //       setCommunities(communitiesDetails);
  //       dispatch(actions.setCommunities(communitiesDetails));
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const getCommunitiesAPI = async () => {
    try {
      setLoading(true);
      const response = await Promise.all([
        getCommunitiesList(),
        joinedCommunities(),
      ]);

      const {
        data: { communitiesDetails },
      } = response[0];

      const {
        data: { communities },
      } = response[1];
      dispatch(actions.setJoinedCommunities(communities));
      dispatch(actions.setCommunities(communitiesDetails));
      setCommunities(communitiesDetails);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCommunitiesAPI();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilterCommunities(communities);
  }, [communities]);

  useEffect(() => {
    if (searchText.length === 0 && communities?.length > 0) {
      let data = communities.map((ele) => ele);
      setFilterCommunities(data);
    } else {
      let updatedPosts = Object.values(communities).filter((community) => {
        return community.name
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase());
      });
      setFilterCommunities(updatedPosts);
    }
  }, [searchText, communities]);

  const onSearchTextChange = (text) => {
    setSearchText(text || "");
  };

  return (
    <>
      <div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="container mt-4 mb-4">
              <Title
                title={intl.formatMessage({
                  id: "COMMUNITIES_TITLE",
                })}
              />
              <div className="row">
                <div className=" align-items-center justify-content-between d-flex flex-row mb-3">
                  <input
                    type="text"
                    className="form-control form-control-solid ps-15"
                    placeholder={intl.formatMessage({
                      id: "LABEL_ENTER_SEARCH_TEXT",
                    })}
                    value={searchText}
                    onChange={(e) => {
                      onSearchTextChange(e.target.value);
                    }}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      width: "260px",
                    }}
                  />

                  <div className="">
                    <div
                      className="commentMenu d-flex cursorPointer justify-content-center align-items-center"
                      id="commentDropDown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div
                        className="mx-2"
                        style={{ color: "rgb(143 143 143)" }}
                      >
                        {filterText}
                        {" communities"}
                      </div>
                      <FaFilter
                        style={{
                          fontSize: "18px",
                          color: "var(--accent-color)",
                          // marginTop: "12px",
                        }}
                        type="button"
                      />
                    </div>
                    <div
                      className="dropdown-menu  menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-2"
                      aria-labelledby="commentDropDown"
                    >
                      <div
                        className="comment-menu-item px-3"
                        onClick={() => {
                          setfilterText("my");
                          let data = communities.filter((community) => {
                            return joinedCommunitiesData.some(
                              (joinedCommunity) => {
                                return community?.id === joinedCommunity?.id;
                              }
                            );
                          });
                          setFilterCommunities(data);
                        }}
                      >
                        <span className="comment-menu-link px-3">
                          {intl.formatMessage({
                            id: "COMMUNITIES_MY",
                          })}
                        </span>
                      </div>
                      <div
                        className="comment-menu-item px-3"
                        onClick={() => {
                          setfilterText("Public");
                          let data = communities.filter(
                            (ele) => ele?.privacy === "public"
                          );
                          setFilterCommunities(data);
                        }}
                      >
                        <span className="comment-menu-link px-3">
                          {intl.formatMessage({
                            id: "COMMUNITIES_PUBLIC",
                          })}
                        </span>
                      </div>
                      <div
                        className="comment-menu-item px-3"
                        onClick={() => {
                          setfilterText("Private");
                          let data = communities.filter(
                            (ele) => ele?.privacy === "private"
                          );
                          setFilterCommunities(data);
                        }}
                      >
                        <span className="comment-menu-link px-3">
                          {intl.formatMessage({
                            id: "COMMUNITIES_PRIVATE",
                          })}
                        </span>
                      </div>
                      <div
                        className="comment-menu-item px-3"
                        onClick={() => {
                          setfilterText("All");
                          let data = communities.map((ele) => ele);
                          setFilterCommunities(data);
                        }}
                      >
                        <span className="comment-menu-link px-3">
                          {intl.formatMessage({
                            id: "COMMUNITIES_ALL",
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {filterCommunities && filterCommunities.length > 0 ? (
                <>
                  {filterCommunities.map((community, index) => {
                    return (
                      <>
                        <CommunityCard
                          details={community}
                          getCommunitiesAPI={getCommunitiesAPI}
                        />
                        {index !== communities.length - 1 ? (
                          <div className="separator separator-dashed my-4"></div>
                        ) : null}
                      </>
                    );
                  })}
                </>
              ) : null}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CommunitiesWrapper;
