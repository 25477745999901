import Modal from "react-bootstrap/Modal";
import { BsFillStarFill } from "react-icons/bs";
import { useIntl } from "react-intl";

import { useState } from "react";
import { errorToast, successToast } from "../Toast/Toasts";
import "./PostRatingDetails.scss";
import {
  addRatingsComment,
  addRatingsReply,
  updateRatingsComment,
} from "./RatingsAPI";

const AddRatingModal = ({
  show,
  closeModal,
  details,
  rating,
  type,
  replyId,
  updateRatingCommentsAll,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const [selectedStar, setSelectedStar] = useState(
    type === "update" ? rating.ratings : 0
  );
  const [comment, setComment] = useState(
    type === "update" && !replyId ? rating.body : ""
  );

  const handleSubmit = () => {
    setLoading(true);
    if (type === "update") {
      if (replyId) {
        addRatingsReply(details.id, comment, replyId.id).finally(() => {
          setLoading(false);
          closeModal();
          updateRatingCommentsAll();
        });
      } else {
        updateRatingsComment(rating.id, selectedStar, comment)
          .then((data) => {
            successToast(
              intl.formatMessage({ id: "POST_RATINGS_UPDATE_SUCCESS_MESSAGE" })
            );
          })
          .catch(() => {
            errorToast(
              intl.formatMessage({ id: "POST_RATINGS_UPDATE_FAILURE_MESSAGE" })
            );
          })
          .finally(() => {
            setLoading(false);
            closeModal();
            updateRatingCommentsAll();
          });
      }
    } else {
      addRatingsComment(details.id, selectedStar, comment)
        .then((data) => {
          successToast(
            intl.formatMessage({ id: "POST_RATINGS_ADD_SUCCESS_MESSAGE" })
          );
        })
        .catch(() => {
          errorToast(
            intl.formatMessage({ id: "POST_RATINGS_ADD_FAILURE_MESSAGE" })
          );
        })
        .finally(() => {
          setLoading(false);
          closeModal();
          updateRatingCommentsAll();
        });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={closeModal}
        size={"lg"}
        dialogClassName={"buynow-modal"}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {replyId
              ? intl.formatMessage({ id: "POST_RATINGS_ADD_REPLY_BUTTON" })
              : intl.formatMessage({ id: "POST_RATINGS_ADD_MODAL_TITLE" })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {!replyId ? (
              <div className="m-2">
                {[1, 2, 3, 4, 5].map((data, index) => {
                  return (
                    <BsFillStarFill
                      key={index}
                      style={{
                        fontSize: "35px",
                        color: selectedStar > index ? "#ffd814" : "#D3D3D3",
                      }}
                      type="button"
                      onClick={() => {
                        setSelectedStar(data);
                      }}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="m-2">
                <span>
                  {intl.formatMessage({ id: "POST_RATINGS_REPLYING_TO" })}{" "}
                  {replyId.users_username}
                </span>
              </div>
            )}

            <textarea
              id="addTextarea"
              style={{
                width: "100%",
                minHeight: "200px",
                border: "none",
                padding: "20px",
                borderRadius: "20px",
              }}
              value={comment || ""}
              placeholder={intl.formatMessage({
                id: "POST_RATINGS_ADD_MODAL_TEXTAREA_PLACEHOLDER",
              })}
              autoFocus
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center w-100">
            <button
              id="button-custom-two"
              onClick={() => {
                handleSubmit();
              }}
              disabled={
                comment && comment.length > 0 && selectedStar > 1 ? false : true
              }
              type="button"
            >
              {!loading && (
                <span className="indicator-label">
                  {intl.formatMessage({
                    id: "POST_RATINGS_ADD_MODAL_POST_BUTTON",
                  })}
                </span>
              )}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddRatingModal;
