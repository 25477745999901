import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Loader from "../../../sharedComponents/Loader";
import Tabs from "../../../sharedComponents/Tabs/Tabs";
import { TitleHeader } from "../../../sharedComponents/Title";
import { getSubscriptionsList } from "../../auth/Redux/AuthApi";
import SubscriptionsList from "./SubscriptionsList";

function SubscriptionsWrapper() {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [tabActiveIndex, setTabActiveIndex] = useState(0);
  const [subscriptionList, setSubscriptionList] = useState({});

  const onTabChange = (index) => {
    if (index !== tabActiveIndex) {
      setTabActiveIndex(index);
    }
  };

  const getSubscriptionsListAPI = () => {
    setLoading(true);
    getSubscriptionsList()
      .then(({ data }) => {
        setSubscriptionList(data.subscriptions);
        // dispatch(actions.setUser(data))
        //   dispatch(actions.setUser(data.personalInformation))
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSubscriptionsListAPI();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="card mb-4 mb-xl-10 me-0 card-no-border ms-0">
            <div
              className="card-header border-0 cursor-pointer card-header-custom pt-0 px-0"
              role="button"
              //  data-bs-toggle='collapse'
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
            >
              <TitleHeader
                noMarginTop
                title={intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_SUBSCRIPTIONS_TITLE",
                })}
              />
            </div>
            <div>
              {intl.formatMessage({
                id: "ACCOUNT_SETTINGS_SUBSCRIPTIONS_INFO",
              })}
            </div>
            <div className="mt-2 mb-6">
              <Tabs
                tabs={[
                  intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_SUBSCRIPTIONS_TYPE_1",
                  }),
                  intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_SUBSCRIPTIONS_TYPE_2",
                  }),
                  intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_SUBSCRIPTIONS_TYPE_3",
                  }),
                ]}
                activeIndex={tabActiveIndex}
                onActiveIndexChange={(index) => {
                  onTabChange(index);
                }}
              />
            </div>
            {tabActiveIndex === 0 ? (
              <SubscriptionsList
                subscriptionList={subscriptionList.active_subscriptions?.concat(
                  subscriptionList.canceled_subscriptions?.filter(
                    (ele) => ele.is_cancelled === 1 && ele.is_expired === 0
                  )
                )}
                cancelButton={true}
                getSubscriptionsListAPI={getSubscriptionsListAPI}
              />
            ) : tabActiveIndex === 1 ? (
              <SubscriptionsList
                subscriptionList={subscriptionList.canceled_subscriptions?.filter(
                  (ele) => ele.is_cancelled === 1 && ele.is_expired === 1
                )}
                canceled={true}
                getSubscriptionsListAPI={getSubscriptionsListAPI}
              />
            ) : (
              <SubscriptionsList
                subscriptionList={subscriptionList.expired_subscriptions}
                getSubscriptionsListAPI={getSubscriptionsListAPI}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default SubscriptionsWrapper;
