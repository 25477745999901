import { useSelector } from "react-redux/es/hooks/useSelector";
import HeaderImage from "./HeaderImage";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import "./style.scss";
const HeaderWithNavbar = ({ setLocale, locale }) => {
  const generalDetails = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  return (
    <header>
      {generalDetails.navigation_top ? (
        <TopBar
          generalDetails={generalDetails}
          //setLocale={setLocale}
          //locale={locale}
        />
      ) : (
        ""
      )}
      <HeaderImage />
      <NavBar generalDetails={generalDetails} />
    </header>
  );
};
export default HeaderWithNavbar;
