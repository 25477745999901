import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  getPostsDetails,
  setCurrentPage,
} from "../../Redux/PostsDetailsReducer";
import "./style.scss";
const Pagination = ({ pageName, uniqueId, pageId }) => {
  const dispatch = useDispatch();
  //   const smallScreen = useMediaQuery({ maxWidth: 575 })
  const mediumScreen = useMediaQuery({ maxWidth: 767 });
  const [sectionDetails, setSectionDetails] = useState({});
  const paginationDetails = useSelector(
    (state) => state?.postsDetails?.[pageName]?.[uniqueId]?.paginationDetails
  );
  const currentPage = paginationDetails?.currentPage;
  const numberOfPages = paginationDetails?.[1]?.number_of_pages;
  const pageDetails = useSelector(
    (state) => state?.pageDetails?.pages?.[pageName]
  );
  useEffect(() => {
    if (pageDetails?.sections) {
      const details = pageDetails?.sections.filter((ele) => {
        let uId = `${ele.section_id}_${ele.id}`;
        return uId === uniqueId;
      });

      setSectionDetails(details[0]);
    }
  }, [pageName, uniqueId]);
  const handlePageClick = async (data) => {
    const _pageId = data.selected + 1;
    if (!paginationDetails?.[_pageId]) {
      const paramsObject = {
        offset: sectionDetails.offset,
        option_for_featured: sectionDetails.option_for_featured,
        pagination: sectionDetails.pagination,
        items_per_page: sectionDetails.items_per_page,
        groups: sectionDetails.groups,
        groups_condition: sectionDetails.groups_condition,
        categories: sectionDetails.categories,
        categories_condition: sectionDetails.categories_condition,
        tags: sectionDetails.tags,
        tags_condition: sectionDetails.tags_condition,
        sorting: sectionDetails.sorting,
        rule_type: sectionDetails.rule_type,
        type: sectionDetails.type,
      };
      dispatch(
        getPostsDetails({
          paramsObject,
          pageName,
          id: sectionDetails.id,
          type: sectionDetails.type,
          defaultView: sectionDetails.default_view,
          title: sectionDetails.title,
          sectionId: sectionDetails.section_id,
          pageId: _pageId,
        })
      );
    } else {
      dispatch(setCurrentPage({ pageId: _pageId, pageName, uniqueId }));
    }
  };
  return (
    <div>
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        breakLabel={"..."}
        pageCount={numberOfPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={mediumScreen ? 1 : 2}
        onPageChange={handlePageClick}
        containerClassName={
          "pagination-wrapper d-flex justify-content-center align-items-center my-2 mb-3"
        }
        pageClassName={"pagination-numbers"}
        pageLinkClassName={"custom-page-link"}
        previousLinkClassName={"arrow arrow-left pagination-arrow"}
        nextLinkClassName={"arrow arrow-right pagination-arrow"}
        breakClassName={"pagination-numbers"}
        breakLinkClassName={"custom-page-link"}
        activeClassName={"active-page"}
        initialPage={currentPage - 1}
      />
    </div>
  );
};

export default Pagination;
