import React, { useEffect, useState } from "react";
// import { useIntl } from "react-intl";
import EditCompanyAddress from "./EditCompanyAddress";
import NewCompanyAddress from "./NewCompanyAddress";

const CompanyAddressWrapper = ({ userDetails, getCustomerDetailsAPI }) => {
  // const intl = useIntl();
  const [user, setUser] = useState(userDetails);
  useEffect(() => {
    setUser(userDetails);
  }, [userDetails]);

  return (
    <>
      {!!user?.companyAddress ? (
        <EditCompanyAddress
          userDetails={user}
          getCustomerDetailsAPI={getCustomerDetailsAPI}
        />
      ) : (
        <NewCompanyAddress
          userDetails={user}
          getCustomerDetailsAPI={getCustomerDetailsAPI}
        />
      )}
    </>
  );
};

export default CompanyAddressWrapper;
