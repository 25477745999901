import axios from "axios";
import config from "../config";
// import { useDispatch } from "react-redux";
import { Store } from "..";
import { actions } from "../Redux/AuthReducer";

const clearStorage = () => {
  sessionStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  const user_sign_in =
    Store.getState()?.generalDetails?.generalDetails?.general_settings;
  if (user_sign_in === "yes") {
    window?.location?.replace("/");
  }
  setTimeout(() => {
    Store.dispatch(actions.logout());
  }, 0);
};

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const refresh_token = () => {
  const axiosInstance = axios.create();

  const clientDetails_id = parseInt(
    sessionStorage.getItem("clientDetails_id") || "0"
  );
  const clientDetails_secret =
    sessionStorage.getItem("clientDetails_secret") || "";
  const refreshToken = localStorage.getItem("refreshToken") || "";
  return axiosInstance.post(config.APP_URL + `/client/refreshToken`, {
    client_id: clientDetails_id,
    client_secret: clientDetails_secret,
    refresh_token: refreshToken,
    grant_type: "refresh_token",
  });
};

export default function setupAxios(axios, store) {
  let isRefreshing = false;
  let pendingQueue = [];

  // @ts-ignore
  const processQueue = (error) => {
    pendingQueue.forEach((prom) => {
      if (error) {
        // @ts-ignore
        prom?.reject(error);
      } else {
        // @ts-ignore
        prom?.resolve();
      }
    });

    pendingQueue = [];
  };

  axios.defaults.baseURL = config.APP_URL;
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config) => {
      const accessToken = sessionStorage.getItem("accessToken");
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.request.use(
    (config) => {
      const accessToken = sessionStorage.getItem("accessToken");

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      // @ts-ignore

      // @ts-ignore
      if (error.response?.status !== 401) {
        return Promise.reject(error);
      }

      if (error.response?.status === 401) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            // @ts-ignore
            pendingQueue.push({ resolve, reject });
          })
            .then(() => {
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          isRefreshing = true;
          try {
            let res = await refresh_token();
            if (res.data.access_token && res.data.refresh_token) {
              sessionStorage.setItem("accessToken", res.data.access_token);
              localStorage.setItem("refreshToken", res.data.refresh_token);
              isRefreshing = false;
              processQueue(null);
              return axios(originalRequest);
            } else {
              source.cancel();
              clearStorage();
            }
          } catch (e) {
            source.cancel();
            clearStorage();
          }
          isRefreshing = false;
        }
      }
    }
  );
}
