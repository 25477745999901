import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import * as Yup from "yup";
import { CustomDropDown } from "../../../sharedComponents/Dropdown/CustomDropDown";
import CustomFooter from "../../../sharedComponents/Footer/CustomFooter";
import InputLabel from "../../../sharedComponents/InputLabel/InputLabel";
import LegalTextModal from "../../../sharedComponents/Modal/LegalTextModal";
import TextInput from "../../../sharedComponents/TextInput/TextInput";
import { useCustomMediaQuery } from "../../../utils";
import { register } from "../Redux/AuthApi";

function RegistrationDefault({ email, forgotPassword }) {
  const intl = useIntl();
  // const locale = localStorage.getItem("language");
  const navigate = useNavigate();
  const SALUATION_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_SALUATION_OPTIONS" })
  );
  const TITLES_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "USERS_TITLES_OPTIONS" })
  );
  // const generalDetails = useSelector(
  //   (state) => state.generalDetails.generalDetails
  // );

  const checked = {};
  const user_sign_In = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings?.user_sign_In
  );

  const legalTexts = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings
        ?.user_registration_legal_texts
  );

  const [legalString, setLegalString] = useState(
    legalTexts.map((ele) => ele?.legal_text_element)
  );

  const [legalTextData, setlegalTextData] = useState({});
  // const [islegalText, setislegalText] = useState(false);

  // useEffect(() => {
  //   if (legalTexts && legalTexts.length > 0) {
  //     setislegalText(true);
  //   }
  // }, []);

  useEffect(() => {
    if (legalTexts && legalTexts.length > 0) {
      legalTexts.forEach((ele, index) => {
        setlegalTextData((prev) => {
          return {
            ...prev,
            [`legal_texts[${index}][id]`]: ele?.legal_text_id,
            [`legal_texts[${index}][version_id]`]: ele?.legal_text_version_id,
          };
        });
      });
    }
  }, []);

  useEffect(() => {
    if (legalTexts && legalTexts.length > 0) {
      legalTexts.forEach((ele, index) => {
        if (ele?.legal_text_element) {
          const placeholder = ele?.legal_text_element?.match(/\{{%(.*?)\%}}/g);
          //let replacedText = "";
          if (placeholder && placeholder.length > 0) {
            // replacedText = ele?.legal_text_element?.replace(
            //   placeholder[0],
            //   `<button class='read-more-btn border-0 bg-white text-decoration-underline' onClick={()=>console.log('clicked)} >${ele?.legal_text_name}</button>`
            // );
            setLegalString((prev) => {
              if (placeholder && placeholder.length > 0) {
                prev[index] = Array.isArray(prev[index])
                  ? prev[index]
                  : prev[index]?.split(placeholder[0]);
              } else {
                prev[index] = prev[index].concat(
                  ` <a class='read-more-btn border-0 bg-white text-decoration-underline'>Read more</a>`
                );
              }
              return [...prev];
            });
          }
        }
      });
    }
  }, []);

  // const initialValues = {
  //   title: "",
  //   salutation: "",
  //   firstname: "",
  //   lastname: "",
  //   email: "",
  //   username: "",
  //   password: "",
  //   retypePassword: "",
  //   ...checked
  // };
  //console.log(legalTextData)

  if (legalTexts && legalTexts.length > 0) {
    legalTexts.forEach((ele, index) => {
      checked[`checked_${index}`] = false;
    });
  }

  const handleCloseModal = (index) =>
    setshow((prev) => {
      prev[index] = false;
      return [...prev];
    });
  const handleShowModal = (index) =>
    setshow((prev) => {
      prev[index] = true;
      return [...prev];
    });

  // const handleTouched = (fieldName) => {
  //   formik.setTouched({ ...formik.touched, [fieldName]: true }, true);
  // };

  const [showLegalTextModal, setshow] = useState(
    legalTexts ? new Array(legalTexts?.length).fill(false) : [false]
  );
  // const defaultPage = useSelector(
  //   (state) => state?.navigationMenu?.navigationMenu?.defaultPage
  // );
  const {
    // isXsScreen,
    isSmScreen,
    isMdScreen,
    isLgScreen,
    isXLgScreen,
    isXxLgScreen,
  } = useCustomMediaQuery();
  const [resStatus, setresStatus] = useState({});
  const [selectedSalutation, setSelectedSalutation] = useState();
  const [selectedTitle, setSelectedTitle] = useState();
  // const newPasswordSchema = Yup.object().shape({
  //   salutation: Yup.string().required("Required!"),
  //   firstname: Yup.string()
  //     .max(60, "Max limit 60 symbols")
  //     .required("Required!"),
  //   lastname: Yup.string()
  //     .max(60, "Max limit 60 symbols")
  //     .required("Required!"),
  //   email: Yup.string()
  //     .email("Enter valid email")
  //     .max(60, "Max limit 60 symbols")
  //     .required("Required!"),
  //   // username: Yup.string()
  //   //   .matches(/^[a-zA-Z0-9]*$/, "Enter valid username")
  //   //   .required("Required!"),
  //   password: Yup.string().required(
  //     intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" })
  //   ),
  //   retypePassword: Yup.string()
  //     .required(intl.formatMessage({ id: "USERS_PASSWORD_REQUIRED" }))
  //     .when("password", {
  //       is: (val) => (val && val.length > 0 ? true : false),
  //       then: Yup.string().oneOf(
  //         [Yup.ref("newPassword")],
  //         intl.formatMessage({ id: "USERS_PASSWORD_MISMATCH" })
  //       ),
  //     }),
  // });

  const formik = useFormik({
    initialValues: {
      title: "",
      salutation: "",
      firstname: "",
      lastname: "",
      email: "",
      // username: "",
      password: "",
      retypePassword: "",
      ...checked,
    },
    validate: (values) => {
      let errors = {};
      if (!values?.salutation) {
        errors.salutation = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      }
      if (!values.email) {
        errors.email = intl.formatMessage({
          id: "VALIDATE_REQUIRED_ERROR_MESSAGE",
        });
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = intl.formatMessage({
          id: "VALIDATE_EMAIL_INVALID_ADDRESS_ERROR_MESSAGE",
        });
      }
      if (!values?.firstname) {
        errors.firstname = intl.formatMessage({
          id: "VALIDATE_FIRST_NAME_REQUIRED_ERROR_MESSAGE",
        });
      } else if (values?.firstname && values?.firstname.length < 2) {
        errors.firstname = intl.formatMessage({
          id: "VALIDATE_TOO_SHORT_ERROR_MESSAGE",
        });
      } else if (values?.firstname && values?.firstname.length > 40) {
        errors.firstname = intl.formatMessage({
          id: "USERS_PASSWORD_MISMATCH",
        });
      }
      if (!values?.lastname) {
        errors.lastname = intl.formatMessage({
          id: "VALIDATE_LAST_NAME_REQUIRED_ERROR_MESSAGE",
        });
      } else if (values?.lastname && values?.lastname.length < 2) {
        errors.lastname = intl.formatMessage({
          id: "VALIDATE_TOO_SHORT_ERROR_MESSAGE",
        });
      } else if (values?.lastname && values?.lastname.length > 40) {
        errors.lastname = intl.formatMessage({
          id: "VALIDATE_TOO_LONG_ERROR_MESSAGE",
        });
      }
      // if (!values?.username) {
      //   errors.username = "UserName is Required";
      // } else if (values?.username && values?.username.length < 2) {
      //   errors.username = "Too short";
      // } else if (values?.username && values?.username.length > 40) {
      //   errors.username = "Too Long";
      // }
      if (!values.password) {
        errors.password = "Password is required";
      } else if (values?.password && values?.password.length < 8) {
        errors.password = "Too short";
      }
      if (!values.retypePassword) {
        errors.retypePassword = "Password is required";
      } else if (values.password !== values.retypePassword) {
        errors.retypePassword = "Password must match";
      }
      if (legalTexts && legalTexts.length > 0) {
        legalTexts.forEach((ele, index) => {
          if (!values[`checked_${index}`] && ele.required === 1) {
            errors[`checked_${index}`] = "Please accept terms and conditions";
          }
        });
      }
      return errors;
    },
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const data = {
        salutation_id: values.salutation,
        title_id: values.title === 0 ? null : values.title,
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        //username: values.username,
        password: values.password,
        password_confirmation: values.retypePassword,
        ...legalTextData,
      };
      // setLoading(true)
      setSubmitting(true);
      setTimeout(() => {
        register(data)
          .then((response) => {
            if (response.status === 200) {
              setresStatus(response.data);
              setTimeout(() => {
                navigate("/");
              }, 3000);
              //setSubmitting(false)
            } else if (response.status === 422) {
              setresStatus(response.data);
            } else {
              setStatus(response.data);
            }
            // setLoading(false)
            // SuccessAlert('Password is reset successfully')
            // history.push(`/login`)
          })
          .catch((response) => {
            setresStatus(response.response.data);
            // console.log(response.response.data.message)
            // setLoading(false)
            // setSubmitting(false)
            // setStatus('The login detail is incorrect')
          })
          .finally(() => {
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <div className="">
      {legalTexts &&
        legalTexts.length > 0 &&
        legalTexts.map((ele, index) => (
          <LegalTextModal
            handleClose={handleCloseModal}
            show={showLegalTextModal[index]}
            versionId={ele?.legal_text_version_id}
            index={index}
            key={index}
          />
        ))}
      <div
        className="container"
        style={
          {
            // position: "absolute",
            // top: "54%",
            // left: "50%",
            // transform: "translate(-50%,-50%)",
          }
        }
      >
        <div
          // className=""
          className="d-flex align-items-center justify-content-evenly mt-0 mx-auto"
          style={{
            width:
              isXxLgScreen || isXLgScreen || isLgScreen
                ? "60%"
                : isMdScreen
                ? "70%"
                : isSmScreen
                ? "80%"
                : "95%",
            boxShadow:
              "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          {/* {isLgScreen || isXLgScreen || isXxLgScreen ? (
            <div class="col-lg-6 col-xl-6 py-5">
              <Player
                autoplay
                loop={true}
                src="https://assets6.lottiefiles.com/packages/lf20_jcikwtux.json"
                style={{
                  height: "500px",
                  width: "400px",
                  position: "relative",
                  top: "-10px",
                }}
              ></Player>
            </div>
          ) : null} */}
          <div className="px-md-4 px-sm-2 px-2 py-3">
            <div className="text-center mb-5">
              {/* <img
                src={generalDetails?.logo_url}
                className="img-fluid logo"
                style={{ maxHeight: "64px" }}
              /> */}
              <h4 className="">{intl.formatMessage({ id: "REGISTER" })}</h4>
              <p className="" style={{ color: "rgb(0,0,0,0.5)" }}>
                {/* {translate("USER_CHANGE_PASSWORD_MESSAGE")} */}
                {intl.formatMessage({ id: "REGISTER_MESSAGE" })}
              </p>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className=" mb-4 form-outline position-relative">
                {/* <DropDownSmall
                  name="salutation"
                  //   small={true}
                  value={formik.values.salutation}
                  handleChange={(val) =>
                    formik.setFieldValue("salutation", val)
                  }
                  errors={formik.errors}
                  handleTouched={handleTouched}
                  touched={formik.touched}
                /> */}
                <InputLabel
                  required
                  text={intl.formatMessage({
                    id: "TITLE_SALUTATION",
                  })}
                />
                <CustomDropDown
                  id="salutationDropDown"
                  hideSearch
                  // size="small"
                  items={SALUATION_OPTIONS}
                  selectedItem={selectedSalutation}
                  // @ts-ignore
                  onSelectItem={(item) => {
                    formik.setFieldValue("salutation", item.id);
                    setSelectedSalutation(item);
                  }}
                  displayValue={selectedSalutation?.name || ""}
                  // @ts-ignore
                  displayFunc={(item) => {
                    return item.name;
                  }}
                  isError={
                    formik.errors.salutation && formik.touched.salutation
                  }
                />
                {formik.errors.salutation && formik.touched.salutation && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik.errors.salutation}</span>
                  </div>
                )}
              </div>

              <div className=" mb-4 form-outline position-relative">
                {/* <TitleDropDownSmall
                  name="title"
                  //   small={true}
                  value={formik.values.title}
                  handleChange={(val) => formik.setFieldValue("title", val)}
                  errors={formik.errors}
                  handleTouched={handleTouched}
                  touched={formik.touched}
                /> */}
                <InputLabel
                  text={intl.formatMessage({
                    id: "TITLE_TITLE",
                  })}
                />

                <CustomDropDown
                  id="titleDropDown"
                  hideSearch
                  // size="small"
                  items={TITLES_OPTIONS}
                  selectedItem={selectedTitle}
                  // @ts-ignore
                  onSelectItem={(item) => {
                    formik.setFieldValue("title", item.id);
                    setSelectedTitle(item);
                  }}
                  displayValue={selectedTitle?.name || ""}
                  // @ts-ignore
                  displayFunc={(item) => {
                    return item.name;
                  }}
                  isError={formik.errors.title}
                />
                {formik.errors.title && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik.errors.title}</span>
                  </div>
                )}
              </div>

              <div className="row mb-4 form-outline position-relative">
                {/*first name input */}
                <div className="col-md-6 position-relative first-name-sm">
                  {/* <input
                    {...formik.getFieldProps("firstname")}
                    style={{ width: "100%" }}
                    id="form-input"
                    type="text"
                    name="firstname"
                    autoComplete="off"
                    //   placeholder="*******"
                    className="mb-3"
                  />
                  <label
                    placeholder={
                      JSON.stringify(locale).includes("de")
                        ? "First Name"
                        : "First Name"
                    }
                    alt={
                      JSON.stringify(locale).includes("de")
                        ? "First Name"
                        : "First Name"
                    }
                    id="form-label"
                  ></label>
                  {formik.touched.firstname && formik.errors.firstname && (
                    <span
                      role="alert "
                      className="position-absolute"
                      style={{
                        top: "55px",
                        color: "var(--error-alert)",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.firstname}
                    </span>
                  )} */}

                  <InputLabel
                    required
                    text={intl.formatMessage({ id: "LABEL_FIRST_NAME" })}
                  />

                  <TextInput
                    value={formik.values.firstname}
                    onChange={(value) => {
                      formik.setFieldValue("firstname", value);
                    }}
                    isError={
                      formik.touched.firstname && formik.errors.firstname
                    }
                  />
                  {formik.touched.firstname && formik.errors.firstname && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.firstname}</span>
                    </div>
                  )}
                </div>

                {/* last name input */}
                <div className=" col-md-6 position-relative">
                  {/* <input
                    {...formik.getFieldProps("lastname")}
                    style={{ width: "100%" }}
                    id="form-input"
                    type="text"
                    name="lastname"
                    autoComplete="off"
                    //   placeholder="*******"
                    className="mb-3"
                  />
                  <label
                    placeholder={
                      JSON.stringify(locale).includes("de")
                        ? "Last Name"
                        : "Last Name"
                    }
                    alt={
                      JSON.stringify(locale).includes("de")
                        ? "Last Name"
                        : "Last Name"
                    }
                    id="form-label"
                  ></label>
                  {formik.touched.lastname && formik.errors.lastname && (
                    <span
                      role="alert "
                      className="position-absolute"
                      style={{
                        top: "55px",
                        color: "var(--error-alert)",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.lastname}
                    </span>
                  )} */}

                  <InputLabel
                    required
                    text={intl.formatMessage({ id: "LABEL_LAST_NAME" })}
                  />

                  <TextInput
                    value={formik.values.lastname}
                    onChange={(value) => {
                      formik.setFieldValue("lastname", value);
                    }}
                    isError={formik.touched.lastname && formik.errors.lastname}
                  />
                  {formik.touched.lastname && formik.errors.lastname && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.lastname}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* username input */}
              {/* <input
                    {...formik.getFieldProps("username")}
                    style={{ width: "100%" }}
                    id="form-input"
                    type="text"
                    name="username"
                    autoComplete="off"
                    //   placeholder="*******"
                    className="mb-3"
                  />
                  <label
                    placeholder={
                      JSON.stringify(locale).includes("de")
                        ? "Username"
                        : "Username"
                    }
                    alt={
                      JSON.stringify(locale).includes("de")
                        ? "Username"
                        : "Username"
                    }
                    id="form-label"
                  ></label>
                  {formik.touched.username && formik.errors.username && (
                    <span
                      role="alert "
                      className="position-absolute"
                      style={{
                        top: "55px",
                        color: "var(--error-alert)",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.username}
                    </span>
                  )} */}
              {/* <div className="form-outline mb-4 position-relative">
                <div>
                  <InputLabel
                  required
                    text={
                      JSON.stringify(locale).includes("de")
                        ? "Username"
                        : "Username"
                    }
                  />

                  <TextInput
                    value={formik.values.username}
                    onChange={(value) => {
                      formik.setFieldValue("username", value);
                    }}
                    isError={formik.touched.username && formik.errors.username}
                  />
                  {formik.touched.username && formik.errors.username && (
                    <div style={{fontSize:'14px',color:'var(--error-alert)'}}>
                      <span role="alert">{formik.errors.username}</span>
                    </div>
                  )}
                </div>
              </div> */}

              {/* email input */}
              <div className="form-outline mb-4 position-relative">
                {/* <input
                  {...formik.getFieldProps("email")}
                  style={{ width: "100%" }}
                  id="form-input"
                  type="text"
                  name="email"
                  autoComplete="off"
                  //   placeholder="*******"
                  className="mb-3"
                />
                <label
                  placeholder={
                    JSON.stringify(locale).includes("de") ? "Email" : "Email"
                  }
                  alt={
                    JSON.stringify(locale).includes("de") ? "Email" : "Email"
                  }
                  id="form-label"
                ></label>
                {formik.touched.email && formik.errors.email && (
                  <span
                    role="alert "
                    className="position-absolute"
                    style={{
                      top: "55px",
                      color: "var(--error-alert)",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.email}
                  </span>
                )} */}
                <InputLabel
                  required
                  text={intl.formatMessage({ id: "LABEL_EMAIL" })}
                />

                <TextInput
                  value={formik.values.email}
                  onChange={(value) => {
                    formik.setFieldValue("email", value);
                  }}
                  isError={formik.touched.email && formik.errors.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik.errors.email}</span>
                  </div>
                )}
              </div>

              {/* password input */}
              <div className="form-outline mb-4 position-relative">
                {/* <input
                  {...formik.getFieldProps("password")}
                  style={{ width: "100%" }}
                  id="form-input"
                  type="password"
                  name="password"
                  autoComplete="off"
                  //   placeholder="*******"
                  className="mb-3"
                />
                <label
                  placeholder={
                    JSON.stringify(locale).includes("de")
                      ? "Password"
                      : "Password"
                  }
                  alt={
                    JSON.stringify(locale).includes("de")
                      ? "Password"
                      : "Password"
                  }
                  id="form-label"
                ></label>
                {formik.touched.password && formik.errors.password && (
                  <span
                    role="alert "
                    className="position-absolute"
                    style={{
                      top: "55px",
                      color: "var(--error-alert)",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.password}
                  </span>
                )} */}

                <InputLabel
                  required
                  text={intl.formatMessage({ id: "LABEL_PASSWORD" })}
                />

                <TextInput
                  value={formik.values.password}
                  type="password"
                  onChange={(value) => {
                    formik.setFieldValue("password", value);
                  }}
                  isError={formik.touched.password && formik.errors.password}
                />
                {formik.touched.password && formik.errors.password && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik.errors.password}</span>
                  </div>
                )}
              </div>

              {/* retype Password input */}
              <div className="form-outline mb-4 position-relative">
                {/* <input
                  {...formik.getFieldProps("retypePassword")}
                  style={{ width: "100%" }}
                  id="form-input"
                  type="password"
                  name="retypePassword"
                  autoComplete="off"
                  //   placeholder="*******"
                  className="mb-3"
                />
                <label
                  placeholder={
                    JSON.stringify(locale).includes("de")
                      ? "Confirm Password"
                      : "Confirm Password"
                  }
                  alt={
                    JSON.stringify(locale).includes("de")
                      ? "Confirm Password"
                      : "Confirm Password"
                  }
                  id="form-label"
                ></label>
                {formik.touched.retypePassword &&
                  formik.errors.retypePassword && (
                    <span
                      role="alert "
                      className="position-absolute"
                      style={{
                        top: "55px",
                        color: "var(--error-alert)",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.retypePassword}
                    </span>
                  )} */}

                <InputLabel
                  required
                  text={intl.formatMessage({ id: "LABEL_CONFIRM_PASSWORD" })}
                />

                <TextInput
                  value={formik.values.retypePassword}
                  type="password"
                  onChange={(value) => {
                    formik.setFieldValue("retypePassword", value);
                  }}
                  isError={
                    formik.touched.retypePassword &&
                    formik.errors.retypePassword
                  }
                />
                {formik.touched.retypePassword &&
                  formik.errors.retypePassword && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.retypePassword}</span>
                    </div>
                  )}
              </div>

              {/* terms and conditions */}

              <div>
                {legalTexts &&
                  legalTexts.length > 0 &&
                  legalTexts.map((ele, index) => {
                    return (
                      <>
                        <div className="mt-3">
                          <input
                            type="checkbox"
                            name={`checked_${index}`}
                            value={formik.values[`checked_${index}`]}
                            label={legalString[index]}
                            // required={!!ele?.required}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            key={index}
                          />
                          <label className="d-inline">
                            {/* <span
                            style={{ verticalAlign: "super" }}
                          ></span> */}
                            {legalString[index] &&
                            Array.isArray(legalString[index]) ? (
                              <>
                                <span style={{ verticalAlign: "super" }}>
                                  <span>
                                    {" "}
                                    {legalString[index][0].split("<p>")[1]}
                                  </span>
                                  <span>
                                    <span
                                      className="read-more-btn border-0 bg-white text-decoration-underline "
                                      onClick={() => handleShowModal(index)}
                                      style={{
                                        padding: "0px 5px",
                                        color: "var(--accent-color)",
                                      }}
                                      type="button"
                                    >
                                      {legalTexts?.[index]?.legal_text_name}
                                    </span>
                                  </span>
                                  <span>
                                    {" "}
                                    {legalString[index][1]?.split("</p>")[0]}
                                  </span>
                                </span>
                              </>
                            ) : (
                              <span style={{ verticalAlign: "super" }}>
                                {legalTexts?.[
                                  index
                                ]?.legal_text_element?.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                )}
                                <span
                                  className="read-more-btn border-0 bg-white text-decoration-underline "
                                  onClick={() => handleShowModal(index)}
                                  style={{
                                    padding: "0px 10px",
                                    color: "var(--accent-color)",
                                  }}
                                  type="button"
                                >
                                  {intl.formatMessage({
                                    id: "BUTTON_READ_MORE",
                                  })}
                                </span>
                              </span>
                            )}
                          </label>
                        </div>
                        {formik.touched[`checked_${index}`] &&
                          formik.errors &&
                          formik.errors[`checked_${index}`] && (
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "var(--font-Family)",
                                color: "var(--error-alert)",
                                position: "relative",
                                top: "-10px",
                              }}
                            >
                              {formik.errors[`checked_${index}`]}
                            </span>
                          )}
                      </>
                    );
                  })}
              </div>

              <div className="mt-4 d-flex justify-content-between align-items-center flex-column">
                <button
                  type="submit"
                  id="button-custom-form-sm"
                  className="mx-0"
                  style={{ width: "100%", borderRadius: "3px" }}
                  // disabled={formik.isSubmitting || !formik.isValid}
                >
                  {intl.formatMessage({ id: "BUTTON_SUBMIT" })}
                  {formik.isSubmitting ? (
                    <Oval
                      height={20}
                      width={15}
                      color="white"
                      wrapperStyle={{
                        display: "inline-block",
                        marginLeft: "2rem",
                        verticalAlign: "middle",
                        padding: "0px",
                      }}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="white"
                      strokeWidth={4}
                      strokeWidthSecondary={4}
                    />
                  ) : null}
                </button>
                <span className="mt-3">
                  {intl.formatMessage({ id: "HAVE_VERIFICATION_CODE" })}
                  <Link
                    to="/register/verify"
                    // className="mt-2"
                    style={{ color: "var(--accent-color)" }}
                  >
                    {` `}
                    {intl.formatMessage({ id: "SIGNUP" })}
                  </Link>
                </span>

                {user_sign_In && user_sign_In === "no" ? (
                  <Link
                    to="/"
                    className="mt-2"
                    style={{ color: "var(--accent-color)" }}
                  >
                    {intl.formatMessage({ id: "BUTTON_BACK_TO_HOME" })}
                  </Link>
                ) : (
                  <Link
                    to="/login"
                    className="mt-2"
                    style={{ color: "var(--accent-color)" }}
                  >
                    {intl.formatMessage({ id: "MESSAGE_BACK_TO_LOGIN" })}
                  </Link>
                )}
              </div>
              {resStatus && Object.values(resStatus).length > 0 ? (
                <div
                  className={`mb-40 mt-3 ${
                    resStatus.status === "Success"
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }`}
                  style={{
                    padding: "10px",
                    borderRadius: "3px",
                    textAlign: "start",
                  }}
                >
                  <div className="alert-text font-weight-bold text-center">
                    {resStatus.message}
                  </div>
                </div>
              ) : null}
            </form>
          </div>
        </div>
        <CustomFooter />
      </div>
    </div>
  );
}

export default RegistrationDefault;
