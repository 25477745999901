import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useIntl } from "react-intl";
import AdditonalFieldsForm from "./AdditonalFieldsForm";
const AdditionalFields = ({ setadditionalFields, additionalFields }) => {
  const intl = useIntl();
  const [row, setrow] = useState(1);
  const [addFieldsVisible, setaddFieldsVisible] = useState(false);
  const [values, setvalues] = useState([]);
  const [touched, setTouched] = useState([]);
  const handleAddRow = () => {
    setrow((prev) => prev + 1);
  };
  const handleAddFields = () => {
    setaddFieldsVisible((prev) => !prev);
    if (row === 0) {
      setrow(1);
    }
  };
  // const StringIsEmpty = (str) => {
  //   if (str) {
  //     return !str.replace(/\s/gm, "").length;
  //   }
  // };
  const handleRemoveRow = (index) => {
    if (row === 1) {
      setaddFieldsVisible(false);
    }
    setrow((prev) => prev - 1);
    setvalues((prev) => {
      const arr = prev.filter((ele, i) => i !== index);
      return [...arr];
    });
    setTouched((prev) => {
      const arr = prev.filter((ele, i) => i !== index);
      return [...arr];
    });
    setadditionalFields((prev) => {
      let i = 0;
      let newObj = {};
      let flag = false;
      for (let [key, val] of Object.entries(prev)) {
        if (!key?.includes(index)) {
          if (key?.includes("name")) {
            newObj[`additionals[${i}][name]`] = val;
            flag = false;
          } else {
            newObj[`additionals[${i}][value]`] = val;
            flag = true;
          }
          if (flag) {
            i++;
          }
        }
      }
      return newObj;
    });
  };
  const handleBlur = (index, type) => {
    if (touched?.[index]?.name && touched?.[index]?.name) {
      if (
        values?.[index]?.name !== undefined &&
        values?.[index]?.value !== undefined &&
        !values?.[index]?.name.isEmpty &&
        !values?.[index]?.value.isEmpty &&
        !!values?.[index]?.value.replace(/\s/g, "").length &&
        !!values?.[index]?.name.replace(/\s/g, "").length
      ) {
        setadditionalFields((prev) => {
          let i = 0;
          let newObj = {};
          if (values && values.length > 0) {
            values.forEach((ele, index) => {
              if (!ele) return;
              newObj[`additionals[${i}][name]`] = ele?.name;
              newObj[`additionals[${i}][value]`] = ele?.value;
              i++;
            });
          }
          return newObj;
        });
      }
    }
    handleError(index, type);
  };
  //console.log(touched, values);
  const handleInputChange = (value, { type, index }) => {
    if (!touched?.[index]?.[type]) {
      setTouched((prev) => {
        prev[index] = { ...prev[index], [type]: true };
        return [...prev];
      });
    }
    setvalues((prev) => {
      prev[index] = { ...prev?.[index], [type]: value };
      return [...prev];
    });
    handleError(index, type);
  };

  const handleError = (index, type) => {
    if (type === "name") {
      if (!!touched?.[index]?.value) {
        if (
          (values?.[index]?.name === "" ||
            values?.[index]?.name === undefined ||
            !values?.[index]?.name.replace(/\s/g, "").length) &&
          (values?.[index]?.value !== "" ||
            values?.[index]?.value === undefined ||
            !values?.[index]?.value.replace(/\s/g, "").length)
        ) {
          return true;
        }
      }
    } else {
      if (!!touched?.[index]?.name) {
        if (
          (values?.[index]?.name !== "" ||
            values?.[index]?.name !== undefined ||
            values?.[index]?.name.replace(/\s/g, "").length) &&
          (values?.[index]?.value === "" ||
            values?.[index]?.value === undefined ||
            !values?.[index]?.value.replace(/\s/g, "").length)
        ) {
          return true;
        }
      }
    }
  };

  //   useEffect(() => {
  //     setadditionalFields((prev) => {
  //       let i = 0;
  //       let newObj = {};
  //       if (values && values.length > 0) {
  //         values.forEach((ele, index) => {
  //           if (!ele) return;
  //           newObj[`additionals[${i}][name]`] = ele?.name;
  //           newObj[`additionals[${i}][value]`] = ele?.value;
  //           i++;
  //         });
  //       }
  //       return newObj;
  //     });
  //   }, [values]);

  return (
    <div className="mt-3">
      <span onClick={() => handleAddFields()} style={{ cursor: "pointer" }}>
        {/* {!addFieldsVisible ? ( */}
        <AiOutlinePlus
          style={{
            fill: "var(--accent-color)",
            verticalAlign: "text-bottom",
            //transform: "scale3d(1.3,1.3,1.2)",
          }}
        />
        {/* ) : (
          <AiOutlineMinusSquare
            onClick={() => handleAddFields()}
            style={{
              fill: "var(--accent-color)",
              verticalAlign: "baseline",
              transform: "scale3d(1.3,1.3,1.2)",
            }}
          />
        )} */}
        <div className="d-inline mx-1 fw-semibold">
          {intl.formatMessage({
            id: "SHARED_COMPONENTS_MODAL_ADDITIONAL_FIELDS",
          })}
        </div>
      </span>
      {!!row && addFieldsVisible
        ? new Array(row).fill(row).map((ele, index) => {
            return (
              <AdditonalFieldsForm
                key={index}
                index={index}
                values={values}
                handleBlur={handleBlur}
                handleError={handleError}
                handleRemoveRow={handleRemoveRow}
                handleInputChange={handleInputChange}
              />
            );
            //{InputRow({key:index,index:index})}
          })
        : null}
      {addFieldsVisible ? (
        <div className={`mt-3 slide`}>
          {row < 4 ? (
            <div
              type="button"
              onClick={() => handleAddRow()}
              style={{ color: "var(--accent-color)", width: "fit-content" }}
            >
              {intl.formatMessage({ id: "SHARED_COMPONENTS_MODAL_ADD" })}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default AdditionalFields;
