import React from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import error from "../../assets/error.png";
const ContactAdminModal = ({ show, setshowErrorModal }) => {
  const intl = useIntl();
  return (
    <Modal
      show={show}
      onHide={() => setshowErrorModal(false)}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <img
          src={error}
          style={{ width: "1.5rem", height: "1.5rem", marginRight: "1rem" }}
          alt=""
        />
        {intl.formatMessage({ id: "SHARED_COMPONENTS_CONTACT_ADMIN" })}
      </Modal.Header>
      {/* <Modal.Footer>
    </Modal.Footer> */}
    </Modal>
  );
};

export default ContactAdminModal;
