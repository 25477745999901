import axios from "axios";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { actions } from "../Redux/AuthReducer";
import {
  getNavigationMenu,
  setDefaultPage,
} from "../Redux/NavigationMenuSlice";
import { getTrendingPosts } from "../Redux/PostsDetailsReducer";
import DetailsPageWrapper from "../components/Communities/DetailsPageComponents/DetailsPageWrapper";
import CommunitiesWrapper from "../components/Communities/OverviewComponents/CommunitiesWrapper";
import AccountSettingsWrapper from "../components/Pages/AccountSettings/AccountSettingsWrapper";
import AllFavouritePosts from "../components/Pages/AllFavouritePosts";
import AllPosts from "../components/Pages/AllPosts";
import AllPostsRead from "../components/Pages/AllPostsRead";
import AuthorProfile from "../components/Pages/AuthorProfile";
import LegalText from "../components/Pages/LegalText";
import PageSection from "../components/Sections/PageSection";
import GeneralSearchDetails from "../components/auth/components/GenralSearchDetails";
import config from "../config";
import CustomAlert from "../sharedComponents/CustomAlerts/CustomAlert";
import DetailsPageWithSidebar from "../sharedComponents/DetailsPage/DetailsPageWithSidebar";
import DetailsPageWithoutSidebar from "../sharedComponents/DetailsPage/DetailsPageWithoutSidebar";
import EventsDetailsPageWithSidebar from "../sharedComponents/EventsDetailsPage/EventsDetailsPageWithSidebar";
import EventsDetailsPageWithoutSidebar from "../sharedComponents/EventsDetailsPage/EventsDetailsPageWithoutSidebar";
import { NewsLetterActivation } from "../sharedComponents/NewsLetter/NewsLetterActivation";
import { NewsLetterDeactivation } from "../sharedComponents/NewsLetter/NewsLetterDeactivation";
import NewsLetterForm from "../sharedComponents/NewsLetter/NewsLetterForm";
import PaymentFail from "../sharedComponents/Payments/PaymentFail";
import PaymentSuccess from "../sharedComponents/Payments/PaymentSuccess";
import SidebarEvents from "../sharedComponents/Sidebar/SidebarEvents";
import SidebarPosts from "../sharedComponents/Sidebar/SidebarPosts";
import ActivationReminderModal from "./ActivationReminder/ActivationReminderModal";
// const DetailsPage = React.lazy(() => import("../TempFiles/DetailsPage"));

// const Dashboard = React.lazy(() => import("../components/Pages/Dashboard"));

function PrivateRoutes() {
  const navigationMenu = useSelector(
    (state) => state?.navigationMenu?.navigationMenu
  );
  const [defaultPage, setdefaultPage] = useState({});
  const [openReminderModal, setOpenReminderModal] = useState(false);
  // const masterData = useSelector((state) => state?.masterData);
  const legalText = useSelector((state) => state.generalDetails.legalText);
  // const posts = useSelector((state) => state.generalDetails.posts);
  const navigate = useNavigate();
  // const generalDetails = useSelector(
  //   (state) => state.generalDetails.generalDetails
  // );
  // const user_sign_In = useSelector(
  //   (state) =>
  //     state.generalDetails.generalDetails?.general_settings?.user_sign_In
  // );
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const campaigns = useSelector((state) => state.auth.campaignDetails);
  const sysAdmin = useSelector(
    (state) => state?.auth?.user?.personalInformation?.sys_admin
  );
  const doubleOptIn = useSelector(
    (state) => state?.auth?.user?.personalInformation?.double_opt_in
  );
  const postSidebars = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.posts_general_settings
        ?.posts_details_sidebars
  );
  const eventSidebars = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.events_general_settings
        ?.events_details_sidebars
  );

  const trendingPostTypeId = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.website_settings
        ?.condition_top_posts_section
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();

  const [showAlert, setShowAlert] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  useEffect(() => {
    // if (user_sign_In && user_sign_In === "no") {
    //   sessionStorage.removeItem("accessToken");
    //   sessionStorage.removeItem("clientDetails_secret");
    //   sessionStorage.removeItem("clientDetails_id");
    // }
    // dispatch(getGeneralDetails());
    dispatch(getNavigationMenu());
    dispatch(getTrendingPosts(trendingPostTypeId || "all"));
  }, []);

  useEffect(() => {
    if (doubleOptIn === 0) {
      setTimeout(() => {
        setOpenReminderModal(true);
      }, 2000);
    } else {
      setOpenReminderModal(false);
    }
  }, [doubleOptIn]);

  useEffect(() => {
    if (navigationMenu && navigationMenu.length > 0) {
      const findDefaultPage = (menu) => {
        for (let ele of menu) {
          if (ele?.default_page === 1) {
            dispatch(setDefaultPage(ele));
            setdefaultPage(ele);
          } else if (ele?.children && ele?.children?.length > 0) {
            findDefaultPage(ele?.children);
          }
        }
      };
      findDefaultPage(navigationMenu);
      if (
        !location.pathname === "/payment/success" ||
        !location.pathname === "/payment/cancel"
      ) {
        navigate(defaultPage.name);
      }
    }
  }, [navigationMenu]);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(actions.getContactProducts());
    }
    // setOpenReminderModal(true);
  }, [isAuthorized]);

  useEffect(() => {
    const obj = {};
    axios.get(`${config.APP_URL}/campaigns/banners/final`).then((data) => {
      if (
        Object.keys(data?.data?.campaigns.contentBannerCampaign)?.length > 0
      ) {
        obj["contentBannerCampaign"] =
          data?.data?.campaigns.contentBannerCampaign;
      }
      if (
        Object.keys(data?.data?.campaigns.sidebarBannerCampaign)?.length > 0
      ) {
        obj["sidebarBannerCampaign"] =
          data?.data?.campaigns.sidebarBannerCampaign;
      }
      if (Object.keys(data?.data?.campaigns.skyScrappersCampaign)?.length > 0) {
        obj["skyScrappersCampaign"] =
          data?.data?.campaigns.skyScrappersCampaign;
      }
      dispatch(actions.setCampaignDetails(obj));
    });
  }, []);

  useEffect(() => {
    //campaigns api change added
    setInterval(() => {
      const obj = {};
      axios.get(`${config.APP_URL}/campaigns/banners/final`).then((data) => {
        if (
          Object.keys(data?.data?.campaigns.contentBannerCampaign)?.length > 0
        ) {
          obj["contentBannerCampaign"] =
            data?.data?.campaigns.contentBannerCampaign;
        }
        if (
          Object.keys(data?.data?.campaigns.sidebarBannerCampaign)?.length > 0
        ) {
          obj["sidebarBannerCampaign"] =
            data?.data?.campaigns.sidebarBannerCampaign;
        }
        if (
          Object.keys(data?.data?.campaigns.skyScrappersCampaign)?.length > 0
        ) {
          obj["skyScrappersCampaign"] =
            data?.data?.campaigns.skyScrappersCampaign;
        }
        dispatch(actions.setCampaignDetails(obj));
      });
    }, 40000);
  }, []);

  // useEffect(() => {
  //   if (detailsPageSidebar && detailsPageSidebar.length > 0) {
  //     detailsPageSidebar.forEach((ele) => {
  //       if (ele?.type === "post-list") {
  //         const paramsObject = {
  //           offset: ele.offset,
  //           option_for_featured: ele.option_for_featured,
  //           pagination: ele.pagination,
  //           items_per_page: ele.items_per_page,
  //           groups: ele.groups,
  //           groups_condition: ele.groups_condition,
  //           categories: ele.categories,
  //           categories_condition: ele.categories_condition,
  //           tags: ele.tags,
  //           tags_condition: ele.tags_condition,
  //           rule_type: ele.rule_type,
  //           type: ele.type,
  //         };
  //         if (ele.type === "post-list" || ele.type === "event-list") {
  //           dispatch(
  //             getSidebarPosts({
  //               paramsObject,
  //               id: ele.id,
  //               type: ele.type,
  //               pageType: "details",
  //               sectionId: ele.section_id,
  //               title:ele?.title
  //             })
  //           );
  //         }
  //       }
  //     });
  //   }
  // }, [detailsPageSidebar]);

  // useEffect(() => {
  //   if (window.location.pathname === "login") {
  //     console.log("this is test");
  //   }
  // }, []);

  // const PageRoute = (details) => {
  //   if (details?.children && details?.children.length > 0) {
  //     details?.children.forEach((ele) => PageRoute(ele));
  //   }
  //   return (
  //     <Route
  //       exact
  //       key={details?.id}
  //       path={`/${details?.name.toLowerCase()}`}
  //       element={
  //         <PageSection id={details?.id} pageName={details?.name} replace />
  //       }
  //     ></Route>
  //   );
  // };
  //navigationMenu?.[0]?.name?.toLowerCase()
  //id: navigationMenu?.[0]?.id
  return (
    <>
      <CustomAlert
        show={showAlert}
        closeModal={() => {
          setShowAlert(false);
        }}
        type={isSuccess ? "success" : "error"}
        message={
          isSuccess
            ? intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATE_ACCOUNT_SUCCESS_MESSAGE",
              })
            : intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PERSONAL_INFORMATION_ACTIVATE_ACCOUNT_ERROR_MESSAGE",
              })
        }
      />
      {isAuthorized &&
        sysAdmin === 0 &&
        doubleOptIn === 0 &&
        openReminderModal && (
          <ActivationReminderModal
            show={openReminderModal}
            closeModal={() => {
              setOpenReminderModal(false);
              setTimeout(() => {
                setOpenReminderModal(true);
              }, 300000);
            }}
            setIsSuccess={setIsSuccess}
            setShowAlert={setShowAlert}
          />
        )}
      <Routes>
        {defaultPage && Object.entries(defaultPage).length > 0 ? (
          <>
            <Route
              exact
              path="/"
              element={
                <Navigate
                  to={`/${defaultPage?.seo_permalink?.toLowerCase()}`}
                  state={{ id: defaultPage?.id }}
                  replace
                />
              }
            />
            <Route path="newsletter" element={<NewsLetterForm />} />
            <Route
              // exact
              path="login"
              element={
                <Navigate
                  to={`/${defaultPage?.seo_permalink}`}
                  state={{ id: defaultPage?.id }}
                />
              }
            />
          </>
        ) : null}

        {/* {navigationMenu.length>0 ? <Route exact path="/" element={<Dashboard id={navigationMenu?.[0]?.id} pageName={navigationMenu?.[0].name}/>}></Route>:null} */}
        {navigationMenu.length > 0 &&
          navigationMenu.map((ele) => {
            return (
              <Route
                exact
                key={ele.id}
                path={`/${ele.seo_permalink.toLowerCase()}`}
                element={
                  <PageSection id={ele.id} pageName={ele.name} replace />
                }
              ></Route>
            );
          })}
        <Route
          exact
          path="favourite-posts"
          element={<AllFavouritePosts />}
        ></Route>
        <Route
          exact
          path="communities"
          element={<CommunitiesWrapper />}
        ></Route>
        <Route
          exact
          path="community/:id"
          element={<DetailsPageWrapper />}
        ></Route>
        <Route exact path="posts-read" element={<AllPostsRead />}></Route>
        {navigationMenu.length > 0 &&
          navigationMenu.map((ele) => {
            // return PageRoute(ele)
            return (
              ele?.children.length > 0 &&
              ele.children.map((ele) => (
                <Route
                  exact
                  key={ele.id}
                  path={`/${ele.seo_permalink.toLowerCase()}`}
                  element={
                    <PageSection id={ele.id} pageName={ele.name} replace />
                  }
                ></Route>
              ))
            );
          })}
        {navigationMenu.length > 0 &&
          navigationMenu.map((ele) => {
            return (
              ele?.children.length > 0 &&
              ele.children.map((ele) => {
                return (
                  ele?.children &&
                  ele?.children.length > 0 &&
                  ele.children.map((ele) => (
                    <Route
                      exact
                      key={ele.id}
                      path={`/${ele.seo_permalink.toLowerCase()}`}
                      element={
                        <PageSection id={ele.id} pageName={ele.name} replace />
                      }
                    ></Route>
                  ))
                );
              })
            );
          })}
        {legalText && Object.keys(legalText).length > 0
          ? Object.values(legalText).map((ele) => {
              return (
                <Route
                  key={ele.id}
                  path={"legaltexts/" + ele?.name}
                  element={
                    <LegalText details={ele} versionId={ele?.version_id} />
                  }
                ></Route>
              );
            })
          : null}
        <Route
          path="/categories/:pageName/:id/:title/*"
          element={<AllPosts />}
        ></Route>
        <Route
          path="/groups/:pageName/:id/:title/*"
          element={<AllPosts />}
        ></Route>
        <Route
          path="/tags/:pageName/:id/:title/*"
          element={<AllPosts />}
        ></Route>
        <Route
          path="post/:id/:postTitle/*"
          element={
            postSidebars && Object.entries(postSidebars).length > 0 ? (
              <DetailsPageWithSidebar />
            ) : (
              <DetailsPageWithoutSidebar />
            )
          }
        ></Route>
        <Route path="/authors/:id" element={<AuthorProfile />}></Route>
        <Route
          path="event/:id/:eventTitle"
          element={
            eventSidebars && Object.entries(eventSidebars).length > 0 ? (
              <EventsDetailsPageWithSidebar />
            ) : (
              <EventsDetailsPageWithoutSidebar />
            )
          }
        ></Route>
        <Route path="/account/*" element={<AccountSettingsWrapper />}></Route>
        <Route
          exact
          path="payment/success"
          element={<PaymentSuccess />}
        ></Route>
        <Route exact path="payment/cancel" element={<PaymentFail />}></Route>
        <Route
          path="/newsletters/activation/:id"
          element={<NewsLetterActivation />}
        />
        <Route
          path="/newsletters/deactivation/:id"
          element={<NewsLetterDeactivation />}
        />
        <Route
          exact
          path="sidebar/posts/:id/:postTitle"
          element={<SidebarPosts />}
        ></Route>
        <Route
          exact
          path="/general-search/:id/:category/:pageid"
          element={<GeneralSearchDetails />}
        />
        <Route
          exact
          path="sidebar/events/:id/:eventTitle"
          element={<SidebarEvents />}
        ></Route>
        {navigationMenu &&
        navigationMenu.length > 0 &&
        defaultPage &&
        Object.entries(defaultPage).length > 0 ? (
          <Route path="*" element={<Navigate to={"/404"} />} />
        ) : null}
      </Routes>
    </>
  );
}

export default PrivateRoutes;
