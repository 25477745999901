import React from "react";
import { MdAudiotrack, MdFileDownload } from "react-icons/md";
import { useIntl } from "react-intl";
import { ReactComponent as PdfIcon } from "../../assets/pdf.svg";
import { downloadFile } from "../../components/auth/Redux/AuthApi";
import { bytesToSize } from "../../utils";
import { TitleHeader } from "../Title";
import { successToast } from "../Toast/Toasts";
import "./MediaContainer.scss";

const MediaContainer = ({ media }) => {
  const intl = useIntl();

  return media && media.length > 0 ? (
    <>
      <TitleHeader title={intl.formatMessage({ id: "MEDIA_TITLE" })} />
      <div className="d-flex row gy-4">
        {media.map((mediaFile) => {
          return (
            <>
              <div className="col-lg-6 col-md-6 d-flex flex-row justify-content-between mb-1">
                <div className="svg-icon svg-icon-3x d-flex flex-row align-items-center">
                  {/* <BsFillFileEarmarkPdfFill className="me-2 media-pdf-icon" /> */}
                  {mediaFile?.file_path.includes(".mp3") ? (
                    <MdAudiotrack className=" me-2 media-audio-icon" />
                  ) : (
                    <PdfIcon className="me-2 media-pdf-icon" />
                  )}

                  <div>
                    <a
                      href={mediaFile?.file_path}
                      target="_blank"
                      rel="noreferrer"
                      className="text-black"
                    >
                      <div>{mediaFile?.name}</div>
                    </a>
                    <div className="text-muted" style={{ fontSize: "12px" }}>
                      {bytesToSize(mediaFile?.size)}
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    successToast(
                      intl.formatMessage({
                        id: "OFFERS_DOWNLOADING",
                      })
                    );
                    downloadFile(mediaFile?.id).then((response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      if (mediaFile?.file_path.includes(".mp3")) {
                        link.setAttribute("download", `${mediaFile?.name}.mp3`);
                      } else {
                        link.setAttribute("download", `${mediaFile?.name}.pdf`);
                      }

                      document.body.appendChild(link);
                      link.click();
                      successToast(
                        intl.formatMessage({
                          id: "FILE_DOWNLOAD_SUCCESSFUL",
                        })
                      );
                    });
                  }}
                  className="p-0 mx-2 d-flex align-items-center pointer-cursor"
                >
                  {/* <a
                    rel="noreferrer"
                    download
                    target="_blank"
                    href={mediaFile?.file_path}
                  > */}
                  <MdFileDownload className="p-1 media-download-icon" />
                  {/* </a> */}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  ) : null;
};

export default MediaContainer;
