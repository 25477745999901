import React from "react";
import { useIntl } from "react-intl";

function SplashScreen() {
  const intl = useIntl();
  return (
    <div className="splash-screen">
      <span>
        {intl.formatMessage({ id: "ACCOUNT_SETTINGS_CONTACTS_LOADING" })}
      </span>
    </div>
  );
}

export default SplashScreen;
