import React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { convert } from "html-to-text";
import { useIntl } from "react-intl";

const DeadlinePreviewModal = ({ previewDeadline, show, closeModal }) => {
  const intl = useIntl();

  const PostDescription = () => {
    return (
      <>
        <div
          className="deadline_preview_description"
          dangerouslySetInnerHTML={{
            __html: previewDeadline?.description
              ? previewDeadline?.description
              : "",
          }}
        ></div>
      </>
    );
  };

  return (
    <div className="cam-modal-container">
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        //   contentClassName={loading ? "pe-none" : ""}
      >
        <Modal.Header closeButton onHide={closeModal}>
          <div className="d-flex flex-row flex-fill align-items-center justify-content-between">
            <Modal.Title>
              <h3 className="deadline_preview_title">{previewDeadline?.title}</h3>
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="py-4 px-lg-5" style={{ maxHeight: "500px" }}>
          <div className="d-flex ">
            <div
              className=""
              style={{
                maxHeight: "80px",
                maxWidth: "65px",
              }}
            >
              <div
                className="text-white px-4 d-flex justify-content-center"
                style={{
                  backgroundColor: "var(--accent-color)",
                  borderRadius: "4px 4px 0 0",
                }}
              >
                <span className="fw-bold">
                  {previewDeadline?.date
                    ? moment(previewDeadline?.date).format("MMM")
                    : "-"}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center border border-top-0"
                style={{
                  borderRadius: "0 0 4px 4px",
                  backgroundColor: "#f8f8f8",
                }}
              >
                <span className="fw-bold">
                  {previewDeadline?.date
                    ? moment(previewDeadline?.date).format("DD")
                    : "-"}
                </span>
                <span style={{ fontSize: "10px" }}>
                  {previewDeadline?.date
                    ? moment(previewDeadline?.date).format("YYYY")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="px-4 d-flex flex-column">
              <PostDescription />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeadlinePreviewModal;
