import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SidebarArticle from "../../sharedComponents/Article/SidebarArticle";
import TitleHeader from "../../sharedComponents/Title/TitleHeader";

const SidebarEventSection = ({
  displayHeader,
  uId,
  pageName,
  title,
  type,
  ele,
}) => {
  const postsDetails = useSelector((state) => state?.postsDetails?.[pageName]);
  const [data, setData] = useState({});
  useEffect(() => {
    if (postsDetails?.[uId]) setData(postsDetails?.[uId]?.posts?.[1]);
    //eslint-disable-next-line
  }, [uId, postsDetails?.[uId]]);
  return (
    Object.values(data).length > 0 && (
      <div className="mt-0 mb-3">
        {!!displayHeader ? (
          <TitleHeader
            title={title}
            header_navigation_id={ele?.header_navigation_id}
            noMarginTop
          />
        ) : null}

        {data && Object.values(data).length > 0
          ? Object.values(data).map((ele, index) => {
              if (index < 5) {
                return <SidebarArticle details={ele} key={index} type={type} />;
              }
            })
          : null}
        {data && Object.values(data).length > 5 ? (
          <Link
            to={
              ele.seo_permalink
                ? `../sidebar/events/${uId}/${ele.seo_permalink}`
                : `../sidebar/events/${uId}/${title.replace(/\s+/g, "-")}`
            }
            state={{ pageName: pageName, uId: uId, ele: ele }}
            className="mt-3"
            style={{ color: "var(--accent-color)" }}
          >
            Show More
          </Link>
        ) : null}
      </div>
    )
  );
};

export default SidebarEventSection;
