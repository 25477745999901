export const GET_POST_DATA_BY_ID = "GET_POST_DATA_BY_ID";
export const GET_POST_DATA_BY_ID_SUCCESS = "GET_POST_DATA_BY_ID_SUCCESS";
export const GET_POST_DATA_BY_ID_FAIL = "GET_POST_DATA_BY_ID_FAIL";

const initialState = {
  error: {},
  isLoading: true,
  posts: {},
};

export const getPostDataById = ({
  id,
  isAuthorized,
  contactId,
  hideLoader,
}) => {
  return {
    type: GET_POST_DATA_BY_ID,
    payload: { id, isAuthorized, contactId, hideLoader },
  };
};

export const getPostDataByIdSuccess = (data) => {
  return { type: GET_POST_DATA_BY_ID_SUCCESS, payload: data };
};

export const getPostDataByIdFail = (error) => {
  return { type: GET_POST_DATA_BY_ID_FAIL, payload: error };
};

const PostsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POST_DATA_BY_ID: {
      return {
        ...state,
        isLoading: action.payload.hideLoader ? false : true,
        error: {},
      };
    }
    case GET_POST_DATA_BY_ID_SUCCESS: {
      return {
        ...state,
        posts: { ...state.posts, [action.payload.id]: action.payload },
        isLoading: false,
      };
    }
    case GET_POST_DATA_BY_ID_FAIL: {
      return { ...state, error: action.payload, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default PostsReducer;
