import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import noImage from "../../assets/noLogo.png";
import "./style.scss";
const HeaderImage = () => {
  const generalDetails = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  const campaigns = useSelector((state) => state.auth.campaignDetails);
  return generalDetails?.logo_url === null &&
    generalDetails.navigation_top === 0 ? null : (
    <div className="bg-white">
      <div className="container">
        {generalDetails?.website_settings?.header_layout === "layout-1" ? (
          <div className="row">
            <div className="col">
              <figure className="py-2 text-center my-auto d-none d-lg-block">
                <Link to="/">
                  <img
                    src={
                      generalDetails?.logo_url
                        ? generalDetails?.logo_url
                        : noImage
                    }
                    className="img-fluid logo"
                    style={{
                      height:
                        generalDetails.logo_size === "large"
                          ? "120px"
                          : generalDetails.logo_size === "short"
                          ? "60px"
                          : "90px",
                      width:
                        generalDetails.logo_size === "large"
                          ? "213.3px"
                          : generalDetails.logo_size === "short"
                          ? "107px"
                          : "160px",
                    }}
                    alt=""
                  />
                </Link>
              </figure>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className=" col-sm-12 col-md-4 my-auto d-none d-lg-block ">
              <figure className="mb-0">
                <Link to="/">
                  <img
                    src={generalDetails?.logo_url}
                    style={{
                      height:
                        generalDetails.logo_size === "large"
                          ? "120px"
                          : generalDetails.logo_size === "short"
                          ? "60px"
                          : "90px",
                      width:
                        generalDetails.logo_size === "large"
                          ? "213.3px"
                          : generalDetails.logo_size === "short"
                          ? "107px"
                          : "160px",
                    }}
                  />
                </Link>
              </figure>
            </div>
            <div
              className="col-md-8 d-none d-lg-block "
              title={campaigns?.skyScrappersCampaign?.name}
              style={{ cursor: "pointer" }}
            >
              <figure className="mt-3 ">
                <div
                  onClick={() => {
                    if (campaigns?.skyScrappersCampaign?.site_url)
                      window.open(
                        campaigns?.skyScrappersCampaign?.site_url,
                        "_blank",
                        "noreferrer"
                      );
                  }}
                >
                  <img
                    src={campaigns?.skyScrappersCampaign?.image_url}
                    className="img-fluid w-100"
                  />
                </div>
              </figure>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderImage;
