import { combineReducers } from "redux";
import { CommunitiesReducer } from "./components/Communities/Redux/CommunitiesRedux";
import { AuthReducer } from "./Redux/AuthReducer";
import eventsReducer from "./Redux/EventsReducer";
import generalDetailsReducer from "./Redux/GeneralDetailsSlice";
import MasterDataReducer from "./Redux/MasterDataReducer";
import NavigationMenuReducer from "./Redux/NavigationMenuSlice";
import PageDetailsReducer from "./Redux/PageDetailsReducer";
import PaymentsReducer from "./Redux/PaymentsReducer";
import PostsDetailsReducer from "./Redux/PostsDetailsReducer";
import PostsReducer from "./Redux/PostsReducer";
const RootReducer = combineReducers({
  generalDetails: generalDetailsReducer,
  navigationMenu: NavigationMenuReducer,
  pageDetails: PageDetailsReducer,
  postsDetails: PostsDetailsReducer,
  events: eventsReducer,
  posts: PostsReducer,
  masterData: MasterDataReducer,
  auth: AuthReducer,
  payment: PaymentsReducer,
  communities: CommunitiesReducer,
});

export default RootReducer;
