import React, { useState } from "react";
import NewPassword from "./NewPassword";
import { useParams } from "react-router";
import ForgotPasswordCodeVerification from "./ForgotPasswordCodeVerification";

const ForgotPasswordWrapper = () => {
  const [codeVerified, SetCodeVerified] = useState(false);
  const { email } = useParams();
  const submitted = () => {
    SetCodeVerified(true);
  };
  return codeVerified ? (
    <NewPassword email={email} forgotPassword={true} />
  ) : (
    <ForgotPasswordCodeVerification email={email} submitted={submitted} />
  );
};

export default ForgotPasswordWrapper;
