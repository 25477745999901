import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { getPostsDetails } from "../../Redux/PostsDetailsReducer";
import CarouselArticle from "../../sharedComponents/Article/CarouselArticle";
import Title from "../Title";
import "./styles.scss";

const SidebarEvents = () => {
  const intl = useIntl();
  const location = useLocation();
  const defaultPage = useSelector(
    (state) => state?.navigationMenu?.navigationMenu?.defaultPage
  );
  const { eventTitle } = useParams();
  const [current, setCurrent] = useState(9);
  const pageName = location?.state?.pageName;
  const uId = location?.state?.uId;
  const ele = location?.state?.ele;
  const postData = useSelector(
    (state) => state?.postsDetails?.[pageName]?.[uId]?.posts?.[1]
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });
  const handleLoadMore = () => {
    setCurrent((prev) => prev + 9);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const paramsObject = {
      offset: ele?.offset,
      option_for_featured: ele?.option_for_featured,
      pagination: ele?.pagination,
      items_per_page: ele?.items_per_page,
      groups: ele?.groups,
      groups_condition: ele?.groups_condition,
      categories: ele?.categories,
      categories_condition: ele?.categories_condition,
      tags: ele?.tags,
      tags_condition: ele?.tags_condition,
      sorting: ele?.sorting,
      rule_type: ele?.rule_type,
      type: ele?.type,
    };
    if (!postData)
      dispatch(
        getPostsDetails({
          paramsObject,
          pageName,
          id: ele?.id,
          type: ele?.type,
          defaultView: ele?.default_view,
          title: "sideBar",
          sectionId: ele?.section_id,
        })
      );
  }, []);

  useEffect(() => {
    if (!postData && uId && defaultPage) {
      navigate(`/${defaultPage?.name}`);
    }
  }, []);

  return (
    <div className="container mt-2">
      <Title title={eventTitle} header_navigation_id={ele?.header_navigation_id}></Title>
      <div className="row mt-4">
        {postData && Object.values(postData).length > 0
          ? Object.values(postData)
              .slice(0, current)
              .map((element, index) => {
                return (
                  <div key={index} className="col-lg-4 col-md-6 mt-3">
                    <CarouselArticle
                      details={element}
                      ref={ref}
                      className={`col-lg-4 reveal fade-bottom ${
                        inView ? "active" : ""
                      }`}
                      sidebars={{ a: true }}
                      type="event-list"
                    />
                  </div>
                );
              })
          : null}
      </div>
      {postData && current < Object.values(postData).length && (
        <div className="d-flex align-items-center justify-content-center my-3">
          <button
            onClick={() => handleLoadMore()}
            id="button-custom"
            className="mx-auto "
            style={{ width: "fit-content", height: "fit-content" }}
          >
            {intl.formatMessage({ id: "PAGES_LOAD_MORE" })}
          </button>
        </div>
      )}
    </div>
  );
};

export default SidebarEvents;
