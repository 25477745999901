export const GET_POST_DETAILS = "GET_POST_DETAILS";
export const GET_PAGINATION_POST_DETAILS = "GET_PAGINATION_POST_DETAILS";
export const GET_POST_DETAILS_SUCCESS = "GET_POST_DETAILS_SUCCESS";
export const GET_POST_DETAILS_FAIL = "GET_POST_DETAILS_FAIL";
export const CLEAR_POST_DETAILS = "CLEAR_POST_DETAILS";
export const GET_TRENDING_POSTS = "GET_TRENDING_POSTS";
export const GET_TRENDING_POSTS_SUCCESS = "GET_TRENDING_POSTS_SUCCESS";
export const GET_TRENDING_POSTS_FAIL = "GET_TRENDING_POSTS_FAIL";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_IS_LOADING_FALSE = "SET_IS_LOADING_FALSE";
export const SET_MAIN_IS_LOADING = "SET_MAIN_IS_LOADING";

const initialState = {
  isLoading: true,
  isMainLoading: false,
  error: {
    message: "",
  },
};

export const getPostsDetails = ({
  paramsObject,
  id,
  pageName,
  type,
  title,
  defaultView,
  sectionId,
  pageId,
  isLast,
}) => {
  return {
    type: pageId ? GET_PAGINATION_POST_DETAILS : GET_POST_DETAILS,
    payload: {
      paramsObject,
      id,
      pageName,
      type,
      title,
      defaultView,
      sectionId,
      pageId,
      isLast,
    },
  };
};

export const getPostsDetailsSuccess = (details) => {
  return {
    type: GET_POST_DETAILS_SUCCESS,
    payload: details,
  };
};

export const clearPostDetails = () => {
  return {
    type: CLEAR_POST_DETAILS,
  };
};

export const getPostsDetailsFail = (error) => {
  return {
    type: GET_POST_DETAILS_FAIL,
    payload: error,
  };
};

export const setIsLoadingFalse = (data) => {
  return {
    type: SET_IS_LOADING_FALSE,
    payload: data,
  };
};

export const setMainIsLoading = (bool) => {
  // console.log("calling action", bool);
  return {
    type: SET_MAIN_IS_LOADING,
    payload: bool,
  };
};

export const getTrendingPosts = (id) => {
  return {
    type: GET_TRENDING_POSTS,
    payload: id,
  };
};

export const getTrendingPostsSuccess = (data) => {
  return {
    type: GET_TRENDING_POSTS_SUCCESS,
    payload: data,
  };
};
export const getTrendingPostsFail = (error) => {
  return {
    type: GET_TRENDING_POSTS_FAIL,
    payload: error,
  };
};

export const setCurrentPage = ({ pageId, pageName, uniqueId }) => {
  return {
    type: SET_CURRENT_PAGE,
    payload: { pageId, pageName, uniqueId },
  };
};
const PostsDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POST_DETAILS: {
      return { ...state };
    }
    case GET_PAGINATION_POST_DETAILS: {
      const { pageName, id, sectionId } = action.payload;
      const uniqueId = `${sectionId}_${id}`;
      return {
        ...state,
        // isLoading: false,
        [pageName]: {
          ...state?.[pageName],
          [uniqueId]: {
            ...state?.[pageName]?.[uniqueId],
            isLoading: true,
          },
        },
      };
    }
    case GET_POST_DETAILS_SUCCESS: {
      // const title = action.payload.title
      //   ? action.payload.title
      //   : "Default Title";
      // const defaultView = action.payload.defaultView
      //   ? action.payload.defaultView
      //   : null;
      const {
        pageName,
        eventDetails,
        id,
        sectionId,
        type,
        postDetails,
        paginationDetails,
        pageId,
      } = action.payload;
      const uniqueId = `${sectionId}_${id}`;
      // let postListData = postDetails && postDetails.reduce((acc, ele,index) => {
      //   acc[index] = ele;
      //   return acc;
      // }, {});
      // let eventListData=eventDetails && eventDetails.reduce((acc,ele,index)=>{
      //   acc[index]=ele;
      //   return acc;
      // },{});
      let pagination;
      if (Object.entries(paginationDetails).length > 0) {
        pagination = {
          ...state?.[pageName]?.[uniqueId]?.paginationDetails,
          [paginationDetails?.current_page]: paginationDetails,
          currentPage: pageId ? pageId : 1,
        };
      } else {
        pagination = paginationDetails;
      }
      return type.startsWith("post")
        ? {
            ...state,
            [pageName]: {
              ...state?.[pageName],
              [uniqueId]: {
                posts: {
                  ...state?.[pageName]?.[uniqueId]?.posts,
                  [pageId ? pageId : "1"]: postDetails,
                },
                paginationDetails: pagination,
                isLoading: action.payload.isLast ? false : state?.isLoading,
              },
            },
            // isLoading: action.payload.isLast ? false : state?.isLoading,
            isMainLoading: action.payload.isLast ? false : state?.isMainLoading,
          }
        : {
            ...state,
            [pageName]: {
              ...state?.[pageName],
              [uniqueId]: {
                posts: {
                  ...state?.[pageName]?.[uniqueId]?.posts,
                  [pageId ? pageId : "1"]: eventDetails,
                },
                paginationDetails: pagination,
              },
            },
            // isLoading: action.payload.isLast ? false : state?.isLoading,
            isMainLoading: action.payload.isLast ? false : state?.isMainLoading,
          };
    }
    case GET_POST_DETAILS_FAIL: {
      return { ...state, isLoading: false, error: { message: action.payload } };
    }
    case SET_MAIN_IS_LOADING: {
      return { ...state, isMainLoading: action.payload };
    }
    case SET_IS_LOADING_FALSE: {
      const { pageName, id, sectionId } = action.payload;
      const uniqueId = `${sectionId}_${id}`;
      return {
        ...state,
        // isLoading: false,
        [pageName]: {
          ...state?.[pageName],
          [uniqueId]: {
            ...state?.[pageName]?.[uniqueId],
            isLoading: false,
          },
        },
      };
    }
    case CLEAR_POST_DETAILS: {
      return { ...initialState };
    }
    case GET_TRENDING_POSTS: {
      return { ...state };
    }
    case GET_TRENDING_POSTS_SUCCESS: {
      return { ...state, trendingPosts: action.payload };
    }
    case GET_POST_DETAILS_FAIL: {
      return { ...state, error: { message: action.payload } };
    }
    case SET_CURRENT_PAGE: {
      const { pageId, pageName, uniqueId } = action.payload;
      return {
        ...state,
        [pageName]: {
          ...state?.[pageName],
          [uniqueId]: {
            posts: {
              ...state?.[pageName]?.[uniqueId]?.posts,
            },
            paginationDetails: {
              ...state?.[pageName]?.[uniqueId]?.paginationDetails,
              currentPage: pageId,
            },
          },
        },
        // isLoading: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default PostsDetailsReducer;
