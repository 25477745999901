import { useSelector } from "react-redux/es/hooks/useSelector";
import { Link } from "react-router-dom";
import noImage from "../../assets/noLogo.png";
import "./style.scss";
const HeaderWithoutNavbar = ({ setLocale, locale, headerTwoActive }) => {
  const generalDetails = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  return (
    <header>
      {generalDetails.navigation_top ? (
        // <TopBar
        //   generalDetails={generalDetails}
        //   setLocale={setLocale}
        //   locale={locale}
        //   headerTwoActive={headerTwoActive}
        // />
        <div></div>
      ) : (
        ""
      )}
      <div className="bg-white">
        <div className="container">
          <div className="row">
            <div className="col">
              <figure className="py-2 text-center my-auto d-block">
                <Link to="/">
                  <img
                    src={
                      generalDetails?.logo_url
                        ? generalDetails?.logo_url
                        : noImage
                    }
                    className="img-fluid logo"
                    style={{
                      height:
                        generalDetails.logo_size === "large"
                          ? "120px"
                          : generalDetails.logo_size === "short"
                          ? "60px"
                          : "90px",
                      width:
                        generalDetails.logo_size === "large"
                          ? "213.3px"
                          : generalDetails.logo_size === "short"
                          ? "107px"
                          : "160px",
                    }}
                    alt=''
                  />
                </Link>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default HeaderWithoutNavbar;
