import React from "react";

function Tag({ text, onRemove }) {
  return (
    <div
      className="d-flex align-items-center bg-secondary px-2 py-1"
      style={{ borderRadius: "10px" }}
    >
      <i
        className="la la-close text-gray-600 me-1 text-hover-primary"
        onClick={(e) => {
          onRemove?.(e);
        }}
      ></i>
      <span className="fs-7">{text}</span>
    </div>
  );
}

export default Tag;
