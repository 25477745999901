import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getCountriesList } from '../../../API/Api'
import SplashScreen from '../../Pages/SplashScreen'
import { getClientToken } from './AuthApi'
import { setCountriesList } from '../../../Redux/MasterDataReducer'

function AuthInit({children}) {

    const dispatch = useDispatch()

    const [showSplashScreen, setShowSplashScreen] = useState(true)
    useEffect(() => {
      // get client tokens API Call
    const getClientTokenAPI = () => {
        getClientToken()
          .then(({data: {clientDetails}}) => {
            // dispatch(auth.actions.setClientDetails(clientDetails))
            sessionStorage.setItem('clientDetails_id', clientDetails.id.toString())
            sessionStorage.setItem('clientDetails_secret', clientDetails.secret)
          })
          .catch((e) => {
            console.log('ERROR: getClientToken', e)
          })
          .finally(() => {
            setShowSplashScreen(false)
          })
      }
      getClientTokenAPI()
      getCountriesList().then(({data: {countries}}) => {
        dispatch(setCountriesList(countries))
      })
    }, [])
    
  return (
    showSplashScreen ? <SplashScreen /> : <>{children}</>
  )
}

export default AuthInit