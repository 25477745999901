import moment from "moment";
import React from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { useIntl } from "react-intl";
import { successToast } from "../../../sharedComponents/Toast/Toasts";

const ProductCard = ({ element }) => {
  // const date = new Date(element?.transaction_date * 1000).toLocaleString(
  //   "default"
  // );

  const intl = useIntl();
  // const date = new Date().getDate()
  return (
    <>
      <div
        className="d-xl-flex p-3 mb-3 justify-content-between position-relative"
        style={{ border: "2px solid #f3f3f3", borderRadius: "10px" }}
      >
        <div className=" position-absolute px-3 py-1 mt-3 me-3 product-type-label">
          {element?.assigned_to === "events" ? "Event" : "Post"}
        </div>
        <div>
          <div
            className="fw-bolder"
            style={{ fontSize: "20px", maxWidth: "400px" }}
          >
            <div className="mb-2" style={{ wordBreak: "break-all" }}>
              {element?.post_title
                ? element?.post_title
                : element?.events_title}
            </div>
            <div
              style={{ fontSize: "18px", wordBreak: "break-all" }}
            >{`${element?.name}`}</div>
            <div
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <span style={{ fontSize: "18px", color: "var(--accent-color)" }}>
                {`${element?.currency === "eur" ? "€" : ""}${(
                  parseInt(element?.price) / 100
                ).toFixed(2)} / `}
              </span>
              <span style={{ color: "red" }} className="ms-1">
                {` *`}
              </span>
              <span>
                {`${
                  element?.tax_behavior === "inclusive"
                    ? intl.formatMessage({
                        id: "ACCOUNT_SETTINGS_PRODUCT_CARD_INCLUSIVE_OF_ALL_TAXES",
                      })
                    : intl.formatMessage({
                        id: "ACCOUNT_SETTINGS_PRODUCT_CARD_EXCLUSIVE_OF_ALL_TAXES",
                      })
                }`}
              </span>
            </div>
          </div>
          <div className="mt-1 fw-bold" style={{ fontSize: "14px" }}>
            {/* {date} */}
            <div className=" d-inline-block" style={{ width: "100px" }}>
              {intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PRODUCT_CARD_PURCHASED_DATE",
              })}
            </div>
            :&nbsp;
            <span className="fw-normal">
              {` ${moment(element?.transaction_date * 1000).format(
                "DD.MM.yyyy"
              )}`}
            </span>
          </div>
          <div className="mt-1 fw-bold" style={{ fontSize: "14px" }}>
            <div className=" d-inline-block" style={{ width: "100px" }}>
              {intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PRODUCT_CARD_TRANSACTION_ID",
              })}
            </div>
            :&nbsp;
            <span className=" fw-normal">{` ${element?.transaction_id}`}</span>
            <MdOutlineContentCopy
              className="clipboard-icon d-inline-block"
              onClick={() => {
                navigator.clipboard.writeText(element?.transaction_id);
                successToast("copied to clickboard");
              }}
            />
          </div>
        </div>
        <div className="justify-content-xl-end mt-xl-0 mt-3 d-flex align-items-center">
          {element.invoice_pdf ? (
            <a
              download
              onClick={() => {
                successToast(
                  intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PRODUCT_CARD_DOWNLOAD_INVOICE_SUCCESS_TOAST",
                  })
                );
              }}
              href={element?.invoice_pdf}
              rel="noreferrer"
              className="btn btn-custom fw-bold cursor-pointer"
            >
              <span>
                {/* <VscCloudDownload className="product-download-icon" /> */}
              </span>
              {intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PRODUCT_CARD_DOWNLOAD_INVOICE",
              })}
            </a>
          ) : null}
          <a
            download
            href={element?.receipt_url}
            rel="noreferrer"
            className="btn btn-custom fw-bold cursor-pointer ms-2"
            target="_blank"
          >
            {/* <span>
              <VscCloudDownload className="product-download-icon" />
            </span> */}
            {intl.formatMessage({
              id: "ACCOUNT_SETTINGS_PRODUCT_CARD_VIEW_RECIEPT",
            })}
          </a>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
