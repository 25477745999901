import React from 'react'
// import {Link} from 'react-router-dom'
const CategoryList = ({name,postsCount,title,id,handleClick}) => {
  return (
    <li>
    <div className='d-flex justify-content-between' onClick={()=>handleClick(title,id,name)}>
      <span>{name}</span>
      <span>{postsCount}</span>
    </div>
   </li>
  )
}

export default CategoryList