import React, { useEffect } from "react";
import { useNavigate } from "react-router";

export const NavigationSection = ({ navigations, sidebar, index }) => {
  const navigate = useNavigate();

  const leavePage = (page) => {
    navigate(`/${page.toLowerCase()}`);
  };

  const isInViewport = () => {
    // console.log("infunc");
    // const el = document.getElementsByClassName("animated-element");
    const el = document.querySelector(`.animation-element-${index}`);
    const rect = el?.getBoundingClientRect() || {};

    if (
      rect &&
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    ) {
      // console.log("rect", rect);
      el.classList.add("in-view");
      el.classList.add("fadeInUpCard");
    }
    // return (
    //   rect.top >= 0 &&
    //   rect.left >= 0 &&
    //   rect.bottom <=
    //     (window.innerHeight || document.documentElement.clientHeight) &&
    //   rect.right <=
    //     (window.innerWidth || document.documentElement.clientWidth)
    // );
  };

  useEffect(() => {
    // console.log("indexx", index);

    window.addEventListener("scroll", () => {
      isInViewport();
    });
    //  = () => {
    //   // if (isInViewport(box)) {
    //   //   box.classList.add("in-view");
    //   //   box.classList.add("fadeInUpCard");
    //   // }

    //   isInViewport();
    // };

    isInViewport();

    return () => {
      window.removeEventListener("scroll", () => {});
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {navigations && navigations.length > 0 ? (
        <>
          <div className="mb-2">
            <div
              className={`d-flex row gx-3 gy-5 mx-auto justify-content-center  animated animatedFadeInUpCard subject animation-element-${index}`}
            >
              {navigations.map((ele) => {
                return (
                  <div
                    className={`cursor-pointer  ${
                      sidebar ? "col-lg-6 col-md-6" : "col-lg-4 col-md-4"
                    }`}
                    style={{ height: "150px" }}
                    onClick={() => {
                      leavePage(ele?.destination_permalink);
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center navigation-card border-0 card h-100">
                      <h4
                        className="navigation-card-text m-0"
                        style={{ textAlign: "center" }}
                      >
                        {ele?.data_name}
                      </h4>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
