/*
 * Simple input text component that takes placeholder text as a prop
 */

// props:
// value = input value
// onChange = input on change function
// size = size of input {Options: lg, sm, md }

import React, { useRef } from "react";
import "./TextInput.scss";

const TextInput = ({
  value,
  onChange,
  size = "lg",
  isError,
  type = "text",
  fontSize,
  placeholder,
  params,
  onBlur,
  disabled,
  id,
}) => {
  const textareaRef = useRef(null);
  // const [textValue, setTextValue] = useState(value);

  // useEffect(() => {
  //     //@ts-ignore
  //     textareaRef.current.style.height = "0px";
  //     //@ts-ignore
  //     const scrollHeight = textareaRef.current.scrollHeight;
  //     //@ts-ignore
  //     textareaRef.current.style.height = scrollHeight+ 3 + "px";
  // }, [textValue]);

  return (
    <input
      id={id}
      disabled={disabled}
      className={` form-control-custom form-control form-control-${size} ${
        isError ? "error-input" : ""
      } form-control-solid mb-lg-0 w-100`}
      autoComplete="off"
      placeholder={placeholder ? placeholder : ""}
      // style={{resize: 'none', borderRadius:'5px'}}
      style={{ border: "1px solid #919196", fontSize: fontSize }}
      ref={textareaRef}
      value={value}
      type={type}
      onChange={(e) => {
        // setTextValue(e.target.value);
        onChange?.(e.target.value, { ...params });
      }}
      onBlur={onBlur}
    />
  );
};

export default TextInput;
