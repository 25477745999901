import React from "react";
import { BsPlayCircleFill } from "react-icons/bs";
import { MdAudiotrack } from "react-icons/md";
import "./style.scss";

function PlayerIcon({ type, size }) {
  return (
    <>
      {type === "video" ? (
        <BsPlayCircleFill
          className={
            size && size === "large"
              ? "player-icon-one"
              : size && size === "listSize"
              ? "player-icon-list"
              : size && size === "sidebarlistSize"
              ? "player-icon-sidebar-list"
              : "player-icon-two"
          }
        />
      ) : (
        <MdAudiotrack
          className={
            size && size === "large"
              ? "player-icon-one"
              : size && size === "listSize"
              ? "player-icon-list"
              : "player-icon-two"
          }
        />
      )}
    </>
  );
}

export default PlayerIcon;
