import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocal } from "../../App";
import { useRef } from "react";
import { useEffect } from "react";
import { AiOutlineLeft, AiOutlinePause, AiOutlineRight } from "react-icons/ai";
import { BsFillPlayFill } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { IoMdContact } from "react-icons/io";
import { RiCalendarTodoFill } from "react-icons/ri";
import OwlCarousel from "react-owl-carousel";
import moment from "moment";
import { formatDate } from "../../utils";
import "./DeadlinesScroller.scss";
import { convert } from "html-to-text";
import DeadlinePreviewModal from "../../components/Sections/DeadlinePreviewModal";

const DeadlinesScroller = ({
  id,
  title,
  type,
  sectionId,
  uniqueId,
  key,
  pageName,
  sidebars,
  details,
}) => {
  const [filteredDeadlines, setFilteredDeadlines] = useState();
  const [previewDeadline, setPreviewDeadline] = useState();
  const [showDeadlineModal, setShowDeadlineModal] = useState(false);

  const intl = useIntl();
  const local = useLocal();
  const ref = useRef(null);

  useEffect(() => {
    const tempArr = [...details.deadlines];
    const deadlinesDisplayOption = tempArr
      ?.filter((item) => item.date !== null)
      .filter((item) => {
        if (details?.display_option === "all") {
          return item;
        } else if (details?.display_option === "past") {
          const today = new Date();
          return item.date < today;
        } else if (details?.display_option === "future") {
          const today = new Date();
          return (
            new Date(item.date) > today ||
            item.date === moment(new Date()).format("YYYY-MM-DD")
          );
        }
      });

    const arr = [...deadlinesDisplayOption];
    const filtered = arr?.filter((item) => {
      if (details?.display_variant === "year") {
        return moment(item.date).format("YYYY") === moment().format("YYYY");
      } else if (details?.display_variant === "month") {
        return moment(item.date).format("M") === moment().format("M");
      }
    });

    setFilteredDeadlines(filtered);
  }, [details]);

  const handlePause = () => {
    ref.current?.stop();
  };

  const handlePlay = () => {
    ref.current?.play();
  };
  const handlePrev = () => {
    ref.current?.prev();
  };
  const handleNext = () => {
    ref.current?.next();
  };

  return filteredDeadlines && filteredDeadlines?.length > 0 ? (
    <div className="mb-4 " key={id}>
      {showDeadlineModal && (
        <DeadlinePreviewModal
          show={showDeadlineModal}
          closeModal={() => setShowDeadlineModal(false)}
          previewDeadline={previewDeadline}
        />
      )}
      <div
        className="container"
        style={{
          padding: sidebars && sidebars.length === 0 ? "0 28px" : "0 13px",
        }}
      >
        <div
          className="row"
          style={{
            border: `1px solid ${details?.title_background_color}`,
            borderRadius: "2px",
            minHeight: "70px",
          }}
        >
          <div className="col-11 pe-0">
            <div className="row" style={{ height: "100%" }}>
              <div
                style={{
                  minHeight: "70px",
                  color: details?.title_text_color,
                  background: details?.title_background_color,
                  border: details?.title_background_color,
                }}
                className={`${
                  sidebars && sidebars.length === 0 ? "col-3" : "col-3"
                } justify-content-center scroller-title d-none d-sm-none d-md-none d-lg-flex align-items-center`}
              >
                <div className="">
                  <span className="scroller-main-title" style={{ alignSelf: "center" }}>{title}</span>
                </div>
              </div>
              <div
                className={`${
                  sidebars && sidebars.length > 0
                    ? "col-12 col-lg-10 col-md-11"
                    : "col-12 col-lg-9 col-md-11"
                } d-flex justify-content-between align-items-center`}
              >
                <OwlCarousel
                  // onChange={() => {
                  //   const active =
                  //     document.getElementsByClassName("owl-item active")[0]
                  //       .clientHeight;

                  //   console.log("active", active);
                  // }}
                  ref={ref}
                  items={1}
                  loop={true}
                  autoplay={true}
                  dots={false}
                  // onInitialized={handleInitialize}
                  autoplayHoverPause={true}
                  autoplaySpeed={5000}
                  autoplayTimeout={5000}
                  autoWidth={false}
                >
                  {filteredDeadlines &&
                    filteredDeadlines.length > 0 &&
                    filteredDeadlines.map((ele, index) => {
                      return (
                        <div
                          className="d-flex align-items-center gap-2"
                          style={{ marginRight: "5px" }}
                        >
                          {/* <div
                            style={{
                              maxHeight: "50px",
                              maxWidth: "50px",
                            }}
                          >
                            <div
                              className="text-white px-3 d-flex justify-content-center"
                              style={{
                                backgroundColor: "var(--accent-color)",
                                borderRadius: "4px 4px 0 0",
                                fontSize: "12px",
                              }}
                            >
                              <span className="fw-bold">
                                {ele?.date
                                  ? moment(ele?.date).format("MMM")
                                  : "-"}
                              </span>
                            </div>
                            <div
                              className="d-flex flex-column align-items-center border border-top-0"
                              style={{
                                borderRadius: "0 0 4px 4px",
                                backgroundColor: "#f8f8f8",
                              }}
                            >
                              <span
                                className="fw-bold"
                                style={{ fontSize: "12px" }}
                              >
                                {ele?.date
                                  ? moment(ele?.date).format("DD")
                                  : "-"}
                              </span>
                              <span style={{ fontSize: "8px" }}>
                                {ele?.date
                                  ? moment(ele?.date).format("YYYY")
                                  : "-"}
                              </span>
                            </div>
                          </div> */}
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center justify-content-start ">
                              <div className="text-truncate">
                                <span
                                  className="scroller__title cursor-pointer"
                                  style={{
                                    alignSelf: "center",
                                    color: "black",
                                  }}
                                  onClick={() => {
                                    setPreviewDeadline(ele);
                                    setShowDeadlineModal(true);
                                  }}
                                >
                                  {ele.title}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-start ">
                              {/* <div className="text-truncate">
                                <span
                                  className="scroller__title"
                                  style={{
                                    alignSelf: "center",
                                    fontSize: "14px",
                                  }}
                                >
                                  {ele?.description
                                    ? convert(ele?.description)
                                    : ""}
                                </span>
                              </div> */}
                              <div
                                style={{}}
                                className="d-flex align-items-center "
                              >
                                <li className="list-inline-item">
                                  <span
                                    className="text-dark d-flex justify-content-center align-items-center"
                                    style={{
                                      marginRight: "1rem",
                                      height: "30px",
                                    }}
                                  >
                                    <RiCalendarTodoFill className="icon-calendar" />
                                    {ele.date && local && local === "en"
                                      ? formatDate(ele.date)
                                      : moment(ele.date).format("DD.MM.yyyy")}
                                  </span>
                                </li>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </OwlCarousel>
              </div>
            </div>
          </div>
          <ActionButtons
            handlePause={handlePause}
            handlePlay={handlePlay}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        </div>
      </div>
    </div>
  ) : null;
};

const ActionButtons = ({ handlePause, handlePlay, handlePrev, handleNext }) => {
  const [pause, setPause] = useState(false);

  return (
    <div className="col-1 px-0 justify-content-end align-items-center d-flex">
      <div
        className="d-flex flex-row gap-1 px-1 h-100 align-items-center"
        style={{ background: "#f6f6f6", zIndex: "10" }}
        onMouseOver={() => {
          handlePause();
        }}
        onMouseLeave={() => {
          if (!pause) {
            handlePlay();
          }
        }}
      >
        <AiOutlineLeft
          style={{
            height: "25px",
            width: "25px",
          }}
          onClick={() => {
            handlePrev();
          }}
        />
        {pause ? (
          <BsFillPlayFill
            style={{
              height: "25px",

              width: "25px",
            }}
            onClick={() => {
              handlePlay();
              setPause(false);
            }}
          />
        ) : (
          <AiOutlinePause
            style={{
              height: "25px",

              width: "25px",
            }}
            onClick={() => {
              handlePause();
              setPause(true);
            }}
          />
        )}
        <AiOutlineRight
          style={{
            height: "25px",
            width: "25px",
          }}
          onClick={() => {
            handleNext();
          }}
        />
      </div>
    </div>
  );
};

export default DeadlinesScroller;
