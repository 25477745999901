import React from "react";
import { Link } from "react-router-dom";
const DropDownItem = ({ item, children, id, name, link, ...props }) => {
  return item?.page_type === "link" ? (
    <li className="dropdown-item" key={id}>
      <div
        className={
          props.className
            ? props.className
            : React.Children.toArray(children).length >= 1
            ? `icon-arrow arrow-align`
            : ""
        }
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (item.link_url_opening === 0) {
            window.open(item.link_url, "_self");
          } else {
            window.open(item.link_url, "_blank", "noreferrer");
          }
        }}
      >
        {name}
      </div>
      {children}
    </li>
  ) : (
    <li className="dropdown-item" key={id}>
      <Link
        className={
          props.className
            ? props.className
            : React.Children.toArray(children).length >= 1
            ? `icon-arrow arrow-align`
            : ""
        }
        to={link?.toLowerCase()}
      >
        {name}
      </Link>
      {children}
    </li>
  );
};

export default DropDownItem;
