import axios from "axios";
import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ImLocation2 } from "react-icons/im";
import { IoMdContact } from "react-icons/io";
import { RiCalendarTodoFill } from "react-icons/ri";
import { useIntl } from "react-intl";
import useGeoLocation from "react-ipgeolocation";
import { MapContainer } from "react-leaflet/MapContainer";
import { Marker } from "react-leaflet/Marker";
import { Popup } from "react-leaflet/Popup";
import { TileLayer } from "react-leaflet/TileLayer";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchLatandLong,
  fetchMicroPageEvent,
  getSubscriptioncheckoutUrl,
} from "../../API/Api";
import { useLocal } from "../../App";
import noImage from "../../assets/noImage.jpg";
import GalleryContainer from "../../sharedComponents/CardGallery";
import Loader from "../../sharedComponents/Loader";
import { formatDate, useCustomMediaQuery } from "../../utils";
import FollowUpEvents from "../FollowUpEvents/FollowUpEvents";
import CustomFooter from "../Footer/CustomFooter";
import ContactAdminModal from "../Modal/ContactAdminModal";
import EventRegisterModal from "../Modal/EventRegisterModal";
import SelectFlowModal from "../Modal/SelectFlowModal";
import { TitleHeader } from "../Title";
import { successToast } from "../Toast/Toasts";
import "./style.scss";

import { useRef } from "react";
import { EventProduct, FreeEventData } from "./EventProduct";
// import { errorToast, successToast } from "../Alerts/ToastifyAlerts";

const EventsMicroPage = () => {
  const intl = useIntl();
  const local = useLocal();
  // const dispatch = useDispatch();
  // const location = useLocation();
  const navigate = useNavigate();
  const geoLocation = useGeoLocation();
  // const largeScreen = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const smallScreen = useMediaQuery({ maxWidth: 575 });
  // const mediumScreen = useMediaQuery({ minWidth: 576, maxWidth: 767 });
  const vlgScreen = useMediaQuery({ minWidth: 992 });
  const [showFlowModal, setshowFlowModal] = useState(false);
  const [flow, setflow] = useState("products");
  const { resolution } = useCustomMediaQuery();
  const [sideBar, setsideBar] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [invalidLocation, setInvalidLocation] = useState(false);
  const [position, setPosition] = useState([51.505, -0.09]);
  // const [loading, setLoading] = useState(true);
  const [loadingMap, setLoadingMap] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [isRegistered, setIsRegistered] = useState(0);
  //const [singleLicenseProduct, setsingleLicenseProduct] = useState({});
  const [SoldOut, setSoldOut] = useState(false);
  const [showProductModal, setshowProductModal] = useState(false);
  const [PaidEvent, setPaidEvent] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [arrivingFlag, setArrivingFlag] = useState(false);
  //const [disabled, setdisabled] = useState(false);
  const handleShow = (func) => func(true);

  // const user = useSelector((state) => state.auth?.user);
  const today = moment().toDate();
  const [mainLoading, setMainLoading] = useState(true);
  const [event, setEvent] = useState();
  const productRef = useRef(null);
  const handleButtonClick = () =>
    window.scroll(0, productRef.current.offsetTop);

  //   const events = useSelector((state) => state.events.events);
  // const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  // const productsBought = useSelector((state) => state.auth?.products);
  // const sysAdmin = useSelector(
  //   (state) => state.auth?.user?.personalInformation?.sys_admin
  // );
  const freeLegalTexts = useSelector(
    (state) =>
      state.generalDetails.generalDetails.events_general_settings
        .free_events_registrations_legal_texts
  );
  const isLoading = useSelector((state) => state?.events?.isLoading);
  const { eventUid } = useParams();
  sessionStorage.setItem("EventsMicroPage", true);
  sessionStorage.setItem("pathName", window.location.pathname);
  useEffect(() => {
    setMainLoading(true);
    if (!eventUid) {
      navigate("/404");
    }
    fetchMicroPageEvent(eventUid)
      .then(({ data: { eventDetails } }) => {
        setEvent(eventDetails);
        setMainLoading(false);
      })
      .catch(() => {
        navigate("/404");
      })
      .finally(() => {});
    // checkUserRegistrationAPI();
  }, []);
  useEffect(() => {
    if (event) {
      if (event?.registration_end_date) {
        const bool = moment(today).isBefore(event?.registration_end_date);
        setSoldOut(!bool);
      }
      if (event?.registration_start_date) {
        const bool = moment(today).isBefore(event?.registration_start_date);
        setArrivingFlag(bool);
      }
      if (moment(today).isAfter(event?.start_date)) {
        setSoldOut(true);
      }
      if (event?.event_mode_id === 2 || event?.event_mode_id === 4) {
        setPaidEvent(true);
      }
    }
  }, [event]);

  const hideProducts = () => {
    if (event?.capacity_remaining === 0) {
      return true;
    } else if (isRegistered === 1) {
      return true;
    } else if (moment(today).isAfter(event?.start_date)) {
      return true;
    } else if (
      event?.event_access_id === 1 &&
      event?.event_mode_id === 1 &&
      event?.event_type_id === 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleRegistationDisable = () => {
    if (event?.capacity_remaining === 0) {
      return true;
    } else if (
      (event?.event_mode_id === 2 || event?.event_mode_id === 4) &&
      event?.products.length === 0 &&
      event?.subscriptions.length === 0
    ) {
      return true;
      // } else if (
      //   (event?.event_mode_id === 2 || event?.event_mode_id === 4) &&
      //   event?.products.length !== 0 &&
      //   event?.products.filter((ele) => ele?.type === "single_event").length === 0
      // ) {
      //   return true;
      // }
    } else if (event?.registration_end_date) {
      if (event?.registration_start_date) {
        const bool = moment(today).isBetween(
          event?.registration_start_date,

          event?.registration_end_date
        );

        return !bool;
      } else {
        const bool = moment(today).isBefore(event?.registration_end_date);

        return !bool;
      }
    } else if (event?.registration_start_date) {
      const bool = moment(today).isAfter(event?.registration_start_date);

      return !bool;
    }
  };

  // const checkUserRegistrationAPI = () => {
  //   setLoading(true);
  //   checkUserEventRegister(event?.id, user?.personalInformation?.id)
  //     .then(({ data: { eventDetails } }) => {
  //       setIsRegistered(eventDetails?.is_registered);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };
  // useEffect(() => {
  //   if (event?.products && event?.products.length > 0) {
  //     const singleLicenseProduct = event?.products.filter(
  //       (ele) => ele?.type === "single_event"
  //     );
  //     if (singleLicenseProduct && singleLicenseProduct.length > 0) {
  //       setsingleLicenseProduct(singleLicenseProduct[0]);
  //     }
  //   }
  // }, [event]);

  const calculateDaysOrHours = () => {
    if (moment(today).diff(event?.registration_start_date, "days") < 0) {
      return `${intl.formatMessage({
        id: "SHARED_COMPONENTS_EVENTS_BOOKING_OPENS_IN",
      })} ${moment(event?.registration_start_date).diff(
        today,
        "days"
      )} ${intl.formatMessage({ id: "SHARED_COMPONENTS_EVENTS_DAYS" })}`;
    } else {
      // let duration = moment.duration(events?.[eventId]?.start_date)
      return `${intl.formatMessage({
        id: "SHARED_COMPONENTS_EVENTS_BOOKING_OPENS_IN",
      })} ${moment(event?.registration_start_date).diff(
        today,
        "hours"
      )} ${intl.formatMessage({ id: "SHARED_COMPONENTS_EVENTS_HOURS" })}`;
    }
  };

  const eventRegistrationAPI = (paramsObj) => {
    // setLoading(true)
    const paramsObject = {
      contact_id: null,
      salutation_id: paramsObj?.salutation_id || null,
      firstname: paramsObj?.firstname || null,
      lastname: paramsObj?.lastname || null,
      email: paramsObj?.email || null,
      product_id: paramsObj?.product_id || null,
      country_code: geoLocation?.country,
      ...paramsObj,
    };
    return new Promise((resolve, reject) => {
      if (flow === "subscription") {
        getSubscriptioncheckoutUrl(event.id, paramsObject).then((response) => {
          //setregistrationLoading(false);
          if (response.status == 200) {
            if (
              (event?.event_mode_id === 2 || event?.event_mode_id === 4) &&
              response?.data?.stripeProductDetails?.url
            ) {
              window.location.href = response?.data?.stripeProductDetails?.url;
            } else {
              successToast(
                intl.formatMessage({
                  id: "SHARED_COMPONENTS_EVENTS_SUCCESS_TOAST",
                })
              );
            }
            resolve("success");
          } else {
            reject(
              intl.formatMessage({
                id: "SHARED_COMPONENTS_EVENTS_CANT_REGISTER",
              })
            );
          }
        });
      } else {
        try {
          axios({
            method: "post",
            url:
              flow === "products"
                ? `/events/register/${event.id}`
                : `/events/register/offers/${event.id}`,
            data: paramsObject,
            // headers: { "Content-Type": "multipart/form-data" },
            responseType: flow !== "products" ? "blob" : "json",
          })
            .then((response) => {
              const {
                data: { stripeProductDetails = {} },
              } = response;
              // setIsRegistered(eventDetails?.is_registered)
              if (flow !== "products") {
                successToast(
                  intl.formatMessage({ id: "OFFER_INVOICE_DOWNLOAD_MESSAGE" })
                );
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.pdf");
                document.body.appendChild(link);
                link.click();
              } else {
                if (
                  (event?.event_mode_id === 2 || event?.event_mode_id === 4) &&
                  stripeProductDetails?.url
                ) {
                  window.location.href = stripeProductDetails?.url;
                } else {
                  successToast(
                    successToast(
                      intl.formatMessage({
                        id: "SHARED_COMPONENTS_EVENTS_SUCCESS_TOAST",
                      })
                    )
                  );
                  setShowModal(false);
                }
              }
              resolve(
                intl.formatMessage({ id: "SHARED_COMPONENTS_EVENTS_SUCCESS" })
              );
            })
            .catch((error) => {
              const {
                response: {
                  data: { message, status },
                },
              } = error;
              if (flow !== "products") {
                reject(
                  intl.formatMessage({
                    id: "SHARED_COMPONENTS_EVENTS_CANT_REGISTER",
                  })
                );
              }
              if (status === "Error") {
                reject(
                  message
                    ? message
                    : intl.formatMessage({
                        id: "SHARED_COMPONENTS_EVENTS_CANT_REGISTER",
                      })
                );
              } else if (status === "Warning") {
                reject(
                  message
                    ? message
                    : intl.formatMessage({
                        id: "SHARED_COMPONENTS_EVENTS_USER_ALREADY_REGISTERED",
                      })
                );
              }
            })
            .finally(() => {
              // setLoading(false)
              // checkUserRegistrationAPI();
            });
        } catch (error) {
          throw error;
        }
      }
    });
  };

  //   useEffect(() => {
  //     if (!Object.keys(events).includes(eventId)) {
  //       dispatch(geteventDataById(eventId));
  //     }
  //   }, [eventId]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  const setPostMediaSource = () => {
    if (
      event?.event_image_resolutions &&
      event?.event_image_resolutions.length > 0
    ) {
      const source = event?.event_image_resolutions.filter(
        (element) => element?.resolution === resolution
      );
      return source[0].file_url;
    }
    return event?.event_image_url ? event?.event_image_url : noImage;
  };

  const setContactMediaSource = (event) => {
    if (
      event?.contact_person_image_resolutions &&
      event?.contact_person_image_resolutions.length > 0
    ) {
      const source = event?.contact_person_image_resolutions.filter(
        (element) => element?.resolution === "256x144"
      );
      return source[0].file_url;
    }
    return event?.contact_person_image_url
      ? event?.contact_person_image_url
      : noImage;
  };

  const formatAddress = () => {
    let address_1 = event?.address_1 === null ? "" : `${event?.address_1} `;
    let address_2 = event?.address_2 === null ? "" : `${event?.address_2} `;
    let city = event?.city === null ? "" : `${event?.city} `;
    let country_name =
      event?.county_name === null ? "" : `${event?.county_name} `;
    let zip_code = event?.zip_code === null ? "" : `${event?.zip_code} `;

    // if((address_1 + address_2 + city + country_name + zip_code) === ''){
    //     setShowMap(false)
    // }

    return address_1 + address_2 + city + zip_code + country_name;
  };

  const getLatandLong = () => {
    setLoadingMap(true);
    const address = formatAddress();

    //this condition is given to mitigate initial render before
    //event details are updated
    if (address === "undefined undefined undefined undefined undefined") {
      return;
    }

    fetchLatandLong(address)
      .then(({ lat, lon }) => {
        setPosition([Number(lat), Number(lon)]);
        if (event?.address_1 === null && event?.address_2 === null) {
          setInvalidLocation(true);
        }
      })
      .catch(() => {
        setInvalidLocation(true);
      })
      .finally(() => {
        // console.log(position);
        setLoadingMap(false);
        // setMapLoading(false);
        if (address !== "") {
          setShowMap(true);
        }
      });
  };

  const contactDetails = () => {
    if (
      event?.contact_person_name === null &&
      event?.contact_person_email === null &&
      event?.contact_person_phone === null
    ) {
      return false;
    }
    return true;
  };

  const addressDetails = () => {
    if (
      event?.address_1 === null &&
      event?.address_2 === null &&
      event?.zip_code === null &&
      event?.event_type_id !== 1 &&
      event?.county_name === null
    ) {
      return false;
    }
    return true;
  };

  // const hostDetails = () => {
  //   if (
  //     event?.partner_details?.host_name === null &&
  //     event?.partner_details?.host_email === null
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  const PostDescription = () => {
    return (
      <>
        <div
          className="event-description-one"
          dangerouslySetInnerHTML={{ __html: event?.description }}
        >
          {/* {formatString(posts?.[eventId]?.description)} */}
        </div>

        {event?.galleries?.length > 0 ? (
          <div>
            <GalleryContainer sideBar={sideBar} galleries={event?.galleries} />
          </div>
        ) : null}
      </>
    );
  };
  return (
    <>
      <Helmet>
        <meta name="description" content={event?.seo_description} />
        <meta property="title" name="title" content={event?.seo_permalink} />
        <meta property="tag" name="tag" content={event?.seo_tag} />
      </Helmet>
      <div className="pb-4">
        {mainLoading === true ? (
          <Loader />
        ) : (
          <>
            <SelectFlowModal
              show={showFlowModal}
              setflow={setflow}
              // setshowProductModal={setshowProductModal}
              setEventRegsiterModal={setShowModal}
              closeModal={() => setshowFlowModal(false)}
              showEventRegisterModal={showModal}
              //showProductModal={showProductModal}
            />
            {/* <EventProductsModal
              show={showProductModal}
              products={event?.products}
              closeModal={() => setshowProductModal(false)}
              setShowEventRegisterModal={setShowModal}
              setProductDetails={setProductDetails}
              remainingCapacity={event?.capacity_remaining}
              showEventRegisterModal={showModal}
              setshowFlowModal={setshowFlowModal}
              showFlowModal={showFlowModal}
            /> */}

            {showModal && (
              <EventRegisterModal
                show={showModal}
                legalTexts={
                  event?.event_mode_id === 1 || event?.event_mode_id === 3
                    ? freeLegalTexts
                    : productDetails?.legal_texts
                }
                eventId={event.id}
                productId={productDetails?.id}
                setshowErrorModal={setshowErrorModal}
                closeModal={() => {
                  setShowModal(false);
                  setshowFlowModal(false);
                }}
                eventRegistrationAPI={eventRegistrationAPI}
                productDetails={productDetails}
                flow={flow}
              />
            )}

            {showErrorModal && (
              <ContactAdminModal
                setshowErrorModal={setshowErrorModal}
                show={showErrorModal}
              />
            )}
            <div className="container">
              <div className="row">
                <div className={sideBar ? `col-lg-8` : "col-lg-12"}>
                  <div className="mt-5 mb-4 title_container">
                    <h1 style={{ textAlign: "start" }}>{event?.title}</h1>
                    <div
                      style={{ fontSize: "18px" }}
                      dangerouslySetInnerHTML={{
                        __html: event?.sub_title,
                      }}
                    >
                      {/* {formatString(posts?.[eventId]?.introduction)} */}
                    </div>
                  </div>
                  {/* <hr
                  className="my-3"
                  style={{
                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                    maxWidth: "750px",
                    margin: "auto",
                  }}
                ></hr> */}
                  {/* <ul
                  className="list-inline d-flex justify-content-start align-items-center"
                  //   className={`${
                  //     posts?.[eventId] &&
                  //     posts?.[eventId].products &&
                  //     Object.keys(posts?.[eventId].products).length > 0
                  //       ? "buyPage list-inline d-flex justify-content-between align-items-center"
                  //       : "list-inline d-flex justify-content-between align-items-center"
                  //   }`}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: "750px",
                  }}
                >
                  <li
                    className="list-inline-item"
                    style={{ marginLeft: "10px" }}
                  >
                    <Author authors={event?.authors} />
                  </li>
                  <li
                    className="list-inline-item"
                    style={{ verticalAlign: "top" }}
                  >
                    <span
                      className="text-dark text-capitalize fw-semibold d-flex justify-content-center align-items-center"
                      style={{ fontSize: "14px" }}
                    >
                      <RiCalendarTodoFill className="icon-calendar" />
                      {local && local === "en"
                        ? formatDate(event?.start_date)
                        : moment(event?.start_date).format(
                            "DD.MM.yyyy"
                          )}
                    </span>
                  </li>
                </ul> */}
                  {/* <div> */}
                  <div
                    className="row event_host_details_sidebar event-page-alignment mx-auto"
                    style={{
                      marginTop: "30px",
                      flex: "1",
                      height: "fit-content",
                    }}
                  >
                    <div
                      className="d-flex justify-content-md-start"
                      style={{ flex: "1" }}
                    >
                      <fieldset className="border p-2 w-100 ">
                        <legend
                          className="float-none w-auto px-2  text-muted"
                          style={{ fontSize: "1.2rem" }}
                        >
                          {intl.formatMessage({
                            id: "EVENT_DETAILS_FIELDSET_LEGEND_TITLE",
                          })}
                        </legend>
                        <div
                          className="d-flex flex-column gap-3"
                          style={{ fontSize: "1rem" }}
                        >
                          <div className="d-flex flex-row">
                            <div>
                              <span>
                                <RiCalendarTodoFill className="event-icons" />
                              </span>
                            </div>

                            <div className="d-flex flex-column">
                              <div className="d-flex align-items-center gap-2">
                                <div>
                                  {local && local === "en"
                                    ? formatDate(event?.start_date)
                                    : moment(event?.start_date).format(
                                        "DD.MM.yyyy HH:mm"
                                      )}
                                </div>
                                <div>-</div>
                                <div>
                                  {local && local === "en"
                                    ? formatDate(event?.end_date)
                                    : moment(event?.end_date).format(
                                        "DD.MM.yyyy HH:mm"
                                      )}
                                </div>
                              </div>
                              <div
                                className=""
                                style={{
                                  fontSize: "0.8rem",
                                  color: "red",
                                  lineHeight: "1.2rem",
                                }}
                              >
                                {event?.registration_start_date ||
                                event?.registration_end_date
                                  ? intl.formatMessage({
                                      id: "SHARED_COMPONENTS_EVENTS_REGISTRATION",
                                    })
                                  : ""}
                                {`${
                                  event?.registration_start_date
                                    ? `${intl.formatMessage({
                                        id: "SHARED_COMPONENTS_EVENTS_STARTS_FROM",
                                      })} ${
                                        local && local === "en"
                                          ? formatDate(
                                              event?.registration_start_date
                                            )
                                          : moment(
                                              event?.registration_start_date
                                            ).format("DD.MM.yyyy HH:mm")
                                      } `
                                    : ""
                                }${
                                  event?.registration_end_date
                                    ? `${intl.formatMessage({
                                        id: "SHARED_COMPONENTS_EVENTS_TILL",
                                      })} ${
                                        local && local === "en"
                                          ? formatDate(
                                              event?.registration_end_date
                                            )
                                          : moment(
                                              event?.registration_end_date
                                            ).format("DD.MM.yyyy HH:mm")
                                      }`
                                    : ""
                                }`}
                              </div>
                            </div>
                          </div>

                          {addressDetails() === true ? (
                            <>
                              <div className=" d-none">
                                <hr
                                  style={{
                                    border: "0",
                                    width: "1px",
                                    height: "75px",
                                    color: "var(--accent-color)",
                                  }}
                                />
                              </div>
                              <div className="d-flex pe-md-5 location-top">
                                <span>
                                  <ImLocation2 className="event-icons" />
                                </span>
                                {event?.event_type_id === 1 ? (
                                  <div>
                                    <div
                                      style={{
                                        fontSize: "1rem",
                                      }}
                                    >
                                      {intl.formatMessage({
                                        id: "EVENTS_ADDRESS_ONLINE_DETAILS",
                                      })}
                                    </div>
                                    <a
                                      href={event?.meeting_url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div style={{ fontSize: "1rem" }}>
                                        {event?.meeting_url_name}
                                      </div>
                                    </a>
                                    {event?.meeting_url_code ? (
                                      <div>Code: {event?.meeting_url_code}</div>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="d-flex flex-column flex-wrap">
                                    {/* <h5 style={{ fontSize: "1.3rem" }}>Location</h5> */}
                                    <div
                                      className="mb-0"
                                      style={{ fontSize: "1rem" }}
                                    >{`${event?.address_1} ${
                                      event?.address_2 ? event?.address_2 : ""
                                    }`}</div>
                                    <div className="d-flex flex-wrap">
                                      <div
                                        className="mb-0"
                                        style={{ fontSize: "1rem" }}
                                      >
                                        {`${
                                          event?.zip_code
                                            ? event?.zip_code + ","
                                            : ""
                                        } ${
                                          event?.city ? event?.city + "," : ""
                                        }`}
                                      </div>
                                      <div
                                        className="mb-0"
                                        style={{ fontSize: "1rem" }}
                                      >{`${
                                        event?.county_name
                                          ? event?.county_name
                                          : ""
                                      }`}</div>
                                    </div>
                                    <button
                                      style={{ textAlign: "left" }}
                                      className="map-toggle-button"
                                      onClick={() => {
                                        showMap === true
                                          ? setShowMap(false)
                                          : getLatandLong();
                                      }}
                                    >
                                      {showMap
                                        ? intl.formatMessage({
                                            id: "SHARED_COMPONENTS_EVENTS_HIDE_MAP",
                                          })
                                        : intl.formatMessage({
                                            id: "SHARED_COMPONENTS_EVENTS_SHOW_MAP",
                                          })}
                                      {/* Show map */}
                                    </button>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="text-secondary d-flex">
                              <span>
                                <ImLocation2 className="event-icons" />
                              </span>
                              <p>
                                {intl.formatMessage({
                                  id: "EVENTS_ADDRESS_LOCATION_DETAILS",
                                })}
                              </p>
                            </div>
                          )}
                        </div>
                      </fieldset>
                    </div>
                    {contactDetails() === true ? (
                      <div
                        className={`d-flex justify-content-md-start`}
                        style={{ flex: "1", fontSize: "1rem" }}
                      >
                        <fieldset className="border p-2 w-100">
                          <legend
                            className="float-none w-auto px-2  text-muted"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {intl.formatMessage({
                              id: "EVENT_HOST_DETAILS_FIELDSET_LEGEND_TITLE",
                            })}
                          </legend>
                          <div className="">
                            <div className="d-flex flex-row">
                              {event?.contact_person_image_url ? (
                                <img
                                  style={{
                                    width: "auto",
                                    height: "30px",
                                    // borderRadius: "50%",
                                    marginBottom: "6px",
                                  }}
                                  className="mx-1 "
                                  src={event?.contact_person_image_url}
                                />
                              ) : (
                                <IoMdContact className="event-icons" />
                              )}
                              <div className="ms-1">
                                <div>
                                  {event?.contact_person_name === null
                                    ? ""
                                    : `${event?.contact_person_name}`}
                                </div>
                                <div>
                                  {event?.contact_person_email === null
                                    ? ""
                                    : `${event?.contact_person_email}`}
                                </div>
                                <div>
                                  {event?.contact_person_phone === null
                                    ? ""
                                    : `${event?.contact_person_phone}`}
                                </div>
                                <div
                                  style={{
                                    width: !smallScreen ? "max-content" : "",
                                  }}
                                >
                                  {event?.contact_person_remarks === null
                                    ? ""
                                    : `${event?.contact_person_remarks}`}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={`row event-page-alignment mx-auto position-relative ${
                      loadingMap ? "map-loading-height w-100" : ""
                    }`}
                  >
                    {loadingMap ? <Loader map={true} /> : null}
                    {showMap && invalidLocation ? (
                      <div className="map-message-wrapper">
                        <div className="map-message">
                          {intl.formatMessage({
                            id: "SHARED_COMPONENTS_EVENTS_MAP_ERROR_MESSAGE",
                          })}
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={`${
                        invalidLocation && showMap ? "map-blur-effect" : ""
                      } ${showMap ? "mt-3" : ""}`}
                    >
                      {showMap === true ? (
                        <MapContainer
                          center={position}
                          zoom={16}
                          scrollWheelZoom={false}
                        >
                          <TileLayer
                            attribution=""
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker
                            position={position}
                            icon={
                              new Icon({
                                iconUrl: markerIconPng,
                                iconSize: [25, 41],
                                iconAnchor: [12, 41],
                              })
                            }
                          >
                            <Popup>{event?.title}</Popup>
                          </Marker>
                        </MapContainer>
                      ) : null}
                    </div>
                  </div>

                  <div
                    style={{
                      margin: "auto",
                      width: vlgScreen ? "90%" : "100%",
                      marginTop: "30px",
                    }}
                  >
                    <img
                      className="post-image rounded"
                      src={setPostMediaSource()}
                    />
                    <p style={{ fontSize: "14px" }}>
                      {event?.event_image_copyright_text}
                    </p>
                  </div>
                  {/* </div> */}

                  {event?.event_access_id === 1 &&
                  event?.event_mode_id === 1 &&
                  event?.event_type_id === 1 ? null : (
                    <div
                      className="row event-page-alignment mx-auto justify-content-center"
                      style={{ marginTop: "30px" }}
                    >
                      <button
                        className="btn btn-custom"
                        style={{ maxWidth: "250px", fontSize: "20px" }}
                        onClick={() => {
                          if (event?.state === "External") {
                            window.open(event?.state_url, "_blank");
                          } else {
                            handleButtonClick();
                          }
                        }}
                        disabled={handleRegistationDisable()}
                      >
                        {event?.capacity_remaining === 0 || SoldOut
                          ? intl.formatMessage({ id: "TEXT_SOLD_OUT" })
                          : arrivingFlag
                          ? calculateDaysOrHours()
                          : intl.formatMessage({ id: "BUTTON_REGISTER_NOW" })}
                      </button>
                    </div>
                  )}

                  <PostDescription />

                  {/* {
                    event?.state === 'External' ? null :  */}
                  {hideProducts() ? null : (
                    <div
                      ref={productRef}
                      style={{
                        //  marginTop: "30px",
                        maxWidth: "750px",
                      }}
                      className="row event-page-alignment mx-auto"
                    >
                      {event?.products?.length > 0 ||
                      event?.event_mode_id === 1 ||
                      event?.event_mode_id === 3 ? (
                        <>
                          <TitleHeader
                            title={intl.formatMessage({
                              id: "SHARED_COMPONENTS_BUY_PRODUCT",
                            })}
                            header_navigation_id={null}
                            capacity={
                              event?.event_access_id === 1 &&
                              event?.event_type_id === 1
                                ? null
                                : event?.maximum_capacity
                            }
                          />
                          {event?.event_mode_id === 2 ||
                          event?.event_mode_id === 4 ? (
                            <EventProduct
                              products={event?.products}
                              setShowEventRegisterModal={setShowModal}
                              setProductDetails={setProductDetails}
                              showEventRegisterModal={showModal}
                              remainingCapacity={event?.capacity_remaining}
                              freeEvent={false}
                              handleRegisterClick={() => {
                                PaidEvent && event?.products.length > 0
                                  ? handleShow(setshowProductModal)
                                  : handleShow(setShowModal);
                              }}
                              handleRegistationDisable={
                                handleRegistationDisable
                              }
                              setshowFlowModal={setshowFlowModal}
                            />
                          ) : (
                            <EventProduct
                              products={[FreeEventData]}
                              setShowEventRegisterModal={setShowModal}
                              setProductDetails={setProductDetails}
                              showEventRegisterModal={showModal}
                              remainingCapacity={event?.capacity_remaining}
                              freeEvent={true}
                              handleRegisterClick={() => {
                                PaidEvent && event?.products.length > 0
                                  ? handleShow(setshowProductModal)
                                  : handleShow(setShowModal);
                              }}
                              handleRegistationDisable={
                                handleRegistationDisable
                              }
                              setshowFlowModal={setshowFlowModal}
                              subscription={false}
                            />
                          )}
                        </>
                      ) : null}
                      {event?.subscriptions &&
                      event?.subscriptions.length > 0 ? (
                        <>
                          <TitleHeader
                            title={intl.formatMessage({
                              id: "SHARED_COMPONENTS_BUY_SUBSCRIPTION",
                            })}
                            header_navigation_id={null}
                          />

                          <EventProduct
                            products={event?.subscriptions}
                            setShowEventRegisterModal={setShowModal}
                            setProductDetails={setProductDetails}
                            showEventRegisterModal={showModal}
                            remainingCapacity={event?.capacity_remaining}
                            freeEvent={
                              event?.event_mode_id === 1 ||
                              event?.event_mode_id === 3
                            }
                            handleRegisterClick={() => {
                              PaidEvent && event?.products.length > 0
                                ? handleShow(setshowProductModal)
                                : handleShow(setShowModal);
                            }}
                            handleRegistationDisable={handleRegistationDisable}
                            subscription={true}
                            setflow={setflow}
                          />
                        </>
                      ) : null}
                    </div>
                  )}
                  {/* } */}

                  {event?.follow_up_events?.length > 0 ? (
                    <div
                    // style={{ marginTop: "30px" }}
                    >
                      <FollowUpEvents
                        Id={event.id}
                        sideBar={sideBar}
                        followUpEvents={event?.follow_up_events}
                        noLink={true}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div
              // style={{ marginTop: "30px" }}
              >
                <CustomFooter />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EventsMicroPage;
