import axios from "axios";

export async function codeVerification(email, code, forgotPassword = false) {
  const data = { email, code };
  // if (!forgotPassword) {
  //   data.technology = "app";
  // }
  try {
    const response = await axios({
      method: "post",
      url:
        process.env.REACT_APP_API_URL +
        `${
          !forgotPassword
            ? "/contact/register/verify/code"
            : "/forgotPassword/code"
        }`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
///contact/register/verify/activate
export async function newPassword(
  email,
  password,
  password_confirmation,
  forgotPassword
) {
  const data = { email, password, password_confirmation };
  if (!forgotPassword) {
    data.technology = "app";
  }
  try {
    const response = await axios({
      method: "post",
      url:
        process.env.REACT_APP_API_URL +
        `${
          !!forgotPassword
            ? "/forgotPassword/reset"
            : "/contact/register/verify/activate"
        }`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function login({
  client_id,
  client_secret,
  email,
  password,
  ip,
  browser_agent,
}) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + "/contact/login",
    data: {
      client_id,
      client_secret,
      username: email,
      password,
      grant_type: "password",
      ip: ip,
      browser_agent: browser_agent,
    },
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function getContactDetails() {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_API_URL + `/contact/profile`,
    data: {},
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function valuatePurchaseDetails() {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + `/contact/purchase/valuation`,
    data: {},
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function getClientToken() {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_API_URL + "/client/token?channel=web",
    data: {},
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function register(data) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + "/contact/register/app",
    data: {
      salutation_id: data.salutation_id,
      title_id: data.title_id,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation,
      condition: "app_user_signup",
      ...data,
    },
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function getProductList() {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_API_URL + `/contact/products`,
    data: {},
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function getSubscriptionsList() {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_API_URL + `/contact/subscriptions`,
    data: {},
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function cancelSubscription(id, comments) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + `/contact/subscriptions/cancel/${id}`,
    data: {
      comments: comments,
    },
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function loginAfter2FA(
  id,
  email,
  password,
  client_id,
  client_secret
) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + `/contact/loginAfter2FA/${id}`,
    data: {
      client_id,
      client_secret,
      username: email,
      password,
      grant_type: "password",
    },
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

// verify codes
export function verifyCode(id, code_name, code) {
  return axios.post(
    process.env.REACT_APP_API_URL + `/user/twoFactorAuth/verify/${id}`,
    {
      [code_name]: code,
    }
  );
}

// enable 2-factor auth
export function enableTwoFactorAuthentication(id) {
  return axios.post(`/user/twoFactorAuth/enable/${id}`);
}

// disable 2-factor auth
export function disableTwoFactorAuthentication(id) {
  return axios.delete(`/user/twoFactorAuth/disable/${id}`);
}

// deactive account
export function deactivateContactAccount() {
  return axios.delete(`/contact/deactivate`);
}

// get QR code
export function getQRCode(id) {
  return axios.get(`/user/twoFactorAuth/QRCode/${id}`);
}

// get Recovery codes
export function getRecoveryCodes(id) {
  return axios.get(`/user/twoFactorAuth/recoveryCodes/${id}`);
}

// confirm user password
export function confirmPassword(id, password) {
  return axios.post(`/user/passwordConfirmation/${id}`, {
    password,
  });
}

export async function getOffersList() {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_API_URL + `/contact/offers`,
    data: {},
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function downloadOffer(offerId) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + `/contact/offers/download/${offerId}`,
    data: {},
    headers: { "Content-Type": "multipart/form-data" },
    responseType: "blob",
  });
  return response;
}

export async function downloadFile(id) {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_API_URL + `/mediaManager/downloadFile/${id}`,
    data: {},
    headers: { "Content-Type": "multipart/form-data" },
    responseType: "blob",
  });
  return response;
}

export async function offerReminder(offerId) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + `/contact/offers/resend/${offerId}`,
    data: {},
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function addCompanyAddress(
  id,
  type,
  code,
  name,
  address_1,
  address_2,
  city,
  zip_code,
  country_id
) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + `/contact/companyAddress/add/${id}`,
    data: {
      type,
      code,
      name,
      address_1,
      address_2,
      city,
      zip_code,
      country_id,
    },
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function updateCompanyAddress(
  id,
  name,
  address_1,
  address_2,
  city,
  zip_code,
  country_id
) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + `/contact/companyAddress/update/${id}`,
    data: {
      name,
      address_1,
      address_2,
      city,
      zip_code,
      country_id,
    },
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export async function removeCompanyAddress(
  id,
  name,
  address_1,
  address_2,
  city,
  zip_code,
  country_id
) {
  const response = await axios({
    method: "post",
    url:
      process.env.REACT_APP_API_URL +
      `/contact/companyAddress/remove/${id}?move`,
    data: {
      name,
      address_1,
      address_2,
      city,
      zip_code,
      country_id,
    },
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}
export async function activateDoubleOptIn(email, code) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + `/contact/doubleOptIn/activation`,
    data: {
      email,
      code,
    },
  });
  return response;
}

export async function getGeneralSearchDetails(search_keyword, category) {
  const response = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + `/websiteApp/general/search`,
    data: {
      search_keyword,
      category,
    },
  });
  return response;
}

export async function getGeneralPageSearchDetails(
  search_keyword,
  category,
  id
) {
  const response = await axios({
    method: "post",
    url:
      process.env.REACT_APP_API_URL + `/websiteApp/general/search?page=${id}`,
    data: {
      search_keyword,
      category,
    },
  });
  return response;
}
