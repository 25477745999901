import React, { useState } from "react";
import { useIntl } from "react-intl";
import OwlCarousel from "react-owl-carousel";
// import { useSelector } from "react-redux";
import CarouselArticle from "../Article/CarouselArticle";
import "./style.scss";
const RelatedPosts = ({ sidebar, Id, relatedPosts }) => {
  // const posts = useSelector((state) => state.posts.posts);
  const intl = useIntl();
  const [count, setCount] = useState(1);
  const navLeft =
    count === 1
      ? `<span class="arrow arrow-left arrow-disable"></span>`
      : `<span class="arrow arrow-left arrow-enable"></span>`;
  const navRight =
    count === 1
      ? `<span class="arrow arrow-right arrow-disable"></span>`
      : `<span class="arrow arrow-right arrow-enable"></span>`;

  const responsive = {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    991: {
      items: 2,
    },
  };
  const handleInitialize = (event) => {
    setCount(event.item.count);
  };
  return (
    <div
      className={`mb-3 mt-4 ${!sidebar ? `col-lg-10 mx-auto` : ""}`}
      style={!sidebar ? { maxWidth: "750px" } : {}}
    >
      <div className="d-flex title-wrapper">
        <div className="flex-grow-1  d-flex border-section">
          <h4 className="mb-0 flex-grow-1 d-inline align-self-end">
            {intl.formatMessage({ id: "RELATED_POSTS" })}
          </h4>
          <div className="custom-nav owl-nav mx-auto d-inline relatedposts-nav"></div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <OwlCarousel
          className="owl-theme"
          loop={relatedPosts && relatedPosts.length > 2 ? true : false}
          // loop
          autoplay
          margin={16}
          nav={true}
          lazyLoad={true}
          navText={[
            relatedPosts && relatedPosts.length > 2 ? navLeft : "",
            relatedPosts && relatedPosts.length > 2 ? navRight : "",
          ]}
          dots={false}
          autoplaySpeed={5000}
          autoplayTimeout={5000}
          autoplayHoverPause={true}
          responsive={responsive}
          navContainer=".d-flex .owl-nav.relatedposts-nav"
          onInitialized={handleInitialize}
        >
          {relatedPosts !== undefined &&
            relatedPosts.length > 0 &&
            relatedPosts.map((element, index) => {
              return (
                <CarouselArticle
                  details={element}
                  key={index}
                  sidebars={{ a: true }}
                  relatedPosts={true}
                />
              );
            })}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default RelatedPosts;
