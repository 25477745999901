import { Controls, Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchPaymentStatus } from "../../API/Api";
const PaymentFail = () => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultPage = useSelector(
    (state) => state?.navigationMenu?.defaultPage
  );
  const [response, setResponse] = useState({});
  const id = searchParams.get("id");
  const orderNumber = searchParams.get("orderNumber");
  const navigate = useNavigate();
  useEffect(() => {
    if (defaultPage) {
      if (id && orderNumber) {
        const paramsObject = {
          order_number: orderNumber,
          id,
          status: "cancel",
        };
        fetchPaymentStatus(paramsObject).then((response) => {
          setTimeout(() => {
            setResponse(response);
            //setTimeout(() => navigate(`../../${defaultPage?.name}`), 5000);
          }, 2000);
        });
      }
    }
  }, [defaultPage]);
  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ height: "50vh" }}
    >
      <div className="d-flex justify-content-center align-items-center fw-bold flex-column">
        {/* <img src={FailedGif} style={{ width: "150px", height: "150px" }} /> */}
        <Player
          autoplay
          loop={false}
          src="https://assets1.lottiefiles.com/packages/lf20_tl52xzvn.json"
          style={{ height: "150px", width: "150px" }}
          keepLastFrame={true}
        >
          <Controls visible={false} />
        </Player>
        <p style={{ fontSize: "20px" }}>
          {intl.formatMessage({ id: "PAYMENT_CANCEL_MESSAGE" })}
        </p>
        <div className="text-center mt-3">
          <button
            id="button-custom"
            onClick={() => navigate(`../../${defaultPage?.name}`)}
          >
            {intl.formatMessage({ id: "BUTTON_BACK_TO_HOME" })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFail;
