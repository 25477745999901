import { convert } from "html-to-text";
import React, { useState } from "react";
import { BiWorld } from "react-icons/bi";
import { RiLock2Fill } from "react-icons/ri";
import { useIntl } from "react-intl";

const AboutCard = ({ community }) => {
  const [seeMoreToggle, setSeeMoreToggle] = useState(true);
  const intl = useIntl();

  return (
    <div className="card" style={{ position: "sticky", top: "73px" }}>
      <div className="card-body">
        <h5>
          {intl.formatMessage({
            id: "COMMUNITIES_ABOUT",
          })}
        </h5>
        <div>
          {convert(community?.description).length > 150 ? (
            <>
              {seeMoreToggle ? (
                <>
                  <div>
                    {convert(community?.description)
                      .slice(0, 130)
                      .concat(`...`)}
                  </div>
                  <span
                    onClick={() => {
                      setSeeMoreToggle(false);
                    }}
                    style={{ cursor: "pointer" }}
                    className="fw-bold"
                  >
                    {intl.formatMessage({
                      id: "COMMUNITIES_SEE_MORE",
                    })}
                  </span>
                </>
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: community?.description,
                    }}
                  ></div>
                  <span
                    onClick={() => {
                      setSeeMoreToggle(true);
                    }}
                    style={{ cursor: "pointer" }}
                    className="fw-bold"
                  >
                    {intl.formatMessage({
                      id: "COMMUNITIES_SEE_LESS",
                    })}
                  </span>
                </>
              )}
            </>
          ) : (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: community?.description,
                }}
              ></div>
            </>
          )}
        </div>
        <div className="d-flex flex-row mt-2">
          <div>
            {community?.privacy === "private" ? (
              <RiLock2Fill
                style={{
                  fontSize: "24px",
                  // marginTop: "12px",
                }}
                type="button"
              />
            ) : (
              <BiWorld
                style={{
                  fontSize: "24px",
                  // marginTop: "12px",
                }}
                type="button"
              />
            )}
          </div>
          <div
            className=" ms-2"
            style={
              community?.privacy === "private"
                ? { fontSize: "20px" }
                : { fontSize: "19px" }
            }
          >
            {community?.privacy === "private"
              ? intl.formatMessage({
                  id: "COMMUNITIES_PRIVATE",
                })
              : intl.formatMessage({
                  id: "COMMUNITIES_PUBLIC",
                })}
            <div style={{ fontSize: "14px" }}>
              {community?.privacy === "private"
                ? intl.formatMessage({
                    id: "COMMUNITIES_PRIVATE_MESSAGE",
                  })
                : intl.formatMessage({
                    id: "COMMUNITIES_PUBLIC_MESSAGE",
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCard;
