import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { updateCustomerPersonalAddress } from "../../../API/Api";
import Alert from "../../../sharedComponents/Alerts/Alert";
import { CustomDropDown } from "../../../sharedComponents/Dropdown/CustomDropDown";
import InputLabel from "../../../sharedComponents/InputLabel/InputLabel";
import TextInput from "../../../sharedComponents/TextInput/TextInput";
import { TitleHeader } from "../../../sharedComponents/Title";
import "./style.scss";

function PersonalAddress({ userDetails, getCustomerDetailsAPI }) {
  const intl = useIntl();
  const countries = useSelector((state) => state.masterData.countriesList);

  const [user, setUser] = useState(userDetails);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  useEffect(() => {
    setUser(userDetails);
  }, [userDetails]);

  useEffect(() => {
    countries.forEach((country) => {
      if (
        country.name.toLocaleLowerCase() ===
        user?.personalAddress?.country.toLocaleLowerCase()
      ) {
        formik.setFieldValue("country_id", country.id);
        setSelectedCountry(country);
        onCountrySelect(country, true);
      }
    });
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    address_1: user?.personalAddress?.address_1 || "",
    address_2: user?.personalAddress?.address_2 || "",
    zip_code: user?.personalAddress?.zip_code || undefined,
    city: user?.personalAddress?.city || "",
    country_id: user?.personalAddress?.country_id || undefined,
    state_id: user?.personalAddress?.state_id || undefined,
  };

  const AddAddressSchema = Yup.object().shape({
    address_1: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS1_MESSAGE",
      })
    ),
    zip_code: Yup.number().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ZIP_CODE_MESSAGE",
      })
    ),
    city: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_CITY_MESSAGE",
      })
    ),
    country_id: Yup.number().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_COUNTRY_MESSAGE",
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: AddAddressSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (
        values.address_1 &&
        values.country_id &&
        values.city &&
        values.zip_code
      ) {
        setLoading(true);
        updateCustomerPersonalAddress(
          user?.personalInformation?.id,
          values.address_1,
          values.address_2,
          values.zip_code,
          values.city,
          values.country_id,
          values.state_id
        )
          .then(() => {
            setShowSuccess(true);
            getCustomerDetailsAPI();
            // setCustomer({
            //   ...user,
            //   personalAddress,
            // })
            // SuccessAlert('Address added successfully!')
          })
          .catch(() => {
            setShowFailure(true);
            // setStatus('Cannot add address, try again!')
          })
          .finally(() => {
            setLoading(false);
            // closeModal?.()
          });
      }
    },
  });

  const onCountrySelect = (item) => {
    setSelectedCountry(item);
    // setSelectedState(undefined)
    // setStatesLoading(true)
    // getStatesList(item.id)
    //   .then(({data: {states = []}}) => {
    //     setStates(states)
    //     if (updateState) {
    //       states.map((state) => {
    //         if (state.id === address?.state_id) {
    //           formik.setFieldValue('state_id', state.id)
    //           setSelectedState(state)
    //         }
    //       })
    //     }
    //   })
    //   .finally(() => {
    //     setStatesLoading(false)
    //   })
  };

  return (
    <>
      <Alert
        show={showSuccess ? showSuccess : showFailure}
        setShow={showSuccess ? setShowSuccess : setShowFailure}
        message={
          showSuccess
            ? intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_SUCCESS_MESSAGE",
              })
            : intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ERROR_MESSAGE",
              })
        }
        type={showSuccess ? "success" : "failure"}
      />
      <div className="card mb-4 mb-xl-10 mx-0 card-no-border">
        <div
          className="card-header border-0 cursor-pointer card-header-custom pt-0"
          role="button"
          //  data-bs-toggle='collapse'
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <TitleHeader
            noMarginTop
            title={intl.formatMessage({
              id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_TITLE",
            })}
          />
        </div>

        <div id="kt_account_profile_details" className="collapse show">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <div className="card-body pt-0 p-3">
              {/* address 1 */}
              <div className=" mb-3">
                {/* <label className=' col-lg-4 col-form-label required form-input-label'>
                {translate('ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS1_LABEL')}
                </label>

                <div className='col-lg-8'>
                  <div className='fv-row'>
                    <input
                      type='text'
                      className=' mb-3 mb-lg-0 form-common-input'
                      style={{width:'100%'}}
                      {...formik.getFieldProps('address_1')}
                    />
                    {formik.errors.address_1 && (
                      <div className='text-danger'>
                        <span role='alert'>{formik.errors.address_1}</span>
                      </div>
                    )}
                  </div>
                </div> */}
                <InputLabel
                  required
                  text={intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS1_LABEL",
                  })}
                />

                <TextInput
                  value={formik.values.address_1}
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    formik.setFieldValue("address_1", value);
                  }}
                  isError={formik.touched.address_1 && formik.errors.address_1}
                />
                {formik.touched.address_1 && formik.errors.address_1 && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik.errors.address_1}</span>
                  </div>
                )}
              </div>

              {/* address 2 */}
              <div className=" mb-3">
                {/* <label className=' col-lg-4 col-form-label form-input-label'>
                {translate('ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS2_LABEL')}
                </label>

                <div className='col-lg-8'>
                  <div className='fv-row'>
                    <input
                      type='text'
                      className=' mb-3 mb-lg-0 form-common-input'
                      style={{width:'100%'}}
                      {...formik.getFieldProps('address_2')}
                    />
                    {formik.errors.address_2 && (
                      <div className='text-danger'>
                        <span role='alert'>{formik.errors.address_2}</span>
                      </div>
                    )}
                  </div>
                </div> */}
                <InputLabel
                  text={intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS2_LABEL",
                  })}
                />

                <TextInput
                  value={formik.values.address_2}
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    formik.setFieldValue("address_2", value);
                  }}
                  isError={formik.touched.address_2 && formik.errors.address_2}
                />
                {formik.touched.address_2 && formik.errors.address_2 && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik.errors.address_2}</span>
                  </div>
                )}
              </div>

              {/* city and zip code */}

              <div className="row mb-3 form-outline position-relative">
                <div className="col-md-6 position-relative first-name-sm">
                  <InputLabel
                    required
                    text={intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_CITY_LABEL",
                    })}
                  />

                  <TextInput
                    value={formik.values.city}
                    size="lg"
                    fontSize="16px"
                    onChange={(value) => {
                      formik.setFieldValue("city", value);
                    }}
                    isError={formik.touched.city && formik.errors.city}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.city}</span>
                    </div>
                  )}
                </div>
                <div className="col-md-6 position-relative first-name-sm">
                  <InputLabel
                    required
                    text={intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ZIP_CODE_LABEL",
                    })}
                  />

                  <TextInput
                    value={formik.values.zip_code}
                    size="lg"
                    fontSize="16px"
                    type="number"
                    onChange={(value) => {
                      formik.setFieldValue("zip_code", value);
                    }}
                    isError={formik.touched.zip_code && formik.errors.zip_code}
                  />
                  {formik.touched.zip_code && formik.errors.zip_code && (
                    <div
                      style={{ fontSize: "14px", color: "var(--error-alert)" }}
                    >
                      <span role="alert">{formik.errors.zip_code}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* state */}
              <div className=" mb-3">
                <InputLabel
                  text={intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_STATE_LABEL",
                  })}
                />

                <TextInput
                  value={formik.values.state_id}
                  size="lg"
                  fontSize="16px"
                  onChange={(value) => {
                    formik.setFieldValue("state_id", value);
                  }}
                  isError={formik.touched.state_id && formik.errors.state_id}
                />
                {formik.touched.state_id && formik.errors.state_id && (
                  <div
                    style={{ fontSize: "14px", color: "var(--error-alert)" }}
                  >
                    <span role="alert">{formik.errors.state_id}</span>
                  </div>
                )}
              </div>

              {/* country */}
              <div className="w-100">
                <InputLabel
                  required
                  text={intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_COUNTRY_LABEL",
                  })}
                />
                <CustomDropDown
                  id="countriesDropDown"
                  //   hideSearch
                  items={countries}
                  selectedItem={selectedCountry}
                  size="small"
                  // @ts-ignore
                  onSelectItem={(item) => {
                    formik.setFieldValue("country_id", item.id);
                    onCountrySelect(item);
                  }}
                  displayValue={selectedCountry?.name || ""}
                  // @ts-ignore
                  displayFunc={(item) => {
                    return item.emoji + " " + item.name;
                  }}
                />

                {formik.errors.country_id && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.country_id}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="card-footer d-flex justify-content-start py-3 px-9 card-footer-custom">
              <button
                type="submit"
                className="btn btn-custom"
                disabled={loading}
              >
                {!loading &&
                  intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_SUBMIT",
                  })}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    {intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_LOADING",
                    })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default PersonalAddress;
