import { useState } from "react";
import CommentSection from "./CommentSection";

const Comment = ({ details, updateRatingCommentsAll }) => {
  const [showComments, setShowComments] = useState(undefined);
  const [showSubComments, setShowSubComments] = useState(undefined);
  const [showLastLevelComments, setShowLastLevelComments] = useState(undefined);

  const handleReplyItem = (item) => {
    setShowComments(item);
  };

  const handleSubReplyItem = (item) => {
    setShowSubComments(item);
  };

  const handleLastLevelReplyItem = (item) => {
    setShowLastLevelComments(item);
  };

  const getTotalCommentsRecursive = (items, result) => {
    items.map((data) => {
      result.push(data);
      if (data.replies.length > 0) {
        getTotalCommentsRecursive(data.replies, result);
      }
    });
  };

  const getLastLevelReplies = (items) => {
    const result = [];
    getTotalCommentsRecursive(items, result);
    return result;
  };

  const totalRatings = details?.ratings || [];

  const shouldShowHideReplies = (rating, depth) => {
    if (depth === 1) {
      return rating.id === showComments?.id;
    } else if (depth === 2) {
      return rating.id === showSubComments?.id;
    } else if (depth === 3) {
      return rating.id === showLastLevelComments?.id;
    }
  };

  const hideReplies = (depth) => {
    if (depth === 1) {
      setShowComments(undefined);
      setShowSubComments(undefined);
      setShowLastLevelComments(undefined);
    } else if (depth === 2) {
      setShowSubComments(undefined);
      setShowLastLevelComments(undefined);
    } else if (depth === 3) {
      setShowLastLevelComments(undefined);
    }
  };

  return (
    <div>
      {totalRatings && totalRatings.length > 0
        ? totalRatings.map((rating, index) => {
            const replies = rating.replies || [];
            return (
              <div>
                <CommentSection
                  key={index}
                  rating={rating}
                  details={details}
                  updateRatingCommentsAll={updateRatingCommentsAll}
                  handleReplyItem={handleReplyItem}
                  depth={1}
                  shouldShowHideReplies={shouldShowHideReplies}
                  hideReplies={hideReplies}
                />

                {showComments?.id === rating.id && (
                  <div
                    style={{
                      borderLeft: "1px solid #ccc",
                      marginTop: "15px",
                      padding: "5px 0 0 0",
                      marginLeft: "10px",
                    }}
                  >
                    {replies &&
                      replies.length > 0 &&
                      replies.map((reply) => {
                        const subReplies = reply.replies;
                        return (
                          <div>
                            <CommentSection
                              rating={reply}
                              details={details}
                              updateRatingCommentsAll={updateRatingCommentsAll}
                              handleReplyItem={handleSubReplyItem}
                              depth={2}
                              shouldShowHideReplies={shouldShowHideReplies}
                              hideReplies={hideReplies}
                            />
                            {showSubComments?.id === reply.id && (
                              <div
                                style={{
                                  borderLeft: "1px solid #ccc",
                                  marginTop: "15px",
                                  padding: "5px 0 0 0",
                                  marginLeft: "30px",
                                }}
                              >
                                {subReplies &&
                                  subReplies.length > 0 &&
                                  subReplies.map((subReply) => {
                                    const lastLeveleReplies =
                                      getLastLevelReplies(subReply.replies);
                                    return (
                                      <div>
                                        <CommentSection
                                          rating={subReply}
                                          details={details}
                                          updateRatingCommentsAll={
                                            updateRatingCommentsAll
                                          }
                                          handleReplyItem={
                                            handleLastLevelReplyItem
                                          }
                                          depth={3}
                                          shouldShowHideReplies={
                                            shouldShowHideReplies
                                          }
                                          hideReplies={hideReplies}
                                        />
                                        {showLastLevelComments?.id ===
                                          subReply.id && (
                                          <div
                                            style={{
                                              borderLeft: "1px solid #ccc",
                                              marginTop: "15px",
                                              padding: "5px 0 0 0",
                                              marginLeft: "30px",
                                            }}
                                          >
                                            {lastLeveleReplies &&
                                              lastLeveleReplies.length > 0 &&
                                              lastLeveleReplies.map(
                                                (lastItem) => {
                                                  return (
                                                    <div>
                                                      <CommentSection
                                                        rating={lastItem}
                                                        details={details}
                                                        updateRatingCommentsAll={
                                                          updateRatingCommentsAll
                                                        }
                                                        depth={4}
                                                      />
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
                {/* {index !== totalRatings.length - 1 ? (
                  <div
                    className="mt-3"
                    style={{
                      height: "0.2px",
                      backgroundColor: "#e0e0e0",
                    }}
                  ></div>
                ) : null} */}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default Comment;
