import React, { useState } from "react";
import LoginCodeVerification from "./LoginCodeVerification";
import NewPassword from "./NewPassword";

function CodeVerificationWrapper() {
  const [codeVerified, SetCodeVerified] = useState(false);
  const [customSignupEmail, setcustomSignupEmail] = useState("");
  const submitted = () => {
    SetCodeVerified(true);
  };
  return codeVerified ? (
    <NewPassword email={customSignupEmail} forgotPassword={false} />
  ) : (
    <LoginCodeVerification
      submitted={submitted}
      setcustomSignupEmail={setcustomSignupEmail}
    />
  );
}

export default CodeVerificationWrapper;
