import moment from "moment/moment";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useLocal } from "../../../App";
import LeaveCommentModal from "../../../sharedComponents/Modal/LeaveCommentModal";
import { successToast } from "../../../sharedComponents/Toast/Toasts";
import { formatDate } from "../../../utils";

function SubscriptionCard({
  element,
  cancelButton,
  getSubscriptionsListAPI,
  canceled,
}) {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const local = useLocal();

  const handleClose = () => {
    setShowModal(false);
  };

  const setSubscriptionIntlId = (interval, number) => {
    switch (interval) {
      case "year":
        if (number === "1") {
          return { id: "SUBSCRIPTION_PLAN_1_YEAR" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_YEARLY" };
        }
      case "month":
        if (number === "1") {
          return { id: "SUBSCRIPTION_PLAN_1_MONTH" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_MONTHLY" };
        }
      case "week":
        if (number === "1") {
          return { id: "SUBSCRIPTION_PLAN_1_WEEK" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_WEEKLY" };
        }
      case "day":
        if (number === "1") {
          return { id: "SUBSCRIPTION_PLAN_1_DAY" };
        } else {
          return { id: "SUBSCRIPTION_PLAN_DAILY" };
        }
    }
  };

  return (
    <>
      <LeaveCommentModal
        show={showModal}
        handleClose={() => {
          handleClose();
        }}
        id={element?.id}
        getSubscriptionsListAPI={getSubscriptionsListAPI}
      />
      <div
        className="d-xl-flex p-3 mb-3 justify-content-between position-relative"
        style={{ border: "2px solid #f3f3f3", borderRadius: "10px" }}
      >
        {/* {!!canceled ? ( */}
        <div className=" position-absolute px-2 py-1 mt-2 me-3 subscriptions-active-label">
          Active
        </div>
        {/* ) : null} */}
        <div>
          <div
            className="fw-bolder"
            style={{ fontSize: "20px", maxWidth: "400px" }}
          >
            <div className="mb-2" style={{ wordBreak: "break-all" }}>
              {element?.name}
            </div>
          </div>
          {element?.download_invoice && element?.transaction_id ? (
            <>
              <div
                className="fw-bolder"
                style={{ fontSize: "20px", maxWidth: "400px" }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ fontSize: "12px" }}
                >
                  <span
                    style={{ fontSize: "18px", color: "var(--accent-color)" }}
                  >
                    {`${element?.currency === "eur" ? "€" : ""}${(
                      parseInt(element?.price) / 100
                    ).toFixed(2)} / `}
                  </span>
                  <span style={{ color: "red" }} className="ms-1">
                    {` *`}
                  </span>
                  <span>
                    {`${
                      element?.tax_behavior === "inclusive"
                        ? intl.formatMessage({
                            id: "ACCOUNT_SETTINGS_PRODUCT_CARD_INCLUSIVE_OF_ALL_TAXES",
                          })
                        : intl.formatMessage({
                            id: "ACCOUNT_SETTINGS_PRODUCT_CARD_EXCLUSIVE_OF_ALL_TAXES",
                          })
                    }`}
                  </span>
                </div>
              </div>
              {element?.transaction_date ? (
                <div className="mt-1 fw-bold" style={{ fontSize: "14px" }}>
                  {/* {date} */}
                  <div className=" d-inline-block" style={{ width: "100px" }}>
                    {intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PRODUCT_CARD_PURCHASED_DATE",
                    })}
                  </div>
                  :&nbsp;
                  <span className="fw-normal">
                    {local && local === "en"
                      ? formatDate(element?.transaction_date * 1000)
                      : ` ${moment(element?.transaction_date * 1000).format(
                          "DD.MM.yyyy"
                        )}`}
                  </span>
                </div>
              ) : null}
              <div
                style={{ fontSize: "14px" }}
                className="d-flex flex-row mt-1"
              >
                {/* <TfiReload className="clipboard-icon d-inline-block" /> */}
                <div style={{ color: "var(--accent-color)" }}>{`${
                  element?.price_interval_count === "1"
                    ? ""
                    : element?.price_interval_count
                } ${intl.formatMessage(
                  setSubscriptionIntlId(
                    element?.price_interval,
                    element?.price_interval_count
                  )
                )}`}</div>
              </div>
              {element?.purchase_device !== null ? (
                <div className="">
                  {element?.purchase_device?.toLowerCase() === "ios" ? (
                    <div>
                      {intl.formatMessage({
                        id: "ACCOUNT_SETTINGS_PURCHASE_IOS_INFO",
                      })}
                    </div>
                  ) : (
                    <div>
                      {intl.formatMessage({
                        id: "ACCOUNT_SETTINGS_PURCHASE_ANDROID_INFO",
                      })}
                    </div>
                  )}
                </div>
              ) : null}
            </>
          ) : null}
          {!!element?.is_cancelled && (
            <div style={{ fontSize: "14px" }} className="d-flex flex-row mt-1">
              {/* <TfiReload className="clipboard-icon d-inline-block" /> */}
              <div className="text-danger">{`${intl.formatMessage({
                id: "ACCOUNT_SETTINGS_SUBSCRIPTIONS_CARD_EXPIRES_ON",
              })} ${
                local && local === "en"
                  ? formatDate(element?.cycle_end_date)
                  : moment(element?.cycle_end_date).format("DD.MM.yyyy hh:mm")
              }`}</div>
            </div>
          )}
        </div>
        <div
          className={`justify-content-xl-end ${
            element?.transaction_date ? "mt-xl-0" : "mt-xl-3"
          } mt-3 d-flex align-items-center`}
        >
          {element?.download_invoice ? (
            <a
              download
              onClick={() => {
                successToast(
                  intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_PRODUCT_CARD_DOWNLOAD_INVOICE_SUCCESS_TOAST",
                  })
                );
              }}
              href={element?.download_invoice}
              rel="noreferrer"
              className="btn btn-custom fw-bold cursor-pointer"
            >
              <span>
                {/* <VscCloudDownload className="product-download-icon" /> */}
              </span>
              {intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PRODUCT_CARD_DOWNLOAD_INVOICE",
              })}
            </a>
          ) : null}
          {!!cancelButton &&
          element?.download_invoice &&
          element?.transaction_id &&
          element?.purchase_device === null ? (
            <div
              onClick={() => {
                setShowModal(true);
              }}
              className="btn btn-danger fw-bold cursor-pointer ms-2"
            >
              {/* <span>
              <VscCloudDownload className="product-download-icon" />
            </span> */}
              {intl.formatMessage({
                id: "ACCOUNT_SETTINGS_SUBSCRIPTIONS_CARD_CANCEL_SUBSCRIPTION",
              })}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default SubscriptionCard;
