import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import React from "react";
import { hydrate } from "react-dom";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux/es/exports";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import setupAxios from "./API/setupAxios";
import App from "./App";
import RootReducer from "./RootReducer";
import rootSaga from "./Saga/rootSaga";
import { MetronicI18nProvider } from "./i18n/Metronici18n";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

const sagaMiddleware = createSagaMiddleware();
export const Store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: false,
    }),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(Store);
setupAxios(axios, Store);
const root = ReactDOM.createRoot(document.getElementById("root"));
const rootElement = document.getElementById("root");

const app = (
  <MetronicI18nProvider>
    <Provider store={Store}>
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>
);

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  root.render(app);
}

reportWebVitals();
