import axios from "axios";
import { convert } from "html-to-text";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AiFillRead, AiOutlineRead } from "react-icons/ai";
import { HiHeart, HiOutlineHeart } from "react-icons/hi";
import { RiCalendarTodoFill } from "react-icons/ri";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateUserRead } from "../../API/Api";
import { useLocal } from "../../App";
import { getPostDataById } from "../../Redux/PostsReducer";
import noImage from "../../assets/noImage.jpg";
import { formatDate, useCustomMediaQuery } from "../../utils";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import Author from "../AuthorImage";
import GalleryContainer from "../CardGallery";
import Loader from "../Loader";
import MediaContainer from "../MediaContainer/MediaContainer";
import Product from "../Payments/ProductCard";
import SubscriptionCard from "../Payments/SubscriptionCard";
import PostRatingDetails from "../Ratings/PostRatingDetails";
import RelatedPosts from "../RelatedPosts/RelatedPosts";
import { TitleHeader } from "../Title";
import "./style.scss";
const DetailsPageWithoutSidebar = () => {
  const intl = useIntl();
  const local = useLocal();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // const largeScreen = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  // const smallScreen = useMediaQuery({ maxWidth: 575 });
  // const mediumScreen = useMediaQuery({ minWidth: 576, maxWidth: 767 });
  const vlgScreen = useMediaQuery({ minWidth: 992 });
  const { resolution } = useCustomMediaQuery();
  const [sideBar, setsideBar] = useState(false);
  const [favLoading, setfavLoading] = useState(false);
  const posts = useSelector((state) => state.posts.posts);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const contactId = useSelector(
    (state) => state?.auth?.user?.personalInformation?.id
  );
  // const productsBought = useSelector((state) => state.auth?.products);
  const sysAdmin = useSelector(
    (state) => state.auth?.user?.personalInformation?.sys_admin
  );
  const generalDetails = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  const isLoading = useSelector((state) => state?.posts?.isLoading);
  const posts_details_date_time_format = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails?.posts_general_settings
        ?.posts_details_date_time_format
  );
  const userInfo = useSelector(
    (state) => state.auth?.user?.personalInformation
  );
  const postId = location.state?.postId || id;
  const [favourite, setfavourite] = useState(
    !!posts?.[postId]?.contacts_favourite
  );
  const [readLoading, setreadLoading] = useState(false);
  const [read, setRead] = useState(posts?.[postId]?.contacts_read);
  useEffect(() => {
    if (!postId) {
      navigate("/404");
    }
  }, []);
  useEffect(() => {
    setfavourite(!!posts?.[postId]?.contacts_favourite);
    setRead(posts?.[postId]?.contacts_read);
  }, [posts?.[postId]]);

  useEffect(() => {
    if (!Object.keys(posts).includes(postId)) {
      dispatch(
        getPostDataById({
          id: postId,
          isAuthorized: isAuthorized,
          contactId: contactId,
        })
      );
    }
  }, [postId]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  const setPostMediaSource = () => {
    const imgResolutions =
      posts?.[postId]?.post_type === "image"
        ? posts?.[postId]?.post_media_resolutions
        : posts?.[postId]?.post_thumbnail_resolutions;
    const file_url =
      posts?.[postId]?.post_type === "image"
        ? posts?.[postId]?.post_media
        : posts?.[postId]?.post_thumbnail;
    if (imgResolutions && imgResolutions.length > 0) {
      const source = imgResolutions.filter(
        (element) => element?.resolution === resolution
      );
      return source[0].file_url;
    }
    return file_url ? file_url : noImage;
  };
  const PostDescription = () => {
    return (
      <>
        <div
          className="post-description-one"
          dangerouslySetInnerHTML={{ __html: posts?.[postId]?.description }}
        >
          {/* {formatString(posts?.[postId]?.description)} */}
        </div>

        {posts?.[postId]?.galleries.length > 0 ? (
          <div>
            <GalleryContainer
              sideBar={sideBar}
              galleries={posts?.[postId]?.galleries}
            />
          </div>
        ) : null}

        {posts?.[postId]?.media.length > 0 ? (
          <div className="mb-4 mt-4 mx-auto" style={{ maxWidth: "750px" }}>
            <MediaContainer media={posts?.[postId]?.media} />
          </div>
        ) : null}
      </>
    );
  };
  useEffect(() => {
    if (favourite) {
      const element = document.querySelector("div.favourite");
      if (element) {
        element.classList.add("animate__animated", "animate__bounceIn");
      }
    }
  }, [favourite]);
  const handlePostFavourite = async () => {
    setfavLoading(true);
    try {
      const response = await axios.post(
        `/websiteApp/posts/contacts/favourites/${postId}/${contactId}`,
        {
          favourite: !favourite,
        }
      );
      if (response.data.message === "Success") {
        setfavourite(!!response.data.postDetails.contacts_favourite);
        setfavLoading(false);
      }
    } catch (error) {
      setfavLoading(false);
      throw error;
    }
  };
  const handlePostRead = (val) => {
    setreadLoading(true);
    updateUserRead(postId, contactId, val)
      .then((res) => {
        setreadLoading(false);
        if (res.status === 200) {
          const {
            postDetails: { contacts_read },
          } = res.data;
          setRead(contacts_read);
        }
      })
      .catch((error) => {
        setreadLoading(false);
        throw error;
      });
  };
  const ProductList = ({ products }) => {
    return products && products.length > 0
      ? products.map((ele, index) => (
          <Product products={ele} key={index} sidebar={false} postId={postId} />
        ))
      : null;
  };
  const SubscriptionList = ({ subscriptions }) => {
    return subscriptions && subscriptions.length > 0
      ? subscriptions.map((ele, index) => (
          <SubscriptionCard
            subscription={ele}
            key={index}
            sidebar={false}
            postId={postId}
          />
        ))
      : null;
  };

  const returnScript = () => {
    return posts?.[postId]?.post_type === "video"
      ? JSON.stringify([
          {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            name: posts?.[postId]?.title,
            description: convert(posts?.[postId]?.description),
            thumbnailUrl: posts?.[postId]?.post_thumbnail_url,
            uploadDate: posts?.[postId]?.published_at,
            duration: posts?.[postId]?.post_media_duration,
            contentUrl: posts?.[postId]?.post_media_url,
            embedUrl: posts?.[postId]?.post_media_url,
            // interactionStatistic: {
            //   "@type": "InteractionCounter",
            //   interactionType: { "@type": "WatchAction" },
            //   userInteractionCount: 5647018,
            // },
            // regionsAllowed: "US,NL",
          },
        ])
      : JSON.stringify([
          {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            headline: posts?.[postId]?.title,
            image: posts?.[postId]?.post_media_url,
            datePublished: posts?.[postId]?.published_at,
            dateModified: posts?.[postId]?.updated_at,
            author:
              posts?.[postId]?.authors.length > 0
                ? posts?.[postId]?.authors.map((author) => {
                    return {
                      "@type": "Person",
                      name: author.display_name,
                      url: `https://${window.location.host}/authors/${author.id}`,
                    };
                  })
                : [],
          },
        ]);
  };

  return (
    <>
      <Helmet>
        <title>{posts?.[postId]?.seo_tag}</title>
        <script>{returnScript()}</script>
      </Helmet>

      <div className="pb-4">
        {isLoading === true ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="row">
              <div className={sideBar ? `col-lg-8` : "col-lg-12"}>
                <div className="mt-5 mb-2 title_container">
                  <h1 style={{ textAlign: "start" }}>
                    {posts?.[postId]?.title}
                  </h1>
                  <div
                    style={{ fontSize: "18px" }}
                    dangerouslySetInnerHTML={{
                      __html: posts?.[postId]?.introduction,
                    }}
                  >
                    {/* {formatString(posts?.[postId]?.introduction)} */}
                  </div>
                </div>
                {isAuthorized ||
                posts_details_date_time_format !== "none" ||
                posts?.[postId]?.authors.length > 0 ? (
                  <>
                    <hr
                      className="mt-3 mb-0"
                      style={{
                        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                        maxWidth: "750px",
                        margin: "auto",
                      }}
                    ></hr>
                    <div
                      className="d-flex align-items-center justify-content-between my-2"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        maxWidth: "750px",
                      }}
                    >
                      <ul className="list-inline d-flex justify-content-start align-items-center mt-0 mb-0">
                        {posts?.[postId]?.authors.length > 0 ? (
                          <li
                            className="list-inline-item"
                            style={{ marginLeft: "10px" }}
                          >
                            <Author authors={posts?.[postId]?.authors} />
                          </li>
                        ) : null}
                        {posts_details_date_time_format !== "none" ? (
                          <li
                            className="list-inline-item"
                            style={{ verticalAlign: "top" }}
                          >
                            <span
                              className="text-dark text-capitalize fw-semibold d-flex justify-content-center align-items-center"
                              style={{ fontSize: "14px" }}
                            >
                              <RiCalendarTodoFill className="icon-calendar" />
                              {local && local === "en"
                                ? posts_details_date_time_format === "date"
                                  ? formatDate(posts?.[postId]?.published_at)
                                  : formatDate(
                                      posts?.[postId]?.published_at,
                                      true
                                    )
                                : moment(posts?.[postId]?.published_at).format(
                                    posts_details_date_time_format === "date"
                                      ? "DD.MM.yyyy"
                                      : "DD.MM.yyyy HH:mm"
                                  )}
                            </span>
                          </li>
                        ) : null}
                      </ul>
                      <div
                        className="d-flex justify-content-end align-items-center"
                        style={{ columnGap: "1rem" }}
                      >
                        {isAuthorized ? (
                          <div
                            className="d-flex align-items-center"
                            style={
                              {
                                //columnGap: "3px"
                              }
                            }
                          >
                            {readLoading ? (
                              <Oval
                                height={20}
                                width={15}
                                color="var(--accent-color)"
                                wrapperStyle={{ paddingLeft: "0px" }}
                                wrapperClass="d-inline read-loader"
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="var(--accent-color)"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            ) : read === 0 ? (
                              <div
                                onClick={() => handlePostRead(1)}
                                id="mark-as-read"
                                className="d-flex align-items-center"
                                style={{ cursor: "pointer" }}
                              >
                                <AiOutlineRead
                                  style={{
                                    width: "25px",
                                    height: "24px",
                                    color: "var(--accent-color)",
                                    verticalAlign: "bottom",
                                    marginTop: "2px",
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                onClick={() => handlePostRead(0)}
                                className="d-inline"
                                style={{ cursor: "pointer" }}
                                id="marked-as-read"
                              >
                                <AiFillRead
                                  style={{
                                    width: "25px",
                                    height: "24px",
                                    fill: "var(--accent-color)",
                                    verticalAlign: "bottom",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : null}

                        {isAuthorized ? (
                          favLoading ? (
                            <Oval
                              height={20}
                              width={15}
                              color="var(--accent-color)"
                              wrapperStyle={{}}
                              wrapperClass="mb-1"
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="var(--accent-color)"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                            />
                          ) : (
                            <div
                              className="favourite mb-1"
                              onClick={() => handlePostFavourite()}
                            >
                              {favourite ? (
                                <HiHeart
                                  style={{
                                    width: "25px",
                                    height: "24px",
                                    fill: "var(--accent-color)",
                                  }}
                                />
                              ) : (
                                <HiOutlineHeart
                                  style={{
                                    width: "25px",
                                    height: "24px",
                                    color: "var(--accent-color)",
                                  }}
                                />
                              )}
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
                {/* <div> */}
                {posts?.[postId]?.post_type === "video" ? (
                  <div
                    style={{
                      margin: "auto",
                      width: vlgScreen ? "90%" : "100%",
                    }}
                  >
                    <div className="video-container">
                      <div
                        className="blur-image-bg"
                        style={{
                          backgroundImage: `url(${setPostMediaSource()})`,
                        }}
                      ></div>
                      <div
                        className={`video-wrapper ${
                          (posts?.[postId]?.products?.length > 0 ||
                            posts?.[postId]?.subscriptions?.length > 0) &&
                          !posts?.[postId].is_bought
                            ? "pointerEvents position-relative"
                            : ""
                        }`}
                      >
                        <video
                          controls
                          id="video-player"
                          poster={setPostMediaSource()}
                          className="rounded"
                        >
                          <source src={posts?.[postId]?.post_media}></source>
                        </video>
                        <p>{posts?.[postId]?.post_media_copyright_text}</p>
                        <div className="w-100 disableControls"></div>
                      </div>
                    </div>
                  </div>
                ) : posts?.[postId]?.post_type === "audio" ? (
                  <div
                    className={`${
                      (posts?.[postId]?.products?.length > 0 ||
                        posts?.[postId]?.subscriptions?.length > 0) &&
                      !posts?.[postId].is_bought
                        ? "pointerEvents"
                        : ""
                    }`}
                    style={{
                      margin: "auto",
                      width: vlgScreen ? "90%" : "100%",
                    }}
                  >
                    <AudioPlayer id={postId} />
                  </div>
                ) : (
                  <div
                    style={{
                      margin: "auto",
                      width: vlgScreen ? "90%" : "100%",
                    }}
                  >
                    <img
                      className="post-image rounded"
                      src={setPostMediaSource()}
                    />
                  </div>
                )}
                {/* </div> */}
                <p
                  style={{
                    fontSize: "14px",
                    maxWidth: "750px",
                    margin: "auto",
                  }}
                >
                  {posts?.[postId]?.post_media_copyright_text}
                </p>

                {Boolean(sysAdmin) ? (
                  <PostDescription />
                ) : !!posts?.[postId]?.is_bought ? (
                  <PostDescription />
                ) : posts?.[postId]?.products &&
                  posts?.[postId]?.products.length === 0 &&
                  posts?.[postId]?.subscriptions &&
                  posts?.[postId].subscriptions.length === 0 ? (
                  <PostDescription />
                ) : (
                  <>
                    {posts?.[postId]?.products &&
                    posts?.[postId]?.products.length > 0 ? (
                      <>
                        <div style={{ maxWidth: "750px", margin: "auto" }}>
                          <TitleHeader
                            title={intl.formatMessage({
                              id: "SHARED_COMPONENTS_BUY_PRODUCT",
                            })}
                            className={"mt-4"}
                          />
                        </div>
                        <ProductList products={posts?.[postId].products} />
                      </>
                    ) : null}
                    {posts?.[postId]?.subscriptions &&
                    posts?.[postId].subscriptions.length > 0 ? (
                      <>
                        <div style={{ maxWidth: "750px", margin: "auto" }}>
                          <TitleHeader
                            title={intl.formatMessage({
                              id: "SHARED_COMPONENTS_BUY_SUBSCRIPTION",
                            })}
                            className={"mt-4"}
                          />
                        </div>
                        <SubscriptionList
                          subscriptions={posts?.[postId]?.subscriptions}
                        />
                      </>
                    ) : null}
                  </>
                )}

                {posts?.[postId]?.related_posts.length > 0 ? (
                  <div>
                    <RelatedPosts
                      Id={postId}
                      sideBar={sideBar}
                      relatedPosts={posts?.[postId]?.related_posts}
                    />
                  </div>
                ) : null}
                {userInfo ? (
                  <div className="d-flex justify-content-center">
                    <div style={{ maxWidth: "750px" }}>
                      <PostRatingDetails />{" "}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DetailsPageWithoutSidebar;
