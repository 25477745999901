import React, { useEffect } from "react";
import { BsX } from "react-icons/bs";
import { useIntl } from "react-intl";
import TextInput from "../TextInput/TextInput";
const AdditonalFieldsForm = ({
  index,
  values,
  handleBlur,
  handleRemoveRow,
  handleError,
  handleInputChange,
}) => {
  const intl = useIntl();
  // const [inputValues, setinputValues] = useState({});
  useEffect(() => {}, [values]);

  return (
    <>
      <div
        className="d-flex align-items-start mt-3"
        style={{ columnGap: "1rem" }}
      >
        <div className="flex-fill">
          {/* <InputLabel text="Name" /> */}
          <TextInput
            onChange={handleInputChange}
            params={{ type: "name", index }}
            value={values?.[index]?.name ? values?.[index]?.name : ""}
            isError={handleError(index, "name")}
            placeholder={intl.formatMessage({
              id: "MODAL_PLACEHOLDER_NAME",
            })}
            onBlur={() => handleBlur(index, "name")}
          />
          {!!handleError(index, "name") ? (
            <div
              style={{
                fontSize: "14px",
                fontFamily: "var(--font-Family)",
                color: "var(--error-alert)",
              }}
            >
              {intl.formatMessage({ id: "SHARED_COMPONENTS_REQUIRED" })}
            </div>
          ) : null}
        </div>
        <div className="flex-fill">
          {/* <InputLabel text="Value" /> */}
          <TextInput
            onChange={handleInputChange}
            params={{ type: "value", index }}
            value={values?.[index]?.value ? values?.[index]?.value : ""}
            isError={handleError(index, "value")}
            placeholder={intl.formatMessage({
              id: "MODAL_PLACEHOLDER_VALUE",
            })}
            onBlur={() => handleBlur(index, "value")}
          />
          {!!handleError(index, "value") ? (
            <div
              style={{
                fontSize: "14px",
                fontFamily: "var(--font-Family)",
                color: "var(--error-alert)",
              }}
            >
              {intl.formatMessage({ id: "SHARED_COMPONENTS_REQUIRED" })}
            </div>
          ) : null}
        </div>
        <div
          type="button align-self-stretch"
          onClick={() => handleRemoveRow(index)}
        >
          <BsX
            style={{
              fill: "var(--accent-color)",
              verticalAlign: "baseline",
              transform: "scale3d(1.3,1.3,1.2)",
              marginTop: "16px",
              cursor: "pointer",
              //alignSelf:'center'
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AdditonalFieldsForm;
