import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getMasterDataByType } from "../../Redux/MasterDataReducer";
import TagsList from "./TagsList";
import "./style.scss";
// import translate from "../../i18n/translate";
const Tags = ({ details, pageName, title }) => {
  const navigate = useNavigate();
  const tags = useSelector((state) => state?.masterData);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!tags?.[pageName]?.tags)
      dispatch(getMasterDataByType({ listType: "tag-list", pageName }));
  }, [pageName]);
  const masterDataList = tags?.[pageName]?.tags?.masterData;
  const handleClick = (id, name) => {
    // dispatch(
    //   getMasterDataDetails({
    //     masterDataType: "tags",
    //     id,
    //     pageName,
    //   })
    // );
    navigate(`../tags/${pageName}/${id}/${name}`, {
      state: {
        pageName: pageName,
        type: "tags",
        id: id,
      },
    });
  };
  return (
    <div className="tags-wrapper mt-0 mb-3">
      {!!details?.display_header ? (
        <h4 className="border-section">{title}</h4>
      ) : null}

      <div className="d-inline-block">
        <ul className="list-inline mb-0">
          {masterDataList && masterDataList.length > 0
            ? masterDataList
                .sort((a, b) => b.posts_count - a.posts_count)
                .map((ele, index) => {
                  if (index < details?.maximum_number_of_data) {
                    return (
                      <TagsList
                        name={ele.name}
                        key={index}
                        postsCount={details?.posts_count ? ele.posts_count : ""}
                        id={ele?.id}
                        pageName={pageName}
                        handleClick={handleClick}
                      />
                    );
                  }
                })
            : null}
        </ul>
      </div>
    </div>
  );
};

export default Tags;
