import axios from "axios";
import { convert } from "html-to-text";
import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ImLocation2 } from "react-icons/im";
import { RiCalendarTodoFill } from "react-icons/ri";
import useGeoLocation from "react-ipgeolocation";
import { MapContainer } from "react-leaflet/MapContainer";
import { Marker } from "react-leaflet/Marker";
import { Popup } from "react-leaflet/Popup";
import { TileLayer } from "react-leaflet/TileLayer";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocal } from "../../App";
import { geteventDataById } from "../../Redux/EventsReducer";
import noImage from "../../assets/noImage.jpg";
import GalleryContainer from "../../sharedComponents/CardGallery";
import Loader from "../../sharedComponents/Loader";
import { formatDate, useCustomMediaQuery } from "../../utils";
import "./style.scss";
// import { fetchLatandLong } from "../../API/Api";
import { IoMdContact } from "react-icons/io";
import {
  checkUserEventRegister,
  fetchLatandLong,
  getSubscriptioncheckoutUrl,
} from "../../API/Api";
import FollowUpEvents from "../FollowUpEvents/FollowUpEvents";
import { TitleHeader } from "../Title";

import { useRef } from "react";
import { useIntl } from "react-intl";
import { Oval } from "react-loader-spinner";
import ContactAdminModal from "../Modal/ContactAdminModal";
import EventRegisterModal from "../Modal/EventRegisterModal";
import SelectFlowModal from "../Modal/SelectFlowModal";
import { errorToast, successToast } from "../Toast/Toasts";
import { EventProduct, FreeEventData } from "./EventProduct";

// import { errorToast, successToast } from "../Alerts/ToastifyAlerts";

const EventsDetailsPageWithoutSidebar = () => {
  const intl = useIntl();
  const local = useLocal();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const geoLocation = useGeoLocation();
  // const largeScreen = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const smallScreen = useMediaQuery({ maxWidth: 575 });
  // const mediumScreen = useMediaQuery({ minWidth: 576, maxWidth: 767 });
  const vlgScreen = useMediaQuery({ minWidth: 992 });
  const { resolution } = useCustomMediaQuery();
  const [sideBar, setsideBar] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [invalidLocation, setInvalidLocation] = useState(false);
  const [position, setPosition] = useState([51.505, -0.09]);
  const [loading, setLoading] = useState(false);
  const [loadingMap, setLoadingMap] = useState(false);
  const [registrationLoading, setregistrationLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [isRegistered, setIsRegistered] = useState(0);
  // const [singleLicenseProduct, setsingleLicenseProduct] = useState({});
  const [SoldOut, setSoldOut] = useState(false);
  const [showProductModal, setshowProductModal] = useState(false);
  const [showFlowModal, setshowFlowModal] = useState(false);
  const [PaidEvent, setPaidEvent] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [flow, setflow] = useState("products");
  const [arrivingFlag, setArrivingFlag] = useState(false);
  //const [disabled, setdisabled] = useState(false);

  const productRef = useRef(null);

  const handleShow = (func) => func(true);

  const user = useSelector((state) => state.auth?.user);

  const today = moment().toDate();
  const events = useSelector((state) => state.events.events);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  // const productsBought = useSelector((state) => state.auth?.products);
  // const sysAdmin = useSelector(
  //   (state) => state.auth?.user?.personalInformation?.sys_admin
  // );
  const freeLegalTexts = useSelector(
    (state) =>
      state?.generalDetails?.generalDetails.events_general_settings
        ?.free_events_registrations_legal_texts
  );

  const isLoading = useSelector((state) => state?.events?.isLoading);
  const eventId = location.state?.eventId || id;
  // useEffect(() => {
  //   if (events?.[eventId]?.products && events?.[eventId]?.products.length > 0) {
  //     const product = events?.[eventId]?.products.filter(
  //       (ele) => ele?.type === "single_event"
  //     );
  //     if (product && product.length > 0) {
  //       setsingleLicenseProduct(product[0]);
  //     }
  //   }
  // }, [events]);
  useEffect(() => {
    if (!eventId) {
      navigate("/404");
    }
    if (user && user.personalInformation) {
      checkUserRegistrationAPI();
    }
  }, []);
  useEffect(() => {
    setIsRegistered(!!events?.[eventId]?.is_registered);
    if (events?.[eventId]) {
      if (events?.[eventId]?.registration_end_date) {
        const bool = moment(today).isBefore(
          events?.[eventId]?.registration_end_date
        );
        setSoldOut(!bool);
      }
      if (events?.[eventId]?.registration_start_date) {
        const bool = moment(today).isBefore(
          events?.[eventId]?.registration_start_date
        );
        setArrivingFlag(bool);
      }
      if (moment(today).isAfter(events?.[eventId]?.start_date)) {
        setSoldOut(true);
      }
      if (
        events?.[eventId]?.event_mode_id === 2 ||
        events?.[eventId]?.event_mode_id === 4
      ) {
        setPaidEvent(true);
      }
    }
  }, [events?.[eventId]]);

  const calculateDaysOrHours = () => {
    if (
      moment(today).diff(events?.[eventId]?.registration_start_date, "days") < 0
    ) {
      return `${intl.formatMessage({
        id: "SHARED_COMPONENTS_EVENTS_BOOKING_OPENS_IN",
      })} ${moment(events?.[eventId]?.registration_start_date).diff(
        today,
        "days"
      )} ${intl.formatMessage({ id: "SHARED_COMPONENTS_EVENTS_DAYS" })}`;
    } else {
      // let duration = moment.duration(events?.[eventId]?.start_date)
      return `${intl.formatMessage({
        id: "SHARED_COMPONENTS_EVENTS_BOOKING_OPENS_IN",
      })} ${moment(events?.[eventId]?.registration_start_date).diff(
        today,
        "hours"
      )} ${intl.formatMessage({ id: "SHARED_COMPONENTS_EVENTS_HOURS" })}`;
    }
  };

  const hideProducts = () => {
    if (events?.[eventId]?.capacity_remaining === 0) {
      return true;
    } else if (isRegistered === 1 || events?.[eventId]?.is_registered === 1) {
      return true;
    } else if (
      //join event condition
      events?.[eventId]?.is_registered === 0 &&
      events?.[eventId]?.join_request === 1
    ) {
      return true;
    } else if (moment(today).isAfter(events?.[eventId]?.start_date)) {
      return true;
    } else if (
      events?.[eventId]?.event_access_id === 1 &&
      events?.[eventId]?.event_mode_id === 1 &&
      events?.[eventId]?.event_type_id === 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleRegistationDisable = () => {
    if (events?.[eventId]?.capacity_remaining === 0) {
      return true;
    } else if (isRegistered === 1 || events?.[eventId]?.is_registered === 1) {
      return true;
    } else if (moment(today).isAfter(events?.[eventId]?.start_date)) {
      return true;
    } else if (
      (events?.[eventId]?.event_mode_id === 2 ||
        events?.[eventId]?.event_mode_id === 4) &&
      events?.[eventId]?.products.length === 0 &&
      events?.[eventId]?.subscriptions.length === 0
    ) {
      return true;
    } else if (events?.[eventId]?.registration_end_date) {
      if (events?.[eventId]?.registration_start_date) {
        const bool = moment(today).isBetween(
          events?.[eventId]?.registration_start_date,

          events?.[eventId]?.registration_end_date
        );

        return !bool;
      } else {
        const bool = moment(today).isBefore(
          events?.[eventId]?.registration_end_date
        );

        return !bool;
      }
    } else if (events?.[eventId]?.registration_start_date) {
      const bool = moment(today).isAfter(
        events?.[eventId]?.registration_start_date
      );
      return !bool;
    }
  };
  const checkUserRegistrationAPI = () => {
    setLoading(true);
    checkUserEventRegister(eventId, user?.personalInformation?.id)
      .then(({ data: { eventDetails } }) => {
        setIsRegistered(eventDetails?.is_registered);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const eventRegistrationAPI = (paramsObj) => {
    // setLoading(true)
    if (isAuthorized && !showModal) {
      setregistrationLoading(true);
    }
    const paramsObject = {
      contact_id: user?.personalInformation?.id || paramsObj?.contact_id,
      salutation_id:
        user?.personalInformation?.salutation_id ||
        paramsObj?.salutation_id ||
        null,
      firstname:
        user?.personalInformation?.firstname || paramsObj?.first_name || null,
      lastname:
        user?.personalInformation?.lastname || paramsObj?.last_name || null,
      email: user?.personalInformation?.email || paramsObj?.email || null,
      product_id: paramsObj?.product_id || null,
      country_code: geoLocation?.country,
      // eventId: paramsObj?.eventId || eventId,
      ...paramsObj,
    };
    return new Promise((resolve, reject) => {
      if (flow === "subscription") {
        getSubscriptioncheckoutUrl(eventId, paramsObject).then((response) => {
          setregistrationLoading(false);
          if (response.status == 200) {
            if (
              (events?.[eventId]?.event_mode_id === 2 ||
                events?.[eventId]?.event_mode_id === 4) &&
              response?.data?.stripeProductDetails?.url
            ) {
              window.location.href = response?.data?.stripeProductDetails?.url;
            } else {
              successToast(
                intl.formatMessage({
                  id: "SHARED_COMPONENTS_EVENTS_SUCCESS_TOAST",
                })
              );
              if (user && user.personalInformation) {
                checkUserRegistrationAPI();
              }
            }
            resolve(
              intl.formatMessage({ id: "SHARED_COMPONENTS_EVENTS_SUCCESS" })
            );
          } else {
            reject(
              intl.formatMessage({
                id: "SHARED_COMPONENTS_EVENTS_CANT_REGISTER",
              })
            );
          }
        });
      } else {
        try {
          axios({
            method: "post",
            url:
              flow === "products"
                ? `/events/register/${eventId}`
                : `/events/register/offers/${eventId}`,
            data: paramsObject,
            // headers: { "Content-Type": "multipart/form-data" },
            responseType: flow !== "products" ? "blob" : "JSON",
          })
            .then((response) => {
              const {
                data: { stripeProductDetails = {} },
              } = response;
              setregistrationLoading(false);
              if (flow !== "products") {
                successToast(
                  intl.formatMessage({ id: "OFFER_INVOICE_DOWNLOAD_MESSAGE" })
                );
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.pdf");
                document.body.appendChild(link);
                link.click();
              } else {
                if (
                  (events?.[eventId]?.event_mode_id === 2 ||
                    events?.[eventId]?.event_mode_id === 4) &&
                  stripeProductDetails?.url
                ) {
                  window.location.href = stripeProductDetails?.url;
                } else {
                  successToast(
                    intl.formatMessage({
                      id: "SHARED_COMPONENTS_EVENTS_SUCCESS_TOAST",
                    })
                  );
                  if (user && user.personalInformation) {
                    checkUserRegistrationAPI();
                  }
                }
              }
              resolve(
                intl.formatMessage({ id: "SHARED_COMPONENTS_EVENTS_SUCCESS" })
              );
            })
            .catch(
              ({
                response: {
                  data: { message, status },
                },
              }) => {
                setregistrationLoading(false);
                if (flow !== "products") {
                  reject(
                    intl.formatMessage({
                      id: "SHARED_COMPONENTS_EVENTS_CANT_REGISTER",
                    })
                  );
                }
                if (status === "Error") {
                  if (isAuthorized && !showModal) {
                    errorToast(
                      message
                        ? message
                        : intl.formatMessage({
                            id: "SHARED_COMPONENTS_EVENTS_CANT_REGISTER",
                          })
                    );
                  } else {
                    reject(
                      message
                        ? message
                        : intl.formatMessage({
                            id: "SHARED_COMPONENTS_EVENTS_CANT_REGISTER",
                          })
                    );
                  }
                } else if (status === "Warning") {
                  if (isAuthorized && !showModal) {
                    errorToast(
                      message
                        ? message
                        : intl.formatMessage({
                            id: "SHARED_COMPONENTS_EVENTS_USER_ALREADY_REGISTERED",
                          })
                    );
                  } else {
                    reject(
                      message
                        ? message
                        : intl.formatMessage({
                            id: "SHARED_COMPONENTS_EVENTS_USER_ALREADY_REGISTERED",
                          })
                    );
                  }
                }
              }
            )
            .finally(() => {});
        } catch (error) {
          setregistrationLoading(false);
          throw error;
        }
      }
    });
  };

  useEffect(() => {
    if (!Object.keys(events).includes(eventId)) {
      if (user && user?.personalInformation?.id) {
        dispatch(geteventDataById(eventId, user?.personalInformation?.id));
      } else {
        dispatch(geteventDataById(eventId));
      }
    }
  }, [eventId]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  const setPostMediaSource = () => {
    if (
      events?.[eventId]?.event_image_resolutions &&
      events?.[eventId]?.event_image_resolutions.length > 0
    ) {
      const source = events?.[eventId]?.event_image_resolutions.filter(
        (element) => element?.resolution === resolution
      );
      return source[0].file_url;
    }
    return events?.[eventId]?.event_image_url
      ? events?.[eventId]?.event_image_url
      : noImage;
  };

  const setContactMediaSource = (event) => {
    if (
      event?.contact_person_image_resolutions &&
      event?.contact_person_image_resolutions.length > 0
    ) {
      const source = event?.contact_person_image_resolutions.filter(
        (element) => element?.resolution === "256x144"
      );
      return source[0].file_url;
    }
    return event?.contact_person_image_url
      ? event?.contact_person_image_url
      : noImage;
  };

  const formatAddress = () => {
    let address_1 =
      events?.[eventId]?.address_1 === null
        ? ""
        : `${events?.[eventId]?.address_1} `;
    let address_2 =
      events?.[eventId]?.address_2 === null
        ? ""
        : `${events?.[eventId]?.address_2} `;
    let city =
      events?.[eventId]?.city === null ? "" : `${events?.[eventId]?.city} `;
    let country_name =
      events?.[eventId]?.county_name === null
        ? ""
        : `${events?.[eventId]?.county_name} `;
    let zip_code =
      events?.[eventId]?.zip_code === null
        ? ""
        : `${events?.[eventId]?.zip_code} `;

    // if((address_1 + address_2 + city + country_name + zip_code) === ''){
    //     setShowMap(false)
    // }

    return address_1 + address_2 + city + zip_code + country_name;
  };

  const getLatandLong = () => {
    setLoadingMap(true);
    const address = formatAddress();

    //this condition is given to mitigate initial render before
    //event details are updated
    if (address === "undefined undefined undefined undefined undefined") {
      return;
    }

    fetchLatandLong(address)
      .then(({ lat, lon }) => {
        setPosition([Number(lat), Number(lon)]);
        if (
          events?.[eventId]?.address_1 === null &&
          events?.[eventId]?.address_2 === null
        ) {
          setInvalidLocation(true);
        }
      })
      .catch(() => {
        setInvalidLocation(true);
      })
      .finally(() => {
        // console.log(position);
        setLoadingMap(false);
        // setMapLoading(false);
        if (address !== "") {
          setShowMap(true);
        }
      });
  };

  const contactDetails = () => {
    if (
      events?.[eventId]?.contact_person_name === null &&
      events?.[eventId]?.contact_person_email === null &&
      events?.[eventId]?.contact_person_phone === null
    ) {
      return false;
    }
    return true;
  };

  const addressDetails = () => {
    if (
      events?.[eventId]?.address_1 === null &&
      events?.[eventId]?.address_2 === null &&
      events?.[eventId]?.zip_code === null &&
      events?.[eventId]?.event_type_id !== 1 &&
      events?.[eventId]?.county_name === null
    ) {
      return false;
    }
    return true;
  };
  const handleButtonClick = () =>
    window.scroll(0, productRef.current.offsetTop - 60);

  const handleRegisterClick = () => {
    user?.personalInformation?.id && !PaidEvent && freeLegalTexts.length === 0
      ? // (isAuthorized && PaidEvent && events?.[eventId]?.products.length === 0)
        eventRegistrationAPI({ product_id: null })
      : PaidEvent && events?.[eventId]?.products.length > 0
      ? handleShow(setshowProductModal)
      : handleShow(setShowModal);
  };
  // const hostDetails = () => {
  //   if (
  //     events?.[eventId]?.partner_details?.host_name === null &&
  //     events?.[eventId]?.partner_details?.host_email === null
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };
  const PostDescription = () => {
    return (
      <>
        <div
          className="event-description-one"
          dangerouslySetInnerHTML={{ __html: events?.[eventId]?.description }}
        >
          {/* {formatString(posts?.[eventId]?.description)} */}
        </div>

        {events?.[eventId]?.galleries?.length > 0 ? (
          <div>
            <GalleryContainer
              sideBar={sideBar}
              galleries={events?.[eventId]?.galleries}
            />
          </div>
        ) : null}
      </>
    );
  };
  const returnScript = () => {
    return JSON.stringify([
      {
        "@context": "https://schema.org",
        "@type": "Event",
        name: events?.[eventId]?.title,
        startDate: events?.[eventId]?.start_date,
        endDate: events?.[eventId]?.end,
        eventAttendanceMode:
          events?.[eventId]?.event_type_id === 1
            ? "https://schema.org/OnlineEventAttendanceMode"
            : "https://schema.org/OfflineEventAttendanceMode",
        eventStatus: "https://schema.org/EventScheduled",
        location:
          events?.[eventId]?.event_type_id === 1
            ? {
                "@type": "VirtualLocation",
                url: events?.[eventId]?.meeting_url,
              }
            : {
                "@type": "Place",
                name: "",
                address: {
                  "@type": "PostalAddress",
                  streetAddress: events?.[eventId]?.address_1
                    ? events?.[eventId]?.address_1
                    : events?.[eventId]?.address_2,
                  addressLocality: events?.[eventId]?.city,
                  postalCode: events?.[eventId]?.zip_code,
                  addressRegion: "",
                  addressCountry: events?.[eventId]?.county_name,
                },
              },
        image: events?.[eventId]?.event_image_url,
        description: convert(events?.[eventId]?.description),
        offers:
          events?.[eventId]?.products.length > 0
            ? events?.[eventId]?.products.map((product) => {
                return {
                  "@type": "Offer",
                  name: product.name,
                  price: product.price / 100,
                  priceCurrency: "EUR",
                  validFrom: "",
                  url: "",
                  availability: "",
                };
              })
            : [],
        // performer: {
        //   "@type": "PerformingGroup",
        //   name: "Kira and Morrison",
        // },
        organizer: {
          "@type": "Organization",
          name: events?.[eventId]?.contact_person_name,
          url: events?.[eventId]?.contact_person_image_url,
        },
      },
    ]);
  };
  return (
    <>
      <Helmet>
        <title>{events?.[eventId]?.seo_tag}</title>
        <script type="application/ld+json">{returnScript()}</script>
      </Helmet>
      <div className="pb-4">
        {isLoading === true || loading === true ? (
          <Loader />
        ) : (
          <>
            <SelectFlowModal
              show={showFlowModal}
              setflow={setflow}
              // setshowProductModal={setshowProductModal}
              setEventRegsiterModal={setShowModal}
              closeModal={() => setshowFlowModal(false)}
              showEventRegisterModal={showModal}
              //showProductModal={showProductModal}
            />
            {/* <EventProductsModal
              show={showProductModal}
              products={events?.[eventId]?.products}
              closeModal={() => setshowProductModal(false)}
              setshowFlowModal={setshowFlowModal}
              setShowEventRegisterModal={setShowModal}
              setProductDetails={setProductDetails}
              showEventRegisterModal={showModal}
              eventRegistrationAPI={eventRegistrationAPI}
              isAuthorized={isAuthorized}
              showFlowModal={showFlowModal}
              remainingCapacity={events?.[eventId]?.capacity_remaining}
            /> */}
            {showModal && (
              <EventRegisterModal
                contactId={user?.personalInformation?.id}
                show={showModal}
                legalTexts={
                  events?.[eventId]?.event_mode_id === 1 ||
                  events?.[eventId]?.event_mode_id === 3
                    ? freeLegalTexts
                    : productDetails?.legal_texts
                }
                eventId={eventId}
                productId={productDetails?.id}
                setshowErrorModal={setshowErrorModal}
                closeModal={() => {
                  setShowModal(false);
                  setshowFlowModal(false);
                }}
                eventRegistrationAPI={eventRegistrationAPI}
                productDetails={productDetails}
                flow={flow}
              />
            )}

            {showErrorModal && (
              <ContactAdminModal
                setshowErrorModal={setshowErrorModal}
                show={showErrorModal}
              />
            )}
            <div className="container">
              <div className="row">
                <div className={sideBar ? `col-lg-8` : "col-lg-12"}>
                  <div className="mt-5 mb-4 title_container">
                    <h1 style={{ textAlign: "start" }}>
                      {events?.[eventId]?.title}
                    </h1>
                    <div
                      style={{ fontSize: "18px" }}
                      dangerouslySetInnerHTML={{
                        __html: events?.[eventId]?.sub_title,
                      }}
                    >
                      {/* {formatString(posts?.[eventId]?.introduction)} */}
                    </div>
                  </div>
                  {/* <hr
                  className="my-3"
                  style={{
                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                    maxWidth: "750px",
                    margin: "auto",
                  }}
                ></hr> */}
                  {/* <ul
                  className="list-inline d-flex justify-content-start align-items-center"
                  //   className={`${
                  //     posts?.[eventId] &&
                  //     posts?.[eventId].products &&
                  //     Object.keys(posts?.[eventId].products).length > 0
                  //       ? "buyPage list-inline d-flex justify-content-between align-items-center"
                  //       : "list-inline d-flex justify-content-between align-items-center"
                  //   }`}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: "750px",
                  }}
                >
                  <li
                    className="list-inline-item"
                    style={{ marginLeft: "10px" }}
                  >
                    <Author authors={events?.[eventId]?.authors} />
                  </li>
                  <li
                    className="list-inline-item"
                    style={{ verticalAlign: "top" }}
                  >
                    <span
                      className="text-dark text-capitalize fw-semibold d-flex justify-content-center align-items-center"
                      style={{ fontSize: "14px" }}
                    >
                      <RiCalendarTodoFill className="icon-calendar" />
                      {local && local === "en"
                        ? formatDate(events?.[eventId]?.start_date)
                        : moment(events?.[eventId]?.start_date).format(
                            "DD.MM.yyyy"
                          )}
                    </span>
                  </li>
                </ul> */}
                  {/* <div> */}
                  <div
                    className="row event-page-alignment mx-auto"
                    style={{
                      marginTop: "30px",
                      flex: "1",
                      height: "fit-content",
                      maxWidth: "750px",
                    }}
                  >
                    {moment(events?.[eventId]?.start_date).isSame(
                      events?.[eventId]?.end_date,
                      "date"
                    ) ? (
                      <div
                        className="d-flex justify-content-md-start"
                        style={{ flex: "1" }}
                      >
                        <fieldset className="border p-2 w-100">
                          <legend
                            className="float-none w-auto px-2  text-muted"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {intl.formatMessage({
                              id: "EVENT_DETAILS_FIELDSET_LEGEND_TITLE",
                            })}
                          </legend>
                          <div className="d-flex flex-column gap-3">
                            <div
                              className="d-flex flex-row"
                              style={{ fontSize: "1rem" }}
                            >
                              <div>
                                <span>
                                  <RiCalendarTodoFill className="event-icons" />
                                </span>
                              </div>
                              <div className="d-flex flex-column">
                                <div className="d-flex align-items-center gap-2">
                                  <div>
                                    {local && local === "en"
                                      ? formatDate(
                                          events?.[eventId]?.start_date
                                        )
                                      : moment(
                                          events?.[eventId]?.start_date
                                        ).format("DD.MM.yyyy")}
                                  </div>
                                  <div
                                    style={{
                                      height: "14px",
                                      width: "2px",
                                      backgroundColor: "silver",
                                    }}
                                  ></div>
                                  <div style={{ fontSize: "1rem" }}>
                                    {local && local === "en"
                                      ? moment(
                                          events?.[eventId]?.start_date
                                        ).format("hh:mm a")
                                      : moment(
                                          events?.[eventId]?.start_date
                                        ).format("HH:mm")}
                                    {" - "}
                                    {local && local === "en"
                                      ? moment(
                                          events?.[eventId]?.end_date
                                        ).format("hh:mm a")
                                      : moment(
                                          events?.[eventId]?.end_date
                                        ).format("HH:mm")}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {addressDetails() === true ? (
                              <>
                                <div className=" d-none">
                                  <hr
                                    style={{
                                      border: "0",
                                      width: "1px",
                                      height: "75px",
                                      color: "var(--accent-color)",
                                    }}
                                  />
                                </div>
                                <div className="d-flex pe-md-5 location-top">
                                  <span>
                                    <ImLocation2 className="event-icons" />
                                  </span>
                                  {events?.[eventId]?.event_type_id === 1 ? (
                                    <div>
                                      <div
                                        style={{
                                          fontSize: "1rem",
                                        }}
                                      >
                                        {intl.formatMessage({
                                          id: "EVENTS_ADDRESS_ONLINE_DETAILS",
                                        })}
                                      </div>
                                      {events?.[eventId]?.meeting_url ===
                                        null &&
                                      events?.[eventId]?.meeting_url_code ===
                                        null ? (
                                        <div>
                                          {intl.formatMessage({
                                            id: "EVENT_DETAILS_COMING_SOON_MESSAGE",
                                          })}
                                        </div>
                                      ) : null}
                                      {events?.[eventId]?.meeting_url !==
                                      null ? (
                                        <a
                                          href={events?.[eventId]?.meeting_url}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <div style={{ fontSize: "1rem" }}>
                                            {
                                              events?.[eventId]
                                                ?.meeting_url_name
                                            }
                                          </div>
                                        </a>
                                      ) : null}
                                      {events?.[eventId]?.meeting_url_code !==
                                      null ? (
                                        <div>
                                          {intl.formatMessage({
                                            id: "EVENT_CODE_TITLE",
                                          })}
                                          :{" "}
                                          {events?.[eventId]?.meeting_url_code}
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : (
                                    <div className="d-flex flex-column flex-wrap">
                                      {/* <h5 style={{ fontSize: "1.3rem" }}>Location</h5> */}
                                      <div
                                        className="mb-0"
                                        style={{ fontSize: "1rem" }}
                                      >{`${
                                        events?.[eventId]?.address_1 !== null
                                          ? events?.[eventId]?.address_1
                                          : ""
                                      } ${
                                        events?.[eventId]?.address_2 !== null
                                          ? events?.[eventId]?.address_2
                                          : ""
                                      }`}</div>
                                      <div className="d-flex flex-wrap">
                                        <div
                                          className="mb-0"
                                          style={{ fontSize: "1rem" }}
                                        >
                                          {`${
                                            events?.[eventId]?.zip_code !== null
                                              ? events?.[eventId]?.zip_code +
                                                ","
                                              : ""
                                          } ${
                                            events?.[eventId]?.city !== null
                                              ? events?.[eventId]?.city
                                              : ""
                                          }`}
                                          ,
                                        </div>
                                        <div
                                          className="mb-0"
                                          style={{ fontSize: "1rem" }}
                                        >{`${
                                          events?.[eventId]?.county_name
                                            ? events?.[eventId]?.county_name
                                            : ""
                                        }`}</div>
                                      </div>
                                      <button
                                        style={{ textAlign: "left" }}
                                        className="map-toggle-button"
                                        onClick={() => {
                                          showMap === true
                                            ? setShowMap(false)
                                            : getLatandLong();
                                        }}
                                      >
                                        {showMap
                                          ? intl.formatMessage({
                                              id: "SHARED_COMPONENTS_EVENTS_HIDE_MAP",
                                            })
                                          : intl.formatMessage({
                                              id: "SHARED_COMPONENTS_EVENTS_SHOW_MAP",
                                            })}
                                        {/* Show map */}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <div className=" d-flex flex-column">
                                <div className="text-secondary d-flex">
                                  <span>
                                    <ImLocation2 className="event-icons" />
                                  </span>
                                  <p>
                                    {intl.formatMessage({
                                      id: "EVENTS_ADDRESS_LOCATION_DETAILS",
                                    })}
                                  </p>
                                </div>
                                <div>
                                  {intl.formatMessage({
                                    id: "EVENT_DETAILS_COMING_SOON_MESSAGE",
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </fieldset>
                      </div>
                    ) : (
                      <div
                        className="d-flex justify-content-md-start"
                        style={{ flex: "1" }}
                      >
                        <fieldset className="border p-2 w-100 ">
                          <legend
                            className="float-none w-auto px-2  text-muted"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {intl.formatMessage({
                              id: "EVENT_DETAILS_FIELDSET_LEGEND_TITLE",
                            })}
                          </legend>
                          <div className="d-flex flex-column gap-3">
                            <div
                              className="d-flex flex-row"
                              style={{ fontSize: "1rem" }}
                            >
                              <div>
                                <span>
                                  <RiCalendarTodoFill className="event-icons" />
                                </span>
                              </div>
                              <div className="d-flex flex-column">
                                <div className="d-flex align-items-center gap-2">
                                  <div>
                                    {local && local === "en"
                                      ? formatDate(
                                          events?.[eventId]?.start_date
                                        )
                                      : moment(
                                          events?.[eventId]?.start_date
                                        ).format("DD.MM.yyyy ")}
                                  </div>
                                  {local && local === "en" ? null : (
                                    <>
                                      <div
                                        style={{
                                          height: "14px",
                                          width: "2px",
                                          backgroundColor: "silver",
                                        }}
                                      ></div>
                                      <div>
                                        {local && local === "en"
                                          ? formatDate(
                                              events?.[eventId]?.start_date
                                            )
                                          : moment(
                                              events?.[eventId]?.start_date
                                            ).format("HH:mm")}
                                      </div>
                                    </>
                                  )}
                                  <div
                                  // className=" d-flex position-relative align-items-center"
                                  // style={{
                                  //   height: "5px",
                                  //   left: "40%",
                                  //   margin: "5px 0",
                                  // }}
                                  >
                                    -
                                  </div>
                                  <div>
                                    {local && local === "en"
                                      ? formatDate(events?.[eventId]?.end_date)
                                      : moment(
                                          events?.[eventId]?.end_date
                                        ).format("DD.MM.yyyy ")}
                                  </div>
                                  {local && local === "en" ? null : (
                                    <>
                                      <div
                                        style={{
                                          height: "14px",
                                          width: "2px",
                                          backgroundColor: "silver",
                                        }}
                                      ></div>
                                      <div>
                                        {local && local === "en"
                                          ? formatDate(
                                              events?.[eventId]?.end_date
                                            )
                                          : moment(
                                              events?.[eventId]?.end_date
                                            ).format(" HH:mm")}
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div
                                  className=""
                                  style={{
                                    fontSize: "0.8rem",
                                    color: "red",
                                    lineHeight: "1.2rem",
                                  }}
                                >
                                  {events?.[eventId]?.registration_start_date ||
                                  events?.[eventId]?.registration_end_date
                                    ? intl.formatMessage({
                                        id: "SHARED_COMPONENTS_EVENTS_REGISTRATION",
                                      })
                                    : ""}
                                  {`${
                                    events?.[eventId]?.registration_start_date
                                      ? `${intl.formatMessage({
                                          id: "SHARED_COMPONENTS_EVENTS_STARTS_FROM",
                                        })} ${
                                          local && local === "en"
                                            ? formatDate(
                                                events?.[eventId]
                                                  ?.registration_start_date
                                              )
                                            : moment(
                                                events?.[eventId]
                                                  ?.registration_start_date
                                              ).format("DD.MM.yyyy HH:mm")
                                        } `
                                      : ""
                                  }${
                                    events?.[eventId]?.registration_end_date
                                      ? `${intl.formatMessage({
                                          id: "SHARED_COMPONENTS_EVENTS_TILL",
                                        })} ${
                                          local && local === "en"
                                            ? formatDate(
                                                events?.[eventId]
                                                  ?.registration_end_date
                                              )
                                            : moment(
                                                events?.[eventId]
                                                  ?.registration_end_date
                                              ).format("DD.MM.yyyy HH:mm")
                                        }`
                                      : ""
                                  }`}
                                </div>
                              </div>
                            </div>
                            {addressDetails() === true ? (
                              <>
                                <div className=" d-none ">
                                  <hr
                                    style={{
                                      border: "0",
                                      width: "1px",
                                      height: "75px",
                                      color: "var(--accent-color)",
                                    }}
                                  />
                                </div>
                                <div className="d-flex pe-md-5 location-top">
                                  <span>
                                    <ImLocation2 className="event-icons" />
                                  </span>
                                  {events?.[eventId]?.event_type_id === 1 ? (
                                    <div>
                                      <div
                                        style={{
                                          fontSize: "1rem",
                                        }}
                                      >
                                        {intl.formatMessage({
                                          id: "EVENTS_ADDRESS_ONLINE_DETAILS",
                                        })}
                                      </div>
                                      {events?.[eventId]?.meeting_url ===
                                        null &&
                                      events?.[eventId]?.meeting_url_code ===
                                        null ? (
                                        <div>
                                          {intl.formatMessage({
                                            id: "EVENT_DETAILS_COMING_SOON_MESSAGE",
                                          })}
                                        </div>
                                      ) : null}
                                      {events?.[eventId]?.meeting_url !==
                                      null ? (
                                        <a
                                          href={events?.[eventId]?.meeting_url}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <div style={{ fontSize: "1rem" }}>
                                            {
                                              events?.[eventId]
                                                ?.meeting_url_name
                                            }
                                          </div>
                                        </a>
                                      ) : null}
                                      {events?.[eventId]?.meeting_url_code !==
                                      null ? (
                                        <div>
                                          {intl.formatMessage({
                                            id: "EVENT_CODE_TITLE",
                                          })}
                                          :{" "}
                                          {events?.[eventId]?.meeting_url_code}
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : (
                                    <div className="d-flex flex-column flex-wrap">
                                      {/* <h5 style={{ fontSize: "1.3rem" }}>Location</h5> */}
                                      <div
                                        className="mb-0"
                                        style={{ fontSize: "1rem" }}
                                      >
                                        {events?.[eventId]?.address_1 !==
                                          null &&
                                          `${events?.[eventId]?.address_1} ${
                                            events?.[eventId]?.address_2
                                              ? events?.[eventId]?.address_2
                                              : ""
                                          }`}
                                      </div>
                                      <div className="d-flex flex-wrap">
                                        <div
                                          className="mb-0"
                                          style={{ fontSize: "1rem" }}
                                        >
                                          {`${
                                            events?.[eventId]?.zip_code
                                              ? events?.[eventId]?.zip_code +
                                                ","
                                              : ""
                                          } ${
                                            events?.[eventId]?.city
                                              ? events?.[eventId]?.city + ","
                                              : ""
                                          }`}
                                        </div>
                                        <div
                                          className="mb-0"
                                          style={{ fontSize: "1rem" }}
                                        >{`${
                                          events?.[eventId]?.county_name
                                            ? events?.[eventId]?.county_name
                                            : ""
                                        }`}</div>
                                      </div>
                                      <button
                                        style={{ textAlign: "left" }}
                                        className="map-toggle-button"
                                        onClick={() => {
                                          showMap === true
                                            ? setShowMap(false)
                                            : getLatandLong();
                                        }}
                                      >
                                        {showMap
                                          ? intl.formatMessage({
                                              id: "SHARED_COMPONENTS_EVENTS_HIDE_MAP",
                                            })
                                          : intl.formatMessage({
                                              id: "SHARED_COMPONENTS_EVENTS_SHOW_MAP",
                                            })}
                                        {/* Show map */}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <div className=" d-flex flex-column">
                                <div className="text-secondary d-flex">
                                  <span>
                                    <ImLocation2 className="event-icons" />
                                  </span>
                                  <p>
                                    {intl.formatMessage({
                                      id: "EVENTS_ADDRESS_LOCATION_DETAILS",
                                    })}
                                  </p>
                                </div>
                                <div>
                                  {intl.formatMessage({
                                    id: "EVENT_DETAILS_COMING_SOON_MESSAGE",
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </fieldset>
                      </div>
                    )}
                    {contactDetails() === true ? (
                      <div
                        className={`d-flex justify-content-md-start`}
                        style={{ flex: "1", fontSize: "1rem" }}
                      >
                        <fieldset className="border p-2 w-100">
                          <legend
                            className="float-none w-auto px-2  text-muted"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {intl.formatMessage({
                              id: "EVENT_HOST_DETAILS_FIELDSET_LEGEND_TITLE",
                            })}
                          </legend>
                          <div className="">
                            <div className="d-flex flex-column">
                              {events?.[eventId]?.contact_person_image_url ? (
                                <div>
                                  <img
                                    style={{
                                      width: "auto",
                                      height: "30px",
                                      // borderRadius: "50%",
                                      marginBottom: "6px",
                                    }}
                                    className="mx-1 "
                                    src={
                                      events?.[eventId]
                                        ?.contact_person_image_url
                                    }
                                  />
                                </div>
                              ) : (
                                <IoMdContact className="event-icons" />
                              )}
                              <div className="ms-1">
                                <div>
                                  {events?.[eventId]?.contact_person_name ===
                                  null
                                    ? ""
                                    : `${events?.[eventId]?.contact_person_name}`}
                                </div>
                                <div>
                                  {events?.[eventId]?.contact_person_email ===
                                  null
                                    ? ""
                                    : `${events?.[eventId]?.contact_person_email}`}
                                </div>
                                <div>
                                  {events?.[eventId]?.contact_person_phone ===
                                  null
                                    ? ""
                                    : `${events?.[eventId]?.contact_person_phone}`}
                                </div>
                                <div
                                  style={{
                                    width: !smallScreen
                                      ? "max-content"
                                      : "auto",
                                  }}
                                >
                                  {events?.[eventId]?.contact_person_remarks ===
                                  null
                                    ? ""
                                    : `${events?.[eventId]?.contact_person_remarks}`}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={`row event-page-alignment mx-auto position-relative ${
                      loadingMap ? "map-loading-height w-100" : ""
                    }`}
                    style={{ maxWidth: "750px" }}
                  >
                    {loadingMap ? <Loader map={true} /> : null}
                    {showMap && invalidLocation ? (
                      <div className="map-message-wrapper">
                        <div className="map-message">
                          {intl.formatMessage({
                            id: "SHARED_COMPONENTS_EVENTS_MAP_ERROR_MESSAGE",
                          })}
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={`${
                        invalidLocation && showMap ? "map-blur-effect" : ""
                      } ${showMap ? "mt-3" : ""}`}
                    >
                      {showMap === true ? (
                        <MapContainer
                          center={position}
                          zoom={16}
                          scrollWheelZoom={false}
                        >
                          <TileLayer
                            attribution=""
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker
                            position={position}
                            icon={
                              new Icon({
                                iconUrl: markerIconPng,
                                iconSize: [25, 41],
                                iconAnchor: [12, 41],
                              })
                            }
                          >
                            <Popup>{events?.[eventId]?.title}</Popup>
                          </Marker>
                        </MapContainer>
                      ) : null}
                    </div>
                  </div>

                  <div
                    style={{
                      margin: "auto",
                      width: vlgScreen ? "90%" : "100%",
                      marginTop: "30px",
                    }}
                  >
                    <img
                      className="post-image rounded"
                      src={setPostMediaSource()}
                    />
                    <p style={{ fontSize: "14px" }}>
                      {events?.[eventId]?.event_image_copyright_text}
                    </p>
                  </div>
                  {/* </div> */}

                  {(events?.[eventId]?.event_access_id === 1 &&
                    events?.[eventId]?.event_mode_id === 1 &&
                    events?.[eventId]?.event_type_id === 1) ||
                  hideProducts() ? null : (
                    <div
                      className="row event-page-alignment mx-auto justify-content-center"
                      style={{ marginTop: "30px" }}
                    >
                      <button
                        className="btn btn-custom"
                        onClick={() => {
                          if (events?.[eventId]?.event_state === "External") {
                            window.open(
                              events?.[eventId]?.event_state_url,
                              "_blank"
                            );
                          } else {
                            handleButtonClick();
                          }
                        }}
                        style={{
                          width: "auto",
                          fontSize: "20px",
                          height: "50px",
                          display: "flex",
                          padding: "10px 20px",
                        }}
                        disabled={handleRegistationDisable()}
                      >
                        {!!isRegistered ||
                        !!events?.[eventId]?.is_registered ? (
                          intl.formatMessage({ id: "TEXT_REGISTERED" })
                        ) : events?.[eventId]?.capacity_remaining === 0 ||
                          SoldOut ? (
                          intl.formatMessage({ id: "TEXT_SOLD_OUT" })
                        ) : arrivingFlag ? (
                          calculateDaysOrHours()
                        ) : (
                          <>
                            <div>
                              {intl.formatMessage({
                                id: "BUTTON_REGISTER_NOW",
                              })}
                            </div>
                            {registrationLoading ? (
                              <Oval
                                height={15}
                                width={15}
                                color="white"
                                wrapperStyle={{
                                  display: "inline",
                                  marginLeft: "1rem",
                                  verticalAlign: "middle",
                                  padding: "0px",
                                }}
                                wrapperClass="py-0"
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="white"
                                strokeWidth={4}
                                strokeWidthSecondary={4}
                              />
                            ) : null}
                          </>
                        )}
                      </button>
                    </div>
                  )}

                  <PostDescription />

                  {/* {
                    events?.[eventId]?.state === 'External' ? null : */}
                  {hideProducts() ||
                  events?.[eventId]?.event_state === "External" ? null : (
                    <div
                      ref={productRef}
                      // hidden={events?.[eventId].state === 'External'}
                      style={{
                        // marginTop: "30px",
                        maxWidth: "750px",
                      }}
                      className="row event-page-alignment mx-auto"
                    >
                      {events?.[eventId]?.products?.length > 0 ||
                      events?.[eventId]?.event_mode_id === 1 ||
                      events?.[eventId]?.event_mode_id === 3 ? (
                        <>
                          <TitleHeader
                            title={intl.formatMessage({
                              id:
                                events?.[eventId]?.event_mode_id === 2 ||
                                events?.[eventId]?.event_mode_id === 4
                                  ? "SHARED_COMPONENTS_BUY_PRODUCT"
                                  : "SHARED_COMPONENTS_JOIN_EVENT",
                            })}
                            className="px-0"
                            capacity={
                              events?.[eventId]?.event_access_id === 1 &&
                              events?.[eventId]?.event_type_id === 1
                                ? null
                                : events?.[eventId]?.maximum_capacity
                            }
                            header_navigation_id={null}
                          />
                          {events?.[eventId]?.event_mode_id === 2 ||
                          events?.[eventId]?.event_mode_id === 4 ? (
                            <EventProduct
                              products={events?.[eventId]?.products}
                              setShowEventRegisterModal={setShowModal}
                              setProductDetails={setProductDetails}
                              showEventRegisterModal={showModal}
                              remainingCapacity={
                                events?.[eventId]?.capacity_remaining
                              }
                              freeEvent={false}
                              handleRegisterClick={handleRegisterClick}
                              handleRegistationDisable={
                                handleRegistationDisable
                              }
                              setshowFlowModal={setshowFlowModal}
                            />
                          ) : (
                            <EventProduct
                              products={[FreeEventData]}
                              setShowEventRegisterModal={setShowModal}
                              setProductDetails={setProductDetails}
                              showEventRegisterModal={showModal}
                              remainingCapacity={
                                events?.[eventId]?.capacity_remaining
                              }
                              freeEvent={true}
                              handleRegisterClick={handleRegisterClick}
                              handleRegistationDisable={
                                handleRegistationDisable
                              }
                              setshowFlowModal={setshowFlowModal}
                              subscription={false}
                            />
                          )}
                        </>
                      ) : null}
                      {!(
                        events?.[eventId]?.event_mode_id === 1 ||
                        events?.[eventId]?.event_mode_id === 3
                      ) &&
                      events?.[eventId]?.subscriptions &&
                      events?.[eventId]?.subscriptions.length > 0 ? (
                        <>
                          <TitleHeader
                            title={intl.formatMessage({
                              id: "SHARED_COMPONENTS_BUY_SUBSCRIPTION",
                            })}
                            className="px-0"
                            header_navigation_id={null}
                          />
                          <EventProduct
                            products={events?.[eventId]?.subscriptions}
                            setShowEventRegisterModal={setShowModal}
                            setProductDetails={setProductDetails}
                            showEventRegisterModal={showModal}
                            remainingCapacity={
                              events?.[eventId]?.capacity_remaining
                            }
                            freeEvent={
                              events?.[eventId]?.event_mode_id === 1 ||
                              events?.[eventId]?.event_mode_id === 3
                            }
                            handleRegisterClick={handleRegisterClick}
                            handleRegistationDisable={handleRegistationDisable}
                            subscription={true}
                            setflow={setflow}
                          />
                        </>
                      ) : null}
                    </div>
                  )}
                  {/* } */}

                  {events?.[eventId]?.follow_up_events?.length > 0 ? (
                    <div
                      // style={{ marginTop: "30px" }}
                      className="row event-page-alignment mx-auto"
                    >
                      <FollowUpEvents
                        Id={eventId}
                        sideBar={sideBar}
                        followUpEvents={events?.[eventId]?.follow_up_events}
                        event
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EventsDetailsPageWithoutSidebar;
