export const GET_CHECKOUT_URL = "GET_CHECKOUT_URL";
export const SET_CHECKOUT_URL_SUCCESS = "SET_CHECKOUT_URL_SUCCESS";
export const SET_CHECKOUT_URL_FAIL = "SET_CHECKOUT_URL_FAIL";

const initialState = {
  isLoading: true,
  error: {},
};

export const getCheckoutURL = ({ paramsObject, productId }) => {
  return {
    type: GET_CHECKOUT_URL,
    payload: { paramsObject, productId },
  };
};

export const setCheckoutURLSuccess = (data) => {
  return {
    type: SET_CHECKOUT_URL_SUCCESS,
    payload: data,
  };
};

export const setCheckoutURLFail = (error) => {
  return {
    type: SET_CHECKOUT_URL_FAIL,
    payload: error,
  };
};

const PaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHECKOUT_URL: {
      return { ...state, isLoading: true };
    }
    case SET_CHECKOUT_URL_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload };
    }
    case SET_CHECKOUT_URL_FAIL: {
      return { ...state, isLoading: false, error: action.payload };
    }
    default:
      return { ...state };
  }
};

export default PaymentsReducer;
