export const GET_MASTER_DATA_BY_TYPE = "GET_MASTER_DATA_BY_TYPE";
export const GET_MASTER_DATA_BY_TYPE_SUCCESS =
  "GET_MASTER_DATA_BY_TYPE_SUCCESS";
export const GET_MASTER_DATA_BY_TYPE_FAIL = "GET_MASTER_DATA_BY_TYPE_FAIL";
export const GET_MASTER_DATA_DETAILS = "GET_MASTER_DATA_DETAILS";
export const GET_MASTER_DATA_DETAILS_SUCCESS =
  "GET_MASTER_DATA_DETAILS_SUCCESS";
export const GET_MASTER_DATA_DETAILS_FAIL = "GET_MASTER_DATA_DETAILS_FAIL";
export const SET_COUNTRIES_LIST = "SET_COUNTRIES_LIST";
export const SET_CONATCTTYPES_LIST = "SET_CONATCTTYPES_LIST";

const initialState = {
  error: { messages: "" },
  isLoading: true,
  countriesList: [],
  contactTypesList: [],
};

export const getMasterDataByType = ({ listType, pageName }) => {
  return { type: GET_MASTER_DATA_BY_TYPE, payload: { listType, pageName } };
};

export const getMasterDataByTypeSuccess = (data) => {
  return { type: GET_MASTER_DATA_BY_TYPE_SUCCESS, payload: data };
};

export const getMasterDataByTypeFail = (error) => {
  return { type: GET_MASTER_DATA_BY_TYPE_FAIL, payload: error };
};

export const getMasterDataDetails = ({ masterDataType, id, pageName }) => {
  return {
    type: GET_MASTER_DATA_DETAILS,
    payload: { masterDataType, id, pageName },
  };
};

export const getMasterDataDetailsSuccess = (data) => {
  return { type: GET_MASTER_DATA_DETAILS_SUCCESS, payload: data };
};

export const getMasterDataDetailsFail = (error) => {
  return { type: GET_MASTER_DATA_DETAILS_FAIL, payload: error };
};

export const setCountriesList = (countriesList) => {
  return {
    type: SET_COUNTRIES_LIST,
    payload: { countriesList },
  };
};

export const setContactTypesList = (contactTypesList) => {
  return {
    type: SET_CONATCTTYPES_LIST,
    payload: { contactTypesList },
  };
};

const MasterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MASTER_DATA_BY_TYPE: {
      return { ...state, isLoading: true };
    }
    case GET_MASTER_DATA_BY_TYPE_SUCCESS: {
      const { masterDataType, pageName } = action.payload;
      return {
        ...state,
        [pageName]: { ...state?.[pageName], [masterDataType]: action.payload },
        isLoading: false,
      };
    }
    case GET_MASTER_DATA_BY_TYPE_FAIL: {
      return { ...state, error: action.payload, isLoading: false };
    }
    case GET_MASTER_DATA_DETAILS: {
      return { ...state, isLoading: true };
    }
    case GET_MASTER_DATA_DETAILS_SUCCESS: {
      const { masterDataType, id, pageName, masterDataDetails } =
        action.payload;
      const data = {
        ...state,
        [pageName]: {
          ...state?.[pageName],
          [masterDataType]: {
            ...state?.[pageName]?.[masterDataType],
            masterDataDetails: {
              ...state?.[pageName]?.[masterDataType]?.masterDataDetails,
              [id]: { ...masterDataDetails },
            },
          },
        },
        isLoading: false,
      };
      return {
        ...state,
        [pageName]: {
          ...state?.[pageName],
          [masterDataType]: {
            ...state?.[pageName]?.[masterDataType],
            masterDataDetails: {
              ...state?.[pageName]?.[masterDataType]?.masterDataDetails,
              [id]: { ...masterDataDetails },
            },
          },
        },
        isLoading: false,
      };
    }
    case GET_MASTER_DATA_DETAILS_FAIL: {
      return { ...state, error: action.payload, isLoading: false };
    }

    case SET_COUNTRIES_LIST: {
      const countries = action.payload?.countriesList || [];
      return { ...state, countriesList: countries };
    }

    case SET_CONATCTTYPES_LIST: {
      const contactTypes = action.payload?.contactTypesList || [];
      return { ...state, contactTypesList: contactTypes };
    }
    default:
      return { ...state };
  }
};
export default MasterDataReducer;
