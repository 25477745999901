import clsx from "clsx";
import "./Tabs.scss";

const Tabs = ({ activeIndex, tabs, onActiveIndexChange }) => {
  return (
    <div>
      <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-6 fw-bold">
        {tabs.map((tab, i) => {
          if (tab) {
            return (
              <li
                className="nav-item pe-3"
                key={i}
                onClick={() => {
                  onActiveIndexChange?.(i);
                }}
              >
                <span
                  className={clsx(
                    "nav-link pb-2 px-0",
                    activeIndex === i
                      ? "nav-bar-active"
                      : "nav-bar-inactive text-muted"
                  )}
                  role={"button"}
                >
                  {tab}
                </span>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default Tabs;
