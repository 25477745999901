import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { cancelSubscription } from "../../components/auth/Redux/AuthApi";
import { errorToast, successToast } from "../Toast/Toasts";

const CodeValidationSchema = Yup.object().shape({
  comments: Yup.string().required(),
});

const initialValues = {
  comments: "",
};

function LeaveCommentModal({ handleClose, show, id, getSubscriptionsListAPI }) {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const onFormSubmit = (values) => {
    //api call
    setLoading(true);
    cancelSubscription(id, values.comments)
      .then((data) => {
        successToast("The Subscription is cancelled successfully");
        handleClose();
        getSubscriptionsListAPI?.();
      })
      .catch((data) => {
        errorToast("The Subscription is unsuccessful");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  let handleCommentSubmit = () => {};
  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        centered
        backdrop="static"
        keyboard={false}
        // fullscreen="md-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div>
            <div>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit
              rem, officia voluptate cupiditate inventore ut!
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={CodeValidationSchema}
              onSubmit={(values) => {
                // @ts-ignore
                onFormSubmit(values);
              }}
            >
              {({
                errors,
                values,
                touched,
                setFieldValue,
                handleSubmit,
              }) => {
                handleCommentSubmit = handleSubmit;
                return (
                  <>
                    {/* <input
                  type='text'
                  placeholder={intl.formatMessage({id: 'USERS_VERIFY_CODE_PLACEHOLDER'})}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  name='verify_code'
                  autoComplete='off'
                  onChange={handleChange('verify_code')}
                  value={values.verify_code}
                /> */}
                    <textarea
                      style={{
                        minHeight: "200px",
                        width: "100%",
                        height: "auto",
                        padding: "10px",
                      }}
                      className=" mt-2"
                      value={values.comments}
                      onChange={(e) => {
                        setFieldValue("comments", e.target.value);
                      }}
                    />
                    {touched.comments && errors.comments && (
                      <div className="text-danger">
                        <span role="alert">{errors.comments}</span>
                      </div>
                    )}
                  </>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            style={{ padding: "10px 20px" }}
            onClick={handleClose}
          >
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <button
            className="btn btn-custom"
            style={{ padding: "10px 20px" }}
            onClick={() => {
              handleCommentSubmit();
            }}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LeaveCommentModal;
