import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineLeft, AiOutlinePause, AiOutlineRight } from "react-icons/ai";
import { BsFillPlayFill } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { IoMdContact } from "react-icons/io";
import { RiCalendarTodoFill } from "react-icons/ri";
// import { useIntl } from "react-intl";
import { RiLockFill } from "react-icons/ri";
import { useIntl } from "react-intl";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocal } from "../../App";
import noImage from "../../assets/noImage.jpg";
import { formatDate } from "../../utils";
import Author from "../AuthorImage";
import "./Scroller.scss";

const Scroller = ({
  id,
  title,
  type,
  sectionId,
  uniqueId,
  key,
  pageName,
  sidebars,
  is_bought,
  is_registered,
}) => {
  // const data = useSelector((state) => state?.postsDetails?.trendingPosts);

  const [sections, setSections] = useState();
  const details = useSelector(
    (state) => state?.postsDetails?.[pageName]?.[uniqueId]?.posts?.[1]
  );

  const showLockIcon = useSelector(
    (state) =>
      state.generalDetails.generalDetails.general_settings
        .display_lock_icon_to_content
  );

  const scrollerDetails = useSelector(
    (state) => state?.pageDetails?.pages?.[pageName]?.sections
  );

  useEffect(() => {
    if (scrollerDetails?.length > 0) {
      const tempid = scrollerDetails?.findIndex((data) => data.id === id);
      // console.log("id", tempid);
      if (id) {
        setSections(scrollerDetails[tempid]);
      }
    }
  }, [scrollerDetails]);

  // console.log("scrollerDetails", scrollerDetails);

  const intl = useIntl();
  // const { resolution } = useCustomMediaQuery();

  // const {
  //   isXsScreen,
  //   isSmScreen,
  //   isMdScreen,
  //   isLgScreen,
  //   isXLgScreen,
  //   isXxLgScreen,
  // } = useCustomMediaQuery();
  // const intl = useIntl();
  // const [count, setCount] = useState(1);
  const local = useLocal();

  const ref = useRef(null);
  // const navLeft =
  //   count === 1
  //     ? `<span class="arrow arrow-left arrow-disable"></span>`
  //     : `<span class="arrow arrow-left arrow-enable "></span>`;
  // const navRight =
  //   count === 1
  //     ? `<span class="arrow arrow-right arrow-disable"></span>`
  //     : `<span class="arrow arrow-right arrow-enable ">

  //     </span>`;
  // const handleInitialize = (event) => {
  //   const ele = document.getElementsByClassName("owl-prev")[0];
  //   pauseOnHover(ele);
  //   const eleRight = document.getElementsByClassName("owl-next")[0];

  //   pauseOnHover(eleRight);
  //   setCount(event.item.count);
  // };

  // const pauseOnHover = (ele) => {
  //   ele?.addEventListener("mouseover", () => {
  //     if (ref.current) {
  //       ref.current?.stop();
  //     }
  //   });
  //   ele?.addEventListener("mouseleave", () => {
  //     if (ref.current) {
  //       ref.current?.play();
  //     }
  //   });
  // };

  const handlePause = () => {
    ref.current?.stop();
  };

  const handlePlay = () => {
    ref.current?.play();
  };
  const handlePrev = () => {
    ref.current?.prev();
  };
  const handleNext = () => {
    ref.current?.next();
  };

  useEffect(() => {}, []);

  const setPostMediaSource = (post) => {
    const imgResolutions =
      post?.post_type === "image"
        ? post?.post_media_resolutions
        : post?.post_thumbnail_resolutions;
    const file_url =
      post?.post_type === "image" ? post?.post_media : post?.post_thumbnail;
    if (imgResolutions && imgResolutions.length > 0) {
      const source = imgResolutions.filter(
        (element) => element?.resolution === "256x144"
      );
      return source[0].file_url;
    }
    return file_url ? file_url : noImage;
  };

  const setEventMediaSource = (event) => {
    if (
      event?.event_image_resolutions &&
      event?.event_image_resolutions.length > 0
    ) {
      const source = event?.event_image_resolutions.filter(
        (element) => element?.resolution === "256x144"
      );
      return source[0].file_url;
    }
    return event?.event_image_url ? event?.event_image_url : noImage;
  };

  const setContactMediaSource = (event) => {
    if (
      event?.contact_person_image_resolutions &&
      event?.contact_person_image_resolutions.length > 0
    ) {
      const source = event?.contact_person_image_resolutions.filter(
        (element) => element?.resolution === "256x144"
      );
      return source[0].file_url;
    }
    return event?.contact_person_image_url
      ? event?.contact_person_image_url
      : noImage;
  };

  return details && details.length > 0 ? (
    <div className="mb-4 " key={id}>
      <div
        className="container"
        style={{
          padding: sidebars && sidebars.length === 0 ? "0 28px" : "0 13px",
        }}
      >
        <div
          className="row"
          style={{
            border: `1px solid ${sections?.title_background_color}`,
            borderRadius: "2px",
            minHeight: "70px",
          }}
        >
          <div className="col-11 pe-0">
            <div className="row" style={{ height: "100%" }}>
              <div
                style={{
                  minHeight: "70px",
                  color: sections?.title_text_color,
                  background: sections?.title_background_color,
                  border: sections?.title_background_color,
                }}
                className={`${
                  sidebars && sidebars.length === 0 ? "col-3" : "col-3"
                } justify-content-center scroller-title d-none d-sm-none d-md-none d-lg-flex align-items-center`}
              >
                <div className="text-truncate">
                  <span style={{ alignSelf: "center" }}>{title}</span>
                </div>
              </div>
              <div
                className={`${
                  sidebars && sidebars.length > 0
                    ? "col-12 col-lg-10 col-md-11"
                    : "col-12 col-lg-9 col-md-11"
                } d-flex justify-content-between align-items-center`}
              >
                <OwlCarousel
                  // onChange={() => {
                  //   const active =
                  //     document.getElementsByClassName("owl-item active")[0]
                  //       .clientHeight;

                  //   console.log("active", active);
                  // }}
                  ref={ref}
                  items={1}
                  loop={true}
                  autoplay={true}
                  dots={false}
                  // onInitialized={handleInitialize}
                  autoplayHoverPause={true}
                  autoplaySpeed={5000}
                  autoplayTimeout={5000}
                  autoWidth={false}
                >
                  {details &&
                    details.length > 0 &&
                    details.map((ele, index) => {
                      return (
                        <Link
                          key={index}
                          to={
                            type.includes("event")
                              ? ele.seo_permalink
                                ? `../event/${ele.id}/${ele.seo_permalink}`
                                : `../event/${ele.id}/${ele.title?.replace(
                                    /\s+/g,
                                    "-"
                                  )}`
                              : ele.seo_permalink
                              ? `../post/${ele.id}/${ele.seo_permalink}`
                              : `../post/${ele.id}/${ele.title?.replace(
                                  /\s+/g,
                                  "-"
                                )}`
                          }
                          state={{ postId: ele.id }}
                          className="slide"
                        >
                          <div className="d-flex align-items-center">
                            <div className="position-relative">
                              <img
                                src={
                                  // "https://backend.omnics.in/storage/media/3ac96ca16e0e2888047759512f30c192ee2fe2e298e2bd3ac01f947370ae9fee.jpg"
                                  type === "post-scroller"
                                    ? setPostMediaSource(ele)
                                    : setEventMediaSource(ele)
                                }
                                style={{
                                  marginRight: "15px",
                                  marginLeft: "5px",
                                  objectFit: "cover",
                                  height: "45px",

                                  width: "auto",
                                  borderRadius: "5px",
                                }}
                                alt=""
                              />
                              {type ===
                              "event-scroller" ? null : showLockIcon ===
                                  "yes" &&
                                (!!is_bought || !!is_registered) ? null : (
                                <div
                                  className={` rounded-circle position-absolute scroller-lock-icon `}
                                  style={{
                                    //   background: "var(--accent-color)",
                                    zIndex: "10",

                                    //   opacity: "100%",
                                  }}
                                >
                                  <RiLockFill
                                    className={`lock-icon 
                                        lock-icon-xsmall
                                    }`}
                                  />
                                </div>
                              )}
                            </div>

                            <div className="d-flex flex-column">
                              <div className="d-flex align-items-center justify-content-start ">
                                <div className="text-truncate">
                                  <span
                                    className="scroller__title"
                                    style={{ alignSelf: "center" }}
                                  >
                                    {ele.title}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{}}
                                className="d-flex align-items-center "
                              >
                                <li className="list-inline-item">
                                  <span
                                    className="text-dark d-flex justify-content-center align-items-center"
                                    style={{
                                      marginRight: "1rem",
                                      height: "30px",
                                    }}
                                  >
                                    <RiCalendarTodoFill className="icon-calendar" />
                                    {ele.start_date
                                      ? local && local === "en"
                                        ? formatDate(ele.start_date)
                                        : moment(ele.start_date).format(
                                            "DD.MM.yyyy"
                                          )
                                      : ele.published_at
                                      ? formatDate(ele.published_at)
                                      : moment(ele.published_at).format(
                                          "DD.MM.yyyy"
                                        )}
                                  </span>
                                </li>
                                <li className="list-inline-item d-none d-sm-none d-md-none d-lg-block d-xl-block">
                                  <span className="d-flex justify-content-center align-items-center">
                                    {" "}
                                    {ele?.contact_person_image_url ? (
                                      <>
                                        <IoMdContact
                                          className="event-icons no-background"
                                          style={{ marginRight: "0" }}
                                        />
                                        <img
                                          className=" mx-1"
                                          style={{
                                            height: "22px",
                                            width: "auto",
                                            objectFit: "contain",
                                          }}
                                          src={ele?.contact_person_image_url}
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <Author
                                        authors={ele.authors}
                                        view={"list"}
                                        name={false}
                                      />
                                    )}
                                  </span>
                                </li>
                                {type.includes("event") && (
                                  <li className="list-inline-item  ">
                                    <span className="d-flex justify-content-center align-items-center colorAccent">
                                      {" "}
                                      <ImLocation2
                                        className="event-icons no-background"
                                        style={{ marginRight: "0" }}
                                      />
                                      {ele?.event_type_id === 1
                                        ? intl.formatMessage({
                                            id: "ONLINE_TITLE",
                                          })
                                        : intl.formatMessage({
                                            id: "OFFLINE_TITLE",
                                          })}
                                    </span>
                                  </li>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                </OwlCarousel>
              </div>
            </div>
          </div>
          <ActionButtons
            handlePause={handlePause}
            handlePlay={handlePlay}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        </div>
      </div>
    </div>
  ) : null;
};

const ActionButtons = ({ handlePause, handlePlay, handlePrev, handleNext }) => {
  const [pause, setPause] = useState(false);

  return (
    <div className="col-1 px-0 justify-content-end align-items-center d-flex">
      <div
        className="d-flex flex-row gap-1 px-1 h-100 align-items-center"
        style={{ background: "#f6f6f6", zIndex: "10" }}
        onMouseOver={() => {
          handlePause();
        }}
        onMouseLeave={() => {
          if (!pause) {
            handlePlay();
          }
        }}
      >
        <AiOutlineLeft
          style={{
            height: "25px",
            width: "25px",
          }}
          onClick={() => {
            handlePrev();
          }}
        />
        {pause ? (
          <BsFillPlayFill
            style={{
              height: "25px",

              width: "25px",
            }}
            onClick={() => {
              handlePlay();
              setPause(false);
            }}
          />
        ) : (
          <AiOutlinePause
            style={{
              height: "25px",

              width: "25px",
            }}
            onClick={() => {
              handlePause();
              setPause(true);
            }}
          />
        )}
        <AiOutlineRight
          style={{
            height: "25px",
            width: "25px",
          }}
          onClick={() => {
            handleNext();
          }}
        />
      </div>
    </div>
  );
};

export default Scroller;
