import React from "react";
import { Navigate, Route, Routes } from "react-router";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ForgotPasswordWrapper from "./ForgotPasswordWrapper";
const ForgotPassword = () => {
  return (
    <div>
      <Routes>
        <Route path=":email" element={<ForgotPasswordWrapper />} />
        <Route index element={<ForgotPasswordForm />} />
        <Route path="" element={<Navigate to={"/"} />} />
      </Routes>
    </div>
  );
};

export default ForgotPassword;
