import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  // Register: '[Register] Action',
  SetUser: "[Set User] Action",
  SET_CLIENT_DETAILS: "SET_CLIENT_DETAILS",
  GET_PRODUCTS_DETAILS: "GET_PRODUCTS_DETAILS",
  SET_PRODUCTS_DETAILS_SUCCESS: "SET_PRODUCTS_DETAILS_SUCCESS",
  SET_CAMPAIGNS_DETAILS: "SET_CAMPAIGNS_DETAILS",
};

const initialAuthState = {
  user: undefined,
  accessToken: undefined,
  isAuthorized: false,
  clientDetails: null,
  campaignDetails: {},
};

export const AuthReducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "accessToken", "isAuthorized"] },
  // @ts-ignore
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken;
        return {
          ...state,
          accessToken,
          isAuthorized: true,
          showNotificationDot: true,
        };
      }

      case actionTypes.Logout: {
        return {
          user: undefined,
          accessToken: undefined,
          isAuthorized: false,
          notifications: [],
          showNotificationDot: false,
        };
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case actionTypes.SET_CLIENT_DETAILS: {
        const clientDetails = action.payload?.clientDetails;
        return { ...state, clientDetails };
      }
      case actionTypes.GET_PRODUCTS_DETAILS: {
        return { ...state };
      }
      case actionTypes.SET_PRODUCTS_DETAILS_SUCCESS: {
        return { ...state, products: action.payload };
      }

      case actionTypes.SET_CAMPAIGNS_DETAILS: {
        return { ...state, campaignDetails: action.payload };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken) => ({
    type: actionTypes.Login,
    payload: { accessToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setClientDetails: (clientDetails) => ({
    type: actionTypes.SET_CLIENT_DETAILS,
    payload: { clientDetails },
  }),
  getContactProducts: () => ({ type: actionTypes.GET_PRODUCTS_DETAILS }),
  setContactProducts: (products) => ({
    type: actionTypes.SET_PRODUCTS_DETAILS_SUCCESS,
    payload: products,
  }),
  setCampaignDetails: (campaignDetails) => ({
    type: actionTypes.SET_CAMPAIGNS_DETAILS,
    payload: campaignDetails,
  }),
};
