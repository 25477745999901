import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import "./style.scss";

function Page404() {
  const intl = useIntl();
  return (
    <>
      <Helmet>
        <title>{"404"}</title>
      </Helmet>
      <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-12 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">
                    {intl.formatMessage({ id: "PAGES_404_TITLE" })}
                  </h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">
                    {intl.formatMessage({ id: "PAGES_404_MESSAGE_1" })}
                  </h3>

                  <p>{intl.formatMessage({ id: "PAGES_404_MESSAGE_2" })}</p>

                  <Link to={"/"} className="link_404">
                      {intl.formatMessage({ id: "PAGES_404_GO_HOME" })}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Page404;
