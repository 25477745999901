import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  fetchCheckoutUrl,
  fetchDetailedEventData,
  fetchMasterData,
  fetchMasterDataDetails,
  fetchPostDetails,
  fetchTopTrendingPosts,
} from "../API/Api";
import { actionTypes, actions } from "../Redux/AuthReducer";
import {
  GET_EVENT_DATA_BY_ID,
  geteventDataByIdFail,
  geteventDataByIdSuccess,
} from "../Redux/EventsReducer";
import {
  GET_GENERAL_DETAILS,
  GET_LEGAL_TEXT,
  GET_SIDEBAR_POSTS,
  setGeneralDetails,
  setLegalTextDetails,
  setSidebarPostsFail,
  setSidebarPostsSuccess,
} from "../Redux/GeneralDetailsSlice";
import {
  GET_MASTER_DATA_BY_TYPE,
  GET_MASTER_DATA_DETAILS,
  getMasterDataByTypeFail,
  getMasterDataByTypeSuccess,
  getMasterDataDetailsFail,
  getMasterDataDetailsSuccess,
} from "../Redux/MasterDataReducer";
import { setNavigationMenu } from "../Redux/NavigationMenuSlice";
import {
  GET_PAGE_DETAILS,
  getPageDetailsFail,
  getPageDetailsSuccess,
} from "../Redux/PageDetailsReducer";
import {
  GET_CHECKOUT_URL,
  setCheckoutURLFail,
  setCheckoutURLSuccess,
} from "../Redux/PaymentsReducer";
import {
  GET_PAGINATION_POST_DETAILS,
  GET_POST_DETAILS,
  GET_TRENDING_POSTS,
  getPostsDetailsFail,
  getPostsDetailsSuccess,
  getTrendingPostsFail,
  getTrendingPostsSuccess,
  setCurrentPage,
  setIsLoadingFalse,
} from "../Redux/PostsDetailsReducer";
import {
  GET_POST_DATA_BY_ID,
  getPostDataByIdFail,
  getPostDataByIdSuccess,
} from "../Redux/PostsReducer";
// const apiURL = process.env.REACT_APP_API_URL;

export function* rootSaga() {
  yield all([
    takeEvery("generalDetails/getGeneralDetails", workerGeneralDetailsSaga),
    takeEvery(GET_GENERAL_DETAILS, workerGeneralDetailsSaga),
    takeEvery("navigationMenu/getNavigationMenu", workerNavigationMenuSaga),
    takeEvery(GET_LEGAL_TEXT, workerLegalTextDetailsSaga),
    takeEvery(GET_SIDEBAR_POSTS, workerSidebarPosts),
    takeEvery(GET_PAGE_DETAILS, workerGetPageDetails),
    takeEvery(GET_POST_DETAILS, workerGetPostDetails),
    takeEvery(GET_PAGINATION_POST_DETAILS, workerGetPostDetails),
    takeEvery(GET_POST_DATA_BY_ID, workerGetPostDataById),
    takeEvery(GET_EVENT_DATA_BY_ID, workerGetEventDataById),
    takeEvery(GET_MASTER_DATA_BY_TYPE, workerGetMasterDataByType),
    takeEvery(GET_MASTER_DATA_DETAILS, workerGetMasterDataDetails),
    takeEvery(GET_TRENDING_POSTS, workerGetTrendingPosts),
    takeEvery(GET_CHECKOUT_URL, workerGetCheckoutURL),
    takeEvery(actionTypes.GET_PRODUCTS_DETAILS, workerGetProductDetails),
  ]);
}

export function* workerGeneralDetailsSaga() {
  const { data } = yield call(() =>
    axios.get("/websiteApp/generalSettings?channel=web")
  );
  yield put(setGeneralDetails(data.generalDetails));
}

export function* workerLegalTextDetailsSaga({ payload: id }) {
  const { data } = yield call(() =>
    axios.get(`/client/system/legalTexts/${id}`)
  );
  yield put(setLegalTextDetails(data.legalTextDetail));
}

export function* workerSidebarPosts({ payload }) {
  try {
    const { postDetails, eventDetails } = yield call(
      fetchPostDetails,
      payload.paramsObject,
      payload.type,
      payload?.pageId
    );
    const postsPayload = {
      eventDetails,
      postDetails,
      id: payload.id,
      type: payload.type,
      sectionId: payload.sectionId,
      pageType: payload.pageType,
      title: payload?.title,
    };
    yield put(setSidebarPostsSuccess(postsPayload));
  } catch (error) {
    yield put(setSidebarPostsFail(error));
  }
}
export function* workerNavigationMenuSaga() {
  const { data } = yield call(() =>
    axios.get("/websiteApp/navigationMenu?channel=web")
  );
  yield put(setNavigationMenu(data.navigationMenu));
}

export function* workerGetPageDetails({ payload: id }) {
  try {
    const { data } = yield call(
      () => axios.get(`/websiteApp/pageSettings/${id}`),
      id
    );
    yield put(getPageDetailsSuccess(data.pageSettingsDetails));
  } catch (error) {
    yield put(getPageDetailsFail(error));
  }
}

export function* workerGetPostDetails({ payload }) {
  // console.log("APIcall", payload);

  try {
    const { postDetails, paginationDetails, eventDetails } = yield call(
      fetchPostDetails,
      payload.paramsObject,
      payload.type,
      payload.pageId
    );
    const postsPayload = {
      eventDetails,
      postDetails,
      paginationDetails,
      pageName: payload.pageName,
      id: payload.id,
      type: payload.type,
      title: payload.title,
      defaultView: payload.defaultView,
      sectionId: payload.sectionId,
      pageId: payload.pageId,
      isLast: payload.isLast,
    };
    yield put(getPostsDetailsSuccess(postsPayload));
  } catch (error) {
    yield put(getPostsDetailsFail(error));
    yield put(
      setIsLoadingFalse({
        id: payload.id,
        title: payload.title,
        sectionId: payload.sectionId,
        pageName: payload.pageName,
      })
    );
    if (payload.pageId) {
      let uniqueId = `${payload.sectionId}_${payload.id}`;
      yield put(
        setCurrentPage({
          pageId: payload.pageId,
          pageName: payload.pageName,
          uniqueId,
        })
      );
    }
  }
}
//?channel=web${ isAuthorized ? `&contactId=${contactId}` : ""}
export function* workerGetPostDataById({ payload }) {
  const { isAuthorized, contactId } = payload;
  try {
    const response = yield call(
      () =>
        axios.get(`/posts/${payload?.id}`, {
          params: {
            channel: "web",
            contacts: isAuthorized ? contactId : "",
          },
        }),
      payload?.id
    );
    if (response.status === 200) {
      yield put(getPostDataByIdSuccess(response.data.postDetails));
    }
  } catch (error) {
    yield put(getPostDataByIdFail(error));
  }
}

export function* workerGetEventDataById({ payload: { id, userId } }) {
  try {
    const { eventDetails } = yield call(fetchDetailedEventData, id, userId);
    yield put(geteventDataByIdSuccess(eventDetails));
  } catch (error) {
    yield put(geteventDataByIdFail(id, error));
  }
}

export function* workerGetMasterDataByType({ payload }) {
  try {
    const { listType, pageName } = payload;
    const { masterData } = yield call(fetchMasterData, listType);
    const type = listType.split("-")[0];
    const masterDataType =
      type === "category" ? "categories" : type === "group" ? "groups" : "tags";
    yield put(
      getMasterDataByTypeSuccess({ masterDataType, masterData, pageName })
    );
  } catch (error) {
    yield put(getMasterDataByTypeFail(error));
  }
}

export function* workerGetMasterDataDetails({ payload }) {
  try {
    const { masterDataType, id, pageName } = payload;
    const { masterDataDetails } = yield call(
      fetchMasterDataDetails,
      masterDataType,
      id
    );
    yield put(
      getMasterDataDetailsSuccess({
        masterDataType,
        id,
        pageName,
        masterDataDetails,
      })
    );
  } catch (error) {
    yield put(getMasterDataDetailsFail(error));
  }
}

export function* workerGetTrendingPosts({ payload }) {
  try {
    const { postsDetails } = yield call(fetchTopTrendingPosts, payload);
    yield put(getTrendingPostsSuccess(postsDetails));
  } catch (error) {
    yield put(getTrendingPostsFail);
  }
}

export function* workerGetCheckoutURL({ payload }) {
  try {
    const { paramsObject, productId } = payload;
    const { data } = yield call(fetchCheckoutUrl, paramsObject, productId);
    yield put(setCheckoutURLSuccess(data));
  } catch (error) {
    yield put(setCheckoutURLFail(error));
  }
}

export function* workerGetProductDetails() {
  try {
    const response = yield call(() =>
      axios({
        method: "get",
        url: `/contact/products`,
        headers: { "Content-Type": "multipart/form-data" },
      })
    );
    if (response && response.status === 200) {
      yield put(actions.setContactProducts(response.data.products));
    }
  } catch (error) {
    throw error;
  }
}
export default rootSaga;
