import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  addCustomerContact,
  deleteCustomerContact,
  getContactTypesList,
  updateCustomerContact,
} from "../../../API/Api";
import { setContactTypesList } from "../../../Redux/MasterDataReducer";
import Alert from "../../../sharedComponents/Alerts/Alert";
import { CustomDropDown } from "../../../sharedComponents/Dropdown/CustomDropDown";
import TextInput from "../../../sharedComponents/TextInput/TextInput";
import "./style.scss";

import { useIntl } from "react-intl";

const CustomerContactTypes = ({ customerDetails, getCustomerDetailsAPI }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const contactTypesList = useSelector(
    (state) => state.masterData.contactTypesList
  );
  // const intl = useIntl()
  //   state
  const [showAddContactTypeModal, setShowAddContactTypeModal] = useState({
    show: false,
  });

  const alertValues = [
    {
      id: 0,
      SuccessMessage: intl.formatMessage({
        id: "ACCOUNT_SETTINGS_CONTACTS_ADD_SUCCESS",
      }),
      errorMessage: intl.formatMessage({
        id: "ACCOUNT_SETTINGS_CONTACTS_ADD_ERROR",
      }),
    },
    {
      id: 1,
      SuccessMessage: intl.formatMessage({
        id: "ACCOUNT_SETTINGS_CONTACTS_UPDATE_SUCCESS",
      }),
      errorMessage: intl.formatMessage({
        id: "ACCOUNT_SETTINGS_CONTACTS_UPDATE_ERROR",
      }),
    },
    {
      id: 2,
      SuccessMessage: intl.formatMessage({
        id: "ACCOUNT_SETTINGS_CONTACTS_DELETE_SUCCESS",
      }),
      errorMessage: intl.formatMessage({
        id: "ACCOUNT_SETTINGS_CONTACTS_DELETE_ERROR",
      }),
    },
  ];

  // const [customer, setCustomer] = useState(customerDetails);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [apiType, setApiType] = useState(0);

  useEffect(() => {
    getContactTypesList()
      .then(({ data: { data } }) => {
        dispatch(setContactTypesList(data));
      })
      .catch((e) => {
        window.alert(
          intl.formatMessage({
            id: "ACCOUNT_SETTINGS_CONTACTS_CONTACT_LIST_ERROR",
          }),
          e
        );
      });
      // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   setCustomer(customerDetails);
  // }, [customerDetails]);

  const closeModal = () => {
    setShowAddContactTypeModal({
      show: false,
      type: "",
      contactDetail: undefined,
    });
  };

  const deleteContact = (ContactDetail) => {
    setApiType(2);
    //   deleteConfirmAlert(
    //     intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_CONTACT_DELETE_MESSAGE'}),
    //     () => {
    deleteCustomerContact(
      customerDetails?.personalInformation?.id,
      ContactDetail.contact_detail_id
    )
      .then(() => {
        //   SuccessAlert(
        //     intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_CONTACT_DELETE_SUCCESS_MESSAGE'})
        //   )
        getCustomerDetailsAPI();
        setShowSuccess(true);
      })
      .catch(() => {
        setShowFailure(true);
        //   errorAlert(
        //     intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_CONTACT_DELETE_FAILURE_MESSAGE'})
        //   )
      });
    //     }
    //   )
  };

  const allContactsAdded = () => {
    let flag = true;
    contactTypesList.forEach((contactType) => {
      const index = customerDetails.contactDetails?.findIndex(
        (contactDetail) => contactDetail.contact_detail_id === contactType.id
      );
      if (index === -1) {
        flag = false;
      }
    });
    return flag;
  };

  return (
    <>
      <Alert
        show={showSuccess ? showSuccess : showFailure}
        setShow={showSuccess ? setShowSuccess : setShowFailure}
        message={
          showSuccess
            ? alertValues[apiType].SuccessMessage
            : alertValues[apiType].errorMessage
        }
        type={showSuccess ? "success" : "failure"}
      />
      <div className="card mb-5 mb-xl-10 card-no-border ms-0">
        {/* <AddContactTypeModal
          modalProps={showAddContactTypeModal}
          closeModal={closeModal}
          customerDetails={customerDetails}
          getCustomerDetailsAPI={getCustomerDetailsAPI}
        /> */}
        <div className="card-header d-flex flex-fill flex-row align-items-center justify-content-between card-header-custom pt-0">
          <div className={`title-wrapper w-100`}>
            <h4
              className={`border-section w-100 d-inline-flex justify-content-between align-items-center`}
            >
              {intl.formatMessage({ id: "ACCOUNT_SETTINGS_CONTACTS_TITLE" })}

              <span className="">
                <button
                  type="button"
                  className="btn btn-sm btn-custom focus-disable"
                  onClick={() => {
                    if (allContactsAdded()) {
                      //   SuccessAlert('All contacts added already!', () => {}, ' ')
                      window.alert(
                        `${intl.formatMessage({
                          id: "ACCOUNT_SETTINGS_CONTACTS_FULL_ALERT",
                        })}`
                      );
                    } else {
                      setShowAddContactTypeModal({
                        show: true,
                        type: "new",
                        contactDetail: undefined,
                      });
                    }
                  }}
                >
                  {/* <SVGICON src={PlusIcon} className='svg-icon-2' /> */}
                  {intl.formatMessage({
                    id: "ACCOUNT_SETTINGS_CONTACTS_ADD_CONTACT",
                  })}
                </button>
              </span>
            </h4>
          </div>
        </div>

        <div id="kt_account_contact_types" className="collapse show">
          <div className="card-body pt-0 p-3">
            {(!customerDetails?.contactDetails ||
              customerDetails?.contactDetails.length === 0) && (
              <span className="text-gray-600">
                {intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_CONTACTS_NO_CONTACT_ADDED",
                })}
              </span>
            )}
            {customerDetails?.contactDetails?.map((contactDetail, i) => {
              return (
                <div key={i}>
                  <div className="d-flex flex-wrap align-items-center justify-content-between pb-2">
                    <div>
                      <div className=" mb-1" style={{ fontSize: "1.25rem" }}>
                        {contactDetail.contact_detail}
                      </div>
                      <div className=" text-gray-600">
                        {contactDetail.description}
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-1 focus-disable"
                        onClick={() => {
                          setShowAddContactTypeModal({
                            show: true,
                            type: "edit",
                            contactDetail,
                          });
                        }}
                      >
                        {/* <SVGICON src={EditIcon} className='svg-icon-3' /> */}
                        {intl.formatMessage({
                          id: "ACCOUNT_SETTINGS_CONTACTS_EDIT",
                        })}
                      </button>
                      <button
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary focus-disable"
                        onClick={() => {
                          deleteContact(contactDetail);
                        }}
                      >
                        {/* <SVGICON src={DeleteIcon} className='svg-icon-3' /> */}
                        {intl.formatMessage({
                          id: "ACCOUNT_SETTINGS_CONTACTS_DELETE",
                        })}
                      </button>
                    </div>
                  </div>
                  {i !== customerDetails?.contactDetails?.length - 1 && (
                    <div className="separator separator-dashed my-6"></div>
                  )}
                </div>
              );
            })}
            <AddContactTypeModal
              modalProps={showAddContactTypeModal}
              closeModal={closeModal}
              customerDetails={customerDetails}
              getCustomerDetailsAPI={getCustomerDetailsAPI}
              setApiType={setApiType}
              setShowSuccess={setShowSuccess}
              setShowFailure={setShowFailure}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const AddContactTypeModal = ({
  modalProps,
  closeModal,
  customerDetails,
  getCustomerDetailsAPI,
  setApiType,
  setShowFailure,
  setShowSuccess,
}) => {
  const intl = useIntl();
  const contactTypesList = useSelector(
    (state) => state.masterData.contactTypesList
  );
  const { show, type, contactDetail } = modalProps;
  // const intl = useIntl()

  // state
  const [loading, setLoading] = useState(false);
  const [selectedContactType, setSelectedContactType] = useState();
  const AddContactSchema = Yup.object().shape({
    contactId: Yup.number().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_CONTACTS_CONTACT_ID_ERROR_MESSAGE",
      })
    ),
    contactDescription: Yup.string()
      .max(
        60,
        intl.formatMessage({
          id: "ACCOUNT_SETTINGS_CONTACTS_CONTACT_DESCRIPTION_MAX_MESSAGE",
        })
      )
      .required(
        intl.formatMessage({
          id: "ACCOUNT_SETTINGS_CONTACTS_CONTACT_DESCRIPTION_REQUIRED_MESSAGE",
        })
      ),
  });

  const initialValues = {
    contactId: type === "edit" ? contactDetail?.contact_detail_id : undefined,
    contactDescription: type === "edit" ? contactDetail?.description || "" : "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: AddContactSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading(true);
      setApiType(1);
      if (customerDetails?.personalInformation?.id && values.contactId) {
        if (type === "edit") {
          updateCustomerContact(
            customerDetails?.personalInformation?.id,
            values.contactId,
            values.contactDescription
          )
            .then(() => {
              setShowSuccess(true);
              // SuccessAlert(intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_CONTACT_SUCCESS_MESSAGE'}))
              getCustomerDetailsAPI();
            })
            .catch(() => {
              setShowFailure(true);
              // errorAlert(intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_CONTACT_FAILURE_MESSAGE'}))
            })
            .finally(() => {
              setSelectedContactType(undefined);
              closeModal();
              setLoading(false);
            });
        } else {
          setApiType(0);
          addCustomerContact(
            customerDetails?.personalInformation?.id,
            values.contactId,
            values.contactDescription
          )
            .then(() => {
              setShowSuccess(true);
              // SuccessAlert(
              //   intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CONTACT_SUCCESS_MESSAGE'})
              // )
              getCustomerDetailsAPI();
            })
            .catch(() => {
              setShowFailure(true);
              // errorAlert(
              //   intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CONTACT_FAILURE_MESSAGE'})
              // )
            })
            .finally(() => {
              setSelectedContactType(undefined);
              closeModal();
              setLoading(false);
            });
        }
      }
    },
  });

  const getDropDownItems = () => {
    const result = [];
    contactTypesList.forEach((contactType) => {
      const index = customerDetails.contactDetails?.findIndex(
        (contactDetail) => contactDetail.contact_detail_id === contactType.id
      );
      if (index === -1) {
        result.push(contactType);
      }
    });
    return result;
  };

  return (
    //   <Modal show={show} centered contentClassName={loading ? 'pe-none' : 'AddNew'}>
    <div
      style={{ display: show ? "" : "none" }}
      className={loading ? "pe-none" : "AddNew"}
    >
      {/* <Modal.Header>
          <Modal.Title>
            {type === 'edit'
              ? intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_EDIT_CONTACT_TITLE'})
              : intl.formatMessage({id: 'CUSTOMER_MANAGEMENT_ADD_CONTACT_TITLE'})}
          </Modal.Title>
        </Modal.Header> */}
      <div className="separator separator-dashed my-3"></div>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_customer_contact_form"
      >
        {/* <Modal.Body> */}
        <div className="pb-4">
          <h3 style={{ fontSize: "20px" }} className="pb-2">
            {type === "edit"
              ? intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_CONTACTS_EDIT_CONTACT",
                })
              : intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_CONTACTS_ADD_NEW_CONTACT",
                })}
          </h3>
          {/* <div className='fv-row mb-8'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>
                  Contact Type
                  </span>
                </label>
                <CustomDropDown
                  id='contactTypesDropdown'
                  items={getDropDownItems()}
                  selectedItem={selectedContactType}
                  // @ts-ignore
                  onSelectItem={(item) => {
                    formik.setFieldValue('contactId', item.id)
                    setSelectedContactType(item)
                  }}
                  displayValue={
                    type === 'edit'
                      ? contactDetail?.contact_detail || ''
                      : selectedContactType?.name || ''
                  }
                  // @ts-ignore
                  displayFunc={(item) => {
                    return item.name
                  }}
                  disabled={type === 'edit'}
                />
                {formik.touched.contactId && formik.errors.contactId && (
                  <div className='text-danger'>
                    <span role='alert'>{formik.errors.contactId}</span>
                  </div>
                )}
              </div>
  
              <div className='fv-row'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>
                  Description
                  </span>
                </label>
                <input
                  type='text'
                  placeholder={''}
                  className={'form-control form-control-lg form-control-solid'}
                  autoComplete='off'
                  {...formik.getFieldProps('contactDescription')}
                  value={formik.values.contactDescription}
                />
                {formik.touched.contactDescription && formik.errors.contactDescription && (
                  <div className='text-danger'>
                    <span role='alert'>{formik.errors.contactDescription}</span>
                  </div>
                )}
              </div> */}
          {/* salutation */}
          <div className="row mb-3">
            <label className="col-lg-4 col-form-label form-input-label d-flex align-items-center">
              <span className="required">
                {intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_CONTACTS_CONTACT_TYPE_LABEL",
                })}
              </span>
            </label>

            <div className="col-lg-8 fv-row">
              <CustomDropDown
                id="contactTypesDropdown"
                items={getDropDownItems()}
                selectedItem={selectedContactType}
                // @ts-ignore
                onSelectItem={(item) => {
                  formik.setFieldValue("contactId", item.id);
                  setSelectedContactType(item);
                }}
                displayValue={
                  type === "edit"
                    ? contactDetail?.contact_detail || ""
                    : selectedContactType?.name || ""
                }
                // @ts-ignore
                displayFunc={(item) => {
                  return item.name;
                }}
                disabled={type === "edit"}
              />
              {formik.errors.contactId && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.contactId}</span>
                </div>
              )}
            </div>
          </div>

          {/* description */}
          <div className="row">
            <label className="col-lg-4 col-form-label form-input-label d-flex align-items-center">
              <span className="required">
                {intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_CONTACTS_DESCRIPTION_LABEL",
                })}
              </span>
            </label>

            <div className="col-lg-8 fv-row">
              {/* <input
                      type='text'
                      className=' mb-3 mb-lg-0 form-common-input'
                      style={{width:'100%'}}
                      {...formik.getFieldProps('contactDescription')}
                    /> */}
              <TextInput
                value={formik.values.email}
                onChange={(value) => {
                  formik.setFieldValue("contactDescription", value);
                }}
                isError={formik.touched.email && formik.errors.email}
              />
              {formik.errors.contactDescription && (
                <div className="text-danger">
                  <span role="alert">{formik.errors.contactDescription}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* </Modal.Body> */}

        {/* <Modal.Footer className='justify-content-center'> */}
        <div className="d-flex justify-content-end ">
          {/* <Button variant='secondary' onClick={closeModal}> */}
          <button
            className="btn btn-light me-2 focus-disable"
            type="button"
            onClick={closeModal}
          >
            {intl.formatMessage({ id: "ACCOUNT_SETTINGS_CONTACTS_CLOSE" })}
          </button>
          {/* </Button> */}
          <button
            type="submit"
            className="btn btn-custom focus-disable"
            id="kt_customer_contact_submit"
          >
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_CONTACTS_LOADING",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
            {!loading &&
              intl.formatMessage({ id: "ACCOUNT_SETTINGS_CONTACTS_SUBMIT" })}
          </button>
        </div>
        {/* </Modal.Footer> */}
      </form>
      {/* </Modal> */}
    </div>
  );
};

export default CustomerContactTypes;
