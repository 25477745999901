import clsx from "clsx";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsBuildingFillAdd, BsBuildingFillCheck } from "react-icons/bs";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { CustomDropDown } from "../../../../sharedComponents/Dropdown/CustomDropDown";
import InputLabel from "../../../../sharedComponents/InputLabel/InputLabel";
import TextInput from "../../../../sharedComponents/TextInput/TextInput";
import { successToast } from "../../../../sharedComponents/Toast/Toasts";
import { addCompanyAddress } from "../../../auth/Redux/AuthApi";

const CompanyAddressModal = ({
  show,
  closeModal,
  userDetails,
  getCustomerDetailsAPI,
  userId,
  email,
  password,
  closeLoginModal,
}) => {
  const intl = useIntl();
  const countries = useSelector((state) => state.masterData.countriesList);
  // state
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedCompanyMethod, setSelectedCompanyMethod] = useState(1);

  const [codeError, setCodeError] = useState("");
  const [addressError, setAddressError] = useState("");

  let handleCodeSubmit = () => {};
  let handleAddressSubmit = () => {};

  const codeInitialValues = {
    verify_code: "",
  };

  const addressInitialValues = {
    name: "",
    address1: "",
    address2: "",
    city: "",
    zip_code: "",
    country_id: undefined,
  };

  const CodeValidationSchema = Yup.object().shape({
    verify_code: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_COMPANY_ADDRESS_VERIFICATION_CODE_MESSAGE",
      })
    ),
  });

  const AddressValidationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_COMPANY_ADDRESS_NAME_MESSAGE",
      })
    ),
    address1: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS1_MESSAGE",
      })
    ),
    city: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_CITY_MESSAGE",
      })
    ),
    zip_code: Yup.string().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ZIP_CODE_MESSAGE",
      })
    ),
    country_id: Yup.number().required(
      intl.formatMessage({
        id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_COUNTRY_MESSAGE",
      })
    ),
  });

  const ChooseAuthMethod = () => {
    return (
      <div>
        <div className="">
          {/* company address with code */}
          <label
            className={clsx(
              "btn bg-light-primary rounded p-3 d-flex align-items-center svg-icon svg-icon-3x-half mb-4 new-company-off-box"
              // selectedCompanyMethod === 1
              //   ? "new-company-box"
              //   : "new-company-off-box"
            )}
            onClick={() => {
              setSelectedCompanyMethod(1);
              setStep(2);
            }}
            htmlFor="kt_modal_two_factor_authentication_option_1"
          >
            {/* <SVGICON src={SettingsIcon} className={'svg-icon-4x svg-icon-primary me-4'} /> */}
            <BsBuildingFillCheck
              className="shield-icon"
              style={{ marginRight: "20px", top: "0px" }}
            />
            <span className="d-block fw-bold text-start">
              <span
                className="text-dark fw-bolder d-block"
                style={{ fontSize: "22px" }}
              >
                {intl.formatMessage({ id: "ADD_COMPANY_ADDRESS_WITH_CODE" })}
              </span>
              <span className="text-muted fw-bold" style={{ fontSize: "16px" }}>
                {intl.formatMessage({
                  id: "ADD_COMPANY_ADDRESS_WITH_CODE_INFO",
                })}
              </span>
            </span>
          </label>

          {/* auth code */}
          <label
            className={clsx(
              "btn bg-light-primary rounded p-3 d-flex align-items-center svg-icon svg-icon-3x-half new-company-off-box"
              // selectedCompanyMethod === 2
              //   ? "new-company-box"
              //   : "new-company-off-box"
            )}
            onClick={() => {
              setSelectedCompanyMethod(2);
              setStep(2);
            }}
            htmlFor="kt_modal_two_factor_authentication_option_1"
          >
            {/* <SVGICON src={SettingsIcon} className={'svg-icon-4x svg-icon-primary me-4'} /> */}
            <BsBuildingFillAdd
              className="shield-icon"
              style={{ marginRight: "20px", top: "0px" }}
            />
            <span className="d-block fw-bold text-start">
              <span
                className="text-dark fw-bolder d-block"
                style={{ fontSize: "22px" }}
              >
                {intl.formatMessage({ id: "ADD_NEW_COMPANY_ADDRESS" })}
              </span>
              <span className="text-muted fw-bold" style={{ fontSize: "16px" }}>
                {intl.formatMessage({ id: "ADD_NEW_COMPANY_ADDRESS_INFO" })}
              </span>
            </span>
          </label>
        </div>
      </div>
    );
  };
  const EnterCodeForm = () => {
    const [selectedCountry, setSelectedCountry] = useState();
    const formikCode = useFormik({
      initialValues: codeInitialValues,
      validationSchema: CodeValidationSchema,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: (values) => {
        if (values.verify_code) {
          setLoading(true);
          addCompanyAddress(
            userDetails?.personalInformation?.id,
            "existing",
            values.verify_code
          )
            .then(() => {
              getCustomerDetailsAPI();
              successToast(
                intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_SUCCESS_MESSAGE",
                })
              );
              closeModal();
            })
            .catch(() => {
              //   setShowFailure(true);
              // errorToast(
              //   intl.formatMessage({
              //     id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ERROR_MESSAGE",
              //   })
              // );
              // setStatus('Cannot add address, try again!')
              setCodeError(
                intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ERROR_MESSAGE",
                })
              );
            })
            .finally(() => {
              setLoading(false);
              // closeModal?.()
            });
        }
      },
    });

    const formikAddress = useFormik({
      initialValues: addressInitialValues,
      validationSchema: AddressValidationSchema,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: (values) => {
        if (
          values.name &&
          values.address1 &&
          values.country_id &&
          values.city &&
          values.zip_code
        ) {
          setLoading(true);
          addCompanyAddress(
            userDetails?.personalInformation?.id,
            "new",
            null,
            values.name,
            values.address1,
            values.address2,
            values.city,
            values.zip_code,
            values.country_id
          )
            .then(() => {
              getCustomerDetailsAPI();
              successToast(
                intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_SUCCESS_MESSAGE",
                })
              );
              closeModal();
            })
            .catch(() => {
              // errorToast(
              //   intl.formatMessage({
              //     id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ERROR_MESSAGE",
              //   })
              // );
              setAddressError(
                intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ERROR_MESSAGE",
                })
              );
            })
            .finally(() => {
              setLoading(false);
            });
        }
      },
    });

    handleCodeSubmit = formikCode.handleSubmit;
    handleAddressSubmit = formikAddress.handleSubmit;

    const onCountrySelect = (item) => {
      setSelectedCountry(item);
    };

    return selectedCompanyMethod === 1 ? (
      <>
        {!!codeError ? (
          <div
            className={`mb-10 mt-3 alert alert-danger`}
            style={{
              padding: "10px",
              borderRadius: "3px",
              textAlign: "start",
            }}
          >
            <div className="alert-text font-weight-bold">{codeError}</div>
          </div>
        ) : null}
        <div>
          <InputLabel
            required
            text={intl.formatMessage({ id: "COMPANY_ADDRESS_WITH_CODE" })}
          />
          <>
            <TextInput
              value={formikCode.values.verify_code}
              size="lg"
              fontSize="16px"
              onChange={(value) => {
                formikCode.setFieldValue("verify_code", value);
              }}
              isError={
                formikCode.touched.verify_code && formikCode.errors.verify_code
              }
            />
            {formikCode.touched.verify_code &&
              formikCode.errors.verify_code && (
                <div className="text-danger">
                  <span role="alert">{formikCode.errors.verify_code}</span>
                </div>
              )}
          </>
        </div>
      </>
    ) : (
      <>
        {!!addressError ? (
          <div
            className={`mb-10 mt-3 alert alert-danger`}
            style={{
              padding: "10px",
              borderRadius: "3px",
              textAlign: "start",
            }}
          >
            <div className="alert-text font-weight-bold text-center">
              {addressError}
            </div>
          </div>
        ) : null}
        <div>
          <>
            <div className="mb-2">
              <InputLabel
                required
                text={intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_COMPANY_ADDRESS_NAME_LABEL",
                })}
              />
              <TextInput
                value={formikAddress.values.name}
                size="lg"
                fontSize="16px"
                onChange={(value) => {
                  formikAddress.setFieldValue("name", value);
                }}
                isError={
                  formikAddress.touched.name && formikAddress.errors.name
                }
              />
              {formikAddress.touched.name && formikAddress.errors.name && (
                <div className="text-danger">
                  <span role="alert">{formikAddress.errors.name}</span>
                </div>
              )}
            </div>
            <div className="mb-2">
              <InputLabel
                required
                text={intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS1_LABEL",
                })}
              />
              <TextInput
                value={formikAddress.values.address1}
                size="lg"
                fontSize="16px"
                onChange={(value) => {
                  formikAddress.setFieldValue("address1", value);
                }}
                isError={
                  formikAddress.touched.address1 &&
                  formikAddress.errors.address1
                }
              />
              {formikAddress.touched.address1 &&
                formikAddress.errors.address1 && (
                  <div className="text-danger">
                    <span role="alert">{formikAddress.errors.address1}</span>
                  </div>
                )}
            </div>

            {/* address 2 */}

            <div className="mb-2">
              <InputLabel
                text={intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ADDRESS2_LABEL",
                })}
              />
              <TextInput
                value={formikAddress.values.address2}
                size="lg"
                fontSize="16px"
                onChange={(value) => {
                  formikAddress.setFieldValue("address2", value);
                }}
                isError={
                  formikAddress.touched.address2 &&
                  formikAddress.errors.address2
                }
              />
              {formikAddress.touched.address2 &&
                formikAddress.errors.address2 && (
                  <div className="text-danger">
                    <span role="alert">{formikAddress.errors.address2}</span>
                  </div>
                )}
            </div>

            {/* city and zip code */}

            <div className="row mb-2">
              <div className="col-md-6 col-lg-6">
                <div className="">
                  <InputLabel
                    required
                    text={intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_CITY_LABEL",
                    })}
                  />
                  <TextInput
                    value={formikAddress.values.city}
                    size="lg"
                    fontSize="16px"
                    onChange={(value) => {
                      formikAddress.setFieldValue("city", value);
                    }}
                    isError={
                      formikAddress.touched.city && formikAddress.errors.city
                    }
                  />
                  {formikAddress.touched.city && formikAddress.errors.city && (
                    <div className="text-danger">
                      <span role="alert">{formikAddress.errors.city}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="">
                  <InputLabel
                    required
                    text={intl.formatMessage({
                      id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_ZIP_CODE_LABEL",
                    })}
                  />
                  <TextInput
                    value={formikAddress.values.zip_code}
                    size="lg"
                    fontSize="16px"
                    onChange={(value) => {
                      formikAddress.setFieldValue("zip_code", value);
                    }}
                    isError={
                      formikAddress.touched.zip_code &&
                      formikAddress.errors.zip_code
                    }
                  />
                  {formikAddress.touched.zip_code &&
                    formikAddress.errors.zip_code && (
                      <div className="text-danger">
                        <span role="alert">
                          {formikAddress.errors.zip_code}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>

            {/* country */}

            <div className="mb-2">
              <InputLabel
                required
                text={intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS_COUNTRY_LABEL",
                })}
              />
              <CustomDropDown
                id="countriesDropDownCompany"
                //   hideSearch
                items={countries}
                selectedItem={selectedCountry}
                //   size="small"
                // @ts-ignore
                onSelectItem={(item) => {
                  formikAddress.setFieldValue("country_id", item.id);
                  onCountrySelect(item);
                }}
                displayValue={selectedCountry?.name || ""}
                // @ts-ignore
                displayFunc={(item) => {
                  return item.emoji + " " + item.name;
                }}
              />
              {formikAddress.touched.country_id &&
                formikAddress.errors.country_id && (
                  <div className="text-danger">
                    <span role="alert">{formikAddress.errors.country_id}</span>
                  </div>
                )}
            </div>
          </>
        </div>
      </>
    );
  };

  return (
    <div className="cam-modal-container">
      <Modal
        show={show}
        centered
        dialogClassName="medium-size-modal"
        contentClassName={loading ? "pe-none" : ""}
      >
        <Modal.Header closeButton onHide={closeModal}>
          <div className="d-flex flex-row flex-fill align-items-center justify-content-between">
            <Modal.Title style={{ fontSize: "20px", fontWeight: "600" }}>
              {intl.formatMessage({ id: "ADD_COMPANY_ADDRESS_TITLE" })}
            </Modal.Title>
            {/* <CloseButton onClose={closeModal} /> */}
          </div>
        </Modal.Header>
        <Modal.Body className="py-4 px-lg-5">
          {step === 1 ? <ChooseAuthMethod /> : <EnterCodeForm />}
        </Modal.Body>
        {step === 2 && (
          <Modal.Footer className="justify-content-center px-lg-5">
            {step === 1 ? (
              // <button
              //   className="btn btn-custom mx-0 my-3 w-100"
              //   data-kt-element="options-select"
              //   style={{ height: "42px" }}
              //   onClick={() => {
              //     setStep(2);
              //   }}
              // >
              //   {intl.formatMessage({ id: "CONTINUE_BUTTON" })}
              // </button>
              <></>
            ) : step === 2 ? (
              <>
                <Button
                  variant="secondary"
                  style={{ padding: "10px 20px", width: "100px" }}
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Back
                </Button>
                <button
                  type="submit"
                  className="btn btn-custom"
                  style={{ padding: "10px 20px", minWidth: "100px" }}
                  onClick={() => {
                    selectedCompanyMethod === 1
                      ? handleCodeSubmit()
                      : handleAddressSubmit();
                  }}
                >
                  {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      {intl.formatMessage({ id: "USERS_LOADING_MESSAGE" })}{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </>
            ) : (
              <>
                <Button variant="primary w-100" onClick={closeModal}>
                  {intl.formatMessage({ id: "OKAY_BUTTON" })}
                </Button>
              </>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default CompanyAddressModal;
