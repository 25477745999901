import React, { useEffect } from "react";
import failureIcon from "../../assets/failure-icon.png";
import successIcon from "../../assets/success-icon.png";
import "./Alert.scss";

function Alert({ message, type, show, setShow, custom }) {
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 2000);
    //eslint-disable-next-line
  }, [show]);

  return (
    show && (
      <div
        className={`${
          type === "success" ? "success-bg" : "error-bg"
        } position-absolute mx-3 p-2 animated fadeInLeft`}
        style={custom ? {top:"200px", zIndex:"99"} : { top: "-60px" }}
      >
        {type === "success" ? (
          <img
            src={successIcon}
            alt="test"
            style={{ width: "25px" }}
            className="me-3"
          />
        ) : (
          <img
            src={failureIcon}
            alt="test"
            style={{ width: "25px" }}
            className="me-3"
          />
        )}
        <span>{message}</span>
      </div>
    )
  );
}

export default Alert;
