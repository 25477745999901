import "@formatjs/intl-relativetimeformat/locale-data/de";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/polyfill";
import { IntlProvider } from "react-intl";
import { useLang } from "./Metronici18n";
import languages from "./messages/language";

const I18nProvider = ({ children }) => {
  const locale = useLang();

  const tempObj = {};

  Object.keys(languages).map((key) => {
    tempObj[key] = languages[key][locale];
  });

  return (
    <IntlProvider locale={locale} messages={tempObj}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };

// import React, {  Fragment } from "react";
// import { IntlProvider } from "react-intl";
// import { LOCALES } from "./locales";
// import messages from "./messages";
// import language from './messages/language'
// const I18nProvider = ({ children, locale }) => {
//   return (
//     <IntlProvider
//       locale={locale}
//       textComponent={Fragment}
//       messages={messages[locale]}
//     >
//         {children}
//     </IntlProvider>
//   );
// };

// export default I18nProvider
