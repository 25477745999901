import React, { useState } from "react";
// import { useInView } from "react-intersection-observer";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import CardArticle from "../../sharedComponents/Article/CardArticle";
import CarouselArticle from "../../sharedComponents/Article/CarouselArticle";
import Title, { TitleHeader } from "../../sharedComponents/Title";

const AllPostsList = ({ pageName, type, id, flag, sidebar }) => {
  const intl = useIntl();
  const masterData = useSelector((state) => state.masterData);
  const [current, setCurrent] = useState(sidebar ? 4 : 6);
  const masterDataDetails =
    masterData?.[pageName]?.[type]?.masterDataDetails?.[id];
  // const { ref, inView } = useInView({
  //   triggerOnce: true,
  //   rootMargin: "-100px 0px",
  // });
  const handleLoadMore = (type) => {
    if (sidebar) setCurrent((prev) => prev + 2);
    else setCurrent((prev) => prev + 3);
  };
  return (
    <>
      <div className="row" style={{ rowGap: "1rem" }}>
        {sidebar ? (
          <TitleHeader
            className="mb-0"
            title={`${
              flag.startsWith("p")
                ? intl.formatMessage({ id: "TITLE_POST" })
                : intl.formatMessage({ id: "TITLE_EVENT" })
            }`}
            noMarginTop
          />
        ) : (
          <Title
            title={`${
              flag.startsWith("p")
                ? intl.formatMessage({ id: "TITLE_POST" })
                : intl.formatMessage({ id: "TITLE_EVENT" })
            }`}
          ></Title>
        )}

        {masterDataDetails?.[flag].slice(0, current).map((ele, index) => {
          return (
            <div className={`${sidebar ? "col-lg-6" : "col-lg-4"} col-md-6 `}>
              {flag.startsWith("p") ? (
                <div
                  className="animate__animated animate__fadeInUp"
                  // ref={ref}
                  // className={`reveal fade-bottom ${inView ? "active" : ""}`}
                >
                  <CarouselArticle
                    details={ele}
                    key={index}
                    sidebars={{ a: true }}
                  />
                </div>
              ) : (
                <div
                  className="animate__animated animate__fadeInUp"
                  // ref={ref}
                  // className={`reveal fade-bottom ${inView ? "active" : ""}`}
                >
                  <CardArticle details={ele} key={index} />
                </div>
              )}
            </div>
          );
        })}
      </div>
      {current < masterDataDetails?.[flag].length && (
        <div className="d-flex align-items-center justify-content-center my-3">
          <button
            onClick={() => handleLoadMore()}
            id="button-custom"
            className="mx-auto "
            style={{ width: "fit-content", height: "fit-content" }}
          >
            {intl.formatMessage({ id: "PAGES_LOAD_MORE" })}
          </button>
        </div>
      )}
    </>
  );
};

export default AllPostsList;
