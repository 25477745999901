import axios from "axios";

export async function getCommunitiesList() {
  const response = await axios({
    method: "get",
    url: "/websiteApp/communities",
  });
  return response;
}

export async function joinPublicCommunity(id) {
  const response = await axios({
    method: "post",
    url: `/communities/join/${id}`,
  });
  return response;
}

export async function requestPrivateCommunity(id) {
  const response = await axios({
    method: "post",
    url: `/communities/request/${id}`,
  });
  return response;
}

export async function getCommunityPosts(id) {
  const response = await axios({
    method: "get",
    url: `/communities/posts/${id}`,
  });
  return response;
}

export function addCommunityPostImage(id, body, image_url, image_type) {
  return axios.post(`/communities/posts/${id}`, {
    body,
    image_url,
    image_type,
  });
}

export function addCommunityPostFile(id, medias) {
  return axios.post(`/communities/posts/${id}`, medias, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateCommunityPost(id, body) {
  return axios.post(`/communities/posts/update/${id}`, {
    body,
  });
}

export function deleteCommunityPost(id) {
  return axios.post(`/communities/posts/delete/${id}`);
}

export function addPostComment(communityId, postId, body) {
  return axios.post(`/communities/posts/comments/${communityId}/${postId}`, {
    body,
  });
}

export function addPostCommentReply(id, postID, body, comment_id) {
  return axios.post(`/communities/posts/comments/reply/${id}/${postID}`, {
    comment_id,
    body,
  });
}

export function updateCommentReply(communityId, body, comment_id) {
  return axios.post(
    `/communities/posts/comments/update/${communityId}/${comment_id}`,
    {
      body,
    }
  );
}
export function deleteComment(communityId, comment_id) {
  return axios.post(
    `/communities/posts/comments/delete/${communityId}/${comment_id}`
  );
}
export function joinedCommunities() {
  return axios.get(`/contact/communities`);
}
