import React, { useEffect, useState } from "react";
import { AiFillRead, AiFillSetting } from "react-icons/ai";
import { HiHeart, HiUsers } from "react-icons/hi";
import { TbChevronDown, TbChevronUp } from "react-icons/tb";
import { GoSignOut } from "react-icons/go"
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate } from "react-router-dom";
import deIcon from "../../assets/De-icon.png";
import ukIcon from "../../assets/uk-icon.png";
import Login from "../../components/LoginModal/components/login";
import { setLanguage, useLang } from "../../i18n/Metronici18n";
import { actions } from "../../Redux/AuthReducer";
import { dateFormat } from "../../utils";
import "./style.scss";

const TopBar = ({ generalDetails, setLocale, headerTwoActive }) => {
  const intl = useIntl();
  const lang = useLang();
  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate();
  // const [headerTwoActive, setHeaderTwoActive] = useState(false);
  const [showLocaleDropdown, setShowLocaleDropdown] = useState(false);
  const [showCommunities, setShowCommunities] = useState(true);
  const [openUserModal, setOpenUserModal] = useState(false);
  const dispatch = useDispatch();
  const user_sign_In = useSelector(
    (state) =>
      state.generalDetails.generalDetails?.general_settings?.user_sign_In
  );
  const userInfo = useSelector(
    (state) => state.auth?.user?.personalInformation
  );
  const joinedCommunitiesData = useSelector(
    (state) => state.communities.joinedCommunities
  );

  const allCommunities = useSelector((state) => state.communities.communities);

  const isAuthorized = useSelector((state) => state.auth?.isAuthorized);

  const accessToken = sessionStorage.getItem("accessToken");
  // useEffect(() => {
  //   // window.location.pathname.indexOf("/register/verify") !== -1
  //   //   ? setHeaderTwoActive(true)
  //   //   : setHeaderTwoActive(false);
  //   localStorage.setItem("language", locale.includes("en") ? "en" : "de");
  // }, [navigate]);

  const closeLoginModal = () => {
    setShowLogin(false);
  };
  const openLoginModal = () => {
    setShowLogin(true);
  };

  // const logOut = () => {
  //   dispatch(actions.logout());
  //   sessionStorage.removeItem("accessToken");
  //   navigate(0);
  // };

  const handleClick = (data) => {
    setShowLocaleDropdown((prev) => !prev);
    //setLocale(data);
    setLanguage(data.includes("de") ? "de" : "en");
    localStorage.setItem("language", data.includes("de") ? "de" : "en");
  };

  const logout = () => {
    navigate("/");
    dispatch(actions.logout());
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };

  // window.onclick = function (event) {
  //   if (
  //     !event.target.matches(".menu") &&
  //     !event.target.matches(".profile") &&
  //     !event.target.matches("#dropdownTopbarButton")
  //   ) {
  //   setOpenUserModal(false);
  //   }
  // };

  useEffect(() => {
    if (!isAuthorized) {
      setOpenUserModal(false);
    }
  }, [isAuthorized]);

  const leavePage = (page) => {
    setOpenUserModal(false);
    navigate(`/${page.toLowerCase()}`);
  };

  useEffect(() => {
    if (
      Object.keys(joinedCommunitiesData).length === 0 &&
      Object.keys(allCommunities).length === 0
    ) {
      setShowCommunities(false);
    } else {
      setShowCommunities(true);
    }
  }, [openUserModal, joinedCommunitiesData, allCommunities]);

  return (
    <>
      {showLogin && <Login show={showLogin} closeModal={closeLoginModal} />}
      <div
        className="topbar d-none d-lg-block"
        style={{
          backgroundColor: generalDetails.navigation_top_background,
          color: generalDetails.navigation_top_text,
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-5">
              <div className="topbar-left">
                <div className="topbar-text fw-bold">{dateFormat(lang)}</div>
              </div>
            </div>
            <div className="col-sm-12 col-md-7">
              <div className="list-unstyled topbar-right ">
                {/* <span className='mx-1'>{intl.formatMessage({id:'Langua}ge')}</span> */}
                <div
                  className={`${
                    accessToken ? "me-2" : ""
                  } lang-icon lang-select`}
                >
                  <img
                    src={lang === "en" ? ukIcon : deIcon}
                    onClick={() => setShowLocaleDropdown((prev) => !prev)}
                    alt=""
                  />
                  <ul
                    className={`${
                      showLocaleDropdown ? "d-inline" : "d-none"
                    } lang-dropdown`}
                    style={{ zIndex: 11 }}
                  >
                    <li
                      className={`d-flex lang-icon user-language my-2 px-4 py-2 ${
                        lang === "de" ? "selected" : ""
                      }`}
                      onClick={() => handleClick("de")}
                    >
                      <img src={deIcon} alt="" />
                      {/* <span className="mx-2">German</span> */}
                    </li>
                    <li
                      className={` d-flex lang-icon user-language my-2 px-4 py-2 ${
                        lang === "en" ? "selected" : ""
                      }`}
                      onClick={() => handleClick("en")}
                    >
                      <img src={ukIcon} alt="" />
                      {/* <span className="mx-2">English(UK)</span>  */}
                    </li>
                  </ul>
                </div>

                {!accessToken && user_sign_In === "no" && !headerTwoActive ? (
                  <button
                    className="top-bar-button mx-4 fw-bold"
                    onClick={openLoginModal}
                    style={{ fontSize: "14px", verticalAlign: "super" }}
                  >
                    <span>
                      <i className=" fa-solid fa-user"></i>
                    </span>
                    {/* <div className="topbar-link"> */}
                    {intl.formatMessage({ id: "LOGIN" })}
                    {/* </div>  */}
                  </button>
                ) : null}
                {accessToken ? (
                  <>
                    {/* previous version of profile commented below */}

                    {/* <div 
                    onClick={()=>{open ? setOpen(false) : setOpen(true)}}
                    className=" dropdown-menu account-setting-button" style={{width:'30px', height:'30px'}}>
                      {`${userInfo?.firstname.toUpperCase()[0]}${userInfo?.lastname.toUpperCase()[0]}`}
                      {open ? (
                          <ul className="menu" style={{backgroundColor:'white'}}>
                            <div className="menu-item mt-2">
                              <div className=" d-inline-flex" style={{width:'100%'}}>
                              <div className=" menu-icon-custom">
                                <div className=" position-relative" style={{top:'-2px'}}>
                                {`${userInfo?.firstname.toUpperCase()[0]}${userInfo?.lastname.toUpperCase()[0]}`}
                                </div>
                              </div>
                              <div className="me-3">
                                <div className="menu-full-name">
                                {`${userInfo?.firstname.toUpperCase()} ${userInfo?.lastname.toUpperCase()}`}
                                </div>
                                <div style={{fontSize:'14px', textAlign:'end'}}>
                                {userInfo?.email}
                                </div>
                              </div>
                              </div>
                            </div>
                            <li className="menu-item">
                              <button onClick={() => {
                                  navigate(`/account`)
                                }}>Account Settings</button>
                            </li>
                            <li className="menu-item">
                              <button onClick={logOut}>Sign Out</button>
                            </li>
                          </ul>
                        ) : null}
                    </div> */}

                    {/* <div
                      className=" d-inline-flex ps-2 profile"
                      onMouseEnter={() => setOpen(true)}
                      onMouseLeave={() => setOpen(false)}
                    >
                      {userInfo?.username || userInfo?.firstname ? (
                        <div className=" rounded-letter mx-2">
                          <CgProfile className="topbar-profile-icon position-relative ms-1" />
                        </div>
                      ) : null}
                      {userInfo?.username || userInfo?.firstname}
                      {open ? (
                        <TbChevronUp className="dropdown-topbar-icon position-relative ms-1" />
                      ) : (
                        <TbChevronDown className="dropdown-topbar-icon position-relative ms-1" />
                      )}
                      {open ? (
                        <div
                          className="menu animate animatedFadeInUp fadeInUp"
                          style={{ backgroundColor: "white" }}
                        >
                          <div
                            className="menu-item"
                            style={{ display: "contents" }}
                          >
                            <li
                              onClick={() => {
                                navigate(`/account`);
                              }}
                            >
                              Account
                            </li>
                          </div>
                          <div
                            className="menu-item"
                            style={{ display: "contents" }}
                          >
                            <li onClick={logout}>Sign Out</li>
                          </div>
                        </div>
                      ) : // <div className=" position-absolute ">

                      // </div>
                      null}
                    </div> */}

                    {/* latest version of profile */}

                    <div
                      className="dropdown custom-dropdown  d-inline-flex ps-2 profile"
                      style={{ verticalAlign: "super" }}
                    >
                      <div
                        // href="#"
                        data-bs-toggle="dropdown"
                        className="dropdown-link"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-controls="topBarSettings"
                        id="dropdownTopbarButton"
                        onClick={(e) => setOpenUserModal(!openUserModal)}
                      >
                        {userInfo?.firstname ? (
                          // <div className=" mx-2 d-inline-flex">
                          //   <img src={ukIcon} alt="profile-pic"/>
                          // </div>
                          <div className=" d-inline-flex mx-2 profile-pic">
                            {`${userInfo?.firstname.toUpperCase()[0]}${
                              userInfo?.lastname.toUpperCase()[0]
                            }`}
                          </div>
                        ) : null}
                        <div className=" d-inline-flex">
                          {userInfo?.firstname}
                        </div>
                        <TbChevronDown className="topbar-profile-icon position-relative ms-1 arrow-down" />
                        <TbChevronUp className="topbar-profile-icon position-relative ms-1 arrow-up" />
                      </div>
                    </div>
                  </>
                ) : null}

                {openUserModal && accessToken && (
                  <div className="user_modal">
                    <div style={{ padding: "10px 10px 0 10px" }}>
                      <div
                        className="user_modal_item"
                        onClick={() => leavePage("posts-read")}
                      >
                        {/* Read Posts */}
                        <AiFillRead
                          style={{
                            width: "40px",
                            height: "20px",
                            fill: "var(--accent-color)",
                          }}
                        />
                        {intl.formatMessage({ id: "TITLE_READ_POSTS" })}
                      </div>
                      <div
                        className="user_modal_item"
                        onClick={() => leavePage("favourite-posts")}
                      >
                        <HiHeart
                          style={{
                            width: "40px",
                            height: "20px",
                            fill: "var(--accent-color)",
                          }}
                        />
                        Favourite Posts
                      </div>
                      {showCommunities && (
                        <div
                          className="user_modal_item"
                          onClick={() => leavePage("communities")}
                        >
                          <HiUsers
                            style={{
                              width: "40px",
                              height: "20px",
                              fill: "var(--accent-color)",
                            }}
                          />
                          {intl.formatMessage({ id: "COMMUNITIES_TITLE" })}
                        </div>
                      )}
                    </div>
                    <hr className="user_modal_rule" />
                    <div style={{ padding: "0 10px 10px 10px" }}>
                      <div
                        className="user_modal_item"
                        onClick={() => {
                          setOpenUserModal(false);
                          navigate(`/account`);
                        }}
                      >
                        <AiFillSetting
                          style={{
                            width: "40px",
                            height: "20px",
                            fill: "var(--accent-color)",
                          }}
                        />
                        {intl.formatMessage({
                          id: "SHARED_COMPONENTS_TOPBAR_ACCOUNT_SETTINGS",
                        })}
                      </div>
                      <div className="user_modal_item" onClick={logout}>
                        <GoSignOut
                          style={{
                            width: "40px",
                            height: "20px",
                            fill: "var(--accent-color)",
                          }}
                        />
                        {intl.formatMessage({
                          id: "SHARED_COMPONENTS_TOPBAR_LOGOUT",
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {/* <ul className="topbar-sosmed">
                                <li>
                                    <Link to="#"><FontAwesomeIcon icon={faFacebookF} /></Link>
                                </li>
                                <li>
                                    <Link to="#"><FontAwesomeIcon icon={faTwitter} /></Link>
                                </li>
                                <li>
                                    <Link to="#"><FontAwesomeIcon icon={faInstagram} /></Link>
                                </li>
                            </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
