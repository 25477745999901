import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import TrendingPosts from "../../sharedComponents/TrendingPosts/TrendingPosts";
import Dashboard from "../Pages/Dashboard";
import SideBar from "../Pages/SideBar";
const PageSection = ({ id, pageName }) => {
  const pageDetails = useSelector(
    (state) => state?.pageDetails?.pages?.[pageName]
  );
  const { website_settings } = useSelector(
    (state) => state.generalDetails.generalDetails
  );
  const { trendingPosts } = useSelector((state) => state.postsDetails);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id, pageName]);

  return (
    <>
      {website_settings?.top_posts_section === "yes" &&
        trendingPosts?.length > 0 && <TrendingPosts />}
      {pageDetails?.sidebars?.sections &&
      pageDetails?.sidebars?.sections.length > 0 ? (
        <div className="container pb-3">
          <div className="row">
            <div className="col-lg-8 mt-4" style={{ paddingRight: "20px" }}>
              <Dashboard
                id={id}
                pageName={pageName}
                pageSections={pageDetails?.sections || []}
              />
            </div>
            <div className="col-lg-4 mt-4" style={{ paddingLeft: "20px" }}>
              <SideBar
                sections={pageDetails?.sidebars?.sections}
                pageName={pageName}
                type="landingPage"
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            pageDetails?.sections[0]?.type.toLowerCase() === "banner"
              ? ""
              : pageDetails?.sections[0]?.type.toLowerCase() === "navigations"
              ? "mt-2"
              : "mt-4"
          }
        >
          <Dashboard
            id={id}
            pageName={pageName}
            pageSections={pageDetails?.sections || []}
          />
        </div>
      )}
    </>
  );
};

export default PageSection;
