import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiDevices } from "react-icons/bi";
import { BsFillBuildingsFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FaMoneyCheckAlt, FaRegAddressCard } from "react-icons/fa";
import { MdOutlineLocalOffer, MdSecurity } from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import { actions } from "../../../Redux/AuthReducer";
import Loader from "../../../sharedComponents/Loader";
import {
  getContactDetails,
  valuatePurchaseDetails,
} from "../../auth/Redux/AuthApi";
import AccountInformation from "./AccountInformation";
import CompanyAddressWrapper from "./CompanyAddress/CompanyAddressWrapper";
import CustomerContactTypes from "./ContactsSettings";
import DevicesList from "./DevicesList";
import OffersList from "./OffersList";
import PersonalAddress from "./PersonalAddress";
import ProductList from "./ProductList";
import SignInSettings from "./SignInSettings";
import SubscriptionsWrapper from "./SubscriptionsWrapper";
import "./style.scss";

const sidebarRoutes = [
  { id: 1, elementId: "account-info", route: "/account/account-information" },
  { id: 2, elementId: "security", route: "/account/security" },
  { id: 3, elementId: "personal-address", route: "/account/personal-address" },
  { id: 4, elementId: "contact-types", route: "/account/contact-types" },
  { id: 5, elementId: "products", route: "/account/products" },
  { id: 6, elementId: "subscriptions", route: "/account/subscriptions" },
  { id: 7, elementId: "devices", route: "/account/devices" },
  { id: 8, elementId: "offer", route: "/account/offer" },
  { id: 9, elementId: "company-address", route: "/account/company-address" },
];

function AccountSettingsWrapper() {
  const intl = useIntl();

  const addActiveEffect = (route) => {
    sidebarRoutes.forEach((element) => {
      if (element.route === route) {
        document
          .getElementById(element.elementId)
          ?.classList.add("sidebar-active");
      } else {
        document
          .getElementById(element.elementId)
          ?.classList.remove("sidebar-active");
      }
    });
  };

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userId = useSelector(
    (state) => state.auth?.user?.personalInformation?.id
  );
  const sysAdmin = useSelector(
    (state) => state.auth?.user?.personalInformation?.sys_admin
  );
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(true);
  // const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      addActiveEffect(location.pathname);
    }, 1);
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    // if (!userId) {
    //   return;
    // }
    // getContactDetails().then(({data}) => {
    //     setUserDetails(data);
    //   })
    //   .finally(()=>{
    //     setLoading(false);
    //     // setTimeout(() => {
    //     //     addActiveEffect(window.location.pathname)
    //     // }, 1);
    //   })

    getCustomerDetailsAPI();
    // eslint-disable-next-line
  }, []);

  const getCustomerDetailsAPI = (customer) => {
    if (!userId) {
      return;
    }
    //to valuate purchase details
    valuatePurchaseDetails();
    getContactDetails()
      .then(({ data }) => {
        setUserDetails(data);
        dispatch(actions.setUser(data));
        //   dispatch(actions.setUser(data.personalInformation))
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          addActiveEffect(window.location.pathname);
        }, 1);
      });
  };

  return loading === true ? (
    <Loader />
  ) : (
    <div className="account-setting-wrapper">
      <div className="container ">
        <div className="mt-4 mb-2">
          <h2 className="" style={{ fontSize: "24px" }}>
            {intl.formatMessage({ id: "ACCOUNT_SETTINGS_WRAPPER_TITLE" })}
          </h2>
          <p style={{ fontSize: "14px", color: "grey" }}>
            {intl.formatMessage({ id: "ACCOUNT_SETTINGS_WRAPPER_SUB_TITLE" })}
          </p>
        </div>
        <div className="row mt-4">
          <div className="col-lg-3 col-md-3 mb-md-5">
            <SideBarElements
              icon={<CgProfile className="account-setting-icon" />}
              title={intl.formatMessage({
                id: "ACCOUNT_SETTINGS_ACCOUNT_INFORMATION",
              })}
              id={"account-info"}
              addActiveEffect={addActiveEffect}
            />
            <SideBarElements
              icon={<MdSecurity className="account-setting-icon" />}
              title={intl.formatMessage({ id: "ACCOUNT_SETTINGS_SECURITY" })}
              id={"security"}
              addActiveEffect={addActiveEffect}
            />
            <SideBarElements
              icon={<FaRegAddressCard className="account-setting-icon" />}
              title={intl.formatMessage({
                id: "ACCOUNT_SETTINGS_PERSONAL_ADDRESS",
              })}
              id={"personal-address"}
              addActiveEffect={addActiveEffect}
            />
            {!sysAdmin ? (
              <SideBarElements
                icon={<BsFillBuildingsFill className="account-setting-icon" />}
                title={intl.formatMessage({
                  id: "ACCOUNT_SETTINGS_COMPANY_ADDRESS",
                })}
                id={"company-address"}
                addActiveEffect={addActiveEffect}
              />
            ) : null}
            <SideBarElements
              icon={<FaRegAddressCard className="account-setting-icon" />}
              title={intl.formatMessage({
                id: "ACCOUNT_SETTINGS_CONTACT_TYPES",
              })}
              id={"contact-types"}
              addActiveEffect={addActiveEffect}
            />
            <SideBarElements
              icon={<BiDevices className="account-setting-icon" />}
              title={intl.formatMessage({ id: "TITLE_DEVICES" })}
              id={"devices"}
              addActiveEffect={addActiveEffect}
            />
            <SideBarElements
              icon={<AiOutlineShoppingCart className="account-setting-icon" />}
              title={intl.formatMessage({ id: "TITLE_PRODUCTS" })}
              id={"products"}
              addActiveEffect={addActiveEffect}
            />
            <SideBarElements
              icon={<FaMoneyCheckAlt className="account-setting-icon" />}
              title={intl.formatMessage({ id: "TITLE_SUBSCRIPTIONS" })}
              id={"subscriptions"}
              addActiveEffect={addActiveEffect}
            />
            <SideBarElements
              icon={<MdOutlineLocalOffer className="account-setting-icon" />}
              title={intl.formatMessage({ id: "TITLE_OFFERS" })}
              id={"offer"}
              addActiveEffect={addActiveEffect}
            />
          </div>
          <div className=" col-lg-8 col-md-9 position-relative">
            {/* <div className='card'>
                        <div className='card-body'> */}
            <Routes>
              <Route
                path="/account-information"
                element={
                  <AccountInformation
                    userDetails={userDetails}
                    getCustomerDetailsAPI={getCustomerDetailsAPI}
                  />
                }
              />

              <Route
                path="/security"
                element={
                  <SignInSettings
                    userDetails={userDetails}
                    getCustomerDetailsAPI={getCustomerDetailsAPI}
                  />
                }
              />

              <Route
                path="/personal-address"
                element={
                  <PersonalAddress
                    userDetails={userDetails}
                    getCustomerDetailsAPI={getCustomerDetailsAPI}
                  />
                }
              />

              <Route
                path="/company-address"
                element={
                  <CompanyAddressWrapper
                    userDetails={userDetails}
                    getCustomerDetailsAPI={getCustomerDetailsAPI}
                  />
                }
              />

              <Route
                path="/contact-types"
                element={
                  <CustomerContactTypes
                    customerDetails={userDetails}
                    getCustomerDetailsAPI={getCustomerDetailsAPI}
                  />
                }
              />

              <Route path="/products" element={<ProductList />} />

              <Route path="/subscriptions" element={<SubscriptionsWrapper />} />

              <Route
                path="/devices"
                element={
                  <DevicesList
                    userDetails={userDetails}
                    getCustomerDetailsAPI={getCustomerDetailsAPI}
                  />
                }
              />

              <Route path="/offer" element={<OffersList />} />

              <Route
                index
                element={<Navigate to={"/account/account-information"} />}
              />
            </Routes>
            {/* </div>
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const SideBarElements = ({ icon, title, id, addActiveEffect }) => {
  const navigate = useNavigate();
  return (
    <div
      id={id}
      className=" d-inline-flex p-2 account-setting-sidebar"
      style={{ borderRadius: "4px" }}
      onClick={() => {
        let tempRoute = sidebarRoutes.find(
          (element) => element.elementId === id
        ).route;
        // addActiveEffect(tempRoute);
        navigate(tempRoute);
      }}
    >
      <span
        className="text-dark text-capitalize d-flex justify-content-center align-items-center"
        style={{ fontSize: "16px" }}
      >
        {icon}
        {title}
      </span>
    </div>
  );
};

export default AccountSettingsWrapper;
