import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getPostsDetails } from "../../Redux/PostsDetailsReducer";
import SidebarArticle from "../../sharedComponents/Article/SidebarArticle";
import Category from "../../sharedComponents/Categories";
import Newsletter from "../../sharedComponents/NewsLetter/Newsletter";
import Tags from "../../sharedComponents/Tags";
import { TitleHeader } from "../../sharedComponents/Title";
import DeadlinesListSection from "../Sections/DeadlinesListSection";
import SidebarEventSection from "../Sections/SidebarEventSection";
import SidebarPostSection from "../Sections/SidebarPostSection";
const SideBar = ({ sections, pageName, relatedPosts, relatedEvents, type }) => {
  const intl = useIntl();
  const postsDetails = useSelector((state) => state?.postsDetails?.[pageName]);
  const generalDetails = useSelector(
    (state) => state?.generalDetails?.generalDetails
  );
  const campaigns = useSelector((state) => state.auth.campaignDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (sections && sections.length > 0) {
      sections.forEach((ele) => {
        if (ele.type === "post-list" || ele.type === "event-list") {
          const uId = `${ele?.section_id}_${ele?.id}`;
          const paramsObject = {
            offset: ele.offset,
            option_for_featured: ele.option_for_featured,
            pagination: ele.pagination,
            items_per_page: ele.items_per_page,
            groups: ele.groups,
            groups_condition: ele.groups_condition,
            categories: ele.categories,
            categories_condition: ele.categories_condition,
            tags: ele.tags,
            tags_condition: ele.tags_condition,
            rule_type: ele.rule_type,
            type: ele.type,
            sorting: ele.sorting,
          };
          if (ele.type === "post-list" || ele.type === "event-list") {
            if (!postsDetails?.[uId]) {
              dispatch(
                getPostsDetails({
                  paramsObject,
                  pageName,
                  id: ele.id,
                  type: ele.type,
                  defaultView: ele.default_view,
                  title: "sideBar",
                  sectionId: ele.section_id,
                })
              );
            }
          }
        }
      });
    }
    //eslint-disable-next-line
  }, [pageName, sections]);
  return (
    <div className="mt-0">
      {sections && sections.length > 0
        ? sections.map((element, index) => {
            switch (element.type) {
              case "NewsLetter":
                return (
                  <Newsletter
                    key={index}
                    type={type}
                    details={element}
                    sidebars={true}
                    isSidebar={true}
                    pageName={pageName}
                    generalDetails={generalDetails}
                  />
                  // <>
                  //   {!!element?.display_header ? (
                  //     <TitleHeader title={element?.title} />
                  //   ) : null}
                  //   <NewsLetterThree
                  //     details={element}
                  //     key={index}
                  //     sidebars={true}
                  //   />
                  // </>
                );
              // case("event-list"):return <div>EventList</div>;
              case "deadlines-list":
                return (
                  <DeadlinesListSection
                    id={element.id}
                    title={element.title}
                    pageName={pageName}
                    isSidebar={true}
                    details={element}
                  />
                );
              case "category-list":
                return (
                  <Category
                    details={element}
                    key={index}
                    title="category"
                    pageName={pageName}
                  />
                );
              case "group-list":
                return (
                  <Category
                    details={element}
                    key={index}
                    title="groups"
                    pageName={pageName}
                  />
                );
              case "tag-list":
                return (
                  <Tags
                    details={element}
                    key={index}
                    pageName={pageName}
                    title={element.title}
                  />
                );
              case "post-list": {
                const uId = `${element?.section_id}_${element?.id}`;
                return (
                  <SidebarPostSection
                    displayHeader={element?.display_header}
                    key={index}
                    uId={uId}
                    pageName={pageName}
                    title={element.title}
                    type="post-list"
                    ele={element}
                  />
                );
              }
              case "event-list": {
                const uId = `${element?.section_id}_${element?.id}`;
                return (
                  <SidebarEventSection
                    displayHeader={element?.display_header}
                    key={index}
                    uId={uId}
                    pageName={pageName}
                    title={element.title}
                    type="event-list"
                    ele={element}
                  />
                );
              }
              case "related-post-list": {
                return (
                  relatedPosts &&
                  relatedPosts.length > 0 && (
                    <div className="mb-3">
                      {element.display_header === 1 ? (
                        <TitleHeader
                          title={element.title}
                          header_navigation_id={element?.header_navigation_id}
                          noMarginTop
                        />
                      ) : null}
                      {relatedPosts.map((ele, index) => {
                        return (
                          <SidebarArticle
                            details={ele}
                            key={index}
                            relatedPosts={true}
                          />
                        );
                      })}
                    </div>
                  )
                );
              }
              case "related-event-list": {
                return (
                  relatedEvents &&
                  relatedEvents.length > 0 && (
                    <div className="mb-3">
                      {element.display_header === 1 ? (
                        <TitleHeader
                          title={element.title}
                          header_navigation_id={element.header_navigation_id}
                          noMarginTop
                        />
                      ) : null}
                      {relatedEvents.map((ele, index) => {
                        return (
                          <SidebarArticle
                            details={ele}
                            key={index}
                            // relatedPosts={true}
                            type="event-list"
                          />
                        );
                      })}
                    </div>
                  )
                );
              }
            }
            if (element?.section_id === 10) {
              return (
                campaigns?.sidebarBannerCampaign?.image_url && (
                  <div
                    className="mb-3"
                    title={campaigns?.sidebarBannerCampaign?.name}
                    style={{ cursor: "pointer" }}
                  >
                    <TitleHeader
                      title={intl.formatMessage({ id: "TITLE_ADVERTISE" })}
                      noMarginTop
                      header_navigation_id={element?.header_navigation_id}
                    />
                    <div
                      className=""
                      onClick={() => {
                        if (campaigns?.sidebarBannerCampaign?.site_url)
                          window.open(
                            campaigns?.sidebarBannerCampaign?.site_url,
                            "_blank",
                            "noreferrer"
                          );
                      }}
                    >
                      {campaigns?.sidebarBannerCampaign?.image_url && (
                        <img
                          className="rounded web-kit-width w-100"
                          src={campaigns?.sidebarBannerCampaign?.image_url}
                          // style={{
                          //   aspectRatio: "16/9",
                          //   objectFit: "cover",
                          // }}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )
              );
            }
          })
        : null}
    </div>
  );
};

export default SideBar;
