import axios from "axios";
import { useEffect, useState } from "react";
import { browserName } from "react-device-detect";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router";
import { getNewsletterActivationStatus } from "../../API/Api";
import error from "../../assets/error.png";
import newsletter from "../../assets/newsletter.svg";
import success from "../../assets/success-icon.png";
import "./style.scss";

export const NewsLetterActivation = () => {
  const intl = useIntl();
  const [response, setResponse] = useState({});
  const navigate = useNavigate();
  const [ip, setIP] = useState("");
  const { id } = useParams();
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);

  //function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  const navigateHome = () => {
    navigate("/");
  };

  useEffect(() => {
    if (id) {
      const paramsObject = {
        ip_address: ip,
        browser_agent: browserName,
        hashed_email: id,
      };
      setLoading(true);
      getNewsletterActivationStatus(paramsObject)
        .then((response) => {
          setTimeout(() => {
            setResponse(response);
            setLoading(false);
            // if (response.status === "Success") {
            //   setTimeout(() => navigate("/"), 3000);
            // }
          }, 2000);
        })
        .catch((err) => {
          setLoading(false);
          setErr(true);
        });
    }
  }, []);

  return (
    <div
      className="container newsletter-container d-flex justify-content-center align-items-center gap-2 p-4"
      style={{ minHeight: "400px", maxWidth: "1000px" }}
    >
      <img
        className="newsletterSvg"
        src={newsletter}
        style={{ width: "30px", flex: "1" }}
      />
      <div
        className="d-flex justify-content-center align-items-center fw-bold flex-column"
        style={{ flex: "1" }}
      >
        {response && Object.entries(response).length > 0 && (
          <div>
            {response?.status === "Success" && (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={success} style={{ width: "40px" }} />
                <p className="newsletterText">
                  {intl.formatMessage({ id: "NEWSLETTER_CONFIRMATION" })}
                </p>
              </div>
            )}
          </div>
        )}
        {loading && (
          <div className="d-flex flex-column align-items-center">
            <p className="newsletterText">
              {intl.formatMessage({
                id: "NEWSLETTER_ACTIVATION_INPROGRESS",
              })}
            </p>
          </div>
        )}
        {err && (
          <div className="d-flex flex-column align-items-center">
            <img src={error} style={{ width: "40px" }} />
            <p className="newsletterText">
              {intl.formatMessage({ id: "NEWSLETTER_ACTIVATION_FAILED" })}
            </p>
            <button onClick={navigateHome} id="button-custom">
              Go Home
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
